import {mainServer} from '../../../../variables/config'
const params = {order_by:'id_business', order:0}

export const request_businesses = async (id, params) => {
  const logData = JSON.parse(localStorage.getItem('data'));
  mainServer.instance.defaults.headers.common['Authorization'] = logData.token;
  let response;
  response = await mainServer.instance.get(`/business/listbyclient/${id}${params}`);
  return response.data.data
}

export const request_add_business = async (data) => {
  const logData = JSON.parse(localStorage.getItem('data'))
  const data2send = {rfc:data.rfc}
  mainServer.instance.defaults.headers.common['Authorization'] = logData.token
  let response = await mainServer.instance.post("/business/", data)
  const id = response.data.data.business_id
  await mainServer.instance.patch("/businessdocument/"+id, data2send)
  return id;

}

export const request_delete_business = async (clientID, businessID,params) => {
  const logData = JSON.parse(localStorage.getItem('data'))
  mainServer.instance.defaults.headers.common['Authorization'] = logData.token
  await mainServer.instance.delete(`/business/${businessID}`)
  let response
  response = await mainServer.instance.get(`/business/listbyclient/${clientID}${params}`)
  return response.data.data
}

export const request_employees = async () => {
  const logData = JSON.parse(localStorage.getItem('data'))
  mainServer.instance.defaults.headers.common['Authorization'] = logData.token
  let response;
  response = await mainServer.instance.get("/user/employee/list");
  return response.data.data.employees
}

export const request_clients = async () => {
  const logData = JSON.parse(localStorage.getItem('data'))
  mainServer.instance.defaults.headers.common['Authorization'] = logData.token
  let response;
  response = await mainServer.instance.get("/client/list");
  return response.data.data.clients
}



export const request_clientusers = async (id) => {
  const logData = JSON.parse(localStorage.getItem('data'));
  mainServer.instance.defaults.headers.common['Authorization'] = logData.token;
  let response;
  response = await mainServer.instance.get("/clientuser/listbyclient/"+id);
  return response.data.data.client_users;
}
