import { Grid } from '@material-ui/core';
import React, { useState, useEffect } from 'react';
import LoadingContainer from '../../../../../../components_v1/Structure/Layouts/LoadingContainer';
import { onGetCatalogs, onGetErrorMessage } from '../../../../../../shared/utility';
import BusinessInfoForm from './components/BusinessInfoForm';
import DocumentsCard from './components/DocumentsCards';
import ServicesCard from './components/ServicesCard';
import EditBusinessModal from '../../modals/EditBusinessModal';
import AddServiceModal from '../../modals/AddServiceModal';
import DeleteServiceModal from '../../modals/DeleteServiceModal';
import DocumentModal from '../../modals/DocumentModal';
import AssociatesCard from './components/AssociatesCard';
import { request_business, request_business_document, request_client_businesses, request_download_document } from '../../requests';
import AddAssociateModal from '../../modals/AddAssociateModal';
import DeleteAssociateModal from '../../modals/DeleteAssociateModal';
import DeleteDocumentModal from '../../modals/DeleteDocumentModal';
import PictureModal from './modals/PictureModal/PictureModal';
import ProcessCard from './components/ProcessCard'
import DeleteProcessModal from '../../modals/DeleteProcessModal';
import AddProcessModal from '../../modals/AddProcessModal';
import { request_employees } from '../../../../../Businesses/BusinessView/requests';

const BusinessGeneral = ({ history, match, services_permissions, module_permission }) => {

    const [business, setBusiness] = useState(null)
    const [business_documents, setBusinessDocuments] = useState(null)
    const [legal_representative, setLegalRepresentative] = useState(null)
    const [client_businesses, setClientBusinesses] = useState([])
    const [employees, setEmployees] = useState([])
    const [associates, setAssociates] = useState([])
    const [service_id, setServiceId] = useState(null)
    const [process_id, setProcessId] = useState(null)
    const [associate_id, setAssociatedId] = useState(null)
    const [selected_document, setSelectedDocument] = useState(null)
    const [loading, setLoading] = useState(false)
    const [sending, setSending] = useState(false)
    const [error, setError] = useState(null)

    ///////// MODAL'S STATES //////////////////////////////////
    const [business_modal, setBusinessModal] = useState(false)
    const [service_modal, setServiceModal] = useState(false)
    const [process_modal, setProcessModal] = useState(false)
    const [delete_service_modal, setDeleteServiceModal] = useState(false)
    const [delete_process_modal, setDeleteProcessModal] = useState(false)
    const [document_modal, setDocumentModal] = useState(false)
    const [delete_document_modal, setDeleteDocumentModal] = useState(false)
    const [associates_modal, setAssociatesModal] = useState(false)
    const [delete_associate_modal, setDeleteAssociateModal] = useState(false)
    const [profile_modal, setProfileModal] = useState(false)

    const businessID = match.params.idbusiness
    const clientID = match.params.id

    useEffect(() => {
        const initModule = async () => {
            try {
                setLoading(true)
                const _business = await request_business(businessID)
                const _business_document = await request_business_document(businessID)
                const _employees = await request_employees()
                setBusiness(_business)
                setBusinessDocuments(_business_document)
                setLegalRepresentative(_business_document.legal_representative_id)
                setEmployees(_employees)
            } catch (error) {
                console.log(error)
                setError('No fue posible cargar la información')
            } setLoading(false)
        }
        initModule()
    }, [])

    const onSelectedService = (service_id) => {
        history.push(`/clients/details/${business.client_id}/businesses/${business.id_business}/service/${service_id}`)
    }

    const onSelectedProcess = (process_type, process_id) => {
        history.push(`/clients/details/${business.client_id}/businesses/${business.id_business}/process/${process_type}`)
    }

    const onRemoveService = (service_id) => {
        setServiceId(service_id)
        setDeleteServiceModal(true)
    }

    const onRemoveProcess = (process_id) => {
        setProcessId(process_id)
        setDeleteProcessModal(true)
    }

    const onRemoveAssociate = (data) => {
        setAssociatedId(data)
        setDeleteAssociateModal(true)
    }

    const onEditBusiness = async () => {
        try {
            if (client_businesses.length === 0) {
                let _all_businesses = await request_client_businesses(business.client_id)
                _all_businesses = onGetCatalogs(_all_businesses, 'id_business', 'name')
                setClientBusinesses(_all_businesses)
            }
        } catch (error) { console.log(error) }
        setBusinessModal(true)
    }

    const onAddAssociates = async () => {
        try {
            if (client_businesses.length === 0) {
                let _all_businesses = await request_client_businesses(business.client_id)
                _all_businesses = onGetCatalogs(_all_businesses, 'id_business', 'name')
                setClientBusinesses(_all_businesses)
            }
        } catch (error) { console.log(error) }
        setAssociatesModal(true)
    }

    const onUpdateCompleted = async () => {
        try {
            const _business = await request_business(businessID)
            const _business_document = await request_business_document(businessID)
            setBusiness(_business)
            setBusinessDocuments(_business_document)
            setLegalRepresentative({ id: _business_document.legal_representative_id, name: _business_document.legal_representative })
        } catch (error) {
            console.log(error)
        }
        setBusinessModal(false)
        setServiceModal(false)
        setDeleteServiceModal(false)
        setDeleteProcessModal(false)
        setDocumentModal(false)
        setAssociatesModal(false)
        setProcessModal(false)
        setDeleteAssociateModal(false)
        setDeleteDocumentModal(false)
        setProfileModal(false)
    }

    const onUploadDocument = (data) => {
        setSelectedDocument(data)
        setDocumentModal(true)
    }

    const onDownloadDocument = async (data) => {
        try {
            await request_download_document(businessID, data.path, data.name)
        } catch (error) {
            console.log(error)
            setError(onGetErrorMessage(error))
        }
    }

    const onDeleteDocument = (data) => {
        setSelectedDocument(data)
        setDeleteDocumentModal(true)
    }

    return (
        <LoadingContainer loading={loading} error={error} onCloseError={() => setError(null)}>
            <div style={{ paddingTop: 32 }}>
                <PictureModal open={profile_modal} id={businessID} onUpdateCompleted={onUpdateCompleted}
                    onClose={() => setProfileModal(false)} />
                <AddAssociateModal id={businessID} open={associates_modal} onClose={() => setAssociatesModal(false)}
                    onUpdateCompleted={onUpdateCompleted} associates={business ? business.associates : []} businesses={client_businesses} />
                <AddProcessModal id={businessID} open={process_modal} onClose={() => setProcessModal(false)}
                    onUpdateCompleted={onUpdateCompleted} process={business ? business.processes : []} businesses={client_businesses} />
                <DocumentModal id={businessID} open={document_modal} document={selected_document} onClose={() => setDocumentModal(false)}
                    onUpdateCompleted={onUpdateCompleted} />
                <DeleteAssociateModal id={businessID} associate_id={associate_id} open={delete_associate_modal} onClose={() => setDeleteAssociateModal(false)} onUpdateCompleted={onUpdateCompleted} />
                <DeleteServiceModal id={businessID} service_id={service_id} open={delete_service_modal} onClose={() => setDeleteServiceModal(false)} onUpdateCompleted={onUpdateCompleted} />
                <DeleteProcessModal id={businessID} process_id={process_id} open={delete_process_modal} onClose={() => setDeleteProcessModal(false)} onUpdateCompleted={onUpdateCompleted} />
                <DeleteDocumentModal id={businessID} document={selected_document} open={delete_document_modal} onClose={() => setDeleteDocumentModal(false)} onUpdateCompleted={onUpdateCompleted} />
                <AddServiceModal id={businessID} open={service_modal} onClose={() => setServiceModal(false)}
                    current_services={business ? business.services : []} onUpdateCompleted={onUpdateCompleted} />
                <EditBusinessModal id={businessID} open={business_modal} onClose={() => setBusinessModal(false)} onUpdateCompleted={onUpdateCompleted}
                    init_data={business} legal_representative={legal_representative} client_businesses={client_businesses} 
                    employees={employees} />
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <BusinessInfoForm permission={module_permission} data={business} legal_representative={legal_representative} onEdit={onEditBusiness} onChangePicture={() => setProfileModal(true)} />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <DocumentsCard permission={module_permission} files={business_documents} onUpload={onUploadDocument} onDownload={onDownloadDocument} onDelete={onDeleteDocument} />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <ServicesCard permission={module_permission} services={business ? business.services : []} services_permissions={services_permissions}
                            onAddService={() => setServiceModal(true)} onSelected={onSelectedService} onDelete={onRemoveService} />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <AssociatesCard permission={module_permission} associates={business ? business.associates : []} businesses={client_businesses}
                            onAddAssociate={onAddAssociates} onSelected={onSelectedService} onDelete={onRemoveAssociate} />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <ProcessCard permission={module_permission} process={business ? business.processes : []}
                            onAddProcess={() => setProcessModal(true)} onSelected={onSelectedProcess} onDelete={onRemoveProcess} />
                    </Grid>
                </Grid>
            </div>
        </LoadingContainer>
    );
}

export default BusinessGeneral;