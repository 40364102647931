import React, { useState, useEffect } from 'react'
import { makeStyles, Grid } from '@material-ui/core'
import SimpleCard from '../../../../components_v1/Structure/Cards/SimpleCard'
import DisplayText from '../../../../components_v1/Texts/DisplayText'
import { catalogs } from '../../../../texts/esp/catalogs'
import GenericStatus from '../../../../components_v1/Structure/DisplayData/GenericStatus'
import { nodata } from '../../../../texts/esp/general'

const styles = makeStyles(theme => ({
    text: {
        color: theme.palette.primary.main,
        fontWeight: 500
    },
    percent: {
        padding: 12,
        borderRadius: 32,
        boxSizing: 'border-box',
        boxShadow: '0 10px 24px 0 rgba(82, 91, 115, .12)',
        //borderInline: `5px solid ${theme.palette.secondary.main}`,
        background: 'white'
    }
}))

const TopBanner = ({ data, path, step, content }) => {

    const classes = styles()

    const [percent, setPercent] = useState(0)
    const [advisory, setAdvisory] = useState('')

    useEffect(() => {
        console.log('STEP', step)
        let _percent = catalogs.process_percent[path].find(p => p.id === step)
        let _advisory = catalogs.process_responsibles[path].find(responsible => responsible.values.includes(step))
        setPercent(_percent)
        setAdvisory(_advisory)
    }, [step])

    return (
        <div>
            <Grid container spacing={4}>
                <Grid item xs={12} sm={3}>
                    <SimpleCard padding='small'>
                        {advisory ?
                            <DisplayText variant='body2' align='center' className={classes.text}>
                                <span style={{ color: '#4B92F8' }}>{advisory.label}</span> - {data ? data.fixed_responsible : ''}
                            </DisplayText>
                            :
                            <DisplayText variant='body2' align='center' className={classes.text}>
                                {nodata}
                            </DisplayText>
                        }
                    </SimpleCard>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <SimpleCard padding='small'>
                        {data && data.process_semaphore_id ?
                            <GenericStatus id={data.process_semaphore_id} variant='body2' align='center' options={catalogs.process_status} />
                            :
                            <DisplayText variant='body2' align='center' className={classes.text}>{nodata}</DisplayText>
                        }
                    </SimpleCard>
                </Grid>
                <Grid item xs={12} sm={3}>
                    <div className={classes.percent}>
                        <DisplayText variant='body2' align='center' className={classes.text}>
                            {percent && percent.value} {content.percent}
                        </DisplayText>
                    </div>
                </Grid>
            </Grid>
        </div>
    )
}

export default TopBanner