export const contentData = {
  spanish:{
    title:'Agregar usuario IDSE',
    editTitle:'Editar usuario IDSE',
    nodata:'Sin Registro',
    error:'Sin registro',
    button:'Guardar',
    cancelButton:'Cancelar',
    image_button:'Cambiar',
    form:{
      employer_enrollment:{
        label:'Registro Patronal',
        placeholder:'Registro Patronal',
      },
      user:{
        label:'Usuario IDSE',
        placeholder:'Usuario IDSE',
      },
      password:{
        label:'Contraseña IDSE',
        placeholder:'Contraseña IDSE',
      },
      imss_user:{
        label:'Usuario SIPARE',
        placeholder:'Usuario SIPARE',
      },
      imss_password:{
        label:'Contraseña SIPARE',
        placeholder:'Contraseña SIPARE',
      },
      virtual_desktop_user:{
        label:'Usuario Escritorio Virtual',
        placeholder:'Usuario Escritorio Virtual',
      },
      virtual_desktop_password:{
        label:'Contraseña Escritorio Virtual',
        placeholder:'Contraseña Escritorio Virtual',
      },
      imss_limit_date:{
        label:'Fecha Límite IMSS',
        placeholder:'Fecha Límite IMSS',
      },
      expiration_date:{
        label:'Fecha de Caducidad',
        placeholder:'Fecha de Caducidad',
      },
      risk_premium_id:{
        label:'Prima del riesgo de trabajo',
        placeholder:'Prima del riesgo de trabajo',
      },
      state_id:{
        label:'Estado',
        placeholder:'Estado',
      },
      city:{
        label:'Ciudad',
        placeholder:'Ciudad',
      },
      number_employees:{
        label:'Número de Trabajadores',
        placeholder:'Número de Trabajadores'
      },
      tax_payment_limit_date:{
        label:'Fecha límite de pago',
        placeholder:'1,2,3,4,25',
      },
    },
  },
}