import React from 'react'
import IntegralSolution from './views/IntegralSolutions/IntegralSolutions'
import PhysicalPersons from './views/PhysicalPersons/PhysicalPersons'
import SocialSecurity from './views/SocialSecurity/SocialSecurity'
import Stamping from './views/Stamping/Stamping'
import StateTax from './views/StateTax/StateTax'

const ProcessWrapper = ({ history, language, match }) => {

  const { id, idbusiness, idprocesstype } = match.params

  let contentView = null

  switch (idprocesstype) {
    case '1':
      contentView = <IntegralSolution history={history} language={language} id={idbusiness} clientID={id}/>
      break;
    case '2':
      contentView = <PhysicalPersons history={history} language={language} id={idbusiness} clientID={id}/>
      break;
    case '3':
      contentView = <SocialSecurity history={history} language={language} id={idbusiness} clientID={id}/>
      break;
    case '4':
      contentView = <StateTax history={history} language={language} id={idbusiness} clientID={id}/>
      break;
    case '5':
      contentView = <Stamping history={history} language={language} id={idbusiness} clientID={id}/>
      break;
  }

  return (
    <React.Fragment>
      {contentView}
    </React.Fragment>
  )
}

export default ProcessWrapper
