import React, { useEffect, useState } from 'react'
import { Grid, Card, makeStyles, Hidden } from '@material-ui/core'
import DisplayText from '../../../../../../components_v1/Texts/DisplayText'
import ThinShadedInputText from '../../../../../../components_v1/Forms/ThinShadedInputText';
import InputSelect from '../../../../../../components_v1/Forms/InputSelect';
import { onGetDateFormat, onGetErrorMessage, onGetUserID, onInitFormProcess, onUpdateDocumentListProcess } from '../../../../../../shared/utility';
import LoadingContainer from '../../../../../../components_v1/Structure/Layouts/LoadingContainer';
import RoundedButton from '../../../../../../components_v1/Actions/RoundedButton';
import { contentData } from './content'
import { catalogs } from '../../../../../../texts/esp/catalogs';
import { green, grey } from '@material-ui/core/colors';
import DocumentInput from '../../../../../../components_v1/Forms/DocumentInput';
import Responsibles from '../Responsibles';
import { request_process } from '../../requests';
import cx from 'classnames'
import DocumentModal from '../../modals/DocumentModal';

const styles = makeStyles(theme => ({
    banner: {
        padding: '0px 64px 64px 64px',
        [theme.breakpoints.down('sm')]: {
            padding: '0px 32px 32px 32px',
        },
        borderBottom: `1px solid ${grey[300]}`
    },
    banner2: {
        padding: '0px 64px 64px 64px',
        [theme.breakpoints.down('sm')]: {
            padding: '0px 32px 32px 32px',
        },
    },
    subAccordion: {
        backgroundColor: 'rgba(75, 146, 248, 0.15)',
        color: '#0F2358'
    },
    title: {
        fontSize: 18,
        fontWeight: 500
    },
    smallTitle: {
        fontSize: 14,
        fontWeight: 500,
        color: '#607D8B'
    },
    lineInput: {
        display: 'flex',
        justifyContent: 'flex-end',
        alignItems: 'center',
        height: '100%',
    },
    greenDiv: {
        backgroundColor: green[700],
        border: 0,
        borderRadius: '5px 0px 0px 5px',
        width: 150,
        padding: 5.5,
        [theme.breakpoints.only('xs')]: {
            width: '100%',
            borderRadius: 0
        }
    },
    greyLabel: {
        backgroundColor: '#F3F5F8',
        border: 0,
        borderRadius: '0px 5px 5px 0px',
        width: 150,
        minHeight: 20,
        padding: 5.5,
        [theme.breakpoints.only('xs')]: {
            width: '100%',
            borderRadius: 0
        }
    },
    declarations: {
        backgroundColor: '#F8FBFF',
        padding: 24
    },
    declarationsRow: {
        borderRadius: 24,
        display: 'flex',
        paddingTop: 24
    },
    declarationsTH: {
        width: '50%',
        backgroundColor: '#6D7CA4',
        borderRadius: '5px 0px 0px 5px',
        height: 'fit-content',
        color: 'white',
        padding: 8,
    },
    declarationsTD: {
        width: '25%',
        backgroundColor: '#EDF2F8',
        color: theme.palette.primary.dark,
        height: 'fit-content',
        textAlign: 'center',
        padding: 8
    },
    declarationsTDEnd: {
        width: '25%',
        backgroundColor: '#EDF2F8',
        color: theme.palette.primary.main,
        borderRadius: '0px 5px 5px 0px',
        height: 'fit-content',
        padding: 8,
        paddingRight: 64
    },
    declarationsTOTAL: {
        backgroundColor: theme.palette.primary.dark,
    },
}))

const SectionK = ({ id, ctrlID, onUpdate, onUpdateStep, language, permission, onDownloadDocument }) => {

    const step = 11
    const classes = styles()

    const [form, setForm] = useState(JSON.parse(JSON.stringify(formData)))
    const [files, setFiles] = useState(JSON.parse(JSON.stringify(filesData)))
    const [loading, setLoading] = useState(false)
    const [sending, setSending] = useState(false)
    const [success, setSuccess] = useState(null)
    const [error, setError] = useState(null)

    const [showCIEC, setShowCIEC] = useState(null)
    const [process, setProcess] = useState(null)
    const [selected_document, setSelectedDocument] = useState(null)
    const content = contentData[language]

    const [document_modal, setDocumentModal] = useState(false)

    useEffect(() => {
        const initModule = async () => {
            try {
                setLoading(true)
                const _process = await request_process(id, `?step_id=${step}`)
                setProcess(_process)
                const userID = onGetUserID()
                setShowCIEC(userID === _process.fixed_tax_responsible_id)

                let _form = JSON.parse(JSON.stringify(formData))
                _form = onInitFormProcess(_form, _process, step)
                _form.diot_presentation_limit_date.value = onGetDateFormat(_form.diot_presentation_limit_date.value)
                setForm(_form)
                let _files = JSON.parse(JSON.stringify(filesData))
                _files = onUpdateDocumentListProcess(_files, _process, step)
                setFiles(_files)
            } catch (error) {
                console.log(error)
                setError(onGetErrorMessage(error))
            }
            setLoading(false)
        }
        initModule()
    }, [])

    const onUploadDocument = (data) => {
        setSelectedDocument(data)
        setDocumentModal(true)
    }

    const onUpdateCompleted = async (newStep) => {
        try {
            setSending(true)
            if (newStep)
                await onUpdate(form, true, newStep, content.responsible_banner)
            else {
                let _process = await request_process(id, `?step_id=${step}`)
                let _files = JSON.parse(JSON.stringify(filesData))
                _files = onUpdateDocumentListProcess(_files, _process, step)
                setFiles(_files)
            }
            setDocumentModal(false)
            setSending(false)
            setSuccess(true)
        } catch (error) {
            console.log(error)
            setError(onGetErrorMessage(error))
        }
        setSending(false)
    }

    const onChange = (data) => {
        let temp = { ...form }
        const id = data.config.id
        temp[id] = { ...data }
        setForm(temp)
    }

    return (
        <LoadingContainer loading={loading} sending={sending} success={success} error={error} onCloseSuccess={() => setSuccess(false)} onCloseError={() => setError(false)}>
            <div>
                <DocumentModal id={id} idExt={ctrlID} step={step} open={document_modal} document={selected_document} onClose={() => setDocumentModal(false)} onUpdateCompleted={onUpdateCompleted} />
                <Grid container justify='center' alignItems='center' spacing={8}>
                    <Grid item xs={12}>
                        <div className={classes.banner2}>
                            <Grid container justify='space-between' spacing={2}>
                                <Grid item xs={12} sm={4}>
                                    <InputSelect
                                        className={classes.selectStatus}
                                        color='primary'
                                        data={form.diot_declaration_type_id}
                                        onChange={onChange}
                                        disabled={!permission}
                                    />
                                </Grid>
                                { //OCULTAR SI EL USUARIO NO ES RESPONSABLE DE IMPUESTOS
                                    showCIEC &&
                                    <Grid item xs={12} sm={6}>
                                        <Hidden smDown>
                                            <div className={classes.lineInput}>
                                                <div className={classes.greenDiv}>
                                                    <DisplayText variant='subtitle2' style={{ color: 'white' }} align='center'>
                                                        Clave CIEC
                                                    </DisplayText>
                                                </div>
                                                <div className={classes.greyLabel}>
                                                    <DisplayText variant='body2' align='center'>
                                                        {process && process[`${step}_ciec_key`]}
                                                    </DisplayText>
                                                </div>
                                            </div>
                                        </Hidden>
                                        <Hidden mdUp>
                                            <Grid container alignItems='center'>
                                                <Grid item xs={12}>
                                                    <div className={classes.greenDiv}>
                                                        <DisplayText variant='subtitle2' style={{ color: 'white' }} align='center'>
                                                            Clave CIEC
                                                        </DisplayText>
                                                    </div>
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <div className={classes.greyLabel}>
                                                        <DisplayText variant='body2' align='center'>
                                                            {process && process[`${step}_ciec_key`]}
                                                        </DisplayText>
                                                    </div>
                                                </Grid>
                                            </Grid>
                                        </Hidden>
                                    </Grid>
                                }
                            </Grid>
                        </div>
                    </Grid>
                    <Grid item xs={12}>
                        <div className={classes.banner2}>
                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                    <Grid container justify='center'>
                                        <Grid item xs={12}>
                                            <div className={classes.declarationsRow}>
                                                <div className={classes.declarationsTH}>
                                                    <DisplayText variant='body2' align='center' style={{ fontWeight: 500 }}>
                                                        {content.declarations[0].label}
                                                    </DisplayText>
                                                </div>
                                                <div className={classes.declarationsTDEnd} style={{ width: '50%' }}>
                                                    <DisplayText variant='body2' align='right' style={{ fontWeight: 500 }}>
                                                        <ThinShadedInputText
                                                            color='primary'
                                                            data={form[content.declarations[0].id]}
                                                            onChange={onChange}
                                                            disabled={!permission}
                                                        />
                                                    </DisplayText>
                                                </div>
                                            </div>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <div className={classes.declarationsRow}>
                                                <div className={cx({
                                                    [classes.declarationsTH]: true,
                                                    [classes.declarationsTOTAL]: true
                                                })}>
                                                    <DisplayText variant='body2' align='center' style={{ fontWeight: 500 }}>
                                                        {content.declarations[1].label}
                                                    </DisplayText>
                                                </div>
                                                <div className={classes.declarationsTDEnd} style={{ width: '50%' }}>
                                                    <ThinShadedInputText
                                                        color='primary'
                                                        data={form[content.declarations[1].id]}
                                                        onChange={onChange}
                                                        disabled={!permission}
                                                    />
                                                </div>
                                            </div>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </div>
                    </Grid>
                    <Grid item xs={12}>
                        <div className={classes.banner}>
                            <Grid container spacing={8}>
                                <Grid item xs={12}>
                                    <Grid container justify='center' spacing={4}>
                                        <Grid item>
                                            <DocumentInput
                                                data={files[0]}
                                                onUploadDocument={() => onUploadDocument(files[0])}
                                                onDownloadDocument={() => onDownloadDocument(files[0])}
                                                disabled={!permission}
                                                downloadEnabled
                                            />
                                        </Grid>
                                        <Grid item>
                                            <DocumentInput
                                                data={files[1]}
                                                onUploadDocument={() => onUploadDocument(files[1])}
                                                onDownloadDocument={() => onDownloadDocument(files[1])}
                                                disabled={!permission}
                                                downloadEnabled
                                            />
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </div>
                    </Grid>
                    <Grid item xs={12}>
                        <Responsibles data={process} step={step} language={language} responsible_banner={content.responsible_banner} />
                    </Grid>
                    <Grid item>
                        <RoundedButton disabled={!permission} color='blueGrey' onClick={() => onUpdateStep(step - 1, true)}>
                            {content.back}
                        </RoundedButton>
                    </Grid>
                    <Grid item>
                        <RoundedButton disabled={!permission} color='secondary' onClick={() => onUpdateCompleted(step + 1)}>
                            {content.button}
                        </RoundedButton>
                    </Grid>
                </Grid>
            </div>
        </LoadingContainer >
    )
}

const filesData = [
    { path: 'diot_file', name: 'DIOT Archivo', status: false, ctrlpath: true },
    { path: 'diot_receipt', name: 'DIOT Acuse', status: false, ctrlpath: true },
]

const formData = {
    diot_declaration_type_id: {
        value: null,
        error: false,
        isVisited: false,
        isRequired: false,
        isValid: false,
        ctrlpath: true,
        options: [...catalogs.ctrl_declaration_type],
        config: {
            id: 'diot_declaration_type_id',
            type: 'simple_select',
            fullWidth: true,
            label: 'Tipo de Declaración',
            helper: 'Selecciona una opción'
        },
        rules: {
            type: 'none',
        }
    },
    observations: {
        value: null,
        error: false,
        isVisited: false,
        isRequired: false,
        isValid: false,
        ctrlpath: true,
        config: {
            id: 'observations',
            type: 'text',
            fullWidth: true,
            label: 'Observaciones',
            helper: 'Introduce tus observaciones',
            multiline: true,
            rows: 6
        },
        rules: {
            type: 'distance',
            min: 1
        }
    },
    diot_folio: {
        value: null,
        error: false,
        isVisited: false,
        isRequired: false,
        isValid: false,
        ctrlpath: true,
        config: {
            id: 'diot_folio',
            type: 'text',
            fullWidth: true,
        },
        rules: {
            type: 'distance',
            min: 1
        }
    },
    diot_presentation_limit_date: {
        value: null,
        error: false,
        isVisited: false,
        isRequired: false,
        isValid: false,
        ctrlpath: true,
        config: {
            id: 'diot_presentation_limit_date',
            type: 'date',
            fullWidth: true,
        },
        rules: { type: 'date' }
    },
    /*payment_limit_date: {
        value: null,
        error: false,
        isVisited: false,
        isRequired: false,
        isValid: false,
        ctrlpath: true,
        config: {
            id: 'payment_limit_date',
            type: 'date',
            fullWidth: true,
        },
        rules: { type: 'date' }
    },*/
}

export default SectionK