import React, { useEffect, useState } from 'react'
import {contentData} from './content'
import BasicView from '../../../../../components/Layouts/BasicView/BasicView'
import WarningModal from  '../../../../../components/Modals/WarningModal'
import ServiceForm from '../../../../../components/Forms/ServiceForm/ServiceForm'
import FileManagerModal from '../../../../../components/Modals/FileManagerModal/FileManagerModal'
import FormAModal from '../../../../../components/Modals/FormAModal'
import {withRouter} from 'react-router' 

/////// IMPORT MODULES //////////
import {request_service, 
  request_updateService,
  request_deleteFile,
  request_downloadFile,
  request_uploadFile,
  request_business,
  request_business_documents
} from './requests'

import {formData as servicedata} from './servicedata/data' 
import {contentData as servicecontent} from './servicedata/content' 
import {catalog_matrix as servicematrix} from './servicedata/catalog_matrix'
import EditServiceModal from './modals/EditService/EditServiceModal'
import ServiceCard from '../../../../../components_v1/Forms/ServiceCard'
import DocumentModal from './modals/DocumentModal'
import DeleteDocumentModal from './modals/DeleteDocumentModal'

const LegalRepresentative = props => {

  const {language,businessID, catalogs, permission, permissionsType} = props

  const [loading, setLoading] = useState(false)
  const [sending, setSending] = useState(false)
  const [error, setError] = useState(false)
  const [success, setSuccess] = useState(false)

  //DATA
  const [pageSelected, setPageSelected] = useState(0)
  const [service, setService] = useState(false)
  const [business, setBusiness] = useState(null)
  const [business_documents, setBusinessDocuments] = useState(null)
  const [selected_document, setSelectedDocument] = useState(null)
  const [legalrepID, setLegalRepID] = useState(null)
  const content = contentData[language]

  //MODALES
  const [edititemmodal, setEditItemModal] = useState(false)
  const [documentmodal, setDocumentModal] = useState(false)
  const [deletedocumentmodal, setDeleteDocumentModal] = useState(false)
  const [warningmodal, setWariningModal] = useState(false)  


  useEffect(() => {
    const initModule = async() => {
      try{
        setLoading(true)
        let response = await request_service(businessID)
        setService({...response})
        setLegalRepID(response.legal_representative_id)
        const legalrepID = response.legal_representative_id
        response = await request_business(legalrepID)
        setBusiness([...response])
        response = await request_business_documents(legalrepID)
        setBusinessDocuments({...response})
        setLoading(false)
      }catch(e){
        console.log(e)
        if(e.response)
          setError(e.response.data.error.message)
        else
          setError('No fue posible cargar la información')
      }
      setLoading(false)
    }

    initModule()

  },[])

  const onEditService = async(data, type) => {
      try {
        setSending(true)
        let response = await request_updateService(businessID,legalrepID, data)
        setService({...response})
        setEditItemModal(false)  
        setSending(false)
        setSuccess(true)
      } catch (error) {
        console.log(error)
        if(error.response){
          setError(error.response.data.error.message)
        }else
          setError('No fue posible editar el servicio')
      }
      setSending(false)
    }

  const onUpdateCompleted = async() => {
    try {      
      setSending(true)      
      let response = await request_service(businessID)
      setService({...response})
      const legalrepID = response.legal_representative_id
      response = await request_business(legalrepID)
      setBusiness([...response])
      response = await request_business_documents(legalrepID)
      setBusinessDocuments({...response})
      setSending(false)
      setDocumentModal(false)
      setDeleteDocumentModal(false)
      setSuccess(true)
    } catch (error) {
      console.log(error)
    }
    setSending(false)
  }

  const onSubmitFile = async(filepath, file, data) => {
      try{
        setSending(true)
        const aux = file[0].name.split('.')
        const ext = {ext:aux[aux.length-1]}
        let response = await request_uploadFile(businessID,legalrepID, filepath, file[0], ext)
        setService({...response})
        response = await request_business_documents(legalrepID)
        setBusinessDocuments({...response})
        setDocumentModal(false)
        setSending(false)
        setSuccess(true)
      }catch(e){
        console.log(e)
        if(e.response){
          setError(e.response.data.error.message)
        }else
          setError('No fue posible cargar el archivo')
      }
      setSending(false)
    }

    const onUploadDocument = (data) => {
      setSelectedDocument(data)
      setDocumentModal(true)
    }

    const onDeleteDocument = (data) => {
      setSelectedDocument(data)
      setDeleteDocumentModal(true)
    }

    const onDownloadFile = async (filedata) => {
      try{
        setSending(true)
        await request_downloadFile(legalrepID,filedata.path)
        setSending(false)
      }catch(e){
        window.alert('File does not exist')
      }
      setSending(false)
    }

    const onDeleteFile = async (filedata) => {
      try{
        setSending(true)
        let response = await request_deleteFile(businessID, legalrepID,filedata.path)
        setService({...response})
        response = await request_business_documents(legalrepID)
        setBusinessDocuments({...response})
        setWariningModal(false)
        setSending(false)
        setSuccess(true)
      }catch(e){
        console.log(e)
        if(e.response){
          setError(e.response.data.error.message)
        }else
          setError('Hubo un error al intentar eliminar el archivo')
      }
      setSending(false)
    }

      return(
        <div>
          <DocumentModal id={businessID} legalRepID={legalrepID} open={documentmodal} document={selected_document} onClose={() => setDocumentModal(false)} onUpdateCompleted={onUpdateCompleted}/>
          <DeleteDocumentModal legalRepID={legalrepID} id={businessID} document={selected_document} open={deletedocumentmodal} onClose={() => setDeleteDocumentModal(false)} onUpdateCompleted={onUpdateCompleted}/>
          <EditServiceModal users={business} open={edititemmodal} origin_data={service} onClose={() => setEditItemModal(false)} language={language} onSubmit={onEditService}/>
          <ServiceCard
            button={content.button}
            staticview={content.basicform}
            service={service}
            business_documents={business_documents}            
            onEditData={() => setEditItemModal(true)}
            onUploadFile={onUploadDocument}
            onDownloadFile={onDownloadFile}
            onDeleteFile={onDeleteDocument}
            permission={permission}
            permissionsType={permissionsType}
          />
        </div>
      )
}

export default withRouter(LegalRepresentative)
