import React, { useState, useEffect } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Grid } from '@material-ui/core'
import { grey } from '@material-ui/core/colors'
import CardWrapper from '../../../../../components/Cards/CardWrapper/CardWrapper'
import { DataItemWrapper, PermissionItem, Subtitle, BenefitItem } from '../../../../../components/DisplayData/DisplayData'
import { RoundedButton } from '../../../../../components/Buttons/GeneralButtons'
import ProfileAvatar from '../../../../../components/Avatars/ProfileAvatar'
import Permissions from './Permissions/Permissions'
import { formData } from './data'
import { contentData } from './content'
import { onGetErrorMessage, onGetFormatNumber } from '../../../../../shared/utility'
import { request_user_info, request_user_permissions, request_downloadFile, request_deleteFile, request_update_user } from './requests'
import DocumentModal from './modals/DocumentModal'
import LoadingContainer from '../../../../../components_v1/Structure/Layouts/LoadingContainer'
import DocumentsCards from './components/DocumentsCards'
import ChangePasswordModal from './modals/ChangePasswordModal'
import InfoForm from './components/InfoForm'
import WorkingInfoForm from './components/WorkingInfoForm'
import ContactInfoForm from './components/ContactInfoForm'
import BenefitContent from './components/BenefitContent'
import PermissionContent from './components/PermissionsContent/PermissionContent'

const styles = makeStyles(theme => ({
  root: { padding: '32px' },
  permissions: { padding: '32px', maxHeight: 600 },
  subtitle: { color: theme.palette.primary.main, fontWeight: '500' },
  nospeciality: { color: grey[700], fontWeight: '500' }
}))

const GeneralView = props => {

  const classes = styles()
  const { history, language, catalogs, permissions, permissionsType, translations } = props
  const content = contentData[language]

  const [loading, setLoading] = useState(false)
  const [sending, setSending] = useState(false)
  const [success, setSuccess] = useState(false)
  const [error, setError] = useState(false)

  const [user, setUser] = useState(null)
  const [user_permissions, setUserPermissions] = useState([])
  const [selected_document, setSelectedDocument] = useState(null)

  //MODALS
  const [changepasswordmodal, setChangePasswordModal] = useState(false)
  const [documentmodal, setDocumentModal] = useState(false)
  const [deletefilemodal, setDeleteFileModal] = useState(false)

  useEffect(() => {
    const initModule = async () => {
      try {
        setLoading(true)
        let response = await request_user_info()
        setUser({ ...response })
        response = await request_user_permissions()
        setUserPermissions([...response])
      } catch (error) {
        console.log(error)
        setError(onGetErrorMessage(error))
      }
      setLoading(false)
    }
    initModule()
  }, [])

  const onUpdateCompleted = async () => {
    try {
      setSending(true)
      let response = await request_user_info()
      setUser({ ...response })
      setSending(false)
      //setProfileModal(false)
      setDocumentModal(false)
      setSuccess(true)
    } catch (error) {
      console.log(error)
      setError(onGetErrorMessage(error))
    }
    setSending(false)
  }

  const onUploadDocument = (data) => {
    setSelectedDocument(data)
    setDocumentModal(true)
  }

  const onDownloadDocument = async (filedata) => {
    try {
      setSending(true)
      const path = history.location.pathname
      const userID = path.substring(path.lastIndexOf('/') + 1)
      await request_downloadFile(filedata.path)
    } catch (error) {
      console.log(error)
      setError(onGetErrorMessage(error))
    }
    setSending(false)
  }

  const onDeleteDocument = async (filedata) => {
    try {
      setSending(true)
      const path = history.location.pathname
      const userID = path.substring(path.lastIndexOf('/') + 1)
      let response = await request_deleteFile(filedata.path)
      setUser({ ...response })
      setSending(false)
      setDeleteFileModal(false)
      setSuccess(true)
    } catch (error) {
      console.log(error)
      setError(onGetErrorMessage(error))
    }
    setSending(false)
  }

  const onChangePassword = async (data) => {
    try {
      setSending(true)
      let response = await request_update_user(data)
      setUser({ ...response })
      setSending(false)
      setSuccess(true)
      setChangePasswordModal(false)
    } catch (error) {
      console.log(error)
    }
    setSending(false)
  }

  const updateForm = (data, user, content) => {
    let form = {}
    Object.keys(data).forEach(item => {
      let value = null
      if (user) {
        value = user[item]
        if (data[item].translate) {
          value = translations[value]
        }
        if (item === 'gross_salary' || item === 'net_salary') {
          value = onGetFormatNumber(value)
        }
        if (item === 'mobile') {
          let aux = user.phone_code ? user.phone_code : ''
          value = aux + ' ' + value
        }
        //if(item === 'entrance_hour' || 'exit_hour'){
        //  value = value
        //}
        if (item === 'contract_duration') {
          if (value && user.contract_duration_type) {
            value = value.toString() + ' ' + user.contract_duration_type
          }
        }
      }
      const aux = { ...data[item], ...content[item], data: value }
      form = { ...form, [item]: { ...aux } }
    })
    return form
  }

  let formB = { ...formData.sectionB }
  let formC = { ...formData.sectionC }

  let contentA = null
  let contentB = null
  let contentC = null
  let benefitContent = null
  let permissionContent = null
  let avatarContent = null

  formB = updateForm(formB, user, content.sectionB)
  formC = updateForm(formC, user, content.sectionC)

  contentB = Object.keys(formB).map((item, key) => {
    return (
      <Grid item xs={12} md={6} key={key.toString()}>
        <DataItemWrapper
          label={formB[item].label}
          data={formB[item].data}
          error={content.error}
          icon={formB[item].icon}
        />
      </Grid>
    )
  })
  contentC = Object.keys(formC).map((item, key) => {
    return (
      <Grid item xs={12} md={6} key={key.toString()}>
        <DataItemWrapper
          label={formC[item].label}
          data={formC[item].data}
          error={content.error}
          icon={formC[item].icon}
        />
      </Grid>
    )
  })

  if (user) {
    benefitContent = user.benefits.map((item, key) => {
      return (
        <Grid item xs={12} key={key.toString()}>
          <BenefitItem data={item.benefit} status={item.status} />
        </Grid>
      )
    })
    permissionContent = user.permissions.map((item, key) => {
      return (
        <Grid item xs={12} key={key.toString()}>
          <PermissionItem
            module_name={item.module}
            permission={item.permission}
            permission_id={item.permission_id}
          />
        </Grid>
      )
    })
  }

  avatarContent = <ProfileAvatar image={user ? user.image : null} />

  return (
    <LoadingContainer loading={loading} sending={sending} error={error} success={success} onCloseError={() => setError(false)}>
      <div>
        <ChangePasswordModal open={changepasswordmodal} onSubmit={onChangePassword} onClose={() => setChangePasswordModal(false)} language={language} content={content.modals.change_password} />
        <DocumentModal open={documentmodal} document={selected_document} onClose={() => setDocumentModal(false)}
          onUpdateCompleted={onUpdateCompleted} />
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Grid container spacing={3} justify='flex-end'>
              <Grid item>
                <RoundedButton label='Cambiar Contraseña' size='small' color='secondary' onClick={() => setChangePasswordModal(true)} />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <InfoForm data={user} />
          </Grid>
          <Grid item xs={12} lg={6}>
            <Grid container spacing={4}>
              <Grid item xs={12}>
                <WorkingInfoForm title={content.title.sectionB} data={user} />
              </Grid>
              <Grid item xs={12}>
                <ContactInfoForm title={content.title.sectionC} data={user} />
              </Grid>
              <Grid item xs={12}>
                <DocumentsCards
                  title={content.title.sectionD}
                  files={user}
                  onUpload={onUploadDocument}
                  onDownload={onDownloadDocument}
                  onDelete={onDeleteDocument}
                />
              </Grid>
            </Grid>
          </Grid>
          {/* *********** THIRD SECTION ***************/}
          <Grid item xs={12} lg={6}>
            <Grid container spacing={4}>
              <Grid item xs={12} md={12}>
                <BenefitContent title={content.title.sectionE} benefits={user ? user.benefits : null} />
              </Grid>
              <Grid item xs={12}>
                <PermissionContent title={content.title.sectionF} permissions={user_permissions} language={language} />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </div>
    </LoadingContainer>
  )
}

export default GeneralView
