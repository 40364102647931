export const contentData = {
  spanish:{
    nodata:'Sin Registro',
    error:'Sin registro',
    save_button:'Guardar',
    image_button:'Cambiar',
    title:'',
    sectionA:{
      name:{
        label:'Nombre completo',
        placeholder:'Nombre completo',
      },
      pre_position_id:{
        label:'Puesto',
        placeholder:'Puesto',
      },
      pre_language_id:{
        label:'Idioma',
        placeholder:'Idioma',
      },
      pre_area_id:{
        label:'Área',
        placeholder:'Área',
      },
      pre_country_id:{
        label:'País',
        placeholder:'País',
      },
      client_status_id:{
        label:'Estatus',
        placeholder:'Estatus',
      },
      pre_responsible_id:{
        label:'Responsable',
        placeholder:'Responsable',
      },
    },
  },
}