import React, {Component} from 'react'
import { Redirect } from 'react-router'
import {contentData} from './content'
import BasicView from '../../../../components/Layouts/BasicView/BasicView'
import ServiceModule from './ServiceModule/ServiceModule';
import BankAccounts from '../Modules/BankAccounts/BankAccounts'
import {request_bank_account} from "../Audit/requests";
import Logs from '../Modules/Logs/Logs';

class Audit extends Component {
  state = {
    isloading:false,
    pageSelected:0,
    bankaccount: [],
    modals:{
      filemodal:{open:false,issending:false,error:null,extra:null},
      edit_service:{open:false,issending:false,error:null},
      warning_delete_file:{open:false, issending:false, error:null, id:null}
    },
    success:{open:false,timeout:2000,type:'success',message:'Successful action!'},
  }

  componentDidMount() {
    this.initModule();
  }

  onReturn = () => {
    this.props.history.goBack()
  }

  initModule = async() => {
    try{
      const {businessID} = this.props;
      this.setState({isloading:true});
      let response = await request_bank_account(businessID);
      this.setState({bankaccount:{...response}});
      this.setState({isloading:false});
    }catch(e){
      console.log(e);
    }
  }

  onChangeView = (id) => {
    this.setState({pageSelected:id});
  }

  render(){

    const {history, language, catalogs, businessID, 
          permission, permissionsType} = this.props
    const {isloading, pageSelected,success} = this.state
    const content = contentData[language]
    let contentView = null

    switch(pageSelected){
      case 0:
        contentView = (
            <ServiceModule
                history={history}
                language={language}
                catalogs={catalogs}
                businessID={businessID}
                permission={permission}
                permissionsType={permissionsType}
            />
        )
        break
      case 1:
        contentView = (
            <BankAccounts
                history={history}
                language={language}
                catalogs={catalogs}
                businessID={businessID}
                permission={permission}
                permissionsType={permissionsType}
            />
        )
        break
      case 2:
        contentView = (
            <Logs
              history={history}
              language={language}
              catalogs={catalogs}
              businessID={businessID}
              service={'ctrlaudit'}
              ctrlTableIdLabel={'id_ctrl_imss_inscription'}
            />
        )
        break
      default:
        break
    }

    return(
      <BasicView
        history={history}
        selected={pageSelected}
        content={content.layout}
        onChange={this.onChangeView}
        isloading={isloading}
        returnBtn={true}
        onReturn={this.onReturn}
      >
        {permission === 1 ? (
          <Redirect to='/profile'/>
        ) : null}
        <div>
          {contentView}
        </div>

      </BasicView>
    )
  }
}

export default Audit
