import {mainServer} from '../../../../../variables/config';
import FileSaver from 'file-saver';
import axios from 'axios';

let params = {offset:0, completed:0};

export const request_users = async () => {
    const logData = JSON.parse(localStorage.getItem('data'));
    mainServer.instance.defaults.headers.common['Authorization'] = logData.token;
    let response;
    response = await mainServer.instance.get("/user/employee/list/");
    return response.data.data.employees
}

export const request_control_table = async(completed) =>{
    const logData = JSON.parse(localStorage.getItem('data'));
    mainServer.instance.defaults.headers.common['Authorization'] = logData.token;
    let response;
    params.completed = completed
    response = await mainServer.instance.get("/ctrllegal/all",{params:params});
    return response.data.data.control_table_legal_data;
}

export const request_update_control_table = async(id, data, filterBy) => {
    const logData = JSON.parse(localStorage.getItem('data'));
    mainServer.instance.defaults.headers.common['Authorization'] = logData.token;
    await mainServer.instance.patch("/ctrllegal/" + id, data);
    let response;
    if(filterBy)
        response = await mainServer.instance.get("/ctrllegal/betweenmonths",{params:params});
    else
        response = await mainServer.instance.get("/ctrllegal/month",{params:params});
    return response.data.data.control_table_legal_data;
}

export const request_control_table_by_month = async(completed, month) =>{
    const logData = JSON.parse(localStorage.getItem('data'));
    mainServer.instance.defaults.headers.common['Authorization'] = logData.token;
    let response;
    params = {completed: completed, month: month}
    response = await mainServer.instance.get("/ctrllegal/month",{params:params});
    return response.data.data.control_table_legal_data;
}

export const request_control_table_by_range = async(completed, initDate, finalDate) =>{
    const logData = JSON.parse(localStorage.getItem('data'));
    mainServer.instance.defaults.headers.common['Authorization'] = logData.token;
    let response;
    params = {completed: completed, start_month: initDate, end_month: finalDate}
    response = await mainServer.instance.get("/ctrllegal/betweenmonths",{params:params});
    return response.data.data.control_table_legal_data;
}

export const request_uploadFile = async(id,path,file, ext) => {
    const logData = JSON.parse(localStorage.getItem('data'));
    mainServer.instance.defaults.headers.common['Authorization'] = logData.token;
    const url = 'ctrllegal/' + id + '/' + path
    let response = await mainServer.instance.post(url, ext)
    const options = {headers: {'Content-Type': file.type}}
    delete axios.defaults.headers.common["Authorization"]
    response = await axios.put(response.data.data.path, file, options);
    mainServer.instance.defaults.headers.common['Authorization'] = logData.token;
    response = await mainServer.instance.get("/ctrllegal/all",{params:params});
    return response.data.data.control_table_legal_data;
}

export const request_downloadFile = async(id,path) => {
    const logData = JSON.parse(localStorage.getItem('data'));
    mainServer.instance.defaults.headers.common['Authorization'] = logData.token;
    let response;
    const url = '/ctrllegal/' + id + '/' + path;
    response = await mainServer.instance.get(url)
    delete axios.defaults.headers.common["Authorization"]
    response = await axios.get(response.data.data.path, {responseType: 'blob'});
    FileSaver.saveAs(response.data, path);
}
