export const contentData = {
  spanish:{
    title:'Agregar usuario',
    nodata:'Sin Registro',
    error:'Sin registro',
    button:'Guardar',
    cancelButton:'Cancelar',
    image_button:'Cambiar',
    form:{
      user:{
        label:'Usuario',
        placeholder:'Usuario',
      },
      password:{
        label:'Contraseña',
        placeholder:'Contraseña',
      },
      token:{
        label:'Token',
        placeholder:'Token',
      },
      token_type_id:{
        label:'Tipo de token',
        placeholder:'Tipo de token',
      },
      responsible_id:{
        label:'Responsable',
        placeholder:'Responsable',
      },
      notes:{
        label:'Notas',
        placeholder:'Notas',
      },
    },
  },
}