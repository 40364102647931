import React, { useRef, useState } from 'react'
import { Badge, Button, Grid, Icon, IconButton, LinearProgress, makeStyles, Menu, MenuItem, Paper, Table, TableBody, TableCell, TableHead, TableRow, Typography } from '@material-ui/core'
import { blueGrey, grey } from '@material-ui/core/colors'
import SearchBar from './SearchBar'
import ActionBar from './ActionBar'
import empty from '../../../../../../assets/empty.png'
import { PERMISSION_TYPES } from '../../../../../../variables/config'
import DisplayText from '../../../../../../components_v1/Texts/DisplayText'
import { onGetDateFormat } from '../../../../../../shared/utility'
import AvatarName from '../../../../../../components_v1/Structure/DisplayData/AvatarName'
import IconData from '../../../../../../components_v1/Structure/DisplayData/IconData'
import cx from 'classnames'

const header = [
    {id:1, label:'ID'},
    {id:5, label:'Certificado'},
    {id:2, label:'Nombre'},
    {id:3, label:'Acción'},
    {id:4, label:'Fecha y hora'},
]


const useStyles = makeStyles(theme => ({
    root:{},
    addIcon:{
        background:theme.palette.secondary.main,
        //borderRadius:'50%',
        color:'white',
        '&:hover':{
            background:theme.palette.secondary.dark,
        }
    },
    paper:{
        marginTop:4,
        borderRadius:8,
        boxShadow:'0 10px 24px 0 rgba(82, 91, 115, .12)',
    },
    cell:{
        borderColor:grey[200],
    },
    row:{
        '&:hover':{
            cursor:'pointer',
            background:grey[100]
        }
    },
    cellHeader:{
        borderColor:grey[300],
        paddingTop:16,
        paddingBottom:16
    },
    button:{
        background:theme.palette.primary.main,
        padding:'6px 12px',
        borderRadius:32,
        color:'white',
        '&:hover':{
            background:theme.palette.primary.dark,
        }
    },
    toolbar:{
        marginBottom:16
    },
    actions:{
        marginTop:16
    },
    table:{
        minWidth:1100
    },
    tableWrapper:{
        overflowX: 'auto',
    },
    nodata:{
        marginTop:40
    }
}))

const SimpleTable = props => {

    const classes = useStyles()
    const {data, search, onChangeSearch, total, tableFilter, onUpdateTableFilter, onSelectedItem, loading, 
        permission,onRemoveItem,onAddRegister, filterOptions, onChangeTableFilter, onChangeFilter, filter} = props

    let can_edit = true
    let can_create = true
    let has_full_access = true
    if(permission){
        //can_edit = permission.id_permission_type > PERMISSION_TYPES.READ
        can_create = permission.permission_type_id > PERMISSION_TYPES.EDIT
        has_full_access = permission.permission_type_id > PERMISSION_TYPES.CREATE
    }

    return(
        <div>
            <div className={classes.toolbar}>
                <Grid container spacing={2} > 
                    <Grid item xs={12}>
                        <Grid container wrap='nowrap' spacing={2} alignItems='center'>
                            <Grid item xs/>
                            <Grid item><FilterMenu filterOptions={filterOptions} onChangeFilter={onChangeFilter} filter={filter} /></Grid>
                            <Grid item><SearchBar value={search} onChange={onChangeSearch}/></Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </div>
            {loading ? (
                <Grid container justify='center'>
                    <Grid item xs={12}><LinearProgress /></Grid>
                </Grid>
            ) : null}
            <Paper className={classes.paper}>
                {data && data.length ? (
                    <div className={classes.tableWrapper}>
                        <Table className={classes.table}>
                            {header ? (
                                <TableHead>
                                    <TableRow>
                                        {header.map(item=>{
                                            return(
                                                <TableCell padding={item.id === 1 ? 'default' : 'default'} className={classes.cellHeader} key={item.id.toString()}>
                                                    <DisplayText variant='subtitle2' >{item.label}</DisplayText>
                                                </TableCell>
                                            )
                                        })}
                                    </TableRow>
                                </TableHead>
                            ):null}
                            <TableBody>
                                {data.map((item,key) => {

                                    const {id_certificate_action, user_name, user_image, certificate_action_type, certificate_action_type_id, date, certificate} = item

                                    let icon = ""
                                    let color = ""
                                    if (certificate_action_type_id === 1 || certificate_action_type_id === 4) {
                                        icon = 'cloud_upload'
                                        color = '#133975'
                                    }
                                    if (certificate_action_type_id === 2 || certificate_action_type_id === 5) {
                                        icon = 'cloud_download'
                                        color = '#1F77F7'
                                    }
                                    if (certificate_action_type_id === 3 || certificate_action_type_id === 6) {
                                        icon = 'clear'
                                        color = '#d32f2f'
                                    }

                                    return(
                                        <TableRow key={key.toString()} className={classes.row} >
                                            <TableCell className={classes.cell} onClick={() => onSelectedItem(item)}><DisplayText variant='body2'>{id_certificate_action}</DisplayText></TableCell>
                                            <TableCell className={classes.cell} onClick={() => onSelectedItem(item)}><DisplayText variant='body2'>{certificate}</DisplayText></TableCell>
                                            <TableCell className={classes.cell} onClick={() => onSelectedItem(item)}><AvatarName name={user_name} imgSrc={user_image} /></TableCell>
                                            <TableCell className={classes.cell} onClick={() => onSelectedItem(item)}><IconData data={certificate_action_type} icon={icon} color={color} /></TableCell>
                                            <TableCell className={classes.cell} onClick={() => onSelectedItem(item)}><DisplayText variant='body2'>{onGetDateFormat(date, 'DD-MM-YYYY hh:mm')+ `${date ? ' hrs' : ''}`}</DisplayText></TableCell>
                                            {/* <TableCell className={classes.cell} onClick={() => onSelectedItem(item)}><DisplayText variant='body2'>{onGetDateFormat(expiration_date, 'DD MMM YYYY')}</DisplayText></TableCell>
                                            {has_full_access || !has_full_access ?
                                                <TableCell className={classes.cell} padding='checkbox' onClick={() => onRemoveItem(item)}><IconButton size='small'><Icon>delete</Icon></IconButton></TableCell>
                                            : null} */}
                                        </TableRow>
                                    )
                                })}
                                
                            </TableBody>
                        </Table>
                    </div>
                    
                ): null}
            </Paper>
            {data && data.length ? <div className={classes.actions}>
                <ActionBar total={total} tableFilter={tableFilter} onUpdateTableFilter={onUpdateTableFilter}/>
            </div> : null}
            {data.length === 0 && !loading ? (
                <div className={classes.nodata}>
                    <Grid container justify='center' alignItems='center' direction='column'>
                        <Grid item>
                            <img src={empty} alt='' width={180}/>
                        </Grid>
                        <Grid item>
                            <DisplayText>No se encontraron registros</DisplayText>
                        </Grid>
                    </Grid>
                </div>
                
            ) : null}
            
        </div>
    )
}

export default SimpleTable


const useButtonStyles = makeStyles((theme) => ({
	root: { position: 'relative' },
	button: {
		background: blueGrey[100],
		color: blueGrey[700],
		borderRadius: 8,
		padding: '8px 16px',
		'&:hover': { background: blueGrey[200] },
		textTransform: 'none',
	},
	selected: {
		color: 'white',
		background: theme.palette.primary.dark,
		fontWeight: 500,
		boxShadow: '0 10px 24px 0 rgba(82, 91, 115, .12)',
		'&:hover': { background: theme.palette.secondary.dark },
		//border:`1px solid ${theme.palette.primary.main}`
	},
	buttonFilter: {
		background: blueGrey[100],
		color: blueGrey[700],
        borderRadius: '0px 8px 8px 0px',
		padding: '7px 16px',
		'&:hover': { background: blueGrey[200] },
		textTransform: 'none',
	},
	selectedFilter: {
		color: 'white',
		background: theme.palette.primary.dark,
		fontWeight: 500,
		boxShadow: '0 10px 24px 0 rgba(82, 91, 115, .12)',
		'&:hover': { background: theme.palette.secondary.dark },
		borderRadius: '8px',
	},
}));

const CustomButton = (props) => {
	const classes = useButtonStyles();
	const { children, selected, onClick, number } = props;

	return (
		<div className={classes.root}>
			<Badge badgeContent={number} color='error'>
				<Button
					onClick={onClick}
					className={cx({
						[classes.button]: true,
						[classes.selected]: selected,
					})}
				>
					{children}
				</Button>
			</Badge>
		</div>
	);
};

const FilterMenu = ({ filterOptions, filter, onChangeFilter }) => {

	const selected = filterOptions.find((item) => item.value === filter.value);

	const [open, setOpen] = useState(false);
    const anchorRef = useRef(null)

    const classes = useButtonStyles()

	const onSelect = (id) => {
	
		setOpen(false);
		onChangeFilter(id);
	};

	/* const onChangeOrder = (prevOrder) => {
		let _tableFilter = {...tableFilter};
		_tableFilter.order = prevOrder === 1 ? 0 : 1;
		setOpen(false);
		onChangeTableFilter(_tableFilter);
	};

    let iconOrder = tableFilter.order === 1 ? <Icon>keyboard_arrow_down</Icon> : <Icon>keyboard_arrow_up</Icon> */

    /* if(selected.revert)
        iconOrder = tableFilter.order === 1 ? <Icon>keyboard_arrow_up</Icon> : <Icon>keyboard_arrow_down</Icon> */

	return (
		<Grid container alignItems='center'>
			<Grid item>
				<Typography style={{marginRight: 15, fontWeight: 500}}>Filtrar por: </Typography>
			</Grid>
			<Grid item>
				<Button className={classes.selectedFilter} ref={anchorRef} aria-controls='filter-menu' aria-haspopup='true' onClick={() => setOpen(!open)}
                endIcon={<Icon>keyboard_arrow_down</Icon>}>
					{selected.label}
				</Button>
				{/* <Button className={classes.buttonFilter} onClick={() => onChangeOrder(tableFilter.order)}>
                    {iconOrder}
				</Button> */}
				<Menu anchorEl={anchorRef.current} id='simple-menu' keepMounted open={open} onClose={() => setOpen(false)}>
					{filterOptions.map((item) => {
						return [
							<MenuItem onClick={() => onSelect(item)}>
								{item.label}
							</MenuItem>,
						];
					})}
				</Menu>
			</Grid>
		</Grid>
	);
};