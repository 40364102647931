import React from 'react'
import {grey, green, cyan, blue, deepPurple,red} from '@material-ui/core/colors'
import { Grid, Icon, Typography } from '@material-ui/core'


const PermissionItem = (props) => {
    const {module_name, permission_id, permission} = props
  
    let color = null
    switch(permission_id){
      case 5:
        color=green[700]
        break
      case 4:
        color=cyan[700]
        break;
      case 3:
        color=blue[700]
        break;
      case 2:
        color=deepPurple[700]
        break;
      case 1:
        color=red[700]
        break;
      default:
        color=grey[500]
    }
  
    return(
      <div>
        <Grid container spacing={6} alignItems='center'>
          <Grid item xs>
            <Typography variant='body2' style={styles_permissions.text}>
              {module_name}
            </Typography>
          </Grid>
          <Grid item>
            <div>
              <Grid container spacing={1} alignItems='center'>
                <Grid item>
                  <Typography variant='caption' style={{color:grey[700]}}>
                    {permission}
                  </Typography>
                </Grid>
                <Grid item>
                  <Icon style={{...styles_permissions.icon, color:color}}>brightness_1</Icon>
                </Grid>
              </Grid>
            </div>
          </Grid>
        </Grid>
      </div>
    )
  }

  export default PermissionItem

  const styles_permissions = {
    text:{
      color:grey[700],
      fontWeight:'500'
    },
    icon:{
      fontSize:12
    }
  
  }