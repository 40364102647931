import { catalogs } from "../../../texts/esp/catalogs";

export const content = {
    header:[
        {id:0,label:'',hidden:false, fixed:true, width:26, position:0, padding:'checkbox'},
        {id:1,key:'fixed_businesses_name',label:'Razón Social',hidden:false, width:300, fixed:true, add:{icon:'add'}, position:34},
        {id:2,key:'fixed_rfc',label:'RFC',hidden:false},
        {id:3,key:'fixed_client_classification',label:'Clasificación',hidden:false},
        {id:4,key:'fixed_client_status_id',label:'Estatus',hidden:false},
        {id:5,key:'fixed_client_responsible',label:'Asesor',hidden:false},
        
        {id:6,key:'fixed_efirma_expiration_date',label:'Fecha de Expiración',hidden:false,add:{icon:'add'}},
        {id:15,key:'fixed_efirma_name',label:'Nombre',hidden:false},
        {id:7,key:'fixed_efirma_password',label:'Contraseña',hidden:false},
        {id:8,label:'Archvios adjuntos',hidden:false},
        {id:9,label:'e.firma .key',hidden:false},
        {id:10,label:'e.firma .cer',hidden:false},
        
        {id:11,label:'Autorización de envío e.firma(doc)',hidden:false},
        {id:11,key:'responsible',label:'Responsable',hidden:false},
        {id:12,key:'notes',label:'Notas',hidden:false},
        {id:13,key:'completition_date',label:'Fecha de completado',hidden:false},
        {id:14,label:'Completado',hidden:false},
    ],
    dataConfig:[
        {id:'fixed_businesses_name',type:'text',fixed:true, editBtn:true, position:34},
        {id:'fixed_rfc',type:'text'},
        {id:'fixed_client_classification',type:'text'},
        {id:'fixed_client_status_id',type:'status', options: [...catalogs.client_statuses] },
        {id:'fixed_client_responsible',type:'text'},
        ///////// second fixed data
        {id:'fixed_efirma_expiration_date',type:'date', expiration_date:true},
        {id:'fixed_efirma_name',type:'text'},
        {id:'fixed_efirma_password',type:'text'},
        {id:'fixed_efirma_documents',type:'document',readOnly:true, efirmaDoc:true},
        {id:'fixed_efirma_key',type:'document',readOnly:true,bussinesDocument:true},
        {id:'fixed_efirma_cer', type:'document',readOnly:true,bussinesDocument:true},
        // Real data
        {id:'charge_proof',type:'document'},
        {id:'responsible', formId:'responsible_id',type:'text',editable:true},
        {id:'notes',type:'text',editable:true},
        {id:'completition_date',type:'date', variant:'date',editable:true},
        {id:'completed',type:'checkbox',editable:true},
    ]
}