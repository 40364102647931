import React, { useEffect, useState } from 'react'
import { Grid, Card, makeStyles, Hidden } from '@material-ui/core'
import DisplayText from '../../../../../../components_v1/Texts/DisplayText'
import ThinShadedInputText from '../../../../../../components_v1/Forms/ThinShadedInputText';
import ShadedInputText from '../../../../../../components_v1/Forms/ShadedInputText'
import InputSelect from '../../../../../../components_v1/Forms/InputSelect';
import InputSelectStatus from '../../../../../../components_v1/Forms/InputSelectStatus';
import { onGetDateFormat, onGetErrorMessage, onInitFormProcess } from '../../../../../../shared/utility';
import LoadingContainer from '../../../../../../components_v1/Structure/Layouts/LoadingContainer';
import RoundedButton from '../../../../../../components_v1/Actions/RoundedButton';
import { contentData } from './content'
import { catalogs } from '../../../../../../texts/esp/catalogs';
import { green, grey } from '@material-ui/core/colors';
import Responsibles from '../Responsibles';
import { request_process } from '../../requests';

const styles = makeStyles(theme => ({
    banner: {
        padding: '0px 64px 64px 64px',
        [theme.breakpoints.down('sm')]: {
            padding: '0px 32px 32px 32px',
        },
        borderBottom: `1px solid ${grey[300]}`
    },
    banner2: {
        padding: '0px 64px 64px 64px',
        [theme.breakpoints.down('sm')]: {
            padding: '0px 32px 32px 32px',
        },
    },
    subAccordion: {
        backgroundColor: 'rgba(75, 146, 248, 0.15)',
        color: '#0F2358'
    },
    title: {
        fontSize: 18,
        fontWeight: 500
    },
    smallTitle: {
        fontSize: 14,
        fontWeight: 500,
        color: '#607D8B'
    },
    lineInput: {
        display: 'flex',
        justifyContent: 'flex-end',
        alignItems: 'center',
        height: '100%',
    },
    greenDiv: {
        backgroundColor: green[700],
        border: 0,
        borderRadius: '5px 0px 0px 5px',
        width: 150,
        padding: 5.5,
        [theme.breakpoints.only('xs')]: {
            width: '100%',
            borderRadius: 0
        }
    },
    greyLabel: {
        backgroundColor: '#F3F5F8',
        border: 0,
        borderRadius: '0px 5px 5px 0px',
        width: 150,
        minHeight: 20,
        padding: 5.5,
        [theme.breakpoints.only('xs')]: {
            width: '100%',
            borderRadius: 0
        }
    },
    declarations: {
        backgroundColor: '#F8FBFF',
        padding: 24
    },
    declarationsRow: {
        borderRadius: 24,
        display: 'flex',
        paddingTop: 24
    },
    declarationsTH: {
        width: '50%',
        backgroundColor: '#6D7CA4',
        borderRadius: '5px 0px 0px 5px',
        height: 'fit-content',
        color: 'white',
        padding: 8,
    },
    declarationsTD: {
        width: '25%',
        backgroundColor: '#EDF2F8',
        color: theme.palette.primary.dark,
        height: 'fit-content',
        textAlign: 'center',
        padding: 8
    },
    declarationsTDEnd: {
        width: '25%',
        backgroundColor: '#EDF2F8',
        color: theme.palette.primary.main,
        borderRadius: '0px 5px 5px 0px',
        height: 'fit-content',
        padding: 8,
        paddingRight: 64
    },
    declarationsTOTAL: {
        backgroundColor: theme.palette.primary.dark,
    },
}))

const SectionH = ({ id, ctrlID, process, onUpdate, onUpdateStep, language, permission, onDownloadDocument }) => {

    const step = 8
    const classes = styles()

    const [loading, setLoading] = useState(false)
    const [sending, setSending] = useState(false)
    const [success, setSuccess] = useState(null)
    const [error, setError] = useState(null)

    const [innerProcess, setProcess] = useState(null)
    const [completed, setCompleted] = useState(null)
    const [selected_document, setSelectedDocument] = useState(null)
    const content = contentData[language]

    const [document_modal, setDocumentModal] = useState(false)

    useEffect(() => {
        const initModule = async () => {
            try {
                setLoading(true)
                setProcess(process)
                setCompleted(process[`${step}_completed`] && process[`${step}_completed`] !== 0)
            } catch (error) {
                console.log(error)
                setError(onGetErrorMessage(error))
            }
            setLoading(false)
        }
        initModule()
    }, [])


    const onFinishProcess = async () => {
        try {
            setLoading(true)
            await onUpdate({}, true, step + 1, content.responsible_banner)
            const _process = await request_process(id, `?step_id=${step}`)
            setProcess(_process)
            setCompleted(_process[`${step}_completed`] && _process[`${step}_completed`] !== 0)
        } catch (error) {
            console.log(error)
            setError(onGetErrorMessage(error))
        }
        setLoading(false)
    }
    return (
        <LoadingContainer loading={loading}>
            <Grid container justify='center' alignItems='center' spacing={8}>
                <Grid item xs={12}>
                    <div style={{ heigth: 150 }}></div>
                </Grid>
                <Grid item xs={12}>
                    <Responsibles data={innerProcess} step={step} language={language} responsible_banner={content.responsible_banner} />
                </Grid>
                <Grid item xs={12}>
                    <div style={{ heigth: 150 }}></div>
                </Grid>
                <Grid item>
                    {!completed &&
                        <RoundedButton color='blueGrey' onClick={() => onUpdate({}, true, step - 1, content.responsible_banner)} disabled={!permission}>
                            {content.back}
                        </RoundedButton>
                    }
                </Grid>
                <Grid item>
                    {!completed &&
                        <RoundedButton color='secondary' onClick={onFinishProcess} disabled={!permission}>
                            {content.button}
                        </RoundedButton>
                    }
                </Grid>
            </Grid>
        </LoadingContainer >
    )
}

export default SectionH