export const contentData = {
  spanish:{
    nodata:'Sin Registro',
    notable:'No existen sellos de facturación registrados',
    tableTitle:'Sellos de Facturación',
    header:['ID','Número','Sucursal','Estatus', 'Expiración', 'Contraseña','Certificado','Llave', ],
    modals:{
      warning_delete_item:{
        title:'¿Estás seguro?',
        message:'¿Realmente quieres eliminar este registro? Este proceso no puede ser revertido',
        cancelButton:'Cancelar',
        deleteButton:'Eliminar'
      },
      cerl:{
        name:'Certificado eFirma',
        button:'Guardar',
        cancelButton:'Cancelar'
      }
    },
  },
  english:{

  }
}