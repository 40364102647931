import React, { useState, useEffect } from 'react';
import { styled } from '@material-ui/styles';
import { alpha, IconButton } from '@material-ui/core';

const StyledIconButton = styled(IconButton)(({ theme }) => ({
    position:'absolute',
    bottom:32,
    right:32,
    background:alpha(theme.palette.primary.main, 0.75),
    zIndex:5,
    '&:hover':{
      background:alpha(theme.palette.primary.main, 0.8),
    }
}));

const AIChatButton = ({
    permissions
}) => {

    const [permission, setPermission] = useState()

    useEffect(() => {
        if(permissions && permissions.length){
            const _temp = permissions.find(el => el.id_permission_module === 80)
            setPermission(_temp)
        }
    }, [permissions])


    if(!permission) return null
    if(permission.id_permission_type === 1) return null


    return ( 
        <StyledIconButton  href="/aichat">
            <img src="/assets/images/chatbot.png" width={48} />
        </StyledIconButton>
     );
}
 
export default AIChatButton;