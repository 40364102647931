import { Avatar, Grid, Icon, IconButton, makeStyles } from '@material-ui/core'
import React from 'react'
import SimpleCard from '../../Cards/SimpleCard'
import DisplayText from '../../../Texts/DisplayText'
import { isValidDocument } from '../../../../shared/utility';
import { imgServerS3 } from '../../../../variables/config';
import nopicture from '../../../../assets/nouser.png'

const EvaluatorProfile = props => {

    const { evaluator, evaluation_date, onEditEvaluator, path } = props
    const classes = styles()

    let selectedImage = nopicture

    if(evaluator && evaluator.image){
        const isValid = isValidDocument(evaluator.image)
        selectedImage = isValid ? `${imgServerS3}${evaluator.image}` : nopicture
    }

    return (
        <div style={{position:'relative'}} >
           
            {!path.includes('profile') ? <IconButton className={classes.edit_button} onClick={() => onEditEvaluator(evaluator)}>
                <Icon fontSize='small'>edit</Icon>
            </IconButton> : null}
            
            <SimpleCard>
                <Grid container spacing={4}>
                    <Grid item>
                        <Avatar src={selectedImage} alt='' className={classes.image} />
                    </Grid>
                    <Grid item xs>
                        <Grid container direction='column' justify='space-between' alignItems='stretch' spacing={4}>
                            <Grid item>
                                <Grid container spacing={1}>
                                    <Grid item xs={12}>
                                        <DisplayText variant='subtitle2' color='textSecondary'>{evaluator.title}</DisplayText>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <DisplayText variant='body2'>{evaluator.data}</DisplayText>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item>
                                <Grid container spacing={1}>
                                    <Grid item xs={12}>
                                        <DisplayText variant='subtitle2' color='textSecondary'>{evaluation_date.title}</DisplayText>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <DisplayText variant='body2'>{evaluation_date.data}</DisplayText>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </SimpleCard>
        </div>
    )
}

const styles = makeStyles((theme) => ({
    image: {
        width: 150,
        height: 150
    },
    edit_button: {
        position:'absolute',
        top:24,
        right:24,
		width: 35,
		height: 35,
		background: theme.palette.secondary.light,
		'&:hover': {
			background: theme.palette.secondary.main,
		},
		'& .MuiIcon-root': {
			color: 'white',
		},
	},
}))

export default EvaluatorProfile