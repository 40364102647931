export const formData = {
  name:{
    value: '',
    error: false,
    isVisited: false,
    isRequired: true,
    isValid: false,
    config:{
      id:'name',
      type:'text',
      fullWidth: true,
    },
    rules:{
      type:'distance',
      min:1,
      max:100
    }
  },
  specs:{
    value: '',
    error: false,
    isVisited: false,
    isRequired: true,
    isValid: false,
    config:{
      id:'specs',
      type:'text',
      fullWidth: true,
    },
    rules:{
      type:'distance',
      min:1,
      max:100
    }
  },
  responsible_id:{
    value: null,
    error: false,
    isVisited: false,
    isRequired: true,
    isValid: false,
    options:[],
    config:{
      id:'responsible_id',
      type:'avatar_select',
      fullWidth: true,
    },
    rules:{
      type:'none',
    }
  },
  periodicity_id:{
    value: null,
    error: false,
    isVisited: false,
    isRequired: true,
    isValid: false,
    options:[],
    config:{
      id:'periodicity_id',
      type:'simple_select',
      fullWidth: true,
    },
    rules:{
      type:'none',
    }
  },
  net_salary:{
    value: '',
    error: false,
    isVisited: false,
    isRequired: true,
    isValid: false,
    config:{
      id:'net_salary',
      type:'number',
      fullWidth: true,
    },
    rules:{
      type:'distance',
      min:1,
      max:100
    }
  },
  reimbursement:{
    value: '',
    error: false,
    isVisited: false,
    isRequired: true,
    isValid: false,
    config:{
      id:'reimbursement',
      type:'number',
      fullWidth: true,
    },
    rules:{
      type:'distance',
      min:1,
      max:100
    }
  },
  substitute_boss:{
    value: '',
    error: false,
    isVisited: false,
    isRequired: true,
    isValid: false,
    config:{
      id:'substitute_boss',
      type:'text',
      fullWidth: true,
    },
    rules:{
      type:'distance',
      min:1,
      max:100
    }
  },
  clabe:{
    value: '',
    error: false,
    isVisited: false,
    isRequired: true,
    isValid: false,
    config:{
      id:'clabe',
      type:'number',
      fullWidth: true,
    },
    rules:{
      type:'distance',
      min:1,
      max:100
    }
  },
  bank_id:{
    value: null,
    error: false,
    isVisited: false,
    isRequired: true,
    isValid: false,
    options:[],
    config:{
      id:'bank_id',
      type:'simple_select',
      fullWidth: true,
    },
    rules:{
      type:'none',
    }
  },
  beneficiary:{
    value: '',
    error: false,
    isVisited: false,
    isRequired: true,
    isValid: false,
    config:{
      id:'beneficiary',
      type:'text',
      fullWidth: true,
    },
    rules:{
      type:'distance',
      min:1,
      max:100
    }
  },
  bank_data:{
    value: '',
    error: false,
    isVisited: false,
    isRequired: true,
    isValid: false,
    config:{
      id:'bank_data',
      type:'text',
      fullWidth: true,
    },
    rules:{
      type:'distance',
      min:1,
      max:100
    }
  },
  comission:{
    value: '',
    error: false,
    isVisited: false,
    isRequired: true,
    isValid: false,
    config:{
      id:'comission',
      type:'number',
      fullWidth: true,
    },
    rules:{
      type:'distance',
      min:1,
      max:100
    }
  },
}