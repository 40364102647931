export const contentData = {
  spanish:{
    layout:{
      title:'Prevención Lavado de Dinero',
      navigation:[
          {name:'Tablas de Control',path:'/controltables'},
          {name:'Detalles',path:'/controltables/details/:id'},
      ],
      menu:null
    },
    modals:{
      warning_delete_file:{
        title:'¿Estás seguro?',
        message:'¿Realmente quieres eliminar este registro? Este proceso no puede ser revertido',
        cancelButton:'Cancelar',
        deleteButton:'Eliminar'
      },
      edit_service:{
        title:'Editar servicio',
        button:'Guardar',
        cancelButton:'Cancelar'
      }
    },
    basicform:{
      title:'Información',
      button:null,
      nodata:'Sin Registro',
      headers:{
        fixedHeaderA: ['Nombre','RFC','Estatus','Clasificación','Regimen Fiscal','Actividad'],
        fixedHeaderB: null,
        varHeader: ['Documentos Cliente','Alerta','Aviso','Folio', 'Fecha de Representación', 
        'Oficial de Cumplimiento', 'Hoja de Trabajo', 'Acuse','Responsable', 'Notas', 'Horas Extra',
        'Comprobante Horas', 'Cargos Extra', 'Comprobante Cargo', 'Cargo a Cobranza', 
        'Fecha de Completado'],
        extra: 'Completado'
      },

    }
  }
}
