
import moment from 'moment'

export const cleanData2Table = (data, nodata) => {

  const table = data.map(item => {
    
    let temp = {
      id:item.id_infonavit_user, 
      used_id: item.used,
      files:[
        {id:item.id_infonavit_user,
        key:'compliance_opinion',name:'Opinión del Cumplimiento INFONAVIT',path:'complianceopinion', status: checkStatus(item, 'compliance_opinion')},
      ],
      data:[]
    }
    
    let array = [
      item.id_infonavit_user, 
      item.user ? item.user: nodata, 
      item.password ? item.password: nodata,
      item.email ? item.email: nodata,
      'compliance_opinion'
    ]

    temp.data = [...array]
    return temp
  })

  return table
}

const checkStatus = (item, key) => {
  return item[key] !== "0" && item[key] !== 0 && item[key] !== ""
}
