import React from 'react';
import { Button, Drawer, fade, Grid, Icon, makeStyles, Typography } from '@material-ui/core';
import cx from 'classnames'
import { onGetDateWithTimezone } from '../../utils/formatTime';
import { useHistory } from 'react-router';



const AISidebar = ({
    chats,
    chat,
    onSelectChat,
    onGenerateNewChat
}) => {

    const classes = useStyles()
    const history = useHistory()

    return ( 
        <Drawer
            open={true}
            className={classes.root}
            variant='persistent'
            anchor='left'
            classes={{
                paper:classes.root
            }}
        
        >
            <div className={classes.logo}>
                <img 
                    src="/assets/images/company/ascg-white.png" 
                    alt="ascg-logo"
                    width='100%'
                />
            </div>
            <Button variant='contained' className={classes.add_button} fullWidth onClick={onGenerateNewChat}>
                <Icon>add</Icon>
                <Typography variant='body2' style={{textTransform:'none', marginLeft:1}}>Nueva pregunta</Typography>
            </Button>
            <div className={classes.content}>
                
                <div className={classes.chats_content}>
                    <Grid container spacing={1}>
                        {chats.map(_chat => {
                            const selected = _chat?.id_ai_chat === chat?.id_ai_chat
                            return(
                                <Grid item xs={12} key={`chat-${_chat?.id_ai_chat}`}>
                                    <div 
                                        className={cx({
                                            [classes.chat_button]:true,
                                            [classes.selected_chat_button]:selected,
                                        })} 
                                        onClick={() => onSelectChat(_chat)}
                                    >
                                        <div className={classes.first_pattern}>
                                            <Icon fontSize='small' style={{color:'white'}}>
                                                sms
                                            </Icon>
                                            <Typography noWrap variant='body2' style={{color:'white', marginLeft:8}}>
                                                {_chat?.title}
                                            </Typography>
                                        </div>
                                        
                                        <Typography variant='caption' component='p'>
                                            {onGetDateWithTimezone(_chat?.updated_at, 'DD MMM YY, HH:ss')}
                                        </Typography>
                                        
                                        
                                    </div>
                                </Grid>
                            )
                        })}
                    </Grid>
                </div>
            </div>
            <div className={classes.footer}>
                <div className={classes.actionbutton} onClick={() => history.push("/")}>
                    <Grid container spacing={1}  >
                        <Grid item>
                            <Icon>arrow_back</Icon>
                        </Grid>
                        <Grid item>
                            <Typography variant='body2' >Regresar a la plataforma </Typography>
                        </Grid>
                    </Grid> 
                </div>
            </div>
        </Drawer>
     );
}

const useStyles = makeStyles(theme => ({
    root:{
        background: theme.palette.primary.dark,
        width:250,
        color:'white',
        padding:"0px 8px",
        boxSizing:'border-box'
    },
    logo:{
        paddingTop:32,
        paddingBottom:32,
        width:180,
        margin:'0px auto'
    },
    actionbutton:{
        cursor:'pointer'
    },
    content:{
        height:'calc(100vh - 240px)',
        boxSizing:'border-box',
        padding:'8px 0px',
        overflowY:'auto',
        overflowX:'hidden'
    },
    add_button:{
        color:theme.palette.primary.dark,
        background:'white',
        marginBottom:16
    },
    chat_button:{
        width:'100%',
        //display:'flex',
        //flexDirection:'row',
        //alignItems:'center',
        //flexWrap:'nowrap',
        cursor:'pointer',
        padding:'12px 8px',
        boxSizing:'border-box',
        borderRadius:8,
        transition:'0.3s lineal all',
        '&:hover':{
            background:fade('#FFF',0.2),
            transition:'0.3s lineal all',
        }
        //justifyContent:'flex-start'
    },
    selected_chat_button:{
        background:theme.palette.secondary.main,
        cursor:'pointer',
        transition:'0.3s lineal all',
        '&:hover':{
            background:theme.palette.secondary.main,
            transition:'0.3s lineal all',
        }
    },
    first_pattern:{
        width:'100%',
        display:'flex',
        flexDirection:'row',
        alignItems:'center',
        flexWrap:'nowrap',
    },
    footer:{
        marginTop:16
    }
}))
 
export default AISidebar;