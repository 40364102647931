import { makeStyles, Typography } from '@material-ui/core'
import { red } from '@material-ui/core/colors'
import React from 'react'

const styles = makeStyles(theme => ({
    root:{
        position:'relative',
        width:24,
        height:24,
        borderRadius: '100%',
        background: red[500],
        //zIndex:3
    },
    notify: {
        position: 'absolute',
        top:'50%', left:'50%',
        transform:'translate(-50%, -50%)',
        fontSize: '70%'
    },
    fixed:{
        position:'absolute',
        right:12,
        top:4,
        zIndex:5
    }
}))

const NotificationBubble = props => {

    const classes = styles()
    const { number, top, right } = props
    //const number = 9999

    let _number = number
    if(number > 99) _number = '99+'
    

    return (
        <div className={classes.fixed} style={{top, right}}>
            <div className={classes.root} >
                <div className={classes.notify}>
                    {_number}
                </div>
            </div>
        </div>
        
    )
}

export default NotificationBubble