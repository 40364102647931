import React, {Component} from 'react'
import {Grid} from '@material-ui/core'
import ModuleList from './ModuleList/ModuleList'

import {
  request_stamps,
  request_add_billing_stamp, 
  request_edit_billing_stamp, 
  request_delete_billing_stamp,
  request_uploadFile,
  request_downloadFile
} from './requests.jsx'

import FormAModal from '../../../../../components/Modals/FormAModal'
import FileManagerModal from '../../../../../components/Modals/FileManagerModal/FileManagerModal'
import WarningModal from '../../../../../components/Modals/WarningModal'
import CustomSnackbar from '../../../../../components/Snackbars/CustomSnackbar'


import {formData as adddata} from './additem/data' 
import {contentData as addcontent} from './additem/content' 
import {catalog_matrix as addmatrix} from './additem/catalog_matrix'


import {cleanData2Table} from './customFunctions'
import { contentData } from './content'
import SimpleLoader from '../../../../../components/Loaders/SimpleLoader';

class BillingStamps extends Component {

  state = {
    isloading:false,
    accounts:[],
    stamps:[],
    selected_item:null,
    users:[],
    modals:{
      additem:{open:false,issending:false,error:null},
      edititem:{open:false,issending:false,error:null, extra:null},
      filemodal:{open:false,issending:false,error:null,extra:null},
      warning_delete_item:{open:false, issending:false, error:null, id:null}
    },
    success:{open:false,timeout:2000,type:'success',message:'Successful action!'},
  }

  componentDidMount(){
    this.initModule()
  }

  initModule = async() => {
    const {businessID} = this.props
    this.setState({isloading:true})
    let response = await request_stamps(businessID)
    this.setState({stamps:[...response]})
    this.setState({isloading:false})
  }

  onAddItem = async(data, type) => {
    try{
      const {businessID} = this.props
      const redata = {...data, business_id:businessID}
      this.onEditModal(type,{issending:true})
      let response = await request_add_billing_stamp(businessID,redata)
      this.setState({stamps:[...response]})
      this.onCloseModal(type)
      this.onActivateSuccessSnack()
    }catch(e){
      this.onEditModal(type,{issending:false})
      if(e.response){
        this.onEditModal(type,{error:e.response.data.error.message})
      }
      
    }
  }

  onEditItem = async(data, type) => {
    try{
      const {businessID} = this.props
      const {selected_item} = this.state
      this.onEditModal(type,{issending:true})
      let response = await request_edit_billing_stamp(businessID,data, selected_item.id_billing_stamp)
      this.setState({stamps:[...response]})
      this.onCloseModal(type)
      this.onActivateSuccessSnack()
    }catch(e){
      this.onEditModal(type,{issending:false})
      if(e.response){
        this.onEditModal(type,{error:e.response.data.error.message})
      }
      
    }
  }

  onPreDeleteItem = (id) => {
    const type = 'warning_delete_item'
    this.onEditModal(type, {open:true, data:id})
  }

  onDeleteItem = async(id) => {
    const type = 'warning_delete_item'
    try{
      const {businessID} = this.props
      this.onEditModal(type,{issending:true})
      let response = await request_delete_billing_stamp(businessID, id)
      this.setState({stamps:[...response]})
      this.onCloseModal(type)
      this.onActivateSuccessSnack()
    }catch(e){
      this.onEditModal(type,{issending:false})
      if(e.response){
        this.onEditModal(type,{error:e.response.data.error.message})
      }
      
    }
  }

  onFileActionManager = async(action, data) => {
    if(action === 'upload'){
      this.onOpenFileModal('filemodal',data)
    }else{
      this.onDownloadFile(data)
    }
  }

  onSubmitFile = async(filepath, file, data) => {
    try{
      const {businessID} = this.props
      this.onEditModal('filemodal',{issending:true})
      const aux = file[0].name.split('.')
      const ext = {ext:aux[aux.length-1]}
      let response = await request_uploadFile(businessID, filepath, file[0], ext, data.id)
      this.setState({stamps:[...response]})
      this.onCloseModal('filemodal')
      this.onActivateSuccessSnack()
    }catch(e){
      this.onEditModal('filemodal',{issending:false})
      if(e.response){
        this.onEditModal('filemodal',{error:e.response.data.error.message})
      }
    }
  }

  onDownloadFile = async (data) => {
    try{
      await request_downloadFile(data.id, data.path)
    }catch(e){
      console.log(e)
    }
  }

  onItemSelected = (id) => {
    const {stamps} = this.state
    let aux = stamps.filter(el => el.id_billing_stamp === id)
    if(aux.length > 0){
      this.setState({selected_item:aux[0]})
      this.onOpenFileModal('edititem')
    }
  }

  onOpenFileModal = (section, filedata) => {
    const {modals} = this.state
    let tempModals = {...modals}
    tempModals[section].open=true
    if(filedata){
      tempModals[section].data = {...filedata} 
    }
    this.setState({modals:{...tempModals}})
  }

  onCloseModal = (type) => {
    this.onEditModal(type, {open:false, issending:false, error:null})
  }

  onEditModal = (section, data) => {
    const {modals} = this.state;
    let tempModals = {...modals};
    tempModals[section]={...tempModals[section],...data};
    this.setState({modals:{...tempModals}})
  }

  onCloseSnackbar = (id) => {
    let temp = this.state[id]
    temp.open = false
    this.setState({[id]:{...temp}})
  }

  onActivateSuccessSnack = () => {
    const {success} = this.state
    let success_temp = {...success}
    success_temp.open = true
    this.setState({success:{...success_temp}})
  }

  render(){
    const {isloading, stamps, modals, success, selected_item} = this.state
    const {language, catalogs, permission, permissionsType} = this.props
    const content = contentData[language]
    let contentView = <Grid container justify='center'><Grid item><SimpleLoader /></Grid></Grid>

    let stamps_list = cleanData2Table(stamps, content.nodata, catalogs)

    if(!isloading){
      contentView = (
        <ModuleList 
          title={content.tableTile}
          header={content.header}
          data={stamps_list}
          onItemSelected={this.onItemSelected}
          onAddItem={() => this.onOpenFileModal('additem')}
          onDeleteItem={this.onPreDeleteItem}
          onFileAction={this.onFileActionManager}
          nodata={content.notable}
          permission={permission}
          permissionsType={permissionsType}
        />
      )
    }

    let modalcontent = null

    if(modals.warning_delete_item){
      modalcontent=(
        <WarningModal 
          data={modals.warning_delete_item}
          content={content.modals.warning_delete_item}
          onClose={() => this.onCloseModal('warning_delete_item')}
          onDelete={this.onDeleteItem}
        />
      )
    }

    if(modals.additem.open){
      modalcontent = (
        <FormAModal 
          addSpace={true}
          catalogs={catalogs.business_documents}
          data={modals.additem}
          catalog_matrix={addmatrix}
          content={addcontent[language]}
          form_data={adddata}
          onClose={() => this.onCloseModal('additem')}
          onSubmit={(data) => this.onAddItem(data, 'additem')}

        />
      )
    }

    if(modals.edititem.open){
      modalcontent = (
        <FormAModal 
          origin_data={selected_item}
          addSpace={true}
          catalogs={catalogs.business_documents}
          data={modals.edititem}
          catalog_matrix={addmatrix}
          content={addcontent[language]}
          form_data={adddata}
          onClose={() => this.onCloseModal('edititem')}
          onSubmit={(data) => this.onEditItem(data, 'edititem')}

        />
      )
    }

    if(modals.filemodal.open){
      modalcontent = (
        <FileManagerModal
          data={modals.filemodal}
          open={true}
          language={language}
          onClose={() => this.onCloseModal('filemodal')}
          onSubmit={this.onSubmitFile}
          onDelete={this.onDeleteFile}
        />
      )
    }


    return(
      <div>
        {modalcontent}
        {contentView}
        <CustomSnackbar open={success.open} autoHideDuration={success.timeout} onClose={() => this.onCloseSnackbar(success.type)} message={success.message} variant={success.type}/>
      </div>
    )
  }
}

export default BillingStamps