export const contentData = {
  spanish:{
    layout:{
      title:'Declaración Anual AC',
      navigation:[
          {name:'Tablas de Control',path:'/controltables'},
          {name:'Detalles',path:'/controltables/details/:id'},
      ],
      menu:null
    },
    modals:{
      warning_delete_file:{
        title:'¿Estás seguro?',
        message:'¿Realmente quieres eliminar este registro? Este proceso no puede ser revertido',
        cancelButton:'Cancelar',
        deleteButton:'Eliminar'
      },
      edit_service:{
        title:'Editar servicio',
        button:'Guardar',
        cancelButton:'Cancelar'
      }
    },
    basicform:{
      title:'Información',
      button:null,
      nodata:'Sin Registro',
      headers:{
        fixedHeaderA: ['Nombre','RFC','Estatus','Clasificación','Contraseña', 'Regimen Fiscal','Sub-Actividad'],
        fixedHeaderB: null,
        varHeader: ['Estatus','Periodo','Tipo','Ingresos','Egresos','Utilidad Físcal/Pérdida',
        'ISR a Pagar','Total a Pagar','Número de Operación','Fecha de Presentación','Hoja de Trabajo',
        'Acuse Anual','Detalle Doc','Fecha de Entrega','Responsable', 'Notas', 'Horas Extra', 'Comprobante Horas',
        'Cargos Extra', 'Comprobante Cargo', 'Cargo a Cobranza', 'Fecha de Completado'],
        extra: 'Completado'
      },

    }
  }
}
