import React, {Component} from 'react';
import Dropzone from 'react-dropzone';
import { withStyles } from '@material-ui/core/styles'

const styles = (theme) => ({
  container:{
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '20px',
    borderWidth: 2,
    borderRadius: 2,
    borderColor: '#eeeeee',
    borderStyle: 'dashed',
    backgroundColor: '#fafafa',
    color: '#bdbdbd',
    outline: 'none',
    transition: 'border .24s ease-in-out'
  }
})

const contentData = {
  spanish:{
    message:'Arrastra un archivo aquí o da clic para seleccionar alguno'
  },
  english:{
    message:"Drag 'n' drop a file here, or click to select one"
  }
}

// Note that there will be nothing logged when files are dropped
class SimpleDropzone extends Component {
  constructor() {
    super();
    this.onDrop = (files) => {
      const {onDrop} = this.props
      this.setState({files})
      onDrop(files)
    };
    this.state = {
      files: []
    };
  }

  render(){

    const {classes, language, accept} = this.props
    const content = contentData[language]

    const files = this.state.files.map(file => (
      <li key={file.name}>
        {file.name} - {file.size} bytes
      </li>
    ));

    return(
      <Dropzone onDrop={this.onDrop} accept={accept}>
        {({getRootProps, getInputProps}) => (
          <section className={classes.container}>
            <div {...getRootProps({className:'dropzone'})}>
              <input {...getInputProps()} />
              <p>{content.message}</p>
            </div>
            {files.length > 0 ?
            <aside>
              <ul>{files}</ul>
            </aside>:null}
          </section>
        )}
      </Dropzone>    
    )
  }
  
}

export default withStyles(styles)(SimpleDropzone)


