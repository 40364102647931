import React, {useState} from 'react';
import { fade, Grid, makeStyles } from '@material-ui/core';
import { blueGrey } from '@material-ui/core/colors';
import { catalogs } from '../../texts/esp/catalogs';
import StatusData from '../Structure/DisplayData/StatusData';
import DisplayText from '../Texts/DisplayText';
import PopperContainer from '../Overlays/PopperContainer';

const useStyles = makeStyles(theme => ({
    button:{
        borderRadius:8,
        border:`1px solid ${blueGrey[100]}`,
        padding:`8px 6px`,
        '&:hover':{cursor:'pointer'}
    },
    popover:{padding:"8px 0px"},
    item:{
        padding:8,
        '&:hover':{cursor:'pointer', background:fade(blueGrey[50],1)}
    }
}))

const HTimeReportStateBtn = ({title, statusID, size, onChange, disabled}) => {

    const classes = useStyles()
    const [anchorEl, setAnchorEl] = useState(null)
    const status = catalogs.timereport_states.find(el => el.value === statusID)
    const onInnerSelectedItem = (data) => {
        onChange(data)
        setAnchorEl(null)
    }

    return ( 
        <Grid container>
            <PopperContainer anchorEl={anchorEl} onClose={() => setAnchorEl(null)}> 
                <div className={classes.popover}>
                    <Grid container direction='column'>
                        {catalogs.timereport_states.map(item => {
                            return(
                                <Grid item key={item.value}>
                                    <div className={classes.item} onClick={() => onInnerSelectedItem(item)}>
                                        <StatusData data={item.label} color={item.color}/>
                                    </div>
                                </Grid>
                            )
                        })}
                    </Grid>
                </div>
            </PopperContainer>
            <Grid item xs={12} md={4}>
                <DisplayText variant={size === 'small' ? 'subtitle2' : 'subtitle1'}>{title}</DisplayText>
            </Grid>
            <Grid item xs={12} md={8}>
                <div className={classes.button} onClick={!disabled ? (e) => setAnchorEl(e.currentTarget) : null} >
                    <StatusData data={status ? status.label : null} color={status ? status.color : null} />
                </div>
            </Grid>
        </Grid>
     );
}
 
export default HTimeReportStateBtn;

