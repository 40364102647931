import { mainServer } from '../../../variables/config'
import axios from 'axios'
import FileSaver from 'file-saver'

export const request_get_user_info = async () => {
  const logData = JSON.parse(localStorage.getItem('data'))
  mainServer.instance.defaults.headers.common['Authorization'] = logData.token
  let response
  response = await mainServer.instance.get("/user/employee/me")
  return response.data.data.employee
}

export const request_process = async (id, url, params) => {
  let _url = url.replaceAll('_', '')
  const logData = JSON.parse(localStorage.getItem('data'))
  mainServer.instance.defaults.headers.common['Authorization'] = logData.token
  const response = await mainServer.instance.get(`/${_url}/${id}${params}`)
  return response.data.data[url]
}

export const request_update_process_step = async (id, url, params, data) => {
  let _url = url.replaceAll('_', '')
  const logData = JSON.parse(localStorage.getItem('data'))
  mainServer.instance.defaults.headers.common['Authorization'] = logData.token
  await mainServer.instance.patch(`/${_url}/${id}${params}`, data)
}

export const request_update_process = async (id, idExt, url, params, path, data, dataExt) => {
  let _url = url.replaceAll('_', '')
  const logData = JSON.parse(localStorage.getItem('data'))
  mainServer.instance.defaults.headers.common['Authorization'] = logData.token
  await mainServer.instance.patch(`/${_url}/${id}${params}`, data)
  await mainServer.instance.patch(`/${path}/${idExt}`, dataExt)
  const response = await mainServer.instance.get(`/${_url}/${id}${params}`)
  return response.data.data[url]
}

export const request_download_document = async (id, idExt, url, path, data, ctrlpath) => {
  let _url = url.replaceAll('_', '')
  const logData = JSON.parse(localStorage.getItem('data'))
  mainServer.instance.defaults.headers.common['Authorization'] = logData.token
  let response = null

  if (ctrlpath) {
    if (path === 'ctrlaccounting')
      response = await mainServer.instance.get(`/${path}/${idExt}/document`, data)
    else
      response = await mainServer.instance.get(`/${path}/${idExt}/${data.params.path}`)
  }
  else
    response = await mainServer.instance.get(`/${_url}/${id}/file`, data)
  delete axios.defaults.headers.common["Authorization"]
  response = await axios.get(response.data.data.path, { responseType: 'blob' })
  FileSaver.saveAs(response.data, `evidence`)
}