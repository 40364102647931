export const contentData = {
    spanish: {
        button: 'Continuar',
        back: 'Regresar',
        responsible_banner: {
            responsible: 'responsible',
            responsible_id: 'responsible_id',
            completed_date: 'completed_date'
        },
        accordions: [
            {
                status: 'compliance_opinion_status_id',
                comment:'compliance_opinion_comments',
                file:'compliance_opinion',
                title: 'Opinión de Cumplimiento',
            },
            /*{
                status: 'tax_mailbox_status_id',
                comment:'mailbox_comment',
                file:'tax_mailbox',
                title: 'Buzón Tributario'
            },*/
        ],
        declarations_date: {
            id: 'payment_limit_date',
            label: 'Fecha límite de Pago'
        }
    }
}
