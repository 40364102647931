import { Grid, Typography, makeStyles } from "@material-ui/core";
import React from "react";


const styles = makeStyles(theme => ({
    place:{
        background:theme.palette.secondary.main,
        borderRadius:'50%',
        width:36,
        height:36,
        color:'white',
        display:'flex',
        justifyContent:'center',
        alignItems:'center'
    },
    evaluated:{
        textOverflow:'ellipsis'
    }
}))

const DisplayConsultor = ({ place, evaluated}) => {

    const classes = styles()

    return(
        <Grid container spacing={2} alignItems="center" wrap="nowrap" >
            <Grid item><div className={classes.place} ><Typography>#{place}</Typography></div></Grid>
            <Grid item xs zeroMinWidth><Typography className={classes.evaluated} noWrap>{evaluated}</Typography></Grid>
        </Grid>
    )
}

export default DisplayConsultor