import React, {Component} from 'react'
import { withStyles } from '@material-ui/core/styles'
import { Grid, Typography} from '@material-ui/core'

import CardWrapper from '../../../../../components/Cards/CardWrapper/CardWrapper'
import SimpleLoader from '../../../../../components/Loaders/SimpleLoader'
import {ErrorMessage} from '../../../../../components/DisplayData/DisplayData'
import {RoundedButton} from '../../../../../components/Buttons/GeneralButtons'
//import ProfileAvatar from '../../../../../components/Avatars/ProfileAvatar'
import InputText from '../../../../../components/Inputs/InputText/InputText';
import InputSimpleSelect from '../../../../../components/Inputs/InputSimpleSelect/InputSimpleSelect';

import {formData, permissions_benefits} from './data'
import {contentData} from './content'

const styles = (theme) => ({
  root:{padding:'32px'},
  subtitle:{
    color:theme.palette.primary.main,
    fontWeight:'500'
  }
})



class AddUserCard extends Component {

  state = {
    formA: JSON.parse(JSON.stringify(formData.sectionA)),
    isValid:{
      formA:false,
    }
  }

  componentDidMount(){
    const {catalogs, language} = this.props
    const {formA} = this.state
    const content = contentData[language]
    let tempA = {...formA}
    tempA['user_level_id'].options = catalogs.user_levels
    //tempA['language_id'].options = catalogs.user_language
    tempA['timezone'].options = catalogs.timezones
    tempA['employee_status_id'].options = catalogs.user_status
    Object.keys(formA).forEach(item => {
      tempA[item].value = ''
      tempA[item].config = {...tempA[item].config,...content.sectionA[item]}
    })
    tempA['timezone'].value = 'Mexico/General'
    this.setState({formA:{...tempA}})
  }

  onInputChange = (data, type) => {
    const  formData = this.state[type]
    const id = data.config.id;
    let temp = {...formData};
    temp[id] = {...data};
    const isValidForm = this.validationForm(temp)
    this.setState({[type]:{...temp}, isValid:{...this.state.isValid,[type]:isValidForm}})
  }

  validationForm = (data) => {
    let isValid = true;
    Object.keys(data).forEach((item) => {
      if(data[item].isRequired && !data[item].isValid){
        isValid = false;
      }
    })
    return isValid;
  }

  onSubmit = () => {
    const {onSubmit} = this.props
    const {formA} = this.state
    let data2Send = {}
    
    Object.keys(formA).forEach((item)=>{
      if(formA[item].isValid){
        if(item !== 'passwordConfirm')
        data2Send = {...data2Send, [item]:formA[item].value}
      }
    })

    data2Send = {...data2Send, ...permissions_benefits}
    onSubmit(data2Send)
  }

  render(){

    const {classes, language, issending, error} = this.props
    const {formA, isValid} = this.state
    const content = contentData[language]

    let tempFormA = {...formA}
    formA.passwordConfirm.value2 = formA.password.value

    let contentA = null
    //// Basic info form
    contentA = Object.keys(tempFormA).map((item,key)=> {
      let inputContent = null
      switch (tempFormA[item].config.type) {
        case 'simple_select':
          inputContent = (
            <InputSimpleSelect
              wrap='vertical'
              data={tempFormA[item]}
              onChange={(data) => this.onInputChange(data,'formA')}
            />
          )
          break;
        default:
          inputContent = (
            <InputText
              data={tempFormA[item]}
              onChange={(data) => this.onInputChange(data,'formA')}
            />
          )
          break;
      }
      return(
        <Grid item xs={12} md={6} lg={4} key={key.toString()}>
          {inputContent}
        </Grid>
      )
    })
    //avatarContent = <ProfileAvatar image={user ? user.image : null}/>

    let loaderContent = null
    if(issending){
      loaderContent = <SimpleLoader />
    }else{
      if(error){
        loaderContent = <ErrorMessage message={error}/>
      }
    }    

    return(
      <CardWrapper>
        <div className={classes.root}>
          <Grid container spacing={8}>
            <Grid item xs={12}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Typography variant='body1' className={classes.subtitle}>
                  {content.title.sectionA}
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Grid container spacing={3}>
                    {contentA}
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Grid container justify='flex-end' alignItems='center' spacing={2}>
                <Grid item>
                  {loaderContent}
                </Grid>
                <Grid item>
                  <RoundedButton 
                    label={content.add_button} 
                    size='small' 
                    color='blue'
                    disabled={!isValid.formA}
                    onClick={this.onSubmit}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </div>
      </CardWrapper>
    )
  }
}

export default withStyles(styles)(AddUserCard)