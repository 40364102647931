import React, { useState, useEffect } from 'react'
import { Redirect } from 'react-router'
import {contentData} from './content'
import GeneralView from './GeneralView/GeneralView';
import LegalRepresentative from '../Modules/LegalRepresentative/LegalRepresentative';
import LegalRepresentativeView from './LegalRepresentativeView/LegalRepresentativeView'
import Logs from '../Modules/Logs/Logs';
import BasicView from '../../../../components_v1/Structure/Layouts/BasicView';
import ToolbarTable from '../../Users/UserView/components/ToolbarTable';
import { connect } from 'react-redux';
import { PERMISSION_MODULES, PERMISSION_TYPES } from '../../../../variables/config';
import HistoricalView from './HistoricalView/HistoricalView';

const Efirma = props => {

  const {history, language, catalogs, businessID, clientID, permissionsType, permissions} = props
  const [success, setSuccess] = useState(null)
  const [permission, setPermission] = useState()
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(null)
  const [businesses, setBusinessView] = useState([])
  const [pageSelected, setPageSelected] = useState(0)
  const [view, setView] = useState(1)
  
  const content = contentData[language]


  useEffect(() => {
    let module_permission = null
    if(permissions && permissions.length) module_permission = permissions.find(el => el.permission_module_id === PERMISSION_MODULES.EFIRMA)
    setPermission(module_permission)
  }, [permission])

  const onChangeView = (id) => {
    const urlwithargs = `?view=${id}`
    window.history.replaceState(null, null, urlwithargs);
    setView(id)
  }
    
  let contentView = null

  switch(view){
    case 1:
      contentView = (
        <GeneralView
          history={history}
          clientID={clientID}
          language={language}
          catalogs={catalogs}
          businessID={businessID}
          permission={permission}
          permissionsType={permissionsType}
        />
      )
      break
    case 2:
      contentView = (
          <LegalRepresentativeView
              history={history}
              language={language}
              catalogs={catalogs}
              businessID={businessID}
              permission={permission}
              permissionsType={permissionsType}
          />
      )
      break
    case 3:
      contentView = (
        <HistoricalView
        businessID={businessID}
            /* history={history}
            language={language}
            catalogs={catalogs}
            businessID={businessID}
            permission={permission}
            permissionsType={permissionsType} */
        />
      )
      break
    default:
      break
  }

  let redirectContent = (permission && permission.permission_type_id === PERMISSION_TYPES.NO_ACCESS) ? <Redirect to='/profile'/> : null

  return(
    <BasicView
      title={content.layout.title} error={error} onCloseError={() => setError(null)}
      success={success} onCloseSuccess={() => setSuccess(null)} setReturn >
      <ToolbarTable selected={view} menuOptions={content.layout.menu} onChange={onChangeView} />
      {redirectContent}
      <div>
        {contentView}
      </div>

    </BasicView>
  )
}

const mapStateToProps = state => {
  return{permissions:state.permissions}
}

export default connect(mapStateToProps)(Efirma)
