import { mainServer } from "../../variables/config"

const useBusinessCost = () => {

    const onGetAll = async(params) => {
        const logData = JSON.parse(localStorage.getItem('data'))
        mainServer.instance.defaults.headers.common['Authorization'] = logData.token
        let response
        response = await mainServer.instance.get("/businesscost/all", {params:params})
        return {
            data:response.data.data.business_costs,
            total:response.data.data.count
        }
    }

    /*const onGet = async(id) => {
        const logData = JSON.parse(localStorage.getItem('data'))
        if(!logData) return null
        mainServer.instance.defaults.headers.common['Authorization'] = logData?.token
        let response
        response = await mainServer.instance.get(`/aichat/${id}`)
        return response.data.data.ai_chat
    }

    const onAdd = async(data2send) => {
        const logData = JSON.parse(localStorage.getItem('data'))
        mainServer.instance.defaults.headers.common['Authorization'] = logData.token
        let response
        response = await mainServer.instance.post(`/aichat`, data2send)
        console.log(response.data.data)
        //return response.data.data.ai_chats
        return response.data.data.ai_chat_id
    }

    const onAddMessage = async(id, data2send) => {
        const logData = JSON.parse(localStorage.getItem('data'))
        mainServer.instance.defaults.headers.common['Authorization'] = logData.token
        let response
        response = await mainServer.instance.post(`/aichat/${id}/message`, data2send)
        console.log(response.data.data)
        
    }*/

    return {
        onGetAll,
    };
}
 
export default useBusinessCost;