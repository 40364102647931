export const contentData = {
    spanish:{
      title:'Cambiar Contraseña',
      nodata:'Sin Registro',
      error:'Sin registro',
      button:'Actualizar',
      cancelButton:'Cancelar',
      image_button:'Cambiar',
      form:{
        password:{
            label:'Nueva Contraseña',
            placeholder:'Nueva Contraseña'
        }
      },
    },
  }