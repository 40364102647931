import React from 'react'
import { Button, Icon, IconButton, makeStyles } from '@material-ui/core'
import { useTheme } from '@material-ui/styles'
import { grey } from '@material-ui/core/colors'
import GenericImage from './GenericImage'


const styles = makeStyles(theme => ({
    imageContainer: {
        position: 'relative',
        background: 'white',
        borderRadius: 10,
        //border: '1px solid #EAEAEA'
    },
    editButton: {
        top: '50%',
        left : '50%',
        position: 'absolute',
        padding:'8px 24px',
        border: '1px solid #FFFFFF',
        color:'white',
        background:'rgba(255, 255, 255, 0.1)',
        borderRadius:10,
        transform:'translate(-50%, -50%)',
        zIndex:15
    },
    mask:{
        position:'absolute',
        top:0,
        left:0,
        width:'100%',
        height:'100%',
        background:'rgba(12, 37, 78, 0.58)',
        borderRadius:10,
        zIndex:10
    }
}))

const ImageEdition = ({ src, onClick, ratio, fullpath, src_temp }) => {

    const classes = styles()
    const theme = useTheme()

    return (
        <div className={classes.imageContainer}>
            <div className={classes.mask}></div>
            <GenericImage src={src} borderRadius={10} ratio={ratio} fullpath={fullpath} src_temp={src_temp} />
            <Button className={classes.editButton} onClick={onClick}>
                Editar
            </Button>
        </div>
    )
}

export default ImageEdition