export const contentData = {
  spanish:{
    error:'',
    save_button:'Actualizar',
    layout:{
      title:'Cambiar contraseña',
      navigation:[
        {name:'Usuarios',path:'/users'},
        {name:'Contraseña',path:'/users/change-password'},
      ]
    },
    basicForm:{
        password:{
            label:'Nueva Contraseña',
            placeholder:'Nueva Contraseña'
        }
    }
  }
}
