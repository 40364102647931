import React from 'react'
import { Redirect } from 'react-router-dom'
import { Router, Route, Switch } from 'react-router-dom'
import SignIn from '../layouts/Login/login.jsx'
import Dashboard from '../layouts/Dashboard/Dashboard.jsx'
import AIChatView from '../views/AIChat/ATChatView.js'


const PublicRouters = (props) => {
  return(
    <Router history={props.history}>
        <Switch>
          <Route 
            path={'/aichat'}
            component={AIChatView}
          />
          <Route
            path={'/signin'}
            component={SignIn}
          />
          <Route
            path={'/'}
            component={Dashboard}
          />
          <Redirect to={'/signin'}/>
        </Switch>
    </Router>
  );
}

export default PublicRouters
