import React, { useState, useEffect } from 'react';
import { PDFDownloadLink } from '@react-pdf/renderer';
import { Grid } from '@material-ui/core';
import { Redirect } from 'react-router';
import BasicView from '../Layouts/BasicView';
import InputText from '../../Forms/InputText';
import SimpleCard from '../Cards/SimpleCard';
import { onGetErrorMessage, onGetFormData, onGetUserID, onInitForm, onSetErrorsToForm } from '../../../shared/utility';
import RoundedButton from '../../Actions/RoundedButton';
import { catalogs } from '../../../texts/esp/catalogs';
import { isFormValid } from '../../../shared/utility';
import { checkTableState, cleanData2Table, cleanData2TablePerformance, onRecalculateScoreValues } from './customFunctions';
import { request_evaluation, request_update_evaluation } from './requests';
import PerformanceTable from '../Tables/PerformanceTable';
import DataView from './components/DataView'
import { contentData } from './content';
import SignModal from './modals/SignModal';
import EvaluationPDF from './components/EvaluationPDF'
import moment from 'moment';
import EditEvaluatorModal from './modals/EditEvaluatorModal';

const EvaluationGenericView = (props) => {

    const { match, history, language, data, permissions, permissionsType } = props
    const content = contentData[language]
    const evaluationID = match.params.id
    console.log(match.path)
    const userID = onGetUserID()

    const [loading, setLoading] = useState(false)
    const [sending, setSending] = useState(false)
    const [error, setError] = useState(null)
    const [success, setSuccess] = useState(false)

    const [access, setAccess] = useState(false)
    const [form, setForm] = useState(JSON.parse(JSON.stringify(formData)))
    const [evaluation, setEvaluation] = useState(null)
    const [step, setStep] = useState(null)
    const [errorTable, setErrorTable] = useState(false)
    const [evaluator, setEvaluator] = useState(null)

    const [signmodal, setSignModal] = useState(false)
    const [secondsignmodal, setSecondSignModal] = useState(false)
    const [edit_evaluator, setEditEvaluator] = useState(false)

    useEffect(() => {
        console.log('Inicializar')
        const initModule = async () => {
            try {
                setLoading(true)
                const _evaluation = await request_evaluation(evaluationID)
                setEvaluation(_evaluation)
                setStep(_evaluation.evaluation_step_id)
                let _access = true
                const { evaluation_step_id, evaluator_id, evaluated_id } = _evaluation
                if (evaluation_step_id !== 6) {
                    if (_evaluation && evaluator_id === userID)
                        _access = (evaluation_step_id === 2 || evaluation_step_id === 4 || evaluation_step_id == 5)
                    if (_evaluation && evaluated_id === userID)
                        _access = (evaluation_step_id === 1 || evaluation_step_id === 3)
                }
                setAccess(_access)
            } catch (error) {
                console.log(error)
                setError(onGetErrorMessage(error))
            }
            setLoading(false)
        }
        initModule()
    }, [])

    useEffect(() => {
        try {
            setLoading(true)
            let _form = JSON.parse(JSON.stringify(formData))
            _form = onInitForm(_form, evaluation)
            _form.comments.disabled = !access || !(step === 2 || step === 4)
            _form.rejection_comments.disabled = !access || !(step === 3 || step === 1)
            setForm({ ..._form })
        } catch (error) {
            console.log(error)
            setError(onGetErrorMessage(error))
        }
        setLoading(false)
    }, [evaluation, access, step])

    const onChange = (data) => {
        let temp = { ...form }
        const id = data.config.id;
        temp[id] = { ...data };
        setForm(temp)
    }

    const onChangeEvaluationRow = async (data) => {
        try {
            setSending(true)
            const { id, total_id, value } = data

            console.log(`iID: ${id}, Total:${total_id}, Value:${value}`)
            //console.log(form)

            //ASIGNAMOS EL NUEVO VALOR PARA EL RENGLON SELECCIONADO (id).
            let data2send = onGetFormData(form)
            data2send[id] = value


            //RECALCULAMOS EL TOTAL DE LA CATEGORIA (total_id) EN BASE AL TOTAL PREVIO.
            const new_sums = onRecalculateScoreValues({ ...evaluation, ...data2send })
            const data_evals = { ...data2send, ...new_sums }

            const _evaluation = await request_update_evaluation(evaluationID, data_evals)
            setEvaluation(_evaluation)
            setErrorTable(false)
            setSending(false)
            setSuccess(true)
        } catch (error) {
            console.log(error)
            setError(onGetErrorMessage(error))
        }
        setSending(false)
    }

    const handleStepButtons = () => {
        switch (step) {
            case 1:
                onUpdateCompleted()
                break
            case 2:
                onUpdateCompleted()
                break
            case 3:
                setSignModal(true)
                break
            case 4:
                onUpdateCompleted()
                break
            case 5:
                setSecondSignModal(true)
                break
            case 6:
                break
        }
    }

    const onUpdateCompleted = async (data) => {
        try {
            setSending(true)
            let _form = { ...form }
            if (step === 1)
                delete _form.comments
            const errors = isFormValid(_form)

            /// Check if the form is valid
            if (errors && errors.length) {
                setSending(false)
                setSignModal(false)
                setSecondSignModal(false)
                const new_form = onSetErrorsToForm(form, errors)
                setForm(new_form)
                return
            }

            if (step === 1 || step === 2 || step === 4) {
                let evaluationCompleted = checkTableState(evaluation, content.sections.performance_info.content)
                console.log(evaluationCompleted)
                if (!evaluationCompleted) {
                    setSending(false)
                    setErrorTable(true)
                    return
                }
            }

            //Extract data
            let data2send = onGetFormData(_form)
            data2send.evaluation_step_id = step + 1
            if (data && data.length > 0) {
                //in case wasn't rejected
                let id = (step === 1 || step === 3) ? 'evaluated_signature' : 'evaluator_signature'
                data2send[id] = data
                if (step === 3){
                    data2send.evaluation_step_id = 5
                    data2send = { ...data2send, accepted_date: moment().utc().format() }
                }
            }

            if (step === 4)
                data2send.evaluation_step_id = 3

            if (step === 5)
                data2send = { ...data2send, evaluation_date: moment().utc().format() }

            /*if (step === 6)//means that will be completed after this.
                data2send = { ...data2send, accepted_date: moment().utc().format() }*/

            const _evaluation = await request_update_evaluation(evaluationID, data2send)
            setEvaluation(_evaluation)
            setStep(_evaluation.evaluation_step_id)
            let _access = true
            const { evaluation_step_id, evaluator_id, evaluated_id } = _evaluation
            if (evaluation_step_id !== 6) {
                if (_evaluation && evaluator_id === userID)
                    _access = (evaluation_step_id === 2 || evaluation_step_id === 4 || evaluation_step_id == 5)
                if (_evaluation && evaluated_id === userID)
                    _access = (evaluation_step_id === 1 || evaluation_step_id === 3)
            }
            setAccess(_access)
            setSending(false)
            setSignModal(false)
            setSecondSignModal(false)
            setSuccess(true)
        } catch (error) {
            console.log(error)
            setError(onGetErrorMessage(error))
        }
        setSending(false)
    }

    const onSetEvaluation = (val) => setEvaluation(val)

    let buttons = content.buttons.find(e => e.id === step)
    let buttonsContent = null

    if (buttons)
        buttonsContent = (
            <Grid container justify='center' spacing={4}>
                {step === 3 ?
                    <Grid item>
                        <RoundedButton
                            disabled={!access}
                            color='whiteSecondary'
                            onClick={onUpdateCompleted}
                        >
                            {buttons.reject}
                        </RoundedButton>
                    </Grid>
                    : null}
                {step === 6 ?
                    <Grid item>
                        <PDFDownloadLink document={<EvaluationPDF {...evaluation} />} fileName="evaluation.pdf">
                            {({ blob, url, loading, error }) => {
                                console.log(error)
                                return (
                                    <RoundedButton
                                        disabled={!access || loading}
                                        color='secondary'
                                    >
                                        {loading ? 'Cargando documento...' : buttons.submit}
                                    </RoundedButton>
                                )
                            }}
                        </PDFDownloadLink>
                    </Grid>
                    :
                    <Grid item>
                        <RoundedButton
                            disabled={!access}
                            color='secondary'
                            onClick={handleStepButtons}
                        >
                            {buttons.submit}
                        </RoundedButton>
                    </Grid>}
            </Grid>
        )

    let redirectContent = null
    let _permission = permissions.find(e => e.id_permission_module === 0)

    if (_permission && _permission.permission_type_id === permissionsType.NO_ACCESS) redirectContent = <Redirect to='/profile' />

    let performanceData = null
    let evaluationData = null
    if (evaluation) {
        performanceData = cleanData2TablePerformance(evaluation, content.sections.performance_info.content, catalogs.employee_performance_status)
        evaluationData = cleanData2Table(evaluation, content.nodata, content.sections.main_info)
    }

    //console.log()
    //console.log(evaluation)

    const onEditEvaluator = (ev) =>{
        //setEvaluator(ev)
        setEditEvaluator(true)
    }

    return (
        <BasicView loading={loading} title={content.title} sending={sending} setReturn
            success={success} onCloseSuccess={() => setSuccess(null)}
            error={error} onCloseError={() => setError(null)}>
            {redirectContent}
            <SignModal open={signmodal} content={content.modals.sign_modal} onClose={() => setSignModal(false)} onUpdateCompleted={onUpdateCompleted} />
            <SignModal open={secondsignmodal} content={content.modals.second_sign_modal} onClose={() => setSecondSignModal(false)} onUpdateCompleted={onUpdateCompleted} />
            <EditEvaluatorModal content={content.modals.edit_evaluator} open={edit_evaluator} onClose={()=>setEditEvaluator(false)} origin={evaluation}  
                                onSetEvaluation={onSetEvaluation} />
            <Grid container justify='center' spacing={4}>
                <Grid item xs={12}>
                    <DataView
                        data={evaluationData}
                        content={content.sections}
                        onEditEvaluator={onEditEvaluator}
                        path={match.path}
                    />
                </Grid>
                <Grid item xs={12}>
                    <PerformanceTable
                        access={(step === 1 || step === 2 || step === 4) && access}
                        loading={sending}
                        header={content.sections.performance_info.header}
                        onSelectedItem={onChangeEvaluationRow}
                        data={performanceData}
                        contentError={errorTable}
                    />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <SimpleCard>
                        <InputText
                            color='primary'
                            data={form.comments}
                            onChange={onChange}
                        />
                    </SimpleCard>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <SimpleCard>
                        <InputText
                            color='primary'
                            data={form.rejection_comments}
                            onChange={onChange}
                        />
                    </SimpleCard>
                </Grid>
                <Grid item>
                    {buttonsContent}
                </Grid>
            </Grid>

        </BasicView>
    );
}

const formData = {
    comments: {
        value: null,
        error: false,
        isVisited: false,
        isRequired: true,
        isValid: false,
        config: {
            id: 'comments',
            type: 'text',
            fullWidth: true,
            multiline: true,
            rows: 6,
            label: 'Comentarios evaluador',
            placeholder: 'Introduce un texto'
        },
        rules: {
            type: 'distance',
            min: 1,
            max: 1024
        }
    },
    rejection_comments: {
        value: null,
        error: false,
        isVisited: false,
        isRequired: true,
        isValid: false,
        config: {
            id: 'rejection_comments',
            type: 'text',
            fullWidth: true,
            multiline: true,
            rows: 6,
            label: 'Comentarios evaluado',
            placeholder: 'Introduce un texto'
        },
        rules: {
            type: 'distance',
            min: 1,
            max: 1024
        }
    },
}
export default EvaluationGenericView;