import React, {useState, useRef, useEffect} from 'react'
import { Grid } from '@material-ui/core'
import CustomDropzone from './CustomDropzone'
import ReactCrop from 'react-image-crop'
import 'react-image-crop/dist/ReactCrop.css'
import { onGetFileExtension } from '../../../../../../../shared/utility'
import {request_upload_image, request_delete_image} from '../../requests'
import SimpleModal from '../../../../../../../components_v1/Structure/Modals/SimpleModal'
import DisplayText from '../../../../../../../components_v1/Texts/DisplayText'
import ActionModalBar from '../../../../../../../components_v1/Actions/ActionModalBar'

const PictureModal = props => {

    const {open, onClose, onUpdateCompleted, id} = props
    const [file, setFile] = useState(null)
    const [src, setSrc] = useState(null)
    const [crop, setCrop] = useState({aspect:1/1})
    const [croppedImage, setCroppedImage] = useState(null)
    const [error, setError] = useState(null)
    const [loading, setLoading] = useState(false)
    const [valid, setValid] = useState(false)
    let imageRef = useRef(null)


    useEffect(() => {
        if(open){
            setFile(null)
            setSrc(null)
            setCrop({aspect:1/1})
            setCroppedImage(null)
            setError(null)
            setLoading(false)
            setValid(false)
            imageRef.current = null
        }
    }, [open])

    const onDropFile = (data) => {
        setFile(data)
        if(data){
            const reader = new FileReader();
            reader.addEventListener("load", () => setSrc(reader.result))
            reader.readAsDataURL(data)
        }
    }

    const onImageLoaded = image => imageRef.current = image

    const handleOnCropChange = (crop) => {
        setCrop(crop)
    }

    const handleOnCropComplete = async(crop, pixelCrop) => {
        setValid(crop.width > 0 && crop.height > 0)
        if(imageRef && crop.width && crop.height){
            const croppedImg = await getCroppedImg(imageRef.current, crop, 'profile')
            setCroppedImage(croppedImg)
        }
    }

    const getCroppedImg = (image, crop, fileName) => {
        const canvas = document.createElement('canvas');
        const scaleX = image.naturalWidth / image.width;
        const scaleY = image.naturalHeight / image.height;
        canvas.width = crop.width;
        canvas.height = crop.height;
        const ctx = canvas.getContext('2d');
      
        ctx.drawImage(
          image,
          crop.x * scaleX,
          crop.y * scaleY,
          crop.width * scaleX,
          crop.height * scaleY,
          0,
          0,
          crop.width,
          crop.height,
        );


      
        // As Base64 string
        // const base64Image = canvas.toDataURL('image/jpeg');
      
        // As a blob
        return new Promise((resolve, reject) => {
            canvas.toBlob(blob => {
                let fileUrl
                window.URL.revokeObjectURL(fileUrl);
                fileUrl = window.URL.createObjectURL(blob);
                resolve(fileUrl)
            });
        });
    }

    const onSubmit = async() => {
        if(!valid){
            setError('Seleccione un archivo')
            return
        }
        setLoading(true)
        try {
            if(croppedImage){
                const filed2send = await handlerURL2Blob(croppedImage)
                const ext = {type:onGetFileExtension(filed2send.name)}
                await request_upload_image(id, filed2send, ext)
                console.log('Actualizar')
                onUpdateCompleted()
            }
        } catch (error) {
            console.log(error)
            setError('Hubo un error')
            setLoading(false)
        }
        

    }

    const handlerURL2Blob = async(url) => {
        let blob = await fetch(url).then(r => r.blob());
        let temp = blob.type.split('/');
        let ext = "."+temp[temp.length-1];
        let newfile = new File([blob], "profile"+ext, {type:blob.type});
        return newfile;
    }

    const onDelete = async() => {
        setLoading(true)
        try {
            await request_delete_image(id)
            onUpdateCompleted()
        } catch (error) {
            console.log(error)
            setError('Hubo un error')
            setLoading(false)
        }
    }


    return(
        <SimpleModal open={open} onClose={onClose}>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <DisplayText variant='h6'>Imagen de negocio</DisplayText>
                </Grid>
                <Grid item xs={12}>
                    <CustomDropzone onDropFile={onDropFile}/>
                </Grid>
                {file ? (
                    <Grid item xs={12}>
                        <Grid container>
                            <Grid item xs={12}>
                                <DisplayText variant='subtitle1' >Imagen seleccionada</DisplayText>
                            </Grid>
                            <Grid item xs={12}>
                                <ReactCrop 
                                    src={src} crop={crop} 
                                    onImageLoaded={onImageLoaded}
                                    onChange={handleOnCropChange}
                                    onComplete = {handleOnCropComplete}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                ) : null}
                <Grid item xs={12}>
                    <ActionModalBar error={error} loading={loading} btnLabel='Guardar' onDelete={onDelete} onSubmit={onSubmit}/>
                </Grid>
            </Grid>
        </SimpleModal>
    )
}

export default PictureModal