export const contentData = {
  spanish:{
    title:'Editar información de registro',
    nodata:'Sin Registro',
    error:'Sin registro',
    button:'Guardar',
    form:{
      classification_id:{
        label:'Clasificación',
        placeholder:'Clasificación'
      },
      pre_fiscal_regime_id:{
        label:'Régimen Fiscal',
        placeholder:'Régimen Fiscal'
      },
      pre_contact_medium_id:{
        label:'Medio de contacto',
        placeholder:'Medio de contacto'
      },
      pre_contact_date:{
        label:'Fecha de contacto',
        placeholder:'Fecha de contacto'
      },
      pre_required_date:{
        label:'Fecha requerida',
        placeholder:'Fecha requerida'
      },
      pre_end_date:{
        label:'Fecha de fin',
        placeholder:'Fecha de fin'
      },
      pre_observations:{
        label:'Observaciones',
        placeholder:'Observaciones'
      },
      pre_contact_name:{
        label:'Nombre de contacto',
        placeholder:'Nombre de contacto'
      },
    },
  },
}