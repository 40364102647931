export const contentData = {
    spanish: {
        title: 'Impuesto local sobre nómina',
        location: true,
        header: [
            { id: 1, label: 'Razón Social', key:'business' },
            { id: 2, label: 'Estado', key: 'fixed_state' },
            { id: 3, label: 'Ciudad', key: 'fixed_city' },
            { id: 11, label: 'Clasificación', key: 'fixed_client_classification' },
            { id: 20, label: 'Importancia', key: 'fixed_importance' },
            { id: 21, label: 'Socio', key: 'fixed_client_associate' },
            { id: 23, label: 'Responsable', key: 'fixed_client_responsible' },
            { id: 22, label: 'Asesor', key: 'fixed_business_advisor' },
            { id: 24, label: 'Turno de', key: 'fixed_responsible' },
            { id: 6, label: 'Fecha límite', key:'fixed_payday_limit' },
            { id: 7, label: 'No. de paso', key:'fixed_state_tax_step_id' },
            { id: 8, label: 'Prioridad', key:'solution_priority' },
            { id: 9, label: 'Porcentaje', },
            { id: 10, label: 'Estatus', key:'process_semaphore_id' }
        ],
        modals: {
            sign_modal: {
                title: 'Firma',
                button: 'Aprobar'
            },
            second_sign_modal: {
                title: 'Firma',
                button: 'Completar evaluación'
            }
        },
    }
}
