import {mainServer} from '../../../../variables/config'
import axios from 'axios'

export const sleep = (time) => {
    return new Promise((resolve) => setTimeout(resolve, time));
}

export const request_update_password = async(id,data) => {
    const logData = JSON.parse(localStorage.getItem('data'));
    mainServer.instance.defaults.headers.common['Authorization'] = logData.token;
    await mainServer.instance.patch("/user/employee/" + id, data);
    let response;
    response = await mainServer.instance.get("/user/employee/" + id);
    return response.data.data.employee
}
