import React, {Component} from 'react'
import { Redirect } from 'react-router'
import {Grid} from '@material-ui/core'
import {
  request_users,
  request_outsourcing,
  request_add_outsourcings, 
  request_edit_outsourcings, 
  request_uploadFile,
  request_downloadFile, 
  request_deleteFile,
  request_business_documents
} from './requests.jsx'

import FormAModal from '../../../../../components/Modals/FormAModal'
import ServiceForm from  '../../../../../components/Forms/ServiceFormB/ServiceFormB'

import FileManagerModal from '../../../../../components/Modals/FileManagerModal/FileManagerModal'
import WarningModal from '../../../../../components/Modals/WarningModal'
import CustomSnackbar from '../../../../../components/Snackbars/CustomSnackbar'


import {formData as adddata} from './additem/data' 
import {contentData as addcontent} from './additem/content' 
import {catalog_matrix as addmatrix} from './additem/catalog_matrix'

import { contentData } from './content'
import SimpleLoader from '../../../../../components/Loaders/SimpleLoader';

class OutsourcingDetails extends Component {

  state = {
    isloading:false,
    service_item_selected:null,
    outsourcing_id:null,
    business_documents:[],
    selected_item:null,
    users:[],
    modals:{
      additem:{open:false,issending:false,error:null},
      edititem:{open:false,issending:false,error:null, extra:null},
      filemodal:{open:false,issending:false,error:null,extra:null},
      warning_delete_item:{open:false, issending:false, error:null, id:null}
    },
    success:{open:false,timeout:2000,type:'success',message:'Successful action!'},
  }

  componentDidMount(){
    this.initModule()
  }

  initModule = async() => {
    const {businessID, moduleID} = this.props
    this.setState({isloading:true})
    let response = await request_outsourcing(moduleID)
    this.setState({service_item_selected:{...response}})
    response = await request_users()
    this.setState({users:[...response]})
    response = await request_business_documents(businessID)
            this.setState({business_documents:{...response}})
    this.setState({isloading:false})
  }

  onAddItem = async(data, type) => {
    try{
      const {moduleID} = this.props
      const redata = {...data, business_id:moduleID}
      this.onEditModal(type,{issending:true})
      let response = await request_add_outsourcings(moduleID,redata)
      this.setState({service_item_selected:{...response}})
      this.onCloseModal(type)
      this.onActivateSuccessSnack()
    }catch(e){
      this.onEditModal(type,{issending:false})
      if(e.response){
        this.onEditModal(type,{error:e.response.data.error.message})
      }
      
    }
  }

  onEditItem = async(data, type) => {
    try{
      const {moduleID} = this.props
      const {service_item_selected} = this.state
      this.onEditModal(type,{issending:true})
      let response = await request_edit_outsourcings(moduleID,data, service_item_selected.id_outsourcing)
      this.setState({service_item_selected:{...response}})
      this.onCloseModal(type)
      this.onActivateSuccessSnack()
    }catch(e){
      this.onEditModal(type,{issending:false})
      if(e.response){
        this.onEditModal(type,{error:e.response.data.error.message})
      }
      
    }
  }

  onSubmitFile = async(filepath, file, data) => {
    try{
      const {businessID, moduleID} = this.props
      this.onEditModal('filemodal',{issending:true})
      const aux = file[0].name.split('.')
      const ext = {ext:aux[aux.length-1]}
      let response = await request_uploadFile(businessID, moduleID, filepath, file[0], ext, data.businessDocument)
      this.setState({service_item_selected:{...response}})
      response = await request_business_documents(businessID)
      this.setState({business_documents:{...response}})
      this.onCloseModal('filemodal')
      this.onActivateSuccessSnack()
    }catch(e){
      this.onEditModal('filemodal',{issending:false})
      if(e.response){
        this.onEditModal('filemodal',{error:e.response.data.error.message})
      }
    }
  }

  onDownloadFile = async (data) => {
    try{
      const {businessID, moduleID} = this.props
      await request_downloadFile(businessID, moduleID, data.path, data.businessDocument)
    }catch(e){
      console.log(e)
    }
  }

  onDeleteFile = async (filedata) => {
    try{
      this.onEditModal('warning_delete_item',{issending:true});
      const {businessID, moduleID} = this.props
      let response = await request_deleteFile(businessID, moduleID, filedata.path, filedata.businessDocument)
      this.setState({service_item_selected:{...response}})
      response = await request_business_documents(businessID)
      this.setState({business_documents:{...response}})
      this.onCloseModal('warning_delete_item')
      this.onActivateSuccessSnack()
    }catch(e){
      this.onEditModal('warning_delete_item',{issending:false});
      if(e.response){
        this.onEditModal('warning_delete_item',{error:e.response.data.error.message})
      }
    }
    
  }

  onOpenFileModal = (section, filedata) => {
    const {modals} = this.state
    let tempModals = {...modals}
    tempModals[section].open=true
    if(filedata){
      tempModals[section].data = {...filedata} 
    }
    this.setState({modals:{...tempModals}})
  }

  onCloseModal = (type) => {
    this.onEditModal(type, {open:false, issending:false, error:null})
  }

  onEditModal = (section, data) => {
    const {modals} = this.state;
    let tempModals = {...modals};
    tempModals[section]={...tempModals[section],...data};
    this.setState({modals:{...tempModals}})
  }

  onCloseSnackbar = (id) => {
    let temp = this.state[id]
    temp.open = false
    this.setState({[id]:{...temp}})
  }

  onActivateSuccessSnack = () => {
    const {success} = this.state
    let success_temp = {...success}
    success_temp.open = true
    this.setState({success:{...success_temp}})
  }

  render(){
    const {isloading, service_item_selected, modals, success, users, business_documents} = this.state
    const {language, catalogs, onClose, permission, permissionsType} = this.props
    const content = contentData[language]
    let contentView = <Grid container justify='center'><Grid item><SimpleLoader /></Grid></Grid>

    if(!isloading){
      contentView = (
        <ServiceForm 
          staticview={content.basicform}
          service={service_item_selected}
          business_documents={business_documents}
          onClose={onClose}
          onEditData={() => this.onOpenFileModal('edititem')}
          onUploadFile={(filedata) => this.onOpenFileModal('filemodal', filedata)}
          onDownloadFile={this.onDownloadFile}
          onDeleteFile={(filedata) => this.onOpenFileModal('warning_delete_item', filedata)}
          permission={permission}
          permissionsType={permissionsType}
        />
      )
    }

    let modalcontent = null

    if(modals.warning_delete_item){
      modalcontent=(
        <WarningModal 
          data={modals.warning_delete_item}
          content={content.modals.warning_delete_item}
          onClose={() => this.onCloseModal('warning_delete_item')}
          onDelete={this.onDeleteFile}
        />
      )
    }

    if(modals.edititem.open){
      modalcontent = (
        <FormAModal 
          origin_data={service_item_selected}
          addSpace={true}
          users={users}
          catalogs={catalogs.business_documents}
          data={modals.edititem}
          catalog_matrix={addmatrix}
          content={addcontent[language]}
          form_data={adddata}
          onClose={() => this.onCloseModal('edititem')}
          onSubmit={(data) => this.onEditItem(data, 'edititem')}

        />
      )
    }

    if(modals.filemodal.open){
      modalcontent = (
        <FileManagerModal
          data={modals.filemodal}
          open={true}
          language={language}
          onClose={() => this.onCloseModal('filemodal')}
          onSubmit={this.onSubmitFile}
          onDelete={this.onDeleteFile}
        />
      )
    }

    let subcontentView = null

    return(
      <div>
        {modalcontent}
        <Grid container spacing={4}>
          <Grid item xs={12}>
            {contentView}
          </Grid>
          <Grid item xs={12}>
            {subcontentView}
          </Grid>
        </Grid>
        <CustomSnackbar open={success.open} autoHideDuration={success.timeout} onClose={() => this.onCloseSnackbar(success.type)} message={success.message} variant={success.type}/>
      </div>
    )
  }
}

export default OutsourcingDetails