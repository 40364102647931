export const cleanData2Table = (data, nodata, users) => {

  const table = data.map(item => {
    
    let temp = {
      id:item.id_user, 
      image: item.image, 
      data:[]
    }
    
  
    let fullname = ''
    fullname = fullname + (item.first_name ? item.first_name : nodata)
    fullname = fullname + ' '+ (item.last_name ? item.last_name : '')

    let array = [
      item.id_user, 
      fullname, 
      item.username ? item.username : nodata,
      item.work_email ? item.work_email : nodata,
      item.client_user_level ? item.client_user_level : nodata,  
    ]

    temp.data = [...array]
    return temp
  })

  return table
}

//const findResponsible  = (id, users, nodata) => {
//  if(id !== null && id !== undefined){
//    if(users.length > 0){
//      let temp = users.filter(el => el.id_user === id)
//      return JSON.parse(JSON.stringify(temp[0]))
//    }
//  }
//  return {
//    first_name: nodata.split(' ')[0], 
//    last_name:nodata.split(' ')[1],
//    image:"0"
//  }
//}