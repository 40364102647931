import {mainServer} from '../../../../../variables/config.jsx'
import FileSaver from 'file-saver'
import axios from 'axios'

export const request_users = async () => {
  const logData = JSON.parse(localStorage.getItem('data'));
  mainServer.instance.defaults.headers.common['Authorization'] = logData.token;
  let response;
  response = await mainServer.instance.get("/user/employee/list/");
  return response.data.data.employees;
}

/**************************************** */

export const request_service = async (id) => {
  const logData = JSON.parse(localStorage.getItem('data'));
  mainServer.instance.defaults.headers.common['Authorization'] = logData.token;
  let response;
  response = await mainServer.instance.get("/stateregisterinscription/"+id.toString()+"/list")
  return response.data.data.state_register_inscription;
}

export const request_add_services = async (id, data) => {
  const logData = JSON.parse(localStorage.getItem('data'));
  mainServer.instance.defaults.headers.common['Authorization'] = logData.token;
  await mainServer.instance.post("/stateregisterinscription",data);
  let response;
  response = await mainServer.instance.get("/stateregisterinscription/"+id.toString()+"/list")
  return response.data.data.state_register_inscription;
}

export const request_edit_services = async (id, data, stateregisterinscriptionID) => {
  const logData = JSON.parse(localStorage.getItem('data'));
  mainServer.instance.defaults.headers.common['Authorization'] = logData.token;
  await mainServer.instance.patch("/stateregisterinscription/" + stateregisterinscriptionID, data);
  let response;
  response = await mainServer.instance.get("/stateregisterinscription/"+id.toString()+"/list")
  return response.data.data.state_register_inscription;
}

export const request_delete_services = async (id, stateregisterinscriptionID) => {
  const logData = JSON.parse(localStorage.getItem('data'));
  mainServer.instance.defaults.headers.common['Authorization'] = logData.token;
  await mainServer.instance.delete("/stateregisterinscription/" + stateregisterinscriptionID);
  let response;
  response = await mainServer.instance.get("/stateregisterinscription/"+id.toString()+"/list")
  return response.data.data.state_register_inscription;
}

/**************************************** */

export const request_uploadFile = async(id,path,file,ext, stateregisterinscriptionID) => {
  const logData = JSON.parse(localStorage.getItem('data'));
  mainServer.instance.defaults.headers.common['Authorization'] = logData.token;
  const url = 'stateregisterinscription/' + stateregisterinscriptionID + '/documents/' + path
  let response = await mainServer.instance.post(url, ext)
  const options = {headers: {'Content-Type': file.type}}
  delete axios.defaults.headers.common["Authorization"]
  response = await axios.put(response.data.data.path, file, options);
  mainServer.instance.defaults.headers.common['Authorization'] = logData.token;
  response = await mainServer.instance.get("/stateregisterinscription/"+id.toString()+"/list")
  return response.data.data.state_register_inscription
}

export const request_downloadFile = async(id, path) => {
  const logData = JSON.parse(localStorage.getItem('data'));
  mainServer.instance.defaults.headers.common['Authorization'] = logData.token;
  let response;
  const url = 'stateregisterinscription/' + id + '/documents/';
  response = await mainServer.instance.get(url)
  delete axios.defaults.headers.common["Authorization"]
  response = await axios.get(response.data.data.path, {responseType: 'blob'})
  FileSaver.saveAs(response.data, path)
}
