import React from 'react'
import { makeStyles, Card, Grid } from '@material-ui/core'
import DisplayText from '../../../../../components_v1/Texts/DisplayText'
import { onGetDateFormat } from '../../../../../shared/utility'
import { nodata } from '../../../../../texts/esp/general'

const styles = makeStyles(theme => ({
    responsibleCard: {
        width: 300,
        height: 110,
        padding: 32,
        textAlign: 'center',
        background: '#F8FBFF',
        border: '0.86px solid rgba(19, 57, 117, 0.19)',
        borderRadius: 8.62,
        boxShadow: 'none'
    },
}))

const contentData = {
    spanish: {
        responsible: 'Responsable',
        completed_date: 'Fecha de completado'
    }
}
const Responsibles = ({ data, step, language, responsible_banner }) => {

    const classes = styles()
    const content = contentData[language]

    let responsibleLABEL = null
    let completedDate = null
    let completedDateHour = null

    if(data){
        const { responsible, completed_date } = responsible_banner
        responsibleLABEL = data[`${step}_${responsible}`] ? data[`${step}_${responsible}`] : nodata
        completedDate = data[`${step}_${completed_date}`] ? onGetDateFormat(data[`${step}_${completed_date}`], 'DD/ MMMM / YYYY') : nodata
        completedDateHour = data[`${step}_${completed_date}`] ? onGetDateFormat(data[`${step}_${completed_date}`], 'HH:mm') : '-'
    }

    return (
        <Grid container justify='center' spacing={4}>
            <Grid item>
                <Card className={classes.responsibleCard}>
                    <Grid container justify='center' spacing={3}>
                        <Grid item xs={12}>
                            <DisplayText variant='body2' color='primary' align='center' style={{ fontWeight: 500 }}>
                                {content.responsible}
                            </DisplayText>
                        </Grid>
                        <Grid item xs={12}>
                            <DisplayText color='primary' align='center' style={{ fontSize: 18 }}>
                                {responsibleLABEL}
                            </DisplayText>
                        </Grid>
                    </Grid>
                </Card>
            </Grid>
            <Grid item>
                <Card className={classes.responsibleCard}>
                    <Grid container justify='center' spacing={3}>
                        <Grid item xs={12}>
                            <DisplayText variant='body2' color='primary' align='center' style={{ fontWeight: 500 }}>
                                {content.completed_date}
                            </DisplayText>
                        </Grid>
                        <Grid item xs={12}>
                            <DisplayText color='primary' align='center' style={{ fontSize: 18 }}>
                                {completedDate}
                            </DisplayText>
                        </Grid>
                        <Grid item xs={12}>
                            <DisplayText color='primary' align='center' style={{ fontSize: 13, fontWeight: 300 }}>
                                {completedDateHour} hrs.
                            </DisplayText>
                        </Grid>
                    </Grid>
                </Card>
            </Grid>
        </Grid>
    )
}

export default Responsibles