import React from 'react'
import LoaderIcon from 'react-loader-spinner'
import { blue } from '@material-ui/core/colors'

const SimpleLoader = (props) => {
  return(
    <LoaderIcon 
        type="ThreeDots" 
        color={blue[900]}
        height={20} 
        width={50}
      />
  )
}

export default SimpleLoader