import React from 'react';

import { Card, Grid, makeStyles, Typography, Icon } from '@material-ui/core';
import { isValidDocument, onGetDateFormat } from '../../../../../../../shared/utility';
import nouser from '../../../../../../../assets/nouser.png';
import nobusiness from '../../../../../../../assets/business2.png';
import { catalogs } from '../../../../../../../texts/esp/catalogs';
import { grey } from '@material-ui/core/colors';
import moment from 'moment';
import { imgServerS3 } from '../../../../../../../variables/config';

const TaskCard = (props) => {
	const { task, content, onSelect } = props;
	const { id_task, business_image, business, title, creator_image, creator, expiration_date, task_priority_id, task_status_id, task_type_id, order_number, finish_date } = task;
	const classes = useStyles();
	const _imgClient = isValidDocument(business_image) ? `${imgServerS3}${business_image}` : nobusiness;
	const _imgUser = isValidDocument(creator_image) ? `${imgServerS3}${creator_image}` : nouser;

	const status = catalogs.task_status.find((item) => item.value === task_status_id);
	const priority = catalogs.task_priority.find((item) => item.value === task_priority_id);
	const type = catalogs.task_type.find((item) => item.value === task_type_id);

    const expiringDate = finish_date ? moment(expiration_date).diff(moment(finish_date), 'hours') < 24 : moment(expiration_date).diff(moment(), 'hours') < 24

	return (
		<Card className={classes.root} onClick={() => onSelect(id_task)}>
			<Grid container>
				<Grid item xs />
				<Grid item>
					<Typography className={classes.id}># {id_task}</Typography>
				</Grid>
				<Grid item container xs={12} spacing={1} alignItems='center'>
					<Grid item>
						<img src={_imgClient} className={classes.img_client} />{' '}
					</Grid>
					<Grid item>
						<Typography className={classes.client_name}>{business}</Typography>
					</Grid>
				</Grid>
				<Grid item container xs={12} alignItems='center' style={{ marginTop: 20, marginBottom: 45, position: 'relative' }}>
					{/* <div className={classes.status_adornment} style={{ background: status.color }} /> */}
					<Grid item xs={6}>
						<Typography className={classes.title}>{title}</Typography>
						<Typography className={classes.status_tag} style={{ background: status.color }}>
							{status.label}
						</Typography>
					</Grid>
				</Grid>
				<Grid item container justify='space-between' xs={12} style={{ paddingBottom: 18, borderBottom: '1px solid #E7E7E7' }}>
					<Grid item xs={5} container alignItems='center'  spacing={1}>
						<Grid item >
							<img src={_imgUser} className={classes.img_user} />
						</Grid>
						<Grid item>
							<Typography className={classes.user_name}>{creator}</Typography>
						</Grid>
					</Grid>
					<Grid item xs={6}>
						<div className={classes.date_container} style={expiringDate ? {background: '#FFE7E7' } : {}}>
							<Typography className={classes.date_title}>{content.date_title}</Typography>
							<Typography className={classes.date_text} style={expiringDate ? {color: '#EA1313' } : {}}>{onGetDateFormat(expiration_date, 'DD/ MMMM / YYYY')}</Typography>
						</div>
					</Grid>
				</Grid>
				<Grid item container xs={12} style={{ marginTop: 15 }} alignItems='center'>
					<Grid item container xs={6}>
						<Grid item container alignItems='center'  justify='center' xs={12}>
							<Grid item>
								<Icon className={classes.bottom_info_icon}>{content.priority_icon}</Icon>
							</Grid>
							<Grid item>
								<Typography className={classes.bottom_info_title}>{content.priority_title}</Typography>
							</Grid>
						</Grid>
						<Grid item xs={12}>
							<Typography align='center' style={{ color: priority.color }} className={classes.bottom_info_text}>
								{priority.label}
							</Typography>
						</Grid>
					</Grid>
					<div style={{ height: 30, width: 1, background: '#e7e7e7' }} />
					<Grid item xs container>
						<Grid item container alignItems='center' justify='center' xs={12}>
							<Grid item>
								<Icon className={classes.bottom_info_icon}>{content.type_icon}</Icon>
							</Grid>
							<Grid item>
								<Typography className={classes.bottom_info_title}>{content.type_title}</Typography>
							</Grid>
						</Grid>
						<Grid item xs={12}>
							<Typography align='center' style={{ color: type.color }} className={classes.bottom_info_text}>
								{type.label}
							</Typography>
						</Grid>
					</Grid>
				</Grid>
			</Grid>
		</Card>
	);
};

const useStyles = makeStyles((theme) => ({
	root: {
		borderRadius: 14,
		boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.05)',
		padding: '15px 20px',
        cursor: 'pointer',
        '&:hover':{
            background: grey[100]
        }
	},
	id: {
		fontSize: '0.8rem',
		color: '#80848D',
		fontWeight: 600,
	},
	img_client: {
		width: 42,
		height: 42,
		borderRadius: '100%',
	},
	client_name: {
		fontSize: '0.75rem',
		fontWeight: 500,
		color: '#80848D',
	},
	title: {
		fontSize: '1rem',
		fontWeight: 500,
		color: theme.palette.primary.main,
	},
	status_adornment: {
		height: '100%',
		width: 3,
		position: 'absolute',
		left: -20,
		top: 0,
	},
	img_user: {
		width: 32,
		height: 32,
		borderRadius: '100%',
	},
	user_name: {
		fontSize: '0.75rem',
		fontWeight: 400,
		color: '#222222',
	},
	status_tag: {
		width: 'fit-content',
		padding: '0px 12px',
		color: 'white',
		fontSize: '0.75rem',
		fontWeight: 500,
		marginTop: 10,
	},
	date_container: {
		padding: '5px 10px',
		background: '#E0E6EF',
	},
	date_title: {
		fontSize: '0.65rem',
		color: '#9AA6B4',
		fontWeight: 500,
	},
	date_text: {
		fontSize: '0.8rem',
		color: theme.palette.primary.main,
		fontWeight: 600,
	},
	bottom_info_title: {
		fontSize: '0.8rem',
		color: '#80848D',
		fontWeight: 600,
	},
	bottom_info_icon: {
		fontSize: '0.8rem',
		color: '#80848D',
		marginRight: 5,
	},
	bottom_info_text: {
		fontSize: '0.9rem',
		fontWeight: 500,
	},
}));

export default TaskCard;
