import React, {Component} from 'react';
import { withStyles } from '@material-ui/core/styles';
import styles from './styles.jsx';
import {
  Card,
  Grid,
  CircularProgress,
  withWidth,
  Hidden
} from '@material-ui/core';
import {connect} from 'react-redux';
import { withRouter, Redirect } from 'react-router-dom';
import * as actions from '../../redux/actions.jsx';
import {SignIn as settings} from '../../variables/config.jsx';

import {RoundedButton} from '../../components/Buttons/GeneralButtons'
import InputText from '../../components/Inputs/InputText/InputText';
import {contentData} from './content'
import {formData} from './data'
import {request_login} from './requests'
import ascglogo from '../../assets/ASCGLOGO.png'
import smsmiembrologo from '../../assets/LogoSMSMIEMBRO.png'
import bannerASCG from '../../assets/company/Logotipo ASCG.png'
import bannerIcon from '../../assets/company/30years.png'

class SignIn extends Component{

  state = {
    formData: JSON.parse(JSON.stringify(formData)),
    isValid: false,
    isloading:false,
    error:null,
  }

  componentDidMount(){
    document.addEventListener("keydown", this.handleKeyPress, false);
    const {formData} = this.state;
    const content = contentData['spanish'];
    let temp = {...formData};
    Object.keys(formData).forEach(item => {
      temp[item].config = {...temp[item].config,...content.form[item]};
    });
    this.setState({formData:{...temp}})
  }

  handleKeyPress = (event) => {
    if(event.key === 'Enter'){
      this.onSubmit()
    }
  }

  onInputChange = (data) => {
    const {formData} = this.state;
    const id = data.config.id;
    let temp = {...formData};
    temp[id] = {...data};
    const isValidForm = this.validationForm(temp);
    this.setState({formData:{...temp}, isValid: isValidForm})
  }

  validationForm = (data) => {
    let isValid = true;
    Object.keys(data).forEach((item) => {
      if(data[item].isRequired && !data[item].isValid){
        isValid = false;
      }
    })
    return isValid;
  }

  onSubmit = () => {
    const {formData, isValid} = this.state;
    let data2Send = {};

    if(isValid){
      Object.keys(formData).forEach((item)=>{
        if(formData[item].isValid){
            data2Send = {...data2Send, [item]:formData[item].value}
        }
      })
      this.onSignInHandler(data2Send)
    }

  }

  onSignInHandler = async(data) => {
    try {
      await this.setState({isloading:true})
      await request_login(data)
      await this.setState({isloading:false, error:null})
    } catch (error) {
      if(error.response){
        this.setState({error:error.response.data.error.message})
      }else{
        this.setState({error:'No Internet'})
      }
      await this.setState({isloading:false})
    }
  }

  render(){
    const {classes} = this.props;
    const {formData, isloading, error} = this.state;
    const content = contentData['spanish']

    let contentRedirect = null;

    // Check if it already exist a valid session


    let temp = localStorage.getItem('data');
    if(temp){
      temp = JSON.parse(temp);
      let currentTime = new Date();
      let tokenTime = new Date(temp.exp*1000);
      if(currentTime.getTime() < tokenTime.getTime()){
        contentRedirect = <Redirect to={'/home'}/>
      }
    }

    let errorMesage = null;
    if(isloading){
      errorMesage = (
        <CircularProgress
          size={30}
          className={classes.progress}
          style={{ color: settings.spinner.color }}
          thickness={7}
        />
      );
    } else if(error){
      errorMesage = (
        <Grid item>
          <div className={classes.errorMessage}>
            {error}
          </div>
        </Grid>
      );
    };

    let mainContent = (
        <div className={classes.container}>
            <Grid container direction='column' alignItems='center' spacing={2}>
              <Grid item xs={12}>
                <Card className={classes.card}>
                  <div className={classes.iconBanner}><img src={bannerIcon} alt='' width='100%' height='100%'/></div>
                  <div className={classes.cardContent}>
                    {/* ////////////////////// LOGO //////////////////////////*/}
                    <Grid container justify='center' alignItems='center' spacing={5}>
                      <Grid item xs={12}>
                        <Grid container justify='center'>
                          <Grid item>
                            <img src={settings.logo} alt="CD" className={classes.ima}/>
                          </Grid>
                        </Grid>
                      </Grid>
                      {/* ////////////////////// FORM //////////////////////////*/}
                      <Grid item xs={12}>
                        <div className={classes.formContent}>
                          <Grid container spacing={1}>
                            <Grid item xs={12}>
                              <InputText
                                data={formData.username}
                                onChange={this.onInputChange}
                              />
                            </Grid>

                            <Grid item xs={12}>
                              <InputText
                                data={formData.password}
                                onChange={this.onInputChange}
                              />
                            </Grid>
                          </Grid>
                        </div>
                      </Grid>
                      {/* ////////////////////// ACTIONS //////////////////////////*/}
                      <Grid item xs={12}>
                        <Grid container direction='column' alignItems='center' spacing={1}>
                          <Grid item>
                            <RoundedButton
                              label={content.button.label}
                              onClick={this.onSubmit}
                              color='primary'
                            />
                          </Grid>
                          <Grid item xs={12} style={{height:'5px'}}>
                            {errorMesage}
                          </Grid>
                        </Grid>
                      </Grid>
                      {/* ////////////////////// ACTIONS //////////////////////////*/}
                      {/* ////////////////////// END //////////////////////////*/}
                    </Grid>
                  </div>
                </Card>
              </Grid>
              <Grid item>
                <Hidden smDown>
                  <Grid container justify='center'  alignItems='center' wrap='nowrap'>
                    <Grid item><img src={bannerASCG} alt='' width={500}/></Grid>
                  </Grid>
                </Hidden>
              </Grid>
              
            </Grid>
        </div>
    );


    return(
      <div className={classes.root}>
        {contentRedirect}
        {mainContent}
      </div>
    );
  }
}

export default withStyles(styles)(withWidth()(SignIn));
