export const formData = {
  name:{
    value: '',
    error: false,
    isVisited: false,
    isRequired: true,
    isValid: false,
    config:{
      id:'name',
      type:'text',
      fullWidth: true,
    },
    rules:{
      type:'distance',
      min:1,
      max:100
    }
  },
  fiscal_address:{
    value: '',
    error: false,
    isVisited: false,
    isRequired: true,
    isValid: false,
    config:{
      id:'fiscal_address',
      type:'text',
      fullWidth: true,
    },
    rules:{
      type:'distance',
      min:1,
      max:100
    }
  }
}