export const formData = {
  process_status_id:{
    value: null,
    error: false,
    isVisited: false,
    isRequired: false,
    isValid: false,
    options:[],
    config:{
      id:'process_status_id',
      type:'simple_select',
      fullWidth: true,
    },
    rules:{
      type:'none',
    }
  },
  declaration_period_id:{
    value: null,
    error: false,
    isVisited: false,
    isRequired: false,
    isValid: false,
    options:[],
    config:{
      id:'declaration_period_id',
      type:'simple_select',
      fullWidth: true,
    },
    rules:{
      type:'none',
    }
  },
  declaration_type_id:{
    value: null,
    error: false,
    isVisited: false,
    isRequired: false,
    isValid: false,
    options:[],
    config:{
      id:'declaration_type_id',
      type:'simple_select',
      fullWidth: true,
    },
    rules:{
      type:'none',
    }
  },
  accumulated_income:{
    value:'',
    error: false,
    isVisited: false,
    isRequired: false,
    isValid: false,
    config:{
      id:'accumulated_income',
      type:'number',
      fullWidth: true
    },
    rules:{
      type:'distance',
      min:1,
      max:100
    }
  },
  deductions:{
    value:'',
    error: false,
    isVisited: false,
    isRequired: false,
    isValid: false,
    config:{
      id:'deductions',
      type:'number',
      fullWidth: true
    },
    rules:{
      type:'distance',
      min:1,
      max:100
    }
  },
  fiscal_utility:{
    value:'',
    error: false,
    isVisited: false,
    isRequired: false,
    isValid: false,
    config:{
      id:'fiscal_utility',
      type:'number',
      fullWidth: true
    },
    rules:{
      type:'distance',
      min:1,
      max:100
    }
  },
  isr_to_pay:{
    value:'',
    error: false,
    isVisited: false,
    isRequired: false,
    isValid: false,
    config:{
      id:'isr_to_pay',
      type:'number',
      fullWidth: true
    },
    rules:{
      type:'distance',
      min:1,
      max:100
    }
  },
  total_to_pay:{
    value:'',
    error: false,
    isVisited: false,
    isRequired: false,
    isValid: false,
    config:{
      id:'total_to_pay',
      type:'number',
      fullWidth: true
    },
    rules:{
      type:'distance',
      min:1,
      max:100
    }
  },
  accumulated_loses:{
    value:'',
    error: false,
    isVisited: false,
    isRequired: false,
    isValid: false,
    config:{
      id:'accumulated_loses',
      type:'number',
      fullWidth: true
    },
    rules:{
      type:'distance',
      min:1,
      max:100
    }
  },
  exercise_loses:{
    value:'',
    error: false,
    isVisited: false,
    isRequired: false,
    isValid: false,
    config:{
      id:'exercise_loses',
      type:'number',
      fullWidth: true
    },
    rules:{
      type:'distance',
      min:1,
      max:100
    }
  },
  isr_in_favor:{
    value:'',
    error: false,
    isVisited: false,
    isRequired: false,
    isValid: false,
    config:{
      id:'isr_in_favor',
      type:'number',
      fullWidth: true
    },
    rules:{
      type:'distance',
      min:1,
      max:100
    }
  },
  operation_number:{
    value:'',
    error: false,
    isVisited: false,
    isRequired: false,
    isValid: false,
    config:{
      id:'operation_number',
      type:'text',
      fullWidth: true
    },
    rules:{
      type:'distance',
      min:1,
      max:100
    }
  },
  presentation_date:{
    value: null,
    error: false,
    isVisited: false,
    isRequired: false,
    isValid: false,
    config:{
      id:'presentation_date',
      type:'date',
      fullWidth: true,
    },
    rules:{
      type:'distance',
      min:1,
      max:100
    }
  },
  work_sheet:{
    key:'work_sheet',
    config:{
      type:'file'
    }
  },
  annual_receipt:{
    key:'annual_receipt',
    config:{
      type:'file'
    }
  },
  details_document:{
    key:'details_document',
    config:{
      type:'file'
    }
  },
  delivery_date:{
    value: null,
    error: false,
    isVisited: false,
    isRequired: false,
    isValid: false,
    config:{
      id:'delivery_date',
      type:'date',
      fullWidth: true,
    },
    rules:{
      type:'distance',
      min:1,
      max:100
    }
  },
  responsible_id:{
    value: null,
    error: false,
    isVisited: false,
    isRequired: false,
    isValid: false,
    options:[],
    config:{
      id:'responsible_id',
      type:'avatar_select',
      fullWidth: true,
    },
    rules:{
      type:'none',
    }
  },
  notes:{
    value:'',
    error: false,
    isVisited: false,
    isRequired: false,
    isValid: false,
    config:{
      id:'notes',
      type:'text',
      fullWidth: true
    },
    rules:{
      type:'distance',
      min:1,
      max:100
    }
  },
  extra_hours:{
    value:'',
    error: false,
    isVisited: false,
    isRequired: false,
    isValid: false,
    config:{
      id:'extra_hours',
      type:'number',
      fullWidth: true
    },
    rules:{
      type:'distance',
      min:1,
      max:100
    }
  },
  hours_proof:{
    key:'hours_proof',
    config:{
      type:'file'
    }
  },
  extra_charges:{
    value:'',
    error: false,
    isVisited: false,
    isRequired: false,
    isValid: false,
    config:{
      id:'extra_charges',
      type:'number',
      fullWidth: true
    },
    rules:{
      type:'distance',
      min:1,
      max:100
    }
  },
  charge_proof:{
    key:'charge_proof',
    config:{
      type:'file'
    }
  },
  collections_charge:{
    value:'',
    error: false,
    isVisited: false,
    isRequired: false,
    isValid: false,
    config:{
      id:'collections_charge',
      type:'number',
      fullWidth: true
    },
    rules:{
      type:'distance',
      min:1,
      max:100
    }
  },
  return_date:{
    value: null,
    error: false,
    isVisited: false,
    isRequired: false,
    isValid: false,
    config:{
      id:'return_date',
      type:'date',
      fullWidth: true,
    },
    rules:{
      type:'distance',
      min:1,
      max:100
    }
  },
  return_comment:{
    value:'',
    error: false,
    isVisited: false,
    isRequired: false,
    isValid: false,
    config:{
      id:'return_comment',
      type:'text',
      fullWidth: true
    },
    rules:{
      type:'distance',
      min:1,
      max:100
    }
  },
  completition_date:{
    value: null,
    error: false,
    isVisited: false,
    isRequired: false,
    isValid: false,
    config:{
      id:'completition_date',
      type:'date',
      fullWidth: true,
    },
    rules:{
      type:'distance',
      min:1,
      max:100
    }
  }
    
  }
