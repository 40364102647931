import {mainServer} from '../../../../variables/config'
import FileSaver from 'file-saver';
import axios from 'axios'

export const request_users = async () => {
  const logData = JSON.parse(localStorage.getItem('data'));
  mainServer.instance.defaults.headers.common['Authorization'] = logData.token;
  let response;
  response = await mainServer.instance.get("/user/employee/list/");
  return response.data.data.employees
}

export const request_service = async (id) => {
  const logData = JSON.parse(localStorage.getItem('data'));
  mainServer.instance.defaults.headers.common['Authorization'] = logData.token;
  let response;
  response = await mainServer.instance.get("/moralsatinscription/"+id.toString());
  return response.data.data.moral_sat_inscription;
}

export const request_business_documents = async (id) => {
  const logData = JSON.parse(localStorage.getItem('data'));
  mainServer.instance.defaults.headers.common['Authorization'] = logData.token;
  let response;
  response = await mainServer.instance.get("/businessdocument/"+id.toString());
  return response.data.data.tributary_mailbox_statuses;
}



export const request_updateService = async(id,data) => {
  const logData = JSON.parse(localStorage.getItem('data'));
  mainServer.instance.defaults.headers.common['Authorization'] = logData.token;
  await mainServer.instance.patch("/moralsatinscription/"+id.toString(), data);
  let response;
  response = await mainServer.instance.get("/moralsatinscription/"+id.toString());
  return response.data.data.moral_sat_inscription;
}

export const request_uploadFile = async(id, businessID, path,file, ext, type) => {
  console.log(type)
  const logData = JSON.parse(localStorage.getItem('data'));
  mainServer.instance.defaults.headers.common['Authorization'] = logData.token
  let url = ''
  if(type){
    url = 'businessdocument/' + businessID + '/' + path
  }else{
    url = 'moralsatinscription/' + businessID + '/' + path
  }
  let response = await mainServer.instance.post(url, ext)
  const options = {headers: {'Content-Type': file.type}}
  delete axios.defaults.headers.common["Authorization"]
  response = await axios.put(response.data.data.path, file, options);
  mainServer.instance.defaults.headers.common['Authorization'] = logData.token;
  response = await mainServer.instance.get("/moralsatinscription/"+businessID.toString());
  return response.data.data.moral_sat_inscription;
}

export const request_downloadFile = async(id,businessID,path,type) => {
  console.log(businessID)
  const logData = JSON.parse(localStorage.getItem('data'));
  mainServer.instance.defaults.headers.common['Authorization'] = logData.token;
  let response;
  let url = ''

  if(type){
    url = 'businessdocument/' + businessID + '/' + path
  }else{
    url = 'moralsatinscription/' + businessID + '/' + path
  }
  response = await mainServer.instance.get(url)
  delete axios.defaults.headers.common["Authorization"]
  response = await axios.get(response.data.data.path, {responseType: 'blob'})
  FileSaver.saveAs(response.data, path)
}

export const request_deleteFile = async(id,businessID,path,type) => {
  const logData = JSON.parse(localStorage.getItem('data'));
  mainServer.instance.defaults.headers.common['Authorization'] = logData.token;
  let response
  let url = ''
  if(type){
    url = '/businessdocument/' + businessID + '/' + path
  }else{
    url = 'moralsatinscription/' + businessID + '/' + path
  }
  await mainServer.instance.delete(url)
  response = await mainServer.instance.get("/moralsatinscription/"+businessID.toString());
  return response.data.data.moral_sat_inscription;
}