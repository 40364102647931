import React, { useState, useEffect } from 'react';
import BusinessCostTable from '../../../../../components_v1/businesscosts/BusinessCostTable';
import useBusinessCostSection from './useBusinessCostSection';

const BusinessCostSection = ({
    id_business
}) => {
    
    const {
        loading, 
        data,
        filter_data,
        tableFilter,
        total,
        onDownload,
        onUpdateTableFilter
    } = useBusinessCostSection({id_business})

    return ( 
        <div style={{marginTop:8}}>
            <BusinessCostTable
                hide_month={true}
                hide_search={true}
                loading={loading}
                data={data}
                filter_data={filter_data}
                tableFilter={tableFilter}
                total={total}
                onDownload={onDownload}
                onUpdateTableFilter={onUpdateTableFilter}
            />
            <div style={{height:60}} />
        </div>
     );
}
 
export default BusinessCostSection;