export const catalog_matrix = [
  {form_key:'pre_position_id', catalog_key:'client_user_positions'},
  {form_key:'classification_id', catalog_key:'client_classifications'},
  {form_key:'pre_fiscal_regime_id', catalog_key:'business_fiscal_regimes'},
  {form_key:'pre_language_id', catalog_key:'client_user_languages'},
  {form_key:'pre_area_id', catalog_key:'client_user_areas'},
  {form_key:'pre_country_id', catalog_key:'countries'},
  {form_key:'client_status_id', catalog_key:'client_statuses'},
  {form_key:'client_state_id', catalog_key:'client_state'},
  {form_key:'pre_responsible_id', catalog_key:'none', propname:'users'},
  {form_key:'responsible_id', catalog_key:'none', propname:'users'},
  {form_key:'restricted', catalog_key:'client_restricted'},
]