import React, { useState } from 'react'
import { AppBar, Box, Grid, makeStyles, Tab, Tabs, Typography } from '@material-ui/core'
import RoundedButton from '../../../../../../../components_v1/Actions/RoundedButton'
import SimpleCard from '../../../../../../../components_v1/Structure/Cards/SimpleCard'
import PermissionItem from '../../../../../../../components_v1/Structure/DisplayData/PermissionItem'
import DisplayText from '../../../../../../../components_v1/Texts/DisplayText'
import { PERMISSION_TYPES } from '../../../../../../../variables/config';
import { contentData } from './content'
import cx from 'classnames'
import { grey } from '@material-ui/core/colors'


const TabPanel = ({children, value, index, ...other}) => {
  
    return (
      <Typography
        component="div"
        role="tabpanel"
        hidden={value !== index}
        id={`scrollable-auto-tabpanel-${index}`}
        aria-labelledby={`scrollable-auto-tab-${index}`}
        {...other}
      >
        {value === index && <Box p={3}>{children}</Box>}
      </Typography>
    );
  
}

const PermissionContent = ({title, language, permissions, onEdit, permission}) => {

    const classes = styles()
    const [value, setValue] = useState(0)
    const content = contentData[language]

    const handleChange = (event,value) => {
        setValue(value)
    }
    
    const onUpdatePermissions = (localPermissions, serverPermissions) =>{
        let result = JSON.parse(JSON.stringify(localPermissions))
        if(serverPermissions && serverPermissions.length){
        Object.keys(localPermissions).forEach(item => {
            localPermissions[item].forEach((el,key) => {
            const _perm = serverPermissions.find(val => val.permission_module_id === el.id_permission_module)
            if(_perm) result[item][key] = {...result[item][key], id_permission_type:_perm.permission_type_id}
            })
        })
        }
        return result
    }

    const permissionsUpdated = onUpdatePermissions(content.permissions, permissions)

    let can_edit = true
    let can_create = true
    let has_full_access = true
    
    if(permission){
        can_edit = permission > PERMISSION_TYPES.READ
        can_create = permission > PERMISSION_TYPES.EDIT
        has_full_access = permission > PERMISSION_TYPES.CREATE
    }

    const header = (
        <AppBar position="sticky" color="primary">
            <Tabs variant="fullWidth" value={value} onChange={handleChange}>
                {content.header.map((item, key) => {
                    return <Tab value={key} label={item}/>
                })}
            </Tabs>
        </AppBar>
    )

    const pKeys = Object.keys(permissionsUpdated);

    const tabs = content.header.map((item,key) => {
        return <TabPanel value={value} index={key}>
            {permissionsUpdated[pKeys[key]].map(item =>{
                let status = content.status.find(e => e.id == item.id_permission_type)
                return <PermissionItem
                        className={cx({
                        [classes.permission_even]: key%2 == 0,
                        [classes.permission_odd]: key%2 != 0
                        })}
                        styles={{backgroundColor:'black'}}
                        module_name={item.name}
                        permission_id={item.id_permission_type}
                        permission={status.label}
                    />
            })}
        </TabPanel>
    })

    return (
        <div className={classes.root}>
            <SimpleCard>
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <DisplayText color='primary' variant='subtitle1' >{title}</DisplayText>
                    </Grid>
                    <Grid item xs={12}>
                        {header}
                        {tabs}
                    </Grid>
                    {can_edit ? <Grid item xs={12}>
                        <Grid container justify='flex-end'>
                            <Grid item>
                                <RoundedButton onClick={onEdit}>Editar</RoundedButton>
                            </Grid>
                        </Grid>
                    </Grid> : null}
                </Grid>
            </SimpleCard>
        </div>
    )

}

export default PermissionContent

const styles = makeStyles( theme => ({
    text:{
      color:grey[700],
      fontWeight:'500'
    },
    icon:{
      fontSize:12
    },
    block:{
      padding:'10px 20px 10px 20px'
    }
  
  }))