export const contentData = {
  spanish:{
    title:'Información Basica',
    nodata:'Sin Registro',
    error:'Sin registro',
    button:'Guardar',
    cancelButton:'Cancelar',
    image_button:'Cambiar',
    form:{
      specs:{
        label:'Especificaciones',
        placeholder:'Especificaciones'
      },
      responsible_id:{
        label:'Responsable',
        placeholder:'Responsable'
      },
      periodicity_id:{
        label:'Periodicidad',
        placeholder:'Periodicidad'
      },
    },
  },
}