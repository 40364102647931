import { Grid } from '@material-ui/core'
import React from 'react'
import SimpleCard from '../../Cards/SimpleCard'
import AvatarName from '../../DisplayData/AvatarName'
import GenericStatus from '../../DisplayData/GenericStatus'
import VerticalData from '../../DisplayData/VerticalData'
import DisplayText from '../../../Texts/DisplayText'
import { catalogs } from '../../../../texts/esp/catalogs'

const Banner = props => {
    const { evaluated, division, position, status } = props

    return (
        <div>
            <SimpleCard>
                <Grid container justify='space-between' alignItems='center'>
                    <Grid item>
                        <Grid container spacing={1}>
                            <Grid item xs={12}>
                                <DisplayText variant='subtitle2' color='textSecondary'>{evaluated.title}</DisplayText>
                            </Grid>
                            <Grid item xs={12}>
                                <AvatarName name={evaluated.name} imgSrc={evaluated.image} styles={{ width: 30, height: 30 }} />
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item>
                        <Grid container spacing={1}>
                            <Grid item xs={12}>
                                <DisplayText variant='subtitle2' color='textSecondary'>{division.title}</DisplayText>
                            </Grid>
                            <Grid item xs={12}>
                                <DisplayText variant='body2'>{division.data}</DisplayText>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item>
                        <Grid container spacing={1}>
                            <Grid item xs={12}>
                                <DisplayText variant='subtitle2' color='textSecondary'>{position.title}</DisplayText>
                            </Grid>
                            <Grid item xs={12}>
                                <DisplayText variant='body2'>{position.data}</DisplayText>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item>
                        <Grid container spacing={1}>
                            <Grid item xs={12}>
                                <DisplayText variant='subtitle2' color='textSecondary'>{status.title}</DisplayText>
                            </Grid>
                            <Grid item xs={12}>
                                <GenericStatus id={status.id} variant='body2' options={catalogs.steps} />
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </SimpleCard>
        </div>
    )
}

export default Banner