export const contentData = {
  spanish:{
    modals:{
      warning_delete_item:{
        title:'¿Estás seguro?',
        message:'¿Realmente quieres eliminar este registro? Este proceso no puede ser revertido',
        cancelButton:'Cancelar',
        deleteButton:'Eliminar'
      },
      edit_service:{
        title:'Editar servicio',
        button:'Guardar',
        cancelButton:'Cancelar'
      }
    },
    basicform:{
      title:'Información Detallada',
      button:'Editar',
      nodata:'Sin Registro',
      data:{
        rfc:{
          label:'',
          data:''
        },
        name:{
          label:'',
          data:''
        },
        requirements:{
          label:'',
          data:''
        },
        due_date:{
          label:'',
          data:''
        },
        process:{
          label:'',
          data:''
        },
        periodicity:{
          label:'',
          data:''
        },
        responsible:{
          label:'',
          data:''
        },
        estimated_delivery_date:{
          label:'',
          data:''
        },
        comments:{
          label:'',
          data:''
        },
        project_costs:{
          label:'',
          data:''
        },
        provider:{
          label:'',
          data:''
        },
        cost:{
          label:'',
          data:''
        },
        sell_price:{
          label:'',
          data:''
        }
      },
      content:{
        rfc:{
          label:'RFC'
        },
        name:{
          label:'Nombre'
        },
        requirements:{
          label:'Requisitos Técnicos'
        },
        due_date:{
          label:'Fecha Requerida'
        },
        process:{
          label:'Proceso'
        },
        periodicity:{
          label:'Periodicidad'
        },
        responsible:{
          label:'Responsable'
        },
        estimated_delivery_date:{
          label:'Fecha de Entrega Estimada'
        },
        comments:{
          label:'Comentarios'
        },
        project_costs:{
          label:'Costo del Proyecto'
        },
        provider:{
          label:'Proveedor'
        },
        cost:{
          label:'Costo'
        },
        sell_price:{
          label:'Precio de Venta'
        }
      },
      documents:{
        title:'Documentos',
        header:{title:'Archivos', actions:'Acciones'},
        files:[
          {key:'financial_statements',name:'Estados Financieros',path:'financialstatements',status:false, businessDocument:true},
          {key:'trial_balance',name:'Balanza de Comprobación',path:'trialbalance',status:false, businessDocument:true},

          {key:'ledger',name:'Libro Mayor',path:'ledger', status:false},
          {key:'payroll_report',name:'Reporte de Nóminas',path:'payrollreport',status:false},

          {key:'customs_declaration',name:'Pedimentos de Importación',path:'customsdeclaration',status:false, businessDocument:true},
          {key:'foreign_expenses',name:'Gastos Extranjeros',path:'foreignexpenses',status:false, businessDocument:true},
          {key:'expense_control',name:'Control de Gastos',path:'expensecontrol',status:false, businessDocument:true},

          {key:'aux',name:'Auxiliares',path:'aux',status:false},
          {key:'documents',name:'Documentos',path:'documents',status:false}
        ]
      }
    }
  }
};
