import React from 'react';
import { Redirect } from 'react-router';

const PermissionRedirect = ({permission, level}) => {
    if(!permission) return null
    //Check if user match the lower level permission
    if(permission.id_permission_type >= level) return null
    return <Redirect to='/profile'/>;
}
 
export default PermissionRedirect;