export const contentData = {
  spanish:{
    layout:{
      title:'Editar negocio',
      navigation:[
        {name:'Clientes',path:'/clients'},
        {name:'Detalles',path:'/clients/details/:id'},
        {name:'Negocios',path:'/clients/details/:id/businesses'},
        {name:'Detalles',path:'/clients/details/:id/businesses/:idbusiness'},
        {name:'Editar',path:'/clients/details/:id/businesses/:idbusiness/edit'},
      ],
    },
    
  }
}