export const formData = {
  
    rfc:{
      value: null,
      error: false,
      isVisited: false,
      isRequired: false,
      isValid: false,
      config:{
        id:'rfc',
        type:'text',
        fullWidth: true,
      },
      rules:{
        type:'rfc',
      }
    },
    responsible_id:{
      value: null,
      error: false,
      isVisited: false,
      isRequired: false,
      isValid: false,
      options:[],
      config:{
        id:'responsible_id',
        type:'avatar_select',
        fullWidth: true,
      },
      rules:{
        type:'none',
      }
    }
  
  }