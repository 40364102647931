import processIcon from '../../../assets/icons/process.svg'

export const contentData = {
    spanish: {
        title: 'Procesos',
        button: null,
        process: [
            { id: 1, label: 'Solución Integral', notifications: false, url: 'process/integralsolution/', svgIcon: processIcon },
            { id: 2, label: 'Personas Físicas', notifications: false, url: 'process/physicalpersons/', svgIcon: processIcon },
            { id: 3, label: 'Seguro Social', notifications: false, url: 'process/socialsecurity/', svgIcon: processIcon },
            { id: 4, label: 'Impuesto local sobre nómina', notifications: false, url: 'process/statetax/', svgIcon: processIcon },
            { id: 4, label: 'Timbrado y nómina', notifications: false, url: 'process/stamping/', svgIcon: processIcon },
        ]
    }
}
