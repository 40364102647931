export const contentData = {
  spanish:{
    title:'Información de Registro',
    nodata:'Sin Registro',
    error:'Sin registro',
    button:'Guardar',
    image_button:'Cambiar',
    form:{
      classification_id:{
        label:'Clasificación',
        placeholder:'Clasificación',
      },
      pre_fiscal_regime_id:{
        label:'Régimen Fiscal',
        placeholder:'Régimen Fiscal',
      },
      service:{
        label:'Servicio seleccionado',
        placeholder:'Servicio seleccionado',
      },
      pre_quotation:{
        label:'Cotización',
        placeholder:'Cotización',
      },
      pre_contact_date:{
        label:'Fecha de contacto',
        placeholder:'Fecha de contacto',
      },
      pre_contact_medium_id:{
        label:'Medio de contacto',
        placeholder:'Medio de contacto',
      },
      client_state_id:{
        label:'Estado del cliente',
        placeholder:'Estado del cliente',
      },
      pre_required_date:{
        label:'Fecha requerida',
        placeholder:'Fecha requerida',
      },
      pre_end_date:{
        label:'Fecha de fin',
        placeholder:'Fecha de fin',
      },
      pre_observations:{
        label:'Observaciones',
        placeholder:'Observaciones',
      },
      pre_contact_name:{
        label:'Nombre de contacto',
        placeholder:'Nombre de contacto',
      }
    },
  },
}