export const contentData = {
    spanish: {
        title: 'Subproceso',
        button: 'Continuar',
        back:'Regresar',
        client_response:'Respuesta del cliente',
        responsible_banner: {
            responsible: 'responsible',
            responsible_id: 'responsible_id',
            completed_date: 'completed_date'
        },
        accordions: [
            {
                id: 'selective_revision',
                title: 'Revisión Selectiva'
            },
            {
                id: 'financial_statement_sent',
                title: 'Envío de Estados Financieros'
            },
            {
                id: 'asiviewer_upload',
                title: 'Cargar en AsiViewer'
            },
            {
                id: 'client_responded',
                title: 'Respuesas al cliente'
            },
        ],
    }
}
