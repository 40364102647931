import React, { useState, useEffect } from 'react';
import BasicView from '../../../components_v1/Structure/Layouts/BasicView';
import SimpleTable from './components/SimpleTable';
import { request_businesses } from './requests';
import queryString from 'query-string' 
import AddBusinessModal from './modals/AddBusinessModal/AddBusinessModal';

const BusinessView = (props) => {

    const {history, language} = props
    const [success, setSuccess] = useState(null)
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState(null)
    //const [addbusinessmodal, setAddBussinessModal] = useState(false)
    const [businesses, setBusinessView] = useState([])

    //Table states
    const [total, setTotal] = useState(0)
    const [tableFilter, setTableFilter] = useState({limit:10,offset:0, order_by:'id_business', order:1})
    const [search, setSearch] = useState(null)

    useEffect(() => {
        const initModule = async() => {try {
            console.log(search)
            setLoading(true)
            const args = queryString.parseUrl(history.location.search)
            const {limit, offset, order_by, order} = tableFilter
            let filterArgs = [
                {"field":"name","operator":"LIKE","value":`%${search?search:''}%`},
            ]
            filterArgs = encodeURIComponent(JSON.stringify(filterArgs))
            const param2send = {limit:limit, offset:offset*limit, order_by:order_by, order:order}
            let params = `?${queryString.stringify(param2send)}&filter=${filterArgs}` //&filter=${filterArgs}
            const _businesses = await request_businesses(params)
            setBusinessView(_businesses.businesses)
            setTotal(_businesses.count)
            console.log(_businesses.businesses)
        } catch (error) {
            console.log(error)
        }
        setLoading(false)}
        initModule()
    },[tableFilter])

    const onChangeSearch = (val) => {
        setSearch(val)
        //setTableFilter({...tableFilter, offset:0})
    }


    return ( 
        <div>
            <BasicView 
                title='Negocios' 
                error={error} 
                onCloseError={() => setError(null)}
                success={success} 
                onCloseSuccess={() => setSuccess(null)}
            >
                {/*<AddBusinessModal open={addbusinessmodal} onClose={() => setAddBussinessModal(false)} history={history} language={language}/>*/}
                <SimpleTable 
                    search={search} 
                    onChangeSearch={onChangeSearch} 
                    //onAddRegister={() => setAddBussinessModal(true)}
                    onSelectedItem={(data) => history.push(`/businesses/${data.id_business}`)}
                    data={businesses} 
                    tableFilter={tableFilter}  
                    onUpdateTableFilter={(data) => setTableFilter(data)}
                    total={total} 
                    loading={loading} 
                />

            </BasicView>
        </div> 
     );
}
 
export default BusinessView;