export const contentData = {
  spanish:{
    title:'Editar cliente',
    nodata:'Sin Registro',
    error:'Sin registro',
    button:'Guardar',
    form:{
      name:{
        label:'Nombre completo',
        placeholder:'Nombre completo',
        helper:'Nombre no válido'
      },
      pre_position_id:{
        label:'Puesto',
        placeholder:'Puesto',
      },
      classification_id:{
        label:'Clasificación',
        placeholder:'CLasificación',
      },
      importance:{
        label:'Importancia',
        placeholder:'Importancia'
      },
      pre_fiscal_regime_id:{
        label:'Régimen Fiscal',
        placeholder:'Régimen Fiscal',
      },
      pre_language_id:{
        label:'Idioma',
        placeholder:'Idioma',
      },
      pre_area_id:{
        label:'Área',
        placeholder:'Área',
      },
      pre_country_id:{
        label:'País',
        placeholder:'País',
      },
      client_status_id:{
        label:'Estatus',
        placeholder:'Estatus',
      },
      client_state_id:{
        label:'Estado del proceso',
        placeholder:'Estado del proceso',
      },
      pre_contact_name:{
        label:'Nombre de contacto',
        placeholder:'Nombre de contacto',
        helper:'Nombre de contacto no válido'
      },
      pre_responsible_id:{
        label:'Socio',
        placeholder:'Socio',
        helper:'Seleccione un socio'
      },
      responsible_id:{
        label:'Responsable',
        placeholder:'Responsable',
        helper:'Seleccione un responsable'
      },
      antiquity:{
        label:'Antiguedad',
        placeholder:'Antiguedad',
        helper:'Indique la antiguedad'
      },
      restricted:{
        label:'¿Es cliente restringido?',
        placeholder:'¿Es cliente restringido?',
        helper:'Selecciona una opción'
      }
    },
  },
}