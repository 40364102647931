import { Grid, makeStyles } from '@material-ui/core';
import React, { useState, useEffect } from 'react';
import SimpleCard from '../../../../../../components_v1/Structure/Cards/SimpleCard';
import DisplayText from '../../../../../../components_v1/Texts/DisplayText';
import {onGetDecimalNumber, onGetTimeDuration} from '../../../../../../shared/utility';
import statusIcon from '../../../../../../assets/timereport/status.png'
import clientIcon from '../../../../../../assets/timereport/clients.png'
import businessIcon from '../../../../../../assets/timereport/business.png'
import complexityIcon from '../../../../../../assets/timereport/complexity.png'
import durationIcon from '../../../../../../assets/timereport/duration.png'
import satisfactionIcon from '../../../../../../assets/timereport/satisfaction.png'
import workHoursIcon from '../../../../../../assets/timereport/work_hours.png'

const Statistics = ({statistics}) => {

    const {average_client_satisfaction, average_complexity, average_duration,
        business_number, client_number, scheduled_time, time_report_status, worked_time} = statistics

    return ( 
        <div style={{paddingBottom:16}}>
            <Grid container spacing={2}>
                <Grid item xs={6} md={3}>
                    <StatisticCard image={statusIcon} title='Estatus' data={time_report_status}/>
                </Grid>
                <Grid item xs={6} md={3}>
                    <StatisticCard image={satisfactionIcon} title='Satistacción' data={onGetDecimalNumber(average_client_satisfaction) }/>
                </Grid>
                <Grid item xs={6} md={3}>
                    <StatisticCard image={complexityIcon} title='Complejidad' data={onGetDecimalNumber(average_complexity)}/>
                </Grid>
                <Grid item xs={6} md={3}>
                    <StatisticCard image={durationIcon} title='Duración' data={onGetTimeDuration(average_duration)}/>
                </Grid>
                <Grid item xs={6} md={3}>
                    <StatisticCard image={workHoursIcon} title='Horas trabajadas' data={onGetTimeDuration(worked_time)}/>
                </Grid>
                <Grid item xs={6} md={3}>
                    <StatisticCard image={workHoursIcon} title='Horas laborales' data={onGetTimeDuration(scheduled_time)}/>
                </Grid>
                <Grid item xs={6} md={3}>
                    <StatisticCard image={clientIcon} title='No. clientes' data={client_number}/>
                </Grid>
                <Grid item xs={6} md={3}>
                    <StatisticCard image={businessIcon} title='No. negocios' data={business_number}/>
                </Grid>
            </Grid>
        </div>
     );
}
 
export default Statistics;

const useCardStyles = makeStyles(theme => ({
    image:{
        width:54
    }
}))

const StatisticCard = props => {

    const classes = useCardStyles()
    const {image, title, data} = props

    return(
        <SimpleCard padding='small'>
            <div>
            <Grid container spacing={1}>
                <Grid item>
                    <img src={image} alt='' className={classes.image}/>
                </Grid>
                <Grid item xs>
                    <Grid container>
                        <Grid item xs={12}>
                            <DisplayText variant='body2' color='textSecondary'>{title}</DisplayText>
                        </Grid>
                        <Grid item xs={12}>
                            <DisplayText variant='body1'>{data}</DisplayText>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid> 
            </div>
            
        </SimpleCard>
    )
}