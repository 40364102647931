import React, { useState } from 'react'
import { CircularProgress, Grid, Icon, IconButton, LinearProgress, makeStyles, Paper, Table, TableBody, TableCell, TableHead, TableRow } from '@material-ui/core'
import { grey, red } from '@material-ui/core/colors'
import DisplayText from '../../Texts/DisplayText'
import empty from '../../../assets/empty.png'
import { catalogs } from '../../../texts/esp/catalogs'
import SimpleCard from '../Cards/SimpleCard'
import InputSelect from '../../Forms/InputSelect'
import { useEffect } from 'react'
import cx from 'classnames'

const useStyles = makeStyles(theme => ({
    root: {},
    addIcon: {
        background: theme.palette.secondary.main,
        borderRadius: '100%',
        color: 'white',
        '&:hover': {
            background: theme.palette.secondary.dark,
        }
    },
    paper: {
        marginTop: 4,
        borderRadius: 8,
        boxShadow: '0 10px 24px 0 rgba(82, 91, 115, .12)',
    },
    paperError: {
        boxShadow: '0 10px 24px 0 rgba(225, 91, 115, .12)',
    },
    cell: {
        borderColor: grey[200],
    },
    cellClick: {
        '&:hover': {
            cursor: 'pointer'
        }
    },
    row: {
        '&:hover': {
            background: grey[100]
        }
    },
    cellHeader: {
        borderColor: grey[300],
        paddingTop: 16,
        paddingBottom: 16
    },
    button: {
        background: theme.palette.primary.main,
        padding: '6px 12px',
        borderRadius: 32,
        color: 'white',
        '&:hover': {
            background: theme.palette.primary.dark,
        }
    },
    toolbar: {
        marginBottom: 16
    },
    actions: {
        marginTop: 16
    },
    table: {
        minWidth: 1100
    },
    tableWrapper: {
        overflowX: 'auto',
    },
    nodata: {
        marginTop: 40
    }
}))

const PerformanceTable = props => {

    const classes = useStyles()
    const { loading, access, contentError, header, data, onSelectedItem } = props
    const [form, setForm] = useState(JSON.parse(JSON.stringify(formData)))
    const [selected, setSelected] = useState(1)
    const keys = data ? Object.keys(data.data2table) : null
    const [error, setError] = useState(false)

    useEffect(() => {
        setError(contentError)
    }, [contentError])

    const onChange = (data) => {
        let temp = { ...form }
        temp.employee_performance_id = { ...data };
        setSelected(data.value)
        setForm(temp)
    }

    return (
        <div>
            <Grid container spacing={4}>
                <Grid item xs={12}>
                    <Grid container>
                        <Grid item xs={5}>
                            <SimpleCard padding='small'>
                                <InputSelect
                                    color='primary'
                                    data={form.employee_performance_id}
                                    onChange={onChange}
                                />
                            </SimpleCard>
                        </Grid>
                        <Grid item xs></Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12}>
                    {loading ? (
                        <Grid container justify='center'>
                            <Grid item xs={12}><LinearProgress /></Grid>
                        </Grid>
                    ) : null}
                    {!data ?
                        <Grid container justify='center'>
                            <Grid item><CircularProgress size={32} /></Grid>
                        </Grid>
                        :
                        (data !== {} ?
                            <Grid container spacing={4}>
                                <Grid item xs={12}>
                                    <Paper className={cx({
                                        [classes.paper]: true,
                                        [classes.paperError]: error
                                    })}>
                                        {data ? (
                                            <div className={classes.tableWrapper}>
                                                <Table className={classes.table}>
                                                    {header ? (
                                                        <TableHead>
                                                            <TableRow>
                                                                {header.map(item => {
                                                                    const status = catalogs.employee_performance_status.find(e => e.value === item.id)

                                                                    return (
                                                                        <TableCell padding={item.id === 1 ? 'default' : 'default'} className={classes.cellHeader} key={item.id.toString()}>
                                                                            {status ?
                                                                                <Grid container key={item.id}>
                                                                                    <Grid item xs={12}>
                                                                                        <DisplayText variant='subtitle2' align='center' style={{ color: status.color }}>{item.label}</DisplayText>
                                                                                    </Grid>
                                                                                    <Grid item xs={12}>
                                                                                        <DisplayText variant='subtitle2' align='center'>{status.value}</DisplayText>
                                                                                    </Grid>
                                                                                </Grid>
                                                                                :
                                                                                <DisplayText variant='subtitle2' color='primary'>{item.label}</DisplayText>
                                                                            }
                                                                        </TableCell>
                                                                    )
                                                                })}
                                                            </TableRow>
                                                        </TableHead>
                                                    ) : null}
                                                    <TableBody>
                                                        {keys.map((item, key) => {
                                                            if (selected == 1 || selected == key + 2) {
                                                                return data.data2table[item].data.map(info => {
                                                                    const { id, total_id, subid, label, status, score } = info
                                                                    let data2send = { id: id, total_id: total_id }

                                                                    if (access)
                                                                        return (
                                                                            <TableRow key={key.toString()} className={classes.row} >
                                                                                <TableCell className={classes.cell}><DisplayText variant='body2'>{label}</DisplayText></TableCell>
                                                                                <TableCell className={classes.cellClick} onClick={loading ? null : () => onSelectedItem({ ...data2send, value: 1 })}><DisplayText variant='body2' align='center'>{score === 1 ? <Icon fontSize='large' style={{ color: status.color }}>check</Icon> : null}</DisplayText></TableCell>
                                                                                <TableCell className={classes.cellClick} onClick={loading ? null : () => onSelectedItem({ ...data2send, value: 2 })}><DisplayText variant='body2' align='center'>{score === 2 ? <Icon fontSize='large' style={{ color: status.color }}>check</Icon> : null}</DisplayText></TableCell>
                                                                                <TableCell className={classes.cellClick} onClick={loading ? null : () => onSelectedItem({ ...data2send, value: 3 })}><DisplayText variant='body2' align='center'>{score === 3 ? <Icon fontSize='large' style={{ color: status.color }}>check</Icon> : null}</DisplayText></TableCell>
                                                                                <TableCell className={classes.cellClick} onClick={loading ? null : () => onSelectedItem({ ...data2send, value: 4 })}><DisplayText variant='body2' align='center'>{score === 4 ? <Icon fontSize='large' style={{ color: status.color }}>check</Icon> : null}</DisplayText></TableCell>
                                                                                <TableCell className={classes.cellClick} onClick={loading ? null : () => onSelectedItem({ ...data2send, value: 5 })}><DisplayText variant='body2' align='center'>{score === 5 ? <Icon fontSize='large' style={{ color: status.color }}>check</Icon> : null}</DisplayText></TableCell>
                                                                                <TableCell className={classes.cell}><DisplayText variant='body2' align='center'>{score}</DisplayText></TableCell>
                                                                            </TableRow>
                                                                        )
                                                                    return (
                                                                        <TableRow key={key.toString()} className={classes.row} >
                                                                            <TableCell className={classes.cell}><DisplayText variant='body2'>{label}</DisplayText></TableCell>
                                                                            <TableCell className={classes.cell}><DisplayText variant='body2' align='center'>{score === 1 ? <Icon fontSize='large' style={{ color: status.color }}>check</Icon> : null}</DisplayText></TableCell>
                                                                            <TableCell className={classes.cell}><DisplayText variant='body2' align='center'>{score === 2 ? <Icon fontSize='large' style={{ color: status.color }}>check</Icon> : null}</DisplayText></TableCell>
                                                                            <TableCell className={classes.cell}><DisplayText variant='body2' align='center'>{score === 3 ? <Icon fontSize='large' style={{ color: status.color }}>check</Icon> : null}</DisplayText></TableCell>
                                                                            <TableCell className={classes.cell}><DisplayText variant='body2' align='center'>{score === 4 ? <Icon fontSize='large' style={{ color: status.color }}>check</Icon> : null}</DisplayText></TableCell>
                                                                            <TableCell className={classes.cell}><DisplayText variant='body2' align='center'>{score === 5 ? <Icon fontSize='large' style={{ color: status.color }}>check</Icon> : null}</DisplayText></TableCell>
                                                                            <TableCell className={classes.cell}><DisplayText variant='body2' align='center'>{score}</DisplayText></TableCell>
                                                                        </TableRow>
                                                                    )
                                                                })
                                                            }
                                                        })}
                                                        <TableRow>
                                                            <TableCell className={classes.cell}><DisplayText variant='h6'>TOTAL:</DisplayText></TableCell>
                                                            <TableCell className={classes.cell}></TableCell>
                                                            <TableCell className={classes.cell}></TableCell>
                                                            <TableCell className={classes.cell}></TableCell>
                                                            <TableCell className={classes.cell}></TableCell>
                                                            <TableCell className={classes.cell}></TableCell>
                                                            {data && <TableCell className={classes.cell}><DisplayText variant='h6'>{selected == 1 ? data.total : data.data2table[keys[selected - 2]].total}</DisplayText></TableCell>}
                                                        </TableRow>
                                                    </TableBody>
                                                </Table>
                                            </div>

                                        ) : null}
                                    </Paper>
                                </Grid>
                                {error &&
                                    <Grid item xs={12}>
                                        <DisplayText variant='body2' style={{ color: red[700] }}>La tabla de evaluación no está completada</DisplayText>
                                    </Grid>
                                }
                            </Grid>
                            :
                            <div className={classes.nodata}>
                                <Grid container justify='center' alignItems='center' direction='column'>
                                    <Grid item>
                                        <img src={empty} alt='' width={180} />
                                    </Grid>
                                    <Grid item>
                                        <DisplayText>No se encontraron registros</DisplayText>
                                    </Grid>
                                </Grid>
                            </div>

                        )
                    }
                </Grid>
            </Grid>
        </div>
    )
}

const formData = {
    employee_performance_id: {
        value: 1,
        error: false,
        isVisited: false,
        isRequired: true,
        isValid: false,
        options: [...catalogs.employee_performance],
        config: {
            id: 'employee_performance_id',
            type: 'simple_select',
            fullWidth: true,
            label: 'Categorías de desempeño',
        },
        rules: {
            type: 'none',
        }
    },
}

export default PerformanceTable