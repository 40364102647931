import { catalogs } from "../../../texts/esp/catalogs";

export const formData = {
    notes:{
        value:null,
        isRequired:false,
        isValid:false,
        isVisited:false,
        formType:'normal',
        config:{id:'notes', type:'text', placeholder:'Seleccione una fecha', helperText:'La fecha no es válida'},
        rules:{type:'none'}
    },
    completition_date:{
        value:null,
        isRequired:false,
        isValid:false,
        isVisited:false,
        formType:'normal',
        config:{id:'completition_date', type:'date', placeholder:'Seleccione una fecha', helperText:'La fecha no es válida'},
        rules:{type:'none'}
    },
    completed:{
        value:null,
        isRequired:false,
        isValid:false,
        isVisited:false,
        formType:'checkbox',
        config:{id:'completed', type:'checkbox'},
        rules:{type:'none'}
    },

}