import {mainServer} from '../../variables/config'

export const request_reauth = async() => {
  const logData = JSON.parse(localStorage.getItem('data'))
  mainServer.instance.defaults.headers.common['Authorization'] = logData.token
  let response
  response = await mainServer.instance.get("/login/reauthenticate")
  localStorage.setItem('data',JSON.stringify(response.data.data))
}

export const request_get_user_info = async() => {
  const logData = JSON.parse(localStorage.getItem('data'))
  mainServer.instance.defaults.headers.common['Authorization'] = logData.token
  let response
  response = await mainServer.instance.get("/user/employee/me")
  return response.data.data.employee
}

export const request_permissions = async() => {
  const logData = JSON.parse(localStorage.getItem('data'))
  mainServer.instance.defaults.headers.common['Authorization'] = logData.token
  let response;
  response = await mainServer.instance.get("/permission/me/list");
  return response.data.data.user_permissions
}

export const request_areas = async() => {
  const logData = JSON.parse(localStorage.getItem('data'))
  mainServer.instance.defaults.headers.common['Authorization'] = logData.token
  let response;
  response = await mainServer.instance.get("/area/list");
  return response.data.data.areas
}

export const request_notifications = async() => {
  const logData = JSON.parse(localStorage.getItem('data'))
  mainServer.instance.defaults.headers.common['Authorization'] = logData.token
  let response;
  response = await mainServer.instance.get("notificationv2/general");
  return response.data.data
}