export const formData = {
    tax_payment_date:{
      value: null,
      error: false,
      isVisited: false,
      isRequired: false,
      isValid: false,
      config:{
        id:'tax_payment_date',
        type:'date',
        fullWidth: true,
      },
      rules:{
        type:'distance',
        min:1,
        max:100
      }
    },
    presentation_limit_date:{
      value: null,
      error: false,
      isVisited: false,
      isRequired: false,
      isValid: false,
      config:{
        id:'presentation_limit_date',
        type:'date',
        fullWidth: true,
      },
      rules:{
        type:'distance',
        min:1,
        max:100
      }
    },
    work_sheet:{
      key:'work_sheet',
      config:{
        type:'file'
      }
    },
    process:{
      value: '',
      error: false,
      isVisited: false,
      isRequired: true,
      isValid: false,
      config:{
        id:'process',
        type:'text',
        fullWidth: true,
      },
      rules:{
        type:'distance',
        min:1,
        max:100
      }
    },
    status:{
      value: '',
      error: false,
      isVisited: false,
      isRequired: true,
      isValid: false,
      config:{
        id:'status',
        type:'text',
        fullWidth: true,
      },
      rules:{
        type:'distance',
        min:1,
        max:100
      }
    },
    client_attention_info_1_id:{
      value: null,
      error: false,
      isVisited: false,
      isRequired: false,
      isValid: false,
      options:[],
      config:{
        id:'client_attention_info_1_id',
        type:'simple_select',
        fullWidth: true,
      },
      rules:{
        type:'none',
      }
    },
    client_attention_info_2_id:{
      value: null,
      error: false,
      isVisited: false,
      isRequired: false,
      isValid: false,
      options:[],
      config:{
        id:'client_attention_info_2_id',
        type:'simple_select',
        fullWidth: true,
      },
      rules:{
        type:'none',
      }
    },
    payment_quantity:{
      value:'',
      error: false,
      isVisited: false,
      isRequired: false,
      isValid: false,
      config:{
        id:'payment_quantity',
        type:'number',
        fullWidth: true
      },
      rules:{
        type:'distance',
        min:1,
        max:100
      }
    },
    surcharges_and_updates:{
      value:'',
      error: false,
      isVisited: false,
      isRequired: false,
      isValid: false,
      config:{
        id:'surcharges_and_updates',
        type:'number',
        fullWidth: true
      },
      rules:{
        type:'distance',
        min:1,
        max:100
      }
    },
    payment_total:{
      value:'',
      error: false,
      isVisited: false,
      isRequired: false,
      isValid: false,
      config:{
        id:'payment_total',
        type:'number',
        fullWidth: true
      },
      rules:{
        type:'distance',
        min:1,
        max:100
      }
    },
    capture_line_imss_infonavit:{
      key:'capture_line_imss_infonavit',
      config:{
        type:'file'
      }
    },
    payment_limit_date:{
      value: null,
      error: false,
      isVisited: false,
      isRequired: false,
      isValid: false,
      config:{
        id:'payment_limit_date',
        type:'date',
        fullWidth: true,
      },
      rules:{
        type:'distance',
        min:1,
        max:100
      }
    },
    responsible_id:{
      value: null,
      error: false,
      isVisited: false,
      isRequired: false,
      isValid: false,
      options:[],
      config:{
        id:'responsible_id',
        type:'avatar_select',
        fullWidth: true,
      },
      rules:{
        type:'none',
      }
    },
    client_answer:{
      key:'client_answer',
      config:{
        type:'file'
      }
    },
    client_comment:{
      value: null,
      error: false,
      isVisited: false,
      isRequired: false,
      isValid: false,
      config:{
        id:'client_comment',
        type:'text',
        fullWidth: true,
      },
      rules:{
        type:'distance',
        min:1,
        max:100
      }
    },
    notes:{
      value:'',
      error: false,
      isVisited: false,
      isRequired: false,
      isValid: false,
      config:{
        id:'notes',
        type:'text',
        fullWidth: true
      },
      rules:{
        type:'distance',
        min:1,
        max:100
      }
    },
    extra_hours:{
      value:'',
      error: false,
      isVisited: false,
      isRequired: false,
      isValid: false,
      config:{
        id:'extra_hours',
        type:'number',
        fullWidth: true
      },
      rules:{
        type:'distance',
        min:1,
        max:100
      }
    },
    hours_proof:{
      key:'hours_proof',
      config:{
        type:'file'
      }
    },
    extra_charges:{
      value:'',
      error: false,
      isVisited: false,
      isRequired: false,
      isValid: false,
      config:{
        id:'extra_charges',
        type:'number',
        fullWidth: true
      },
      rules:{
        type:'distance',
        min:1,
        max:100
      }
    },
    charge_proof:{
      key:'charge_proof',
      config:{
        type:'file'
      }
    },
    collections_charge:{
      value:'',
      error: false,
      isVisited: false,
      isRequired: false,
      isValid: false,
      config:{
        id:'collections_charge',
        type:'number',
        fullWidth: true
      },
      rules:{
        type:'distance',
        min:1,
        max:100
      }
    },
    completition_date:{
      value: null,
      error: false,
      isVisited: false,
      isRequired: false,
      isValid: false,
      config:{
        id:'completition_date',
        type:'date',
        fullWidth: true,
      },
      rules:{
        type:'distance',
        min:1,
        max:100
      }
    }
  }
