import React from 'react';
import { Checkbox, Grid, Icon, IconButton, TableBody, TableRow } from '@material-ui/core';
import TCell from './TCell';
import moment from 'moment-timezone'
import { grey } from '@material-ui/core/colors';
import DisplayText from '../../../../Texts/DisplayText';
import { isValidDocument, onGetFormatNumber } from '../../../../../shared/utility';
import { nodata } from '../../../../../texts/esp/general';

const novalue = '-'

const TData = ({id, value, type, fixed, options, position, readOnly, editBtn, onUploadDocument, onDownloadDocument, onDeleteDocument, can_edit, can_create, has_full_access, log, expiration_date}) => {



    if(type === 'date'){
        let momentDate = moment(value)
        momentDate = momentDate.isValid() ? momentDate.format('DD MMM YY') : novalue

        //console.log(moment(value).format(), ', fecha actual: ',  moment().format())
        //console.log(moment(value).format() < moment().format(), expiration_date)
        if(moment(value).format() < moment().format() && expiration_date){
            return <TCell fixed={fixed} position={position}><span style={{color:'#b71c1c'}}  >{momentDate}</span></TCell>
        }else{
            return <TCell fixed={fixed} position={position}>{momentDate}</TCell>
        }
         
    }

    if(type === 'cash'){
        let money = onGetFormatNumber(value)
        return <TCell fixed={fixed} position={position}>{money}</TCell>
    }

    if(type === 'status'){
        if(log) console.log(value)
        let statusObj = options.find(el => el.value === value)
        return <TCell fixed={fixed} position={position}>
            <Grid container alignItems='center' wrap='nowrap'>
                <Grid item><Icon style={{marginTop:6,marginRight:4, fontSize:14, color: statusObj ? statusObj.color : grey[300]}}>brightness_1</Icon></Grid>
                <Grid item><DisplayText variant='body2'>{statusObj ? statusObj.label : 'N/D'}</DisplayText></Grid>
            </Grid>
        </TCell>
    }
    if(type === 'document'){
        let isValid = isValidDocument(value)
        if(readOnly){
            return <TCell fixed={fixed} position={position}>
                <Grid container >
                    <Grid item><IconButton color='primary' disabled={!isValid} onClick={() => onDownloadDocument(id)}><Icon fontSize='small'>cloud_download</Icon></IconButton></Grid>
                </Grid>
            </TCell>
        }
        return <TCell fixed={fixed} position={position}>
            <Grid container >
                <Grid item><IconButton color='primary' disabled={!can_edit} onClick={() => onUploadDocument(id)}><Icon fontSize='small'>cloud_upload</Icon></IconButton></Grid>
                <Grid item><IconButton color='primary' disabled={!isValid} onClick={() => onDownloadDocument(id)}><Icon fontSize='small'>cloud_download</Icon></IconButton></Grid>
                <Grid item><IconButton color='primary' disabled={!isValid || !has_full_access} onClick={() => onDeleteDocument(id)}><Icon fontSize='small'>close</Icon></IconButton></Grid>
            </Grid>
        </TCell>
    }
    if(type === 'checkbox'){
        return <TCell fixed={fixed} position={position}>
            <Checkbox checked={parseInt(value) === 1} readOnly />
        </TCell>
    }
    if(type === 'boolean'){
        let _value = '-'
        if(value !== undefined && value !== null){
            if(value === 1){
                _value = 'Sí'
            }else{
                _value = 'No'
            }
        }

        return <TCell fixed={fixed} position={position}>{_value}</TCell>
    }

    /*if(editBtn){
        return<TCell>
            <Grid container wrap='nowrap'>
                <Grid item><IconButton color='primary' ><Icon fontSize='small'>edit</Icon></IconButton></Grid>
                <Grid item>{value}</Grid>
            </Grid>
        </TCell>
    }*/

    return <TCell fixed={fixed} position={position}>{value ? value : nodata}</TCell>
    //to change the color
    //return <TCell fixed={fixed} position={position}><span style={{color:color ? color : 'rgba(0, 0, 0, 0.87)'}} >{value ? value : nodata}</span></TCell>
}
 
export default React.memo(TData);