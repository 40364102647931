import React, { useState, useEffect } from 'react';
import BasicView from '../../../components_v1/Structure/Layouts/BasicView';
import { request_employees, request_evaluations } from './requests';
import { onGetErrorMessage, onGetMonthsCatalog, onGetPeriodsCatalog, onGetUserID } from '../../../shared/utility';
import queryString from 'query-string'
import moment from 'moment'
import EvaluationPDF from '../../../components_v1/Structure/EvaluationGenericView/components/EvaluationPDF'
import SimpleTable from './components/SimpleTable';
import { cleanData2Table } from './customFunctions';
import { contentData } from './content';
import { Redirect } from 'react-router';
import { pdf } from '@react-pdf/renderer';
import { saveAs } from 'file-saver';

const Evaluations = (props) => {

    const { history, language, permissions, permissionsType } = props
    const content = contentData[language]

    const [loading, setLoading] = useState(false)
    const [sending, setSending] = useState(false)
    const [success, setSuccess] = useState(null)
    const [error, setError] = useState(null)

    const [total, setTotal] = useState(0)
    const [tableFilter, setTableFilter] = useState({ limit: 10, offset: 0 })
    const [search, setSearch] = useState(null)

    const [evaluations, setEvaluations] = useState([])
    const [users, setUsers] = useState([])
    const [task_type, setTaskType] = useState(-1)

    const [month, setMonth] = useState(moment().month())
    const [year, setYear] = useState(moment().year())
    const [month_options, setMonthOptions] = useState(onGetMonthsCatalog(moment().year()))

    //new for periods
    const [period, setPeriod] = useState(Math.ceil(moment().month()/2))
    const [periods, setPeriods] = useState(onGetPeriodsCatalog(moment().year()))

    //console.log(moment().month())

    useEffect(() => {
        const initModule = async () => {
            //setSuccess('Bienvenido')
            setLoading(true)
            try {
                const userID = onGetUserID()
                const { limit, offset } = tableFilter
                //const rangeDate = month_options.find(el => el.value === month)
                //console.log(period)
                const rangeDate = periods.find(el => el.value === period)
                let filterArgs = [
                    { "field": "auto_date", "operator": ">=", "value": rangeDate.start_date },
                    { "field": "auto_date", "operator": "<=", "value": rangeDate.end_date },
                    { "field": 'evaluator_id', "operator": "LIKE", "value": userID },
                    { "field": "evaluated","operator":"LIKE","value":`%${search?search:''}%`},
                ]
                const param2send = { language_id: 2, limit: limit, offset: offset * limit, order_by: 'id_employee_evaluation', order: 1 }
                filterArgs = encodeURIComponent(JSON.stringify(filterArgs))
                const params = `?${queryString.stringify(param2send)}&filter=${filterArgs}`
                const _evaluations = await request_evaluations(params)
                setTotal(_evaluations.count)
                setEvaluations(_evaluations.employee_evaluations)
                let response = await request_employees()
                setUsers([...response])
            } catch (error) {
                console.log(error)
                setError(onGetErrorMessage(error))
            }
            setLoading(false)
        }

        initModule()
    }, [tableFilter, month, year, task_type])



    const onChangeFilter = (value, type) => {
        setTableFilter({ limit: 10, offset: 0 })
        //if (type === 'month') setMonth(value)
        if(type === 'period') setPeriod(value)
        if (type === 'year') {
            setYear(value)
            //setMonthOptions(onGetMonthsCatalog(value.toString()))
            //setMonth(1)
            setPeriods(onGetPeriodsCatalog(value.toString()))
            setPeriod(1)
        }

    }
    const onChangeSearch = (val) => {
        setSearch(val)
        //setTableFilter({...tableFilter, offset:0})
    }

    const generatePDF = async (documentData) => {
        try {
            setSending(true)
            const blob = await pdf(<EvaluationPDF {...documentData} />).toBlob()
            saveAs(blob, "evaluation.pdf");
        } catch (error) {
            console.log(error)
        }
        setSending(false)
    };

    const data2table = cleanData2Table(evaluations, content.nodata, users)
    let redirectContent = null
    let _permission = permissions.find(e => e.id_permission_module === 0)
    if (_permission && _permission.permission_type_id === permissionsType.NO_ACCESS) redirectContent = <Redirect to='/profile' />

    return (
        <div>
            {redirectContent}
            <BasicView
                title={content.title}
                sending={sending}
                error={error}
                onCloseError={() => setError(null)}
                success={success}
                onCloseSuccess={() => setSuccess(null)}
            >
                <div style={{ paddingTop: 32 }}>
                    <SimpleTable
                        header={content.header}
                        task_type={task_type}
                        month={month}
                        year={year}
                        period={period}
                        monthCatalog={month_options}
                        periodCatalog={periods}
                        data={data2table}
                        origin_data={evaluations}
                        tableFilter={tableFilter}
                        total={total}
                        loading={loading}
                        generatePDF={generatePDF}
                        onSelectedItem={(data) => history.push(`/evaluations/${data.id_employee_evaluation}`)}
                        onUpdateTableFilter={(data) => setTableFilter(data)}
                        onChangeFilter={onChangeFilter}
                        onChangeSearch={onChangeSearch}
                        search={search}
                    />
                </div>
            </BasicView>
        </div>
    );
}

export default Evaluations