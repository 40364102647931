export const contentData = {
    spanish: {
        title: 'Evaluaciones',
        button: null,
        nodata: 'Sin Registro',
        filter: [
            { value: 1, label: 'Histórico' },
            { value: 2, label: 'Estadísticas' }
        ],
        header: [
            { id: 1, label: 'ID' },
            { id: 2, label: 'Periodo' },
            { id: 3, label: 'Monto' },
            { id: 4, label: 'Estatus' },
            { id: 5, label: 'Detalles' },
        ],
        charts:{
            results:{
                id:'results_total',
                label:'Obtención de resultados'
            },
            quality:{
                id:'quality_total',
                label:'Calidad'
            },
            relations:{
                id:'relations_total',
                label:'Relaciones interpersonales'
            },
            iniciative:{
                id:'iniciative_total',
                label:'Iniciativa'
            },
            organization:{
                id:'organization_total',
                label:'Organización'
            },
            total:{
                id:'total',
                label:'Puntaje Final'
            }
        },
        modal:{
            title:'Detalles'
        }
    }
}
