import React from 'react'
import { Grid, makeStyles } from '@material-ui/core'
import DisplayText from './DisplayText'
import cx from 'classnames'
import { grey } from '@material-ui/core/colors'
import { blueGrey } from '@material-ui/core/colors'

const StepperStatus = ({ id, color, label, align }) => {

    return (
        <Grid container justify={align ? align : 'flex-start'} alignItems='center' spacing={2} wrap='nowrap'>
            <Grid item>
                <StepIcon id={id} color={color} />
            </Grid>
            <Grid item>
                <DisplayText variant='body2'>
                    {label}
                </DisplayText>
            </Grid>
        </Grid>
    )
}

const styles = makeStyles(theme => ({
    number: {
        width: 20,
        height: 20,
        textAlign: 'center',
        borderRadius: '100%',
        color: 'white',
        padding: 5
    },
    button: {
        '&:hover': {
            cursor: 'pointer'
        }
    },
    primary: {
        backgroundColor: theme.palette.primary.dark
    },
    secondary: {
        backgroundColor: '#30C7FF'
    },
    grey: {
        color: grey[300],
        backgroundColor: blueGrey[500]
    }
}))

export const StepIcon = ({ id, color, onClick }) => {

    const classes = styles()

    return (

        <div
            className={cx({
                [classes.number]: true,
                [classes.primary]: color === 'primary',
                [classes.secondary]: color === 'secondary',
                [classes.grey]: color === 'grey',
                [classes.button]: onClick
            })}
            onClick={onClick}
        >
            <DisplayText align='center' style={{ fontSize: 14 }}>{id}</DisplayText>
        </div>

    )
}

export default StepperStatus