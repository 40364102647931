import React, {Component} from 'react'
import { withStyles } from '@material-ui/core/styles'
import { Grid} from '@material-ui/core'
import styles from './styles'
import {contentData} from './content'

import BasicView from '../../../../components/Layouts/BasicView/BasicView'
import EditUserInfo from './EditUserInfo/EditUserInfo'
import EditWorkInfo from './EditWorkInfo/EditWorkInfo'
import EditContactInfo from './EditContactInfo/EditContactInfo'
import EditBenefits from './EditBenefits/EditBenefits';
import EditPermissions from './EditPermissions/EditPermissions'
import ProfilePictureModal from '../../../../components/Modals/ProfilePictureModal/ProfilePictureModal'

import {
  request_user_info, 
  request_permissions,
  request_uploadFile,
  request_deleteFile,
  request_update_user,
  request_update_permissions,
  request_user_areas,
  sleep,
  request_user_add_area,
  request_user_delete_area
} from './requests'
import { connect } from 'react-redux'
import { actionTypes } from '../../../../redux/actions'



class EditUser extends Component{

  state = {
    isloading:false,
    user:null,
    userID:null,
    permissions:[],
    areas:[],
    modals:{
      imagemodal:{open:false,issending:false,error:null},
      warning_delete_file:{open:false, issending:false, error:null, id:null}
    },
    forms:{
      basicinfo:{issending:false, error:null},
      workinfo:{issending:false, error:null},
      contactinfo:{issending:false, error:null},
      benefits:{issending:false, error:null},
      permission:{issending:false, error:null},
    },
    success:{open:false,timeout:2000,type:'success',message:'Successful action!'},
  }

  componentDidMount(){
    this.initModule()
  }

  onCloseSnackbar = (id) => {
    let temp = this.state[id];
    temp.open = false;
    this.setState({[id]:{...temp}})
  }

  getUserID = () => {
    const {history} = this.props
    const path = history.location.pathname
    return path.substring(path.lastIndexOf('/')+1)
  }

  cleanPermissions = (permissions) => {
    let cleanData = []
    permissions.map(item => {
      let array = {
        id_permission_module: item.permission_module_id,
        name: item.permission_module,
        id_permission_type: item.permission_type_id
      }
      cleanData.push(array)
    })

    return cleanData
  }

  onActivateSuccessSnack = () => {
    const {success} = this.state;
    let success_temp = {...success};
    success_temp.open = true;
    this.setState({success:{...success_temp}})
  }

  initModule = async() => {
    try{
      const {history} = this.props
      const path = history.location.pathname
      const userID = path.substring(path.lastIndexOf('/')+1)
      this.setState({isloading:true})
      const _user = await request_user_info(userID)
      const response = await request_permissions(userID)
      const permissions = this.cleanPermissions([...response])
      this.setState({user:_user,permissions:[...permissions], isloading:false})
    }catch(e){
      console.log(e)
    }
  }

  onReturn = () => {
    const {history} = this.props
    const path = history.location.pathname
    const userID = path.substring(path.lastIndexOf('/')+1)
    history.push('/users/details/'+userID)
  }

  onCloseModal = (type) => {
    this.onEditModal(type, {open:false, issending:false, error:null})
  }

  onEditModal = (section, data) => {
    const {modals} = this.state
    let tempModals = {...modals}
    tempModals[section]={...tempModals[section],...data}
    this.setState({modals:{...tempModals}})
  }

  onEditForm = (section, data) => {
    const {forms} = this.state
    let temp = {...forms}
    temp[section]={...temp[section],...data}
    this.setState({forms:{...temp}})
  }

  onOpenFileModal = (section, filedata) => {
    const {modals} = this.state
    let tempModals = {...modals}
    tempModals[section].open=true
    if(filedata){
      tempModals[section].data = {...filedata} 
    }
    this.setState({modals:{...tempModals}})
  }

  onSubmitPicture = async(type, file) => {
    try {
      const {history} = this.props
      const path = history.location.pathname
      const userID = path.substring(path.lastIndexOf('/')+1)
      this.onEditModal('imagemodal',{issending:true})
      const aux = file[0].name.split('.')
      const ext = {type:aux[aux.length-1]}
      let response = await request_uploadFile(userID, type, file[0], ext);
      this.setState({user:{...response}})
      this.onCloseModal('imagemodal')
      this.onActivateSuccessSnack()
    } catch (error) {
      if(error.response){
        this.onEditForm('imagemodal',{issending:false, error:error.response.data.error.message})
      }
    }
  }

  onDeletePicture = async() => {
    try {
      const {history} = this.props
      const path = history.location.pathname
      const userID = path.substring(path.lastIndexOf('/')+1)
      this.onEditModal('imagemodal',{issending:true})
      let response = await request_deleteFile(userID, 'image');
      this.setState({user:{...response}})
      this.onCloseModal('imagemodal')
      this.onActivateSuccessSnack()
    } catch (error) {
      if(error.response){
        this.onEditForm('imagemodal',{issending:false, error:error.response.data.error.message})
      }
    }
  }

  onEditUserInfo = async(data, type) => {
    const {user} = this.state
    try {
      const userID = this.getUserID()
      this.onEditForm(type,{issending:true})
      if(data.area_id){
        if(user.area_id) await request_user_delete_area(userID, user.area_id)
        await request_user_add_area(userID,data.area_id)
        const _user = await request_user_info(userID)
        this.setState({user:_user})
      }
      let response = await request_update_user(userID, data)
      this.setState({user:{...response}})
      this.onEditForm(type,{issending:false, error:null})
      this.onActivateSuccessSnack()
      await sleep(600)
      this.onReturn();
    } catch (error) {
      this.onEditForm(type,{issending:false})
      if(error.response){
        this.onEditForm(type,{error:error.response.data.error.message})
      }
    }
  }

  onEditPermissions = async(data, type) => {
    try {
      const {updatePermissions, onUpdatePermissions} = this.props
      const userID = this.getUserID()
      const actualUserID = JSON.parse(localStorage.getItem('data')).user_id
      this.onEditForm(type,{issending:true})
      let response = await request_update_permissions(userID, data)
      let permissions = this.cleanPermissions(response)
      if(userID == actualUserID){
        onUpdatePermissions(response)
        updatePermissions(permissions)

      }
      this.setState({permissions: [...permissions]})
      this.onEditForm(type,{issending:false, error:null})
      this.onActivateSuccessSnack()
      //await sleep(600)
      //this.onReturn();
    } catch (error) {
      this.onEditForm(type,{issending:false})
      if(error.response){
        this.onEditForm(type,{error:error.response.data.error.message})
      }
    }
  }

  render(){

    const {history, language, catalogs} = this.props;
    const {isloading, forms, user, success, modals, permissions, areas} = this.state
    const content = contentData[language]
    let modalcontent = null
    
    if(modals.imagemodal.open){
      modalcontent = (
        <ProfilePictureModal
          data={modals.imagemodal}
          language={language}
          onClose={() => this.onCloseModal('imagemodal')}
          onSubmit={this.onSubmitPicture}
          onDelete={this.onDeletePicture}
        />
      )
    }

    
    return(
      <BasicView
        history={history}
        selected={0}
        onChange={this.onChangeView}
        content={content.layout}
        returnBtn={true}
        onReturn={this.onReturn}
        success={success}
        onCloseSnackbar={this.onCloseSnackbar}
        isloading={isloading}
      >
        {modalcontent}
        <Grid container spacing={4}>
          <Grid item xs={12}>
            <EditUserInfo
              language={language}
              catalogs={catalogs}
              user={user}
              areas={areas}
              issending={forms.basicinfo.issending}
              error={forms.basicinfo.error}
              onSubmit={(data) => this.onEditUserInfo(data, 'basicinfo')}
              onChangeImage={()=> this.onOpenFileModal('imagemodal')}
            />
          </Grid>
          <Grid item xs={12} lg={6}>
            <Grid container spacing={4}>
              <Grid item xs={12}>
                <EditWorkInfo
                  language={language}
                  catalogs={catalogs}
                  user={user}
                  issending={forms.workinfo.issending}
                  error={forms.workinfo.error}
                  onSubmit={(data) => this.onEditUserInfo(data, 'workinfo')}
                />
              </Grid>
              <Grid item xs={12}>
                <EditContactInfo
                  language={language}
                  catalogs={catalogs}
                  user={user}
                  issending={forms.contactinfo.issending}
                  error={forms.contactinfo.error}
                  onSubmit={(data) => this.onEditUserInfo(data, 'contactinfo')}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} lg={6}>
            <Grid container spacing={4}>
              <Grid item xs={12}>
                <EditBenefits
                  language={language}
                  catalogs={catalogs}
                  user={user}
                  issending={forms.benefits.issending}
                  error={forms.benefits.error}
                  onSubmit={(data) => this.onEditUserInfo(data, 'benefits')}
                />
              </Grid>
              <Grid item xs={12}>
                <EditPermissions
                  language={language}
                  catalogs={catalogs}
                  permissions={permissions}
                  issending={forms.permission.issending}
                  error={forms.permission.error}
                  onSubmit={(data) => this.onEditPermissions(data, 'permission')}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </BasicView>
      
    )
  }
}

const mapDispatchToProps = dispatch => {
  return{
    onUpdateUser: (user) => dispatch({type: actionTypes.AUTH_UPDATE_USER, user}),
    onUpdatePermissions: (permissions) => dispatch({type: actionTypes.AUTH_UPDATE_PERMISSIONS, permissions})

  }
}


export default connect(null, mapDispatchToProps)(withStyles(styles)(EditUser))