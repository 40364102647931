import { Avatar, Grid } from '@material-ui/core';
import React, { useState, useEffect } from 'react';
import RoundedButton from '../../../../../../components_v1/Actions/RoundedButton';
import SimpleCard from '../../../../../../components_v1/Structure/Cards/SimpleCard';
import VerticalData from '../../../../../../components_v1/Structure/DisplayData/VerticalData';
import { onGetTimeDuration } from '../../../../../../shared/utility';
import nouser from '../../../../../../assets/nouser.png'
import StatusData from '../../../../../../components_v1/Structure/DisplayData/StatusData';
import { nodata } from '../../../../../../texts/esp/general';
import { red } from '@material-ui/core/colors';
import AvatarPicture from '../../../../../../components_v1/Structure/DisplayData/AvatarPicture';
import { PERMISSION_TYPES } from '../../../../../../variables/config';

const ProspectInfoForm = ({data, onEdit, onChangePicture, permission}) => {


    let quotedHours = '0 h'
    let restricted
    if(data){
        quotedHours = onGetTimeDuration(data.monthly_quoted_time)
        restricted = data.restricted ? 'Negocio restringido' : null
    } 


    let can_edit = true
    let can_create = true
    let has_full_access = true
    if(permission){
        can_edit = permission.permission_type_id > PERMISSION_TYPES.READ
        can_create = permission.permission_type_id > PERMISSION_TYPES.EDIT
        has_full_access = permission.permission_type_id > PERMISSION_TYPES.CREATE
    }

    return ( 
        <SimpleCard>
            <Grid container>
                <Grid item xs={12} md={6}>
                    <Grid container alignItems='center' direction='column' spacing={2}>
                        <Grid item>
                            <AvatarPicture can_edit={can_edit} nopicture={nouser} onChangePicture={onChangePicture} imgSrc={data ? data.image : null}/>
                        </Grid>
                        {restricted ? <Grid item><StatusData color={red[700]} data={restricted}/></Grid> : null}
                    </Grid>
                </Grid>
                <Grid item xs={12} md={6}>
                    <Grid container spacing={3}>
                        <Grid item xs={12} md={6}>
                            <VerticalData title='Nombre' data={data ? data.name : null}/>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <VerticalData title='Puesto' data={data ? data.pre_position : null}/>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <VerticalData icon='language' title='Idioma' data={data ? data.pre_language : null}/>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <VerticalData title='Pais' data={data ? data.pre_country : null}/>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <VerticalData icon='business' title='Área' data={data ? data.pre_area : null}/>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <VerticalData title='Estatus' data={data ? data.client_status : null}/>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <VerticalData title='Responsable' data={data ? data.responsible : null}/>
                        </Grid>
                        {can_edit ? <Grid item xs={12}>
                            <Grid container justify='flex-end'>
                                <Grid item>
                                    <RoundedButton onClick={onEdit}>Editar</RoundedButton>
                                </Grid>
                            </Grid>
                        </Grid> : null}

                    </Grid>
                </Grid>
            </Grid>
        </SimpleCard>
     );
}
 
export default ProspectInfoForm