import moment from 'moment'

export const cleanData2Table = (prevData, currentData, noData) =>{
    let temp = {
        id: currentData.id_ctrl_imss_inscription,
        completed: currentData.completed ? true : false,
        files:[],
        data:[]
    }

    let fixedDataA = [
        currentData.businesses_name ? currentData.businesses_name : noData,
        currentData.rfc ? currentData.rfc : noData,
        currentData.client_status ? currentData.client_status : noData,
        currentData.client_classification ? currentData.client_classification : noData,
        currentData.imss_inscription_request_date ? moment(currentData.imss_inscription_request_date).format('DD-MM-YYYY') : noData,
        currentData.imss_inscription_estimated_delivery_date ? moment(currentData.imss_inscription_estimated_delivery_date).format('DD-MM-YYYY') : noData,
        currentData.imss_inscription_quoted_time ? currentData.imss_inscription_quoted_time : noData,
        {key:'imss_inscription_documents'},
        currentData.imss_inscription_service_description ? currentData.imss_inscription_service_description : noData,
    ];

    let varData = [
        currentData.proceadure ? currentData.proceadure : noData,
        {key:'receipt'},
        currentData.comments ? currentData.comments : noData,
        {key:'client_response'},
        currentData.client_comment ? currentData.client_comment : noData,
        currentData.responsible ? currentData.responsible : noData,
        currentData.notes ? currentData.notes : noData,
        currentData.extra_hours ? currentData.extra_hours : noData,
        {key:'hours_proof'},
        currentData.extra_charges ? currentData.extra_charges : noData,
        {key:'charge_proof'},
        currentData.collections_charge ? currentData.collections_charge : noData,
        currentData.completition_date ? moment(currentData.completition_date).format('DD-MM-YYYY') : noData,
    ]

    let origin_data = {
        proceadure_id : currentData.proceadure_id,
        comments: currentData.comments,
        client_comment: currentData.client_comment, 
        responsible_id: currentData.responsible_id,
        notes : currentData.notes,
        extra_hours : currentData.extra_hours,
        extra_charges : currentData.extra_charges,
        collections_charge : currentData.collections_charge,
        completition_date : currentData.completition_date
    }

    let tempA = {...temp}
    tempA.data = [...fixedDataA]
    tempA.files = [
        {id:currentData.id_ctrl_imss_inscription,key:'imss_inscription_documents',name:'Documentación Adjunta',path:'imssinscriptiondocuments',
            status: checkStatus(currentData, 'imss_inscription_documents')}
    ]
    prevData.fixedDataA.push(tempA);

    let tempVar = {...temp}
    tempVar.data = [...varData]
    tempVar.files = [
        {id:currentData.id_ctrl_imss_inscription,key:'receipt',name:'Acuse',path:'receipt',
            status: checkStatus(currentData, 'receipt')},
        {id:currentData.id_ctrl_imss_inscription,key:'client_response',name:'Respuesta Cliente',path:'clientresponse',
            status: checkStatus(currentData, 'client_response')},
        {id:currentData.id_ctrl_imss_inscription,key:'hours_proof',name:'Comprobante de horas',path:'hoursproof',
            status: checkStatus(currentData, 'hours_proof')},
        {id:currentData.id_ctrl_imss_inscription,key:'charge_proof',name:'Tabla SAT',path:'chargeproof',
            status: checkStatus(currentData, 'charge_proof')}
    ]
    tempVar.origin_data = {...origin_data}
    
    prevData.varData.push(tempVar);
    return prevData;
}

const checkStatus = (item, key) => {
    return item[key] !== "0" && item[key] !== 0 && item[key] !== ""
}
