import { Grid } from '@material-ui/core';
import React from 'react';
import SimpleCard from '../../../../../../components_v1/Structure/Cards/SimpleCard';
import VerticalData from '../../../../../../components_v1/Structure/DisplayData/VerticalData';
import { onGetDateFormat } from '../../../../../../shared/utility';
import nouser from '../../../../../../assets/nouser.png'
import ProfileAvatar from '../../../../../../components/Avatars/ProfileAvatar'

const InfoForm = ({data}) => {

    return ( 
        <SimpleCard>
            <Grid container>
                <Grid item xs={12} md={6}>
                    <Grid container alignItems='center' direction='column' spacing={2}>
                        <Grid item>
                        <ProfileAvatar image={data ? data.image : nouser} />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12} md={6}>
                    <Grid container spacing={3}>
                        <Grid item xs={12} md={6}>
                            <VerticalData title='Nombre(s)' data={data ? data.first_name : null}/>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <VerticalData title='Apellido(s)' data={data ? data.last_name : null}/>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <VerticalData title='Usuario' data={data ? data.username : null}/>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <VerticalData title='Género' data={data ? data.gender : null}/>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <VerticalData title='Escolaridad' data={data ? data.scolarity : null }/>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <VerticalData title='Especialización' data={data ? data.specialization : null}/>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <VerticalData title='Puesto' data={data ? data.employee_status : null}/>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <VerticalData title='Área' data={data ? data.area_name : null}/>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <VerticalData icon='calendar_today' title='Fecha de nacimiento' data={data ? onGetDateFormat(data.birth_date) : null}/>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <VerticalData icon='language' title='Idioma' data={data ? data.language : null}/>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <VerticalData icon='schedule' title='Zona horaria' data={data ? data.timezone : null}/>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </SimpleCard>
     );
}
 
export default InfoForm