import {mainServer} from '../../../../../variables/config.jsx'
import FileSaver from 'file-saver'
import axios from 'axios'

export const request_users = async () => {
  const logData = JSON.parse(localStorage.getItem('data'));
  mainServer.instance.defaults.headers.common['Authorization'] = logData.token;
  let response;
  response = await mainServer.instance.get("/user/employee/list/");
  return response.data.data.employees;
}

/**************************************** */

export const request_service_item = async (id) => {
  const logData = JSON.parse(localStorage.getItem('data'));
  mainServer.instance.defaults.headers.common['Authorization'] = logData.token;
  let response;
  response = await mainServer.instance.get("/technology/"+id.toString())
  return response.data.data.technology;
}

export const request_add_service_item = async (id, data) => {
  const logData = JSON.parse(localStorage.getItem('data'));
  mainServer.instance.defaults.headers.common['Authorization'] = logData.token;
  await mainServer.instance.post("/technology",data);
  let response;
  response = await mainServer.instance.get("/technology/"+id.toString())
  return response.data.data.technology;
}

export const request_edit_service_item = async (id, data) => {
  const logData = JSON.parse(localStorage.getItem('data'));
  mainServer.instance.defaults.headers.common['Authorization'] = logData.token;
  await mainServer.instance.patch("/technology/" + id, data);
  let response;
  response = await mainServer.instance.get("/technology/"+id.toString())
  return response.data.data.technology;
}

export const request_delete_service_item = async (id, data) => {
  const logData = JSON.parse(localStorage.getItem('data'));
  mainServer.instance.defaults.headers.common['Authorization'] = logData.token;
  await mainServer.instance.delete("/technology/" +id+"/"+data);
  let response;
  response = await mainServer.instance.get("/technology/"+id.toString())
  return response.data.data.technology;
}

/**************************************** */

export const request_uploadFile = async(id,file,ext) => {
  const logData = JSON.parse(localStorage.getItem('data'));
  mainServer.instance.defaults.headers.common['Authorization'] = logData.token;
  const url = 'technology/' + id + '/documents'
  let response = await mainServer.instance.post(url, ext)
  const options = {headers: {'Content-Type': file.type}}
  delete axios.defaults.headers.common["Authorization"]
  response = await axios.put(response.data.data.path, file, options);
  mainServer.instance.defaults.headers.common['Authorization'] = logData.token;
  response = await mainServer.instance.get("/technology/"+id.toString())
  return response.data.data.technology
}

export const request_downloadFile = async(id) => {
  
  const logData = JSON.parse(localStorage.getItem('data'));
  mainServer.instance.defaults.headers.common['Authorization'] = logData.token;
  let response;
  const url = 'technology/' + id + '/documents/';
  response = await mainServer.instance.get(url)
  delete axios.defaults.headers.common["Authorization"]
  response = await axios.get(response.data.data.path, {responseType: 'blob'})
  FileSaver.saveAs(response.data)
}

export const request_deleteFile = async(id) => {
  const logData = JSON.parse(localStorage.getItem('data'));
  mainServer.instance.defaults.headers.common['Authorization'] = logData.token;
  let response
  const url = 'technology/' + id + '/documents/';
  await mainServer.instance.delete(url)
  response = await mainServer.instance.get("/technology/"+id.toString())
  return response.data.data.technology
}