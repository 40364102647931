import { Grid, makeStyles } from '@material-ui/core';
import React, { useState, useEffect } from 'react';
import ActionModalBar from '../../../../../../../components_v1/Actions/ActionModalBar';
import InputText from '../../../../../../../components_v1/Forms/InputText';
import SimpleModal from '../../../../../../../components_v1/Structure/Modals/SimpleModal';
import DisplayText from '../../../../../../../components_v1/Texts/DisplayText';
import { isFormValid, onGetErrorMessage, onGetFormData, onSetErrorsToForm } from '../../../../../../../shared/utility';
import {onInitEmptyForm} from '../../../../../../../shared/utility'
import {request_add_service} from '../../requests'

const useStyles = makeStyles(theme => ({
    form:{
        paddingTop:32,
        paddingBottom:32
    }
}))

const AddItemModal = (props) => {

    const classes = useStyles()
    const { businessID, open, onClose, content, onUpdateCompleted } = props
    const [form, setForm] = useState(JSON.parse(JSON.stringify(formData)))
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState('')

    useEffect(() => {
        if (!open) {
            let _form = JSON.parse(JSON.stringify(formData))
            _form = onInitEmptyForm(_form, content.form)
            setForm({..._form})
            setError(''); setLoading(false);
        }
    }, [open])

    const onChange = (data) => {
        let temp = { ...form }
        const id = data.config.id
        temp[id] = { ...data }
        setForm(temp)
    }

    const onSubmit = async() => {
        const errors = isFormValid(form)
        /// Check if the form is valid
        if(errors && errors.length){
            const new_form = onSetErrorsToForm(form, errors)
            setForm(new_form)
            return
        }
        
        //Extract data
        let data2send = onGetFormData(form)
        data2send.business_id = businessID

        try {
            setLoading(true)
            await request_add_service(data2send)
            onUpdateCompleted()
        } catch (error) {
            console.log(error)
            setError(onGetErrorMessage(error))
        }
    }

    return ( 
        <SimpleModal open={open} onClose={onClose} maxWidth='md'>
            <div>
                <DisplayText variant='h6' color='primary' style={{ fontWeight: 500 }}>{content.title}</DisplayText>
                <div className={classes.form}>
                    <Grid container spacing={3}>
                        <Grid item xs={12}>
                            <InputText data={form.name} onChange={onChange}/>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <InputText data={form.email} onChange={onChange}/>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <InputText data={form.service_description} onChange={onChange}/>
                        </Grid>
                        <Grid item xs={12}>
                            <InputText data={form.expiration_date} onChange={onChange}/>
                        </Grid>
                        <Grid item xs={12}>
                            <ActionModalBar btnLabel='Guardar' loading={loading} error={error} onSubmit={onSubmit}/>
                        </Grid>
                    </Grid>
                </div>
            </div>
        </SimpleModal>
     );
}
 
export default AddItemModal

export const formData = { 
    name:{
        value:'',
        error: false,
        isVisited: false,
        isRequired: false,
        isValid: false,
        config:{
            id:'name',
            type:'text',
            fullWidth: true,
            label:'Nombre',
            placeholder:'Nombre'
        },
        rules:{
            type:'distance',
            min:1,
            max:100
        }
    },
    email:{
        value:'',
        error: false,
        isVisited: false,
        isRequired: false,
        isValid: false,
        config:{
            id:'email',
            type:'text',
            fullWidth: true,
            label:'Email',
            placeholder:'Email'
        },
        rules:{
            type:'distance',
            min:1,
            max:100
        }
    },
    service_description:{
        value:'',
        error: false,
        isVisited: false,
        isRequired: false,
        isValid: false,
        config:{
            id:'service_description',
            type:'text',
            fullWidth: true,
            label:'Descripción',
            placeholder:'Descripción'
        },
        rules:{
            type:'distance',
            min:1,
            max:100
        }
    },
    expiration_date:{
        value:'',
        error: false,
        isVisited: false,
        isRequired: false,
        isValid: false,
        config:{
            id:'expiration_date',
            type:'date',
            fullWidth: true,
            label:'Fecha de Expiración',
            placeholder:'Fecha de Expiración'
        },
        rules:{
            type:'distance',
            min:1,
            max:100
        }
    }
}