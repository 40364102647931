import React, { useEffect, useState } from 'react';
import { Grid, makeStyles } from '@material-ui/core';
import ActionModalBar from '../../../../../../../components_v1/Actions/ActionModalBar';
import InputText from '../../../../../../../components_v1/Forms/InputText';
import InputSelect from '../../../../../../../components_v1/Forms/InputSelect';
import SimpleModal from '../../../../../../../components_v1/Structure/Modals/SimpleModal';
import DisplayText from '../../../../../../../components_v1/Texts/DisplayText';
import { isFormValid, onGetFormData, onInitForm, onSetErrorsToForm } from '../../../../../../../shared/utility';

const useStyles = makeStyles(theme => ({
    form: {
        paddingTop: 32,
        paddingBottom: 32
    }
}))

const WorkingInfoModal = props => {

    const classes = useStyles()
    const { open, sending, onClose, content, catalogs, origin_data, history, language } = props
    const [form, setForm] = useState(JSON.parse(JSON.stringify(formData)))
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState(null)

    useEffect(() => {
        if (open) {
            console.log(origin_data)
            let _form = JSON.parse(JSON.stringify(formData))
            _form = onInitForm(_form, origin_data, content.form)
            // Update catalogs
            catalog_matrix.forEach(item => {
                if(item.form_key === "salary_currency_id"){
                    console.log(item)
                    console.log(catalogs)
                }
                _form[item.form_key].options = catalogs[item.catalog_key]
            })
            setForm({ ..._form })
            setError('')
            setLoading(false)
        }
    }, [open, origin_data])

    const onChange = (data) => {
        let temp = { ...form }
        const id = data.config.id
        temp[id] = { ...data }
        setForm(temp)
    }

    const onSubmit = async () => {
        const errors = isFormValid(form)
        /// Check if the form is valid
        if (errors && errors.length) {
            const new_form = onSetErrorsToForm(form, errors)
            setForm(new_form)
            return
        }

        //Extract data
        let data2send = onGetFormData(form)
        //Send data
        data2send.entrance_hour = data2send.entrance_hour.substring(0, 5)
        data2send.exit_hour = data2send.exit_hour.substring(0, 5)
        setLoading(true)
        props.onSubmit(data2send)
        setLoading(false)
    }

    console.log(form)

    return (
        <SimpleModal open={open} onClose={onClose} maxWidth='md'>
            <div>
                <DisplayText variant='h6' color='primary' style={{ fontWeight: 500 }}>{content.title}</DisplayText>
                <div className={classes.form}>
                    <Grid container spacing={3}>
                        <Grid item xs={12} sm={6}>
                            <InputText data={form.rfc} onChange={onChange} />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <InputText data={form.curp} onChange={onChange} />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <InputSelect data={form.contract_type_id} onChange={onChange} />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <InputText data={form.contract_duration} onChange={onChange} />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <InputSelect data={form.contract_duration_type_id} onChange={onChange} />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <InputSelect data={form.employee_status_id} onChange={onChange} />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <InputSelect data={form.salary_currency_id} onChange={onChange} />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <InputText data={form.company_cost} onChange={onChange} />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <InputText data={form.gross_salary} onChange={onChange} />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <InputText data={form.net_salary} onChange={onChange} />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <InputText data={form.entrance_hour} onChange={onChange} />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <InputText data={form.exit_hour} onChange={onChange} />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <InputText data={form.entry_day} onChange={onChange} />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <InputText data={form.exit_day} onChange={onChange} />
                        </Grid>
                        <Grid item xs={12}>
                            <ActionModalBar btnLabel='Guardar' loading={sending} error={error} onSubmit={onSubmit} />
                        </Grid>
                    </Grid>
                </div>
            </div>
        </SimpleModal>
    );
}

const catalog_matrix = [
    { form_key: 'contract_type_id', catalog_key: 'user_contract_types' },
    { form_key: 'contract_duration_type_id', catalog_key: 'user_contract_durations' },
    { form_key: 'employee_status_id', catalog_key: 'user_status' },
    {form_key:'salary_currency_id', catalog_key:'user_currencies'},
]

const formData = {
    rfc: {
        value: null,
        error: false,
        isVisited: false,
        isRequired: false,
        isValid: false,
        config: {
            id: 'rfc',
            type: 'text',
            fullWidth: true,
            label: 'RFC',
            placeholder: 'RFC',
        },
        rules: {
            type: 'rfc',
        }
    },
    curp: {
        value: null,
        error: false,
        isVisited: false,
        isRequired: false,
        isValid: false,
        config: {
            id: 'curp',
            type: 'text',
            fullWidth: true,
            label: 'CURP',
            placeholder: 'CURP',
        },
        rules: {
            type: 'distance',
            min: 1,
            max: 100
        }
    },
    contract_type_id: {
        value: null,
        error: false,
        isVisited: false,
        isRequired: false,
        isValid: false,
        options: [],
        config: {
            id: 'contract_type_id',
            type: 'simple_select',
            fullWidth: true,
            label: 'Tipo de contrato',
            placeholder: 'Tipo de contrato',
        },
        rules: {
            type: 'none',
        }
    },
    contract_duration: {
        value: null,
        error: false,
        isVisited: false,
        isRequired: false,
        isValid: false,
        config: {
            id: 'contract_duration',
            type: 'number',
            fullWidth: true,
            label: 'Duración de contrato',
            placeholder: 'Duración de contrato',
        },
        rules: {
            type: 'distance',
            min: 1,
            max: 100
        }
    },
    contract_duration_type_id: {
        value: null,
        error: false,
        isVisited: false,
        isRequired: false,
        isValid: false,
        options: [],
        config: {
            id: 'contract_duration_type_id',
            type: 'simple_select',
            fullWidth: true,
            label: 'Tipo de duración',
            placeholder: 'Tipo de duración',
        },
        rules: {
            type: 'none',
        }
    },
    employee_status_id: {
        value: null,
        error: false,
        isVisited: false,
        isRequired: false,
        isValid: false,
        options: [],
        config: {
            id: 'employee_status_id',
            type: 'simple_select',
            fullWidth: true,
            label: 'Estatus',
            placeholder: 'Estatus',
        },
        rules: {
            type: 'none',
        }
    },
    salary_currency_id:{
        value: null,
        error: false,
        isVisited: false,
        isRequired: false,
        isValid: false,
        options:[],
        config:{
          id:'salary_currency_id',
          type:'simple_select',
          fullWidth: true,
          label:'Divisa',
          placeholder:'Seleccione una opción'
        },
        rules:{
          type:'none',
        }
      },
      company_cost:{
        value: null,
        error: false,
        isVisited: false,
        isRequired: false,
        isValid: false,
        config:{
          id:'company_cost',type:'number',fullWidth: true, label:'Costo', placeholder:'Ingrese un valor'
        },
        rules:{type:'distance',min:1,max:100}
      },
    gross_salary: {
        value: null,
        error: false,
        isVisited: false,
        isRequired: false,
        isValid: false,
        config: {
            id: 'gross_salary',
            type: 'number',
            fullWidth: true,
            label: 'Salario Bruto',
            placeholder: 'Salario Bruto',
        },
        rules: { type: 'distance', min: 1, max: 100 }
    },
    net_salary: {
        value: null,
        error: false,
        isVisited: false,
        isRequired: false,
        isValid: false,
        config: {
            id: 'net_salary',
            type: 'number',
            fullWidth: true,
            label: 'Salario Neto',
            placeholder: 'Salario Neto',
        },
        rules: { type: 'distance', min: 1, max: 100 }
    },
    entrance_hour: {
        value: null,
        error: false,
        isVisited: false,
        isRequired: false,
        isValid: false,
        config: {
            id: 'entrance_hour',
            type: 'time',
            fullWidth: true,
            label: 'Hora de entrada',
            placeholder: 'Hora de entrada',
        },
        rules: {
            type: 'time',
        }
    },
    exit_hour: {
        value: null,
        error: false,
        isVisited: false,
        isRequired: false,
        isValid: false,
        config: {
            id: 'exit_hour',
            type: 'time',
            fullWidth: true,
            label: 'Hora de salida',
            placeholder: 'Hora de salida',
        },
        rules: {
            type: 'time',
        }
    },
    entry_day: {
        value: null,
        error: false,
        isVisited: false,
        isRequired: false,
        isValid: false,
        config: {
            id: 'entry_day',
            type: 'date',
            fullWidth: true,
            label: 'Fecha de entrada',
            placeholder: 'Fecha de entrada',
        },
        rules: {
            type: 'date',
        }
    },
    exit_day: {
        value: null,
        error: false,
        isVisited: false,
        isRequired: false,
        isValid: false,
        config: {
            id: 'exit_day',
            type: 'date',
            fullWidth: true,
            label: 'Fecha de salida',
            placeholder: 'Fecha de salida',
        },
        rules: {
            type: 'date',
        }
    },

}

export default WorkingInfoModal;