import { mainServer } from '../../../../variables/config'
import axios from 'axios'

export const request_get_user_info = async () => {
  const logData = JSON.parse(localStorage.getItem('data'))
  mainServer.instance.defaults.headers.common['Authorization'] = logData.token
  let response
  response = await mainServer.instance.get("/user/employee/me")
  return response.data.data.employee
}

export const request_process = async (id, params) => {
  const logData = JSON.parse(localStorage.getItem('data'));
  mainServer.instance.defaults.headers.common['Authorization'] = logData.token;
  const response = await mainServer.instance.get(`/procphysicalperson/${id}${params}`)
  return response.data.data.proc_physical_person
}

export const request_upload_document = async (id, idExt, file, data, path, ctrlpath) => {
  const logData = JSON.parse(localStorage.getItem('data'));
  mainServer.instance.defaults.headers.common['Authorization'] = logData.token;

  let response = null
  if (ctrlpath) {
    if (path === 'ctrlaccounting')
      response = await mainServer.instance.post(`/${path}/${idExt}/document`, data)
    else
      response = await mainServer.instance.post(`/${path}/${idExt}/${data.path}`, { ext: data.ext })
  }
  else
    response = await mainServer.instance.post(`/procphysicalperson/${id}/file`, data)
  const options = { headers: { 'Content-Type': file.type } }
  delete axios.defaults.headers.common["Authorization"]
  if (ctrlpath)
    await axios.put(response.data.data.path, file, options);
  else
    await axios.put(response.data.data.upload_url, file, options);
}