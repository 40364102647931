export const contentData = {
  spanish:{
    title:'Información Básica',
    nodata:'Sin Registro',
    error:'Sin registro',
    button:'Guardar',
    cancelButton:'Cancelar',
    image_button:'Cambiar',
    form:{
      rfc:{
        label:'RFC',
        placeholder:'RFC'
      },
      billing_option_id:{
        label:'Opción de facturación',
        placeholder:'Opción de facturación',
      },
      billing_stamps_limit_id:{
        label:'Límite de estampado',
        placeholder:'Límite de estampado',
      },
      billing_responsible_id:{
        label:'Responsable de facturación',
        placeholder:'Responsable de facturación'
      }
    },
  },
}