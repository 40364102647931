import { Grid, makeStyles} from '@material-ui/core';
import React from 'react';
import ActionModalBar from '../../../../components_v1/Actions/ActionModalBar';
import InputCheckbox from '../../../../components_v1/Forms/InputCheckbox';
import InputSelect from '../../../../components_v1/Forms/InputSelect';
import SimpleModal from '../../../../components_v1/Structure/Modals/SimpleModal';
import DisplayText from '../../../../components_v1/Texts/DisplayText';

const useStyles = makeStyles((theme) => ({
	form: {
		paddingTop: 32,
		paddingBottom: 32,
	},
}));

const AddUserModal = ({ open, onClose, content, form, onChange, onSubmit, loading, error }) => {
	const classes = useStyles();

	return (
		<SimpleModal open={open} onClose={onClose} maxWidth='sm'>
			<div>
				<DisplayText variant='h6' color='primary' style={{ fontWeight: 500 }}>
					{content.title}
				</DisplayText>
				<div className={classes.form}>
					<Grid container spacing={3}>
						<Grid item xs={12}>
							<InputSelect data={form.user_id} onChange={onChange} />
						</Grid>
						<Grid item>
							<InputCheckbox data={form.main} onChange={onChange} />
						</Grid>
						<Grid item xs={12}>
							<ActionModalBar btnLabel={content.button} loading={loading} error={error} onSubmit={onSubmit} />
						</Grid>
					</Grid>
				</div>
			</div>
		</SimpleModal>
	);
};

export default AddUserModal;
