export const contentData = {
  spanish:{
    modals:{
      warning_delete_item:{
        title:'¿Estás seguro?',
        message:'¿Realmente quieres eliminar este registro? Este proceso no puede ser revertido',
        cancelButton:'Cancelar',
        deleteButton:'Eliminar'
      },
      edit_service:{
        title:'Editar servicio',
        button:'Guardar',
        cancelButton:'Cancelar'
      }
    },
    basicform:{
      title:'Información Detallada',
      button:'Editar',
      nodata:'Sin Registro',
      data:{
        name:{
          label:'',
          data:'',
        },
        fiscal_address:{
          label:'',
          data:''
        }
      },
      content:{
        name:{
          label:'Nombre del trabajador'
        },
        fiscal_address:{
          label:'Domicilio Fiscal'
        }
      },
      documents:{
        title:'Documentos',
        header:{title:'Archivos', actions:'Acciones'},
        files:[
          {key:'possible_names',name:'Posibles nombres de la sociedad',path:'possiblenames', status:false},
          {key:'oficial_id',name:'Identificación oficial',path:'oficialid', status:false, businessDocument:true},
          {key:'address_proof',name:'Comprobante de domicilio',path:'addressproof', status:false, businessDocument:true},
          {key:'migratory_form',name:'Forma Migratoria de Turista',path:'migratoryform', status:false, businessDocument:true},
          {key:'temporal_residence',name:'Residencia temporal',path:'temporalresidence', status:false, businessDocument:true},
          {key:'special_attorney_power',name:'Poder Especial Certificado ante Notario Extranjero y Apostillado',path:'specialattorneypower', status:false},
          {key:'foreign_public_register',name:'Registro Público Extranjero (Certificados) Original y Apostillado',path:'foreignpublicregister', status:false},
          {key:'constitutive_act',name:'Acta Constitutiva',path:'constitutiveact', status:false, businessDocument:true},
          {key:'draft_power_format',name:'Formulario "Borrador de Poder"',path:'draftpowerformat', status:false},
          {key:'mexican_partners_const_format',name:'Formulario "Constitución de Sociedad Socios Mexicanos"',path:'mexicanpartnersconstformat', status:false},      
        ]
      }
    }
  }
}