import React from 'react';
import { Card, Grid, Icon, IconButton, makeStyles, Typography } from '@material-ui/core';
import { onGetDateFormat } from '../../../../shared/utility';
import nouser from '../../../../assets/nouser.png';
import { imgServerS3 } from '../../../../variables/config';
import moment from 'moment';
import HtmlDisplayer from '../../../../components_v1/Structure/DisplayData/HtmlDisplayer';

const TaskComments = ({ content, comments, onAddComment, onDeleteComment, onEditComment, onUploadDocument, onDownloadDocument, onDeleteDocument }) => {
	const classes = useStyles();

	return (
		<Grid container className={classes.mt_sm} alignItems='center' style={{ margin: '40px 0px' }}>
			<Grid item container xs={12} alignItems='center' style={{ marginBottom: 30 }}>
				<Grid item>
					<Typography className={classes.task_title}>{content.title}</Typography>
				</Grid>
				<Grid item style={{ marginLeft: 30 }}>
					<IconButton fontSize='small' className={classes.edit_button} onClick={onAddComment}>
						<Icon>add</Icon>
					</IconButton>
				</Grid>
			</Grid>
			{comments.length > 0 ? (
				<Grid item container spacing={3} xs={12}>
					{comments.map((item, i) => {
						return (
							<Grid item xs={12} md={4} key={i}>
								<CommentCard
									content={content.card}
									comment={item}
									onDeleteComment={onDeleteComment}
									onEditComment={onEditComment}
									onUploadDocument={onUploadDocument}
									onDeleteDocument={onDeleteDocument}
									onDownloadDocument={onDownloadDocument}
								/>
							</Grid>
						);
					})}
				</Grid>
			) : (
				<Grid item xs>
					<Typography variant='h5' align='center' style={{ marginTop: 50 }} color='primary'>
						{content.nocomment}
					</Typography>
				</Grid>
			)}
		</Grid>
	);
};

const CommentCard = ({ content, comment, onDeleteComment, onEditComment, onUploadDocument, onDeleteDocument, onDownloadDocument }) => {
	const classes = useStyles();
	const imgSRC = comment.user_image ? `${imgServerS3}${comment.user_image}` : nouser;

	return (
		<Card className={classes.card}>
			<Grid container spacing={4}>
				<Grid item container xs={12} justify='space-between' alignItems='center'>
					<Grid item>
						<Typography className={classes.section_title}>{content.creation_date}</Typography>
						<Typography className={classes.date}>{`${moment.utc(comment.comment_date).local().format('DD/ MMMM / YYYY')} • ${moment.utc(comment.comment_date).local().format('HH:mm')} hrs`}</Typography>
					</Grid>
					<Grid item>
						<IconButton style={{ width: 35, height: 35 }} onClick={() => onDeleteComment(comment.id_task_comment)}>
							<Icon fontSize='small' style={{ color: '#9AA6B4' }}>
								delete
							</Icon>
						</IconButton>
					</Grid>
				</Grid>
				<Grid item container xs={12} justify='space-between' alignItems='center'>
					<Grid item xs={9}>
						<Typography className={classes.title}>{comment.title ? comment.title : '-'}</Typography>
					</Grid>
					<Grid item>
						<IconButton className={classes.edit_button} onClick={() => onEditComment(comment)}>
							<Icon fontSize='small'>edit</Icon>
						</IconButton>
					</Grid>
				</Grid>
				<Grid item xs={12} style={{ minHeight: 150 }}>
					{/* <Typography className={classes.desc}>
						{comment.comment}
					</Typography> */}
					<HtmlDisplayer data={comment.comment}  />
				</Grid>
				<Grid item container xs={12} justify='space-between' alignItems='center' style={{ borderTop: '1px solid #e7e7e7', paddingTop: 20 }}>
					<Grid item xs={6} container>
						<Grid item>
							<img src={imgSRC} className={classes.img} />
						</Grid>
						<Grid item>
							<Typography className={classes.section_title}>{content.creator}</Typography>
							<Typography className={classes.date}>{comment.user}</Typography>
						</Grid>
					</Grid>
					<Grid item xs={6} container justify='flex-end'>
						<Grid item>
							<IconButton color='secondary' onClick={() => onUploadDocument(comment)}>
								<Icon fontSize='small'>cloud_upload</Icon>
							</IconButton>
						</Grid>
						<Grid item>
							<IconButton disabled={comment.document === '0' || !comment.document} color='primary' onClick={() => onDownloadDocument(comment)}>
								<Icon fontSize='small'>cloud_download</Icon>
							</IconButton>
						</Grid>
						<Grid item>
							<IconButton onClick={() => onDeleteDocument(comment)}>
								<Icon style={{ color: 'red' }} fontSize='small'>
									close
								</Icon>
							</IconButton>
						</Grid>
					</Grid>
				</Grid>
			</Grid>
		</Card>
	);
};

const useStyles = makeStyles((theme) => ({
	task_title: {
		fontWeight: 500,
		fontSize: '1.375rem',
		color: theme.palette.primary.main,
	},
	edit_button: {
		width: 35,
		height: 35,
		background: theme.palette.secondary.light,
		'&:hover': {
			background: theme.palette.secondary.main,
		},
		'& .MuiIcon-root': {
			color: 'white',
		},
	},
	mt_sm: {
		[theme.breakpoints.down('sm')]: {
			marginTop: 20,
		},
	},
	card: {
		borderRadius: 14,
		boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.05)',
		padding: '15px 20px',
	},
	section_title: {
		fontWeight: 600,
		fontSize: '0.75rem',
		color: '#9AA6B4',
	},
	date: {
		fontSize: '0.75rem',
		fontWeight: 400,
	},
	desc: {
		fontSize: '0.875rem',
		fontWeight: 300,
	},
	title: {
		fontWeight: 500,
		fontSize: '1rem',
		color: theme.palette.primary.dark,
	},
	img: {
		width: 40,
		height: 40,
		borderRadius: '100%',
		marginRight: 12,
	},
}));

export default TaskComments;
