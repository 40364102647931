export const contentData = {
  spanish:{
    title:'Información Laboral',
    nodata:'Sin Registro',
    error:'Sin registro',
    save_button:'Guardar',
    image_button:'Cambiar',
    sectionA:{
      rfc:{
        label:'RFC',
        placeholder:'RFC',
      },
      curp:{
        label:'CURP',
        placeholder:'CURP',
      },
      contract_type_id:{
        label:'Tipo de contrato',
        placeholder:'Tipo de contrato',
      },
      contract_duration:{
        label:'Duración de contrato',
        placeholder:'Duración de contrato',
      },
      contract_duration_type_id:{
        label:'Tipo de duración',
        placeholder:'Tipo de duración',
      },

      employee_status_id:{
        label:'Estatus',
        placeholder:'Estatus',
      },
      weekly_salary:{
        label:'Salario',
        placeholder:'Salario',
      },
      entrance_hour:{
        label:'Hora de entrada',
        placeholder:'Hora de entrada',
      },
      exit_hour:{
        label:'Hora de salida',
        placeholder:'Hora de salida',
      },
      entry_day:{
        label:'Fecha de entrada',
        placeholder:'Fecha de entrada',
      },
      exit_day:{
        label:'Fecha de salida',
        placeholder:'Fecha de salida',
      }
    },
  },
}