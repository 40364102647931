import React, { useRef, useState } from 'react'
import { Button, Grid, Icon, IconButton, LinearProgress, makeStyles, Menu, MenuItem, Paper, Table, TableBody, TableCell, TableHead, TableRow, Typography } from '@material-ui/core'
import { blueGrey, grey } from '@material-ui/core/colors'
//import SearchBar from './SearchBar'
import ActionBar from './ActionBar'
import empty from '../../../assets/empty.png'
import DisplayText from '../../../components_v1/Texts/DisplayText'
import { catalogs } from '../../../texts/esp/catalogs'
import GenericStatus from '../../../components_v1/Structure/DisplayData/GenericStatus'
import FilterButton from './FilterButton'
import moment from 'moment'
import SearchBar from '../../../components_v1/Actions/SearchBar/SearchBar'
import { onGetYearCatalog } from '../../../shared/utility'


const header = [
    {id:1, label:'ID'},
    {id:2, label:'Usuario'},
    {id:3, label:'Periodo'},
    {id:4, label:'Monto'},
    {id:5, label:'Estatus'},
    {id:6, label:'Detalles'}
]


const useStyles = makeStyles(theme => ({
    root:{},
    paper:{
        marginTop:4,
        borderRadius:8,
        boxShadow:'0 10px 24px 0 rgba(82, 91, 115, .12)',
    },
    cell:{
        borderColor:grey[200],
    },
    row:{
        '&:hover':{
            cursor:'pointer',
            background:grey[100]
        }
    },
    cellHeader:{
        borderColor:grey[300],
        paddingTop:16,
        paddingBottom:16
    },
    button:{
        background: '#DBE3E8',
        padding: '6px 32px',
        borderRadius: 5,
        color: '#0F2358',
        textTransform:'none'
        /* '&:hover':{
            background:theme.palette.primary.dark,
        } */
    },
    toolbar:{
        marginBottom:16
    },
    actions:{
        marginTop:16
    },
    table:{
        minWidth:1100
    },
    tableWrapper:{
        overflowX: 'auto',
    },
    nodata:{
        marginTop:40
    }
}))

const SimpleTable = props => {

    const classes = useStyles()
    const {data, status, search, onChangeDate, onChangeSearch, onChangeFilter, total, tableFilter, onUpdateTableFilter, onSelectedItem, loading, 
        startDate, endDate, year, month, monthCatalog, onRemoveItem, period, permission} = props


    const onInnerChange = (value, id) => {
        console.log(id)
        console.log(value)
    }

    const periods = [
        {value:1, label:'Enero-Febrero'},
        {value:2, label:'Marzo-Abril'},
        {value:3, label:'Mayo-Junio'},
        {value:4, label:'Julio-Agosto'},
        {value:5, label:'Sep-Octubre'},
        {value:6, label:'Nov-Dic'},
    ]

    const filter_options = [
        { id: 'bonus_status_id', label: 'Estatus' },
        { id: 'employee', label: 'Empleado' }
    ]

    return(
        <div>
            <div className={classes.toolbar}>
                <Grid container spacing={2} > 
                    <Grid item xs={12}>
                        <Grid container wrap='nowrap' spacing={2} alignItems='flex-end'>
                            <Grid item><FilterButton label='Periodo' options={periods} selected={period} onChange={(val) => onChangeFilter(val,'period')}/></Grid>
                            <Grid item><FilterButton label='Año' options={onGetYearCatalog()} selected={year} onChange={(val) => onChangeFilter(val,'year')}/></Grid>
                            <Grid item xs/>
                            <Grid item>
                                <FilterMenu filterOptions={filter_options} tableFilter={tableFilter} onChangeTableFilter={onUpdateTableFilter} />
                            </Grid>
                            <Grid item><SearchBar onUpdateTableFilter={onUpdateTableFilter} tableFilter={tableFilter} value={search} onChange={onChangeSearch}/></Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </div>
            {loading ? (
                <Grid container justify='center'>
                    <Grid item xs={12}><LinearProgress /></Grid>
                </Grid>
            ) : null}
            <Paper className={classes.paper}>
                {data && data.length ? (
                    <div className={classes.tableWrapper}>
                        <Table className={classes.table}>
                            {header ? (
                                <TableHead>
                                    <TableRow>
                                        {header.map(item=>{
                                            return(
                                                <TableCell padding={item.id === 1 ? 'default' : 'default'} className={classes.cellHeader} key={item.id.toString()}>
                                                    <DisplayText variant='subtitle2' >{item.label}</DisplayText>
                                                </TableCell>
                                            )
                                        })}
                                    </TableRow>
                                </TableHead>
                            ):null}
                            <TableBody>
                                {data.map((item,key) => {

                                    const { id_bonus, employee, bonus_status_id, amount, start_date, date} = item

                                    const period = periods.find(el => el.value === Math.ceil(parseFloat(moment(start_date).format('MM'))/2) )
                                    //console.log(moment(date).format('MM'))

                                    return(
                                        <TableRow key={key.toString()} className={classes.row} >
                                            <TableCell className={classes.cell} onClick={permission > 2 ? () => onSelectedItem('edit', item) : undefined}><DisplayText variant='body2'>{id_bonus}</DisplayText></TableCell>
                                            <TableCell className={classes.cell} onClick={permission > 2 ? () => onSelectedItem('edit', item) : undefined}><DisplayText variant='body2'>{employee}</DisplayText></TableCell>
                                            <TableCell className={classes.cell} onClick={permission > 2 ? () => onSelectedItem('edit', item) : undefined}><DisplayText variant='body2'>{period ? period.label : '-'}</DisplayText></TableCell>
                                            <TableCell className={classes.cell} onClick={permission > 2 ? () => onSelectedItem('edit', item) : undefined}><DisplayText variant='body2'>{amount}</DisplayText></TableCell>
                                            <TableCell className={classes.cell} onClick={permission > 2 ? () => onSelectedItem('edit', item) : undefined}><GenericStatus id={bonus_status_id} variant='body2' options={catalogs.bonus}/></TableCell>
                                            <TableCell className={classes.cell}><Button className={classes.button} onClick={()=>onSelectedItem('info', item)} >Ver</Button></TableCell>
                                        </TableRow>
                                    )
                                })}
                                
                            </TableBody>
                        </Table>
                    </div>
                    
                ): null}
            </Paper>
            {data && data.length ? <div className={classes.actions}>
                <ActionBar total={total} tableFilter={tableFilter} onUpdateTableFilter={onUpdateTableFilter}/>
            </div> : null}
            {data.length === 0 && !loading ? (
                <div className={classes.nodata}>
                    <Grid container justify='center' alignItems='center' direction='column'>
                        <Grid item>
                            <img src={empty} alt='' width={180}/>
                        </Grid>
                        <Grid item>
                            <DisplayText>No se encontraron registros</DisplayText>
                        </Grid>
                    </Grid>
                </div>
                
            ) : null}
            
        </div>
    )
}

export default SimpleTable


const useButtonStyles = makeStyles((theme) => ({
	root: { position: 'relative' },
	button: {
		background: blueGrey[100],
		color: blueGrey[700],
		borderRadius: 8,
		padding: '8px 16px',
		'&:hover': { background: blueGrey[200] },
		textTransform: 'none',
	},
	selected: {
		color: 'white',
		background: theme.palette.primary.dark,
		fontWeight: 500,
		boxShadow: '0 10px 24px 0 rgba(82, 91, 115, .12)',
		'&:hover': { background: theme.palette.secondary.dark },
		//border:`1px solid ${theme.palette.primary.main}`
	},
	buttonFilter: {
		background: blueGrey[100],
		color: blueGrey[700],
        borderRadius: '0px 8px 8px 0px',
		padding: '7px 16px',
		'&:hover': { background: blueGrey[200] },
		textTransform: 'none',
	},
	selectedFilter: {
		color: 'white',
		background: theme.palette.primary.dark,
		fontWeight: 500,
		boxShadow: '0 10px 24px 0 rgba(82, 91, 115, .12)',
		'&:hover': { background: theme.palette.secondary.dark },
		borderRadius: '8px 0px 0px 8px',
	},
}));


const FilterMenu = ({ tableFilter, onChangeTableFilter, filterOptions }) => {


	const selected = filterOptions.find((item) => item.id === tableFilter.order_by);
    //console.log(tableFilter)

	const [open, setOpen] = useState(false);
    const anchorRef = useRef(null)

    const classes = useButtonStyles()

	const onSelect = (id) => {
		let _tableFilter = {...tableFilter};
		_tableFilter.order_by = id;
		setOpen(false);
		onChangeTableFilter(_tableFilter);
	};

	const onChangeOrder = (prevOrder) => {
		let _tableFilter = {...tableFilter};
		_tableFilter.order = prevOrder === 1 ? 0 : 1;
		setOpen(false);
		onChangeTableFilter(_tableFilter);
	};

    let iconOrder = tableFilter.order === 1 ? <Icon>keyboard_arrow_down</Icon> : <Icon>keyboard_arrow_up</Icon>

    /* if(selected.revert)
        iconOrder = tableFilter.order === 1 ? <Icon>keyboard_arrow_up</Icon> : <Icon>keyboard_arrow_down</Icon> */

	return (
		<Grid container alignItems='center'>
			<Grid item>
				<Typography style={{marginRight: 15, fontWeight: 500}}>Ordenar por: </Typography>
			</Grid>
			<Grid item>
				<Button className={classes.selectedFilter} ref={anchorRef} aria-controls='filter-menu' aria-haspopup='true' onClick={() => setOpen(!open)}>
					{selected.label}
				</Button>
				<Button className={classes.buttonFilter} onClick={() => onChangeOrder(tableFilter.order)}>
                    {iconOrder}
				</Button>
				<Menu anchorEl={anchorRef.current} id='simple-menu' keepMounted open={open} onClose={() => setOpen(false)}>
					{filterOptions.map((item) => {
						return [
							<MenuItem onClick={() => onSelect(item.id)}>
								{item.label}
							</MenuItem>,
						];
					})}
				</Menu>
			</Grid>
		</Grid>
	);
};