import { Grid, makeStyles, Switch } from '@material-ui/core';
import React, { useState, useEffect } from 'react';
import ActionModalBar from '../../../../../../../components_v1/Actions/ActionModalBar';
import InputSelect from '../../../../../../../components_v1/Forms/InputSelect';
import InputText from '../../../../../../../components_v1/Forms/InputText';
import SimpleModal from '../../../../../../../components_v1/Structure/Modals/SimpleModal';
import DisplayText from '../../../../../../../components_v1/Texts/DisplayText';

const useStyles = makeStyles(theme => ({
    form:{
        paddingTop:32,
        paddingBottom:32
    }
}))

const AddTaskModal = ({ open, onClose, history, content, form, onChange, onSubmit, loading, error}) => {
    const classes = useStyles()

    return ( 
        <SimpleModal open={open} onClose={onClose} maxWidth='sm'>
            <div>
                <DisplayText variant='h6' color='primary' style={{ fontWeight: 500 }}>{content.title}</DisplayText>
                <div className={classes.form}>
                    <Grid container spacing={3}>
                        <Grid item xs={12}>
                            <InputText data={form.title} onChange={onChange}/>
                        </Grid>
                        <Grid item xs={12}>
                            <InputSelect data={form.business_id} onChange={onChange}/>
                        </Grid>
                        <Grid item xs={12}>
                            <InputText data={form.order_number} onChange={onChange}/>
                        </Grid>
                        <Grid item xs={12}>
                            <InputText data={form.expiration_date} onChange={onChange}/>
                        </Grid>
                        <Grid item xs={5}>
                            <InputSelect data={form.task_priority_id} onChange={onChange}/>
                        </Grid>
                        <Grid item xs/>
                        <Grid item xs={5}>
                            <InputSelect data={form.task_type_id} onChange={onChange}/>
                        </Grid>
                        <Grid item xs={12}>
                            <ActionModalBar btnLabel={content.button} loading={loading} error={error} onSubmit={onSubmit}/>
                        </Grid>
                    </Grid>
                </div>
            </div>
        </SimpleModal>
     );
}
 
export default AddTaskModal;
