export const contentData = {
  spanish:{
    title:'Agregar negocio',
    nodata:'Sin Registro',
    error:'Sin registro',
    button:'Agregar',
    form:{
      rfc:{
        label:'R.F.C.',
        placeholder:'R.F.C.',
        helper:'RFC inválido'
      },
      name:{
        label:'Razón Social',
        placeholder:'Razón Social',
        helper:'Razón social inválida'
      },
      fiscal_regime_id:{
        label:'Régimen Fiscal',
        placeholder:'Régimen Fiscal',
      },
      business_status_id:{
        label:'Estatus',
        placeholder:'Estatus',
      }
    },
  },
}