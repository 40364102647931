import React, { useState, useEffect } from 'react';
import { isFormValid } from '../../../components_v1/Forms/customFunctions';
import BasicView from '../../../components_v1/Structure/Layouts/BasicView';
import GenericControlTable from '../../../components_v1/Structure/Tables/GenericControlTable/GenericControlTable';
import { onCheckBooleanForm, onCheckDateForm, onGetEmployeesCatalogs, onGetExtensionFromURL, onGetSelectedFormData, onGetUserID, onInitForm, onSetErrorsToForm } from '../../../shared/utility';
import { content } from './content';
import { formData } from './data';
import {request_control_table, request_download_document, request_employees, request_update_control_table_item} from './requests'
import moment from 'moment-timezone'
import DocumentModal from './modals/DocumentModal';
import DeleteDocumentModal from './modals/DeleteDocumentModal';
import { connect } from 'react-redux';
import { PERMISSION_MODULES, PERMISSION_TYPES } from '../../../variables/config';
import { Redirect } from 'react-router';
import queryString from 'query-string'
import ResponsibleFilterModal from '../modals/ResponsibleFilterModal';
import BusinessFilterModal from '../modals/BusinessFilterModal';

const CTRLPhysicalAnnualStatement = ({history, permissions}) => {

    const [data, setData] = useState([])
    const [loading, setLoading] = useState(false)
    const [sending, setSending] = useState(false)
    const [error, setError] = useState(null)

     //Filters
     const [business, setBusiness] = useState()
     const [responsible, setResponsible] = useState()

    //Table states
    const [total, setTotal] = useState(1)
    const [tableFilter, setTableFilter] = useState({limit:10,offset:0, order:-1, order_by:null})
    const [search, setSearch] = useState(null)
    const [startDate, setStartDate] = useState(moment().subtract(1,'y').startOf('year').format('YYYY-MM-DD'))

    const [first_hidden, setFirstHidden] = useState(true)
    const [second_hidden, setSecondHidden] = useState(true)

    const [form, setForm] = useState(JSON.parse(JSON.stringify(formData)))
    const [employees, setEmployees] = useState([])
    const [selectedItem, setSelecteItem] = useState(null)
    const [selected_document, setSelectedDocument] = useState(null)
    const [editable, setEditable] = useState(false)

    //Modals
    const [document_modal, setDocumentModal] = useState(false)
    const [delete_document_modal, setDeleteDocumentModal] = useState(false)
    const [responsible_modal, setResponsibleModal] = useState(false)
    const [business_modal, setBusinessModal] = useState(false)

    const userID = onGetUserID()

    useEffect(() => {
        const initModule = async() => {
            setLoading(true)
            let params = onGetParams()
            const _response = await request_control_table(params)
            const _employees = await request_employees()
            setEmployees(onGetEmployeesCatalogs(_employees))
            setTotal(_response.count)
            setData(_response.control_table_physical_annual_statement_data)
            console.log(_response.control_table_physical_annual_statement_data[0])
            setLoading(false)
        }
        initModule()
    }, [tableFilter, startDate, search, responsible, business])

    const onGetParams = () => {
        const start_date = moment(startDate).startOf('M').format('YYYY-MM-DD')
        const end_month = moment(startDate).endOf('Y').format('YYYY-MM-DD')
        let filterArgs = [
            {"field":"auto_date","operator":">=","value":start_date},
            {"field":"auto_date","operator":"<=","value":end_month}
        ]
        if(search) filterArgs.push({"field":"fixed_business_name","operator":"LIKE","value":`%${search}%`})
        if(business) filterArgs.push({"field":"business_id","operator":"=","value":`${business.value}`})
        //if(responsible) filterArgs.push({"field":"responsible_id","operator":"=","value":`${responsible.value}`})
        filterArgs = encodeURIComponent(JSON.stringify(filterArgs))
        let params = { limit:tableFilter.limit, offset:tableFilter.limit*tableFilter.offset}
        const {order_by, order} = tableFilter
        if(order_by !== null && order !== -1) params = {...params, order:order, order_by:order_by}
        if(responsible && responsible.value) params.employee_id = responsible.value
        return `?${queryString.stringify(params)}&filter=${filterArgs}`
    }

    const onEditItem = (data) => {
        // Update form
        let _form = onInitForm(JSON.parse(JSON.stringify(formData)), data)
        /******************** Customization **************************/ 
        //// Completed checkbox /////
        _form.completed = onCheckBooleanForm(_form.completed) 
        //// Dates /////
        _form.presentation_date = onCheckDateForm(_form.presentation_date)
        _form.delivery_date = onCheckDateForm(_form.delivery_date)
        _form.completition_date = onCheckDateForm(_form.completition_date)
        //// Catalogs
        //_form.stamping_responsible_id.options = [...employees]
        _form.responsible_id.options = [...employees]
        setForm(_form)
        setSelecteItem(data)
        setEditable(true)
    }

    const onSaveItem = async() => {
        try {
            const errors = isFormValid(form)
            /// Check if the form is valid
            if(errors && errors.length){
                const new_form = onSetErrorsToForm(form, errors)
                setForm(new_form)
                return
            }

            let data2send = onGetSelectedFormData(form, selectedItem)
            
            // Update special forms
            data2send.completed = data2send.completed ? 1 : 0
            const ctrlTableID = selectedItem.id_ctrl_physical_annual_statements
            let params = onGetParams()
            setSending(true)
            const _response = await request_update_control_table_item(ctrlTableID,data2send,params)
            setTotal(_response.count)
            setData(_response.control_table_physical_annual_statement_data)
            setEditable(false)
            setSelecteItem(null)

        } catch (error) {
            console.log(error)
            setError('Hubo un error')
        }
        setSending(false)

        //setSelecteItem(null)
        //setEditable(false)
    }

    const onInputChange = (data) => {
        let temp = { ...form }
        const id = data.config.id
        temp[id] = { ...data }
        setForm(temp)
    }

    const onChangeDate = (value, id) => {
        let aux
        aux = moment(value)
        setStartDate(value)
    }

    const onVisibleClick = (data, id) => {
        if(data === 1) setFirstHidden(!first_hidden)
        if(data === 6) setSecondHidden(!second_hidden)
    }

    const onDownloadDocument = async(idCtrl, item, obj1, obj2, obj3, lineitem, docKey) => {try {
        const localPath = lineitem[docKey]
        const ext = onGetExtensionFromURL(localPath)
        const _path = item.split('_').join('')
        await request_download_document(idCtrl, _path, ext)
    } catch (error) {
        console.log(error)
        setError('Hubo un error')
    }}

    const onUploadDocument = (idCtrl, item) => {
        console.log(`ID:${idCtrl}, Column:${item}`)
        setSelectedDocument({ctrl_id:idCtrl, key:item})
        setDocumentModal(true)
    }

    
    const onDeleteDocument = (idCtrl, item) => {
        console.log(`ID:${idCtrl}, Column:${item}`)
        setSelectedDocument({ctrl_id:idCtrl, key:item})
        setDeleteDocumentModal(true)
    }

    const onUpdateCompleted = async() => {try {
        let params = onGetParams()
        const _response = await request_control_table(params)
        setTotal(_response.count)
        setData(_response.control_table_physical_annual_statement_data)

        } catch (error) {
            console.log(error)
            setError('Hubo un error')
        } 
        setDocumentModal(false)
        setDeleteDocumentModal(false)
    }

    const onAddFilter = (obj, key) => {
        setTableFilter({...tableFilter, offset:0})
        if(key === 'business') setBusiness(obj)
        if(key === 'responsible') setResponsible(obj)
    }

    const onOpenFilter = (id) => {
        if(id === 'RESPONSIBLE_MODAL') setResponsibleModal(true)
        if(id === 'BUSINESS_MODAL') setBusinessModal(true)
    }

    const onRemoveFilter = (key) => {
        console.log(key)
        if(key === 'business') setBusiness(null)
        if(key === 'responsible') setResponsible(null)
    }


    let _header = content.header

    // Header
    if(first_hidden) [2,3,4,5].forEach(item => _header[item].hidden = true)
    if(!first_hidden) [2,3,4,5].forEach(item => _header[item].hidden = false)
    _header[1].add.icon = first_hidden ? 'add' : 'remove'

    if(second_hidden) [7].forEach(item => _header[item].hidden = true)
    if(!second_hidden) [7].forEach(item => _header[item].hidden = false)
    _header[6].add.icon = second_hidden ? 'add' : 'remove'

    // Body
    let _dataConfig = JSON.parse(JSON.stringify(content.dataConfig))
    if(first_hidden) [1,2,3,4].forEach(item => _dataConfig[item].hidden = true)
    if(!first_hidden) [1,2,3,4].forEach(item => _dataConfig[item].hidden = false)
//
    if(second_hidden) [6].forEach(item => _dataConfig[item].hidden = true)
    if(!second_hidden) [6].forEach(item => _dataConfig[item].hidden = false)

    //console.log(_dataConfig)

    /// Find module permission
    const _permission = permissions ? permissions.find(el => el.permission_module_id === PERMISSION_MODULES.CTRL_PHYSICAL_ANNUAL_STATEMENT) : null
        
    let redirectContent = null
    if(_permission && _permission.permission_type_id === PERMISSION_TYPES.NO_ACCESS) redirectContent = <Redirect to='/profile'/> 

    return ( 
        <BasicView nopadding title='Declaración Anual Física' setReturn  sending={sending} error={error} onCloseError={() => setError(null)}>
            <>
            {redirectContent}
                <ResponsibleFilterModal open={responsible_modal} onClose={() => setResponsibleModal(false)} onAddFilter={onAddFilter}/>   
                <BusinessFilterModal open={business_modal} onClose={() => setBusinessModal(false)} onAddFilter={onAddFilter}/>
                <DeleteDocumentModal selected_document={selected_document} open={delete_document_modal} onClose={() => setDeleteDocumentModal(false)} 
                    onUpdateCompleted ={onUpdateCompleted}/>
                <DocumentModal selected_document={selected_document} open={document_modal} onClose={() => setDocumentModal(false)} 
                onUpdateCompleted={onUpdateCompleted}/>
                <GenericControlTable date_format='year'  idRef='id_ctrl_physical_annual_statements' header={content.header} data={data} dataConfig={_dataConfig}  onAddFilter={onOpenFilter} responsible={responsible} business={business} onRemoveFilter={onRemoveFilter}
                startDate={startDate} onChangeDate={onChangeDate} onVisibleClick={onVisibleClick} onDownloadDocument={onDownloadDocument} onUploadDocument={onUploadDocument} onDeleteDocument={onDeleteDocument}
                total={total} tableFilter={tableFilter} onUpdateTableFilter={(data) => setTableFilter(data)} loading={loading}
                form={form} editable={editable} selectedItem={selectedItem} onEditItem={onEditItem} onSaveItem={onSaveItem} 
                onInputChange={onInputChange} search={search} onChangeSearch={(val) => setSearch(val)}  permission={_permission} />
            </>
        </BasicView>
     );
}

const mapStateToProps = state => {
    return{
        permissions:state.permissions
    }
}
 
export default connect(mapStateToProps)(CTRLPhysicalAnnualStatement);

