export const contentData = {
  spanish:{
    title:'Información Basica',
    nodata:'Sin Registro',
    error:'Sin registro',
    button:'Guardar',
    cancelButton:'Cancelar',
    image_button:'Cambiar',
    form:{
      rfc:{
        label:'RFC',
        placeholder:'RFC'
      },
      sat_password:{
        label:'Contraseña SAT',
        placeholder:'Contraseña SAT'
      },
      antiquity:{
        label:'Antiguedad del Representante Legal',
        placeholder:'Antiguedad del Representante Legal'
      },
      fiscal_situation_id:{
        label:'Situación Fiscal',
        placeholder:'Situación Fiscal'
      },
      tax_limit_date:{
        label:'Fecha Límite de Pago Impuestos',
        placeholder:'Fecha Límite de Pago Impuestos'
      },
      mail_tributary_mailbox:{
        label:'Mail Buzón Tributario',
        placeholder:'Mail Buzón Tributario'
      },
      tributary_mailbox_password:{
        label:'Contraseña Buzón Tributario',
        placeholder:'Contraseña Buzón Tributario'
      },
      tributary_mailbox_status_id:{
        label:'Estatus Buzón Tributario',
        placeholder:'Estatus Buzón Tributario'
      },
      fiel_expiration_date:{
        label:'Fecha de vencimiento de certificado',
        placeholder:'Fecha de vencimiento de certificado'
      },
      importers_list_id:{
        label:'Padrón de importadores',
        placeholder:'Padrón de importadores'
      },
      fiscal_address:{
        label: 'Dirección',
        placeholder: 'Dirección'
      },
      fiscal_obligation_id:{
        label:'Obligaciones Fiscales',
        placeholder:'Obligaciones Fiscales'
      },
      ciec_key:{
        label:'Clave CIEC',
        placeholder:'Clave CIEC'
      },
      accounting_method_id:{
        label:'Método de pago de impuestos',
        placeholder:'Método de pago de impuestos'
      },
      payday_limit:{
        label:'Límite envío Impuestos',
        placeholder:'Límite envío Impuestos'
      }
    },
  },
}