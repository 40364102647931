import { catalogs } from "../../../texts/esp/catalogs";

export const formData = {
    payment_date:{
        value:null,
        isRequired:false,
        isValid:false,
        isVisited:false,
        formType:'normal',
        config:{id:'payment_date', type:'date', placeholder:'Seleccione una fecha', helperText:'La fecha no es válida'},
        rules:{type:'none'}
    },
    amount:{
        value:null,
        isRequired:false,
        isValid:false,
        isVisited:false,
        formType:'normal',
        config:{id:'amount', type:'number', placeholder:'Ingrese un valor', helperText:'El mínimo valor aceptable es 0'},
        rules:{type:'numeric', min:0, max:999999999}
    },
    responsible_id:{
        value:null,
        isRequired:false,
        isValid:false,
        isVisited:false,
        options:[],
        formType:'select',
        config:{id:'responsible_id', type:'select', placeholder:'Seleccione una opcion', helperText:'El campo no es válido'},
        rules:{type:'none'}
    },
    notes:{
        value:null,
        isRequired:false,
        isValid:false,
        isVisited:false,
        formType:'normal',
        config:{id:'notes', type:'text', placeholder:'Seleccione una fecha', helperText:'La fecha no es válida'},
        rules:{type:'none'}
    },
    completition_date:{
        value:null,
        isRequired:false,
        isValid:false,
        isVisited:false,
        formType:'normal',
        config:{id:'completition_date', type:'date', placeholder:'Seleccione una fecha', helperText:'La fecha no es válida'},
        rules:{type:'none'}
    },
    completed:{
        value:null,
        isRequired:false,
        isValid:false,
        isVisited:false,
        formType:'checkbox',
        config:{id:'completed', type:'checkbox'},
        rules:{type:'none'}
    },

}