import React, { useEffect, useState } from 'react';
import { Grid, makeStyles } from '@material-ui/core';
import ActionModalBar from '../../../../../../components_v1/Actions/ActionModalBar';
import InputText from '../../../../../../components_v1/Forms/InputText';
import SimpleModal from '../../../../../../components_v1/Structure/Modals/SimpleModal';
import DisplayText from '../../../../../../components_v1/Texts/DisplayText';
import { isFormValid, onGetFormData, onInitEmptyForm, onSetErrorsToForm } from '../../../../../../shared/utility';

const useStyles = makeStyles(theme => ({
    form: {
        paddingTop: 32,
        paddingBottom: 32
    }
}))

const ChangePasswordModal = props => {

    const classes = useStyles()
    const { open, onClose, history, language, content } = props
    const [form, setForm] = useState(JSON.parse(JSON.stringify(formData)))
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState(null)

    useEffect(() => {
        if (!open) {
            let _form = JSON.parse(JSON.stringify(formData))
            _form = onInitEmptyForm(_form, content.form)
            setForm({ ..._form })
            setError('')
            setLoading(false)
        }
    }, [open])

    const onChange = (data) => {
        let temp = { ...form }
        const id = data.config.id
        temp[id] = { ...data }
        setForm(temp)
    }

    const onSubmit = async () => {
        const errors = isFormValid(form)
        /// Check if the form is valid
        if (errors && errors.length) {
            const new_form = onSetErrorsToForm(form, errors)
            setForm(new_form)
            return
        }

        //Extract data
        let data2send = onGetFormData(form)
        //Send data
        setLoading(true)
        props.onSubmit(data2send)
        setLoading(false)
    }

    return (
        <SimpleModal open={open} onClose={onClose} maxWidth='md'>
            <div>
                <DisplayText variant='h6' color='primary' style={{ fontWeight: 500 }}>{content.title}</DisplayText>
                <div className={classes.form}>
                    <Grid container spacing={3}>
                        <Grid item xs={12}>
                            <InputText data={form.password} onChange={onChange} />
                        </Grid>
                        <Grid item xs={12}>
                            <ActionModalBar btnLabel='Guardar' loading={loading} error={error} onSubmit={onSubmit} />
                        </Grid>
                    </Grid>
                </div>
            </div>
        </SimpleModal>
    );
}

export const formData = {
    password: {
        value: null,
        error: false,
        isVisited: false,
        isRequired: true,
        isValid: false,
        config: {
            id: 'password',
            type: 'password',
            fullWidth: true,
            label:'Nueva Contraseña',
            placeholder:'Nueva Contraseña'
        },
        rules: {
            type: 'distance',
            min: 1,
            max: 100
        }
    }
}

export default ChangePasswordModal;