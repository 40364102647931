export const contentData = {
  spanish:{
    title:'Editar llave en mano',
    nodata:'Sin Registro',
    error:'Sin registro',
    button:'Guardar',
    cancelButton:'Cancelar',
    image_button:'Cambiar',
    form:{
      name:{
        label:'Nombre',
        placeholder:'Nombre',
      }, 
      fiscal_address:{
        label:'Domicilio Fiscal',
        placeholder:'Domicilio Fiscal'
      }
    },
  },
}