import React, {Component} from 'react'
import { withStyles } from '@material-ui/core/styles'
import cx from 'classnames'
import {Fab, Icon} from '@material-ui/core'
import {grey, red, green} from '@material-ui/core/colors'

const rounded_button_styles = (theme) => ({
  icon:{
    marginRight:'8px'
  },
  button:{
    color:'white',
    textTransform:'none',
    padding:'0px 40px',
  },
  small_button:{
    padding:'0px 16px',
  },

  secondary_button:{
    background:theme.palette.secondary.main,
    '&:hover':{
      background:theme.palette.secondary.dark,
    }
  },
  white_button:{
    color:grey[500],
    background:`linear-gradient(135deg, #FFF, ${grey[50]})`,
    '&:hover':{
      background:`linear-gradient(135deg, ${grey[50]}, ${grey[100]})`,
    },
  },
  red_button:{
    background:`linear-gradient(135deg, ${red[500]}, ${red[900]})`,
    '&:hover':{
      background:`linear-gradient(135deg, ${red[700]}, ${red[900]})`,
    },
  },
  green_button:{
    background:green[700],
    '&:hover':{
      background:green[900],
    },
  },
  grey_button:{
    background:grey[400],
    '&:hover':{
      background:grey[500],
    },
  },
  transparent_button:{
    background:'#FFF',
    '&:hover':{
      background:'#FFF',
    },
    color:grey[700],
    boxShadow:'none',
  },
  primary_button:{
    background:theme.palette.primary.main,
    '&:hover':{
      background:theme.palette.primary.dark,
    }
  },
})

class RoundedButtonW extends Component {

  render(){

    const {classes, icon, label, color, onClick, size, disabled} = this.props;

    let iconContent = null;
    if(icon){
      iconContent = <Icon className={classes.icon}>{icon}</Icon>
    }
    return(
      <div>
        <Fab
          variant="extended"
          size={size}
          disabled={disabled}
          onClick={onClick}
          onKeyPress={this.handleKeyPress}
          className={cx({
            [classes.button]:true,
            [classes.secondary_button]:true,
            [classes.white_button]:color==='white',
            [classes.green_button]:color==='green',
            [classes.red_button]:color==='red',
            [classes.grey_button]:color==='grey',
            [classes.transparent_button]:color==='transparent',
            [classes.primary_button]:color==='primary',
          })}>
          {iconContent}
          <div className={cx({
            [classes.small_button]: size==='small' && (icon === null || icon === undefined )
          })}>{label}</div>
        </Fab>
      </div>
    )
  }
}

export const RoundedButton = withStyles(rounded_button_styles)(RoundedButtonW)
