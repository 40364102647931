import React, { useRef, useState } from 'react';
import { Badge, Button, Grid, Icon, IconButton, makeStyles, Menu, MenuItem, Typography } from '@material-ui/core';
import { blueGrey } from '@material-ui/core/colors';
import cx from 'classnames'
import RoundedButton from '../../../../../../components_v1/Actions/RoundedButton';
import SearchBar from './SearchBar';

const useStyles = makeStyles(theme => ({
    addIcon:{
        background:theme.palette.secondary.main,
        borderRadius:'100%',
        color:'white',
        '&:hover':{
            background:theme.palette.secondary.dark,
        },
        width: 40,
        height: 40
    },
}))

const ToolbarTable = (props) => {
	const classes = useStyles();

	const { selected, onChange, onAddRegister, menuOptions, search, onChangeSearch, tableFilter, onChangeTableFilter, filterOptions } = props;

	return (
		<div>
			<Grid container spacing={3}>
				{menuOptions.map((item, i) => {
					return (
						<Grid item key={i}>
							<CustomButton number={item.number} onClick={() => onChange(item.id)} selected={item.id === selected}>
								{item.label}
							</CustomButton>
						</Grid>
					);
				})}
				<Grid item xs />
				<Grid item>
					<FilterMenu filterOptions={filterOptions} tableFilter={tableFilter} onChangeTableFilter={onChangeTableFilter} />
				</Grid>
				<Grid item>
					<SearchBar value={search} onChange={onChangeSearch} />
				</Grid>
				<Grid item>
					<IconButton className={classes.addIcon} color='primary' onClick={onAddRegister}>
						<Icon>add</Icon>
					</IconButton>
				</Grid>
			</Grid>
		</div>
	);
};

export default ToolbarTable;

const useButtonStyles = makeStyles((theme) => ({
	root: { position: 'relative' },
	button: {
		background: blueGrey[100],
		color: blueGrey[700],
		borderRadius: 8,
		padding: '8px 16px',
		'&:hover': { background: blueGrey[200] },
		textTransform: 'none',
	},
	selected: {
		color: 'white',
		background: theme.palette.primary.dark,
		fontWeight: 500,
		boxShadow: '0 10px 24px 0 rgba(82, 91, 115, .12)',
		'&:hover': { background: theme.palette.secondary.dark },
		//border:`1px solid ${theme.palette.primary.main}`
	},
	buttonFilter: {
		background: blueGrey[100],
		color: blueGrey[700],
        borderRadius: '0px 8px 8px 0px',
		padding: '7px 16px',
		'&:hover': { background: blueGrey[200] },
		textTransform: 'none',
	},
	selectedFilter: {
		color: 'white',
		background: theme.palette.primary.dark,
		fontWeight: 500,
		boxShadow: '0 10px 24px 0 rgba(82, 91, 115, .12)',
		'&:hover': { background: theme.palette.secondary.dark },
		borderRadius: '8px 0px 0px 8px',
	},
}));

const CustomButton = (props) => {
	const classes = useButtonStyles();
	const { children, selected, onClick, number } = props;

	return (
		<div className={classes.root}>
			<Badge badgeContent={number} color='error'>
				<Button
					onClick={onClick}
					className={cx({
						[classes.button]: true,
						[classes.selected]: selected,
					})}
				>
					{children}
				</Button>
			</Badge>
		</div>
	);
};

const FilterMenu = ({ tableFilter, onChangeTableFilter, filterOptions }) => {
	const selected = filterOptions.find((item) => item.id === tableFilter.order_by);

	const [open, setOpen] = useState(false);
    const anchorRef = useRef(null)

    const classes = useButtonStyles()

	const onSelect = (id) => {
		let _tableFilter = {...tableFilter};
		_tableFilter.order_by = id;
		setOpen(false);
		onChangeTableFilter(_tableFilter);
	};

	const onChangeOrder = (prevOrder) => {
		let _tableFilter = {...tableFilter};
		_tableFilter.order = prevOrder === 1 ? 0 : 1;
		setOpen(false);
		onChangeTableFilter(_tableFilter);
	};

    let iconOrder = tableFilter.order === 1 ? <Icon>keyboard_arrow_down</Icon> : <Icon>keyboard_arrow_up</Icon>

    if(selected.revert)
        iconOrder = tableFilter.order === 1 ? <Icon>keyboard_arrow_up</Icon> : <Icon>keyboard_arrow_down</Icon>

	return (
		<Grid container alignItems='center'>
			<Grid item>
				<Typography style={{marginRight: 15, fontWeight: 500}}>Ordenar por: </Typography>
			</Grid>
			<Grid item>
				<Button className={classes.selectedFilter} ref={anchorRef} aria-controls='filter-menu' aria-haspopup='true' onClick={() => setOpen(!open)}>
					{selected.label}
				</Button>
				<Button className={classes.buttonFilter} onClick={() => onChangeOrder(tableFilter.order)}>
                    {iconOrder}
				</Button>
				<Menu anchorEl={anchorRef.current} id='simple-menu' keepMounted open={open} onClose={() => setOpen(false)}>
					{filterOptions.map((item) => {
						return [
							<MenuItem onClick={() => onSelect(item.id)}>
								{item.label}
							</MenuItem>,
						];
					})}
				</Menu>
			</Grid>
		</Grid>
	);
};