export const formData = {
  sectionA:{
    first_name:{
      label:'',
      data:'',
    },
    last_name:{
      label:'',
      data:'',
    },
    username:{
      label:'',
      data:'',
    },
    gender:{
      label:'',
      data:'',
    },
    scolarity:{
      label:'',
      data:'',
    },
    specialization:{
      label:'',
      data:'',
    },
    user_level:{
      label:'',
      data:'',
    },
    area_name:{
      label:'',
      data:'',
    },
    birth_date:{
      label:'',
      data:'',
      icon:'date_range'
    },
    language:{
      label:'',
      data:'',
      icon:'language'
    },
    timezone:{
      label:'',
      data:'',
      icon:'schedule'
    },
    
  },
  sectionB:{
    rfc:{
      label:'',
      data:'',
    },
    curp:{
      label:'',
      data:'',
    },
    contract_type:{
      label:'',
      data:'',
    },
    contract_duration:{
      label:'',
      data:'',
    },
    gross_salary:{
      label:'',
      data:'',
    },
    net_salary:{
      label:'',
      data:'',
    },
    employee_status:{
      label:'',
      data:'',
    },
    weekly_salary:{
      label:'',
      data:'',
    },
    entrance_hour:{
      label:'',
      data:'',
      icon:'schedule'
    },
    exit_hour:{
      label:'',
      data:'',
      icon:'schedule'
    },
    entry_day:{
      label:'',
      data:'',
      icon:'date_range'
    },
    exit_day:{
      label:'',
      data:'',
      icon:'date_range'
    },

  },
  sectionC:{
    phone_number:{
      label:'',
      data:'',
      icon:'phone'
    },
    work_email:{
      label:'',
      data:'',
      icon:'mail'
    },
    personal_email:{
      label:'',
      data:'',
      icon:'mail'
    },
    gmail:{
      label:'',
      data:'',
      icon:'mail'
    },
    dropbox:{
      label:'',
      data:'',
      icon:'mail'
    },
  },
}