export const contentData = {
  spanish:{
    title:'Información Basica',
    nodata:'Sin Registro',
    error:'Sin registro',
    button:'Guardar',
    cancelButton:'Cancelar',
    image_button:'Cambiar',
    form:{
      rfc:{
        label:'RFC',
        placeholder:'RFC'
      },
      sat_password:{
        label:'Contraseña SAT',
        placeholder:'Contraseña SAT'
      },
      ciec_key:{
        label:'Clave CIEC',
        placeholder:'Clave CIEC'
      }
    },
  },
}