import { catalogs } from "../../../texts/esp/catalogs";

export const content = {
    header:[
        {id:0, label:'',hidden:false, fixed:true, width:26, position:0, padding:'checkbox'},
        {id:1,key:'fixed_business_name', label:'Razón Social',hidden:false, width:300, fixed:true, add:{icon:'add'}, position:34},
        {id:2,key:'fixed_rfc', label:'RFC',hidden:false},
        {id:3,key:'fixed_client_classification', label:'Clasificación',hidden:false},
        {id:4,key:'fixed_client_status_id', label:'Estatus',hidden:false},
        {id:5,key:'fixed_client_responsible', label:'Asesor',hidden:false},
        //Fixed other data
        {id:6,key:'fixed_sub_activity', label:'Regimen Fiscal',hidden:false, add:{icon:'add'}},
        {id:7,key:'fixed_activity', label:'Actividad económica',hidden:false},
        {id:8,key:'fixed_fiscal_situation', label:'Situación fiscal',hidden:false},
        {id:9,key:'fixed_accounting_method', label:'Método de pago de Impuestos',hidden:false},
        {id:10,key:'fixed_payday_limit', label:'Límite envío Impuestos',hidden:false},
        {id:11,key:'ciec_key', label:'Clave CIEC',hidden:false},/// Modif
        /// New data
        {id:20,key:'process_status_id', label:'Estatus',hidden:false},//
        {id:21,key:'provisional_to_charge_isr', label:'ISR Provisional a Cargo',hidden:false},//
        {id:22,key:'isr_salaries_withholdings', label:'Ret. ISR Sueldos y Salarios',hidden:false},//
        {id:23,key:'isr_assimilated_fees_withholdings', label:'Ret. ISR Honorarios Asimilados',hidden:false},//
        {id:24,key:'isr_professional_services_withholdings', label:'Ret. ISR Servicios Profesionales',hidden:false},//
        {id:25,key:'isr_leasing_withholdings', label:'Ret. ISR Arrendamiento',hidden:false},
        {id:26,key:'other_income_isr_withholdings', label:'Otras Retenciones de ISR',hidden:false},//
        {id:27,key:'isr_withholdings', label:'Ret. ISR',hidden:false},//
        {id:28,key:'vat_withholdings', label:'Ret. de IVA',hidden:false},//
        {id:29,key:'vat_to_charge_withholdings', label:'Ret. de IVA a Cargo',hidden:false},//
        {id:30,key:'vat_in_favor_withholdings', label:'Ret. de IVA a Favor',hidden:false},//
        {id:31,key:'vat_accreditation', label:'Acreditamiento de IVA',hidden:false},//
        {id:32,key:'vat_to_charge_in_favor', label:'IVA a Cargo/Favor',hidden:false},//
        {id:33,key:'ieps_accreditation', label:'Acreditamiento de IEPS',hidden:false},//
        {id:34,key:'ieps_to_charge_in_favor', label:'IEPS a Cargo/Favor',hidden:false},//
        {id:35,key:'tax_accessories', label:'Accesorios Fiscales',hidden:false},//
        {id:36,key:'total_to_pay', label:'Total a Pagar',hidden:false},//
        {id:37,key:'payment_limit_date', label:'Fecha Límite de Pago',hidden:false},///
        {id:38, label:'Acuse',hidden:false},//
        {id:39, label:'Línea de Captura',hidden:false},//
        {id:40, label:'Respuesta Cliente',hidden:false},//
        {id:41,key:'client_comments', label:'Comentarios Cliente',hidden:false},//
        {id:42,key:'diot_folio', label:'DIOT Folio',hidden:false},//
        {id:43, label:'DIOT Archivo',hidden:false},//
        {id:44,key:'diot_presentation_limit_date', label:'DIOT Fecha Límite Presentación',hidden:false},//
        {id:45,key:'diot_responsible', label:'DIOT Responsable',hidden:false},//
        {id:46, label:'DIOT Acuse',hidden:false},//
        {id:47, label:'Buzon Tributario',hidden:false},//
        {id:48, label:'Opinión de Cumplimiento',hidden:false},//
        {id:49,key:'compliance_opinion_status_id', label:'Estado de Opinión de Cumplimiento',hidden:false},//
        {id:50,key:'mailbox_status_id', label:'Buzón Estatus',hidden:false},//
        {id:51,key:'mailbox_comment', label:'Buzón Comentarios',hidden:false},//
        {id:52, label:'Buzón Acuse',hidden:false},//
        {id:53,key:'responsible', label:'Responsable',hidden:false},
        {id:54,key:'notes', label:'Notas',hidden:false},
        {id:55,key:'completition_date', label:'Fecha de Completado',hidden:false},
        {id:56, label:'Completado',hidden:false},

    ],
    dataConfig:[
        {id:'fixed_business_name',type:'text',fixed:true, editBtn:true, position:34},
        {id:'fixed_rfc',type:'text'},
        {id:'fixed_client_classification',type:'text'},
        {id:'fixed_client_status_id',type:'status', options: [...catalogs.client_statuses] },
        {id:'fixed_client_responsible',type:'text'},
        ///////// second fixed data
        {id:'fixed_sub_activity',type:'text'},
        {id:'fixed_activity',type:'text'},
        {id:'fixed_fiscal_situation',type:'text'},
        {id:'fixed_accounting_method',type:'text'},
        {id:'fixed_payday_limit',type:'text'},
        {id:'ciec_key',type:'text'},
        // Real data
        {id:'process_status_id', type:'status', options: [...catalogs.ctrl_process_statuses], editable:true},
        
        {id:'provisional_to_charge_isr',type:'cash', editable:true},
        {id:'isr_salaries_withholdings', type:'cash', editable:true},
        {id:'isr_assimilated_fees_withholdings', type:'cash', editable:true},
        {id:'isr_professional_services_withholdings', type:'cash', editable:true},
        {id:'isr_leasing_withholdings', type:'cash', editable:true},
        {id:'other_income_isr_withholdings', type:'cash', editable:true},
        {id:'isr_withholdings', type:'cash', editable:true},
        {id:'vat_withholdings', type:'cash', editable:true},
        {id:'vat_to_charge_withholdings', type:'cash', editable:true},
        {id:'vat_in_favor_withholdings', type:'cash', editable:true},
        {id:'vat_accreditation', type:'cash', editable:true},
        {id:'vat_to_charge_in_favor', type:'cash', editable:true},
        {id:'ieps_accreditation', type:'cash', editable:true},
        {id:'ieps_to_charge_in_favor', type:'cash', editable:true},
        {id:'tax_accessories', type:'cash', editable:true},
        
        {id:'total_to_pay', type:'cash', editable:true},
        {id:'payment_limit_date', type:'date', editable:true},
        {id:'receipt', type:'document'},
        {id:'capture_line', type:'document'},
        {id:'client_response', type:'document'},
        {id:'client_comments', type:'text', editable:true},
        {id:'diot_folio', type:'text', editable:true},
        {id:'diot_file', type:'document'},
        {id:'diot_presentation_limit_date', type:'date', editable:true},
        {id:'diot_responsible', formId:'diot_responsible_id', type:'text', editable:true},
        {id:'diot_receipt', type:'document'},
        {id:'tax_mailbox', type:'document'},
        {id:'compliance_opinion', type:'document'},
        {id:'compliance_opinion_status_id', type:'status', options:[...catalogs.ctrl_compliance_opinion_statuses],editable:true}, //Actualizar catalogo
        {id:'mailbox_status_id', type:'status', options:[...catalogs.ctrl_mailbox_statuses], editable:true},// Check if it is text or catalog
        {id:'mailbox_comment', type:'text', editable:true}, 
        {id:'mailbox_receipt', type:'document'},
        {id:'responsible', formId:'responsible_id', type:'text', editable:true},
        {id:'notes',type:'text',editable:true},
        {id:'completition_date',type:'date', variant:'date',editable:true},
        {id:'completed',type:'checkbox',editable:true},
    ]
}