import React, { useState, useEffect } from 'react';
import BasicView from '../../../components_v1/Structure/Layouts/BasicView';
import { onGetUserID } from '../../../shared/utility';
import ToolbarTable from './components/ToolbarTable';
import { request_pending_timereports, request_rejected_timereports } from './requests';
import GeneralTableView from './views/GeneralTableView';
import queryString from 'query-string' 

const FullTimeReports = (props) => {

    const {history} = props

    const [success, setSuccess] = useState(null)
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState(null)
    const [addtimereportmodal, setAddtimereportmodal] = useState(false)
    const [selected, setSelected] = useState(1) // 1. General View    2. Approved view  3. Rejected  4. Supervisados
    const [pending, setPending] = useState(0)
    const [rejected, setRejected] = useState(0)
    const [businessModal, setBusinessModal] = useState(false)
    const [business, setBusiness] = useState(null)


    return ( 
        <div>
            <BasicView title='Time Report General' error={error} onCloseError={() => setError(null)} loading={loading}
            success={success} onCloseSuccess={() => setSuccess(null)}>
                <div style={{paddingTop:32}}>
                    <GeneralTableView history={history} />
                </div>

            </BasicView>
        </div> 
     );
}
 
export default FullTimeReports;