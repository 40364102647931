export const contentData = {
  spanish: {
    layout: {
      title: 'Mi Perfil',
      navigation: [
        { name: 'Perfil', path: '/profile' },
        { name: 'Detalles', path: '/profile' },
      ]
    },
    modals: {
      warning_delete_file: {
        title: '¿Estás seguro?',
        message: '¿Realmente quieres eliminar este registro? Este proceso no puede ser revertido',
        cancelButton: 'Cancelar',
        deleteButton: 'Eliminar'
      },
    }
  }
}
