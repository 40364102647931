import React from 'react';
import {
	Checkbox,
	Grid,
	Hidden,
	Icon,
	IconButton,
	LinearProgress,
	makeStyles,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	Typography,
	withStyles,
} from '@material-ui/core';
import { onGetDateFormat } from '../../../../shared/utility';
import { grey } from '@material-ui/core/colors';
import { catalogs } from '../../../../texts/esp/catalogs';
import moment from 'moment';
import { nodata } from '../../../../texts/esp/general';

const CustomizedTableRow = withStyles((theme) => ({
	root: {
		background: 'white',
		// boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.07)',
		borderRadius: 15,
		height: 85,
		'& td:first-child': {
			borderRadius: '15px 0px 0px 15px',
		},
		'& td:last-child': {
			borderRadius: '0px 15px 15px 0px',
		},
		'&:hover': {
			cursor: 'pointer',
			background: grey[100],
		},
	},
}))(TableRow);

const Subtasks = ({ content, subtasks, onAddSubtask, onDeleteSubtask, onSelectSubtask, onUploadDocument, onDownloadDocument, onDeleteDocument, loading }) => {
	const classes = useStyles();

	return (
		<Grid container className={classes.mt_sm} alignItems='center' style={{ marginTop: 50 }}>
			<Grid item container xs={12} alignItems='center'>
				<Grid item>
					<Typography className={classes.task_title}>{content.title}</Typography>
				</Grid>
				<Grid item style={{ marginLeft: 30 }}>
					<IconButton fontSize='small' className={classes.edit_button} onClick={onAddSubtask}>
						<Icon>add</Icon>
					</IconButton>
				</Grid>
			</Grid>
			{subtasks.length > 0 ? (
				<Grid item xs={12}>
					<TableContainer>
						<Table size='small' aria-label={content.title} stickyHeader className={classes.table}>
							<TableHead>
								<TableRow>
									{content.header.map((item) => (
										<TableCell key={item.id} className={classes.section_title}>
											{item.label}
										</TableCell>
									))}
								</TableRow>
							</TableHead>
							<TableBody>
								{subtasks.map((task, i) => {
									const {expiration_date, finish_date} = task
									const status = catalogs.task_status.find((item) => item.id === task.task_status_id);
									const expiringDate = finish_date ? moment(expiration_date).diff(moment(finish_date), 'hours') < 24 : moment(expiration_date).diff(moment(), 'hours') < 24

									return (
										<CustomizedTableRow className={classes.row} key={i}>
											<TableCell className={classes.cell} onClick={() => onSelectSubtask(task)}>
												<Typography className={classes.text_cell}>{task.title}</Typography>
											</TableCell>
											<TableCell className={classes.cell} onClick={() => onSelectSubtask(task)}>
												<div className={classes.user_container}>{task.user}</div>
											</TableCell>
											<TableCell className={classes.cell} onClick={() => onSelectSubtask(task)}>
												<Typography style={expiringDate ? { color: 'red' } : {}} className={classes.text_cell}>
													{moment.utc(task.expiration_date).local().format('DD/MMMM/YYYY')}
												</Typography>
											</TableCell>
											<TableCell className={classes.cell} onClick={() => onSelectSubtask(task)}>
												<Typography className={classes.text_cell}>
													{task.finish_date ? moment.utc(task.finish_date).local().format('DD/MMMM/YYYY') : nodata}
												</Typography>
											</TableCell>
											<TableCell className={classes.cell} onClick={() => onSelectSubtask(task)}>
												<div className={classes.user_container}>
													<Icon fontSize='small' style={{ color: status.color, marginRight: 6 }}>
														adjust
													</Icon>
													{status.label}
												</div>
											</TableCell>
											<TableCell className={classes.cell}>
												<Grid container>
													<Grid item>
														<IconButton color='secondary' onClick={() => onUploadDocument(task)}>
															<Icon fontSize='small'>cloud_upload</Icon>
														</IconButton>
													</Grid>
													<Grid item>
														<IconButton disabled={task.document === '0' || !task.document} color='primary' onClick={() => onDownloadDocument(task)}>
															<Icon fontSize='small'>cloud_download</Icon>
														</IconButton>
													</Grid>
													<Grid item>
														<IconButton onClick={() => onDeleteDocument(task)}>
															<Icon style={{ color: 'red' }} fontSize='small'>
																close
															</Icon>
														</IconButton>
													</Grid>
												</Grid>
											</TableCell>
											<TableCell className={classes.cell}>
												<IconButton onClick={() => onDeleteSubtask(task.id_task)}>
													<Icon style={{ color: '#9AA6B4' }}>delete</Icon>
												</IconButton>
											</TableCell>
										</CustomizedTableRow>
									);
								})}
							</TableBody>
						</Table>
					</TableContainer>
				</Grid>
			) : (
				<Grid item xs>
					<Typography variant='h5' align='center' style={{ marginTop: 50 }} color='primary'>
						{content.notask}
					</Typography>
				</Grid>
			)}
		</Grid>
	);
};

const useStyles = makeStyles((theme) => ({
	table: {
		borderSpacing: '0px 6px',
		borderCollapse: 'separate',
		marginTop: 50,
	},
	section_title: {
		fontWeight: 600,
		fontSize: '0.75rem',
		color: '#9AA6B4',
		background: '#edf2f8',
		borderBottom: 'none',
	},
	task_title: {
		fontWeight: 500,
		fontSize: '1.375rem',
		color: theme.palette.primary.main,
	},
	edit_button: {
		width: 35,
		height: 35,
		background: theme.palette.secondary.light,
		'&:hover': {
			background: theme.palette.secondary.main,
		},
		'& .MuiIcon-root': {
			color: 'white',
		},
	},
	cell: {
		borderBottom: 'none',
		position: 'relative',
	},
	row: {},
	user_container: {
		fontWeight: 400,
		fontSize: '1rem',
		display: 'flex',
		alignItems: 'center',
	},
	text_cell: {
		fontSize: '1rem',
		fontWeight: 400,
	},
	mt_sm: {
		[theme.breakpoints.down('sm')]: {
			marginTop: 20,
		},
	},
}));

export default Subtasks;
