import { onGetYearCatalog } from "../../../../../shared/utility";

export const contentData = {
    spanish: {
        title: 'Evaluaciones',
        button: null,
        nodata: 'Sin Registro',
        year_options: onGetYearCatalog(),
        chart: {
            title: 'General',
            bars: {
                results: {
                    id: 'results_total',
                    label: 'Orientación de resultados',
                    color: '#30C7FF'
                },
                quality: {
                    id: 'quality_total',
                    label: 'Calidad',
                    color: '#133975'
                },
                relations: {
                    id: 'relations_total',
                    label: 'Relaciones interpersonales',
                    color: '#F69955'
                },
                iniciative: {
                    id: 'iniciative_total',
                    label: 'Iniciativa',
                    color: '#FFD600'
                },
                organization: {
                    id: 'organization_total',
                    label: 'Organización',
                    color: '#4B92F8'
                },
            }
        },
        mix_chart: {
            title: 'Historico',
            bars: {
                results: {
                    id: 'results_total',
                    label: 'Orientación de resultados',
                    color: '#30C7FF'
                },
                quality: {
                    id: 'quality_total',
                    label: 'Calidad',
                    color: '#133975'
                },
                relations: {
                    id: 'relations_total',
                    label: 'Relaciones interpersonales',
                    color: '#F69955'
                },
                iniciative: {
                    id: 'iniciative_total',
                    label: 'Iniciativa',
                    color: '#FFD600'
                },
                organization: {
                    id: 'organization_total',
                    label: 'Organización',
                    color: '#4B92F8'
                },
            }
        }
    }
}
