

export const contentData = {
    spanish: {
        title: '¡Te damos la bienvenida!',
        best_evaluation: 'Consultores inspiradores del mes con mejor evaluación. ',
        congrats:'¡Felicidades!',
        happy_birthday:'¡Feliz cumpleaños!',
        bless:'¡Dios los bendiga!',
        
    }
}
