import { useEffect, useState } from 'react';
import {
	request_add_user,
	request_businesses,
	request_create_comment,
	request_create_task,
	request_delete_comment,
	request_delete_document,
	request_delete_task,
	request_delete_task_user,
	request_download_document,
	request_edit_comment,
	request_edit_task,
	request_set_main_user,
	request_subtasks,
	request_task,
	request_tasks,
	request_task_comments,
	request_task_users,
	request_upload_document,
	request_users,
} from './requests';
import queryString from 'query-string';
import {
	isFormValid,
	onGetCatalogs,
	onGetDateFormat,
	onGetErrorMessage,
	onGetFileExtension,
	onGetFormData,
	onGetFormDataNew,
	onGetSelectedFormData,
	onGetSelectedFormDataNew,
	onInitEmptyForm,
	onInitForm,
	onSetErrorsToForm,
} from '../../../shared/utility';
import { catalogs } from '../../../texts/esp/catalogs';
import moment from 'moment';

const useTaskView = ({ history, userID, taskID, content }) => {
	const [loading, setLoading] = useState(false);
	const [sending, setSending] = useState(false);
	const [error, setError] = useState(null);
	const [success, setSuccess] = useState(null);
	const [form, setForm] = useState({ ...formData });

	const [task, setTask] = useState({});
	const [users, setUsers] = useState([]);
	const [file, setFile] = useState(null);
	const [subtasks, setSubtasks] = useState([]);
	const [comments, setComments] = useState([]);
	const [selectedSubtask, setSelectedSubtask] = useState(null);
	const [selectedComment, setSelectedComment] = useState(null);
	const [description, setDescription] = useState('')
	const [comment, setComment] = useState('')
	const [add_comment, setAddComment] = useState('')
	const [subtaks_description, setSubtaksDescription] = useState('')
	const [subtaks_des_add, setSubtaksDesAdd] = useState('')

	const [edit_task_modal, setEditTaskModal] = useState(false);
	const [edit_subtask_modal, setEditSubtaskModal] = useState(false);
	const [upload_document_modal, setUploadDocumentModal] = useState(false);
	const [add_user_modal, setAddUserModal] = useState(false);
	const [add_subtask_modal, setAddSubtaskModal] = useState(false);
	const [add_comment_modal, setAddCommentModal] = useState(false);
	const [edit_comment_modal, setEditCommentModal] = useState(false);

	useEffect(() => {
		actions.onInitModule();
	}, []);

	// useEffect(() => {
	// 	setForm({ ...formData });
	// }, [add_modal]);

	const actions = {
		onInitModule: async () => {
			// setLoading(true);

			try {
				//Get task
				const _task = await request_task(taskID);

				setTask(_task);

				//Get task users
				let filter = { AND: [{ field: 'task_id', operator: '=', value: taskID }] };
				filter = encodeURIComponent(JSON.stringify(filter));
				const _users = await request_task_users(`?filter=${filter}`);
				setUsers(_users);

				//Get subtasks
				filter = { AND: [{ field: 'parent_task_id', operator: '=', value: taskID }] };
				filter = encodeURIComponent(JSON.stringify(filter));
				const _st = await request_subtasks(`?filter=${filter}`);
				setSubtasks(_st);

				//Get task comments
				filter = { AND: [{ field: 'task_id', operator: '=', value: taskID }] };
				filter = encodeURIComponent(JSON.stringify(filter));
				const _comments = await request_task_comments(`?filter=${filter}`);
				setComments(_comments);

				//InitForm
				setForm({ ...formData });

				setSelectedSubtask(null);
				setSelectedComment(null);

				// setLoading(false);
			} catch (error) {
				setError(onGetErrorMessage(error));
				// setLoading(false);
			}
		},
		onChange: (data) => {
			let temp = { ...form };
			const id = data.config.id;
			temp[id] = { ...data };
			setForm(temp);
		},
		onChangeModalStatus: (key, status) => {
			switch (key) {
				case 'edit_task':
					setEditTaskModal(status);
					if (!status) {
						setSelectedSubtask(null);
					}
					break;
				case 'edit_subtask':
					setEditSubtaskModal(status);
					if (!status) {
						setSelectedSubtask(null);
					}
					break;
				case 'upload_document':
					setUploadDocumentModal(status);
					if (!status) {
						setSelectedComment(null);
						setSelectedSubtask(null);
						setFile(null)
					}
					break;
				case 'add_user':
					setAddUserModal(status);
					break;
				case 'add_subtask':
					setAddSubtaskModal(status);
					break;
				case 'add_comment':
					setAddCommentModal(status);
					break;
				case 'edit_comment':
					setEditCommentModal(status);
					break;
				default:
					break;
			}
		},
		onChangeError: (error) => {
			setError(error);
		},
		onChangeSuccess: (success) => {
			setError(success);
		},
		onChangeFile: (file) => {
			setFile(file);
		},
		onSetDescription: (val) => setDescription(val),
		onSetSubtaksDescription: (val) => setSubtaksDescription (val),
		onSetSubtaskDesAdd: (val) => setSubtaksDesAdd(val),
		onSetAddComment: (val) => setAddComment(val),
		onOpenEditTaskModal: () => {
			//console.log(task)
			let _form = onInitForm(formData, task, content.editTaskForm);
			_form.expiration_date.value =  moment.utc(_form.expiration_date.value).local().format('YYYY-MM-DD')
			const userOptions = onGetCatalogs(users, 'user_id', 'user');
			_form.main_task_user_id.options = userOptions;
			setForm(_form);
			setDescription(task.description ? task.description : '' )
			actions.onChangeModalStatus('edit_task', true);
		},
		onEditTask: async () => {
			const taskKeys = Object.keys(content.editTaskForm);
			const errors = isFormValid(form, taskKeys);
			/// Check if the form is valid
			if (errors && errors.length) {
				const new_form = onSetErrorsToForm(form, errors);
				setForm(new_form);
				return;
			}
			let data2send = {}
			let id = null 
			//Extract data
			if(selectedSubtask){
				data2send = onGetSelectedFormDataNew(form, selectedSubtask);
				data2send.created_by = selectedSubtask.created_by;
				data2send.description = subtaks_description
				id = selectedSubtask.id_task
			}
			else{
				data2send = onGetSelectedFormDataNew(form, task);
				data2send.created_by = task.created_by;
				data2send.description = description
				id = taskID
			}

			if(data2send.task_status_id && data2send.task_status_id === 6)
				data2send.finish_date = moment().utc().format()

			if(data2send.expiration_date)
				data2send.expiration_date = moment(data2send.expiration_date).utc().format()

			

			console.log(data2send);
			setSending(true);

			try {
				//Send data
				if(selectedSubtask && data2send.user_id){

					let filter = { AND: [{ field: 'task_id', operator: '=', value: selectedSubtask.id_task }] };
					filter = encodeURIComponent(JSON.stringify(filter));
					const _users = await request_task_users(`?filter=${filter}`);
					const old_taskuser_id = _users[0].id_task_user
					await request_delete_task_user(old_taskuser_id)
					const datauser = {task_id: selectedSubtask.id_task, user_id: data2send.user_id, main: 1}
					await request_add_user(datauser)

					delete data2send.user_id
					
				}

				await request_edit_task(id, data2send);
				actions.onChangeModalStatus(selectedSubtask ? 'edit_subtask' : 'edit_task', false);
				actions.onInitModule();
				setSending(false);
			} catch (error) {
				setError(onGetErrorMessage(error));
				setSending(false);
			}
		},
		onSubmitDocument: async () => {
			try {
				if (!file) {
					setError(content.uploadDocumentModal.error);
					return;
				}
				setSending(true);
				const fileext = onGetFileExtension(file.name);
				const file2send = { ext: fileext, document: 'document' };
				console.log(selectedSubtask);
				console.log(selectedComment);
				if (selectedSubtask) await request_upload_document(selectedSubtask.id_task, file, file2send, 'task');
				else if (selectedComment) await request_upload_document(selectedComment.id_task_comment, file, file2send, 'taskcomment');
				else await request_upload_document(taskID, file, file2send, 'task');
				actions.onChangeModalStatus('upload_document', false);
				await actions.onInitModule();
			} catch (error) {
				console.log(error);
				setError(onGetErrorMessage(error));
			}
			setSending(false);
		},
		onDownloadDocument: async (item) => {
			setSending(true);
			let id;
			let namefile;
			let url;
			if (item && (item.id_task || item.id_task_comment)) {
				if (item.id_task) {
					id = item.id_task;
					namefile = item.title.replace(/[^a-zA-Z]/g, '');
					url = 'task';
				} else {
					id = item.id_task_comment;
					namefile = item.title.replace(/[^a-zA-Z]/g, '');
					url = 'taskcomment';
				}
			} else {
				id = taskID;
				namefile = task.title.replace(/[^a-zA-Z]/g, '');
				url = 'task';
			}

			try {
				await request_download_document(id, namefile, url);
				setSending(false);
			} catch (error) {
				console.log(error);
				setError(onGetErrorMessage(error));
				setSending(false);
			}
		},
		onDeleteDocument: async (item) => {
			setSending(true);
			let id;
			let url;
			if (item && (item.id_task || item.id_task_comment)) {
				if (item.id_task) {
					id = item.id_task;
					url = 'task';
				} else {
					id = item.id_task_comment;
					url = 'taskcomment';
				}
			} else {
				id = taskID;
				url = 'task';
			}
			try {
				await request_delete_document(id, url);
				await actions.onInitModule();
				setSending(false);
			} catch (error) {
				console.log(error);
				setError(onGetErrorMessage(error));
				setSending(false);
			}
		},
		onOpenAddUserModal: async () => {
			let _form = onInitEmptyForm(form, content.addUserForm);
			let userOptions = await request_users();
			userOptions = onGetCatalogs(userOptions, 'user_id', 'user');
			_form.user_id.options = userOptions;
			setForm(_form);
			actions.onChangeModalStatus('add_user', true);
		},
		onAddUser: async () => {
			const userKeys = Object.keys(content.addUserForm);
			const errors = isFormValid(form, userKeys);
			/// Check if the form is valid
			if (errors && errors.length) {
				const new_form = onSetErrorsToForm(form, errors);
				setForm(new_form);
				console.log(errors);
				return;
			}

			//Extract data
			let data2send = onGetFormDataNew(form, userKeys);
			data2send.task_id = taskID;
			if (form.main.isVisited) data2send.main = data2send.main ? 1 : 0;

			console.log(data2send);
			setSending(true);

			try {
				//Send data
				await request_add_user(data2send);
				actions.onChangeModalStatus('add_user', false);
				actions.onInitModule();
				setSending(false);
			} catch (error) {
				setError(onGetErrorMessage(error));
				setSending(false);
			}
		},
		onDeleteUser: async (id) => {
			setSending(true);
			try {
				await request_delete_task_user(id);
				await actions.onInitModule();
				setSending(false);
			} catch (error) {
				setError(onGetErrorMessage(error));
				setSending(false);
			}
		},
		onSetMainUser: async (id, prevVal) => {
			setSending(true);
			const data2send = { main: prevVal === 1 ? 0 : 1 };

			try {
				await request_set_main_user(id, data2send);
				await actions.onInitModule();
				setSending(false);
			} catch (error) {
				setError(onGetErrorMessage(error));
				setSending(false);
			}
		},
		onOpenAddSubtaskModal: async () => {
			let _form = onInitEmptyForm(formData, content.addSubtaskForm);
			_form.expiration_date.value = moment.utc(_form.expiration_date.value).local().format('YYYY-MM-DD')
			const userOptions = onGetCatalogs(users, 'user_id', 'user');
			_form.main_task_user_id.options = userOptions;

			setForm(_form);
			actions.onChangeModalStatus('add_subtask', true);
		},
		onAddSubtask: async () => {
			const taskKeys = Object.keys(content.addSubtaskForm);
			const errors = isFormValid(form, taskKeys);
			/// Check if the form is valid
			if (errors && errors.length) {
				const new_form = onSetErrorsToForm(form, errors);
				setForm(new_form);
				return;
			}

			//Extract data
			let data2send = onGetFormDataNew(form, taskKeys);
			data2send.created_by = userID;
			data2send.parent_task_id = task.id_task;
			data2send.task_status_id = 1;
			data2send.description = subtaks_des_add
			const user_id = data2send.main_task_user_id
			delete data2send.main_task_user_id

			if(data2send.expiration_date)
				data2send.expiration_date = moment(data2send.expiration_date).utc().format()

			console.log(data2send);
			setSending(true);

			try {
				//Send data
				const id_task_created = await request_create_task(data2send);
				const datauser = {user_id: user_id, task_id: id_task_created, main: 1}

				await request_add_user(datauser)

				actions.onChangeModalStatus('add_subtask', false);
				await actions.onInitModule();
				setSending(false);
			} catch (error) {
				setError(onGetErrorMessage(error));
				setSending(false);
			}
		},
		onUploadSubtaskDocument: (task) => {
			setSelectedSubtask(task);
			actions.onChangeModalStatus('upload_document', true);
		},
		onDeleteSubtask: async (id) => {
			setSending(true);
			try {
				//Send data
				await request_delete_task(id);
				await actions.onInitModule();
				setSending(false);
			} catch (error) {
				setError(onGetErrorMessage(error));
				setSending(false);
			}
		},
		onSelectSubtask: (task) => {
			setSelectedSubtask(task)
			let _form = onInitForm(form, task, content.editSubtaskForm);
			console.log(task)
			_form.expiration_date.value = moment.utc(_form.expiration_date.value).local().format('YYYY-MM-DD')
			const userOptions = onGetCatalogs(users, 'user_id', 'user');
			_form.user_id.options = userOptions;
			setForm(_form);
			setSubtaksDescription(task.description)
			actions.onChangeModalStatus('edit_subtask', true);
		},
		onOpenAddCommentModal: () => {
			let _form = onInitEmptyForm(formData, content.addCommentForm);
			setForm(_form);
			actions.onChangeModalStatus('add_comment', true);
		},
		onAddComment: async () => {
			const commentKeys = Object.keys(content.addCommentForm);
			const errors = isFormValid(form, commentKeys);
			/// Check if the form is valid
			if (errors && errors.length) {
				const new_form = onSetErrorsToForm(form, errors);
				setForm(new_form);
				return;
			}

			//Extract data
			let data2send = onGetFormDataNew(form, commentKeys);
			data2send.user_id = userID;
			data2send.task_id = taskID;
			data2send.comment_date = moment().utc().format()
			data2send.comment = add_comment

			console.log(data2send);
			setSending(true);

			try {
				//Send data
				await request_create_comment(data2send);
				actions.onChangeModalStatus('add_comment', false);
				await actions.onInitModule();
				setSending(false);
			} catch (error) {
				setError(onGetErrorMessage(error));
				setSending(false);
			}
		},
		onSetComment: (val) => setComment(val),
		onDeleteComment: async (id) => {
			setSending(true);
			try {
				//Send data
				await request_delete_comment(id);
				await actions.onInitModule();
				setSending(false);
			} catch (error) {
				setError(onGetErrorMessage(error));
				setSending(false);
			}
		},
		onOpenEditCommentModal: (comment) => {
			setSelectedComment(comment);
			let _form = onInitForm(formData, comment, content.addCommentForm);

			setForm(_form);
			setComment(comment ? comment.comment : '')
			actions.onChangeModalStatus('edit_comment', true);
		},
		onEditComment: async () => {
			const commentKeys = Object.keys(content.addCommentForm);
			const errors = isFormValid(form, commentKeys);
			/// Check if the form is valid
			if (errors && errors.length) {
				const new_form = onSetErrorsToForm(form, errors);
				setForm(new_form);
				return;
			}

			//Extract data
			let data2send = onGetFormDataNew(form, commentKeys);
			data2send.comment = comment
			console.log(data2send);
			setSending(true);

			try {
				//Send data
				await request_edit_comment(selectedComment.id_task_comment, data2send);
				actions.onChangeModalStatus('edit_comment', false);
				await actions.onInitModule();
				setSending(false);
			} catch (error) {
				setError(onGetErrorMessage(error));
				setSending(false);
			}
		},
		onUploadCommentDocument: (comment) => {
			setSelectedComment(comment);
			actions.onChangeModalStatus('upload_document', true);
		},
	};

	const system = { loading, sending, error, success };
	const view_data = { task, form, users, file, subtasks, comments, description, comment, subtaks_description, subtaks_des_add, add_comment };
	const modals = { edit_task_modal, edit_subtask_modal, upload_document_modal, add_user_modal, add_subtask_modal, add_comment_modal, edit_comment_modal };

	return { system, view_data, actions, modals };
};

const formData = {
	//////TASK////////
	expiration_date: {
		// value: moment().format('YYYY-MM-DD'),
		value: '',
		error: false,
		isVisited: false,
		isRequired: true,
		isValid: false,
		config: {
			id: 'expiration_date',
			type: 'date',
			fullWidth: true,
			// minDate: moment().subtract(2,'days').format('YYYY-MM-DD'),
			// maxDate: moment().add(2,'days').format('YYYY-MM-DD'),
			onKeyDown: (e) => e.preventDefault(),
		},
		rules: {
			// type: 'dateRange',
			// min:moment().subtract(2,'days').format('YYYY-MM-DD'),
			// max:moment().add(2,'days').format('YYYY-MM-DD')
			type: 'date',
		},
	},
	title: {
		value: '',
		error: false,
		isVisited: false,
		isRequired: true,
		isValid: false,
		config: {
			id: 'title',
			type: 'text',
			fullWidth: true,
		},
		rules: { type: 'distance', min:1, max: 240 },
	},
	description: {
		value: '',
		error: false,
		isVisited: false,
		isRequired: false,
		isValid: false,
		config: {
			id: 'description',
			type: 'text',
			fullWidth: true,
			multiline: true,
			rows: 4,
		},
		rules: { type: 'distance', max: 1000 },
	},
	task_status_id: {
		value: '',
		error: false,
		isVisited: false,
		isRequired: true,
		isValid: false,
		options: [...catalogs.task_status],
		config: {
			id: 'task_status_id',
			type: 'select',
			fullWidth: true,
		},
		rules: { type: 'select' },
	},
	task_priority_id: {
		value: '',
		error: false,
		isVisited: false,
		isRequired: true,
		isValid: false,
		options: [...catalogs.task_priority],
		config: {
			id: 'task_priority_id',
			type: 'select',
			fullWidth: true,
		},
		rules: { type: 'select' },
	},
	//////TASK USER//////
	user_id: {
		value: '',
		error: false,
		isVisited: false,
		isRequired: true,
		isValid: false,
		options: [],
		config: {
			id: 'user_id',
			type: 'select',
			fullWidth: true,
		},
		rules: { type: 'select' },
	},
	main: {
		value: false,
		error: false,
		isVisited: false,
		isRequired: false,
		isValid: false,
		config: {
			id: 'main',
		},
		rules: { type: 'none' },
	},
	//////SUBTASK/////
	main_task_user_id: {
		value: '',
		error: false,
		isVisited: false,
		isRequired: true,
		isValid: false,
		options: [],
		config: {
			id: 'main_task_user_id',
			type: 'select',
			fullWidth: true,
		},
		rules: { type: 'select' },
	},
	task_type_id: {
		value: '',
		error: false,
		isVisited: false,
		isRequired: true,
		isValid: false,
		options: [...catalogs.task_type],
		config: {
			id: 'task_type_id',
			type: 'select',
			fullWidth: true,
		},
		rules: { type: 'select' },
	},
	///COMMENT///
	comment: {
		value: '',
		error: false,
		isVisited: false,
		isRequired: false,
		isValid: false,
		config: {
			id: 'comment',
			type: 'text',
			fullWidth: true,
			multiline: true,
			rows: 4,
		},
		rules: { type: 'distance', max: 1000 },
	},
};

export default useTaskView;
