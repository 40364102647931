import React from 'react'
import { CircularProgress, Grid, Icon, IconButton, makeStyles, Paper, Table, TableBody, TableCell, TableHead, TableRow, Tooltip } from '@material-ui/core'
import { green, grey, red } from '@material-ui/core/colors'
import BCFilterButton from './BCFilterButton'
import BCActionBar from './BCActionBar'
import StarData from '../Structure/DisplayData/StarData'
import BCSearchbar from './BCSearchbar'
import DisplayText from '../Texts/DisplayText'
import { onGetTimeDuration, onGetYearCatalog } from '../../shared/utility'
import { fCurrency } from '../../utils/formatNumber'

const header = [
    {id:'buisness_name', label:'Razón social'},
    {id:'business_rfc', label:'RFC'},
    {id:'client_classification', label:'Clasificación'},
    {id:'client_importance', label:'Importancia'},
    {id:'client_associate', label:'Socio'},
    {id:'client_responsible', label:'Responsable'},
    {id:'business_advisor', label:'Asesor'},
    {id:'contract_value', label:'Valor de contrato (s/IVA)'},
    {id:'fixed_cost', label:'Costo fijo'},
    {id:'variable_cost', label:'Costo variable'},
    {id:'formula', label:'Utilidad o Pérdida'},
    {id:'formula_status', label:'Estatus'},
    {id:'monthly_quoted_time', label:'Horas contratadas'},
    {id:'worked_hours', label:'Horas trabajadas'},
    {id:'average_duration', label:'Duración promedio'},
    {id:'average_complexity', label:'Complejidad'},
    {id:'average_client_satisfaction', label:'Satisfación'},
    {id:'ctrl_accounting_operations', label:'Número de operaciones'},
    {id:'ctrl_billing_notes', label:'Notas facturación'},
    {id:'employee_number', label:'Número de trabajadores'},
]




const BusinessCostTable = props => {

    const classes = useStyles()
    const {
        loading, 
        data, 
        total, 
        filter_data,
        tableFilter,
        onDownload,
        onUpdateTableFilter,  
        hide_search,
        hide_month
     } = props

     const {
        search_by,
        year,
        month,
        month_options,
        onChangeFilter,
        onChangeSearch,
        setSearchBy
     } = filter_data

    return(
        <div>
            <div className={classes.toolbar}>
                <Grid container spacing={2} > 
                    <Grid item xs={12}>
                        <Grid container wrap='nowrap' spacing={2} alignItems='flex-end'>
                            {!hide_month && <Grid item><BCFilterButton label='Mes' options={month_options} selected={month} onChange={(val) => onChangeFilter(val,'month')}/></Grid>}
                            <Grid item><BCFilterButton label='Año' options={onGetYearCatalog()} selected={year} onChange={(val) => onChangeFilter(val,'year')}/></Grid>
                            <Grid item xs/>
                            {!hide_search && <>
                                <Grid item>
                                    <BCFilterButton
                                        label='Buscar por'
                                        selected={search_by}
                                        options={[
                                            {value:'business_name', label:'Razón social'},
                                            {value:'business_rfc', label:'RFC'},
                                        ]}
                                        onChange={(val) => setSearchBy(val)}
                                    />
                                </Grid>
                                <Grid item><BCSearchbar onRequestSearch={onChangeSearch}/></Grid>
                            </>}
                            <Grid item>
                                <Tooltip title='Descargar excel'>
                                    <IconButton color='primary' className={classes.download_btn} onClick={onDownload}>
                                        <Icon>download</Icon>
                                    </IconButton>
                                </Tooltip>
                            </Grid>
                        </Grid>
                    </Grid>
                    
                </Grid>
            </div>
            {loading ? (
                <Grid container justifyContent='center'>
                    <Grid item><CircularProgress size={32}/></Grid>
                </Grid>
            ) : null}
            <Paper className={classes.paper}>
                {data && data.length ? (
                    <div className={classes.tableWrapper}>
                        <Table className={classes.table}>
                            {header ? (
                                <TableHead>
                                    <TableRow>
                                        {header.map(item=>{
                                            return(
                                                <TableCell padding={item.id === 1 ? 'default' : 'default'} className={classes.cellHeader} key={item.id.toString()}>
                                                    <DisplayText variant='subtitle2' >{item.label}</DisplayText>
                                                </TableCell>
                                            )
                                        })}
                                    </TableRow>
                                </TableHead>
                            ):null}
                            <TableBody>
                                {data.map((item,key) => {
                                    const {
                                        business_name,
                                        business, average_client_satisfaction, average_complexity, average_duration,
                                        area, client_number, scheduled_time, time_report_status, time_report_status_id, worked_time} = item

                                    //const report_date = onGetDateFormat(date, 'DD MMM YY')
                                    //const timdata = onGetTimeDuration(duration)

                                    const contract_value = parseFloat(item?.contract_value ?? 0)
                                    const fixed_cost = parseFloat(item?.fixed_cost ?? 0)
                                    const variable_cost = parseFloat(item?.variable_cost ?? 0)
                                    const balance = contract_value - fixed_cost - variable_cost

                                    return(
                                        <TableRow key={key.toString()} className={classes.row} >
                                            <TableCell className={classes.cell} style={{minWidth:200}}><DisplayText variant='body2'>{item?.business_name ?? '-'}</DisplayText></TableCell>
                                            <TableCell className={classes.cell} ><DisplayText variant='body2'>{item?.business_rfc ?? '-'}</DisplayText></TableCell>
                                            <TableCell className={classes.cell} ><DisplayText variant='body2'>{item?.client_classification ?? '-'}</DisplayText></TableCell>
                                            <TableCell className={classes.cell} ><DisplayText variant='body2'>{item?.client_importance ?? '-'}</DisplayText></TableCell>
                                            <TableCell className={classes.cell} ><DisplayText variant='body2'>{item?.client_associate ?? '-'}</DisplayText></TableCell>
                                            <TableCell className={classes.cell} ><DisplayText variant='body2'>{item?.client_responsible ?? '-'}</DisplayText></TableCell>
                                            <TableCell className={classes.cell} ><DisplayText variant='body2'>{item?.business_advisor ?? '-'}</DisplayText></TableCell>
                                            <TableCell className={classes.cell} ><DisplayText variant='body2'>{fCurrency(item?.contract_value ?? 0)}</DisplayText></TableCell>
                                            <TableCell className={classes.cell} ><DisplayText variant='body2'>{fCurrency(item?.fixed_cost ?? 0)}</DisplayText></TableCell>
                                            <TableCell className={classes.cell} ><DisplayText variant='body2'>{fCurrency(item?.variable_cost ?? 0)}</DisplayText></TableCell>
                                            <TableCell className={classes.cell} ><DisplayText variant='body2'>{fCurrency(balance?? 0)}</DisplayText></TableCell>
                                            <TableCell className={classes.cell} >
                                                <div className={classes.status_positive} style={{background: balance>=0 ? green[400]: red[400]}}>
                                                    <DisplayText variant='caption'>{balance >= 0 ? 'Positivo' : 'Negativo'}</DisplayText>
                                                </div>
                                            </TableCell>
                                            <TableCell className={classes.cell} ><DisplayText variant='body2'>{onGetTimeDuration(item?.monthly_quoted_time ?? 0)}</DisplayText></TableCell>
                                            <TableCell className={classes.cell} ><DisplayText variant='body2'>{onGetTimeDuration(item?.worked_hours ?? 0)}</DisplayText></TableCell>
                                            <TableCell className={classes.cell} ><DisplayText variant='body2'>{onGetTimeDuration(item?.average_duration ?? 0)}</DisplayText></TableCell>
                                            <TableCell className={classes.cell}><StarData data={parseFloat(average_complexity ?? 0).toFixed(1)}/></TableCell>
                                            <TableCell className={classes.cell}><StarData data={parseFloat(average_client_satisfaction ?? 0).toFixed(1)}/></TableCell>
                                            <TableCell className={classes.cell} ><DisplayText variant='body2'>{item?.ctrl_accounting_operations ?? '-'}</DisplayText></TableCell>
                                            <TableCell className={classes.cell} ><DisplayText variant='body2'>{item?.ctrl_billing_notes ?? '-'}</DisplayText></TableCell>
                                            <TableCell className={classes.cell} ><DisplayText variant='body2'>{item?.employee_number ?? '-'}</DisplayText></TableCell>

                                        </TableRow>
                                    )
                                })}
                                
                            </TableBody>
                        </Table>
                    </div>
                    
                ): null}
            </Paper>
            {data && data.length ? <div className={classes.actions}>
                <BCActionBar total={total} tableFilter={tableFilter} onUpdateTableFilter={onUpdateTableFilter}/>
            </div> : null}
            {data.length === 0 && !loading ? (
                <div className={classes.nodata}>
                    <Grid container justify='center' alignItems='center' direction='column'>
                        <Grid item>
                            <img src="/assets/images/empty.png" alt='' width={180}/>
                        </Grid>
                        <Grid item>
                            <DisplayText>No se encontraron registros</DisplayText>
                        </Grid>
                    </Grid>
                </div>
                
            ) : null}
            
        </div>
    )
}

const useStyles = makeStyles(theme => ({
    root:{},
    paper:{
        borderRadius:8,
        boxShadow:'0 10px 24px 0 rgba(82, 91, 115, .12)',
    },
    cell:{
        borderColor:grey[200],
    },
    row:{
        '&:hover':{
            cursor:'pointer',
            background:grey[100]
        }
    },
    cellHeader:{
        borderColor:grey[300],
        paddingTop:16,
        paddingBottom:16
    },
    button:{
        background:theme.palette.primary.main,
        padding:'6px 12px',
        borderRadius:32,
        color:'white',
        '&:hover':{
            background:theme.palette.primary.dark,
        }
    },
    toolbar:{
        marginBottom:16
    },
    actions:{
        marginTop:16
    },
    table:{
        minWidth:1100
    },
    tableWrapper:{
        overflowX: 'auto',
    },
    nodata:{
        marginTop:40
    },
    status_positive:{
        background:green[400],
        color:'white',
        borderRadius:32,
        textAlign:'center',
        padding:'2px 8px'
    },
    download_btn:{
        background: theme.palette.primary.main,
        color:'white',
        '&:hover':{
            background: theme.palette.primary.dark
        }
    }
}))

export default BusinessCostTable