import React, { useEffect, useState } from 'react';
import { AppBar, Box, Checkbox, CircularProgress, Grid, makeStyles, Tab, Table, TableBody, TableCell, TableHead, TableRow, Tabs, Typography } from '@material-ui/core';
import ActionModalBar from '../../../../../../../components_v1/Actions/ActionModalBar';
import SimpleModal from '../../../../../../../components_v1/Structure/Modals/SimpleModal';
import DisplayText from '../../../../../../../components_v1/Texts/DisplayText';
import {contentData} from './permissionsdata/content'
import { grey } from '@material-ui/core/colors';
import cx from 'classnames'


const styles = makeStyles((theme) => ({
    root: {
      flexGrow: 1,
      backgroundColor: theme.palette.background.paper,
      maxHeight:'600px',
      overflowX:'auto'
  },
    table:{
      overflowX:'auto'
    },
    cellheader:{
      fontSize:12,
      padding:'0px 8px'
    },
    cell:{
      border:0,
      fontSize:14,
      color:grey[700],
      fontWeight:'500'
    },
    permission_even:{
      backgroundColor: 'black',
    },
    permission_odd: {
      backgroundColor: 'white'
    },
    progress:{
        color: theme.palette.primary.main,
    },
  }))
  
const TabRow = ({kkey, module, module_id, permission_id, onChangeItem}) => {
console.log(permission_id)
    return(
        <TableRow key={kkey.toString()}>
        <TableCell style={styles.cell}>{module}</TableCell>
        <TableCell style={styles.cell} padding='checkbox' align='center'>
            <Checkbox 
            checked={permission_id===1} 
            onChange={(event,status) => onChangeItem(module_id,1,permission_id)}
            />
        </TableCell>
        <TableCell style={styles.cell} padding='checkbox' align='center'>
            <Checkbox 
            checked={permission_id===2} 
            onChange={(event,status) => onChangeItem(module_id,2,permission_id)}
            />
        </TableCell>
        <TableCell style={styles.cell} padding='checkbox' align='center'>
            <Checkbox 
            checked={permission_id===3} 
            onChange={(event,status) => onChangeItem(module_id,3,permission_id)}
            />
        </TableCell>
        <TableCell style={styles.cell} padding='checkbox' align='center'>
            <Checkbox 
            checked={permission_id===4} 
            onChange={(event,status) => onChangeItem(module_id,4,permission_id)}
            />
        </TableCell>
        <TableCell style={styles.cell} padding='checkbox' align='center'>
            <Checkbox 
            checked={permission_id===5} 
            onChange={(event,status) => onChangeItem(module_id,5,permission_id)}
            />
        </TableCell>
        
        </TableRow>
)
}
  
const TabPanel = ({children, value, index, ...other}) => {
  
    return (
      <Typography
        component="div"
        role="tabpanel"
        hidden={value !== index}
        id={`scrollable-auto-tabpanel-${index}`}
        aria-labelledby={`scrollable-auto-tab-${index}`}
        {...other}
      >
        {value === index && <Box p={3}>{children}</Box>}
      </Typography>
    );
  
}

const PermissionsModal = props => {

    const classes = styles()
    const { open, sending, onClose, catalogs, origin_data, history, language } = props
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState(null)
    const [permissions, setPermissions] = useState([])
    const [value, setValue] = useState(0)
    const content = contentData[language]

    useEffect(() => {
        if (!open) {
            setLoading(true)
            setPermissions([...origin_data])
            setError('')
            setLoading(false)
        }
    }, [open, origin_data])

    const onChange = (module_id, permission_id) => {
        setLoading(true)
        let _permissions = [...permissions]
        let key = _permissions.findIndex(e => e.permission_module_id === module_id)
        _permissions[key].permission_type_id = permission_id
        setPermissions([..._permissions])
        props.onSubmit(_permissions[key])
        setLoading(false)
    }

    const onSubmit = async () => {
        setLoading(true)
        props.onSubmit([...permissions])        
        setLoading(false)
    }

    const onUpdatePermissions = (localPermissions, serverPermissions) => {
        let result = JSON.parse(JSON.stringify(localPermissions))
        if(serverPermissions.length){
          Object.keys(localPermissions).forEach(item => {
            localPermissions[item].forEach((el,key) => {
              const _perm = serverPermissions.find(val => val.permission_module_id === el.id_permission_module)
              if(_perm) result[item][key] = {...result[item][key], id_permission_type:_perm.permission_type_id}
            })
          })
        }
        return result
    }

    const header = (
        <AppBar position="sticky" color="primary">
            <Tabs variant="fullWidth" value={value} onChange={(event, value) => setValue(value)}>
                {content.header.map((item, key) => {
                    return <Tab value={key} label={item}/>
                })}
            </Tabs>
        </AppBar>
    )

    const permissionsFormat = onUpdatePermissions(content.permissions, permissions)
    const pKeys = Object.keys(permissionsFormat)

    return ( 
        <SimpleModal open={open} onClose={onClose} maxWidth='md'>
            <div>
                <DisplayText variant='h6' color='primary' style={{ fontWeight: 500 }}>{content.title}</DisplayText>
                <div className={classes.form}>
                    <Grid container spacing={3}>
                        <Grid item xs={12}>
                            {header}
                            {content.header.map((item,key) => {
                            return (
                                <TabPanel value={value} index={key}>
                                    <Table>
                                        <TableHead>
                                            <TableRow>
                                                <TableCell className={classes.cellheader}/>
                                                {content.table.header.map(item =>{
                                                    return (
                                                        <TableCell 
                                                        padding='checkbox'
                                                        className={classes.cellheader} 
                                                        align='center'
                                                        key={key.toString()}
                                                        >
                                                        {item}
                                                        </TableCell>
                                                    )
                                                })}
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                        {permissionsFormat[pKeys[key]].map((item,key) =>{
                                            return <TabRow
                                                    className={cx({
                                                    [classes.permission_even]: key%2 == 0,
                                                    [classes.permission_odd]: key%2 != 0
                                                    })}
                                                    kkey={key}
                                                    module={item.name}
                                                    module_id={item.id_permission_module}
                                                    permission_id={item.id_permission_type}
                                                    onChangeItem={onChange}
                                                    onSubmit={onSubmit}
                                                />
                                        })}
                                        </TableBody>
                                    </Table>
                                </TabPanel>
                            )})}
                        </Grid>
                        <Grid item xs></Grid>
                        <Grid item xs={1}>
                            {sending ? (<CircularProgress size={24} className={classes.progress}/>) : null}
                        </Grid>
                    </Grid>
                </div>
            </div>
        </SimpleModal>
     );
}
 
export default PermissionsModal;