export const contentData = {
  spanish:{
    layout:{
      title:'Editar usuario',
      navigation:[
        {name:'Usuarios',path:'/users'},
        {name:'Editar',path:'/users/edit'},
        
      ],
      menu2:[
        {id:0, label:'Mis datos'},
        {id:1, label:'Horario'},
      ],
    },
  }
}
