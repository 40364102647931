export const contentTask = {
	spanish: {
		title: 'Información de tarea',
		topInfo: [
			{
				title: 'Negocio',
				id: 'business',
				imgID: 'business_image',
			},
			{
				title: 'Creador',
				id: 'creator',
				imgID: 'creator_image',
			},
			{
				title: 'Fecha de creación',
				id: 'creation_date',
			},
			{
				title: 'Fecha de término',
				id: 'finish_date',
			},
		],
		taskInfo: {
			title: 'Tarea',
			priority: 'Prioridad',
			status: 'Estatus',
			type: 'Tipo de tarea',
			document: 'Documento',
			expiration_date: 'Fecha de entrega',
		},
		taskUsers: {
			title: 'Usuarios',
			responsible: 'Responsable',
			users: 'usuarios',
			header: [
				{ id: 1, label: '' },
				{ id: 2, label: 'Responsable' },
				{ id: 3, label: '' },
			],
		},
		editTaskModal: {
			title: 'Editar tarea',
			button: 'Guardar',
		},
		editSubtaskModal: {
			title: 'Editar subtarea',
			button: 'Guardar',
		},
		uploadDocumentModal:{
			error: 'Debe adjuntar un documento',
			title: 'Subir documento',
			label: 'Adjuntar documento',
			button: 'Subir'
		},
		editTaskForm: {
			expiration_date: {
				label: 'Fecha límite',
				helper: 'Fecha incorrecta',
			},
			title: {
				label: 'Título',
				placeholder: 'Título de la tarea',
				helper: 'Este campo es requerido',
			},
			description: {
				label: 'Descripción',
				placeholder: 'Descripción de la tarea',
				helper: 'Este campo es requerido',
			},
			task_status_id: {
				label: 'Estaus',
				placeholder: 'Seleccione una opción',
				helper: 'Este campo es requerido',
			},
			task_priority_id: {
				label: 'Prioridad',
				placeholder: 'Seleccione una opción',
				helper: 'Este campo es requerido',
			},
		},
		editSubtaskForm: {
			expiration_date: {
				label: 'Fecha límite',
				helper: 'Fecha incorrecta',
			},
			title: {
				label: 'Título',
				placeholder: 'Título de la tarea',
				helper: 'Este campo es requerido',
			},
			description: {
				label: 'Descripción',
				placeholder: 'Descripción de la tarea',
				helper: 'Este campo es requerido',
			},
			task_status_id: {
				label: 'Estaus',
				placeholder: 'Seleccione una opción',
				helper: 'Este campo es requerido',
			},
			task_priority_id: {
				label: 'Prioridad',
				placeholder: 'Seleccione una opción',
				helper: 'Este campo es requerido',
			},
			user_id: {
				label: 'Responsable',
				placeholder: 'Seleccione una opción',
				helper: 'Este campo es requerido',
			},
		},
		addUserForm: {
			user_id: {
				label: 'Usuario',
				placeholder: 'Selecciona una opción',
				helper: 'Selecciona una opción válida',
			},
			main: {
				label: 'Responsable:',
				helper: 'Este campo es requerido',
			},
		},
		addUserModal: {
			title: 'Agregar usuario',
			button: 'Agregar',
		},
		warning_delete_user: 'Se eliminará al usuario de la tarea',
		subtasks:{
			title: 'Subtareas',
			notask: 'No existen subtareas',
			header: [
				{ id: 1, label: 'Título' },
				{ id: 2, label: 'Responsable' },
				{ id: 3, label: 'Fecha de entrega' },
				{ id: 4, label: 'Fecha de término' },
				{ id: 5, label: 'Estatus' },
				{ id: 6, label: 'Documento' },
				{ id: 7, label: '' },
			],
		},
		addSubtaskModal: {
			title: 'Agregar subtarea',
			button: 'Agregar',
		},
		addSubtaskForm:{
			expiration_date: {
				label: 'Fecha límite',
				helper: 'Fecha incorrecta',
			},
			title: {
				label: 'Título',
				placeholder: 'Título de la tarea',
				helper: 'Este campo es requerido',
			},
			description: {
				label: 'Descripción',
				placeholder: 'Descripción de la tarea',
				helper: 'Este campo es requerido',
			},
			task_type_id: {
				label: 'Tipo',
				placeholder: 'Seleccione una opción',
				helper: 'Este campo es requerido',
			},
			task_priority_id: {
				label: 'Prioridad',
				placeholder: 'Seleccione una opción',
				helper: 'Este campo es requerido',
			},
			main_task_user_id: {
				label: 'Responsable',
				placeholder: 'Seleccione una opción',
				helper: 'Este campo es requerido',
			},
		},
		comments:{
			title: 'Comentarios',
			nocomment: 'No hay comentarios',
			card:{
				creation_date: 'Fecha de creación',
				creator: 'Creador'
			}
		},
		addCommentModal:{
			title: 'Agregar comentario',
			button: 'Agregar',
		},
		editCommentModal:{
			title: 'Editar comentario',
			button: 'Guardar',
		},
		addCommentForm:{
			title: {
				label: 'Título',
				placeholder: 'Título del comentario'
			},
			comment: {
				label: 'Comentario',
				placeholder: 'Descripción del comentario'
			},
		}
	},
};
