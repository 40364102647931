import { catalogs } from "../../../texts/esp/catalogs";

export const content = {
    header:[
        {id:0,label:'',hidden:false, fixed:true, width:26, position:0, padding:'checkbox'},
        {id:1,key:'fixed_business_name',label:'Razón Social',hidden:false, width:300, fixed:true, add:{icon:'add'}, position:34},
        {id:2,key:'rfc',label:'RFC',hidden:false},
        {id:3,key:'client_classification',label:'Clasificación',hidden:false},
        {id:4,key:'client_status_id',label:'Estatus',hidden:false},
        {id:5,key:'fixed_client_responsible',label:'Asesor',hidden:false},
        //Fixed other data
        {id:6,key:'idse_user_employer_enrollment',label:'Registro Patronal',hidden:false, add:{icon:'add'}},
        {id:7,key:'idse_user_number_employees',label:'Número de trabajadores',hidden:false},
        {id:8,key:'idse_user_user',label:'Usuario',hidden:false},
        {id:9,key:'idse_user_password',label:'Clave',hidden:false},
        {id:10,label:'SIPARE',hidden:false},
        {id:11,key:'idse_user_expiration_date',label:'Fecha Pago Impuestos',hidden:false},
        {id:12,key:'imss_responsible',label:'Responsable IMSS',hidden:false},

        /// New data
        {id:20,label:'Línea de Captura IMSS',hidden:false},
        {id:21,label:'Respuesta Cliente',hidden:false},
        {id:22,key:'payment_date',label:'Fecha de Pago',hidden:false},
        {id:23,key:'amount',label:'Monto',hidden:false},
        {id:24,label:'Acuse de Pago',hidden:false},
        {id:27,key:'responsible',label:'Responsable',hidden:false},
        {id:28,key:'notes',label:'Notas',hidden:false},
        {id:29,key:'completition_date',label:'Fecha de Completado',hidden:false},
        {id:29,label:'Completado',hidden:false},

    ],
    dataConfig:[
        {id:'fixed_business_name',type:'text',fixed:true, editBtn:true, position:34},
        {id:'rfc',type:'text'},
        {id:'client_classification', type:'text'},
        {id:'client_status_id',type:'status', options: [...catalogs.client_statuses] },
        {id:'fixed_client_responsible',type:'text'},
        ///////// second fixed data
        {id:'idse_user_employer_enrollment',type:'text'},
        {id:'idse_user_number_employees',type:'text'},
        {id:'idse_user_user',type:'text'},
        {id:'idse_user_password',type:'text'},
        {id:'idse_user_sipare',type:'document', readOnly:true},
        {id:'idse_user_expiration_date',type:'text'},
        {id:'imss_responsible',type:'text'},
        // Real data
        {id:'imss_capture_line',type:'document'},
        {id:'client_response', type:'document'},
        {id:'payment_date',type:'date', variant:'date',editable:true},
        {id:'amount', type:'cash', editable:true},
        {id:'payment_proof', type:'document'},
        {id:'responsible', formId:'responsible_id', type:'text',editable:true},
        {id:'notes',type:'text',editable:true},
        {id:'completition_date',type:'date', variant:'date',editable:true},
        {id:'completed',type:'checkbox',editable:true},
    ]
}