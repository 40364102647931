import React, {Component} from 'react'
import { Redirect } from 'react-router'
import {contentData} from './content'
import BasicView from '../../../../components/Layouts/BasicView/BasicView'
import ServiceModule from './ServiceModule/ServiceModule';
import Logs from '../Modules/Logs/Logs';

class ExternalInvestmentRegistration extends Component {

  state = {
    pageSelected:0
  }

  onReturn = () => {
    this.props.history.goBack()
  }

  onChangeView = (id) => {
    this.setState({pageSelected:id});
  }

  render(){

    const {history, language, catalogs, businessID, 
          permission, permissionsType} = this.props
    const {pageSelected} = this.state
    const content = contentData[language]

    let contentView = null

    switch(pageSelected){
      case 0:
        contentView = (
          <ServiceModule 
            history={history}
            language={language}
            catalogs={catalogs}
            businessID={businessID}
            permission={permission}
            permissionsType={permissionsType}
          />
        )
        break
      case 1:
        contentView = (
            <Logs
              history={history}
              language={language}
              catalogs={catalogs}
              businessID={businessID}
              service={'ctrlexternalinvestmentregistration'}
              ctrlTableIdLabel={'id_ctrl_external_investment_registration'}
            />
        )
        break
      default:
        break
    }

    return(
      <BasicView
        history={history}
        content={content.layout}
        onChange={this.onChangeView}
        selected={pageSelected}
        returnBtn={true}
        onReturn={this.onReturn}
      >
        {permission === 1 ? (
          <Redirect to='/profile'/>
        ) : null}
        <div>
          {contentView}
        </div>

      </BasicView>
    )
  }
}

export default ExternalInvestmentRegistration
