import {mainServer} from '../../../../variables/config'
import FileSaver from 'file-saver';
import axios from 'axios'

export const request_user_info = async (id) => {
  const logData = JSON.parse(localStorage.getItem('data'))
  mainServer.instance.defaults.headers.common['Authorization'] = logData.token
  let response;
  response = await mainServer.instance.get("/user/employee/" + id);
  return response.data.data.employee
}

export const request_employees = async () => {
  const logData = JSON.parse(localStorage.getItem('data'))
  mainServer.instance.defaults.headers.common['Authorization'] = logData.token
  let response;
    response = await mainServer.instance.get("/user/employee/list");
    return response.data.data.employees
}

export const request_clients = async () => {
  const logData = JSON.parse(localStorage.getItem('data'))
  mainServer.instance.defaults.headers.common['Authorization'] = logData.token
  let response;
  response = await mainServer.instance.get("/client/list");
  return response.data.data.clients
}

export const request_clientuser = async (id) => {
  const logData = JSON.parse(localStorage.getItem('data'))
  mainServer.instance.defaults.headers.common['Authorization'] = logData.token
  let response;
  response = await mainServer.instance.get("/clientuser/"+id);
  return response.data.data.user;
}

export const request_businesses = async (id) => {
  const logData = JSON.parse(localStorage.getItem('data'));
  mainServer.instance.defaults.headers.common['Authorization'] = logData.token;
  let response;
  response = await mainServer.instance.get("/business/listbyclient/"+id);
  return response.data.data.businesses;
}

export const request_update_client = async(id,data) => {
  const logData = JSON.parse(localStorage.getItem('data'));
  mainServer.instance.defaults.headers.common['Authorization'] = logData.token;
  await mainServer.instance.patch("/client/"+id, data);
  let response
  response = await mainServer.instance.get("/client/"+id)
  return response.data.data.client
}


export const request_update_clientuser = async(id,data) => {
  const logData = JSON.parse(localStorage.getItem('data'));
  mainServer.instance.defaults.headers.common['Authorization'] = logData.token;
  await mainServer.instance.patch("/clientuser/"+id, data);
  let response;
  response = await mainServer.instance.get("/clientuser/"+id)
  return response.data.data.user
}




export const request_uploadFile = async(id,path,file,ext) => {
  const logData = JSON.parse(localStorage.getItem('data'));
  mainServer.instance.defaults.headers.common['Authorization'] = logData.token;

  let url = '/client/documents/'+path+'/'+id
  let response = await mainServer.instance.post(url, ext)

  const options = {headers: {'Content-Type': file.type}}
  delete axios.defaults.headers.common["Authorization"]
  response = await axios.put(response.data.data.path, file, options);
  mainServer.instance.defaults.headers.common['Authorization'] = logData.token;

  response = await mainServer.instance.get("/client/pre/"+id)
  return response.data.data.pre_client
}

export const request_downloadFile = async(id,path) => {
  const logData = JSON.parse(localStorage.getItem('data'));
  mainServer.instance.defaults.headers.common['Authorization'] = logData.token;
  let response
  let url = '/client/documents/'+path+'/'+id
  response = await mainServer.instance.get(url)
  delete axios.defaults.headers.common["Authorization"]
  response = await axios.get(response.data.data.path, {responseType: 'blob'});
  FileSaver.saveAs(response.data, path);
}

export const request_deleteFile = async(id,path) => {
  const logData = JSON.parse(localStorage.getItem('data'));
  mainServer.instance.defaults.headers.common['Authorization'] = logData.token;
  let response
  let url = '/client/documents/'+path+'/'+id
  await mainServer.instance.delete(url)
  response = await mainServer.instance.get("/client/pre/"+id)
  return response.data.data.pre_client
} 

export const request_add_business = async (id,data) => {
  const logData = JSON.parse(localStorage.getItem('data'));
  mainServer.instance.defaults.headers.common['Authorization'] = logData.token;
  await mainServer.instance.post("/clientuser/permission/"+id, data);
  let response;
  response = await mainServer.instance.get("/clientuser/"+id);
  return response.data.data.user;
}

export const request_delete_business = async (id,data) => {
  const logData = JSON.parse(localStorage.getItem('data'));
  mainServer.instance.defaults.headers.common['Authorization'] = logData.token;
  await mainServer.instance.delete("/clientuser/permission/"+id, {params:data});
  let response;
  response = await mainServer.instance.get("/clientuser/"+id);
  return response.data.data.user;
}