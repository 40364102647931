export const formData = {
  sectionA:{
    first_name:{
      label:'',
      data:'',
    },
    last_name:{
      label:'',
      data:'',
    },
    username:{
      label:'',
      data:'',
    },
    client_user_level:{
      label:'',
      data:'',
    },
    timezone:{
      label:'',
      data:'',
    },
    gender:{
      label:'',
      data:'',
    },    
    country:{
      label:'',
      data:'',
      icon:'my_location'
    },
    area:{
      label:'',
      data:'',
      icon:'business'
    },  
    position:{
      label:'',
      data:'',
    },
    language:{
      label:'',
      data:'',
      icon:'language'
    },
    client_language:{
      label:'',
      data:'',
      icon:'language'
    },
  },
  sectionB:{
    work_email:{
      label:'',
      data:'',
      icon:'mail'
    },
    alternative_email:{
      label:'',
      data:'',
      icon:'mail'
    },
    personal_email:{
      label:'',
      data:'',
      icon:'mail'
    },
    phone_number:{
      label:'',
      data:'',
      icon:'phone'
    },
    extension:{
      label:'',
      data:'',
    },
    cellphone:{
      label:'',
      data:'',
      icon:'phone'
    },
    
  },
  sectionC:{
    pre_office_phone_number:{
      label:'',
      data:'',
      icon:'phone'
    },
    pre_cellphone:{
      label:'',
      data:'',
      icon:'phone'
    },
    pre_phone_number:{
      label:'',
      data:'',
      icon:'phone'
    },
    pre_work_email:{
      label:'',
      data:'',
      icon:'mail'
    },
    pre_alternative_email:{
      label:'',
      data:'',
      icon:'mail'
    },
    pre_personal_email:{
      label:'',
      data:'',
      icon:'mail'
    },
  },
  sectionD:{
    who_charges:{
      label:'',
      data:'',
    },
    contract_duration:{
      label:'',
      data:'',
    },
    contract_periodicity:{
      label:'',
      data:'',
    },
    anual_increment:{
      label:'',
      data:'',
      icon:'trending_up'
    },
    outsourcing_comission:{
      label:'',
      data:'',
    },
    contract_value:{
      label:'',
      data:'',
      icon:'attach_money'
    },
    iva:{
      label:'',
      data:'',
      icon:'attach_money'
    },
    total_value:{
      label:'',
      data:'',
      icon:'attach_money'
    },
    contract_special_conditions:{
      label:'',
      data:'',
    },
  },
}