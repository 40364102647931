import React, { useState, useEffect } from 'react';
import { Grid, makeStyles } from '@material-ui/core';
import ActionModalBar from '../../../../../components_v1/Actions/ActionModalBar';
import InputSelect from '../../../../../components_v1/Forms/InputSelect';
import InputText from '../../../../../components_v1/Forms/InputText';
import SimpleModal from '../../../../../components_v1/Structure/Modals/SimpleModal';
import DisplayText from '../../../../../components_v1/Texts/DisplayText';
import { isFormValid, onSetErrorsToForm, onInitForm, onGetSelectedFormData, onGetEmployeesCatalogs } from '../../../../../shared/utility';
import { catalogs } from '../../../../../texts/esp/catalogs';
import { request_update_business, request_update_business_documents } from '../requests';
import InputCheckbox from '../../../../../components_v1/Forms/InputCheckbox';


const useStyles = makeStyles(theme => ({
  form: {
    paddingTop: 32,
    paddingBottom: 32
  }
}))

const EditBusinessModal = (props) => {

  const classes = useStyles()
  const { id, open, onClose, init_data, client_businesses, employees,
    legal_representative, onUpdateCompleted } = props
  const [form, setForm] = useState(JSON.parse(JSON.stringify(formData)))
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState('')

  useEffect(() => {

    let _form = JSON.parse(JSON.stringify(formData))
    if (init_data) {
      _form = onInitForm(_form, init_data)
      if (legal_representative) {
        _form.legal_representative_id.value = legal_representative.id
        _form.legal_representative_id.isValid = true
        _form.legal_representative_id.isVisited = true
      }
      _form.monthly_quoted_time.value = init_data.monthly_quoted_time && init_data.monthly_quoted_time > 0 ? init_data.monthly_quoted_time / 60 : 0
      _form.advisor_id.options = onGetEmployeesCatalogs(employees)
    }
    setForm(_form)
    if (!open) { setError(''); setLoading(false); }

  }, [open])

  const onChange = (data) => {
    let temp = { ...form }
    const id = data.config.id
    temp[id] = { ...data }
    setForm(temp)
  }

  const onSubmit = async () => {

    const errors = isFormValid(form)
    /// Check if the form is valid
    if (errors && errors.length) {
      const new_form = onSetErrorsToForm(form, errors)
      setForm(new_form)
      setError('Llene los campos faltantes')
      return
    }

    //Extract data
    let data2send = onGetSelectedFormData(form, init_data)
    let business_documents_data = { legal_representative_id: data2send.legal_representative_id, rfc: data2send.rfc }
    //Delete nulls
    Object.keys(data2send).forEach(key => { if (data2send[key] === null || data2send[key] === undefined) delete data2send[key] })
    Object.keys(business_documents_data).forEach(key => { if (business_documents_data[key] === null || business_documents_data[key] === undefined) delete business_documents_data[key] })
    delete data2send.legal_representative_id

    data2send.monthly_quoted_time = data2send.monthly_quoted_time && data2send.monthly_quoted_time > 0 ? data2send.monthly_quoted_time * 60 : 0

    data2send.restricted = data2send.restricted ? 1 : 0

    //Send data
    setLoading(true)
    try {
      await request_update_business(id, data2send)
      await request_update_business_documents(id, business_documents_data)
      onUpdateCompleted()
      //onClose()
      //history.push(`/timereports/${timereport_id}`)

    } catch (error) {
      console.log(error)
      setError('Hubo un error')
      setLoading(false)
    }

  }

  // Update catalogs in real time 

  let legal_representative_form = { ...form.legal_representative_id }
  legal_representative_form.options = [...client_businesses]

  return (
    <SimpleModal open={open} onClose={onClose} maxWidth='md'>
      <div>
        <DisplayText variant='h6' color='primary' style={{ fontWeight: 500 }}>Información del negocio</DisplayText>
        <div className={classes.form}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Grid container justify='flex-end'><Grid item><InputCheckbox data={form.restricted} onChange={onChange} /></Grid></Grid>
            </Grid>
            <Grid item xs={12} md={6}>
              <InputText data={form.name} onChange={onChange} />
            </Grid>
            <Grid item xs={12} md={6}>
              <InputText data={form.rfc} onChange={onChange} />
            </Grid>
            <Grid item xs={12} md={6}>
              <InputSelect data={form.fiscal_regime_id} onChange={onChange} />
            </Grid>
            <Grid item xs={12} md={6}>
              <InputSelect data={form.advisor_id} onChange={onChange} />
            </Grid>
            <Grid item xs={12}>
              <InputText data={form.monthly_quoted_time} onChange={onChange} />
            </Grid>
            <Grid item xs={12}>
              <InputSelect data={legal_representative_form} onChange={onChange} />
            </Grid>
            <Grid item xs={12}>
              <InputSelect data={form.activity_id} onChange={onChange} />
            </Grid>
            <Grid item xs={12} md={6}>
              <InputSelect data={form.subactivity_id} onChange={onChange} />
            </Grid>
            <Grid item xs={12} md={6}>
              <InputText data={form.sub_activity} onChange={onChange} />
            </Grid>
            <Grid item xs={12}>
              <InputText data={form.economic_activity_sector} onChange={onChange} />
            </Grid>
            <Grid item xs={12}>
              <ActionModalBar btnLabel='Guardar' loading={loading} error={error} onSubmit={onSubmit} />
            </Grid>
          </Grid>
        </div>
      </div>
    </SimpleModal>
  );
}

export default EditBusinessModal;

const formData = {
  name: {
    value: null,
    error: false,
    isVisited: false,
    isRequired: true,
    isValid: false,
    config: {
      id: 'name', type: 'text', fullWidth: true,
      label: 'Razón social',
      placeholder: 'Ingrese un texto...'
    },
    rules: {
      type: 'distance', min: 1, max: 100
    }
  },
  rfc: {
    value: null,
    error: false,
    isVisited: false,
    isRequired: true,
    isValid: false,
    config: {
      id: 'rfc', type: 'text', fullWidth: true,
      label: 'R.F.C',
      placeholder: 'Ingrese un texto...'
    },
    rules: {
      type: 'rfc'
    }
  },
  restricted: {
    value: false,
    error: false,
    isVisited: false,
    isRequired: false,
    isValid: false,
    config: { id: 'restricted', type: 'checkbox', label: '¿Está restringido?' },
  },
  fiscal_regime_id: {
    value: null,
    error: false,
    isVisited: false,
    isRequired: true,
    isValid: false,
    options: [...catalogs.business_fiscal_regimes],
    config: {
      id: 'fiscal_regime_id', type: 'simple_select', fullWidth: true,
      label: 'Régimen Fiscal',
      placeholder: 'Seleccione una opción'
    },
    rules: {
      type: 'none',
    }
  },
  advisor_id: {
    value: null,
    error: false,
    isVisited: false,
    isRequired: true,
    isValid: false,
    options: [],
    config: {
      id: 'advisor_id', type: 'simple_select', fullWidth: true,
      label: 'Asesor',
      placeholder: 'Seleccione un asesor'
    },
    rules: {
      type: 'none',
    }
  },
  monthly_quoted_time: {
    value: null,
    error: false,
    isVisited: false,
    isRequired: true,
    isValid: false,
    config: {
      id: 'monthly_quoted_time', type: 'number', fullWidth: true,
      label: 'Horas cotizadas',
      placeholder: 'Ingrese un número positivo'
    },
    rules: {
      type: 'none'
    }
  },
  legal_representative_id: {
    value: null,
    error: false,
    isVisited: false,
    isRequired: false,
    isValid: false,
    options: [],
    config: {
      id: 'legal_representative_id', type: 'simple_select', fullWidth: true,
      label: 'Representante legal',
      placeholder: 'Seleccione una opción'
    },
    rules: {
      type: 'none',
    }
  },
  activity_id: {
    value: null,
    error: false,
    isVisited: false,
    isRequired: false,
    isValid: false,
    options: [...catalogs.activities],
    config: {
      id: 'activity_id', type: 'simple_select', fullWidth: true,
      label: 'Actividades',
      placeholder: 'Seleccione una opción'
    },
    rules: {
      type: 'none',
    }
  },
  subactivity_id: {
    value: null,
    error: false,
    isVisited: false,
    isRequired: false,
    isValid: false,
    options: [...catalogs.sub_activities],
    config: {
      id: 'subactivity_id', type: 'simple_select', fullWidth: true,
      label: 'Subactividad',
      placeholder: 'Seleccione una opción'
    },
    rules: {
      type: 'none',
    }
  },
  sub_activity: {
    value: null,
    error: false,
    isVisited: false,
    isRequired: false,
    isValid: false,
    config: {
      id: 'sub_activity', type: 'text', fullWidth: true,
      label: 'Detalles de subactividad',
      placeholder: 'Ingrese un texto...'
    },
    rules: {
      type: 'distance',
      min: 1,
      max: 100
    }
  },
  economic_activity_sector: {
    value: null,
    error: false,
    isVisited: false,
    isRequired: false,
    isValid: false,
    config: {
      id: 'economic_activity_sector', type: 'text', fullWidth: true,
      label: 'Detalles de Sector de actividad económica',
      placeholder: 'Ingrese un texto...'
    },
    rules: {
      type: 'distance',
      min: 1,
      max: 100
    }
  },
}