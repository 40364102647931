export const contentData = {
  spanish:{
    layout:{
      title:'Declaraciones mensuales',
      navigation:[
        {name:'Clientes',path:'/clients'},
        {name:'Detalles',path:'/clients/details/:id'},
        {name:'Negocios',path:'/clients/details/:id/businesses'},
        {name:'Detalles',path:'/clients/details/:id/businesses/:idbusiness'},
        {name:'Servicio',path:'/clients/details/:id/businesses/:idbusiness/service/:idservice'},
      ],
      menu:[
        {id:0, label:'General'},
        {id:1, label:'Sellos de Facturación'},
        {id:2, label:'Obligaciones Fiscales'},
        {id:3, label:'Representante Legal'},
        {id:4, label:'Logs'}
      ]
    },
    modals:{
      warning_delete_file:{
        title:'¿Estás seguro?',
        message:'¿Realmente quieres eliminar este registro? Este proceso no puede ser revertido',
        cancelButton:'Cancelar',
        deleteButton:'Eliminar'
      },
      edit_service:{
        title:'Editar servicio',
        button:'Guardar',
        cancelButton:'Cancelar'
      }
    },
    basicform:{
      title:'Información Básica',
      button:'Editar',
      nodata:'Sin Registro',
      data:{
        rfc:{
          label:'',
          data:'',
        },
        sat_password:{
          label:'',
          data:'',
        },
        fiscal_situation:{
          label:'',
          data:'',
        },
        tax_limit_date:{
          label:'',
          data:'',
        },
        mail_tributary_mailbox:{
          label:'',
          data:'',
        },
        tributary_mailbox_password:{
          label:'',
          data:'',
        },
        tributary_mailbox_status:{
          label:'',
          data:'',
        },
        fiel_expiration_date:{
          label:'',
          data:'',
        },
        importer_list:{
          label:'',
          data:'',
        },
        fiscal_address:{
          label:'',
          data:''
        },
        ciec_key:{
          label:'',
          data:''
        },
        accounting_method:{
          label:'',
          data:''
        },
        payday_limit:{
          label:'',
          data:''
        }
      },
      content:{
        rfc:{
          label:'RFC',
        },
        sat_password:{
          label:'Contraseña SAT',
        },
        
        fiscal_situation:{
          label:'Situación Fiscal',
        },
        tax_limit_date:{
          label:'Fecha Límite de Pago Impuestos',
        },
        mail_tributary_mailbox:{
          label:'Mail Buzón Tributario',
        },
        tributary_mailbox_password:{
          label:'Contraseña Buzón Tributario',
        },
        tributary_mailbox_status:{
          label:'Estatus Buzón Tributario',
        },
        fiel_expiration_date:{
          label:'Fecha de vencimiento de certificado',
        },
        importer_list:{
          label:'Padrón de importadores',
        },
        fiscal_address:{
          label: 'Dirección'
        },
        ciec_key:{
          label:'Clave CIEC'
        },
        accounting_method:{
          label:'Método de pago de impuestos'
        },
        payday_limit:{
          label:'Límite envío Impuestos'
        }
      },
      documents:{
        title:'Documentos',
        header:{title:'Archivos', actions:'Acciones'},
        files:[
          {key:'tax_sheet',name:'Hoja de Impuestos',path:'taxsheet', status:false, businessDocument:true},
          {key:'curp', name:'CURP', path:'curp', status:false, businessDocument:true}
        ]
      }
    }
  }
}