import React from 'react';
import { Grid, makeStyles, Typography } from '@material-ui/core';
import SimpleCard from '../../../../components_v1/Structure/Cards/SimpleCard';
import { imgServerS3 } from '../../../../variables/config';
import { isValidDocument, onGetDateFormat } from '../../../../shared/utility';
import { nodata } from '../../../../texts/esp/general';
import noimage from '../../../../assets/nouser.png'
import moment from 'moment';

const TopInfo = ({ content, task }) => {
	const classes = useStyles();

	return (
		<SimpleCard>
			<Grid container justify='space-between'>
				{content.map((item) => (
					<Grid item xs={6} md key={item.id}>
						<TopInfoItem {...item} task={task} />
					</Grid>
				))}
			</Grid>
		</SimpleCard>
	);
};

const useStyles = makeStyles((theme) => ({
	root: {},
}));

const TopInfoItem = ({ title, id, imgID, task }) => {
	const classes = useItemStyles();
	const imgSRC = imgID && task[imgID] ? `${imgServerS3}${task[imgID]}` : null
	let text = task[id]
	if(id.toString().includes('date')){
		text = text ? `${moment.utc(text).local().format('DD/ MMMM / YYYY')} • ${moment.utc(text).local().format('HH:mm')} hrs` : nodata ;
	}

	return (
		<Grid container style={{padding: 15}}>
			{imgID && (
				<Grid item>
					<img src={isValidDocument(imgSRC) ? imgSRC : noimage} className={classes.img} />
				</Grid>
			)}
			<Grid item>
				<Typography className={classes.title}>{title}</Typography>
				<Typography className={classes.text}>{text}</Typography>
			</Grid>
		</Grid>
	);
};

const useItemStyles = makeStyles((theme) => ({
	title: {
		color: '#9AA6B4',
		fontWeight: 600,
		fontSize: '0.75rem',
		marginBottom: 5
	},
	text: {
		fontWeight: 400,
		fontSize: '0.875rem',
	},
	img: {
		width: 40,
		height: 40,
		borderRadius: '100%',
		marginRight: 12
	},
	order_number: {
		color: theme.palette.primary.main,
		fontWeight: 500,
		fontSize: '1rem',
		'&::before':{
			content: '"# "'
		}
	}
}));

export default TopInfo;
