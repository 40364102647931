export const contentData = {
  spanish:{
    layout:{
      title:'Contabilidad',
      navigation:[
          {name:'Tablas de Control',path:'/controltables'},
          {name:'Detalles',path:'/controltables/details/:id'},
      ],
      menu:null
    },
    modals:{
      warning_delete_file:{
        title:'¿Estás seguro?',
        message:'¿Realmente quieres eliminar este registro? Este proceso no puede ser revertido',
        cancelButton:'Cancelar',
        deleteButton:'Eliminar'
      },
      edit_service:{
        title:'Editar servicio',
        button:'Guardar',
        cancelButton:'Cancelar'
      }
    },
    basicform:{
      title:'Información',
      button:null,
      nodata:'Sin Registro',
      headers:{
        fixedHeaderA: ['Nombre','RFC','Estatus','Clasificación','Regimen Fiscal','Sub-Actividad', 
        'Sector de Actividad Económica', 'Fecha Pago Impuestos', 'Equipo Responsable', 'No. COI'],
        fixedHeaderB: null,
        varHeader: ['Tabla SAT', 'Docs Cliente', 'Hoja Trabajo Impuestos', 'Responsable', 
        'Fecha de Revisión', 'Revisó', 'PDF Autorización Cliente', 'Status Cliente',
        'Status', 'Fecha de Envío', 'Notas', 'Horas Extra', 'Comprobante Horas', 'Cargos Extra',
        'Comprobante Cargo', 'Cargo a Cobranza', 'Fecha de Completado'],
        extra: 'Completado'
      },

    }
  }
}
