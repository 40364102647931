import {mainServer} from '../../../../variables/config'
import axios from 'axios'

export const sleep = (time) => {
  return new Promise((resolve) => setTimeout(resolve, time));
}

export const request_business = async (id) => {
  const logData = JSON.parse(localStorage.getItem('data'));
  mainServer.instance.defaults.headers.common['Authorization'] = logData.token
  let response
  response = await mainServer.instance.get("/business/"+id)
  let business = response.data.data.business
  response = await mainServer.instance.get("/businessdocument/"+id)
  business = {...business, 
    legal_representative_id: response.data.data.tributary_mailbox_statuses.legal_representative_id}
  return business
}

export const request_update_business = async(id,data) => {
  const logData = JSON.parse(localStorage.getItem('data'));
  mainServer.instance.defaults.headers.common['Authorization'] = logData.token;
  await mainServer.instance.patch("/business/"+id, data);
  await mainServer.instance.patch("/businessdocument/"+id, 
  {legal_representative_id:data.legal_representative_id});
  let response;
  response = await mainServer.instance.get("/business/"+id)
  let business = response.data.data.business
  response = await mainServer.instance.get("/businessdocument/"+id)
  business = {...business, 
    legal_representative: response.data.data.tributary_mailbox_statuses.legal_representative}
  return business
}

export const request_update_business_documents = async(id,data) => {
  const logData = JSON.parse(localStorage.getItem('data'));
  mainServer.instance.defaults.headers.common['Authorization'] = logData.token;
  await mainServer.instance.patch("/businessdocument/"+id, data);
  let response
  response = await mainServer.instance.get("/businessdocument/"+id)
  return response.data.data.tributary_mailbox_statuses;
}


export const request_businesses = async (id) => {
  const logData = JSON.parse(localStorage.getItem('data'));
  mainServer.instance.defaults.headers.common['Authorization'] = logData.token;
  let response;
  response = await mainServer.instance.get("/business/listbyclient/"+id);
  return response.data.data.businesses;
}

export const request_business_documents = async (id) => {
  const logData = JSON.parse(localStorage.getItem('data'));
  mainServer.instance.defaults.headers.common['Authorization'] = logData.token
  let response
  response = await mainServer.instance.get("/businessdocument/"+id)
  return response.data.data.tributary_mailbox_statuses;
}


export const request_uploadFile = async(id,path,file,ext) => {
  const logData = JSON.parse(localStorage.getItem('data'));
  mainServer.instance.defaults.headers.common['Authorization'] = logData.token;

  let url = '/business/'+path+'/'+id
  let response = await mainServer.instance.post(url, ext)

  const options = {headers: {'Content-Type': file.type}}
  delete axios.defaults.headers.common["Authorization"]
  response = await axios.put(response.data.data.path, file, options);
  mainServer.instance.defaults.headers.common['Authorization'] = logData.token;

  response = await mainServer.instance.get("/business/"+id)
  let business = response.data.data.business
  response = await mainServer.instance.get("/businessdocument/"+id)
  business = {...business, 
    legal_representative_id: response.data.data.tributary_mailbox_statuses.legal_representative_id}
  return business
}


export const request_deleteFile = async (id,path) => {
  const logData = JSON.parse(localStorage.getItem('data'))
  mainServer.instance.defaults.headers.common['Authorization'] = logData.token
  const url = '/business/'+path+'/'+id
  await mainServer.instance.delete(url)
  let response;
  response = await mainServer.instance.get("/business/"+id)
  let business = response.data.data.business
  response = await mainServer.instance.get("/businessdocument/"+id)
  business = {...business, 
    legal_representative_id: response.data.data.tributary_mailbox_statuses.legal_representative_id}
  return business
}

