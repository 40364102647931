import React, { useEffect } from 'react'
import { CircularProgress, Grid, makeStyles, withStyles } from '@material-ui/core'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import DisplayText from '../../../../components_v1/Texts/DisplayText';
import CardContainer from '../components/CardContainer';
import Banner from '../components/Banner'
import { useState } from 'react';
import { Accordion, AccordionDetails, AccordionSummary } from '../../../../components_v1/Structure/Accordion/Accordion';
import { onGetErrorMessage } from '../../../../shared/utility';
import { set } from 'date-fns';
import { request_process } from '../requests';
import LoadingContainer from '../../../../components_v1/Structure/Layouts/LoadingContainer';

const styles = makeStyles(theme => ({
    nodata: {
        marginTop: 40
    }
}))

const GeneralInfo = ({ process, files, content, nodata, onDownloadDocument }) => {

    const classes = styles()

    const [accordion, setAccordion] = useState(true) //Set to true after tests

    return (
        <div>
            {process ?
                <Accordion expanded={accordion} onChange={() => setAccordion(prevState => !prevState)}>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="general_info"
                        id="general_info-header"
                    >
                        <DisplayText variant='body2' align='center'>INFORMACIÓN GENERAL</DisplayText>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Grid container justify='center' spacing={4}>
                            <Grid item xs={12}>
                                <Banner content={content.banner} data={process} nodata={nodata} />
                            </Grid>
                            {content.fiscal && <Grid item xs={12} sm>
                                <CardContainer content={content.fiscal} data={process} files={files} nodata={nodata} onDownloadDocument={onDownloadDocument} />
                            </Grid>
                            }
                            {content.taxes && <Grid item xs={12} sm>
                                <CardContainer content={content.taxes} data={process} nodata={nodata} />
                            </Grid>}
                            <Grid item xs={12}>
                                {content.responsibles && <Banner content={content.responsibles} data={process} nodata={nodata} />}
                            </Grid>
                        </Grid>
                    </AccordionDetails>
                </Accordion>
                :
                <div className={classes.nodata}>
                    <Grid container justify='center' alignItems='center' direction='column'>
                        <Grid item>
                            <DisplayText>No se encontraron registros</DisplayText>
                        </Grid>
                    </Grid>
                </div>
            }
        </div>
    )
}

export default GeneralInfo