
import moment from 'moment'

export const cleanData2Table = (data, nodata, catalogs) => {

  const table = data.map(item => {
    
    let temp = {
      id:item.id_billing_stamp, 
      used_id: item.used,
      files:[
        {id:item.id_billing_stamp,key:'cerl',name:'eFirma Certificado',path:'cerl', status: checkStatus(item, 'cerl')},
        {id:item.id_billing_stamp,key:'key',name:'eFirma Llave',path:'key', status:checkStatus(item, 'key')},
      ],
      data:[]
    }
    
    let used_option = catalogs.business_documents.billing_stamp_used.filter(el => el.id === item.used)
    used_option = used_option.length > 0 ? used_option[0].label : nodata

    let array = [
      item.id_billing_stamp, 
      item.number ? item.number : nodata,
      item.branch_office ? item.branch_office: nodata,
      used_option,
      item.expiration_date ?  moment(item.expiration_date).format('DD-MM-YYYY') : nodata,
      item.password ?  item.password : nodata,
      'cerl',
      'key',

    ]

    temp.data = [...array]
    return temp
  })

  return table
}

const checkStatus = (item, key) => {
  return item[key] !== "0" && item[key] !== 0 && item[key] !== ""
}
