import React, {useState, useEffect} from 'react'
import {  Dialog, Grid, Grow, Icon, makeStyles } from '@material-ui/core'
import { red } from '@material-ui/core/colors';
import RoundedButton from '../../../../../../components_v1/Actions/RoundedButton';
import DisplayText from '../../../../../../components_v1/Texts/DisplayText';
import { onGetErrorMessage } from '../../../../../../shared/utility';
import {request_delete_service} from '../requests'

const Transition = React.forwardRef(function Transition(props, ref) {return <Grow ref={ref} {...props} timeout={500} />;});

const useStyles = makeStyles(theme => ({
    paper:{
        borderRadius:32, 
        padding:theme.spacing(5)
    },
    root:{
        paddingTop:32,
        paddingBottom:0
    },
    icon:{
        color:red[700],
        //background:red[700],
        borderRadius:'50%',
        fontSize:72,
        margin:'auto'
        //padding:8
    },
    message:{
        paddingTop:16,
        paddingBottom:16,
    }
}))

const DeleteCertificateModal = (props) => {

    const classes = useStyles()
    const {open, message, onClose, origin, onUpdateCompleted} = props
    const [error, setError] = useState(null)
    const [loading, setLoading] = useState(false)

    useEffect(() => {
        if(!open){
            setError(null); setLoading(false)
        }
    }, [open])

    const onDelete = async() => {
        try {
            setLoading(true)
            await request_delete_service(origin.id_certificate)
            onUpdateCompleted()
        } catch (error) {
            console.log(error)
            setError(onGetErrorMessage(error))
            setLoading(false)
        }
    }

    return(
        <Dialog open={open} maxWidth='xs' fullWidth classes={{ paper: classes.paper }} className={classes.root}
        TransitionComponent={Transition}>
            <div className={classes.root}>
                <Grid container justify='space-around' alignItems='center' spacing={3}>
                    <Grid item>
                        <div className={classes.iconContainer}>
                            <Icon className={classes.icon}>close</Icon>
                        </div>
                    </Grid>
                    <Grid item xs={12}>
                        <DisplayText variant='h6' align='center' style={{paddingTop:8}}> ¿Estás seguro? </DisplayText>
                    </Grid>
                    <Grid item xs={12}>
                        <div className={classes.message}>
                            <DisplayText color='textSecondary' align='center'>{message}</DisplayText>
                        </div>
                    </Grid>
                    <Grid item xs={12}>
                        <Grid container justify='center' spacing={2}>
                            <Grid item>
                                <RoundedButton onClick={onClose}>Cerrar</RoundedButton>
                            </Grid>
                            <Grid item>
                                <RoundedButton color='primary' onClick={onDelete} loading={loading}>Continuar</RoundedButton>
                            </Grid>
                        </Grid>
                    </Grid>
                    
                </Grid>
            </div>
        </Dialog>
    )
}

export default DeleteCertificateModal
