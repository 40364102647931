export const contentData = {
    spanish:{
      layout:{
        title:'Tablas de Control',
        navigation:[
            {name:'Tablas de Control',path:'/controltables'},
          ],
      },
      modals:null
    }
  }