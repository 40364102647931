export const contentData = {
  spanish:{
    modals:{
      warning_delete_item:{
        title:'¿Estás seguro?',
        message:'¿Realmente quieres eliminar este registro? Este proceso no puede ser revertido',
        cancelButton:'Cancelar',
        deleteButton:'Eliminar'
      },
      edit_service:{
        title:'Editar servicio',
        button:'Guardar',
        cancelButton:'Cancelar'
      }
    },
    basicform:{
      title:'Información Detallada',
      button:'Editar',
      nodata:'Sin Registro',
      data:{
        name:{
          label:'',
          data:'',
        },
        specs:{
          label:'',
          data:''
        },
        responsible:{
          label:'',
          data:'',
        },
        periodicity:{
          label:'',
          data:'',
        },
        net_salary:{
          label:'',
          data:'',
        },
        reimbursement:{
          label:'',
          data:'',
        },
        substitute_boss:{
          label:'',
          data:'',
        },
        clabe:{
          label:'',
          data:'',
        },
        banks:{
          label:'',
          data:'',
        },
        beneficiary:{
          label:'',
          data:'',
        },
        bank_data:{
          label:'',
          data:'',
        },
        comission:{
          label:'',
          data:'',
        }
      },
      content:{
        name:{
          label:'Nombre del trabajador'
        },
        specs:{
          label:'Especificaciones'
        },
        responsible:{
          label:'Responsable'
        },
        periodicity:{
          label:'Periodicidad'
        },
        net_salary:{
          label:'Salario Neto a Depositar'
        },
        reimbursement:{
          label:'Reembolso de gastos o viáticos'
        },
        substitute_boss:{
          label:'Patrón Sustituto'
        },
        clabe:{
          label:'CLABE del Beneficiario'
        },
        banks:{
          label:'Banco'
        },
        beneficiary:{
          label:'Nombre Beneficiario'
        },
        bank_data:{
          label:'Otros Datos Bancarios'
        },
        comission:{
          label:'Comisión'
        }
      },
      documents:{
        title:'Documentos',
        header:{title:'Archivos', actions:'Acciones'},
        files:[
          {key:'sdi_imss',name:'SDI para IMSS',path:'sdiimss', status:false},
          {key:'sdi_roster',name:'SD para nómina',path:'sdiroster', status:false},
          {key:'other_benefits',name:'Otras prestaciones',path:'otherbenefits', status:false},
          {key:'bank_data_doc',name:'Datos bancarios',path:'bankdatadoc', status:false},
          {key:'imss_enrollment',name:'Alta IMSS',path:'imssenrollment', status:false},
          {key:'contract',name:'Contrato Outsourcing',path:'contract', status:false},
          {key:'imss_simulator',name:'Simulador IMSS',path:'imsssimulator', status:false},
          {key:'workers_db', name:'Base de Datos de Trabajadores', path:'workersdb', status:false, businessDocument:true}
        ]
      }
    }
  }
}