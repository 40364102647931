import React from 'react';
import { Grid, Icon, IconButton, TableBody, TableRow } from '@material-ui/core';
import TCell from './TCell';
import moment from 'moment-timezone'
import { grey } from '@material-ui/core/colors';
import DisplayText from '../../../../Texts/DisplayText';
import { isValidDocument } from '../../../../../shared/utility';
import InputText from '../../../../Forms/InputText';
import InputSelect from '../../../../Forms/InputSelect';
import InputCheckbox from '../../../../Forms/InputCheckbox';

const novalue = '-'

const TEditableData = ({id, value, onChange, fixed, data, position, editBtn}) => {

    if(data.formType === 'normal') return<TCell fixed={fixed} position={position} width={350}><InputText data={data} onChange={onChange}/></TCell>
    if(data.formType === 'select') return<TCell fixed={fixed} position={position} width={350}><InputSelect data={data} onChange={onChange}/></TCell>
    if(data.formType === 'checkbox') return<TCell fixed={fixed} position={position} width={150}><InputCheckbox data={data} onChange={onChange}/></TCell>

    return <TCell fixed={fixed} position={position}>Editar</TCell>
}
 
export default React.memo(TEditableData);