import React from 'react'
import { Grid, Icon, makeStyles, SvgIcon } from '@material-ui/core'
import DisplayText from '../../Texts/DisplayText'
import SimpleCard from './SimpleCard'
import { red } from '@material-ui/core/colors';

const styles = makeStyles(theme => ({
    root: {
        '&:hover': {
            cursor: 'pointer'
        }
    },
    paper: {
        height: 80,
    },
    notifyContainer: {
        minWidth: 20,
        minHeight: 20
    },
    notify: {
        color: red[500]
    },
    title: {
        paddingLeft: 10,
        paddingRight: 10,
    }
}))

const CardItem = ({ history, label, icon, svgIcon, notifications, url }) => {

    const classes = styles()

    return (
        <div onClick={() => history.push(url)} className={classes.root}>
            <SimpleCard>
                <div className={classes.paper}>
                    <Grid container justify='flex-end'>
                        <Grid item>
                            <div className={classes.notifyContainer}>
                                {notifications && <Icon fontSize='small' className={classes.notify}>circle</Icon>}
                            </div>
                        </Grid>
                        <Grid item xs={12}>
                            {icon || svgIcon ?
                                <Grid container justify='center' alignItems='center'>
                                    <Grid item>
                                        {icon && <Icon color='primary'>{icon}</Icon>}
                                        {svgIcon && <img src={svgIcon}/>}
                                    </Grid>
                                    <Grid item xs>
                                        <DisplayText variant='body2' align='center' className={classes.title}>
                                            {label}
                                        </DisplayText>
                                    </Grid>
                                </Grid>
                                :
                                <DisplayText variant='body2' align='center' className={classes.title}>
                                    {label}
                                </DisplayText>
                            }
                        </Grid>
                    </Grid>
                </div>
            </SimpleCard>
        </div >
    )
}

export default CardItem