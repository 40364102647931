import React, { useState, useEffect } from 'react';
import BasicView from '../../../../../../components_v1/Structure/Layouts/BasicView';
import { onGetUserID } from '../../../../../../shared/utility';
import ToolbarTable from './components/ToolbarTable';
import queryString from 'query-string';
import { contentTasks } from './content';

import TaskCard from './components/TaskCard';
import { Grid, LinearProgress } from '@material-ui/core';
import AddTaskModal from './modals/AddTaskModal';
import useTasks from './useTasks';
import ActionBar from './components/ActionBar';
import empty from '../../../../../../assets/empty.png'
import DisplayText from '../../../../../../components_v1/Texts/DisplayText';

const Tasks = (props) => {
	const { history, visitedUserID } = props;
	const content = contentTasks.spanish;
	const userID = onGetUserID();

	const { system, view_data, actions } = useTasks({ history, userID, visitedUserID });

	return (
		<>
			<AddTaskModal
				open={view_data.add_modal}
				onClose={() => actions.onChangeModalStatus(false)}
				history={history}
				content={content.addModal}
				onChange={actions.onChange}
				loading={system.loading}
				error={system.error}
				form={view_data.form}
				onSubmit={actions.onSubmit}
			/>
			<BasicView
				// title={content.title}
				error={system.error}
				onCloseError={() => actions.onChangeError(null)}
				// loading={system.loading}
				success={system.success}
				onCloseSuccess={() => actions.onChangeSuccess(null)}
				nopaddingroot
			>
				<ToolbarTable
					selected={view_data.selectedTab}
					onChange={(id) => actions.onChangeSelectedTab(id)}
					menuOptions={content.menuOptions}
					onAddRegister={() => actions.onChangeModalStatus(true)}
					search={view_data.search}
					onChangeSearch={actions.onChangeSearch}
					tableFilter={view_data.tableFilter}
					onChangeTableFilter={actions.onChangeTableFilter}
					filterOptions={content.filter_options}
				/>
				{view_data.tasks.length === 0 && !system.loading ? (
					<>
						<div style={{ marginTop: 40 }}>
							<Grid container justify='center' alignItems='center' direction='column'>
								<Grid item>
									<img src={empty} alt='' width={180} />
								</Grid>
								<Grid item>
									<DisplayText>No se encontraron registros</DisplayText>
								</Grid>
							</Grid>
						</div>
					</>
				) : system.loading ? (
					<LinearProgress color='primary' style={{marginTop: 32}} />
				) : (
					<>
						<div style={{ paddingTop: 32 }}>
							<Grid container spacing={4}>
								{view_data.tasks.map((item, i) => (
									<Grid item xs={12} md={4} lg={3} key={i}>
										<TaskCard task={item} content={content.card} onSelect={actions.onSelectTask} />
									</Grid>
								))}
							</Grid>
						</div>
						<ActionBar total={view_data.total} tableFilter={view_data.tableFilter} onUpdateTableFilter={actions.onChangeTableFilter} />
					</>
				)}
			</BasicView>
		</>
	);
};

export default Tasks;
