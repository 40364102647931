import React from 'react';
import { Button, CircularProgress, makeStyles } from '@material-ui/core';
import cx from 'classnames'
import { green, grey, red } from '@material-ui/core/colors';
import { blueGrey } from '@material-ui/core/colors';

const useStyles = makeStyles(theme => ({
    root: {
        background: theme.palette.secondary.main,
        padding: '12px 28px',
        borderRadius: 32,
        color: 'white',
        '&:hover': {
            background: theme.palette.secondary.dark,
        }
    },
    disabled: {
        color: grey[700],
        background: grey[400],
        '&:hover': {
            cursor: 'inherit'
        }
    },
    progress: {
        color: 'white',
        marginLeft: 8
    },
    primary: {
        background: theme.palette.primary.main,
        '&:hover': { background: theme.palette.primary.dark }
    },
    red: {
        background: red[700],
        '&:hover': { background: red[900] }
    },
    green: {
        background: green[700],
        '&:hover': { background: green[900] }
    },
    small: {
        padding: '8px 28px',
    },
    grey: {
        color: '#607D8B',
        border: '1px solid #9FA9C1',
        background: 'white',
        '&:hover': {
            // cursor: 'inherit',
            background: grey[300],
        }
    },
    blueGrey: {
        color: theme.palette.primary.main,
        border: `1px solid ${theme.palette.primary.main}`,
        background: 'white',
        '&:hover': {
            // cursor: 'inherit',
            background: grey[300],
        }
    },
    white: {
        color: grey[500],
        background: `linear-gradient(135deg, #FFF, ${grey[50]})`,
        '&:hover': {
            background: `linear-gradient(135deg, ${grey[50]}, ${grey[100]})`,
        },
    },
    whiteSecondary: {
        color: theme.palette.primary.main,
        background: `linear-gradient(135deg, #FFF, ${grey[50]})`,
        '&:hover': {
            background: `linear-gradient(135deg, ${grey[50]}, ${grey[100]})`,
        },
    },
    thin: {
        padding: '5px 16px',
        textTransform: 'initial',
        boxShadow: '0px 3px 5px -1px rgba(0,0,0,0.2), 0px 6px 10px 0px rgba(0,0,0,0.14), 0px 1px 18px 0px rgba(0,0,0,0.12)'
    }
}))

const RoundedButton = ({ loading, disabled, size, children, color, onClick }) => {

    const classes = useStyles()
    let _color = color && color[0] === '#' ? color : null
    
    return (
        <Button onClick={onClick} size={size}
            disabled={disabled}
            className={cx({
                [classes.root]: true,
                [classes.primary]: color === 'primary',
                [classes.red]: color === 'red',
                [classes.green]: color === 'green',
                [classes.small]: size === 'small',
                [classes.grey]: color === 'grey',
                [classes.blueGrey]: color === 'blueGrey',
                [classes.white]: color === 'white',
                [classes.whiteSecondary]: color === 'whiteSecondary',
                [classes.thin]: size === 'small',
                [classes.disabled]: disabled
            })}
            style={{ backgroundColor: _color }}
        >
            {children}
            {loading ? (<CircularProgress size={24} className={classes.progress} />) : null}
        </Button>
    );
}

export default RoundedButton;