import React, {Component} from 'react'
import { withStyles } from '@material-ui/core/styles'
import Select from 'react-select'
import {
  Avatar,
  Grid,
  MenuItem,
  Typography,
} from '@material-ui/core';
import nouser from '../../../assets/nouser.png'
import {imgServerS3} from '../../../variables/config'
import {blueGrey, blue, grey, red} from '@material-ui/core/colors'
import { ErrorMessage } from '../../DisplayData/DisplayData';

const styles = theme => ({
  root: {
    flexGrow: 1,
  },
  input: {
    display: 'flex',
    padding: 0,
  },
  valueContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    flex: 1,
    alignItems: 'center',
    overflow: 'hidden',
    padding:'0px 10px',

  },
  singleValue: {
    display: 'flex',
    flexWrap: 'wrap',
    flex: 1,
    alignItems: 'center',
    overflow: 'hidden',
  },
  noOptionsMessage: {
    fontSize: 14,
    color:grey[500],
    padding: '8px 8px',
  },
  placeholder: {
    position: 'absolute',
    fontSize: 14,
    paddingLeft:'2px',
    color:grey[400]
  },
  paper: {
    position: 'absolute',
    zIndex: 1,
    marginTop: theme.spacing.unit,
    left: 0,
    right: 0,
    fontSize: 14,
    maxHeight:'200px'
  },
  divider: {
    height: theme.spacing.unit * 2,
  },
});

const styles_cell_item = {
  text:{
    color:grey[700],
    fontWeight:'500'
  },
  icon:{
    color:blue[800]
  }
}

const AvatarItem = (props) => {

  const {label, image} = props
  let iconContent = null

  if(image && image !== 0 && image !== "0" && image !== ""){
    iconContent = (
      <Grid item>
        <Avatar 
          alt={nouser}
          src={imgServerS3+image}
          style={styles_cell_item.avatar}
        />
      </Grid>
    )
  }else{
    iconContent = (
      <Grid item>
        <Avatar 
          alt={'No data'}
          src={nouser}
          style={styles_cell_item.noavatar}
        />
      </Grid>
    )
  }

  return(
    <Grid container wrap='nowrap' spacing={1} alignItems='center'>
      {iconContent}
      <Grid item xs zeroMinWidth>
        <Typography variant='body2' noWrap style={styles_cell_item.text}>
          {label}
        </Typography>
      </Grid>
    </Grid>
  )
}



const NoOptionsMessage = (props) => {
  return (
    <Typography
      color="textSecondary"
      className={props.selectProps.classes.noOptionsMessage}
      {...props.innerProps}
    >
      {props.children}
    </Typography>
  );
}

//const inputComponent = ({ inputRef, ...props }) => {
//  return <div ref={inputRef} {...props} />;
//}

const Option = (props) => {
  return (
    <MenuItem
      buttonRef={props.innerRef}
      selected={props.isFocused}
      component="div"
      style={{
        fontWeight: props.isSelected ? 500 : 400,
      }}
      {...props.innerProps}
    >
      <AvatarItem 
        label={props.data.label}
        image={props.data.image}
      />
    </MenuItem>
  );
}

function Placeholder(props) {
  return (
    <Typography
      noWrap
      variant='caption'
      className={props.selectProps.classes.placeholder}
      {...props.innerProps}
    >
      {props.children}
    </Typography>
  );
}

const  SingleValue = (props) => {
  return (
    <div className={props.selectProps.classes.singleValue}>
      <AvatarItem 
        label={props.data.label}
        image={props.data.image}
      />
    </div>
  );
}

function ValueContainer(props) {
  return <div className={props.selectProps.classes.valueContainer}>{props.children}</div>;
}

const components = {
  NoOptionsMessage,
  Option,
  Placeholder,
  SingleValue,
  ValueContainer,
};

class AvatarSelectInput extends Component{

  state = {
    single: null,
    multi: null,
  }


  handleChange = (item) => {
    const {data, onChange} = this.props;
    let temp = {...data};
    temp.value = item.value;
    temp.isValid = true;
    temp.isVisited = true;
    onChange(temp);
  }

  render(){

    const { classes, data } = this.props;

    const selectStyles = {
      control: styles => ({
        ...styles,
        backgroundColor: "white",
        fontSize:'1em',
        fontFamily:'Montserrat',
        padding:'5px 2px',
        borderRadius:'40px',
        borderColor: blueGrey[100],
        '&:focus': {
          borderWidth:'1px',
          borderColor: blueGrey[500],
        },
        '&:hover': {
          borderWidth:'1px',
          borderColor: blueGrey[200],
        },
      }),
      menuList: styles => ({
        ...styles,
        maxHeight:'200px',
      })
    };

    const selectStyles_error = {
      control: styles => ({
        ...styles,
        backgroundColor: "white",
        fontSize:'1em',
        fontFamily:'Montserrat',
        padding:'5px 2px',
        borderRadius:'40px',
        borderColor: red[500],
        '&:focus': {
          borderWidth:'1px',
          borderColor: red[500],
        },
        '&:hover': {
          borderWidth:'1px',
          borderColor: red[200],
        },
      }),
      menuList: styles => ({
        ...styles,
        maxHeight:'200px',
      })
    };

    let options
    let value
    let isClearable
    let label = ''
    let placeholder = ''
    let error = false
    let isRequired = false

    if(data){
      options = data.options
      isClearable = data.isClearable;
      error = !data.isValid && data.isVisited
      isRequired = data.isRequired
      options.forEach((item, key) => {
        if(item.value === data.value){
          value = key;
        }
      })
      if(data.config.label){
        label = data.config.label
      }
      if(data.config.placeholder){
        placeholder = data.config.placeholder
      }
    }

    return(
        <div className={classes.root}>
          <Grid container spacing={0} alignItems='center'>
            <Grid item xs={12}>
              <Typography variant='subtitle2' align={'left'} style={{fontSize:'0.9em', fontWeight:'600', color: grey[700], padding:'8px 8px'}}>
                {label + (isRequired ? '*' : '')}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <div style={{marginTop:'0px', marginBottom:'4px'}}>
              <Select
                classes={classes}
                styles={error ? selectStyles_error : selectStyles}
                options={options}
                components={components}
                value={value !== undefined ? options[value] : null}
                onChange={this.handleChange}
                placeholder={placeholder}
                isClearable={isClearable}
              />
              </div>
            </Grid>
            <Grid item>
                {error ? (
                  <ErrorMessage message={data ? data.config.helper : ''} variant='caption'/>
                ) : null}
            </Grid>
          </Grid>

          
        </div>
    );
  }
};

export default withStyles(styles)(AvatarSelectInput)
