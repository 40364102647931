export const formData = {
  phone_number:{
    value: null,
    error: false,
    isVisited: false,
    isRequired: false,
    isValid: false,
    config:{
      id:'phone_number',
      type:'number',
      fullWidth: true,
      icon:{
        name:'phone',
        position:'start'
      },
    },
    rules:{
      type:'distance',
      min:10,
      max:100
    }
  },
  work_email:{
    value: null,
    error: false,
    isVisited: false,
    isRequired: true,
    isValid: false,
    config:{
      id:'work_email',
      type:'email',
      fullWidth: true,
      icon:{
        name:'mail',
        position:'start'
      },
      position:'start'
    },
    rules:{
      type:'email',
    }
  },
  personal_email:{
    value: null,
    error: false,
    isVisited: false,
    isRequired: false,
    isValid: false,
    config:{
      id:'personal_email',
      type:'email',
      fullWidth: true,
      icon:{
        name:'mail',
        position:'start'
      },
      position:'start'
    },
    rules:{
      type:'email',
    }
  },
  gmail:{
    value: null,
    error: false,
    isVisited: false,
    isRequired: false,
    isValid: false,
    config:{
      id:'gmail',
      type:'email',
      fullWidth: true,
      position:'start',
      helper:'El valor debe tener al menos 1 caracter'
    },
    rules:{
      type:'none',
    }
  },
  dropbox:{
    value: null,
    error: false,
    isVisited: false,
    isRequired: false,
    isValid: false,
    config:{
      id:'dropbox',
      type:'email',
      fullWidth: true,
      position:'start',
      helper:'El valor debe tener al menos 1 caracter'
    },
    rules:{
      type:'none',
    }
  }
}