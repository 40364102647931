import React from 'react';
import { Grid, Icon, IconButton, makeStyles} from '@material-ui/core';
import { blue, grey, red } from '@material-ui/core/colors';
import DisplayText from '../Texts/DisplayText';
import { PERMISSION_TYPES } from '../../variables/config';
import cx from 'classnames'

const AssociateElement = ({ onDelete, onSelected, associate, permission, associate_id, has_full_access}) => {

    const classes = useStyles()
  
    return(
      <div className={classes.container}>
        <Grid container wrap='nowrap' alignItems='center' spacing={1}>
          <Grid item xs>
            <div className={cx({
              [classes.subcontainer]:true,
            })} onClick={() => !permission || permission >= PERMISSION_TYPES.READ  ? onSelected(associate_id) : undefined}>
              <Grid container spacing={1}>
                <Grid item>
                  <Icon className={classes.folder}>person</Icon>
                </Grid>
                <Grid item xs>
                  <DisplayText variant='body2' className={classes.name}>{associate}</DisplayText>
                </Grid>
              </Grid>
            </div>
          </Grid>
          <Grid item>
            <IconButton disabled={!has_full_access } className={classes.customHoverFocus} onClick={() => onDelete(associate_id)}>
              <Icon fontSize='small'>close</Icon>
            </IconButton>
          </Grid>
        </Grid>
      </div>
    )
}

const useStyles = makeStyles(theme => ({
  card:{borderRadius:'16px'},
    container:{padding:'8px', paddingLeft:'8px', borderTop:`1px solid ${grey[200]}`},
    folder:{color:blue[700]},
    name:{},
    subcontainer:{cursor:'pointer'},
    subcontainer_disabled:{
      opacity:0.6, cursor:'not-allowed'
    },
    customHoverFocus: {
      color:red[700]
    }
}))


export default AssociateElement