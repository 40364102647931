import { Avatar, Grid } from '@material-ui/core';
import React, { useState, useEffect } from 'react';
import RoundedButton from '../../../../../../components_v1/Actions/RoundedButton';
import SimpleCard from '../../../../../../components_v1/Structure/Cards/SimpleCard';
import VerticalData from '../../../../../../components_v1/Structure/DisplayData/VerticalData';
import { onGetDateFormat, onGetTimeDuration } from '../../../../../../shared/utility';
import nouser from '../../../../../../assets/nouser.png'
import StatusData from '../../../../../../components_v1/Structure/DisplayData/StatusData';
import { nodata } from '../../../../../../texts/esp/general';
import { blueGrey, green, red } from '@material-ui/core/colors';
import AvatarPicture from '../../../../../../components_v1/Structure/DisplayData/AvatarPicture';
import { PERMISSION_TYPES } from '../../../../../../variables/config';

const UserInfoForm = ({data, onEdit, onChangePicture, permission, fromTeam}) => {

    let quotedHours = '0 h'
    let restricted_label
    let restricted_color 
    if(data){
        quotedHours = onGetTimeDuration(data.monthly_quoted_time)
        restricted_label = !data.restricted ? 'Negocio restringido' : 'Acceso a contenido restringido'
        restricted_color = !data.restricted ? red[700] : green[700]
    } 


    let can_edit = true
    let can_create = true
    let has_full_access = true
    
    if(permission){
        can_edit = permission > PERMISSION_TYPES.READ
        can_create = permission > PERMISSION_TYPES.EDIT
        has_full_access = permission > PERMISSION_TYPES.CREATE
    }

    
    let taskApproverContent = {data:'No supervisor', color:blueGrey[200]}
    if(data && data.task_approver) taskApproverContent = {data:'Supervisor', color:green[700]}

    return ( 
        <SimpleCard>
            <Grid container>
                <Grid item xs={12} md={6}>
                    <Grid container alignItems='center' direction='column' spacing={2}>
                        <Grid item>
                            <AvatarPicture can_edit={can_edit} nopicture={nouser} onChangePicture={onChangePicture} imgSrc={data ? data.image : null}/>
                        </Grid>
                        <Grid item><StatusData {...taskApproverContent} bold={true}/></Grid>
                        <Grid item><StatusData color={restricted_color} data={restricted_label}/></Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12} md={6}>
                    <Grid container spacing={3}>
                        <Grid item xs={12} md={6}>
                            <VerticalData title='Nombre(s)' data={data ? data.first_name : null}/>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <VerticalData title='Apellido(s)' data={data ? data.last_name : null}/>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <VerticalData title='Usuario' data={data ? data.username : null}/>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <VerticalData title='Género' data={data ? data.gender : null}/>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <VerticalData title='Escolaridad' data={data ? data.scolarity : null }/>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <VerticalData title='Especialización' data={data ? data.specialization : null}/>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <VerticalData title='Posición' data={data ? data.user_level : null}/>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <VerticalData title='Área' data={data ? data.area_name : null}/>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <VerticalData title='Jefe directo' data={data ? data.direct_boss : null}/>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <VerticalData icon='calendar_today' title='Fecha de nacimiento' data={data ? onGetDateFormat(data.birth_date) : null}/>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <VerticalData icon='language' title='Idioma' data={data ? data.language : null}/>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <VerticalData icon='schedule' title='Zona horaria' data={data ? data.timezone : null}/>
                        </Grid>
                        {can_edit ? <Grid item xs={12}>
                            <Grid container justify='flex-end'>
                                <Grid item>
                                    <RoundedButton onClick={onEdit}>Editar</RoundedButton>
                                </Grid>
                            </Grid>
                        </Grid> : null}

                    </Grid>
                </Grid>
            </Grid>
        </SimpleCard>
     );
}
 
export default UserInfoForm