import React from 'react'
import { Grid } from '@material-ui/core';
import { ResponsiveContainer, BarChart, XAxis, YAxis, CartesianGrid, Bar, Cell, LabelList } from 'recharts';
import LoadingContainer from '../../../../../../components_v1/Structure/Layouts/LoadingContainer'
import SimpleCard from '../../../../../../components_v1/Structure/Cards/SimpleCard';
import DisplayText from '../../../../../../components_v1/Texts/DisplayText';

const BChart = ({ data, title, loading, error, success, actions }) => {

    return (
        <LoadingContainer loading={loading} error={error} onCloseError={actions.onCloseError} success={success} onCloseSuccess={actions.onCloseSuccess}>
            <BarChartW title={title} {...data} />
        </LoadingContainer>
    )
}

const BarChartW = ({ title, count, data }) => {

    return (
        <SimpleCard>
            <Grid container justify='center' spacing={2}>
                <Grid item xs={12}>
                    <DisplayText color='primary' style={{ fontSize: 20, fontWeight: 500 }} variant='body2'>{title} ({count})</DisplayText>
                </Grid>
                <Grid item xs={12}>
                    <ResponsiveContainer width="99%" height={100 + 60 * data.length}>
                        <BarChart
                            layout="vertical"
                            data={data}
                            margin={{
                                top: 10,
                                right: 0,
                                left: 150,
                                bottom: 10,
                            }}
                        >
                            <XAxis
                                type='number'
                                axisLine={false}
                                style={{ fontSize: 16, fontWeight: 600, color: '#455A64' }}
                                domain={[0, 100]}
                                tick={{ fontFamily: 'Poppins' }}
                                tickLine={false}
                                tickMargin={20}
                                tickCount={11}
                            />
                            <YAxis
                                type='category'
                                dataKey="name"
                                scale="band"
                                axisLine={false}
                                style={{ fontSize: 16, fontWeight: 500, color: '#455A64' }}
                                tick={{ fontFamily: 'Poppins', dx: -150, textAnchor: 'start' }}
                                tickLine={false}
                                tickMargin={20}
                            />
                            <CartesianGrid horizontal={false} />
                            <Bar
                                type="monotone"
                                dataKey="score"
                                fill={data.color}
                                barSize={50}
                                radius={[0, 10, 10, 0]}
                                isAnimationActive={false}
                            >
                                {data.map((entry, index) => (
                                    <Cell key={`cell-${index}`} fill={`${entry.color}BF`} stroke={entry.color} />
                                ))}
                                <LabelList dataKey={'score'} content={renderCustomizedLabel} />
                            </Bar>
                        </BarChart>
                    </ResponsiveContainer>
                </Grid>
            </Grid>
        </SimpleCard>
    )
}

const renderCustomizedLabel = (props) => {
    const { x, y, width, height, value } = props;

    return (
        <g>
            <text
                x={x + width / 2}
                y={y + height / 2}
                fill={value < 50 ? 'black' : "#fff"}
                textAnchor="middle"
                dominantBaseline="central"
                style={{ fontFamily: 'Poppins', fontWeight: 600 }}
            >
                {value > 0 ? parseFloat(value).toFixed(2) : '-'}
            </text>
        </g>
    );
};

export default BChart