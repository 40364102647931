import React from 'react';
import LoadingContainer from '../../../../components_v1/Structure/Layouts/LoadingContainer';
import BusinessTable from './components/BusinessTable';
import SimpleTable from './components/SimpleTable';

const TimeReportTable = ({month, year, monthCatalog, filter, tableFilter, total, loading, 
    onUpdateTableFilter, onChangeFilter, data, onSelectedItem, type, statistics}) => {

    let contentView = (
        <SimpleTable statistics={statistics}
            month={month} year={year} monthCatalog={monthCatalog}
            data={data} 
            filter={filter}
            tableFilter={tableFilter}
            total={total} 
            loading={loading}
            onSelectedItem={onSelectedItem}
            onUpdateTableFilter={onUpdateTableFilter}
            onChangeFilter={onChangeFilter}/>
    )

    if(type === 'business'){
        contentView = (
            <BusinessTable statistics={statistics}
                month={month} year={year} monthCatalog={monthCatalog}
                data={data} 
                filter={filter}
                tableFilter={tableFilter}
                total={total} 
                loading={loading}
                onSelectedItem={onSelectedItem}
                onUpdateTableFilter={onUpdateTableFilter}
                onChangeFilter={onChangeFilter}/>
        )
    }

    return ( 
        <LoadingContainer>
            
            {contentView}
        </LoadingContainer>
     );
}
 
export default TimeReportTable;