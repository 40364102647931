export const formData = {
    rfc:{
        value: null,
        error: false,
        isVisited: false,
        isRequired: false,
        isValid: false,
        config:{
          id:'rfc',
          type:'text',
          fullWidth: true,
        },
        rules:{
          type:'rfc',
        }
    },
    vulnerable_activity:{
        value: null,
        error: false,
        isVisited: false,
        isRequired: true,
        isValid: false,
        config:{
            id:'vulnerable_activity',
            type:'text',
            fullWidth: true,
        },
        rules:{
            type:'distance',
            min:1,
            max:100
        }
    },
    compliance_officer:{
        value: null,
        error: false,
        isVisited: false,
        isRequired: true,
        isValid: false,
        config:{
            id:'compliance_officer',
            type:'text',
            fullWidth: true,
        },
        rules:{
            type:'distance',
            min:1,
            max:100
        }
    },
    report_limit_date:{
        value: null,
        error: false,
        isVisited: false,
        isRequired: true,
        isValid: false,
        config:{
            id:'report_limit_date',
            type:'date',
            fullWidth: true,
        },
        rules:{
            type:'distance',
            min:1,
            max:100
        }
    },
    responsible_id:{
        value: null,
        error: false,
        isVisited: false,
        isRequired: true,
        isValid: false,
        options:[],
        config:{
            id:'responsible_id',
            type:'avatar_select',
            fullWidth: true,
        },
        rules:{
            type:'none'
        }
    }
};
