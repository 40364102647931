import React from 'react'

import SectionA from './StepsViews/SectionA/SectionA';
import SectionB from './StepsViews/SectionB/SectionB';
import SectionC from './StepsViews/SectionC/SectionC';

export const contentData = {
    spanish: {
        url: 'state_tax',
        fixed_id: 'state_tax_step_id',
        monthly_steps: [],
        paths: {
            primary: {
                id: 'ctrl_state_tax_id',
                path: 'ctrlstatetax'
            },
            secondary: {
                id: 'ctrl_monthly_statements_id',
                path: 'ctrlmonthlystatement'
            }
        },
        sections: {
            general_info: {
                banner: {
                    content: {
                        business: 'Razón social',
                        fixed_rfc: 'RFC',
                        fixed_importance: 'Importancia',
                        fixed_client_classification: 'Clasificación',
                        fixed_client_status: 'Estatus',
                        fixed_client_responsible: 'Responsable',
                        fixed_client_associate: 'Socio'
                    }
                },
                fiscal: {
                    title: 'Impuesto local',
                    content: {
                        fixed_state: 'Estado',
                        fixed_city: 'Ciudad',
                        fixed_employer_enrollment: 'Registro Patronal',
                        fixed_state_register_user: 'Usuario',
                        fixed_state_register_password: 'Contraseña',
                    },
                    files: [
                        {
                            key: 'fixed_state_register_employer_card',
                            url: 'employerregistration',
                            path: 'employercard',
                            name: 'Tarjeta patronal',
                            tableID: 'employer_registration_id',
                            status: false
                        }
                    ]
                },
                taxes: null,
                responsibles: {
                    title: 'Adicionales',
                    content: {
                        fixed_tax_payment_limit_date: 'Fecha Pago Impuestos',
                        fixed_state_tax_responsible: 'Responsable impuesto local',
                        fixed_client_responsible: 'Asesor',
                        fixed_supervisor: 'Supervisor',
                        fixed_analyst: 'Analista',
                    }
                }
            },
            stepper_container: {
                top_banner: {
                    advisory: 1,
                    percent: '% Completado',
                },
                progress: 'Progreso',
                priority: 'Prioridad',
                steps: [
                    {
                        title: 'Elaboración Línea de Captura - Impuesto Local',
                        component: <SectionA />,
                    },
                    {
                        title: 'Envío Línea de Captura y Pago',
                        component: <SectionB />,
                    },
                    {
                        title: 'Revisión Final',
                        component: <SectionC />,
                    },
                ],
            }
        }
    }
}
