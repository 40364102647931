import React, {Component} from 'react'
import { withStyles } from '@material-ui/core/styles'
import { Grid} from '@material-ui/core'
import styles from './styles'
import {contentData} from './content'

import BasicView from '../../../../components/Layouts/BasicView/BasicView'
import EditBusinessInfo from './EditBusinessInfo/EditBusinessInfo'
import ProfilePictureModal from '../../../../components/Modals/ProfilePictureModal/ProfilePictureModal'

import {
  request_business,
  request_businesses,
  request_update_business,
  request_update_business_documents,
  request_business_documents,
  sleep
} from './requests'
import { request_deleteFile, request_uploadFile } from './requests'



class EditBusiness extends Component{

  state = {
    isloading:false,
    business:null,
    businesses:[],
    business_documents:null,
    modals:{
      imagemodal:{open:false,issending:false,error:null},
      warning_delete_file:{open:false, issending:false, error:null, id:null}
    },
    forms:{
      basicinfo:{issending:false, error:null},
      contactinfo:{issending:false, error:null},
    },
    success:{open:false,timeout:2000,type:'success',message:'Successful action!'},
  }

  componentDidMount(){
    this.initModule()
  }

  onCloseSnackbar = (id) => {
    let temp = this.state[id];
    temp.open = false;
    this.setState({[id]:{...temp}})
  }

  onGetPageID = () => {
    return this.props.match.params.id
  }

  onActivateSuccessSnack = () => {
    const {success} = this.state;
    let success_temp = {...success};
    success_temp.open = true;
    this.setState({success:{...success_temp}})
  }

  initModule = async() => {
    const {id, idbusiness} = this.props.match.params
    this.setState({isloading:true})
    let response = await request_business(idbusiness)
    this.setState({business:{...response}})
    response = await request_businesses(id)
    this.setState({businesses:[...response]})
    response = await request_business_documents(idbusiness)
    this.setState({business_documents:{...response}})
    this.setState({isloading:false})
  }

  onReturn = () => {
    this.props.history.goBack()
  }

  onCloseModal = (type) => {
    this.onEditModal(type, {open:false, issending:false, error:null})
  }

  onEditModal = (section, data) => {
    const {modals} = this.state
    let tempModals = {...modals}
    tempModals[section]={...tempModals[section],...data}
    this.setState({modals:{...tempModals}})
  }

  onEditForm = (section, data) => {
    const {forms} = this.state
    let temp = {...forms}
    temp[section]={...temp[section],...data}
    this.setState({forms:{...temp}})
  }

  onOpenFileModal = (section, filedata) => {
    const {modals} = this.state
    let tempModals = {...modals}
    tempModals[section].open=true
    if(filedata){
      tempModals[section].data = {...filedata} 
    }
    this.setState({modals:{...tempModals}})
  }

  onEditBusinessInfo = async(data, type) => {
    try {
      let data2send = {}
      let second_dat2send = {}
      let response

      Object.keys(data).forEach(item => {
        if(item === 'rfc' || item === 'legal_representative_id'){
          data2send = {...data2send, [item]: data[item]}
        }else{
          second_dat2send = {...second_dat2send, [item]: data[item]}
        }
      })
      
      const {idbusiness} = this.props.match.params
      this.onEditForm(type,{issending:true})
      if(JSON.stringify(data2send) !== "{}"){
        response = await request_update_business_documents(idbusiness, data2send)
        this.setState({business_documents:{...response}})
      }
      if(JSON.stringify(second_dat2send) !== "{}"){
        response = await request_update_business(idbusiness, second_dat2send)
        this.setState({business:{...response}})
      }
      this.onEditForm(type,{issending:false, error:null})
      this.onActivateSuccessSnack()
      await sleep(800)
      this.onReturn();
    } catch (error) {
      this.onEditForm(type,{issending:false})
      if(error.response){
        this.onEditForm(type,{error:error.response.data.error.message})
      }
    }
  }

  onSubmitPicture = async(type, file) => {
    try {
      const {history} = this.props
      const path = history.location.pathname
      const {id, idbusiness} = this.props.match.params
      this.onEditModal('imagemodal',{issending:true})
      const aux = file[0].name.split('.')
      const ext = {type:aux[aux.length-1]}
      let response = await request_uploadFile(idbusiness, type, file[0], ext);
      console.log(response)
      this.setState({business:{...response}})
      this.onCloseModal('imagemodal')
      this.onActivateSuccessSnack()
    } catch (error) {
      if(error.response){
        this.onEditForm('imagemodal',{issending:false, error:error.response.data.error.message})
      }
    }
  }

  onDeletePicture = async() => {
    try {
      const {history} = this.props
      const path = history.location.pathname
      const {id, idbusiness} = this.props.match.params
      this.onEditModal('imagemodal',{issending:true})
      let response = await request_deleteFile(idbusiness, 'image');
      this.setState({business:{...response}})
      this.onCloseModal('imagemodal')
      this.onActivateSuccessSnack()
    } catch (error) {
      if(error.response){
        this.onEditForm('imagemodal',{issending:false, error:error.response.data.error.message})
      }
    }
  }

  render(){

    const {history, language, catalogs} = this.props;
    const {isloading, forms, success, modals, business, users} = this.state
    const content = contentData[language]
    let modalcontent = null

    if(modals.imagemodal.open){
      modalcontent = (
        <ProfilePictureModal
          data={modals.imagemodal}
          language={language}
          onClose={() => this.onCloseModal('imagemodal')}
          onSubmit={this.onSubmitPicture}
          onDelete={this.onDeletePicture}
        />
      )
    }

    let businesses_catalog = this.getBusinessesCatalog()
    
    return(
      <BasicView
        history={history}
        content={content.layout}
        returnBtn={true}
        onReturn={this.onReturn}
        success={success}
        onCloseSnackbar={this.onCloseSnackbar}
        isloading={isloading}
      >
        {modalcontent}
        <Grid container spacing={4}>
          <Grid item xs={12}>
            <EditBusinessInfo
              language={language}
              catalogs={catalogs}
              businesses_catalog={businesses_catalog}
              users={users}
              business={business}
              issending={forms.basicinfo.issending}
              error={forms.basicinfo.error}
              onSubmit={(data) => this.onEditBusinessInfo(data, 'basicinfo')}
              onChangeImage={()=> this.onOpenFileModal('imagemodal')}
            />
          </Grid>
        </Grid>
      </BasicView>
      
    )
  }

  getBusinessesCatalog = () => {
    const {match} = this.props
    const {business, businesses} = this.state

    let businesses_catalog = []
    let current_businesses = []
    if(businesses){
      current_businesses = [...businesses]
    }

    businesses.forEach(item => {
      if(item.id_business !== parseInt(match.params.idbusiness)){
        let temp = current_businesses.find(el => el.associate_id === item.id_business)
        if(temp === undefined || temp === null){
          businesses_catalog.push({
            id:item.id_business,
            value:item.id_business,
            label:item.name
          })
        }
        
      }
    })
    return businesses_catalog
  }
}

export default withStyles(styles)(EditBusiness)