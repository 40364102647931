export const contentData = {
  spanish:{
    nodata:'Sin Registro',
    notable:'No existen registros estatales',
    tableTitle:'Registro Estatal',
    header:['ID','Usuario','Contraseña','Estados','Fecha Limite','Inscripción del Registro Estatal',],
    modals:{
      warning_delete_item:{
        title:'¿Estás seguro?',
        message:'¿Realmente quieres eliminar este registro? Este proceso no puede ser revertido',
        cancelButton:'Cancelar',
        deleteButton:'Eliminar'
      }
    },
  },
  english:{

  }
}
