import { useEffect, useState } from 'react';
import { request_add_user, request_businesses, request_create_task, request_tasks } from './requests';
import queryString from 'query-string';
import { isFormValid, onGetCatalogs, onGetErrorMessage, onGetFormData, onSetErrorsToForm } from '../../../shared/utility';
import { catalogs } from '../../../texts/esp/catalogs';
import moment from 'moment';

const useTasks = ({ history, userID }) => {
	const [loading, setLoading] = useState(false);
	const [sending, setSending] = useState(false);
	const [error, setError] = useState(null);
	const [success, setSuccess] = useState(null);
	const [tableFilter, setTableFilter] = useState({ limit: 8, offset: 0, order_by: 'expiration_date', order: 1 });
	const [search, setSearch] = useState('');
	const [form, setForm] = useState({ ...formData });

	const [tasks, setTasks] = useState([]);
	const [total, setTotal] = useState(0);
	const [selectedTab, setSelectedTab] = useState(1);

	const [add_modal, setAddModal] = useState(false);

	useEffect(() => {
		actions.onInitModule();
	}, []);

	useEffect(() => {
		actions.onFilterTasks();
	}, [tableFilter, selectedTab]);

	// useEffect(() => {
	// 	setForm({ ...formData });
	// }, [add_modal]);

	const actions = {
		onInitModule: async () => {
			setLoading(true);

			try {
				//Get tasks
				let params = '';
				let filter = {}
				filter.AND = [
					{ field: 'parent_task_id', operator: 'IS', value: 'null' },
					{ field: 'task_status_id', operator: '<=', value: 5 },
					{
						OR: [
							{ field: 'user_id', operator: '=', value: userID },
							{ field: 'created_by', operator: '=', value: userID },
						],
					},
				];
				let _filter = encodeURIComponent(JSON.stringify(filter));
				params = `?${queryString.stringify(tableFilter)}&filter=${_filter}`;
				const _tasks = await request_tasks(params);
				setTotal(_tasks.count);
				setTasks(_tasks.tasks);

				//Init Form
				let _form = formData;
				let _businesses = await request_businesses();
				_businesses = onGetCatalogs(_businesses, 'id_business', 'name');

				_form.business_id.options = _businesses;
				setForm({ ..._form });

				setLoading(false);
			} catch (error) {
				setError(onGetErrorMessage(error));
				setLoading(false);
			}
		},
		onChange: (data) => {
			console.log(data)
			let temp = { ...form };
			const id = data.config.id;
			temp[id] = { ...data };
			setForm(temp);
		},
		onSelectTask: (id) => {
			history.push(`/tasks/${id}`);
		},
		onChangeModalStatus: (status) => {
			setAddModal(status);
		},
		onChangeError: (error) => {
			setError(error);
		},
		onChangeSuccess: (success) => {
			setError(success);
		},
		onChangeSelectedTab: (tab) => {
			setSelectedTab(tab);
		},
		onChangeTableFilter: (tf) => {
			setTableFilter(tf);
		},
		onChangeSearch: (val) => {
			setSearch(val);
		},
		onFilterTasks: async () => {
			setLoading(true);
			try {
				let filter = {};

				if (selectedTab === 2)
					filter.AND = [
						{ field: 'parent_task_id', operator: 'IS', value: 'null' },
						{ field: 'task_status_id', operator: '>', value: 5 },
						{
							OR: [
								{ field: 'user_id', operator: '=', value: userID },
								{ field: 'created_by', operator: '=', value: userID },
							],
						},
					];
				else
					filter.AND = [
						{ field: 'parent_task_id', operator: 'IS', value: 'null' },
						{ field: 'task_status_id', operator: '<=', value: 5 },
						{
							OR: [
								{ field: 'user_id', operator: '=', value: userID },
								{ field: 'created_by', operator: '=', value: userID },
							],
						},
					];

				if (search && search.length > 0)
					filter.AND.push({
						OR: [
							{ field: 'title', operator: 'LIKE', value: `%%${search}%%` },
							{ field: 'creator', operator: 'LIKE', value: `%%${search}%%` },
						],
					});

				let _filter = encodeURIComponent(JSON.stringify(filter));
				let isValidFilter = Object.keys(filter).length !== 0 || Object.getPrototypeOf(filter) !== Object.prototype;
				let params = isValidFilter ? `?${queryString.stringify(tableFilter)}&filter=${_filter}` : `?${queryString.stringify(tableFilter)}`;

				const _tasks = await request_tasks(params);
				setTotal(_tasks.count);
				setTasks(_tasks.tasks);
				setLoading(false);
			} catch (error) {
				setError(onGetErrorMessage(error));
				setLoading(false);
			}
		},
		onSubmit: async () => {
			const errors = isFormValid(form);
			/// Check if the form is valid
			if (errors && errors.length) {
				const new_form = onSetErrorsToForm(form, errors);
				setForm(new_form);
				return;
			}

			//Extract data
			let data2send = onGetFormData(form);

			data2send.created_by = userID;
			data2send.task_status_id = 1;
			data2send.creation_date = moment().utc().format()

			if(data2send.expiration_date)
				data2send.expiration_date = moment(data2send.expiration_date).utc().format()

            console.log(data2send)
			setLoading(true);

			try {
				//Send data
				let taskID = await request_create_task(data2send);

				const datauser = {user_id: userID, task_id: taskID, main: 0}

				await request_add_user(datauser)

				actions.onChangeModalStatus(false)
				history.push(`/tasks/${taskID}`);
			} catch (error) {
				setError(onGetErrorMessage(error));
				setLoading(false);
			}
		},
	};

	const system = { loading, sending, error, success };
	const view_data = { tasks, tableFilter, total, add_modal, selectedTab, search, form };

	return { system, view_data, actions };
};

const formData = {
	expiration_date: {
		// value: moment().format('YYYY-MM-DD'),
		value: '',
		error: false,
		isVisited: false,
		isRequired: true,
		isValid: true,
		config: {
			id: 'expiration_date',
			type: 'date',
			fullWidth: true,
			label: 'Fecha límite',
			helper: 'Fecha incorrecta',
			// minDate: moment().subtract(2,'days').format('YYYY-MM-DD'),
			// maxDate: moment().add(2,'days').format('YYYY-MM-DD'),
			onKeyDown: (e) => e.preventDefault(),
		},
		rules: {
			// type: 'dateRange',
			// min:moment().subtract(2,'days').format('YYYY-MM-DD'),
			// max:moment().add(2,'days').format('YYYY-MM-DD')
            type: 'date'
		},
	},
	business_id: {
		value: '',
		error: false,
		isVisited: false,
		isRequired: true,
		isValid: false,
		options: [],
		config: {
			id: 'business_id',
			type: 'select',
			fullWidth: true,
			label: 'Negocio',
			placeholder: 'Seleccione una opción',
			helper: 'Este campo es requerido',
		},
		rules: { type: 'select' },
	},
	title: {
		value: null,
		error: false,
		isVisited: false,
		isRequired: true,
		isValid: false,
		config: {
			id: 'title',
			type: 'text',
			fullWidth: true,
			label: 'Título',
			placeholder: 'Título de la tarea',
			helper: 'Este campo es requerido',
		},
		rules: { type: 'distance', min: 1, max: 240 },
	},
	task_type_id: {
		value: '',
		error: false,
		isVisited: false,
		isRequired: true,
		isValid: false,
		options: [...catalogs.task_type],
		config: {
			id: 'task_type_id',
			type: 'select',
			fullWidth: true,
			label: 'Tipo de tarea',
			placeholder: 'Seleccione una opción',
			helper: 'Este campo es requerido',
		},
		rules: { type: 'select' },
	},
	task_priority_id: {
		value: '',
		error: false,
		isVisited: false,
		isRequired: true,
		isValid: false,
		options: [...catalogs.task_priority],
		config: {
			id: 'task_priority_id',
			type: 'select',
			fullWidth: true,
			label: 'Prioridad',
			placeholder: 'Seleccione una opción',
			helper: 'Este campo es requerido',
		},
		rules: { type: 'select' },
	},
};

export default useTasks;
