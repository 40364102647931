import { Typography } from '@material-ui/core';
import React, { useState, useEffect } from 'react';

const DisplayText = (props) => {

    const {children} = props

    return ( 
        <Typography {...props}>{children}</Typography>
     );
}
 
export default DisplayText;