
export const cleanData2Table = (data, nodata) => {

  const table = data.map(item => {
    
    let temp = {
      id:item.id_consulting, 
      status:item.consulting_status_id,
      files:[],
      data:[]
    }

    let array = [
      item.id_consulting, 
      item.name ? item.name : nodata,
      item.consulting_status ? item.consulting_status : nodata,
      item.consulting_progress ? item.consulting_progress : nodata,
    ]

    temp.data = [...array]
    return temp
  })

  return table
}

