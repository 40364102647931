import React, { useState, useEffect } from 'react';
import { makeStyles, Fade, useTheme } from '@material-ui/core';
import noimage from '../../../assets/empty.png'
import cx from 'classnames'
import { imgServerS3 } from '../../../variables/config';

const image_ratio = {
    '1/1': '100%',
    '16/9': '56.25%',
    '4/3': '75%',
    '3/4': '133%',
    '2/1': '200%',
    '5/2': '40%',
    '3/2': '66%'
}

const GenericImage = ({ src, src_temp, size, noborder, ratio, noImagePadding, lowBrightness, borderRadius, style, fullpath, objectFit }) => {

    //console.log(borderRadius)

    const classes = useStyles()
    const [error, setError] = useState(true)

    const onImageLoad = (e) => setError(false)
    const onError = (e) => setError(true)

    let imgSrc = src ? `${imgServerS3}${src}` : noimage
    if(src_temp) imgSrc = src_temp
    if (fullpath) imgSrc = src ? src : noimage
    const _ratio = ratio ? ratio : '1/1'

    let image_height = image_ratio[_ratio]

    
    return (
        <div className={classes.image_container} style={{ paddingTop: image_height, borderRadius: borderRadius, filter: lowBrightness ? 'brightness(0.5)' : 'none', ...style }}>
            {error ? <div className={classes.image_wrapper} ><img src={noimage} alt='' className={classes.image} style={{borderRadius:borderRadius, objectFit: 'contain', background: 'white', padding: 60, boxSizing: 'border-box' }} /></div> : null}
            <Fade in={!error}>
                <div className={classes.image_wrapper} style={{ borderRadius: borderRadius }}>
                    <img
                        alt='' src={imgSrc}
                        className={cx({
                            [classes.image]: true,
                            [classes.imageH]: lowBrightness,
                            [classes.imageContain]: objectFit === 'contain',
                            [classes.noImage]: !src && !src_temp,
                        })}
                        style={{ borderRadius: borderRadius }}
                        onLoad={onImageLoad}
                        onError={onError}
                    />
                </div>

            </Fade>
        </div>
    );
}

const useStyles = makeStyles(theme => ({
    image_container: {
        position: 'relative',
        margin: 'auto',
        width: '100%',
        paddingTop: '100%',
        boxSizing: 'border-box',
        borderRadius: 10,
        height: '100%',
        background:'white'
    },
    image_wrapper: {
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        boxSizing: 'border-box',
    },
    image: {
        width: '100%',
        height: '100%',
        objectFit: 'cover',
        objectPosition: 'center',
        borderRadius: 5,
    },
    imageH: {
        filter: 'brightness(0.5)'
    },
    imageContain: {
        //background: 'black',
        objectFit: 'contain',
    },
    svg_container: {
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        padding: 8,
        boxSizing: 'border-box'
    },
    noImage: {
        objectFit: 'contain',
        background: 'white',
        padding: 60,
        boxSizing: 'border-box'
    }
}))

export default GenericImage;