import {mainServer} from '../../../../variables/config'
import axios from 'axios'

export const sleep = (time) => {
  return new Promise((resolve) => setTimeout(resolve, time));
}

export const request_user_areas = async(id) => {
  const logData = JSON.parse(localStorage.getItem('data'))
  mainServer.instance.defaults.headers.common['Authorization'] = logData.token
  let response = await mainServer.instance.get(`/area/user/${id}`)
  return response.data.data.areas
}

export const request_user_add_area = async(userid, areaid) => {
  const logData = JSON.parse(localStorage.getItem('data'))
  mainServer.instance.defaults.headers.common['Authorization'] = logData.token
  await mainServer.instance.post(`/area/${areaid}/member`, {user_id:userid})
}

export const request_user_delete_area = async(userid, areaid) => {
  const logData = JSON.parse(localStorage.getItem('data'))
  mainServer.instance.defaults.headers.common['Authorization'] = logData.token
  await mainServer.instance.delete(`/area/${areaid}/member/${userid}`)
}

export const request_user_info = async (id) => {
  const logData = JSON.parse(localStorage.getItem('data'))
  mainServer.instance.defaults.headers.common['Authorization'] = logData.token
  let response;
  response = await mainServer.instance.get("/user/employee/" + id);
  return response.data.data.employee
}

export const request_permissions = async(id) => {
  const logData = JSON.parse(localStorage.getItem('data'));
  mainServer.instance.defaults.headers.common['Authorization'] = logData.token;
  let response;
  response = await mainServer.instance.get("/permission/"+id+'/list');
  return response.data.data.user_permissions
}

export const request_update_user = async(id,data) => {
  const logData = JSON.parse(localStorage.getItem('data'));
  mainServer.instance.defaults.headers.common['Authorization'] = logData.token;
  let response;
  response = await mainServer.instance.get('area/user/'+id)
  const areas = response.data.data.areas;
  //if(areas.length > 0)
  //  await mainServer.instance.delete("/area/"+areas[0].id_area+"/member/" + id);
  //await mainServer.instance.post("/area/"+data.area_id+"/member/",{user_id:id});
  await mainServer.instance.patch("/user/employee/" + id, data);

  response = await mainServer.instance.get("/user/employee/" + id);
  return response.data.data.employee
}

export const request_update_permissions = async(id,data) => {
  const logData = JSON.parse(localStorage.getItem('data'));
  mainServer.instance.defaults.headers.common['Authorization'] = logData.token;
  await mainServer.instance.patch("/permission/" + id, data);
  let response;
  response = await mainServer.instance.get("/permission/"+id+'/list');
  return response.data.data.user_permissions
}

export const request_uploadFile = async(id,path,file,ext) => {
  const logData = JSON.parse(localStorage.getItem('data'));
  mainServer.instance.defaults.headers.common['Authorization'] = logData.token;

  let url = '/user/'+path+'/'+id
  let response = await mainServer.instance.post(url, ext)

  const options = {headers: {'Content-Type': file.type}}
  delete axios.defaults.headers.common["Authorization"]
  response = await axios.put(response.data.data.path, file, options);
  mainServer.instance.defaults.headers.common['Authorization'] = logData.token;

  response = await mainServer.instance.get("/user/employee/" + id);
  return response.data.data.employee
}


export const request_deleteFile = async (id,path) => {
  const logData = JSON.parse(localStorage.getItem('data'))
  mainServer.instance.defaults.headers.common['Authorization'] = logData.token
  const url = '/user/'+path+'/'+id
  await mainServer.instance.delete(url)
  let response;
  response = await mainServer.instance.get("/user/employee/" + id);
  return response.data.data.employee
}

