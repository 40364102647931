export const contentData = {
  spanish:{
    layout:{
      title:'Clientes',
      navigation:null
    },
    modals:{
      warning_modal:'¿Realmente quieres eliminar este registro? Este proceso no puede ser revertido',
    },
    nodata:'Sin Registro',
    header:['ID','Nombre', 'Responsable','Proceso','Estatus', 'Fecha de creación'],
    tableTitle:'Lista de clientes'
  }
}