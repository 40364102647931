import { Grid, Icon, Typography, makeStyles } from "@material-ui/core"
import { green, red } from "@material-ui/core/colors"
import React from "react"

const useStyles = makeStyles(theme => ({
    result:{
        width:16,
        height:16,
        padding:4,
        borderRadius:'50%',
        display:'flex',
        justifyContent:'center',
        alignItems:'center'
    },
    container:{
        borderBottom:'1px solid #D9D9D9',
        //marginTop:16,
        padding:'16px 0px'
    },
    icon:{
        color:'white',
        fontSize:14
    }
}))

const SectionTitle = ({title, result}) => {

    const classes = useStyles()

    return(
        <Grid container justify="space-between" className={classes.container} >
            <Grid item><Typography color="primary" variant='h6' style={{fontWeight:300}} >{title}</Typography></Grid>
            <Grid item>
                <div className={classes.result} style={{background:result ? green[500] : red[500]}} >
                    <Icon className={classes.icon} >{result ? 'done' : 'clear'}</Icon>
                </div>
            </Grid>
        </Grid>
    )
}

export default SectionTitle