import React from 'react';
import { Grid, Icon, IconButton, makeStyles } from '@material-ui/core';
import SimpleCard from '../../../../../../../components_v1/Structure/Cards/SimpleCard';
import DisplayText from '../../../../../../../components_v1/Texts/DisplayText';
import AssociateElement from '../../../../../../../components_v1/Forms/AssociateElement';
import { PERMISSION_TYPES } from '../../../../../../../variables/config';

const AssociatesCard = ({associates, onDelete, onSelected, onAddAssociate, permission}) => {

    const classes = useStyles()


    let can_edit = true
    let can_create = true
    let has_full_access = true
    if(permission){
        can_edit = permission.permission_type_id > PERMISSION_TYPES.READ
        can_create = permission.permission_type_id > PERMISSION_TYPES.EDIT
        has_full_access = permission.permission_type_id > PERMISSION_TYPES.CREATE
    }

    return ( 
        <SimpleCard>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Grid container spacing={1}>
                        <Grid item>
                            <DisplayText color='primary' variant='subtitle1' >Socios</DisplayText>
                        </Grid>
                        {can_create ? <Grid item>
                            <IconButton size='small' className={classes.button} onClick={onAddAssociate} ><Icon fontSize='small' >add</Icon></IconButton>
                        </Grid> : null}
                    </Grid>
                </Grid>
                <Grid item xs={12}>
                    <Grid container>
                        <Grid item xs={12}>
                            <div className={classes.header}>
                                <Grid container>
                                    <Grid item xs><DisplayText variant='body2' color='textSecondary'>Nombre</DisplayText></Grid>
                                    <Grid item><DisplayText variant='body2' color='textSecondary'>Acciones</DisplayText></Grid>
                                </Grid> 
                            </div>
                        </Grid>
                        <Grid item xs={12}>
                            {<div className={classes.container}>
                                <Grid container>
                                    {associates.map(item => <Grid item xs={12} key={item.key}><AssociateElement {...item} onSelected={() => console.log('Socio')} onDelete={onDelete} has_full_access={has_full_access}/></Grid>)}
                                </Grid>
                            </div>}
                        </Grid>
                    </Grid>
                    
                </Grid>
            </Grid>
        </SimpleCard>
     );
}

const useStyles = makeStyles(theme => ({
    container:{
        maxHeight:369,
        overflowY:'auto'
    },
    header:{
        padding:8,
        paddingRight:24
    },
    button:{
        background:theme.palette.secondary.main,
        color:'white',
        '&:hover':{
            background:theme.palette.secondary.dark,
        }
    }
}))
 
export default AssociatesCard;
