import React from 'react'
import { CircularProgress, Grid, Icon, IconButton, makeStyles, Paper, Table, TableBody, TableCell, TableHead, TableRow, withStyles } from '@material-ui/core'
import { grey } from '@material-ui/core/colors'

import ActionBar from './ActionBar'
import empty from '../../../../../../assets/empty.png'
import DisplayText from '../../../../../../components_v1/Texts/DisplayText'
import { catalogs } from '../../../../../../texts/esp/catalogs'
import AvatarName from '../../../../../../components_v1/Structure/DisplayData/AvatarName'
import GenericStatus from '../../../../../../components_v1/Structure/DisplayData/GenericStatus'

const useStyles = makeStyles(theme => ({
    root: {},
    paper: {
        borderRadius: 8,
        boxShadow: '0 10px 24px 0 rgba(82, 91, 115, .12)',
    },
    cell: {
        borderColor: grey[200],
    },
    row: {
        '&:hover': {
            cursor: 'pointer',
            background: grey[100]
        }
    },
    cellHeader: {
        borderColor: grey[300],
        paddingTop: 16,
        paddingBottom: 16
    },
    button: {
        background: theme.palette.primary.main,
        padding: '6px 12px',
        borderRadius: 32,
        color: 'white',
        '&:hover': {
            background: theme.palette.primary.dark,
        }
    },
    toolbar: {
        marginBottom: 16
    },
    actions: {
        marginTop: 16
    },
    table: {
        minWidth: 1100
    },
    tableWrapper: {
        overflowX: 'auto',
    },
    nodata: {
        marginTop: 40
    }
}))

const SimpleTable = props => {

    const classes = useStyles()
    const { data, header, total, tableFilter, onUpdateTableFilter, onSelectedItem, loading,
        origin_data, generatePDF } = props

    const noContent = (
        <div className={classes.nodata}>
            <Grid container justify='center' alignItems='center' direction='column'>
                <Grid item>
                    <img src={empty} alt='' width={180} />
                </Grid>
                <Grid item>
                    <DisplayText>No se encontraron registros</DisplayText>
                </Grid>
            </Grid>
        </div>
    )

    return (
        <div>
            {loading ? (
                <Grid container justify='center'>
                    <Grid item><CircularProgress size={32} /></Grid>
                </Grid>
            ) : null}
            <Paper className={classes.paper}>
                {data && data.length ? (
                    <div className={classes.tableWrapper}>
                        <Table className={classes.table}>
                            {header ? (
                                <TableHead>
                                    <TableRow>
                                        {header.map(item => {
                                            return (
                                                <TableCell padding={item.id === 1 ? 'default' : 'default'} className={classes.cellHeader} key={item.id.toString()}>
                                                    <DisplayText variant='subtitle2' >{item.label}</DisplayText>
                                                </TableCell>
                                            )
                                        })}
                                    </TableRow>
                                </TableHead>
                            ) : null}
                            <TableBody>
                                {data.map((item, key) => {
                                    const { id_employee_evaluation, date, evaluator, total, evaluation_date, evaluation_step_id, image } = item

                                    return (
                                        <TableRow key={key.toString()} className={classes.row} >
                                            <TableCell className={classes.cell} onClick={() => onSelectedItem(item)}><DisplayText variant='body2'>{id_employee_evaluation}</DisplayText></TableCell>
                                            <TableCell className={classes.cell} onClick={() => onSelectedItem(item)}><DisplayText variant='body2'>{date}</DisplayText></TableCell>
                                            <TableCell className={classes.cell} onClick={() => onSelectedItem(item)}><AvatarName name={evaluator} imgSrc={image} /></TableCell>
                                            <TableCell className={classes.cell} onClick={() => onSelectedItem(item)}><GenericStatus id={evaluation_step_id} variant='body2' options={catalogs.steps} /></TableCell>
                                            <TableCell className={classes.cell} onClick={() => onSelectedItem(item)}><DisplayText variant='body2'>{total}</DisplayText></TableCell>
                                            <TableCell className={classes.cell} onClick={() => onSelectedItem(item)}><DisplayText variant='body2'>{evaluation_date}</DisplayText></TableCell>
                                            <TableCell className={classes.cell}>
                                                <IconButton styles={{ padding: 8 }} color='primary' disabled={evaluation_step_id !== 6} onClick={() => generatePDF(origin_data[key])}>
                                                    <Icon fontSize='small'>cloud_download</Icon>
                                                </IconButton>
                                            </TableCell>
                                        </TableRow>
                                    )
                                })}

                            </TableBody>
                        </Table>
                    </div>

                ) : null}
            </Paper>
            {data && data.length ? <div className={classes.actions}>
                <ActionBar total={total} tableFilter={tableFilter} onUpdateTableFilter={onUpdateTableFilter} />
            </div> : null}
            {data.length === 0 && !loading ? noContent : null}
        </div>
    )
}

export default SimpleTable