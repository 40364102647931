import React, { useEffect, useState } from 'react';
import { Grid, makeStyles, Typography } from '@material-ui/core';
import ActionModalBar from '../../../components_v1/Actions/ActionModalBar';
import InputText from '../../../components_v1/Forms/InputText';
import SimpleModal from '../../../components_v1/Structure/Modals/SimpleModal';
import DisplayText from '../../../components_v1/Texts/DisplayText';
import { isFormValid, onGetFormData, onGetSelectedFormData, onInitEmptyForm, onInitForm, onSetErrorsToForm, updateAuthorizationHeader } from '../../../shared/utility';
import GenericImage from '../../../components_v1/Structure/DisplayData/GenericImage';
import { private_server } from '../../../variables/config';
import PictureModal from '../../../components_v1/Structure/Modals/PictureModal/PictureModal'
import ImageEdition from '../../../components_v1/Structure/DisplayData/ImageEdition';
import { patch } from 'semver';
import cx from 'classnames'

const useStyles = makeStyles(theme => ({
    form: {
        paddingTop: 32,
        paddingBottom: 0
    },
    labelTab:{
        padding:'6px 12px',
        marginBottom:10,
        cursor:'pointer'
    },
    labelTabSelected:{
        color:theme.palette.primary.main,
        borderBottom:`1px solid ${theme.palette.primary.main}`,
        cursor:'auto'
    }
}))

const EditBannerModal = props => {

    const classes = useStyles()
    const { open, onClose, onUpdateCompleted, origin } = props

    const [form, setForm] = useState(JSON.parse(JSON.stringify(formData)))
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState('')

    const [banner, setBanner] = useState(null)
    const [img_type, setImgType] = useState('web_image')

    const [picture_modal, setPictureModal] = useState(false)

    const image_options = [
        { id: 'web_image', value: 1, label: 'Web' },
        { id: 'mobile_image', value: 2, label: 'Mobile' },
    ]

    useEffect(() => {
        if (!open) {
            setError(''); setLoading(false);
        }else{
            setBanner(origin)
            let _form = JSON.parse(JSON.stringify(formData))
            _form = onInitForm(_form, origin)
            setForm({..._form})
        }
    }, [open])

    const onChange = (data) => {
        let temp = { ...form }
        const id = data.config.id
        temp[id] = { ...data }
        setForm(temp)
    }

    const onSubmit = async () => {
        const errors = isFormValid(form)
        /// Check if the form is valid
        if(errors && errors.length){
            const new_form = onSetErrorsToForm(form, errors)
            setForm(new_form)
            return
        }
        
        //Extract data
        let data2send = onGetSelectedFormData(form, origin)
        console.log(data2send)
        //Send data
        setLoading(true)
        
        try {
            updateAuthorizationHeader(private_server)
            const res = await private_server.patch(`/homebanner/${banner?.id_home_banner}`, data2send)
            console.log(res)
            //regresa home_banner_id
            //setID(banner_id.data.data.home_banner_id)
            await onUpdateCompleted()
            setLoading(false)
            onClose()
         

        } catch (error) {
            console.log(error)
            setError('Hubo un error')
            setLoading(false)
        }

    }

    const onDeleteBanner = async() =>{
        try {
            updateAuthorizationHeader(private_server)
            await private_server.delete(`/homebanner/${banner?.id_home_banner}`)
            await onUpdateCompleted()
            onClose()
        } catch (error) {
            console.log(error)
            setError('Hubo un error')
        }
    }

    const onCompleted = async() =>{
        try {
            updateAuthorizationHeader(private_server)
            const _banner = await private_server.get(`/homebanner/${banner?.id_home_banner}`)
            setBanner(_banner.data.data.bonus)
            await onUpdateCompleted()
            setPictureModal(false)
        } catch (error) {
            console.log(error)
            setError('Hubo un error')
        }
    }

    return (
        <SimpleModal open={open} onClose={onClose} maxWidth='sm'>
            <div>
                <PictureModal open={picture_modal} onClose={()=>setPictureModal(false)} onCompleted={onCompleted} ratio={4/3}
                    title='Añadir Imagen' submit_url={`/homebanner/${banner?.id_home_banner}/document`} enableHighResolution
                    delete_url={`/homebanner/${banner?.id_home_banner}/document?document=web_image`} img_type={img_type} />
                <DisplayText variant='h6' color='primary' style={{ fontWeight: 500 }}>Editar banner</DisplayText>
                <div className={classes.form}> 
                    <Grid container spacing={3}>
                        <Grid item xs={12}>
                            {/* <div>
                                <Grid container >
                                    {image_options.map((tab, key) => (
                                        <Grid item key={`img_type-${key.toString()}`}>
                                            <div onClick={() => setImgType(tab.id)}
                                                className={cx({
                                                    [classes.labelTab]: true,
                                                    [classes.labelTabSelected]: img_type === tab.id
                                                })}>
                                                <Typography>{tab.label}</Typography>
                                            </div>
                                        </Grid>
                                    ))}
                                </Grid>
                            </div> */}
                            <ImageEdition src={banner ? banner['web_image'] : null} ratio='4/3' onClick={()=>setPictureModal(true)} />
                        </Grid>
                        <Grid item xs={12}>
                            <InputText data={form.order} onChange={onChange}/>
                        </Grid>
                        <Grid item xs={12}>
                            <InputText data={form.title} onChange={onChange}/>
                        </Grid>
                        <Grid item xs={12}>
                            <InputText data={form.subtitle} onChange={onChange}/>
                        </Grid>
                        <Grid item xs={12}>
                            <InputText data={form.link} onChange={onChange}/>
                        </Grid>
                        <Grid item xs={12}>
                            <ActionModalBar btnLabel='Guardar' onDelete={onDeleteBanner} loading={loading} error={error} onSubmit={onSubmit}/>
                        </Grid>
                    </Grid>
                </div>
            </div>
        </SimpleModal>
    );
}

export const formData = {
    order: {
        value: null,
        error: false,
        isVisited: false,
        isRequired: false,
        isValid: false,
        config: {
            id: 'order',
            type: 'text',
            fullWidth: true,
            label:'Orden',
            placeholder:'Orden'
        },
        rules: {
            type: 'distance',
            min: 0,
            max: 100
        }
    },
    title: {
        value: null,
        error: false,
        isVisited: false,
        isRequired: true,
        isValid: false,
        config: {
            id: 'title',
            type: 'text',
            fullWidth: true,
            label:'Título',
            placeholder:'Título'
        },
        rules: {
            type: 'distance',
            min: 1,
            max: 100
        }
    },
    subtitle: {
        value: null,
        error: false,
        isVisited: false,
        isRequired: false,
        isValid: false,
        config: {
            id: 'subtitle',
            type: 'text',
            fullWidth: true,
            label:'Subtítulo',
            placeholder:'Subtítulo'
        },
        rules: {
            type: 'distance',
            min: 0,
            max: 100
        }
    },
    link: {
        value: null,
        error: false,
        isVisited: false,
        isRequired: false,
        isValid: false,
        config: {
            id: 'link',
            type: 'text',
            fullWidth: true,
            label:'Url',
            placeholder:'Url'
        },
        rules: {
            type: 'distance',
            min: 0,
            max: 500
        }
    },
    
}

export default EditBannerModal;