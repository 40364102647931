import { mainServer } from '../../../../variables/config'
import axios from 'axios'
import FileSaver from 'file-saver';

export const request_get_user_info = async () => {
  const logData = JSON.parse(localStorage.getItem('data'))
  mainServer.instance.defaults.headers.common['Authorization'] = logData.token
  let response
  response = await mainServer.instance.get("/user/employee/me")
  return response.data.data.employee
}

export const request_process = async (id, params) => {
  const logData = JSON.parse(localStorage.getItem('data'));
  mainServer.instance.defaults.headers.common['Authorization'] = logData.token;
  const response = await mainServer.instance.get(`/procintegralsolution/${id}${params}`)
  return response.data.data.proc_integral_solution
}

export const request_update_process = async (id, idExt, params, path, data, dataExt) => {
  const logData = JSON.parse(localStorage.getItem('data'));
  mainServer.instance.defaults.headers.common['Authorization'] = logData.token;
  await mainServer.instance.patch(`/procintegralsolution/${id}${params}`, data);
  await mainServer.instance.patch(`/${path}/${idExt}`, dataExt);
  const response = await mainServer.instance.get(`/procintegralsolution/${id}${params}`);
  return response.data.data.proc_integral_solution
}

export const request_upload_document = async (id, idExt, file, data, path, ctrlpath) => {
  const logData = JSON.parse(localStorage.getItem('data'));
  mainServer.instance.defaults.headers.common['Authorization'] = logData.token;

  let response = null
  if (ctrlpath) {
    if (path === 'ctrlaccounting')
      response = await mainServer.instance.post(`/${path}/${idExt || id}/document`, data)
    else
      response = await mainServer.instance.post(`/${path}/${idExt}/${data.path}`, { ext: data.ext })
  }
  else
    response = await mainServer.instance.post(`/procintegralsolution/${id}/file`, data)
  const options = { headers: { 'Content-Type': file.type } }
  delete axios.defaults.headers.common["Authorization"]
  if (ctrlpath)
    await axios.put(response.data.data.path, file, options);
  else
    await axios.put(response.data.data.upload_url, file, options);
}

export const request_download_document = async (id, idExt, path, data, ctrlpath) => {
  const logData = JSON.parse(localStorage.getItem('data'));
  mainServer.instance.defaults.headers.common['Authorization'] = logData.token;
  let response = null
  
  if (ctrlpath) {
    if (path === 'ctrlaccounting')
      response = await mainServer.instance.get(`/${path}/${idExt}/document`, data)
    else
      response = await mainServer.instance.get(`/${path}/${idExt}/${data.params.path}`)
  }
  else
    response = await mainServer.instance.get(`/procintegralsolution/${id}/file`, data)
  delete axios.defaults.headers.common["Authorization"]
  response = await axios.get(response.data.data.path, { responseType: 'blob' });
  FileSaver.saveAs(response.data, `evidence`);
}