import React, {Component} from 'react'
import { withStyles } from '@material-ui/core/styles'
import { Grid} from '@material-ui/core'
import styles from './styles'
import {contentData} from './content'

import BasicView from '../../../../components/Layouts/BasicView/BasicView'
import EditUserInfo from './EditUserInfo/EditUserInfo'
import FormA from '../../../../components/Forms/FormA/FormA'
import ProfilePictureModal from '../../../../components/Modals/ProfilePictureModal/ProfilePictureModal'

import {
  request_prospect,
  request_employees,
  request_update_prospect,
  sleep
} from './requests'

import {formData as registerdata} from './registerdata/data' 
import {contentData as registercontent} from './registerdata/content' 
import {catalog_matrix as registermatrix} from './registerdata/catalog_matrix'

import {formData as contactdata} from './contactdata/data' 
import {contentData as contactcontent} from './contactdata/content' 
import {catalog_matrix as contactmatrix} from './contactdata/catalog_matrix'
import { Redirect } from 'react-router'


class EditProspect extends Component{

  state = {
    isloading:false,
    user:null,
    userID:null,
    modals:{
      imagemodal:{open:false,issending:false,error:null},
      warning_delete_file:{open:false, issending:false, error:null, id:null}
    },
    forms:{
      basicinfo:{issending:false, error:null},
      registerinfo:{issending:false, error:null},
      contactinfo:{issending:false, error:null},
    },
    success:{open:false,timeout:2000,type:'success',message:'Successful action!'},
  }

  componentDidMount(){
    this.initModule()
  }

  onCloseSnackbar = (id) => {
    let temp = this.state[id];
    temp.open = false;
    this.setState({[id]:{...temp}})
  }

  onGetPageID = () => {
    const {history} = this.props
    const path = history.location.pathname
    return path.substring(path.lastIndexOf('/')+1)
  }

  onActivateSuccessSnack = () => {
    const {success} = this.state;
    let success_temp = {...success};
    success_temp.open = true;
    this.setState({success:{...success_temp}})
  }

  initModule = async() => {
    const prospectID = this.onGetPageID()
    this.setState({isloading:true})
    let response = await request_prospect(prospectID)
    this.setState({prospect:{...response}})
    response = await request_employees()
    this.setState({users:[...response]})
    this.setState({isloading:false})
  }

  onReturn = () => {
    const id = this.onGetPageID()
    this.props.history.push('/prospects/details/'+id)
  }

  onCloseModal = (type) => {
    this.onEditModal(type, {open:false, issending:false, error:null})
  }

  onEditModal = (section, data) => {
    const {modals} = this.state
    let tempModals = {...modals}
    tempModals[section]={...tempModals[section],...data}
    this.setState({modals:{...tempModals}})
  }

  onEditForm = (section, data) => {
    const {forms} = this.state
    let temp = {...forms}
    temp[section]={...temp[section],...data}
    this.setState({forms:{...temp}})
  }

  onOpenFileModal = (section, filedata) => {
    const {modals} = this.state
    let tempModals = {...modals}
    tempModals[section].open=true
    if(filedata){
      tempModals[section].data = {...filedata} 
    }
    this.setState({modals:{...tempModals}})
  }

  onEditUserInfo = async(data, type) => {
    try {
      const prospectID = this.onGetPageID()
      this.onEditForm(type,{issending:true})
      let response = await request_update_prospect(prospectID, data)
      this.setState({prospect:{...response}})
      this.onEditForm(type,{issending:false, error:null})
      this.onActivateSuccessSnack()
      await sleep(600)
      this.onReturn();
    } catch (error) {
      this.onEditForm(type,{issending:false})
      if(error.response){
        this.onEditForm(type,{error:error.response.data.error.message})
      }
    }
  }

  render(){

    const {history, language, catalogs, permissions, permissionsType} = this.props;
    const {isloading, forms, success, modals, prospect, users} = this.state
    const content = contentData[language]
    let modalcontent = null

    let permission = permissions.find(el => el.id_permission_module === 2)
    permission = permission ? permission.id_permission_type : 1

    if(modals.imagemodal.open){
      modalcontent = (
        <ProfilePictureModal
          data={modals.imagemodal}
          language={language}
          onClose={() => this.onCloseModal('imagemodal')}
          onSubmit={this.onSubmitPicture}
          onDelete={this.onDeletePicture}
        />
      )
    }

    return(
      <BasicView
        history={history}
        selected={0}
        onChange={this.onChangeView}
        content={content.layout}
        returnBtn={true}
        onReturn={this.onReturn}
        success={success}
        onCloseSnackbar={this.onCloseSnackbar}
        isloading={isloading}
      >
        {modalcontent}
        {permission <= permissionsType.EDIT ? (
          <Redirect to='/profile'/>
        ) : null}
        <Grid container spacing={4}>
          <Grid item xs={12}>
            <EditUserInfo
              language={language}
              catalogs={catalogs}
              users={users}
              prospect={prospect}
              issending={forms.basicinfo.issending}
              error={forms.basicinfo.error}
              onSubmit={(data) => this.onEditUserInfo(data, 'basicinfo')}
            />
          </Grid>
          <Grid item xs={12}>
            <Grid container spacing={4}>
              <Grid item xs={12} lg={6}>
                <FormA
                  origin_data={prospect}
                  catalogs={catalogs}
                  catalog_matrix={registermatrix}
                  form_data={registerdata}
                  content={registercontent[language]}
                  users={users}
                  issending={forms.registerinfo.issending}
                  error={forms.registerinfo.error}
                  onSubmit={(data) => this.onEditUserInfo(data, 'registerinfo')}
                />
              </Grid>
              <Grid item xs={12} lg={6}>
                <FormA
                  origin_data={prospect}
                  catalogs={catalogs}
                  catalog_matrix={contactmatrix}
                  form_data={contactdata}
                  content={contactcontent[language]}
                  issending={forms.contactinfo.issending}
                  error={forms.contactinfo.error}
                  onSubmit={(data) => this.onEditUserInfo(data, 'contactinfo')}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </BasicView>
      
    )
  }
}

export default withStyles(styles)(EditProspect)