import React, {useState, useEffect} from 'react'
import { Grid, Switch } from '@material-ui/core'
import moment from 'moment'

import EditCardWrapper from '../../../../../components/Cards/EditCardWrapper'
import ProfileAvatar from '../../../../../components/Avatars/ProfileAvatar'
import InputText from '../../../../../components/Inputs/InputText/InputText';
import InputSimpleSelect from '../../../../../components/Inputs/InputSimpleSelect/InputSimpleSelect';


import {formData} from './data'
import {contentData} from './content'
import { RoundedButton } from '../../../../../components/Buttons/GeneralButtons';
import DisplayText from '../../../../../components_v1/Texts/DisplayText'
import { isFormValid, onGetDateFormat, onGetFormData, onGetSelectedFormData, onInitForm, onSetErrorsToForm } from '../../../../../shared/utility'


const EditBasicInfo = props => {

  const {user, catalogs, language, areas, issending, error, onChangeImage, onSubmit} = props
  const [form, setForm] = useState(JSON.parse(JSON.stringify(formData)))
  const [accredited, setAccredited] = useState(false)
  const [restricted_allow, setRestrictedAllow] = useState(false)

  const content = contentData[language]
  
  useEffect(() => {
    const initModule = async() => {
      //Init form with user information and translations
      let temp = onInitForm(form, user, content.sectionA)
      // Update catalogs
      temp['user_level_id'].options = catalogs.user_levels
      temp['timezone'].options = catalogs.timezones
      temp['gender_id'].options = [...catalogs.user_genders]
      temp['scolarity_id'].options = [...catalogs.user_scolarities]
      temp['area_id'].options = [...catalogs.user_areas]

      const _birth_date = onGetDateFormat(temp.birth_date.value)
      if(_birth_date) temp.birth_date = {...temp.birth_date, value:_birth_date, isValid:true, isVisited:true}
      if(user && user.task_approver) setAccredited(true)
      if(user && user.restricted) setRestrictedAllow(true)

      setForm(temp)

    }
    initModule()
  },[user]);

  const onInputChange = (data) => {
    let temp = {...form};
    const id = data.config.id;
    temp[id] = {...data};
    setForm(temp)
  }

  const onInnerSubmit = () => {
    const errors = isFormValid(form)
    if(errors && errors.length){
      const _form = onSetErrorsToForm(form, errors)
      setForm(_form)
      return
    }
  
    let data2send = onGetSelectedFormData(form, user)
    data2send = {...data2send, task_approver: accredited ? 1: 0, restricted: restricted_allow ? 1 : 0}
    onSubmit(data2send)
  }

    let contentA = null
    let avatarContent = null

    contentA = Object.keys(form).map((item,key)=> {
      let inputContent = null
      switch (form[item].config.type) {
        case 'simple_select':
          inputContent = (
            <InputSimpleSelect
              wrap='vertical'
              data={form[item]}
              onChange={onInputChange}
            />
          )
          break;
        default:
          inputContent = (
            <InputText
              data={form[item]}
              onChange={onInputChange}
            />
          )
          break;
      }
      return(
        <Grid item xs={12} md={6} key={key.toString()}>
          {inputContent}
        </Grid>
      )
    })
    avatarContent = <ProfileAvatar image={user ? user.image : null}/>

    return(
      <EditCardWrapper
        button={content.save_button}
        onSubmit={onInnerSubmit}
        issending={issending}
        error={error}
      >
        <Grid container spacing={4}>
          <Grid item xs={12}>
            <Grid container spacing={3}>
              <Grid item xs={12} lg={6}>
                <Grid container spacing={2} direction='column' justify='center' alignItems='center'>
                  <Grid item>{avatarContent}</Grid>
                  <Grid item>
                    <RoundedButton 
                      label={content.image_button}
                      size='small'
                      color='secondary'
                      onClick={onChangeImage}
                    />
                  </Grid>
                  <Grid item>
                    <div>
                      <Grid container alignItems='center' spacing={2}>
                        <Grid item>
                          <DisplayText>¿Es supervisor?</DisplayText>
                        </Grid>
                        <Grid item>
                          <Grid container alignItems='center'>
                            <Grid item><DisplayText variant='subtitle2'>No</DisplayText></Grid>
                            <Grid item><Switch checked={accredited} onChange={(e,val)=>setAccredited(val)}/></Grid>
                            <Grid item><DisplayText variant='subtitle2'>Sí</DisplayText></Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                    </div>
                  </Grid>
                  <Grid item>
                    <div>
                      <Grid container alignItems='center' spacing={2}>
                        <Grid item>
                          <DisplayText>¿Tiene acceso a contenido restringido?</DisplayText>
                        </Grid>
                        <Grid item>
                          <Grid container alignItems='center'>
                            <Grid item><DisplayText variant='subtitle2'>No</DisplayText></Grid>
                            <Grid item><Switch checked={restricted_allow} onChange={(e,val)=>setRestrictedAllow(val)}/></Grid>
                            <Grid item><DisplayText variant='subtitle2'>Sí</DisplayText></Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                    </div>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12} lg={6}>
                <Grid container spacing={3}>
                  {contentA}
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </EditCardWrapper>
    )
}

export default EditBasicInfo