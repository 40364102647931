export const contentData = {
    spanish: {
        button: 'Continuar',
        back: 'Regresar',
        responsible_banner: {
            responsible: 'responsible',
            responsible_id: 'responsible_id',
            completed_date: 'completed_date'
        },
        folio: {
            id: 'folio',
            label: 'Folio',
        },
        declarations_date: {
            id: 'payment_limit_date',
            label: 'Fecha límite de Pago'
        }
    }
}
