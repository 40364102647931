export const contentData = {
  spanish:{
    title:'Crear constitución',
    nodata:'Sin Registro',
    error:'Sin registro',
    button:'Guardar',
    cancelButton:'Cancelar',
    image_button:'Cambiar',
    form:{
      name:{
        label:'Nombre',
        placeholder:'Nombre',
      }, 
      responsible_id:{
        label:'Responsable',
        placeholder:'Responsable',
      },
      due_date:{
        label:'Fecha requerida',
        placeholder:'Fecha requerida',
      },
      estimated_delivery_date:{
        label:'Fecha Estimada de Entrega',
        placeholder:'Fecha Estimada de Entrega',
      },
    },
  },
}