export const contentData = {
    spanish: {
        title: 'Personas Físicas',
        url: 'procphysicalperson',
        path: 'proc_physical_person',
        button: 'Editar',
        banners: {
            general:{
                title: '',
                button: 'Editar',
                form: {
                    payday_limit: 'Límite de envio de impuestos',
                    fixed_responsible: 'Responsable de Impuestos',
                    advisor: 'Asesor',
                }
            },
            history: {
                title: 'Historial',
                filter: 'Año',
                url: 'physicalperson',
                path: 'proc_physical_persons',
                id: 'physical_person',
                maxStep: 8,
                //location: true,
                location:false,
                header: [
                     { id: 1, label: 'Periodo', key: 'auto_date' },
                    /*{ id: 2, label: 'Turno de', key: 'fixed_responsible' },
                    { id: 3, label: 'Fecha límite', key: 'fixed_payday_limit' },
                    { id: 4, label: 'No. de paso', key: 'fixed_integral_solution_step_id' },
                    { id: 5, label: 'Prioridad', key: 'solution_priority' },
                    { id: 6, label: 'Porcentaje' },
                    { id: 7, label: 'Estatus', key: 'process_semaphore_id' }, */

                    //nuevo
                    //{ id: 1, label: 'Razón Social', key: 'business' },
                    { id: 9, label: 'Clasificación', key: 'fixed_client_classification' },
                    { id: 20, label: 'Importancia', key: 'fixed_importance' },
                    { id: 21, label: 'Socio', key: 'fixed_client_associate' },
                    { id: 23, label: 'Responsable', key: 'responsible' },
                    { id: 22, label: 'Asesor', key: 'fixed_business_advisor' },
                    { id: 24, label: 'Turno de', key: 'fixed_responsible' },
                    { id: 4, label: 'Fecha límite', key: 'fixed_payday_limit' },
                    { id: 5, label: 'No. de paso', key: 'fixed_physical_person_step_id' },
                    { id: 6, label: 'Prioridad', key: 'solution_priority' },
                    { id: 7, label: 'Porcentaje' },
                    { id: 8, label: 'Estatus', key: 'process_semaphore_id' },
                ],
            }
        }
    }
}
