import React, { useState, useEffect } from 'react';
import LoadingContainer from '../../../../components_v1/Structure/Layouts/LoadingContainer';
import ApprovalSimpleTable from '../components/ApprovalSimpleTable';
import { request_timereports } from '../requests';
import queryString from 'query-string'
import { onGetUserID } from '../../../../shared/utility';

const RejectedTableView = ({history}) => {

    const [loading, setLoading] = useState()
    const [error, setError] = useState(null)
    const [timereports, setTimereports] = useState([])
    const [total, setTotal] = useState(0)
    const [tableFilter, setTableFilter] = useState({limit:10,offset:0})
    const [filter, setFilter] = useState({query:''})
    const [task_type, setTaskType] = useState(-1)

    const userID = onGetUserID()

    useEffect(() => {
        const initModule = async() => {
            //setSuccess('Bienvenido')
            setLoading(true)
            try {
                const {limit, offset} = tableFilter

                let filterArgs = [{"field":"user_id","operator":"LIKE","value":userID},
                {"field":"state_id","operator":"LIKE","value":4}]
                if(task_type !== -1) filterArgs.push({"field":"task_type_id","operator":"LIKE","value":task_type})
                const param2send = {language_id:2, limit:limit, offset:offset*limit, order_by:'id_time_report', order:1,}
                filterArgs = encodeURIComponent(JSON.stringify(filterArgs))
                const params = `?${queryString.stringify(param2send)}&filter=${filterArgs}`
                const _timereports = await request_timereports(params)
                setTotal(_timereports.count)
                setTimereports(_timereports.time_reports)
            } catch (error) {
                console.log(error)
            }
            setLoading(false)
        }
        initModule()
    }, [filter, tableFilter, task_type])

    const onChangeFilter = (value, type) => {
        if(type === 'task_type') setTaskType(value)
    }

    return ( 
        <LoadingContainer>
            
            <ApprovalSimpleTable
                data={timereports}  task_type={task_type}
                filter={filter}
                tableFilter={tableFilter}
                total={total} 
                loading={loading}
                onSelectedItem={(data) => window.open(`/timereports/${data.id_time_report}`,'_blank')}
                onUpdateTableFilter={(data) => setTableFilter(data)} onChangeFilter={onChangeFilter}/>
        </LoadingContainer>
     );
}
 
export default RejectedTableView;