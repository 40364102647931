import React, {Component} from 'react'
import { withStyles } from '@material-ui/core/styles'
import { Grid} from '@material-ui/core'
import styles from './styles'
import {contentData} from './content'

import BasicView from '../../../../components/Layouts/BasicView/BasicView'
import FormA from '../../../../components/Forms/FormA/FormA'

import {
  sleep,
  request_employees,
  request_add_prospect
} from './requests'

import {formData as prospectdata} from './prospectdata/data' 
import {contentData as prospectcontent} from './prospectdata/content' 
import {catalog_matrix as prospectmatrix} from './prospectdata/catalog_matrix'




class AddProspect extends Component{

  state = {
    isloading:false,
    users:[],
    forms:{
      prospectinfo:{issending:false, error:null},
    },
    success:{open:false,timeout:2000,type:'success',message:'Successful action!'},
  }

  componentDidMount(){
    this.initModule()
  }

  onCloseSnackbar = (id) => {
    let temp = this.state[id];
    temp.open = false;
    this.setState({[id]:{...temp}})
  }

  onGetPageID = () => {
    const {history} = this.props
    const path = history.location.pathname
    return path.substring(path.lastIndexOf('/')+1)
  }

  onActivateSuccessSnack = () => {
    const {success} = this.state;
    let success_temp = {...success};
    success_temp.open = true;
    this.setState({success:{...success_temp}})
  }

  initModule = async() => {
    this.setState({isloading:true})
    let response = await request_employees()
    this.setState({users:[...response]})
    this.setState({isloading:false})
  }

  onReturn = () => {
    this.props.history.push('/prospects')
  }

  onEditForm = (section, data) => {
    const {forms} = this.state
    let temp = {...forms}
    temp[section]={...temp[section],...data}
    this.setState({forms:{...temp}})
  }


  onAddProspect = async(data, type) => {
    try {
      this.onEditForm(type,{issending:true})
      let prospectID = await request_add_prospect(data)
      this.onEditForm(type,{issending:false, error:null})
      this.onActivateSuccessSnack()
      await sleep(2000)
      this.props.history.push("/prospects/details/"+prospectID)
    } catch (error) {
      this.onEditForm(type,{issending:false})
      if(error.response){
        this.onEditForm(type,{error:error.response.data.error.message})
      }
    }
  }

  render(){

    const {history, language, catalogs} = this.props;
    const {isloading, forms, success, users} = this.state
    const content = contentData[language]

    
    return(
      <BasicView
        history={history}
        selected={0}
        onChange={this.onChangeView}
        content={content.layout}
        returnBtn={true}
        onReturn={this.onReturn}
        success={success}
        onCloseSnackbar={this.onCloseSnackbar}
        isloading={isloading}
      >
        <Grid container spacing={4}>
          <Grid item xs={12}>
            <FormA
              catalogs={catalogs}
              catalog_matrix={prospectmatrix}
              form_data={prospectdata}
              content={prospectcontent[language]}
              users={users}
              issending={forms.prospectinfo.issending}
              error={forms.prospectinfo.error}
              onSubmit={(data) => this.onAddProspect(data, 'prospectinfo')}
            />
          </Grid>
        </Grid>
      </BasicView>
      
    )
  }
}

export default withStyles(styles)(AddProspect)