export const contentData = {
  spanish:{
    nodata:'Sin Registro',
    error:'Sin registro',
    subtitle:'Detalles',
    edit:'Editar',
    add:'Agregar',
    nospecialities:'No existen especialidades registradas',
    title:{
      sectionA:'',
      sectionB:'Información de contacto',
      sectionD:'Accesos a negocios',
    },
    header:{title:'Archivos', actions:'Acciones'},
    sectionA:{
      first_name:{
        label:'Nombre(s)'
      },
      last_name:{
        label:'Apellido(s)'
      },
      username:{
        label:'Usuario'
      },
      client_user_level:{
        label:'Nivel de usuario'
      },
      timezone:{
        label:'Zona Horario'
      },
      gender:{
        label:'Género'
      },
      country:{
        label:'País'
      },
      area:{
        label:'Área'
      },
      position:{
        label:'Puesto'
      },
      language:{
        label:'Idioma (Plataforma)'
      },
      client_language:{
        label:'Idioma (Usuario)'
      },
    },
    sectionB:{
      work_email:{
        label:'Correo de trabajo'
      },
      alternative_email:{
        label:'Correo alternativo'
      },
      personal_email:{
        label:'Correo personal'
      },
      phone_number:{
        label:'Télefono'
      },
      extension:{
        label:'Extensión'
      },
      cellphone:{
        label:'Celular'
      },
    },
    sectionC:{
      work_email:{
        label:'Correo de trabajo'
      },
      alternative_email:{
        label:'Correo alternativo'
      },
      personal_email:{
        label:'Correo personal'
      },
      phone_number:{
        label:'Oficina'
      },
      extension:{
        label:'Celular'
      },
      cellphone:{
        label:'Teléfono'
      },
    },
    sectionD:{
      who_charges:{
        label:'¿Quién cobra?'
      },
      contract_duration:{
        label:'Duración'
      },
      contract_periodicity:{
        label:'Periodicidad'
      },
      anual_increment:{
        label:'Incremento anual'
      },
      outsourcing_comission:{
        label:'Comisión por subcontratación'
      },
      contract_value:{
        label:'Valor del contrato'
      },
      iva:{
        label:'I.V.A.'
      },
      total_value:{
        label:'Valor total'
      },
      contract_special_conditions:{
        label:'Condiciones Especiales'
      },
      
    }
  },
  
}