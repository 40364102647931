import React, { useState, useEffect } from 'react';
import { Grid } from '@material-ui/core';
import ActionModalBar from '../../../../../../components_v1/Actions/ActionModalBar';
import SimpleModal from '../../../../../../components_v1/Structure/Modals/SimpleModal';
import DisplayText from '../../../../../../components_v1/Texts/DisplayText';
import InputFile from '../../../../../../components_v1/Forms/InputFile';
import { onGetErrorMessage, onGetFileExtension } from '../../../../../../shared/utility';
import { request_upload_document } from '../requests';

const DocumentModal = ({serviceID, businessID, document, open, onClose, onUpdateCompleted}) => {

    const [file, setFile] = useState(null)
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState('')

    const onSubmit = async() => {
        console.log(businessID)
        try {
        if(!file){
            setError('Debe adjuntar un documento')
            return
        }
        setLoading(true)
        const fileext = onGetFileExtension(file.name)
        const file2send = {ext:fileext}
        //console.log(document)
        
        if(document.businessDocument){
            console.log('Upload to business documents')
            await request_upload_document(businessID, document.path, file, file2send, 'BUSINESS')
        }else{
            console.log('Upload to service documents')
            await request_upload_document(serviceID, document.path, file, file2send, 'SERVICE')
        }

        onUpdateCompleted()

        //await request_upload_document(id, document.path, file, file2send)
        //onUpdateCompleted()
        } catch (error) {
            console.log(error)
            setError(onGetErrorMessage(error))   
        }
        setLoading(false)
        setFile(null)
    }


    const title = document ? document.name : 'Documento'

    return ( 
        <SimpleModal open={open} onClose={onClose} maxWidth='sm'>
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <DisplayText variant='h6' color='primary'>{title}</DisplayText>
                </Grid>
                <Grid item xs={12}>
                    <InputFile id='file' file={file} label='Adjuntar documento' 
                        onChange={(file) => setFile(file)} onDelete={() => setFile(null)}/>
                </Grid>
                <Grid item xs={12}>
                    <ActionModalBar btnLabel='Subir' loading={loading} error={error} onSubmit={onSubmit}/>
                </Grid>
            </Grid>
        </SimpleModal>
     );
}
 
export default DocumentModal;