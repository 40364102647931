import moment from 'moment'

export const cleanData2Table = (prevData, currentData, noData) =>{
    let temp = {
        id: currentData.id_ctrl_state_tax,
        completed: currentData.completed ? true : false,
        files:[],
        data:[]
    }

    let fixedDataA = [
        currentData.businesses_name ? currentData.businesses_name : noData,
        currentData.rfc ? currentData.rfc : noData,
        currentData.client_status ? currentData.client_status : noData,
        currentData.client_classification ? currentData.client_classification : noData,
        currentData.fiscal_regime ? currentData.fiscal_regime : noData,
    ];

    let fixedDataB = [
        currentData.employer_registration_state ? currentData.employer_registration_state : noData, 
        currentData.employer_registration_city ? currentData.employer_registration_city : noData, 
        currentData.user ? currentData.user : noData,
        currentData.employer_registration_password ? currentData.employer_registration_password : noData,
        currentData.employer_enrollment ? currentData.employer_enrollment : noData,
    ]

    let varData = [
        currentData.tax_payment_date ? moment(currentData.tax_payment_date).format('DD-MM-YYYY') : noData,
        currentData.presentation_limit_date ? moment(currentData.presentation_limit_date).format('DD-MM-YYYY') : noData,
        {key:'work_sheet'},
        currentData.process ? currentData.process : noData,
        currentData.status ? currentData.status : noData,
        currentData.client_attention_info_1 ? currentData.client_attention_info_1 : noData,
        currentData.client_attention_info_2 ? currentData.client_attention_info_2 : noData,
        currentData.payment_quantity  ? currentData.payment_quantity : noData,
        currentData.surcharges_and_updates ? currentData.surcharges_and_updates : noData,
        currentData.payment_total ? currentData.payment_total : noData,
        {key:'capture_line_imss_infonavit'},
        currentData.payment_limit_date ? moment(currentData.payment_limit_date).format('DD-MM-YYYY') : noData,
        currentData.responsible ? currentData.responsible : noData,
        {key:'client_answer'},
        currentData.client_comment ? currentData.client_comment : noData,
        currentData.notes ? currentData.notes : noData,
        currentData.extra_hours ? currentData.extra_hours : noData,
        {key:'hours_proof'},
        currentData.extra_charges ? currentData.extra_charges : noData,
        {key:'charge_proof'},
        currentData.collections_charge ? currentData.collections_charge : noData,
        currentData.completition_date ? moment(currentData.completition_date).format('DD-MM-YYYY') : noData,
    ]

    let origin_data = {
        tax_payment_date: currentData.tax_payment_date,
        presentation_limit_date: currentData.presentation_limit_date,
        process: currentData.process,
        status: currentData.status,
        client_attention_info_1_id : currentData.client_attention_info_1_id,
        client_attention_info_2_id : currentData.client_attention_info_2_id,
        payment_quantity: currentData.payment_quantity,
        surcharges_and_updates: currentData.surcharges_and_updates,
        payment_total: currentData.payment_total,
        payment_limit_date: currentData.payment_limit_date,
        responsible_id: currentData.responsible_id,
        client_comment: currentData.client_comment,
        notes : currentData.notes,
        extra_hours : currentData.extra_hours,
        extra_charges : currentData.extra_charges,
        collections_charge : currentData.collections_charge,
        completition_date : currentData.completition_date
    }

    let tempA = {...temp}
    tempA.data = [...fixedDataA]
    prevData.fixedDataA.push(tempA);

    let tempB = {...temp}
    tempB.data = [...fixedDataB]
    prevData.fixedDataB.push(tempB);

    let tempVar = {...temp}
    tempVar.data = [...varData]
    tempVar.files = [
        {id:currentData.id_ctrl_state_tax,key:'work_sheet',name:'Hoja de Trabajo',path:'worksheet',
            status: checkStatus(currentData, 'work_sheet')},
        {id:currentData.id_ctrl_state_tax,key:'capture_line_imss_infonavit',name:'Línea de Captura IMSS/Infonavit',
            path:'capturelineimssinfonavit', status:checkStatus(currentData, 'capture_line_imss_infonavit')},
        {id:currentData.id_ctrl_state_tax,key:'client_answer',name:'Respuesta del Cliente',path:'clientanswer',
            status: checkStatus(currentData, 'client_answer')},
        {id:currentData.id_ctrl_state_tax,key:'hours_proof',name:'Comprobante de horas',path:'hoursproof',
            status: checkStatus(currentData, 'hours_proof')},
        {id:currentData.id_ctrl_state_tax,key:'charge_proof',name:'Tabla SAT',path:'chargeproof',
            status: checkStatus(currentData, 'charge_proof')}
    ]
    tempVar.origin_data = {...origin_data}
    
    prevData.varData.push(tempVar);
    return prevData;
}

const checkStatus = (item, key) => {
    return item[key] !== "0" && item[key] !== 0 && item[key] !== ""
}
