import { mainServer, private_server } from "../../variables/config"

const useUser = () => {

    

    const onGetAllMe = async(params) => {
        //const logData = JSON.parse(localStorage.getItem('data'));
        //mainServer.instance.defaults.headers.common['Authorization'] = logData.token;
        let result = {
            data:[], 
            count:0
        }
        const request = await mainServer.instance.get('/user/employee/list/me', {params:params})
        result.data = request.data.data.employees
        result.count = request.data.data.count
        return result
    }

    //const onUpdate = async(id, data2send) => {
    //    await mainServer.instance.patch(`/employeepayroll/${id}`, data2send)
    //}

    const actions = {
        //onGetAll,
        onGetAllMe
    }
    return {actions};
}
 
export default useUser;