import React, { useState, useEffect } from 'react';
import { Grid, makeStyles } from '@material-ui/core';
import DocumentElement from '../../../../../../../components_v1/Forms/DocumentElement';
import SimpleCard from '../../../../../../../components_v1/Structure/Cards/SimpleCard';
import DisplayText from '../../../../../../../components_v1/Texts/DisplayText';
import { onUpdateDocumentList } from '../../../../../../../shared/utility';
import { PERMISSION_TYPES } from '../../../../../../../variables/config';

const DocumentsCard = ({files, onUpload, onDownload, onDelete, permission}) => {

    const classes = useStyles()
    const _files = onUpdateDocumentList(local_files, files)

    let can_edit = true
    let can_create = true
    let has_full_access = true
    if(permission){
        can_edit = permission.permission_type_id > PERMISSION_TYPES.READ
        can_create = permission.permission_type_id > PERMISSION_TYPES.EDIT
        has_full_access = permission.permission_type_id > PERMISSION_TYPES.CREATE
    }

    return ( 
        <SimpleCard>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <DisplayText color='primary' variant='subtitle1' >Documentos</DisplayText>
                </Grid>
                <Grid item xs={12}>
                    <Grid container>
                        <Grid item xs={12}>
                            <div className={classes.header}>
                                <Grid container>
                                    <Grid item xs><DisplayText variant='body2' color='textSecondary'>Documento</DisplayText></Grid>
                                    <Grid item><DisplayText variant='body2' color='textSecondary'>Acciones</DisplayText></Grid>
                                </Grid> 
                            </div>
                        </Grid>
                        <Grid item xs={12}>
                            <div className={classes.container}>
                                <Grid container>
                                    {_files.map(item => <Grid item xs={12} key={item.key}>
                                        <DocumentElement can_edit={can_edit} has_full_access={has_full_access}  data={item} onUpload={onUpload} onDownload={onDownload} onDelete={onDelete}/>
                                    </Grid>)}
                                </Grid>
                            </div>
                        </Grid>
                    </Grid>
                    
                </Grid>
            </Grid>
        </SimpleCard>
     );
}

const useStyles = makeStyles(theme => ({
    container:{
        maxHeight:369,
        overflowY:'auto'
    },
    header:{
        padding:8,
        paddingRight:24
    }
}))
 
export default DocumentsCard;

const local_files=[
    {key:'logo',name:'Logo',path:'logo', status:false},
    {key:'workers_db',name:'Base de datos de trabajadores',path:'workersdb', status:false},
    {key:'fiscal_situation_proof',name:'Comprobante de Situación Fiscal',path:'fiscalsituationproof', status:false},
    {key:'trial_balance',name:'Balance de pruebas',path:'trialbalance', status:false},
    {key:'trial_balance_initial',name:'Balance inicial de pruebas',path:'trialbalanceinitial', status:false},
    {key:'financial_statements',name:'Estados financieros',path:'financialstatements', status:false},
    {key:'accounting_backup',name:'Respaldo de contabilidad',path:'accountingbackup', status:false},
    {key:'customs_declaration',name:'Declaración aduanera',path:'customsdeclaration', status:false},
    {key:'foreign_expenses',name:'Gastos en el extranjero',path:'foreignexpenses', status:false},
    {key:'expense_control',name:'Control de gastos',path:'expensecontrol', status:false},
    {key:'ada_table',name:'Tabla ADA',path:'adatable', status:false},
    {key:'tax_sheet',name:'Hoja de impuestos',path:'taxsheet', status:false},
    {key:'tax_loss',name:'Pérdida de impuestos',path:'taxloss', status:false},
    {key:'constitutive_act',name:'Acta constitutiva',path:'constitutiveact', status:false},
    {key:'oficial_id',name:'Identificación Oficial',path:'oficialid', status:false},
    {key:'birth_certificate',name:'Certificado de nacimiento',path:'birthcertificate', status:false},
    {key:'curp',name:'CURP',path:'curp', status:false},
    {key:'passport',name:'Pasaporte',path:'passport', status:false},
    {key:'migratory_form',name:'Forma migratoria',path:'migratoryform', status:false},
    {key:'temporal_residence',name:'Residencia temporal',path:'temporalresidence', status:false},
    {key:'workplace_croquis',name:'Croquis del lugar de trabajo',path:'workplacecroquis', status:false},
    {key:'work_sheet_annual_declaration', name:'Papel de Trabajo Declaración Anual', path:'worksheetannualdeclaration', status:false},
    {key:'initial_diagnosis',name:'Diagnóstico inicial',path:'initialdiagnosis', status:false},
    {key:'risk_evaluation',name:'Evaluación de rieso',path:'riskevaluation', status:false},
    {key:'general_profile',name:'Perfil General',path:'generalprofile', status:false},
    {key:'induction_manual',name:'Manual de inducción',path:'inductionmanual', status:false},
    {key:'fiscal_obligations_calendar',name:'Calendario de obligaciones fiscales',path:'fiscalobligationscalendar', status:false},
    //{key:'induction_manual',name:'Manual de inducción',path:'inductionmanual', status:false},


  ]