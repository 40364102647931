import React from 'react'
import { Grid} from '@material-ui/core'
import CardWrapper from './CardWrapper/CardWrapper'
import {RoundedButton} from '../Buttons/GeneralButtons'
import {ErrorMessage, Subtitle} from '../DisplayData/DisplayData'
import SimpleLoader from '../Loaders/SimpleLoader'

const EditCardWrapper = (props) => {

  const {issending, error, onSubmit, button, title, } = props
  let loaderContent = null
  let titleContent = null
  if(issending){
    loaderContent = <SimpleLoader />
  }else{
    if(error){
      loaderContent = <ErrorMessage message={error}/>
    }
  }

  if(title){
    titleContent = <Grid item xs={12}>
      <Subtitle data={title}/>
    </Grid>
  }
    
  return(
    <CardWrapper>
      <div style={{padding:'32px'}}>
        <Grid container spacing={4}>
          {titleContent}
          <Grid item xs={12}>
            {props.children}
          </Grid>
          <Grid item xs={12}>
            <Grid container justify='flex-end' alignItems='center' spacing={2}>
              <Grid item>
                {loaderContent}
              </Grid>
              {button ? (
                <Grid item>
                  <RoundedButton 
                    label={button} 
                    size='small' 
                    color='secondary'
                    onClick={onSubmit}
                  />
                </Grid>
              ) : null}
              
            </Grid>
          </Grid>
        </Grid>
      </div>
    </CardWrapper>
  )
}

export default EditCardWrapper