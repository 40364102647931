import React from 'react'
import { Grid, makeStyles } from '@material-ui/core'
import FilterButton from './components/FilterButton'
import useGeneral from './useGeneral'
import BChart from './components/BChart'
import DateButton from '../../../../../components_v1/Forms/DateButton'
import MixChart from './components/MixChart'
import moment from 'moment'

const useStyles = makeStyles(theme => ({
    root: {},
    toolbar: {
        marginBottom: 16
    },
    nodata: {
        marginTop: 40
    }
}))

const General = props => {

    const classes = useStyles()
    const { system, view_data, actions } = useGeneral({ ...props })
    const { content, chartA, chartB, month, year, month_options, startDate, endDate } = view_data

    return (
        <Grid container spacing={6}>
            <Grid item xs={12}>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <div className={classes.toolbar}>
                            <Grid container spacing={2} >
                                <Grid item>
                                    <Grid container wrap='nowrap' spacing={2} alignItems='flex-end'>
                                        <Grid item>
                                            <FilterButton label='Mes' options={month_options} selected={month} onChange={(val) => actions.onChangeFilter(val, 'month')} />
                                        </Grid>
                                        <Grid item>
                                            <FilterButton label='Año' options={content.year_options} selected={year} onChange={(val) => actions.onChangeFilter(val, 'year')} />
                                        </Grid>
                                    </Grid>
                                </Grid>

                            </Grid>
                        </div>
                    </Grid>
                    <Grid item xs={12}>
                        <BChart data={chartA} title={content.chart.title} loading={system.loadingA} success={system.success} error={system.error} actions={actions} />
                    </Grid>
                </Grid>
            </Grid>
            <Grid item xs={12}>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <div className={classes.toolbar}>
                            <Grid container spacing={2} >
                                <Grid item>
                                    <Grid container wrap='nowrap' spacing={2} alignItems='flex-end'>
                                        <Grid item>
                                            <DateButton
                                                openTo="year"
                                                views={["year", "month"]}
                                                format={'MMMM / yyyy'}
                                                label="Fecha Inicial"
                                                value={startDate}
                                                maxDate={moment().add(-1, 'months')}
                                                onChange={(date) => actions.onChangeDate(date, 'start_date')}
                                            />
                                        </Grid>
                                        <Grid item>
                                            <DateButton
                                                openTo="year"
                                                views={["year", "month"]}
                                                format={'MMMM / yyyy'}
                                                label="Fecha Final"
                                                value={endDate}
                                                maxDate={moment()}
                                                onChange={(date) => actions.onChangeDate(date, 'end_date')}
                                            />
                                        </Grid>
                                    </Grid>
                                </Grid>

                            </Grid>
                        </div>
                    </Grid>
                    <Grid item xs={12}>
                        <MixChart data={chartB} content={content.mix_chart} loading={system.loadingB} success={system.success} error={system.error} actions={actions} />
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    )
}

export default General