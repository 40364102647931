import React, { useState, useEffect } from 'react';
import BasicView from '../../../components_v1/Structure/Layouts/BasicView';
import BusinessCostTable from '../../../components_v1/businesscosts/BusinessCostTable';
import useBusinessCostView from './useBusinessCostView';

const BusinessCostView = () => {

    const {
        loading, 
        data,
        filter_data,
        tableFilter,
        total,
        onDownload,
        onUpdateTableFilter
    } = useBusinessCostView()

    return ( 
        <BasicView
            title="Estadísticas - Costos del negocio"
        
        >
            <BusinessCostTable
                loading={loading}
                data={data}
                filter_data={filter_data}
                tableFilter={tableFilter}
                total={total}
                onDownload={onDownload}
                onUpdateTableFilter={onUpdateTableFilter}
            />
            <div style={{height:60}} />
        </BasicView>
     );
}
 
export default BusinessCostView;