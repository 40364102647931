export const contentData = {
  spanish:{
    layout:{
      title:'Editar usuario',
      navigation:[
        {name:'Clientes',path:'/clients'},
        {name:'Detalles',path:'/clients/details/:id'},
        {name:'Usuarios',path:'/clients/details/:id/users'},
        {name:'Detalles',path:'/clients/details/:id/users/:iduser'},
        {name:'Editar',path:'/clients/details/:id/users/:iduser/edit'},
      ],
    },
    
  }
}