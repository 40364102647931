import { useCallback, useEffect, useState } from "react";
import useAuth from "../../hooks/api/useAuth";
import { useHistory } from "react-router";
import useInterval from "../../hooks/useInterval";
import useChat from "../../hooks/api/useChat";
import { useSnackbarContext } from "../../providers/snackbar/useSnackbarContext";

const useAIChatView = () => {

    const authManager = useAuth()
    const chatManager = useChat()
    const {enqueueSnackbar} = useSnackbarContext()

    const history = useHistory()
    const [user, setUser] = useState()

    const [chat, setChat] = useState()
    const [chats, setChats] = useState([])
    const [messages, setMessages] = useState([])
    const [reauth, setReauth] = useState(false)
    const [busy, setBusy] = useState([])

    const onRetry = useCallback(async() => {
        console.log("Reintentar mensaje por error")
        setBusy(true)
        try {
            await chatManager.onRetryMessage(chat?.id_ai_chat)
            await onSelectChat(chat)
        } catch (error) {
            enqueueSnackbar("Hubo un error con el servicio, intenta de nuevo.", 'error')
            setBusy(false)
            return
        }
        setBusy(false)
    }, [chat])

    const onGenerateNewChat = useCallback(() => {
        setChat(null)
        setMessages([])
        
    }, [])

    const onSelectChat = useCallback(async(_chat) => {
        setChat(_chat)
        setBusy(true)
        try {
            const _current_chat = await chatManager.onGet(_chat?.id_ai_chat)
            let _string2parse = _current_chat?.messages

            console.log(_string2parse)
            _string2parse = _string2parse
                .replaceAll("%22", "'")
                .replaceAll("%27", '"')
                .replaceAll("%5C",'\\\\' )
                .replaceAll(/\\\\n/g, '\\n')
            
            console.log(_string2parse)

            if(hasHiddenCharacters(_string2parse)){
                _string2parse = removeHiddenCharacters(_string2parse)
            }
       
            let _messages = []
            try {
                _messages = JSON.parse(_string2parse)
            } catch (error) {
                console.log(error)
                console.log(_current_chat)
            }
            setMessages(_messages)
            
        } catch (error) {
            
        }
        setBusy(false)
    }, [])


    

    function hasHiddenCharacters(str) {
        // Regular expression to match a wide range of control characters
        const hiddenCharsRegex = /[\u0000-\u001F\u007F-\u009F\u200B-\u200F\u202A-\u202E\u2060-\u206F\uFEFF\u00A0]/;
        return hiddenCharsRegex.test(str);
    }

    function removeHiddenCharacters(str) {
        // Regular expression to match hidden characters
        const hiddenCharsRegex = /[\u0000-\u001F\u007F-\u009F\u200B-\u200F\u202A-\u202E\u2060-\u206F\uFEFF\u00A0]/g;        // Replace hidden characters with an empty string
        return str.replaceAll(hiddenCharsRegex, '');
      }

      function removeEscapedChars(str){
        return str.replace(/\\/g, '\\\\')  // Backslashes
                         .replace(/"/g, '\\"')    // Double quotes
                         .replace(/\n/g, '\\n');  // Newlines
      }

      function isJSONStringProperlyEscaped(jsonString) {
        // Check for balanced double quotes
        if (jsonString[0] !== '"' || jsonString.slice(-1) !== '"') {
          return false;
        }
      
        // Check for proper escaping of characters within the string
        for (let i = 1; i < jsonString.length - 1; i++) {
          const char = jsonString[i];
          if (char === '\\') {
            // Skip escaped characters
            i++;
          } else if (char === '"') {
            // Unescaped double quote found within the string
            return false;
          }
        }
      
        return true;
      }

    useInterval(async() => {
        const data = JSON.parse(localStorage.getItem('data'))
        if(data && !reauth){
            let expDate = new Date(data.exp*1000)
            let currentDate = new Date()
            if(currentDate.getTime() < expDate.getTime()){ // valid token
                if((currentDate.getTime() + 15*60*1000) > expDate.getTime()){ // expiration date soon
                    console.log("reauthenticando")
                    setReauth(true)
                    await authManager.onReAuth()
                    setReauth(false)
                }
            }else{
                localStorage.clear();
                history.push("/signin");
            }
        }
    }, [5000])

    const onRequestChats = useCallback(async(user_local) => {
        const _user = user_local || user
        const _chats = await chatManager.onGetAll({
            offset:0, limit:20, order:1, order_by:'updated_at',
            filter:JSON.stringify({AND:[
                {field:'user_id', operator:'=', value:_user?.id_user}
            ]})
        })
        console.log(_chats)
        setChats(_chats)
    }, [user])

    const onPostMessage = useCallback(async(_value) => {
        console.log(_value)
        setBusy(true)
        try {
            if(chat){
                console.log("Agregar nuevo mensaje", chat)
                let _messages = [...messages]
                const temp = {
                    role:"user",
                        content:_value
                }
                _messages.push(temp)
                setMessages(_messages)
                const data2add = {
                    new_message:JSON.stringify(temp)
                }
                console.log(data2add)
                try {
                    await chatManager.onAddMessage(chat?.id_ai_chat, data2add)
                } catch (error) {
                    enqueueSnackbar("Hubo un error con el servicio, intenta de nuevo.", 'error')
                }
                
                await onSelectChat(chat)
    
            }else{
                console.log("Crear chat con mensaje")
                let _messages = [...messages]
                const temp = {
                    role:"user",
                        content:_value
                }
                _messages.push(temp)
                setMessages(_messages)
                const data2add = {
                    ai_chat_type_id:1,
                    messages:JSON.stringify([temp]),
                    last_message:JSON.stringify(temp),
                    //resume:"_value",
                    title:_value
                }
                try {
                    const chat_id = await chatManager.onAdd(data2add)
                    await onSelectChat({id_ai_chat:chat_id})
                    onRequestChats()
                    console.log("mi nuevo chat id", chat_id)
                } catch (error) {
                    enqueueSnackbar("Hubo un error con el servicio, intenta de nuevo.", 'error')
                }
                
                
                
                
    
            }
        } catch (error) {
            
        }
        
        setBusy(false)
        
    }, [chat, chatManager, messages])

    const initialize = useCallback(async() => {
        let _user
        try {
            _user = await authManager.onGetUserInfo()
            if(!_user){
                history.push(`/signin?redirect_to=${encodeURIComponent("/aichat")}`)
                return
            }
            setUser(_user)
            await onRequestChats(_user)
            
        } catch (error) {
            if(error?.response?.data?.error?.code === 103){
                history.push(`/signin?redirect_to=${encodeURIComponent("/aichat")}`)
            }
        }
    }, [])

    useEffect(() => {
        initialize()
    }, [])

    return {
        busy,
        chat, messages, chats,
        onSelectChat,
        onPostMessage,
        onGenerateNewChat,
        onRetry
    };
}
 
export default useAIChatView;