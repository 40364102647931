import {mainServer} from '../../../../variables/config'
import FileSaver from 'file-saver';
import axios from 'axios'

export const request_business_documents = async (id) => {
  const logData = JSON.parse(localStorage.getItem('data'));
  mainServer.instance.defaults.headers.common['Authorization'] = logData.token;
  let response;
  response = await mainServer.instance.get("/businessdocument/"+id.toString());
  return response.data.data.tributary_mailbox_statuses;
}

export const request_service = async (id) => {
  const logData = JSON.parse(localStorage.getItem('data'));
  mainServer.instance.defaults.headers.common['Authorization'] = logData.token;
  let response;
  response = await mainServer.instance.get("/accounting/"+id.toString());
  let service = response.data.data.accounting;
  response = await mainServer.instance.get("/businessdocument/"+id.toString());
  service = {...service, 
            rfc:response.data.data.tributary_mailbox_statuses.rfc}
  return service;
}

export const request_users = async () => {
  const logData = JSON.parse(localStorage.getItem('data'));
  mainServer.instance.defaults.headers.common['Authorization'] = logData.token;
  let response;
  response = await mainServer.instance.get("/user/employee/list/");
  return response.data.data.employees;
}

export const request_updateService = async(id,data) => {
  const logData = JSON.parse(localStorage.getItem('data'));
  mainServer.instance.defaults.headers.common['Authorization'] = logData.token;
  console.log(data)
  await mainServer.instance.patch("/accounting/"+id.toString(), data);
  await mainServer.instance.patch("/businessdocument/"+id.toString(), {rfc:data.rfc});
  let response;
  response = await mainServer.instance.get("/accounting/"+id.toString());
  let service = response.data.data.accounting;
  response = await mainServer.instance.get("/businessdocument/"+id.toString());
  service = {...service, 
            rfc:response.data.data.tributary_mailbox_statuses.rfc}
  return service;
}

export const request_uploadFile = async(id,path,file, ext, type) => {
  const logData = JSON.parse(localStorage.getItem('data'));
  mainServer.instance.defaults.headers.common['Authorization'] = logData.token;
  
  let url = '/accounting/'+id+'/'+path
  if(type)
    url = '/businessdocument/' + id + '/' + path

  let response = await mainServer.instance.post(url, ext)

  const options = {headers: {'Content-Type': file.type}}
  delete axios.defaults.headers.common["Authorization"]
  response = await axios.put(response.data.data.path, file, options);

  mainServer.instance.defaults.headers.common['Authorization'] = logData.token;

  response = await mainServer.instance.get("/accounting/"+id.toString());
  return response.data.data.accounting;
}

export const request_downloadFile = async(id,path,type) => {
  const logData = JSON.parse(localStorage.getItem('data'));
  mainServer.instance.defaults.headers.common['Authorization'] = logData.token;
  let response;
  let url = '/accounting/'+id+'/'+path
  if(type)
    url = '/businessdocument/' + id + '/' + path
  response = await mainServer.instance.get(url)
  delete axios.defaults.headers.common["Authorization"]
  response = await axios.get(response.data.data.path, {responseType: 'blob'});
  FileSaver.saveAs(response.data, path);
}

export const request_deleteFile = async(id,path,type) => {
  const logData = JSON.parse(localStorage.getItem('data'));
  mainServer.instance.defaults.headers.common['Authorization'] = logData.token;
  let response
  let url = '/accounting/'+id+'/'+path
  if(type)
    url = '/businessdocument/' + id + '/' + path
  await mainServer.instance.delete(url)
  response = await mainServer.instance.get("/accounting/"+id.toString())
  return response.data.data.accounting
}