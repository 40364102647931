import {mainServer} from '../../../../variables/config'
const params = {order_by:'id_user', order:0}

export const request_users = async (params) => {
  const logData = JSON.parse(localStorage.getItem('data'))
  mainServer.instance.defaults.headers.common['Authorization'] = logData.token
  let response = await mainServer.instance.get(`/user/employee/list${params}`);
  return response.data.data
}

export const request_delete_user = async (id, params) => {
  const logData = JSON.parse(localStorage.getItem('data'))
  mainServer.instance.defaults.headers.common['Authorization'] = logData.token
  await mainServer.instance.delete("/user/employee/"+id)
  let response
  response = await mainServer.instance.get(`/user/employee/list${params}`)
  return response.data.data
}

export const request_add_user = async (data) => {
  const logData = JSON.parse(localStorage.getItem('data'))
  mainServer.instance.defaults.headers.common['Authorization'] = logData.token
  data['language_id'] = 2
  let response = await mainServer.instance.post("/user/employee", data)
  return response.data.data.user_id;
}
