export const contentData = {
    spanish:{
        layout:{
            title:'Infonavit',
            navigation:[
                {name:'Clientes',path:'/clients'},
                {name:'Detalles',path:'/clients/details/:id'},
                {name:'Negocios',path:'/clients/details/:id/businesses'},
                {name:'Detalles',path:'/clients/details/:id/businesses/:idbusiness'},
                {name:'Servicio',path:'/clients/details/:id/businesses/:idbusiness/service/:idservice'},
            ],
            menu:[
                {id:0, label:'General'},
                {id:1, label:'Usuarios IDSE'},
                {id:2, label:'Usuarios Infonavit'},
                {id:3, label:'Registro Estatal'},
                {id:4, label:'Logs'}
            ]
        },
        modals:{
            warning_delete_file:{
                title:'¿Estás seguro?',
                message:'¿Realmente quieres eliminar este registro? Este proceso no puede ser revertido',
                cancelButton:'Cancelar',
                deleteButton:'Eliminar'
            },
            edit_service:{
                title:'Editar servicio',
                button:'Guardar',
                cancelButton:'Cancelar'
            }
        },
        basicform:{
            title:'Información Básica',
            button:'Editar',
            nodata:'Sin Registro',
            data:{
                rfc:{
                    label:'',
                    data:''
                },
                user:{
                    label:'',
                    data:''
                },
                password:{
                    label:'Conraseña',
                    data:''
                }
            },
            content:{
                rfc:{
                    label:'RFC'
                },
                user:{
                    label:'Usuario'
                },
                password:{
                    label:'Contraseña'
                }
            },
            documents:{
                title:'Documentos',
                header:{title:'Archivos', actions:'Acciones'},
                files:[
                    {key:'workers_db',name:'Base de Datos de Trabajadores',path:'workersdb', status:false, businessDocument:true},
                    {key:'fiscal_situation_proof',name:'Constancia de Situación Fiscal',path:'fiscalsituationproof', status:false, businessDocument:true},
                ]
            }
        }
    }
}
