import { Grid } from '@material-ui/core';
import { blueGrey } from '@material-ui/core/colors';
import React from 'react'
import { ResponsiveContainer, ComposedChart, Area, Line, XAxis, YAxis, CartesianGrid, Tooltip } from 'recharts';
import SimpleCard from '../../../../../../components_v1/Structure/Cards/SimpleCard';
import DisplayText from '../../../../../../components_v1/Texts/DisplayText';

const Statistics = ({ data, content }) => {

    return (
        <div>
            {data ?
                <Grid container justify='center' spacing={4}>
                    {Object.keys(content).map(chart => {
                        return (
                            <Grid item xs={12} sm={6} md={4}>
                                <ComposedChartW
                                    title={content[chart].label}
                                    data={data[chart]}
                                />
                            </Grid>
                        )
                    })}
                </Grid>
                : null}
        </div>
    )
}

const RenderCustomAxisTick = ({ x, y, payload, data }) => {

    let position = data.length - 1 === payload.index ? 'start' : (payload.index === 0 ? 'start' : 'middle')
    return (
        <g transform={`translate(${x},${y})`}>
            <text x={0} y={0} dy={10} textAnchor={position} fontSize={9} fill="#000">
                {payload.value.substr(0, 3)}.
            </text>
            <text x={0} y={15} dy={10} textAnchor={position} fontSize={9} fill="#666">
                {data[payload.index].year}
            </text>
        </g>
    )
}

const CustomTooltip = ({ payload, label, active}) => {
    console.log(payload)
    if (active) {
        return (
            <div style={{backgroundColor : 'rgba(255,255,255,0.3)', padding:5}}>
                <p style={{fontWeight:500, fontSize:12, color:'#ff7300'}}>{label}</p>
                <p style={{fontSize:12, color:'#ff7300'}}>{payload[0].payload.year}</p>
                <p style={{fontSize:12}}>Puntaje : <span style={{fontWeight:500, fontSize:12}}>{payload[0].value}</span></p>
            </div>
        );
    }

    return null;
}

const ComposedChartW = ({ title, data }) => {

    return (
        <SimpleCard>
            <Grid container justify='center' spacing={2}>
                <Grid item xs={12}>
                    <DisplayText color='primary' style={{ fontWeight: 500 }} variant='body2'>{title}</DisplayText>
                </Grid>
                <Grid item xs={12}>
                    <ResponsiveContainer width="99%" height={300}>
                        <ComposedChart
                            width={300}
                            height={300}
                            data={data}
                            margin={{
                                top: 10,
                                right: 0,
                                left: -35,
                                bottom: 10,
                            }}
                        >
                            <CartesianGrid strokeDasharray="3" />
                            <XAxis
                                dataKey="month"
                                tick={<RenderCustomAxisTick data={data} />}
                            />
                            <YAxis style={{ fontSize: 9 }} domain={[0, 100]} />
                            <Tooltip content={<CustomTooltip />}/>
                            <Area
                                type="monotone"
                                dataKey="score"
                                stroke="#4B92F899"
                                fill="#4B92F899"
                            />
                            <Line type="monotone" dataKey="score" stroke="#ff7300" />
                        </ComposedChart>
                    </ResponsiveContainer>
                </Grid>
            </Grid>
        </SimpleCard>
    )
}
export default Statistics