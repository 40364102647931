import React, { useState, useEffect } from 'react';
import { Grid } from '@material-ui/core';
import ActionModalBar from '../../../../components_v1/Actions/ActionModalBar';
import SimpleModal from '../../../../components_v1/Structure/Modals/SimpleModal';
import DisplayText from '../../../../components_v1/Texts/DisplayText';
import InputFile from '../../../../components_v1/Forms/InputFile';
import { onGetErrorMessage, onGetFileExtension } from '../../../../shared/utility';

const DocumentModal = ({file, open, onClose, onSubmit, content, loading, error, onSetFile}) => {


    return ( 
        <SimpleModal open={open} onClose={onClose} maxWidth='sm'>
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <DisplayText variant='h6' color='primary'>{content.title}</DisplayText>
                </Grid>
                <Grid item xs={12}>
                    <InputFile id='file' file={file} label={content.label} 
                        onChange={(file) => onSetFile(file)} onDelete={() => onSetFile(null)}/>
                </Grid>
                <Grid item xs={12}>
                    <ActionModalBar btnLabel={content.button} loading={loading} error={error} onSubmit={onSubmit} />
                </Grid>
            </Grid>
        </SimpleModal>
     );
}
 
export default DocumentModal;