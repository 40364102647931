export const contentData = {
  spanish:{
    title:'Crear contacto',
    nodata:'Sin Registro',
    error:'Sin registro',
    button:'Agregar',
    cancelButton:'Cancelar',
    image_button:'Cambiar',
    form:{
      first_name:{
        label:'Nombre',
        placeholder:'Nombre',
      }, 
      last_name:{
        label:'Apellidos',
        placeholder:'Apellidos',
      },
      phone_number:{
        label:'Telefono',
        placeholder:'Telefono',
      }, 
      work_email:{
        label:'Correo',
        placeholder:'Correo',
      }
    },
  },
}