export const contentData = {
    spanish:{
        layout:{
            title:'Declaración Anual Física',
            navigation:[
                {name:'Clientes',path:'/clients'},
                {name:'Detalles',path:'/clients/details/:id'},
                {name:'Negocios',path:'/clients/details/:id/businesses'},
                {name:'Detalles',path:'/clients/details/:id/businesses/:idbusiness'},
                {name:'Servicio',path:'/clients/details/:id/businesses/:idbusiness/service/:idservice'},
            ],
            menu:[
                {id:0, label:'General'},
                {id:1, label:'Cuentas bancarias'},
                {id:2, label:'Logs'}
              ]
        },
        modals:{
            warning_delete_file:{
                title:'¿Estás seguro?',
                message:'¿Realmente quieres eliminar este registro? Este proceso no puede ser revertido',
                cancelButton:'Cancelar',
                deleteButton:'Eliminar'
            },
            edit_service:{
                title:'Editar servicio',
                button:'Guardar',
                cancelButton:'Cancelar'
            }
        },
        basicform:{
            title:'Información Básica',
            button:'Editar',
            nodata:'Sin Registro',
            data:{
                rfc:{
                    label:'',
                    data:''
                },
                sat_password:{
                    label:'',
                    data:''
                },
                tax_payment_limit_date:{
                    label:'',
                    data:''
                },
                business_fiscal_regime:{
                    label:'',
                    data:''
                }
            },
            content:{
                rfc:{
                    label:'RFC'
                },
                sat_password:{
                    label:'Contraseña SAT'
                },
                tax_payment_limit_date:{
                    label:'Fecha Límite de Pago Impuestos'
                },
                business_fiscal_regime:{
                    label:'Tipo de Contribuyente'
                }
            },
            documents:{
                title:'Documentos',
                header:{title:'Archivos', actions:'Acciones'},
                files:[
                    {key:'trial_balance_initial',name:'Balanza de Comprobación con Saldos Iniciales',path:'trialbalanceinitial', status:false, businessDocument: true},
                    {key:'financial_statements',name:'Últimos Estados Financieros ',path:'financialstatements', status:false, businessDocument: true},
                    {key:'customs_declaration',name:'Pedimentos de Importación',path:'customsdeclaration', status:false, businessDocument: true},
                    {key:'accounting_backup',name:'Respaldo de la Contabilidad',path:'accountingbackup', status:false, businessDocument: true},
                    {key:'foreign_expenses',name:'Gastos Extranjeros',path:'foreignexpenses', status:false, businessDocument: true},
                    {key:'expense_control',name:'Control de Gastos',path:'expensecontrol', status:false, businessDocument: true},
                    {key:'aux',name:'Auxiliares',path:'aux', status:false},
                    {key:'ada_table',name:'Tabla de Activos, Depreciaciones o Amortizaciones',path:'adatable', status:false, businessDocument: true},
                    {key:'tax_sheet',name:'Hoja de Impuestos',path:'taxsheet', status:false, businessDocument: true},
                    {key:'tax_loss',name:'Pérdidas Fiscales de ejercicios anteriores',path:'taxloss', status:false, businessDocument: true}
                ]
            }
        }
    }
}
