export const contentData = {
  spanish:{
    modals:{
      warning_delete_item:{
        title:'¿Estás seguro?',
        message:'¿Realmente quieres eliminar este registro? Este proceso no puede ser revertido',
        cancelButton:'Cancelar',
        deleteButton:'Eliminar'
      },
      edit_service:{
        title:'Editar servicio',
        button:'Guardar',
        cancelButton:'Cancelar'
      }
    },
    basicform:{
      title:'Información Detallada',
      button:'Editar',
      nodata:'Sin Registro',
      data:{
        rfc:{
          label:'',
          data:'',
        },
        name:{
          label:'',
          data:'',
        },
        start:{
          label:'',
          data:'',
        },
        end:{
          label:'',
          data:'',
        },
        total_time:{
          label:'',
          data:'',
        },
        quoted_time:{
          label:'',
          data:'',
        },
        variation:{
          label:'',
          data:'',
        },
        consulting_status:{
          label:'',
          data:'',
        },
        mod_type:{
          label:'',
          data:'',
        },
        mod_cost:{
          label:'',
          data:'',
        },
        consulting_progress:{
          label:'',
          data:'',
        },
        witness:{
          label:'',
          data:'',
        },
        note:{
          label:'',
          data:'',
        }
      },
      content:{
        rfc:{
          label:'RFC'
        },
        name:{
          label:'Nombre',
        },
        start:{
          label:'Fecha de inicio',
        },
        end:{
          label:'Fecha de fin',
        },
        total_time:{
          label:'Total de horas dedicadas',
        },
        quoted_time:{
          label:'Total de Horas cotizadas',
        },
        variation:{
          label:'Variacion',
        },
        consulting_status:{
          label:'Estatus',
        },
        mod_type:{
          label:'Tipo MOD',
        },
        mod_cost:{
          label:'Costo MOD',
        },
        consulting_progress:{
          label:'Progreso',
        },
        witness:{
          label:'Testigo',
        },
        note:{
          label:'Notas',
        }
      },
      documents:{
        title:'Documentos',
        header:{title:'Archivos', actions:'Acciones'},
        files:[
          {key:'witness_documents',name:'Testigo',path:'witnessdocuments', status:false},
          {key:'validation',name:'Validación del Jefe inmediato',path:'validation', status:false},
          {key:'note_documents',name:'Notas',path:'notedocuments', status:false},
        
        ]
      }
    }
  }
}