import React, {Component, useEffect, useState} from 'react'
import { withStyles } from '@material-ui/core/styles'
import { Grid} from '@material-ui/core'
import styles from './styles'
import {contentData} from './content'
import BasicView from '../../../../components_v1/Structure/Layouts/BasicView';
import ClientList from './ClientList/ClientList'
import WarningModal from '../../../../components_v1/Structure/Modals/WarningModal'
import {request_clients, request_delete_client, request_employees} from './requests'
import {cleanData2Table} from './customFunctions'
import { Redirect } from 'react-router'
import SimpleTable from './components/SimpleTable'
import queryString from 'query-string' 
import AddClientModal from './modals/AddClient/AddClientModal'

const Clients = (props) => {

  //Table states
  const [total, setTotal] = useState(0)
  const [tableFilter, setTableFilter] = useState({limit:10,offset:0, order_by:'id_client', order:1})
  const [search, setSearch] = useState(null)

  const {history, language, permissions, permissionsType} = props
  const [isloading, setIsloading] = useState(false)
  const [sending, setSending] = useState(false)
  const [success, setSuccess] = useState(null)
  const [error, setError] = useState(null)

  const [clients, setClients] = useState([])
  const [users, setUsers] = useState([])
  const [selected, setSelected] = useState(null)

  const [addclientmodal, setAddClientModal] = useState(false)
  const [warningdeletemodal, setWarningDeleteModal] = useState(false)

  const content = contentData[language]  

  let permission = permissions.find(el => el.id_permission_module === 2)
  permission = permission ? permission.id_permission_type : 1
  
  useEffect(() => { 
    const initModule = async() => {
      try{
        setIsloading(true)
        const args = queryString.parseUrl(history.location.search)
        const {limit, offset, order_by, order} = tableFilter
        let filterArgs = [
            {"field":"name","operator":"LIKE","value":`%${search?search:''}%`},
        ]
        filterArgs = encodeURIComponent(JSON.stringify(filterArgs))
        const param2send = {limit:limit, offset:offset*limit, order_by:order_by, order:order}
        let params = `?${queryString.stringify(param2send)}&filter=${filterArgs}` //&filter=${filterArgs}
        const _clients = await request_clients(params)
        setClients(_clients.clients)
        setTotal(_clients.count)

        let response = await request_employees()
        setUsers([response])
      }catch(error){
        console.log(error)
      }
      setIsloading(false)
    }

    initModule() 
  },[tableFilter])

  const onChangeSearch = (val) => {
    setSearch(val)
    //setTableFilter({...tableFilter, offset:0})
  }

  const onPreDeleteClient = (id) => {
    setSelected(id)
    setWarningDeleteModal(true)
  }

  const onDeleteClient = async() => {
    try {
      setSending(true)
      const {limit, offset, order_by, order} = tableFilter
      let filterArgs = [
        {"field":"name","operator":"LIKE","value":`%${search?search:''}%`},
    ]
      filterArgs = encodeURIComponent(JSON.stringify(filterArgs))
      const param2send = {limit:limit, offset:offset*limit, order_by:order_by, order:order}
      let params = `?${queryString.stringify(param2send)}&filter=${filterArgs}` //&filter=${filterArgs}
      let _clients = await request_delete_client(selected, params)
      setClients(_clients.clients)
      setTotal(_clients.count)
      setWarningDeleteModal(false)
      setSending(false)
      setSuccess(true)
    } catch (error) {
      console.log(error)
      setError('Hubo un error al intentar eliminar el usuario')
    }
    setSending(false)
  }

  return(
    <BasicView
      title={content.layout.title}
      success={success}
      error={error}
      sending={sending}
      onCloseSuccess={() => setSuccess(null)} 
      onCloseError={() => setError(null)}
    >
      <WarningModal open={warningdeletemodal} message={content.modals.warning_modal} onClose={() => setWarningDeleteModal(false)} onDelete={onDeleteClient}/>
      <AddClientModal open={addclientmodal} onClose={() => setAddClientModal(false)} history={history} language={language}/>
        <SimpleTable 
          search={search} 
          onChangeSearch={onChangeSearch} 
          onAddRegister={() => setAddClientModal(true)}
          onSelectedItem={(data) => history.push(`/clients/details/${data.id_client}`)}
          data={clients} 
          tableFilter={tableFilter}  
          onUpdateTableFilter={(data) => setTableFilter(data)}
          total={total} 
          loading={isloading}
          onRemoveItem={onPreDeleteClient}
        />
    </BasicView>
    
  )
}

export default withStyles(styles)(Clients)