export const contentData = {
  spanish:{
    layout:{
      title:'Editar Perfil',
      navigation:[
        {name:'Perfil',path:'/profile'},
        {name:'Editar',path:'/profile/edit'},

      ],
      menu2:[
        {id:0, label:'Mis datos'},
        {id:1, label:'Horario'},
      ],
    },
  }
}
