import { Avatar, Grid } from '@material-ui/core';
import React, { useState, useEffect } from 'react';
import RoundedButton from '../../../../../../components_v1/Actions/RoundedButton';
import SimpleCard from '../../../../../../components_v1/Structure/Cards/SimpleCard';
import VerticalData from '../../../../../../components_v1/Structure/DisplayData/VerticalData';
import { onGetDateFormat, onGetTimeDuration } from '../../../../../../shared/utility';
import nouser from '../../../../../../assets/nouser.png'
import StatusData from '../../../../../../components_v1/Structure/DisplayData/StatusData';
import { nodata } from '../../../../../../texts/esp/general';
import { red } from '@material-ui/core/colors';
import AvatarPicture from '../../../../../../components_v1/Structure/DisplayData/AvatarPicture';
import { PERMISSION_TYPES } from '../../../../../../variables/config';
import teamsIcon from '../../../../../../assets/icons/teams.png'
import asIcon from '../../../../../../assets/icons/as.PNG'
import DisplayText from '../../../../../../components_v1/Texts/DisplayText';

const ContactInfoForm = ({title, data, onEdit, permission}) => {

    let quotedHours = '0 h'
    let restricted
    if(data){
        quotedHours = onGetTimeDuration(data.monthly_quoted_time)
        restricted = data.restricted ? 'Negocio restringido' : null
    } 


    let can_edit = true
    let can_create = true
    let has_full_access = true
    if(permission){
        can_edit = permission > PERMISSION_TYPES.READ
        can_create = permission > PERMISSION_TYPES.EDIT
        has_full_access = permission > PERMISSION_TYPES.CREATE
    }

    return ( 
        <SimpleCard>
            <Grid container>
                <Grid item xs={12}>
                    <DisplayText color='primary' variant='subtitle1' >{title}</DisplayText>
                </Grid>
                <Grid item xs={12}>
                    <Grid container spacing={3}>
                        <Grid item xs={12} md={6}>
                            <VerticalData icon='phone' title='Teléfono' data={data ? data.phone_number : null}/>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <VerticalData icon='mail' title='Correo electrónico' data={data ? data.work_email : null}/>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <VerticalData icon='mail' title='Correo personal' data={data ? data.personal_email : null}/>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <VerticalData image={teamsIcon} title='Usuario Microsoft' data={data ? data.gmail : null}/>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <VerticalData image={asIcon} title='Usuario AS iViewer' data={data ? data.dropbox : null }/>
                        </Grid>
                        {can_edit ? <Grid item xs={12}>
                            <Grid container justify='flex-end'>
                                <Grid item>
                                    <RoundedButton onClick={onEdit}>Editar</RoundedButton>
                                </Grid>
                            </Grid>
                        </Grid> : null}

                    </Grid>
                </Grid>
            </Grid>
        </SimpleCard>
     );
}
 
export default ContactInfoForm