import React, {Component, useEffect, useState} from 'react'
import { withStyles } from '@material-ui/core/styles'
import { Grid } from '@material-ui/core'
import styles from './styles'
import {contentData} from './content'
import BasicView from '../../../../components_v1/Structure/Layouts/BasicView'
import GeneralView from './GeneralView/GeneralView'
import FileManagerModal from  '../../../../components/Modals/FileManagerModal/FileManagerModal'
import WarningModal from  '../../../../components/Modals/WarningModal'

import { RoundedButton } from '../../../../components/Buttons/GeneralButtons';
import FormAModal from '../../../../components/Modals/FormAModal'
import {contentData as addcontent} from './GeneralView/components/ContactInfoList/additem/content'
import {formData as adddata} from './GeneralView/components/ContactInfoList/additem/data'
import { Redirect } from 'react-router'
import { PERMISSION_MODULES } from '../../../../variables/config'
import EditClientModal from './GeneralView/modals/EditClient/EditClientModal'
import EditRegisterInfoModal from './GeneralView/modals/EditRegisterInfoModal/EditRegisterInfoModal'
import PictureModal from './GeneralView/modals/PictureModal/PictureModal'

const ClientView = ({history, language, catalogs, permissions, permissionsType, match}) => {

  const content = contentData[language]
  let permission = permissions.find(el => el.id_permission_module === 2)
  permission = permission ? permission.id_permission_type : 1
  let contract_permission = permissions.find(el => el.id_permission_module === PERMISSION_MODULES.CONTRACTS)
  let modalcontent = null

  let module_permission = null
  if(permissions && permissions.length) module_permission = permissions.find(el => el.permission_module_id === PERMISSION_MODULES.CLIENTS)

  const clientID = match.params.id
  const business_permission = permissions.find(e => e.id_permission_module == 3).id_permission_type

  return(
    <div>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <BasicView 
            history={history}
            title={content.layout.title}
            setReturn
          >
          <GeneralView 
            history={history}
            language={language}
            clientID={clientID}
            catalogs={catalogs}
            module_permission={module_permission}
            business_permission={business_permission}
            permissions={permissions}
            permission={permission}
            permissionsType={permissionsType}
            contract_permission={contract_permission}
          />
          </BasicView>
        </Grid>
      </Grid>
    </div>
  )
}

export default withStyles(styles)(ClientView)