import React from 'react';
import { Grid, Icon } from '@material-ui/core';
import { amber, grey } from '@material-ui/core/colors';
import DisplayText from '../../Texts/DisplayText';

const IconData = ({data, color, bold, variant, icon}) => {


    return ( 
        <Grid container alignItems='center' spacing={1} wrap='nowrap'>
            <Grid item><Icon style={{marginTop:0, fontSize:18, color: color ? color : 'black'}}>{icon}</Icon></Grid>
            <Grid item><DisplayText variant={bold ? 'subtitle1' : (variant ? variant : 'body1')}>{data}</DisplayText></Grid>
        </Grid>
    );
}
 
export default IconData;