import React, {Component} from 'react'
import { withStyles } from '@material-ui/core/styles'
import styles from './styles'
import {contentData} from './content'
import BasicView from '../../../../components/Layouts/BasicView/BasicView'
import GeneralView from './GeneralView/GeneralView'
import WarningModal from  '../../../../components/Modals/WarningModal'
import AddBusinessModal  from './AddBusinessModal/AddBusinessModal'
import {
  request_clientuser,
  request_businesses,
  request_update_client,
  request_add_business,
  request_delete_business
} from './requests'




class ClientuserView extends Component {

  state = {
    isloading:false,
    clientuser:null,
    businesses:[],
    modals:{
      filemodal:{open:false,issending:false,error:null,extra:null},
      add_business:{open:false,issending:false,error:null},
      warning_delete_business:{open:false, issending:false, error:null, id:null}
    },
    success:{open:false,timeout:2000,type:'success',message:'Successful action!'},
  }

  componentDidMount(){
    this.initModule()
  }

  onGetPageID = () => {
    return this.props.match.params.id
  }

  initModule = async() => {
    const {id, idclientuser} = this.props.match.params
    this.setState({isloading:true})
    let response = await request_clientuser(idclientuser)
    this.setState({clientuser:{...response}})
    response = await request_businesses(id)
    this.setState({businesses:[...response]})
    this.setState({isloading:false})
  }

  onEditClientuser = () => {
    this.props.history.push(this.props.history.location.pathname+'/edit')
  }

  onUpdateClientuser = async(data) => {
    try {
      
      const clientID = this.onGetPageID()
      this.setState({stepper:{issending:true}})
      let response = await request_update_client(clientID, data)
      this.setState({clientuser:{...response}})
      this.setState({stepper:{issending:false, error:null}})
      this.onActivateSuccessSnack()
    } catch (error) {
      this.setState({stepper:{issending:false}})
      if(error.response){
        this.setState({stepper:{issending:false, error:error.response.data.error.message}})
      }
    }

  }

  onChangeView = (id) => {
    this.setState({selected:id})
  }

  onReturn = () => {
    this.props.history.goBack()
  }

  onEditClientUser = async(data) => {
    const type = 'add_business'
    try {
      const {idclientuser} = this.props.match.params
      this.onEditModal(type,{issending:true})
      let response = await request_add_business(idclientuser, data)
      this.setState({clientuser:{...response}})
      this.onEditModal(type,{open:false, issending:false, error:null})
      this.onActivateSuccessSnack()
    } catch (error) {
      this.onEditModal(type,{issending:false})
      if(error.response){
        this.onEditModal(type,{error:error.response.data.error.message})
      }
    }
  }

  onDeleteBusiness = async(data) => {
    const type = 'warning_delete_business'
    try {
      const {idclientuser} = this.props.match.params
      this.onEditModal(type,{issending:true})
      let response = await request_delete_business(idclientuser, data)
      this.setState({clientuser:{...response}})
      this.onEditModal(type,{open:false, issending:false, error:null})
      this.onActivateSuccessSnack()
    } catch (error) {
      this.onEditModal(type,{issending:false})
      if(error.response){
        this.onEditModal(type,{error:error.response.data.error.message})
      }
    }
  }

  onPreDeleteUser = (id) => {
    const type = 'warning_delete_user'
    this.onEditModal(type, {open:true, id:id})
  }

  onCloseModal = (type) => {
    this.onEditModal(type, {open:false, issending:false, error:null})
  }

  onEditModal = (section, data) => {
    const {modals} = this.state;
    let tempModals = {...modals};
    tempModals[section]={...tempModals[section],...data};
    this.setState({modals:{...tempModals}})
  }

  onOpenFileModal = (section, filedata) => {
    const {modals} = this.state
    let tempModals = {...modals}
    tempModals[section].open=true
    if(filedata){
      tempModals[section].data = {...filedata} 
    }
    this.setState({modals:{...tempModals}})
  }

  onCloseSnackbar = (id) => {
    let temp = this.state[id]
    temp.open = false
    this.setState({[id]:{...temp}})
  }

  onActivateSuccessSnack = () => {
    const {success} = this.state
    let success_temp = {...success}
    success_temp.open = true
    this.setState({success:{...success_temp}})
  }

  render(){

    const {history, language, catalogs} = this.props
    const {stepper, users, clientuser, businesses, isloading, success, modals} = this.state
    const content = contentData[language]

    let modalcontent = null

    if(modals.warning_delete_business){
      modalcontent=(
        <WarningModal 
          data={modals.warning_delete_business}
          content={content.modals.warning_delete_business}
          onClose={() => this.onCloseModal('warning_delete_business')}
          onDelete={this.onDeleteBusiness}
        />
      )
    }

    if(modals.add_business.open){
      modalcontent = (
        <AddBusinessModal
          data={modals.add_business}
          content={content.modals.add_business}
          businesses={businesses}
          language={language}
          onClose={() => this.onCloseModal('add_business')}
          onSubmit={this.onEditClientUser}
        />
      )
    }


    return(
      <BasicView
        history={history}
        content={content.layout}
        onChange={this.onChangeView}
        isloading={isloading}
        returnBtn={true}
        onReturn={this.onReturn}
        success={success}
        onCloseSnackbar={this.onCloseSnackbar} 
      >
        {modalcontent}

        <GeneralView 
          history={history}
          language={language}
          catalogs={catalogs}
          clientuser={clientuser}
          businesses={businesses}
          users={users}
          stepperdata={stepper}
          onEdit={this.onEditClientuser}
          onAddBusiness={() => this.onOpenFileModal('add_business')}
          onDeleteItem={(data) => this.onOpenFileModal('warning_delete_business',data)}
          onChangeStep={this.onUpdateClientuser}
          
        />
      </BasicView>
    )
  }
}

export default withStyles(styles)(ClientuserView)