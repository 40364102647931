import React, { useState } from 'react'
import { Grid } from '@material-ui/core'
import BasicView from '../../../components_v1/Structure/Layouts/BasicView'
import ToolbarTable from './components/ToolbarTable'
import GeneralView from './views/GeneralView/GeneralView'
import Evaluations from './views/Evaluations/Evaluations'
import TimeReport from './views/TimeReport/TimeReport'
import { contentData } from './content'
import Bonus from './views/Bonus/Bonus'

const Profile = props => {

    const { history, language, catalogs, notifications, permissions, permissionsType, match } = props
    const [view, setView] = useState(1)
    const content = contentData[language]
    const pending = notifications && notifications.pending_personal_evaluations ? notifications.pending_personal_evaluations : 0
    let contentView = null

    const onChangeView = (id) => {
        const urlwithargs = `?view=${id}`
        window.history.replaceState(null, null, urlwithargs);
        setView(id)
    }

    switch (view) {
        case 1:
            contentView = (
                <GeneralView
                    history={history} language={language} catalogs={catalogs}
                    permissions={permissions}
                    permissionsType={permissionsType}
                />
            )
            break;
        case 2:
            contentView = (
                <TimeReport history={history} match={match} />
            )
            break;
        case 3:
            contentView = (
                <Evaluations history={history} language={language} match={match} />
            )
            break;
        case 4:
            contentView = (
                <Bonus history={history} language={language} match={match} />
            )
            break;   
    }

    const menuOptions = [
        { id: 1, label: 'Información principal', },
        { id: 2, label: 'Time Report' },
        { id: 3, label: 'Evaluaciones', number: pending },
        { id: 4, label: 'Bonos'}
    ]

    return (
        <BasicView
            history={history}
            title={content.layout.title}
            onChange={onChangeView}
            setReturn
        >
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <ToolbarTable selected={view} menuOptions={menuOptions} onChange={onChangeView} />
                </Grid>
                <Grid item xs={12}>
                    {contentView}
                </Grid>
            </Grid>
        </BasicView>
    )
}

export default Profile