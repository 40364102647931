
import React from 'react'
import { TextField, Grid, makeStyles, withStyles, Typography, InputAdornment, Icon } from '@material-ui/core'
import { grey, red } from '@material-ui/core/colors'
import Autocomplete from "@material-ui/lab/Autocomplete";
import DisplayText from '../Texts/DisplayText';
import StatusData from '../Structure/DisplayData/StatusData';
import { useState } from 'react';
import { useEffect } from 'react';


const CssTextField = withStyles(theme => ({
  root: {
    '& .MuiAutocomplete-inputRoot': {
      height: 40,
      textAlign: 'center',
      paddingRight: '0px !important'
    },
    '& input': {
      color: grey[900],
    },
    '& .MuiInput-underline': {
      '&:before': {
        border: 0
      },
      '&:hover': {
        border: 0
      },
    },
  },
}))(TextField);

const InputSelectStatus = props => {

  ///////////////////////////////////
  const { data, onChange, onInputChange, inputValue, onClose, disabled, color, statusColor } = props //onSearch
  const { isValid, isVisited, value, options, rules } = data;
  const { id, label, placeholder, helper } = data.config;

  ///////////////////  STATE //////////////////////
  const [selectedValue, setSelectedValue] = useState(null)
  const [selectedOptions, setSelectedOptions] = useState([])
  //////////////  FUNCTIONS  /////////////////

  const onInnerChange = (e, item) => {
    let temp = { ...data }
    if (item) {
      temp.value = item.value
      setSelectedValue(item)
      temp.isValid = true
    } else {
      temp.value = null
      temp.isValid = rules ? false : true
    }
    temp.isVisited = true
    onChange(temp)
  }

  useEffect(() => {
    if (data) {
      if (options)
        setSelectedOptions(options)
      if (value !== null && value !== undefined) {
        const singleEl = options.find(el => el.value === value)
        let _selectedValue = singleEl ? singleEl : null
        setSelectedValue(_selectedValue)
      }else 
      setSelectedValue(null)
    }
  }, [data])



  let inputlabel = null;
  if (label) inputlabel = <DisplayText variant='subtitle2' color={color ? color : (!isValid && isVisited ? 'error' : 'inherit')}>{label}</DisplayText>

  return (
    <div>
      {inputlabel}
      <Autocomplete
        popupIcon={<Icon>expand_more</Icon>}
        disableClearable
        disabled={data.disabled || disabled}
        value={selectedValue}
        options={selectedOptions}
        getOptionLabel={option => option.label}
        renderInput={params => {
          let icon = selectedValue && selectedValue.icon ? selectedValue.icon : 'brightness_1'
          params.InputProps.startAdornment = (<InputAdornment position="start"><Icon style={{ marginLeft: 8, fontSize: 14, color: selectedValue && selectedValue.color ? selectedValue.color : grey[300] }}>{icon}</Icon></InputAdornment>)
          //params.InputProps.endAdornment = 
          return (
            <CssTextField {...params}
              variant='standard'
              error={!isValid && isVisited}
              placeholder={placeholder}
              helperText={(!isValid && isVisited) ? helper : null}

            />
          )
        }}
        onChange={onInnerChange}
        onClose={onClose ? () => onClose(data) : undefined}
        inputValue={inputValue !== undefined ? inputValue : undefined}
        onInputChange={onInputChange}
        noOptionsText={<DisplayText>Sin opciones </DisplayText>}
        renderOption={(option, state) =>
          <div>
            <StatusData
              data={option.label}
              color={option.color}
              variant={state.selected ? 'subtitle1' : 'body1'}
              icon={option.icon ? option.icon : null}
            />
          </div>
        } />
    </div>

  )
}

export default InputSelectStatus