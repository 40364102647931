export const contentData = {
  spanish:{
    title:'Editar outsourcing',
    nodata:'Sin Registro',
    error:'Sin registro',
    button:'Guardar',
    cancelButton:'Cancelar',
    image_button:'Cambiar',
    form:{
      rfc:{
        label:'RFC',
        placeholder:'RFC'
      },
      name:{
        label:'Nombre',
        placeholder:'Nombre',
      }, 
      start:{
        label:'Fecha de inicio',
        placeholder:'Fecha de inicio',
      }, 
      end:{
        label:'Fecha de fin',
        placeholder:'Fecha de fin',
      }, 
      total_time:{
        label:'Total de horas dedicadas',
        placeholder:'Total de horas dedicadas',
      }, 
      quoted_time:{
        label:'Total de Horas cotizadas',
        placeholder:'Total de Horas cotizadas',
      }, 
      variation:{
        label:'Variacion',
        placeholder:'Variacion',
      }, 
      consulting_status_id:{
        label:'Estatus',
        placeholder:'Estatus',
      }, 
      mod_type_id:{
        label:'Tipo MOD',
        placeholder:'Tipo MOD',
      }, 
      mod_cost:{
        label:'Costo MOD',
        placeholder:'Costo MOD',
      }, 
      consulting_progress_id:{
        label:'Progreso',
        placeholder:'Progreso',
      }, 
      witness:{
        label:'Testigo',
        placeholder:'Testigo',
      }, 
      note:{
        label:'Notas',
        placeholder:'Notas',
      }, 
    },
  },
}