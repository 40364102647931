import React, {Component} from 'react'
import { Grid } from '@material-ui/core'
import moment from 'moment'

import EditCardWrapper from '../../Cards/EditCardWrapper'
import InputText from '../../Inputs/InputText/InputText'
import InputSimpleSelect from '../../Inputs/InputSimpleSelect/InputSimpleSelect'
import AvatarSelectInput from '../../Inputs/AvatarSelectInput/AvatarSelectInput'

const prepareData = (users) => {
  let usersData = [];

  if(users){
    usersData = users.map((item) => {
      let firstname = item.first_name ? item.first_name : '';
      let lastname = item.last_name ? item.last_name : ''; 
      return{
        value: item.id_user,
        label: firstname + ' ' + lastname,
        image: item.image
      }
    })
  }

  return usersData;
}

class FormA extends Component {

  state = {
    formData: {},
    isValid:false,
  }

  componentDidMount(){
    const {origin_data, catalogs, catalog_matrix, content, form_data} = this.props
    let temp = {...form_data}

    if(catalog_matrix){
      catalog_matrix.forEach(item => {
        if(item.catalog_key !== 'none'){
          temp[item.form_key].options = catalogs[item.catalog_key]
        }else{
          const aux_catalog = prepareData(this.props[item.propname])
          temp[item.form_key].options = aux_catalog
        }
        
      })
    }

    Object.keys(form_data).forEach(item => {
      temp[item].config = {...temp[item].config,...content.form[item]} // Init labels, placeholders, errors, helpers
      if(origin_data){ 
        let value = origin_data[item]
        if(temp[item].config.type === 'date'){
          value = value ? moment(value).format('YYYY-MM-DD') : null
        }
        if(temp[item].config.type === 'time'){
          value = value ? value : null
          value = value === 'Invalid date' ? null : value
        }
        temp[item].value = value ? value : ''
        temp[item].isValid = value ? true : false
      }else{ // When initialization is not necessary
        temp[item].value = temp[item].value ? temp[item].value : '' 
        temp[item].isValid = false
      }
    });


    const isValidForm = this.validationForm(temp);
    this.setState({formData:{...temp}, isValid: isValidForm})

  }

  onInputChange = (data) => {
    const {formData} = this.state;
    const {error_details} = this.props
    const id = data.config.id;    
    let temp = {...formData};
    temp[id] = {...data};
    const isValidForm = this.validationForm(temp);
    this.setState({formData:{...temp}, isValid: isValidForm})

    if(error_details){
      let isErrorDetails = Object.keys(error_details).filter(el => el === id)
      if(isErrorDetails){
        this.props.onDeleteError(id)
      }
    }
    
  }

  validationForm = (data) => {
    let isValid = true;
    Object.keys(data).forEach((item) => {
      if(data[item].isRequired && !data[item].isValid){
        isValid = false;
      }
    })
    return isValid;
  }

  onSubmit = () => {
    const {onSubmit, origin_data} = this.props;
    const {formData} = this.state;
    let data2Send = {};

    if(origin_data){
      Object.keys(formData).forEach((item)=>{
        if(formData[item].isValid){
          if(formData[item].value !== origin_data[item]){
            data2Send = {...data2Send, [item]:formData[item].value}
          }
        }
      })
    }else{
      Object.keys(formData).forEach((item)=>{
        if(formData[item].isValid){
            data2Send = {...data2Send, [item]:formData[item].value}
        }
      })
    }
    
    
    onSubmit(data2Send)
  }

  render(){

    const {issending, error, content, error_details} = this.props
    const {formData} = this.state

    let r_formdata = JSON.parse(JSON.stringify(formData))

    if(error_details){
      let temp_details = {...error_details}
      Object.keys(temp_details).map(item => {
        if(r_formdata[item]){
          r_formdata[item].error = true
          r_formdata[item].isValid = false
          r_formdata[item].isVisited = true
        }
      })
    }

    let contentA = null

    contentA = Object.keys(r_formdata).map((item,key)=> {

      let inputContent = null
      switch (r_formdata[item].config.type) {
        case 'simple_select':
          inputContent = (
            <InputSimpleSelect
              wrap='vertical'
              data={r_formdata[item]}
              onChange={this.onInputChange}
            />
          )
          break;
        case 'avatar_select':
          inputContent = (
            <AvatarSelectInput
              wrap='vertical'
              data={r_formdata[item]}
              onChange={this.onInputChange}
            />
          )
          break
        default:
          inputContent = (
            <InputText
              data={r_formdata[item]}
              onChange={this.onInputChange}
            />
          )
          break;
      }
      return(
        <Grid item xs={12} md={6} key={key.toString()}>
          {inputContent}
        </Grid>
      )
    })
    
    return(
      <EditCardWrapper
        title={content.title}
        button={content.button}
        onSubmit={this.onSubmit}
        issending={issending}
        error={error}
      >
          <Grid container spacing={3}>
            {contentA}
          </Grid>
      </EditCardWrapper>
    )
  }
}

export default FormA