export const contentData = {
  spanish:{
    title:'Agregar cliente',
    nodata:'Sin Registro',
    error:'Sin registro',
    button:'Agregar',
    form:{
      name:{
        label:'Nombre completo',
        placeholder:'Nombre completo',
        helper:'Nombre no válido'
      },
      pre_position_id:{
        label:'Puesto',
        placeholder:'Puesto',
      },
      pre_language_id:{
        label:'Idioma',
        placeholder:'Idioma',
      },
      pre_area_id:{
        label:'Área',
        placeholder:'Área',
      },
      pre_country_id:{
        label:'País',
        placeholder:'País',
      },
      client_status_id:{
        label:'Estatus',
        placeholder:'Estatus',
      },
      client_state_id:{
        label:'Estado del proceso',
        placeholder:'Estado del proceso',
      },
      pre_contact_name:{
        label:'Nombre de contacto',
        placeholder:'Nombre de contacto',
        helper:'Nombre de contacto no válido'
      },
      pre_responsible_id:{
        label:'Responsable previo',
        placeholder:'Responsable previo',
        helper:'Seleccione un responsable previo'
      },
      responsible_id:{
        label:'Responsable',
        placeholder:'Responsable',
        helper:'Seleccione un responsable'
      },
    },
  },
}