import React, {Component} from 'react'
import { withStyles } from '@material-ui/core/styles'
import { Grid} from '@material-ui/core'
import { grey } from '@material-ui/core/colors'
import moment from 'moment'
import {DataItemWrapper} from '../../../../../components/DisplayData/DisplayData'
import ProfileAvatar from '../../../../../components/Avatars/ProfileAvatar'
import BusinessTable from '../../../../../components/Tables/BusinessTable'
import ViewCardWrapper from '../../../../../components/Cards/ViewCardWrapper'

import {formData} from './data'
import {contentData} from './content'
import { onGetResponsibleObj } from '../../../../../shared/utility'

const styles = (theme) => ({
  root:{padding:'32px'},
  subtitle:{color:theme.palette.primary.main, fontWeight:'500'},
  nospeciality:{color:grey[700], fontWeight:'500'}
})

const findResponsible  = (id, users, nodata) => {
  if(id !== null && id !== undefined){
    if(users.length > 0){
      let temp = users.filter(el => el.id_user === id)
      return JSON.parse(JSON.stringify(temp[0]))
    }
  }
  return {
    first_name: nodata.split(' ')[0], 
    last_name:nodata.split(' ')[1],
    image:"0"
  }
}



class GeneralView extends Component {

  updateForm = (data, user, content) => {
    let form = {}
    Object.keys(data).forEach((item,key)=> {
      let value = null
      if(user){
        value = user[item]
        if(value){
          if(item === 'mobile'){
            let aux =  user.phone_code ? user.phone_code : ''
            value = aux + ' ' + value 
          }
          if(item === 'pre_contact_date' || item === 'pre_required_date' || item === 'pre_end_date')
            if(value){
              value = moment(value).format('YYYY-MM-DD')
            }
            
          if(item === 'contract_duration'){
            value = value.toString() + ' '+ user.contract_duration_type
          }
        }
        
      }
      const aux = {...data[item], ...content[item], data:value}
      form = {...form, [item]:{...aux}}
    })
    return form
  }

  onChange = (stepID) => {
    this.props.onChangeStep({step_id: stepID})
  }

  onFilterEnabledBusinesses = (clientuser, businesses) => {
    let temp = []

    if(clientuser){
      clientuser.permissions.forEach(item => {
        if(item.access === 1){
          const business_name = businesses.filter(el => el.id_business === item.business_id)
          if(business_name.length > 0 ){
            temp.push({
              ...item,
              name:business_name[0].name
            })
          }
        }
      })
    }
    return temp
  } 

  render(){

    const {language, users, businesses, clientuser, onEdit, onAddBusiness, onDeleteItem} = this.props
    const content = contentData[language]

    let formA = {...formData.sectionA}
    let formB = {...formData.sectionB}
    let businesses_enabled = this.onFilterEnabledBusinesses(clientuser, businesses)

    let contentA = null
    let contentB = null
    let avatarContent = null

 
    formA = this.updateForm(formA, clientuser, content.sectionA)
    formB = this.updateForm(formB, clientuser, content.sectionB)

    contentA = Object.keys(formA).map((item,key)=> {
      if(item !== 'responsible' && item !== 'pre_responsible'){
        return(
          <Grid item xs={12} md={6}  key={key.toString()}>
            <DataItemWrapper 
              label={formA[item].label}
              data={formA[item].data}
              error={content.error}
              icon={formA[item].icon}
            />
          </Grid>
        )
      }else{
        const user_key = item+'_id'
        let responsible = onGetResponsibleObj(clientuser ? clientuser[user_key] : null, users)
        //let responsible = onGetResponsibleObjclientuser ? clientuser[user_key] : null,users,content.nodata)
        console.log(responsible)
        return(
          <Grid item xs={12} md={6}  key={key.toString()}>
            <DataItemWrapper 
              label={formA[item].label}
              avatar={responsible}
              error={content.error}
            />
          </Grid>
        )
      }
    })
    contentB = Object.keys(formB).map((item,key)=> {
      if(item !== 'responsible'){
        return(
          <Grid item xs={12} md={6}  key={key.toString()}>
            <DataItemWrapper 
              label={formB[item].label}
              data={formB[item].data}
              error={content.error}
              icon={formB[item].icon}
            />
          </Grid>
        )
      }else{
        let responsible = onGetResponsibleObj(clientuser ? clientuser.pre_responsible_id : null, users)
        //let responsible = onGetResponsibleObjclientuser ? clientuser.pre_responsible_id : null,users,content.nodata)
        return(
          <Grid item xs={12} md={6}  key={key.toString()}>
            <DataItemWrapper 
              label={formB[item].label}
              avatar={responsible}
              error={content.error}
            />
          </Grid>
        )
      }
      
    })
    
    avatarContent = <ProfileAvatar image={clientuser ? clientuser.image : null}/>
    
    

    return(
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <ViewCardWrapper
            title={null}
            button={content.edit}
            onClick={onEdit}
          >
            <Grid container spacing={4}>
              <Grid item xs={12} md={12} lg={6}>
                <Grid container justify='center'>
                  <Grid item>{avatarContent}</Grid>
                </Grid>
              </Grid>
              <Grid item xs={12} md={12} lg={6}>
                <Grid container spacing={3}>
                  {contentA}
                </Grid>
              </Grid>
            </Grid>
          </ViewCardWrapper>
        </Grid>
        <Grid item xs={12}>
          <Grid container spacing={4}>
            <Grid item xs={12}>
              <ViewCardWrapper
                title={content.title.sectionB}
                button={content.edit}
                onClick={onEdit}
              >
                <Grid container spacing={3}>
                  {contentB}
                </Grid>
              </ViewCardWrapper>
            </Grid>
          </Grid>
        </Grid>
        {/* *********** THIRD SECTION *************** */}
        <Grid item xs={12}>
          <Grid container spacing={4}>
            <Grid item xs={12}>
              <ViewCardWrapper
                title={content.title.sectionD}
                button={content.add}
                onClick={onAddBusiness}
              >
                <BusinessTable 
                  language={language}
                  businesses={businesses_enabled}
                  onDelete={onDeleteItem}
                />
              </ViewCardWrapper>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      
    )
  }
}

export default withStyles(styles)(GeneralView)