import React from 'react';
import { Grid, Icon } from '@material-ui/core';
import DisplayText from '../../Texts/DisplayText';
import { nodata } from '../../../texts/esp/general';
import { blue } from '@material-ui/core/colors';

const VerticalData = ({title, icon,image, data, size}) => {
    return ( 
        <Grid container spacing={1}>
            <Grid item xs={12}>
                <DisplayText variant='body2' color='textSecondary'>{title}</DisplayText>
            </Grid>
            <Grid item xs={12}>
                <Grid container spacing={1}>
                    {icon ?
                        <Grid item>
                            <Icon style={{color:blue[900]}}>{icon}</Icon>
                        </Grid>
                    :null}
                    {image ?
                        <Grid item>
                            <img src={image} style={{width:24, height:24}}/>
                        </Grid>
                    :null}
                    <Grid item xs>
                        <DisplayText variant='body2'>{data ? data : nodata}</DisplayText>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
     );
}
 
export default VerticalData;