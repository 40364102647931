import React, { useEffect, useRef } from 'react';
import { Box, Container, Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import AISidebar from '../../components_v1/aichat/AISidebar';
import InputChat from '../../components_v1/aichat/InputChat';
import useAIChatView from './useAIChatView';
import WelcomeMessage from '../../components_v1/aichat/WelcomeMessage';
import ChatConvesartion from '../../components_v1/aichat/ChatConversation';
import { SnackbarProvider } from '../../providers/snackbar/SnackbarContext';

const AIChatView = () => {
    return(
        <SnackbarProvider>
            <_AIChatView />
        </SnackbarProvider>
    )
}

const _AIChatView = () => {

    const classes = useStyles()
    const {busy, chat, messages, chats, onSelectChat, onPostMessage, onGenerateNewChat, onRetry} = useAIChatView()
    const chatRef = useRef()

    useEffect(() => {
        console.log("Hacer scroll hacia abajo", chatRef.current)

        if(chatRef){
            const height = chatRef.current.scrollHeight
            chatRef.current.scrollTo({
                top:height,
                behavior:'smooth'
            })
        }
    }, [messages])

    return ( 
        <div className={classes.root}>
            <AISidebar
                chats={chats}
                chat={chat}
                onSelectChat={onSelectChat}
                onGenerateNewChat={onGenerateNewChat}
            />
                <Grid container>
                    <Grid item xs={12}>
                        <div className={classes.chat_content} ref={chatRef}>
                            <Container maxWidth='md'>
                                <WelcomeMessage />
                                <ChatConvesartion 
                                    busy={busy}
                                    messages={messages}
                                    onRetry={onRetry}
                                />
                            </Container>
                        </div>
                    </Grid>
                    <Grid item xs={12}>
                        <Container maxWidth='md'>
                            <InputChat chats={chats} onSearch={onPostMessage} />
                        </Container>
                    </Grid>
                </Grid>
                
                
            
        </div>
     );
}

const useStyles = makeStyles(theme => ({
    root:{
        display:'flex',
        flexDirection:'row'
    },
    chat_content:{
        height:'calc(100vh - 150px)',
        ///border:'1px solid grey',
        marginTop:32,
        marginBottom:16,
        overflowY:'auto'
    }
}))
 
export default AIChatView;