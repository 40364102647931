export const contentData = {
    spanish: {
        title: 'Evaluaciones',
        button: null,
        nodata: 'Sin Registro',
        filter: [
            { value: 1, label: 'Histórico' },
            { value: 2, label: 'Estadísticas' }
        ],
        header: [
            { id: 1, label: 'ID' },
            { id: 3, label: 'Periodo' },
            { id: 4, label: 'Evaluador' },
            { id: 5, label: 'Estatus' },
            { id: 6, label: 'Puntaje' },
            { id: 7, label: 'Fecha de evaluación' },
            { id: 8, label: 'Documento' },
        ],
        charts:{
            results:{
                id:'results_total',
                label:'Obtención de resultados'
            },
            quality:{
                id:'quality_total',
                label:'Calidad'
            },
            relations:{
                id:'relations_total',
                label:'Relaciones interpersonales'
            },
            iniciative:{
                id:'iniciative_total',
                label:'Iniciativa'
            },
            organization:{
                id:'organization_total',
                label:'Organización'
            },
            total:{
                id:'total',
                label:'Puntaje Final'
            }
        }
    }
}
