export const contentData = {
    spanish:{
        title:'Información Basica',
        nodata:'Sin Registro',
        error:'Sin registro',
        button:'Guardar',
        cancelButton:'Cancelar',
        image_button:'Cambiar',
        form:{
            rfc:{
                label:'RFC',
                placeholder:'RFC'
            },
            vulnerable_activity:{
                label:'Actividad Vulnerable',
                placeholder:'Actividad Vulnerable'
            },
            compliance_officer:{
                label:'Oficial de Cumplimiento',
                placeholder:'Oficial de Cumplimiento'
            },
            report_limit_date:{
                label:'Fecha Límite de Reportes de Envío',
                placeholder:'Fecha Límite de Reportes de Envío'
            },
            responsible_id:{
                label:'Responsable',
                placeholder:'Responsable'
            }
        }
    }
};
