import React from 'react';
import { Card, Grid, LinearProgress, makeStyles, Table, TableBody, TableContainer } from '@material-ui/core';
import { grey } from '@material-ui/core/colors';
import DisplayText from '../../../Texts/DisplayText';
import TCell from './components/TCell';
import THeader from './components/THeader';
import TBody from './components/TBody';
import TActionBar from './components/TActionBar';
import FilterDate from './components/FilterDate';
import FilterButton from './components/FilterButton';
import SearchBar from '../../../../views/Businesses/Businesses/components/SearchBar';
import AddFilterButton from './components/AddFilterButton';
import SingleFilterButton from './components/SingleFilterButton';

const useStyles = makeStyles(theme => ({
    table:{

    },
    header:{

    },
    container:{
        overflowX:'auto',
        paddingBottom:0
    },
    footer:{

    },
    actions:{
        marginTop:8
    },
    toolbar:{
        marginBottom:16
    },
    table_container:{
        maxHeight:`calc(100vh - 300px )`
    }
    
}))

const GenericControlTable = ({children, header, data, dataConfig, onEditItem, onSaveItem, loading, permission, onAddFilter, onRemoveFilter,
editable, selectedItem, idRef, form, onInputChange, total, tableFilter, onUpdateTableFilter, responsible, business, disabledDate,
startDate, endDate, onChangeDate, search, onChangeSearch, onVisibleClick, onDownloadDocument, onUploadDocument, onDeleteDocument, date_format}) => {

    const classes = useStyles()

    const onChangeOrder = (identifier) => {
        let new_order
        if(tableFilter.order === 1){
            new_order = -1 
        }else{
            new_order = tableFilter.order + 1
        }
        if(tableFilter.order_by !== identifier) new_order = 0
        onUpdateTableFilter({...tableFilter, order:new_order, order_by:identifier})
    }

    return ( 
        <>
            <div className={classes.toolbar}>
                <Grid container spacing={2} > 
                    <Grid item xs={12}>
                        <Grid container wrap='nowrap' spacing={2} alignItems='flex-end'>
                            {!disabledDate ? <Grid item><FilterDate id='start_date' label='Fecha inicial' type={date_format} value={startDate} onChange={onChangeDate}/></Grid> : null}
                            {/*<Grid item><FilterButton label='Estatus' options={statusCatalog} selected={status} onChange={(val) => onChangeFilter(val,'status')}/></Grid>*/}
                            
                            {business ? <Grid item><SingleFilterButton label={business.label} id='business' onRemoveFilter={onRemoveFilter}/></Grid> : null}
                            {responsible ? <Grid item><SingleFilterButton label={responsible.label} id='responsible' onRemoveFilter={onRemoveFilter}/></Grid> : null}
                            <Grid item xs/>
                            {/*onChangeSearch ? <Grid item><SearchBar value={search} onChange={onChangeSearch}/></Grid>:null*/}
                            <Grid item><AddFilterButton onChange={onAddFilter}/></Grid>
                        </Grid>
                    </Grid>
                    
                </Grid>
            </div>
            {loading ? (
                <LinearProgress />
            ) : null}
            <Card>
                <div className={classes.container}>
                    <TableContainer className={classes.table_container}>
                        <Table stickyHeader >
                        <THeader data={header} onVisibleClick={onVisibleClick} order={tableFilter ? tableFilter.order : null} orderby={tableFilter ? tableFilter.order_by : null} onChangeOrder={onChangeOrder}/>
                        <TBody data={data} dataConfig={dataConfig} onEditItem={onEditItem} onSaveItem={onSaveItem} onUploadDocument={onUploadDocument} onDownloadDocument={onDownloadDocument} onDeleteDocument={onDeleteDocument}
                        editable={editable} selectedItem={selectedItem} idRef={idRef} form={form} onInputChange={onInputChange} permission={permission}/>
                        </Table>
                    </TableContainer>
                    
                    
                </div>
            </Card>
            {data && data.length ? <div className={classes.actions}>
                <TActionBar total={total} tableFilter={tableFilter} onUpdateTableFilter={onUpdateTableFilter}/>
            </div> : null}
        </>
        
     );
}
 
export default GenericControlTable;