export const contentData = {
  spanish:{
    title:'Información de Contacto',
    nodata:'Sin Registro',
    error:'Sin registro',
    button:'Guardar',
    image_button:'Cambiar',
    form:{
      pre_office_phone_number:{
        label:'Teléfono de Oficina',
        placeholder:'Teléfono de Oficina',
      },
      pre_cellphone:{
        label:'Celular',
        placeholder:'Celular',
      },
      pre_phone_number:{
        label:'Número telefónico',
        placeholder:'Número telefónico',
      },
      pre_work_email:{
        label:'Email de trabajo',
        placeholder:'Email de trabajo',
      },
      pre_alternative_email:{
        label:'Email alternativo',
        placeholder:'Email alternativo',
      },
      pre_personal_email:{
        label:'Email personal',
        placeholder:'Email personal',
      }
    },
  },
}