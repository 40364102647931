import { useCallback, useState } from "react"

const useTableSettings = ({
    initial_filter = {},
    initial_params = {}
}) => {

    const [filter, setFilter] = useState(initial_filter)
    const [pagination, setPagination] = useState(initial_params)

    const onUpdateFilterValue = useCallback((key, value) => {
        let temp = {...filter}
        temp[key].value = value
        setFilter(temp)
    }, [])

    const onUpdatePaginationValue = useCallback((key, value) => {
        let temp = {...pagination}
        temp[key] = value
        setPagination(temp)
    }, [])

    const onResetFilter = useCallback(() => {
        setFilter(initial_filter)
    }, [filter, initial_filter])

    const onResetPagination = useCallback(() => {
        setPagination(initial_params)
    }, [filter, initial_params])

    return {
        filter, 
        pagination,
        onUpdateFilterValue,
        onUpdatePaginationValue,
        onResetFilter,
        onResetPagination,
        setPagination
    }

}

export default useTableSettings