import React, {Component} from 'react'
import { withStyles } from '@material-ui/core/styles';
import { css } from 'glamor'
import { Typography, AppBar, Tabs, Tab, Box } from '@material-ui/core';
import BasicView from '../../../../../components/Layouts/BasicView/BasicView';
import WarningModal from '../../../../../components/Modals/WarningModal';
import FileManagerModal from '../../../../../components/Modals/FileManagerModal/FileManagerModal';
import {contentData} from './content'
import ContentText from '../../../../../components/Texts/ContentText';

function TabPanel(props) {
    const { children, value, index, ...other } = props;
  
    return (
      <Typography
        component="div"
        role="tabpanel"
        hidden={value !== index}
        id={`scrollable-auto-tabpanel-${index}`}
        aria-labelledby={`scrollable-auto-tab-${index}`}
        {...other}
      >
        {value === index && <Box p={3}>{children}</Box>}
      </Typography>
    );
  
}

const hidden = css({
    '>div>div>div>div>div:first-child':{
        display:'none'
    }
})

const styles = {
    card:{
        margin: '20px',
        padding: '64px',
        background: 'white'
    },
}

const data2View = (data, permissions, permissionsType) => {
    let newData = [...data]

    newData.map((table, key) => {
        let permission = permissions[key]
        table.status = permission.id_permission_type >= permissionsType.READ
    })

    return newData
}

class ASAdvisory extends Component{

    state = {
        value:null,
        tabs:null,
        isloading:false,
        modals:{
            filemodal:{open:false,issending:false,error:null,extra:null},
        },
        success:{open:false,timeout:2000,type:'success',message:'Successful action!'},
    }

    componentDidMount = () => {
        const {language, permissions, permissionsType} = this.props
        const content = contentData[language]
        const {control_tables} = content
        const controlTables = data2View(control_tables, permissions, permissionsType)
        let flag = true 

        let tabs = controlTables.map((item, key) => {
            if(item.status && flag){
                this.setState({value: key})
                flag = false
            }

            return (item.status ? <Tab value={key} label={item.name}/> : null)
        })
        
        this.setState({tabs:tabs})
    }

    handleChange = (event,value) => {
        this.setState({value: value})
    }

    onReturn = () => {
        this.props.history.goBack()
    }

    render(){
        const {classes, history, language, catalogs, permissions, 
            permissionsType} = this.props
        const {tabs, value, success, isloading, modals} = this.state
            
        const content = contentData[language]
        const {control_tables} = content
        const controlTables = data2View(control_tables, permissions, permissionsType)

        let modalcontent = null
        if(modals.warning_delete_file){
            modalcontent=(
                <WarningModal
                data={modals.warning_delete_file}
                content={content.modals.warning_delete_file}
                onClose={() => this.onCloseModal('warning_delete_file')}
                onDelete={this.onDeleteFile}
                />
            )
        }

        if(modals.filemodal.open){
            modalcontent = (
                <FileManagerModal
                data={modals.filemodal}
                open={true}
                language={language}
                onClose={() => this.onCloseModal('filemodal')}
                onSubmit={this.onSubmitFile}
                onDelete={this.onDeleteFile}
                />
            )
        }

        const tableProps = { history,language,catalogs, permissionsType}
        console.log(value)
        const contentView = (
            <div {...hidden}>
                <AppBar position="static" color="default">
                    <Tabs
                    value={value}
                    onChange={this.handleChange}
                    indicatorColor="primary"
                    textColor="primary"
                    variant="scrollable"
                    scrollButtons="auto"
                    aria-label="scrollable auto tabs example"
                    >
                        {tabs}
                    </Tabs>
                </AppBar>
                {controlTables.map((item,key) => {
                    let tableProp = {...tableProps, permission:permissions[key].id_permission_type}
                    return item.status ? (
                        <TabPanel value={value} index={key}>
                            {React.cloneElement(item.component,{...tableProp})}
                        </TabPanel> 
                    ) : null
                })}
            </div>
        )

        return(
            <BasicView
            history={history}
            content={content.layout}
            onChange={this.onChangeView}
            isloading={isloading}
            returnBtn={true}
            onReturn={this.onReturn}
            success={success}
            onCloseSnackbar={this.onCloseSnackbar}
            >
                {modalcontent}
                <div>
                {value ? contentView :
                    <div className={classes.card}>
                        <ContentText text={content.nocontent} medium align='center'/>
                    </div>
                }
                </div>
            </BasicView>
        )
    }
}

export default withStyles(styles)(ASAdvisory)