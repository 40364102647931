import React, {Component} from 'react'
import { withStyles } from '@material-ui/core/styles'
import {
  Stepper,
  Step,
  StepLabel,
  StepContent,
  Grid,
} from '@material-ui/core'
import { RoundedButton } from '../../../../../../components/Buttons/GeneralButtons';
import DisplayText from '../../../../../../components_v1/Texts/DisplayText';
import SimpleCard from '../../../../../../components_v1/Structure/Cards/SimpleCard';

const styles = (theme) => ({

})

const contentData = {
  spanish:{
    next:'Siguiente',
    back:'Atrás',
    finish:'Finalizar'
  },
  english:{
    next:'Next',
    back:'back',
    finish:'Finish'
  }
}

class ProspectStepper extends Component {
  render(){

    const {classes, title, step, steps, level, language, onChange} = this.props
    const content = contentData[language]

    


    let stepContent = null
    if(steps){
      stepContent = steps.map((item,key)=>{
        let stepsContent = (
          <StepContent>
            <Grid container spacing={1}>
              <Grid item>
                <RoundedButton
                  disabled={key===0 || key===1}
                  label={content.back}
                  color='transparent'
                  size='small'
                  onClick={() => onChange(item.value-1)}
                />
              </Grid>   
              <Grid item>
                <RoundedButton
                  disabled={key===steps.length-1}
                  label={content.next}
                  color='secondary'
                  size='small'
                  onClick={() => onChange(item.value+1)}
                />
              </Grid>            
            </Grid>
          </StepContent>
        )
        if(level){
          if(level<3){
            stepsContent=null
          }
        }

        return(
          <Step key={key.toString()}>
            <StepLabel>{item.label}</StepLabel>
            {stepsContent}
          </Step>
        )
      })
    }

    return(
      <SimpleCard>
        <DisplayText color='primary' variant='subtitle1'>{title}</DisplayText>
        <Stepper
          activeStep={step-1}
          orientation='vertical'
        >
          {stepContent}
        </Stepper>
      </SimpleCard>
    )
  }
}

export default withStyles(styles)(ProspectStepper)
