import moment from 'moment-timezone'
import 'moment/locale/es'
import { ERROR_CODES } from '../texts/esp/errorCodes';

export const updateObject = (oldObject, updatedProperties) => {
    return {
        ...oldObject,
        ...updatedProperties
    };
};

export const onTruncateText = (input, length) => input.length > length ? `${input.substring(0, length - 3)}...` : input;

export const isValidDocument = (filename) => filename && filename !== "0" && filename !== 0 && filename !== ""

export const onGetFormatNumber = (number) => {
    let result = ''
    if (!number) return '$0'
    let temp = parseFloat(number).toFixed(0)
    result = temp.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    result = `$ ${result}`
    return result
}

export const isFormValid = (form, keys) => {
    let errors = []
    if (form) {
        if (keys) {
            keys.forEach(item => {
                if ((form[item].isRequired || form[item].isVisited) && !form[item].isValid) errors.push(item)
            })
        } else {
            Object.keys(form).forEach(item => {
                if ((form[item].isRequired || form[item].isVisited) && !form[item].isValid) errors.push(item)
            })
        }
    }
    return errors
}

export const onSetErrorsToForm = (form, errors) => {
    let temp = { ...form }
    if (errors && errors.length) errors.forEach(item => temp[item].isVisited = true)
    return temp
}

export const onGetFormDataProcess = (form, step) => {
    let temp = {}
    let temp2 = {}
    if (form) {
        Object.keys(form).forEach(item => {
            if (form[item].isValid && form[item].isVisited) {
                if (form[item].ctrlpath)
                    temp2 = { ...temp2, [item]: form[item].value }
                else
                    temp = { ...temp, [`${item}_${step}`]: form[item].value }
            }
        })
    }
    return { data: { ...temp }, ctrlData: { ...temp2 } }
}

export const onGetFormData = (form) => {
    let temp = {}
    if (form) {
        Object.keys(form).forEach(item => {
            if (form[item].isValid) temp = { ...temp, [item]: form[item].value }
        })
    }
    return temp
}

export const onGetFormDataNew = (form, keys) => {
    let temp = {}
    if (form) {
        if (keys) {
            keys.forEach(item => {
                let { id } = form[item].config
                if (form[item].isValid) temp = { ...temp, [id]: form[item].value }
            })
        } else {
            Object.keys(form).forEach(item => {
                let { id } = form[item].config
                if (form[item].isValid) temp = { ...temp, [id]: form[item].value }
            })
        }

    }
    return temp
}

export const onCleanData = (data) => {
    if(data === undefined || data === null) return undefined
    if(data === "") return "@null!!"
    return data
}

//before
/* export const onGetSelectedFormData = (form, origin) => {
    let temp = {}
    if (form) {
        Object.keys(form).forEach(item => {
            if (form[item].isValid && form[item].value !== origin[item]) temp = { ...temp, [item]: form[item].value }
        })
    }
    return temp
} */

export const onGetSelectedFormData = (form, origin) => {
    let temp = {}
    if(form){
        Object.keys(form).forEach(item => {
            if(!origin){
                temp = {...temp, [item]: onCleanData(form[item].value)}
            }else{
                if(form[item].isValid && form[item].value !== origin[item]) temp = {...temp, [item]:onCleanData(form[item].value)}
            }
        })
    }
    return temp
}

export const onGetSelectedFormDataNew = (form, origin) => {
    let temp = {}
    if (form) {
        Object.keys(form).forEach(item => {
            let { id } = form[item].config
            if (form[item].isValid && form[item].value !== origin[item]) temp = { ...temp, [id]: form[item].value }
        })
    }
    return temp
}

export const onInitFormProcess = (form, origin, step, translations) => {
    let response = { ...form }
    if (!origin) return response
    Object.keys(form).forEach(item => {
        const value = origin[`${step}_${item}`]
        if ((value || value === 0) && value !== "") response[item] = { ...response[item], value: value, isVisited: true, isValid: true }
        if (translations && translations[item]) response[item].config = { ...response[item].config, ...translations[item] }
    })
    return response
}

export const onInitForm = (form, origin, translations) => {
    let response = { ...form }
    if (!origin) return response
    Object.keys(form).forEach(item => {
        const value = origin[item]
        if (value !== null && value !== undefined && value !== "") response[item] = { ...response[item], value: value, isVisited: true, isValid: true }
        if (translations && translations[item]) response[item].config = { ...response[item].config, ...translations[item] }
    })
    return response
}

export const onInitEmptyForm = (form, translations) => {
    let response = { ...form }
    Object.keys(form).forEach(item => {
        if (translations && translations[item]) response[item].config = { ...response[item].config, ...translations[item] }
    })
    return response
}

export const onCheckBooleanForm = (data) => {
    let _result = { ...data }
    if (data.value) {
        _result.value = true
        _result.isValid = true
    } else {
        _result.value = false
        _result.isValid = false
    }
    return _result
}

export const onCheckDateForm = (data) => {
    let momentDate = moment(data.value)
    let _result = { ...data }
    if (momentDate.isValid()) {
        _result.value = momentDate.format('YYYY-MM-DD')
        _result.isValid = true
    } else {
        _result.value = null
        _result.isValid = false
    }
    return _result
}

export const onGetDateFormat = (data, format) => {
    if (!data) return null
    const momentdata = moment(data)
    if (!momentdata.isValid()) return null
    momentdata.locale('es')
    if (format) return momentdata.format(format)
    return momentdata.format('YYYY-MM-DD')
}

export const onGetDateBimesterFormat = (data, format) => {
    if (!data) return null
    const momentdata = moment(data).add(1, 'month')
    if (!momentdata.isValid()) return null
    momentdata.locale('es')
    if (format) return momentdata.format(format)
    return momentdata.format('YYYY-MM-DD')
}

export const onGetCatalogs = (options, keyValue, keyLabel) => {
    if (!options) return []
    return options.map(item => {
        return {
            value: item[keyValue], label: item[keyLabel] ? item[keyLabel] : ''
        }
    })
}

export const onGetEmployeesCatalogs = (options) => {
    if (!options) return []
    return options.map(item => {
        const fullname = onGetFullname(item.first_name, item.last_name)
        return {
            value: item.id_user, label: fullname
        }
    })
}

export const onGetFullname = (first_name, last_name) => {
    if (!first_name) return 'N/D'
    return `${first_name ? first_name : ''} ${last_name ? last_name : ''}`
}


export const onGetFileExtension = (filename) => filename.split('.').pop();

export const onGetExtensionFromURL = (url) => {
    if (url === null || url === undefined || url === '') return null
    const chunks = url.split('.')
    if (!chunks.length) return null
    const extension = chunks[chunks.length - 1]
    return extension
}

export const onGetUserID = () => {
    let res = null
    let localData = localStorage.getItem('data')
    if (localData && typeof localData === 'string') {
        localData = JSON.parse(localData)
        res = localData.user_id
    }
    return res
}

export const onGetErrorMessage = (error) => {
    let message = 'Hubo un error'
    if (!error) return ''
    if (error.response) {
        if (error.response.data) {
            if (error.response.data.error && error.response.data.error.code) {
                const _error = ERROR_CODES[error.response.data.error.code]
                console.log(error.response.data.error.code)
                if (_error) message = _error
            }

        }
    }
    return message
}

export const monthsName = ['Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio', 'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre']

export const periodsName = ['Enero-Febrero', 'Marzo-Abril', 'Mayo-Junio', 'Julio-Agosto', 'Septiembre-Octubre', 'Noviembre-Diciembre']

export const onGetMonthsCatalog = (year) => {
    let response = []
    for (let i = 0; i < 12; i++) {
        const startDate = moment([year, i])
        const endDate = moment(startDate).endOf('month')
        response.push({
            value: i + 1,
            label: monthsName[i],
            start_date: startDate.format('YYYY-MM-DD'),
            end_date: endDate.format('YYYY-MM-DD')
        })
    }
    return response
}

export const onGetPeriodsCatalog = (year) => {
    let response = []
    for (let i = 0; i < 6; i++) {
        const startDate = moment([year, i*2])
        const endDate = moment(startDate).add(1, 'months').endOf('month')
        response.push({
            value: i + 1,
            label: periodsName[i],
            start_date: startDate.format('YYYY-MM-DD'),
            end_date: endDate.format('YYYY-MM-DD')
        })
    }
    return response
}

export const onGetTimeDuration = (minutes) => {
    if (!minutes) return '0h'
    const _hours = Math.floor(minutes / 60)
    const _minutes = minutes % 60
    return _minutes > 0 ? `${_hours}h ${Math.floor(_minutes)}m` : `${_hours}h`
}

export const onGetDecimalNumber = (number) => {
    let result = ''
    if (!number) return '0.0'
    let temp = parseFloat(number).toFixed(1)
    result = temp.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    return result
}

export const onGetResponsibleObj = (responsible_id, users, img) => {
    const no_responsible = { first_name: 'Sin', last_name: 'Registro', image: img }
    if (!responsible_id) return no_responsible
    if (!users) return no_responsible
    const _user = users.find(el => el.id_user === responsible_id)
    if (!_user) return no_responsible
    return _user
}

export const onUpdateDocumentList = (files, origin) => {
    if (!origin) return files
    let _files = files.map(item => {
        const document_path = origin[item.key]
        const isValid = isValidDocument(document_path)
        return { ...item, status: isValid }
    })
    return _files
}

export const onUpdateDocumentListProcess = (files, origin, step) => {
    if (!origin) return files
    let _files = files.map(item => {
        const document_path = origin[`${step}_${item.path}`]
        const isValid = isValidDocument(document_path)
        return { ...item, status: isValid }
    })
    return _files
}

export const onUpdateMixedDocumentList = (files, data, business_documents) => {

    if (!data) return files
    if (!business_documents) return files
    let _files = files.map(item => {
        const document_path = item.businessDocument ? business_documents[item.key] : data[item.key]
        const isValid = isValidDocument(document_path)
        return { ...item, status: isValid }
    })
    return _files
}

export const onUpdateMixedDocumentList2 = (files, business_documents) => {
    //if(!data) return files
    if (!business_documents) return files
    let _files = files.map(item => {
        const document_path = business_documents[item.key]
        const isValid = isValidDocument(document_path)
        return { ...item, status: isValid }
    })
    return _files
}

export const onGetYearCatalog = () => {
    const start_year = 2020
    const end_year = moment().year() + 0
    //console.log(end_year)
    //console.log(start_year, end_year)
    
    
    let result = []

    for(let i=start_year; i<=end_year; i++){

        const startDate = moment([i, 0]).startOf('year')
        const endDate = moment(startDate).endOf('year')
        

        result.push({
            value: i, label: `${i}`,
            start_date: startDate.format(),
            end_date: endDate.format()
        })
    }

    //console.log('MIs añso', result)

    return result

}

export const updateAuthorizationHeader = (server) => {
    const logData = JSON.parse(localStorage.getItem('data'));
    if (!logData) {
        console.log('Token not valid')
        return
    }
    //console.log(logData)
    server.defaults.headers.common['Authorization'] = `${logData.token}`;
}