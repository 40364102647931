import React, {Component} from 'react'
import { withStyles } from '@material-ui/core/styles'
import {contentData} from './content'
import cx from 'classnames'
import { Tabs, AppBar, Typography, Grid, Box, Tab, Icon } from '@material-ui/core';
import {grey, green, cyan, blue, deepPurple,red} from '@material-ui/core/colors'

function TabPanel(props) {
    const { children, value, index, ...other } = props;
  
    return (
      <Typography
        component="div"
        role="tabpanel"
        hidden={value !== index}
        id={`scrollable-auto-tabpanel-${index}`}
        aria-labelledby={`scrollable-auto-tab-${index}`}
        {...other}
      >
        {value === index && <Box p={3}>{children}</Box>}
      </Typography>
    );
  
}

const styles = (theme) => ({
    root: {
        flexGrow: 1,
        backgroundColor: theme.palette.background.paper,
        maxHeight:'600px',
        overflowX:'auto'
    },
    permission_even:{
      backgroundColor: 'black',
    },
    permission_odd: {
      backgroundColor: 'white'
    }
})

class Permissions extends Component{

    state = {
        value: 0
    }

    handleChange = (event,value) => {
        this.setState({value: value})
    }
    
    update_permissions = (localPermissions) =>{
      const {permissions} = this.props
      const local = {...localPermissions}
      let counter = 0;

      if(permissions.length > 0)
        Object.keys(localPermissions).map(key =>{
          let block = [...local[key]]
          block.map(item => {
            item.id_permission_type = permissions[counter++].id_permission_type
          })
          local[key] = [...block]
        })
      
      return local
    }
    
    render(){

        const {classes, language} = this.props
        const {value} = this.state
        const content = contentData[language]
        const permissionsUpdated = this.update_permissions(content.permissions)

        const header = (
            <AppBar position="static" color="primary">
                <Tabs variant="fullWidth" value={value} onChange={this.handleChange}>
                    {content.header.map((item, key) => {
                        return <Tab value={key} label={item}/>
                    })}
                </Tabs>
            </AppBar>
        )

        const pKeys = Object.keys(permissionsUpdated);

        const tabs = content.header.map((item,key) => {
            return <TabPanel value={value} index={key}>
                {permissionsUpdated[pKeys[key]].map(item =>{
                  let status = content.status.find(e => e.id == item.id_permission_type)
                  return <PermissionItem
                          className={cx({
                            [classes.permission_even]: key%2 == 0,
                            [classes.permission_odd]: key%2 != 0
                          })}
                          styles={{backgroundColor:'black'}}
                          module_name={item.name}
                          permission_id={item.id_permission_type}
                          permission={status.label}
                        />
                })}
            </TabPanel>
        })

        return (
            <div className={classes.root}>
                {header}
                {tabs}
            </div>
        )
    }
}

export default withStyles(styles)(Permissions)

const styles_permissions = {
    text:{
      color:grey[700],
      fontWeight:'500'
    },
    icon:{
      fontSize:12
    },
    block:{
      padding:'10px 20px 10px 20px'
    }
  
  }

export const PermissionItem = (props) => {
  const {module_name, permission_id, permission} = props
  
  let color = null
  switch(permission_id){
    case 5:
      color=green[700]
      break
    case 4:
      color=cyan[700]
      break;
    case 3:
      color=blue[700]
      break;
    case 2:
      color=deepPurple[700]
      break;
    case 1:
      color=red[700]
      break;
    default:
      color=grey[500]
  }

  return(
    <div>
      <Grid container spacing={1} alignItems='center' 
      style={styles_permissions.block}>
        <Grid item xs>
          <Typography variant='body2' style={styles_permissions.text}>
            {module_name}
          </Typography>
        </Grid>
        <Grid item>
          <div>
            <Grid container spacing={1} alignItems='center'>
              <Grid item>
                <Typography variant='caption' style={{color:grey[700]}}>
                  {permission}
                </Typography>
              </Grid>
              <Grid item>
                <Icon style={{...styles_permissions.icon, color:color}}>brightness_1</Icon>
              </Grid>
            </Grid>
          </div>
        </Grid>
      </Grid>
    </div>
  )
}