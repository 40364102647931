import React, {Component} from 'react'
import { withStyles } from '@material-ui/core/styles'
import styles from './styles.jsx'
import {
  Grid,
  Typography,
  Dialog,
  withWidth,
  DialogTitle,
  DialogContent,
  DialogActions
} from '@material-ui/core'
import SimpleLoader from '../../Loaders/SimpleLoader'
import {Title, ErrorMessage} from '../../DisplayData/DisplayData'
import SimpleDropzone from '../../Dropzones/SimpleDropzone'
import  {RoundedButton} from '../../Buttons/GeneralButtons'

import ReactCrop from 'react-image-crop'
import 'react-image-crop/dist/ReactCrop.css'



const contentData = {
  spanish:{
    button:'Subir',
    cancel:'Cancelar',
    delete:'Eliminar',
    fileDropMessage: 'Arrastre o de clic para subir una imagen',
    subtitle:'Nombre: ',
    title:'Imagen de Perfil',
    image:{
      title:'Imagen seleccionada',
    },
    cropimage:{
      title:'Imagen recortada',
    }
  },
  english:{
    button:'Upload',
    cancel:'Cancel',
    delete:'Delete',
    fileDropMessage: 'Drag or click here to upload a image',
    subtitle:'Filename: ',
    title:'Profile Picture',
    image:{
      title:'Selected Image',
    },
    cropimage:{
      title:'Cropped Image',
    }
  }
}

class FileManagerModal extends Component {

  constructor(props){
    super(props)
    this.imagePreviewCanvasRef = React.createRef();
    this.state = {
      current_file: [],
      msg_file: '',
      isValid: false,
      src:null,
      imgSrc:null,
      isFilled: false,
      tabSelected: 0,
      crop: {
        aspect: 1/1
      },
      imgSrcExt:null
    }
  }

  onImageLoaded = image => {
    this.imageRef = image;
  };

  onUploadImage = async() => {
    const {onSubmit} = this.props;
    const {croppedImageUrl}  = this.state
    if (croppedImageUrl) {
        const file2send = await this.handlerURL2Blob(croppedImageUrl)
        onSubmit('image', [file2send]);
    }
  }

  onCloseDialog = () => {
    this.props.onChangeProcessState({showEditImage:false});
  }

  makeClientCrop = async(crop) => {
    if (this.imageRef && crop.width && crop.height) {
      const croppedImageUrl = await this.getCroppedImg(
        this.imageRef,
        crop,
        "newFile.jpeg"
      );
      this.setState({ croppedImageUrl })
      
    }
  }

  handlerURL2Blob = async(url) => {
    let blob = await fetch(url).then(r => r.blob());
    let temp = blob.type.split('/');
    let ext = "."+temp[temp.length-1];
    let newfile = new File([blob], "profile"+ext, {type:blob.type});
    return newfile;
  }

  getCroppedImg = (image, crop, fileName) => {
    const canvas = document.createElement("canvas");
    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;
    canvas.width = crop.width;
    canvas.height = crop.height;
    const ctx = canvas.getContext("2d");
  
    ctx.drawImage(
      image,
      crop.x * scaleX,
      crop.y * scaleY,
      crop.width * scaleX,
      crop.height * scaleY,
      0,
      0,
      crop.width,
      crop.height
    );
  
    return new Promise((resolve, reject) => {
      canvas.toBlob(blob => {
        if (!blob) {
          console.error("Canvas is empty");
          return;
        }
        window.URL.revokeObjectURL(this.fileUrl);
        this.fileUrl = window.URL.createObjectURL(blob);
        resolve(this.fileUrl);
      });
    });
  }

  handleOnCropChange = (crop) => {
    this.setState({crop:crop})
  }

  handleOnCropComplete = (crop, pixelCrop) =>{
    this.makeClientCrop(crop);
    if(crop.width > 0 && crop.height > 0){
      this.setState({isValid:true})
    }else{
      this.setState({isValid:false})
    }
  }

  onDropFile = (files) => {
    const msg = files[0].name
    this.setState({current_file: [...files],msg_file: msg,isValid: true})

    if(files && files.length>0){
      const reader = new FileReader();
      reader.addEventListener("load", () =>
        this.setState({ src: reader.result })
      )
      reader.readAsDataURL(files[0])
    }
  }

  onUpdateFile = () => {
    const {data, onSubmit} = this.props
    const {current_file} = this.state
    onSubmit(data.data.path,current_file)
  }

  render(){
    const {current_file, src, crop, croppedImageUrl} = this.state
    const {classes, data, onClose, language, onDelete, title} = this.props
    const content = contentData[language]
    const {issending, error} = data

    let loaderContent = null;
    if(issending){
      loaderContent = <div><SimpleLoader/></div>
    }
    let errorContent = null;
    if(error){
      errorContent = <ErrorMessage message={error}/>
    }

    const contentZone = <Grid item xs={12}>
      <SimpleDropzone language={language} onDrop={this.onDropFile} accept={'image/*'}/>
    </Grid>

    let imageContent = null
    let cropImageContent = null

    if(current_file.length > 0){
      imageContent = (
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <Typography variant='subtitle2'>{content.image.title}</Typography>
          </Grid>
          <Grid item xs={12}>
            <div>
              <ReactCrop 
                src={src}
                crop={crop}
                onImageLoaded={this.onImageLoaded}
                onChange={this.handleOnCropChange}
                onComplete = {this.handleOnCropComplete}
                className={classes.reactCrop}
                imageStyle={{
                  width: '100%',
                  height: 'auto',
                  margin:'auto'
                }}
              />
            </div>
          </Grid>
        </Grid>
      )
      cropImageContent = (
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <Typography variant='subtitle2'>{content.cropimage.title}</Typography>
          </Grid>
          <Grid item xs={12}>
            {croppedImageUrl && (
              <img alt="Crop" className={classes.canvas} src={croppedImageUrl}/>
            )}
          </Grid>
        </Grid>
      )
    }


    return(
      <Dialog
        open={true}
        onClose={onClose}
        maxWidth={'sm'}
      >
        <DialogTitle>
          {title ? title : content.title}
        </DialogTitle>
        <DialogContent style={{overflow:'hidden'}}>
          <Grid container spacing={3}>
            {contentZone}
            <Grid item xs={12}>
              <Grid container spacing={1}>
                <Grid item  xs={12} md={6}>
                  {imageContent}
                </Grid>
                <Grid item  xs={12} md={6}>
                  {cropImageContent}
                </Grid>
              </Grid>
              
            </Grid>
            <Grid item xs={12}>
              {errorContent}
            </Grid>
          </Grid>
          
        </DialogContent>
        <DialogActions>
          {loaderContent}
          <RoundedButton
            label={content.cancel}
            size='small'
            color='grey'
            onClick={onClose}
          />
          <RoundedButton
            label={content.delete}
            size='small'
            color='red'
            onClick={onDelete}
          />
          <RoundedButton
            label={content.button}
            size='small'
            color='secondary'
            onClick={this.onUploadImage}
          />
        </DialogActions>
      </Dialog>
    )
  }
}

export default withStyles(styles)(withWidth()(FileManagerModal));
