import moment from 'moment'

export const cleanData2Table = (prevData, currentData, noData) =>{
    let temp = {
        id: currentData.id_ctrl_money_laundering,
        completed: currentData.completed ? true : false,
        files:[],
        data:[]
    }

    let fixedDataA = [
        currentData.businesses_name ? currentData.businesses_name : noData,
        currentData.rfc ? currentData.rfc : noData,
        currentData.client_status ? currentData.client_status : noData,
        currentData.client_classification ? currentData.client_classification : noData,
        currentData.fiscal_regime ? currentData.fiscal_regime : noData,
        currentData.activity ? currentData.activity : noData
    ];

    let varData = [
        {key:'client_documents'},
        currentData.alert ? currentData.alert : noData,
        currentData.notice ? currentData.notice : noData,
        currentData.folio ? currentData.folio : noData,
        currentData.representation_date ? moment(currentData.representation_date).format('DD-MM-YYYY') : noData,
        currentData.compliance_officer ? currentData.compliance_officer : noData,
        {key:'work_sheet'},
        {key:'receipt'},
        currentData.responsible ? currentData.responsible : noData,
        currentData.notes ? currentData.notes : noData,
        currentData.extra_hours ? currentData.extra_hours : noData,
        {key:'hours_proof'},
        currentData.extra_charges ? currentData.extra_charges : noData,
        {key:'charge_proof'},
        currentData.collections_charge ? currentData.collections_charge : noData,
        currentData.completition_date ? moment(currentData.completition_date).format('DD-MM-YYYY') : noData,
    ]

    let origin_data = {
        alert_id : currentData.alert_id ,
        notice_id : currentData.notice_id,
        folio : currentData.folio,
        representation_date : currentData.representation_date,
        compliance_officer : currentData.compliance_officer ,
        responsible_id : currentData.responsible_id,
        notes : currentData.notes,
        extra_hours : currentData.extra_hours,
        extra_charges : currentData.extra_charges,
        collections_charge : currentData.collections_charge,
        completition_date : currentData.completition_date
    }

    let tempA = {...temp}
    tempA.data = [...fixedDataA]
    prevData.fixedDataA.push(tempA);

    let tempVar = {...temp}
    tempVar.data = [...varData]
    tempVar.files = [
        {id:currentData.id_ctrl_money_laundering,key:'client_documents',name:'Documentos Cliente',path:'clientdocuments',
            status: checkStatus(currentData, 'client_documents')},
        {id:currentData.id_ctrl_money_laundering,key:'work_sheet',name:'Hoja de Trabajo',path:'worksheet',
            status: checkStatus(currentData, 'work_sheet')},
        {id:currentData.id_ctrl_money_laundering,key:'receipt',name:'Acuse',path:'receipt',
            status: checkStatus(currentData, 'receipt')},
        {id:currentData.id_ctrl_money_laundering,key:'hours_proof',name:'Comprobante de horas',path:'hoursproof',
            status: checkStatus(currentData, 'hours_proof')},
        {id:currentData.id_ctrl_money_laundering,key:'charge_proof',name:'Tabla SAT',path:'chargeproof',
            status: checkStatus(currentData, 'charge_proof')}
    ]
    tempVar.origin_data = {...origin_data}
    
    prevData.varData.push(tempVar);
    return prevData;
}

const checkStatus = (item, key) => {
    return item[key] !== "0" && item[key] !== 0 && item[key] !== ""
}
