export const contentData = {
  spanish:{
    title:'Crear registro en inversión extranjera',
    nodata:'Sin Registro',
    error:'Sin registro',
    button:'Guardar',
    cancelButton:'Cancelar',
    image_button:'Cambiar',
    form:{
      name:{
        label:'Nombre',
        placeholder:'Nombre',
      }, 
      due_date:{
        label:'Fecha Requerida',
        placeholder:'Fecha Requerida',
      }, 
      estimated_delivery_date:{
        label:'Fecha Estimada de Entrega',
        placeholder:'Fecha Estimada de Entrega'
      },
      responsible_id:{
        label:'Responsable',
        placeholder:'Responsable'
      }
    }
  }
}