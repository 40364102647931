import {mainServer} from '../../../../variables/config'

const params = {order_by:'id_client', order:0}
//const params = {}

export const request_employees = async () => {
  const logData = JSON.parse(localStorage.getItem('data'))
  mainServer.instance.defaults.headers.common['Authorization'] = logData.token
  let response;
  response = await mainServer.instance.get("/user/employee/list");
  return response.data.data.employees
}

export const request_clients = async (params) => {
  const logData = JSON.parse(localStorage.getItem('data'))
  mainServer.instance.defaults.headers.common['Authorization'] = logData.token
  let response;
  response = await mainServer.instance.get(`/client/list${params}`);
  return response.data.data
}

export const request_add_client = async (data) => {
  const logData = JSON.parse(localStorage.getItem('data'))
  mainServer.instance.defaults.headers.common['Authorization'] = logData.token
  let response = await mainServer.instance.post("/client/", data)
  return response.data.data.client_id
}

export const request_delete_client = async (id,params) => {
  const logData = JSON.parse(localStorage.getItem('data'))
  mainServer.instance.defaults.headers.common['Authorization'] = logData.token
  await mainServer.instance.delete("/client/"+id)
  let response;
  response = await mainServer.instance.get(`/client/list${params}`);
  return response.data.data
}
