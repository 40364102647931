export const contentData = {
    spanish:{
      nodata:'Sin Registro',
      error:'Sin registro',
      button:'Guardar',
      cancelButton:'Cancelar',
      image_button:'Cambiar',
      form:{
        presentation_limit_date:{
          placeholder:'Fecha Límite de Presentación'
        },
        process:{
          placeholder:'Proceso'
        },
        status:{
          placeholder:'Estatus'
        },
        client_attention_info_1:{
          placeholder:'Información Atención al Cliente 1'
        },
        client_attention_info_2:{
          placeholder:'Información Atención al Cliente 2'
        },
        quantity_to_pay:{
          placeholder:'Cantidad a Pagar'
        },
        surcharges_and_updates:{
          placeholder:'Recargos y Actualizaciones'
        },
        total_to_pay:{
          placeholder:'Total a Pagar'
        },
        payment_limit_date:{
          placeholder:'Fecha Límite de Pago'
        },
        client_comment:{
          placeholder:'Comentario Cliente'
        },
        responsible_id:{
          placeholder:'Responsable'
        },
        notes:{
          placeholder:'Notas'
        },
        extra_hours:{
          placeholder:'Horas Extra'
        },
        extra_charges:{
          placeholder:'Cargos Extra'
        },
        collections_charge:{
          placeholder:'Cargo a Cobranza'
        },
        completition_date:{
          placeholder:'Fecha de Completado'
        }
      }
    }
  }
