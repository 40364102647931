export const contentData = {
  spanish:{
    layout:{
      title:'Agregar prospecto',
      navigation:[
        {name:'Prospectos',path:'/prospects'},
        {name:'Agregar',path:'/prospects/add'},  
      ],
      menu:null
    },
  }
}