export const contentData = {
    spanish: {
        title: 'Subproceso',
        button: 'Continuar',
        back:'Regresar',
        responsible_banner: {
            responsible: 'responsible',
            responsible_id: 'responsible_id',
            completed_date: 'completed_date'
        },
        accordions: [
            {
                id: 'closed_trial_balance',
                title: 'Balanza de Comprobación Cerrada'
            },
            {
                id: 'income_statements',
                title: 'Estados de Resultados'
            },
            {
                id: 'general_balance',
                title: 'Balance General'
            },
            {
                id: 'tax_payment_record',
                title: 'Flujo de Efectivo'
            },
            {
                id: 'financial_statements',
                title: 'Auxiliares y Analíticas de Estados Financieros'
            },
            {
                id: 'accounting_adjustments',
                title: 'Cálculos de Impuestos'
            },
            {
                id: 'auxiliars',
                title: 'Auxiliares para determinación de ISR, IVA, y Retenciones'
            },
            {
                id: 'special_reports',
                title: 'Reportes Especiales'
            },            
        ],
    }
}
