import React from 'react'
import {grey, green, red} from '@material-ui/core/colors'
import { Grid, Icon, Typography } from '@material-ui/core'


const BenefitItem = (props) => {
  const {data, status} = props

  let iconContent = null
  if(status === 1){
    iconContent = (
      <Icon fontSize='small' style={styles_benefits.good}>done</Icon>
    )
  }else{
    iconContent = (
      <Icon fontSize='small' style={styles_benefits.bad}>close</Icon>
    )
  }

  return(
    <div>
      <Grid container spacing={1} alignItems='center'>
        <Grid item xs>
          <Typography variant='body2' style={styles_benefits.text}>
            {data}
          </Typography>
        </Grid>
        <Grid item>
          {iconContent}
        </Grid>
      </Grid>
    </div>
  )
}

  export default BenefitItem

  const styles_benefits = {
    text:{
      color:grey[700],
      fontWeight:'500'
    },
    good:{
      color:green[700]
    },
    bad:{
      color:red[700]
    }
  
  }