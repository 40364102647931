import { Grid, Icon, IconButton, makeStyles, TableCell, TableHead, TableRow } from '@material-ui/core';
import { grey } from '@material-ui/core/colors';
import React from 'react';

const useStyles = makeStyles(theme => ({
    root: { position: 'relative' },
    cellHeader: {
        borderColor: grey[300],
        paddingTop: 16,
        paddingBottom: 16
    },
}))

const THeader = ({ data, log, order, orderby, onChangeOrder }) => {

    const classes = useStyles()

    const onInnerChangeOrder = (orderby) => {
        onChangeOrder(orderby)
    }


    if (!data) return null

    //console.log(data)

    let icon = 'unfold_more'
    if (order !== -1) {
        if (order === 0) icon = 'expand_less'
        if (order === 1) icon = 'expand_more'
    }

    return (
        <TableHead>
            <TableRow>
                {data.map((item, pos) => {
                    const { id, width, label, fixed, hidden, add, position, padding, key } = item
                    if (hidden) return null

                    let newKey = key
                    if(newKey === 'fixed_ctrl_period_name')
                        newKey = 'auto_date'

                    let orderButton = null
                    if (newKey) {
                        orderButton = (
                            <Grid item>
                                <IconButton size='small' onClick={() => onInnerChangeOrder(newKey)}>
                                    <Icon>{orderby === newKey ? icon : 'unfold_more'}</Icon>
                                </IconButton>

                            </Grid>
                        )
                    }

                    return (
                        <TableCell key={pos.toString()} className={classes.cellHeader} width={width} fixed={fixed} position={position} padding={padding}>
                            <Grid container alignItems='center' wrap='nowrap'>
                                <Grid item xs>
                                    {label}
                                </Grid>
                                {orderButton}
                            </Grid>
                        </TableCell>
                    )
                })}
            </TableRow>
        </TableHead>
    );
}

export default React.memo(THeader);