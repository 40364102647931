import React from 'react'

import SectionA from './StepsViews/SectionA/SectionA';
import SectionB from './StepsViews/SectionB/SectionB';
import SectionC from './StepsViews/SectionC/SectionC';
import SectionD from './StepsViews/SectionD/SectionD';
import SectionE from './StepsViews/SectionE/SectionE';
import SectionF from './StepsViews/SectionF/SectionF';
import SectionG from './StepsViews/SectionG/SectionG';
import SectionH from './StepsViews/SectionH/SectionH';
import SectionI from './StepsViews/SectionI/SectionI';
import SectionJ from './StepsViews/SectionJ/SectionJ';
import SectionK from './StepsViews/SectionK/SectionK';
import SectionL from './StepsViews/SectionL/SectionL';
import SectionM from './StepsViews/SectionM/SectionM';
import SectionN from './StepsViews/SectionN/SectionN';
import SectionO from './StepsViews/SectionO/SectionO';

export const contentData = {
    spanish: {
        modals: {
            sign_modal: {
                title: 'Firma',
                button: 'Aprobar'
            },
            second_sign_modal: {
                title: 'Firma',
                button: 'Completar evaluación'
            }
        },
        url: 'integral_solution',
        fixed_id: 'integral_solution_step_id',
        //monthly_steps: [7, 11, 12],
        monthly_steps: [9, 11, 12, 14],
        paths: {
            primary: {
                id: 'ctrl_accounting_id',
                path: 'ctrlaccounting'
            },
            secondary: {
                id: 'ctrl_monthly_statements_id',
                path: 'ctrlmonthlystatements'
            }
        },
        sections: {
            general_info: {
                banner: {
                    content: {
                        business: 'Razón social',
                        fixed_rfc: 'RFC',
                        fixed_client_classification: 'Clasificación',
                        fixed_importance: 'Importancia',
                        fixed_client_status: 'Estatus',
                        fixed_client_responsible: 'Responsable',
                        fixed_client_associate: 'Socio'
                    }
                },
                fiscal: {
                    title: 'Fiscal',
                    content: {
                        fixed_fiscal_regime: 'Regimen Fiscal',
                        fixed_activity: 'Actividad económica',
                        fixed_fiscal_situation: 'Situación Fiscal',
                        fixed_accounting_method: 'Método de pago de impuestos'
                    }
                },
                taxes: {
                    title: 'Impuestos',
                    content: {
                        fixed_payday_limit: 'Límite de envio de impuestos',
                        fixed_sending_date_limit: 'Envio estados financieros',
                        fixed_tax_preview: 'Previo de impuestos',
                        fixed_coi: 'Número en COI',
                        fixed_utility_coeficient: 'Coeficiente de utilidad'
                    }
                },
                responsibles: {
                    title: 'Responsables',
                    content: {
                        fixed_business_advisor: 'Asesor',
                        fixed_analyst: 'Analista',
                        fixed_supervisor: 'Supervisor',
                        fixed_staff: 'Staff',
                        fixed_tax_responsible: 'Impuestos'
                    }
                }
            },
            stepper_container: {
                top_banner: {
                    advisory: 1,
                    percent: '% Completado',
                },
                progress: 'Progreso',
                priority: 'Prioridad',
                steps: [
                    {
                        title: 'Solicitud de Información',
                        component: <SectionA />,
                    },
                    {
                        title: 'Revisión Interna de Documentación',
                        component: <SectionB />,
                    },
                    {
                        title: 'Aclaración de Dudas con Cliente',
                        component: <SectionC />,
                    },
                    {
                        title: 'Registro Contable y Cálculo de Impuestos',
                        component: <SectionD />,
                    },
                    {
                        title: 'Supervisión Contable',
                        component: <SectionE />,
                    },
                    {
                        title: 'Generación de reportes',
                        component: <SectionF />,
                        altStep: 9
                    },
                    {
                        title: 'Envío de Reportes al Cliente',
                        component: <SectionG />,
                        altStep: 10
                    },
                    {
                        title: 'Revisión, Envío y Autorización de Cálculo de Impuestos',
                        component: <SectionH />,
                        altStep: 6
                    },
                    {
                        title: 'Presentación de Declaración',
                        component: <SectionI />,
                        altStep: 7
                    },
                    {
                        title: 'Envía línea de Captura y Pago',
                        component: <SectionJ />,
                        altStep: 8
                    },
                    {
                        title: 'Envío de la DIOT',
                        component: <SectionK />,
                    },
                    {
                        title: 'Consulta Opinión del Cumplimiento y Validación del Buzón Tributario',
                        component: <SectionL />,
                        altStep: 14
                    },
                    {
                        title: 'Archivos Contabilidad Electrónica',
                        component: <SectionM />,
                        altStep: 12
                    },
                    {
                        title: 'Envío de contabilidad electrónica',
                        component: <SectionN />,
                        altStep: 13
                    },
                    {
                        title: 'Final del proceso',
                        component: <SectionO />,
                    },
                ],
            }
        }
    }
}
