export const contentData = {
  spanish:{
    title:'Información Básica',
    nodata:'Sin Registro',
    error:'Sin registro',
    button:'Guardar',
    cancelButton:'Cancelar',
    image_button:'Cambiar',
    form:{
      rfc:{
        label:'RFC',
        placeholder:'RFC'
      },
      sat_password:{
        label:'Contraseña SAT',
        placeholder:'Contraseña SAT',
      },
      taxpayer_type_id:{
        label:'Tipo de Contribuyente',
        placeholder:'Tipo de Contribuyente',
      },
      fiscal_situation_id:{
        label:'Situación Fiscal',
        placeholder:'Situación Fiscal',
      },
      payday_limit:{
        label:'Fecha límite de Pago de Impuestos',
        placeholder:'Fecha límite de Pago de Impuestos',
      },
      importers_list_id:{
        label:'Padrón de importadores',
        placeholder:'Padrón de importadores',
      },
      fiscal_obligations_id:{
        label:'Obligaciones fiscales',
        placeholder:'Obligaciones fiscales',
      },
      as_responsible_id:{
        label:'Responsable',
        placeholder:'Responsable',
      },
      analyst_id:{
        label:'Analista',
        placeholder:'Analista',
      },
      supervisor_id:{
        label:'Supervisor',
        placeholder:'Supervisor',
      },
      staff_id:{
        label:'Staff',
        placeholder:'Staff',
      },
      coi:{
        label:'No. COI',
        placeholder:'ContrNo. COIaseña',
      },
      start_date:{
        label:'Fecha de inicio de contabilidad',
        placeholder:'Fecha de inicio de contabilidad',
      },
      accounting_method_id:{
        label:'Método de gestión',
        placeholder:'Método de gestión',
      },
      initial_capital:{
        label:'Capital inicial',
        placeholder:'Capital inicial',
      },
      inventory_system_id:{
        label:'Sistema de inventarios',
        placeholder:'Sistema de inventarios',
      },
      inventory_evaluation_method_id:{
        label:'Método de valuación de inventarios',
        placeholder:'Método de valuación de inventarios',
      },
      financial_states_norm_id:{
        label:'Normativa de Estados Financieros',
        placeholder:'Normativa de Estados Financieros',
      },
      commercial_relation_id:{
        label:'Relaciones Comerciales',
        placeholder:'Relaciones Comerciales',
      },
      financial_states_periodicity_id:{
        label:'Periodicidad de Estados Financieros',
        placeholder:'Periodicidad de Estados Financieros',
      },
      sending_date_limit:{
        label:'Fecha Límite de envío de Edo. Financieros',
        placeholder:'Fecha Límite de envío de Edo. Financieros',
      },
      report_language_id:{
        label:'Idioma que reportan',
        placeholder:'Idioma que reportan',
      },
      tax_preview:{
        label:'Previo de impuestos',
        placeholder:'Previo de impuestos',
      },
    },
  },
}
