import React from 'react'
import { withStyles } from '@material-ui/core';
import MuiAccordion from '@material-ui/core/Accordion';
import MuiAccordionSummary from '@material-ui/core/AccordionSummary';
import MuiAccordionDetails from '@material-ui/core/AccordionDetails';

export const Accordion = withStyles({
    root: {
        background: 'transparent',
        boxShadow: 'none',
        '&:not(:last-child)': {
            borderBottom: 0,
        },
        '&:before': {
            display: 'none',
        },
        '&$expanded': {
            margin: 'auto',
        },
    },
    expanded: {},
})(MuiAccordion);

export const AccordionSummary = withStyles((theme) => ({
    root: {
        backgroundColor: theme.palette.primary.main,
        color: 'white',
        borderBottom: '1px solid rgba(0, 0, 0, .125)',
        borderRadius: '12px 12px 0px 0px',
        marginBottom: -1,
        minHeight: 0,
    },
    content: {
        justifyContent: 'center',
        '&$expanded': {
            margin: 0,
        },
        '& p': {
            fontSize: 16,
            fontWeight: 600,
        }
    },
    expanded:{
        minHeight: '0px !important',
    },
    expandIcon: {
        color: 'white'
    },
}), { withTheme: true })(MuiAccordionSummary);

export const AccordionDetails = withStyles((theme) => ({
    root: {
        padding: theme.spacing(2),
    },
}))(MuiAccordionDetails);

export const Accordion2 = withStyles({
    root: {
        backgroundColor: 'rgba(75, 146, 248, 0.04)',
        borderRadius:'18px !important',
        boxShadow: 'none',
        '&:not(:last-child)': {
            borderBottom: 0,
        },
        '&:before': {
            display: 'none',
        },
        '&$expanded': {
            margin: 'auto',
        },
    },
    expanded: {},
})(MuiAccordion);

export const AccordionSummary2 = withStyles((theme) => ({
    root: {
        backgroundColor: 'rgba(75, 146, 248, 0.15)',
        color: 'white',
        borderBottom: '1px solid rgba(0, 0, 0, .125)',
        borderRadius: 18,
        marginBottom: -1,
        minHeight: '0px !important',
        height:'auto'
    },
    content: {
        justifyContent: 'center',
        '&$expanded': {
            margin: 0,
        },
        '& p': {
            fontSize: 16,
            fontWeight: 600,
        }
    },
    expanded:{
        backgroundColor: `#365689 !important`,
        color:'white',
        minHeight: '0px !important',
    },
    expandIcon: {
        color: 'white',
        padding:10
    },
}), { withTheme: true })(MuiAccordionSummary);