import React, { useEffect, useState } from 'react';
import { Grid } from '@material-ui/core';
import ActionModalBar from '../../../../components_v1/Actions/ActionModalBar';
import SimpleModal from '../../../../components_v1/Structure/Modals/SimpleModal';
import InputFile from '../../../../components_v1/Forms/InputFile';
import DisplayText from '../../../../components_v1/Texts/DisplayText';
import { onGetErrorMessage, onGetFileExtension } from '../../../../shared/utility';
import { request_upload_document } from '../requests';

const DocumentModal = ({selected_document, open, onClose, onUpdateCompleted}) => {

    const [file, setFile] = useState(null)
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState('')

    useEffect(() => {
        if(!open){
            setFile(null)
            setError(null)
            setLoading(false)
        }
    },[open])

    const onSubmit = async() => {try {
        if(!file){
            setError('Debe adjuntar un documento')
            return
        }
        setLoading(true)

        const file2send = {ext:onGetFileExtension(file.name)}
        const {ctrl_id, key} = selected_document
        const _path = key.split('_').join('')
        await request_upload_document(ctrl_id, _path, file, file2send)
        onUpdateCompleted()
        onClose()

    } catch (error) {
        console.log(error)
        setError(onGetErrorMessage(error))   
    } setLoading(false)}

    return ( 
        <SimpleModal open={open} onClose={onClose} maxWidth='sm'>
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <DisplayText variant='h6' color='primary'>Subir documento</DisplayText>
                </Grid>
                <Grid item xs={12}>
                    <InputFile id='file' file={file} label='Adjuntar evidencia' 
                        onChange={(file) => setFile(file)} onDelete={() => setFile(null)}/>
                </Grid>
                <Grid item xs={12}>
                    <ActionModalBar btnLabel='Subir' loading={loading} error={error} onSubmit={onSubmit}/>
                </Grid>
            </Grid>
        </SimpleModal>
     );
}
 
export default DocumentModal;