export const contentData = {
  spanish:{
    title:'Información de Contacto',
    nodata:'Sin Registro',
    error:'Sin registro',
    button:'Guardar',
    image_button:'Cambiar',
    form:{
      work_email:{
        label:'Correo de trabajo',
        placeholder:'Correo de trabajo',
      },
      alternative_email:{
        label:'Correo alternativo',
        placeholder:'Correo alternativo',
      },
      personal_email:{
        label:'Correo personal',
        placeholder:'Correo personal',
      },
      phone_number:{
        label:'Teléfono',
        placeholder:'Teléfono',
      },
      extension:{
        label:'Extensión',
        placeholder:'Extensión',
      },
      cellphone:{
        label:'Celular',
        placeholder:'Celular',
      }
    },
  },
}