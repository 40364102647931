import React, { useEffect, useState } from 'react'
import { Grid, Card, makeStyles, Hidden } from '@material-ui/core'
import DisplayText from '../../../../../../components_v1/Texts/DisplayText'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { Accordion2, AccordionDetails, AccordionSummary2 } from '../../../../../../components_v1/Structure/Accordion/Accordion';
import ShadedInputText from '../../../../../../components_v1/Forms/ShadedInputText';
import InputSelect from '../../../../../../components_v1/Forms/InputSelect';
import InputSelectStatus from '../../../../../../components_v1/Forms/InputSelectStatus';
import { onGetErrorMessage, onInitFormProcess, onUpdateDocumentListProcess } from '../../../../../../shared/utility';
import LoadingContainer from '../../../../../../components_v1/Structure/Layouts/LoadingContainer';
import RoundedButton from '../../../../../../components_v1/Actions/RoundedButton';
import { contentData } from './content'
import { catalogs } from '../../../../../../texts/esp/catalogs';
import { grey } from '@material-ui/core/colors';
import DocumentInput from '../../../../../../components_v1/Forms/DocumentInput';
import Responsibles from '../Responsibles';
import { request_process } from '../../requests';
import ButtonsSelect from '../../../../../../components_v1/Forms/ButtonsSelect';
import InputText from '../../../../../../components_v1/Forms/InputText';

const styles = makeStyles(theme => ({
    banner: {
        padding: '0px 64px 64px 64px',
        [theme.breakpoints.down('sm')]: {
            padding: '0px 32px 32px 32px',
        },
        borderBottom: `1px solid ${grey[300]}`
    },
    banner2: {
        padding: '0px 64px 64px 64px',
        [theme.breakpoints.down('sm')]: {
            padding: '0px 32px 32px 32px',
        },
    },
    subAccordion: {
        backgroundColor: 'rgba(75, 146, 248, 0.15)',
        color: '#0F2358'
    },
    title: {
        fontSize: 18,
        fontWeight: 500
    },
    smallTitle: {
        fontSize: 14,
        fontWeight: 500,
        color: '#607D8B'
    },
}))

const SectionB = ({ id, ctrlID, process, onUpdate, onUpdateStep, language, permission, onDownloadDocument }) => {

    const step = 2
    const classes = styles()

    const [form, setForm] = useState(JSON.parse(JSON.stringify(formData)))
    const [loading, setLoading] = useState(false)
    const [sending, setSending] = useState(false)
    const [success, setSuccess] = useState(null)
    const [error, setError] = useState(null)

    //const [process, setProcess] = useState(null)
    const [accordions, setAccordions] = useState(null)
    const content = contentData[language]

    useEffect(() => {
        const initModule = async () => {
            try {
                setLoading(true)
                let _form = JSON.parse(JSON.stringify(formData))
                _form = onInitFormProcess(_form, process, step)
                setForm(_form)
                let _accordions = content.accordions.map(item => true)
                setAccordions(_accordions)
            } catch (error) {
                console.log(error)
                setError(onGetErrorMessage(error))
            }
            setLoading(false)
        }
        initModule()
    }, [])

    const onUpdateCompleted = async (newStep) => {
        try {
            setSending(true)
            if (newStep)
                await onUpdate(form, true, newStep, content.responsible_banner)
            else {
                let _process = await request_process(id, `?step_id=${step}`)
                let _form = JSON.parse(JSON.stringify(formData))
                _form = onInitFormProcess(_form, _process, step)
                setForm(_form)
            }
            setSending(false)
            setSuccess(true)
        } catch (error) {
            console.log(error)
            setError(onGetErrorMessage(error))
        }
        setSending(false)
    }

    const onChange = (data) => {
        let temp = { ...form }
        const id = data.config.id
        temp[id] = { ...data }
        setForm(temp)
    }

    const handleAccordions = (id) => {
        let _accordions = [...accordions]
        _accordions[id] = !accordions[id]
        setAccordions(_accordions)
    }

    return (
        <LoadingContainer loading={loading}>
            <Grid container justify='center' spacing={8}>
                <Grid item xs={12}>
                    <div className={classes.banner}>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <DisplayText variant='body2' className={classes.title}>{content.title}</DisplayText>
                            </Grid>
                            {accordions && content.accordions.map((accordionData, key) => (
                                <Grid item xs={12}>
                                    <Accordion2 expanded={accordions[key]} onChange={() => handleAccordions(key)}>
                                        <AccordionSummary2
                                            expandIcon={<ExpandMoreIcon />}
                                            aria-controls={`${accordionData.id}_controls`}
                                            id={accordionData}
                                            classes={{ root: classes.subAccordion }}
                                        >
                                            <DisplayText variant='body2' align='center'>{accordionData.title}</DisplayText>
                                        </AccordionSummary2>
                                        <AccordionDetails>
                                            <Grid container justify='center' spacing={4}>
                                                <Grid item xs={12}>
                                                    <ButtonsSelect
                                                        data={form[accordionData.id]}
                                                        onChange={onChange}
                                                        disabled={!permission}
                                                    />
                                                </Grid>
                                            </Grid>
                                        </AccordionDetails>
                                    </Accordion2>
                                </Grid>
                            ))}
                        </Grid>
                    </div>
                </Grid>
                <Grid item xs={12}>
                    <div className={classes.banner}>
                        <ShadedInputText
                            className={classes.selectStatus}
                            color='primary'
                            data={form.comments}
                            onChange={onChange}
                            disabled={!permission}
                        />
                    </div>
                </Grid>
                <Grid item xs={12}>
                    <Responsibles data={process} step={step} language={language} responsible_banner={content.responsible_banner} />
                </Grid>
                <Grid item>
                    <RoundedButton disabled={!permission} color='blueGrey' onClick={() => onUpdateStep(step - 1, true)}>
                        {content.back}
                    </RoundedButton>
                </Grid>
                <Grid item>
                    <RoundedButton disabled={!permission} color='secondary' onClick={() => onUpdateCompleted(step + 1)}>
                        {content.button}
                    </RoundedButton>
                </Grid>
            </Grid>
        </LoadingContainer >
    )
}

const formData = {
    client_response_id: {
        value: null,
        error: false,
        isVisited: false,
        isRequired: false,
        isValid: false,
        options: [...catalogs.client_responsibles],
        config: {
            id: 'client_response_id',
            type: 'simple_select',
            fullWidth: true,
            label: 'Respuesta al cliente:',
            helper: 'Selecciona una opción'
        },
        rules: {
            type: 'none',
        }
    },
    payment_amount: {
        value: null,
        error: false,
        isVisited: false,
        isRequired: false,
        isValid: false,
        config: {
            id: 'payment_amount',
            type: 'number',
            fullWidth: true,
            label: null,
            helper: 'Introduce el número de operaciones',
        },
        rules: {
            type: 'distance',
            min: 1
        }
    },
    comments: {
        value: null,
        error: false,
        isVisited: false,
        isRequired: false,
        isValid: false,
        config: {
            id: 'comments',
            type: 'text',
            fullWidth: true,
            label: 'Comentarios',
            helper: 'Introduce tus comentarios (máximo 255 caracteres)',
            multiline: true,
            rows: 6
        },
        rules: {
            type: 'distance',
            min: 1
        }
    },
    capture_line_sent: {
        value: null,
        error: false,
        isVisited: false,
        isRequired: false,
        isValid: false,
        options: [...catalogs.process_progress],
        config: {
            id: 'capture_line_sent',
            type: 'simple_select',
            fullWidth: true,
            label: 'Disponibilidad',
            helper: 'Disponibilidad'
        },
        rules: {
            type: 'none',
        }
    },
    payment_reception: {
        value: null,
        error: false,
        isVisited: false,
        isRequired: false,
        isValid: false,
        options: [...catalogs.process_progress],
        config: {
            id: 'payment_reception',
            type: 'simple_select',
            fullWidth: true,
            label: 'Disponibilidad',
            helper: 'Disponibilidad'
        },
        rules: {
            type: 'none',
        }
    },
    asiviewer_upload: {
        value: null,
        error: false,
        isVisited: false,
        isRequired: false,
        isValid: false,
        options: [...catalogs.process_progress],
        config: {
            id: 'asiviewer_upload',
            type: 'simple_select',
            fullWidth: true,
            label: 'Disponibilidad',
            helper: 'Disponibilidad'
        },
        rules: {
            type: 'none',
        }
    },
}

export default SectionB