export const contentData = {
  spanish:{
    title:'Agregar inscripción al IMSS',
    nodata:'Sin Registro',
    error:'Sin registro',
    button:'Guardar',
    cancelButton:'Cancelar',
    image_button:'Cambiar',
    form:{
      name:{
        label:'Nombre',
        placeholder:'Nombre',
      },
      process_id:{
        label:'Proceso A Realizar',
        placeholder:'Proceso A Realizar',
      },
      due_date:{
        label:'Fecha Requerida',
        placeholder:'Fecha Requerida',
      },
      responsible_id:{
        label:'Responsable',
        placeholder:'Responsable'
      },
      estimated_delivery_date:{
        label:'Fecha Estimada de Entrega',
        placeholder:'Fecha Estimada de Entrega'
      },
      project_cost:{
        label:'Costos del Proyecto',
        placeholder:'Costos del Proyecto',
      },
      cost:{
        label:'Costo',
        placeholder:'Costo'
      },
      sell_price:{
        label:'Precio de Venta',
        placeholder:'Precio de Venta'
      }
    },
  },
}
