import React, { useState, useEffect } from 'react';
import BasicView from '../../../components_v1/Structure/Layouts/BasicView';
import { Grid } from '@material-ui/core';
import { onGetErrorMessage, onGetUserID } from '../../../shared/utility';
import TopInfo from './components/TopInfo';
import { contentTask } from './content';
import TaskInfo from './components/TaskInfo';
import SimpleCard from '../../../components_v1/Structure/Cards/SimpleCard';
import TaskUsers from './components/TaskUsers.jsx';
import useTaskView from './useTaskView';
import EditTaskModal from './modals/EditTaskModal';
import DocumentModal from './modals/DocumentModal';
import AddUserModal from './modals/AddUserModal';
import Subtasks from './components/Subtasks';
import AddSubtaskModal from './modals/AddSubtaskModal';
import TaskComments from './components/TaskComments';
import AddCommentModal from './modals/AddCommentModal';
import EditCommentModal from './modals/EditCommentModal';
import EditSubtaskModal from './modals/EditSubtaskModal';

const TaskView = ({ match, history }) => {
	const content = contentTask.spanish;
	const taskID = match.params.id;
	const userID = onGetUserID();

	const { system, view_data, actions, modals } = useTaskView({ history, userID, taskID, content });

	return (
		<>
			<EditTaskModal
				content={content.editTaskModal}
				error={system.error}
				form={view_data.form}
				loading={system.sending}
				description={view_data.description}
				onSetDescription={actions.onSetDescription}
				onChange={actions.onChange}
				onClose={() => actions.onChangeModalStatus('edit_task', false)}
				open={modals.edit_task_modal}
				onSubmit={actions.onEditTask}
			/>
			<EditSubtaskModal
				content={content.editSubtaskModal}
				error={system.error}
				form={view_data.form}
				loading={system.sending}
				onChange={actions.onChange}
				onClose={() => actions.onChangeModalStatus('edit_subtask', false)}
				open={modals.edit_subtask_modal}
				onSubmit={actions.onEditTask}
				description={view_data.subtaks_description}
				onSetDescription={actions.onSetSubtaksDescription}
			/>
			<DocumentModal
				content={content.uploadDocumentModal}
				onClose={() => actions.onChangeModalStatus('upload_document', false)}
				open={modals.upload_document_modal}
				error={system.error}
				loading={system.sending}
				onSetFile={actions.onChangeFile}
				file={view_data.file}
				onSubmit={actions.onSubmitDocument}
			/>
			<AddUserModal
				content={content.addUserModal}
				error={system.error}
				form={view_data.form}
				loading={system.sending}
				onChange={actions.onChange}
				onClose={() => actions.onChangeModalStatus('add_user', false)}
				open={modals.add_user_modal}
				onSubmit={actions.onAddUser}
			/>
			<AddSubtaskModal
				content={content.addSubtaskModal}
				error={system.error}
				form={view_data.form}
				loading={system.sending}
				onChange={actions.onChange}
				onClose={() => actions.onChangeModalStatus('add_subtask', false)}
				open={modals.add_subtask_modal}
				onSubmit={actions.onAddSubtask}
				description={view_data.subtaks_des_add}
				onSetDescription={actions.onSetSubtaskDesAdd}
			/>
			<AddCommentModal
				content={content.addCommentModal}
				error={system.error}
				form={view_data.form}
				loading={system.sending}
				onChange={actions.onChange}
				onClose={() => actions.onChangeModalStatus('add_comment', false)}
				open={modals.add_comment_modal}
				onSubmit={actions.onAddComment}
				comment={view_data.add_comment}
				onSetComment={actions.onSetAddComment}
			/>
			<EditCommentModal
				content={content.editCommentModal}
				error={system.error}
				form={view_data.form}
				loading={system.sending}
				onChange={actions.onChange}
				onClose={() => actions.onChangeModalStatus('edit_comment', false)}
				open={modals.edit_comment_modal}
				onSubmit={actions.onEditComment}
				comment={view_data.comment}
				onSetComment={actions.onSetComment}
			/>
			<BasicView
				loading={system.loading}
				title={content.title}
				sending={system.sending}
				setReturn
				success={system.success}
				onCloseSuccess={() => actions.onChangeSuccess(null)}
				error={system.error}
				onCloseError={() => actions.onChangeError(null)}
			>
				<div>
					<Grid container spacing={3}>
						<Grid item xs={12}>
							<TopInfo content={content.topInfo} task={view_data.task} />
						</Grid>
						<Grid item xs={12}>
							<SimpleCard>
								<Grid container>
									<Grid item xs={12} md={8}>
										<TaskInfo
											content={content.taskInfo}
											task={view_data.task}
											onEdit={actions.onOpenEditTaskModal}
											onUploadDocument={() => actions.onChangeModalStatus('upload_document', true)}
											onDownloadDocument={actions.onDownloadDocument}
											onDeleteDocument={actions.onDeleteDocument}
										/>
									</Grid>
									<Grid item xs={12} md={4}>
										<TaskUsers
											content={content.taskUsers}
											users={view_data.users}
											onAddUser={actions.onOpenAddUserModal}
											onDeleteUser={actions.onDeleteUser}
											onSetMainUser={actions.onSetMainUser}
										/>
									</Grid>
								</Grid>
							</SimpleCard>
						</Grid>
						<Grid item xs={12}>
							<Subtasks
								content={content.subtasks}
								subtasks={view_data.subtasks}
								onAddSubtask={actions.onOpenAddSubtaskModal}
								onUploadDocument={actions.onUploadSubtaskDocument}
								onDownloadDocument={actions.onDownloadDocument}
								onDeleteDocument={actions.onDeleteDocument}
								onDeleteSubtask={actions.onDeleteSubtask}
								onSelectSubtask={actions.onSelectSubtask}
							/>
						</Grid>
						<Grid item xs={12}>
							<TaskComments
								content={content.comments}
								comments={view_data.comments}
								onAddComment={actions.onOpenAddCommentModal}
								onDeleteComment={actions.onDeleteComment}
								onEditComment={actions.onOpenEditCommentModal}
								onUploadDocument={actions.onUploadCommentDocument}
								onDownloadDocument={actions.onDownloadDocument}
								onDeleteDocument={actions.onDeleteDocument}
							/>
						</Grid>
					</Grid>
				</div>
			</BasicView>
		</>
	);
};

export default TaskView;
