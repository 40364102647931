import React, {Component} from 'react'
import { withStyles } from '@material-ui/core/styles'
import { Grid} from '@material-ui/core'
import styles from './styles'
import {contentData} from './content'

import BasicView from '../../../../components/Layouts/BasicView/BasicView'
import EditClientuserInfo from './EditClientuserInfo/EditClientuserInfo'
import FormA from '../../../../components/Forms/FormA/FormA'
import ProfilePictureModal from '../../../../components/Modals/ProfilePictureModal/ProfilePictureModal'

import {
  request_clientuser,
  request_update_clientuser
} from './requests'

import {formData as contactdata} from './contactdata/data' 
import {contentData as contactcontent} from './contactdata/content' 
import {catalog_matrix as contactmatrix} from './contactdata/catalog_matrix'


class EditClientuser extends Component{

  state = {
    isloading:false,
    clientuser:null,
    modals:{
      imagemodal:{open:false,issending:false,error:null},
      warning_delete_file:{open:false, issending:false, error:null, id:null}
    },
    forms:{
      basicinfo:{issending:false, error:null},
      contactinfo:{issending:false, error:null},
    },
    success:{open:false,timeout:2000,type:'success',message:'Successful action!'},
  }

  componentDidMount(){
    this.initModule()
  }

  onCloseSnackbar = (id) => {
    let temp = this.state[id];
    temp.open = false;
    this.setState({[id]:{...temp}})
  }

  onGetPageID = () => {
    return this.props.match.params.id
  }

  onActivateSuccessSnack = () => {
    const {success} = this.state;
    let success_temp = {...success};
    success_temp.open = true;
    this.setState({success:{...success_temp}})
  }

  initModule = async() => {
    const {idclientuser} = this.props.match.params
    this.setState({isloading:true})
    let response = await request_clientuser(idclientuser)
    this.setState({clientuser:{...response}})
    this.setState({isloading:false})
  }

  onReturn = () => {
    this.props.history.goBack()
  }

  onCloseModal = (type) => {
    this.onEditModal(type, {open:false, issending:false, error:null})
  }

  onEditModal = (section, data) => {
    const {modals} = this.state
    let tempModals = {...modals}
    tempModals[section]={...tempModals[section],...data}
    this.setState({modals:{...tempModals}})
  }

  onEditForm = (section, data) => {
    const {forms} = this.state
    let temp = {...forms}
    temp[section]={...temp[section],...data}
    this.setState({forms:{...temp}})
  }

  onOpenFileModal = (section, filedata) => {
    const {modals} = this.state
    let tempModals = {...modals}
    tempModals[section].open=true
    if(filedata){
      tempModals[section].data = {...filedata} 
    }
    this.setState({modals:{...tempModals}})
  }

  onEditClientuserInfo = async(data, type) => {
    try {
      const {idclientuser} = this.props.match.params
      this.onEditForm(type,{issending:true})
      let response = await request_update_clientuser(idclientuser, data)
      this.setState({clientuser:{...response}})
      this.onEditForm(type,{issending:false, error:null})
      this.onActivateSuccessSnack()
    } catch (error) {
      this.onEditForm(type,{issending:false})
      if(error.response){
        this.onEditForm(type,{error:error.response.data.error.message})
      }
    }
  }

  render(){

    const {history, language, catalogs} = this.props;
    const {isloading, forms, success, modals, clientuser, users} = this.state
    const content = contentData[language]
    let modalcontent = null

    if(modals.imagemodal.open){
      modalcontent = (
        <ProfilePictureModal
          data={modals.imagemodal}
          language={language}
          onClose={() => this.onCloseModal('imagemodal')}
          onSubmit={this.onSubmitPicture}
          onDelete={this.onDeletePicture}
        />
      )
    }

    console.log(contactdata)

    
    return(
      <BasicView
        history={history}
        content={content.layout}
        returnBtn={true}
        onReturn={this.onReturn}
        success={success}
        onCloseSnackbar={this.onCloseSnackbar}
        isloading={isloading}
      >
        {modalcontent}
        <Grid container spacing={4}>
          <Grid item xs={12}>
            <EditClientuserInfo
              language={language}
              catalogs={catalogs}
              users={users}
              clientuser={clientuser}
              issending={forms.basicinfo.issending}
              error={forms.basicinfo.error}
              onSubmit={(data) => this.onEditClientuserInfo(data, 'basicinfo')}
            />
          </Grid>
          <Grid item xs={12}>
            <Grid container spacing={4}>
              <Grid item xs={12} lg={12}>
                <FormA
                  origin_data={clientuser}
                  catalogs={catalogs}
                  catalog_matrix={contactmatrix}
                  form_data={contactdata}
                  content={contactcontent[language]}
                  issending={forms.contactinfo.issending}
                  error={forms.contactinfo.error}
                  onSubmit={(data) => this.onEditClientuserInfo(data, 'contactinfo')}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </BasicView>
      
    )
  }
}

export default withStyles(styles)(EditClientuser)