import { Grid, makeStyles, Switch } from '@material-ui/core';
import moment from 'moment';
import React, { useState, useEffect } from 'react';
import ActionModalBar from '../../../../components_v1/Actions/ActionModalBar';
import InputFile from '../../../../components_v1/Forms/InputFile';
import InputRating from '../../../../components_v1/Forms/InputRating';
import InputSelect from '../../../../components_v1/Forms/InputSelect';
import InputText from '../../../../components_v1/Forms/InputText';
import SimpleModal from '../../../../components_v1/Structure/Modals/SimpleModal';
import DisplayText from '../../../../components_v1/Texts/DisplayText';
import { isFormValid, onGetCatalogs, onGetFormData, onSetErrorsToForm, onGetUserID, onGetFileExtension } from '../../../../shared/utility';
import { catalogs } from '../../../../texts/esp/catalogs';
import { request_create_timereport, request_timereports_businesses, request_timereports_services, request_timereports_supervisors, request_upload_document } from '../requests';
import RichTextForm from '../../../../components_v1/Forms/RichTextForm';


const useStyles = makeStyles(theme => ({
    form:{
        paddingTop:32,
        paddingBottom:32
    }
}))

const AddTimeReportModal = (props) => {

    const classes = useStyles()
    const { open, onClose, onRequestUsers, history } = props
    const [form, setForm] = useState(JSON.parse(JSON.stringify(formData)))
    const [file, setFile] = useState(null)
    const [businesses, setBusinesses] = useState([])
    const [services, setServices] = useState([])
    const [supervisors, setSupervisors] = useState([])
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState('')
    const [supervisor, setSupervisor] = useState(false)
    const [description, setDescription] = useState('')
    const [meeting_notes, setMeetingNotes] = useState('')

    const [filterBusinesses, setFilterBusinesses] = useState([])

    useEffect(() => {
        if (!open) {
            setForm(JSON.parse(JSON.stringify(formData)))
            setDescription('')
            setMeetingNotes('')
            setError(''); setLoading(false);
        }else{
            onSearchService()
            onSearchBusiness()
        }
    }, [open])

    const onChange = (data) => {
        let temp = { ...form }
        const id = data.config.id
        temp[id] = { ...data }
        setForm(temp)
    }

    const onSearchBusiness = async(e) => {
        //if(!e || e.target.value === "" || e.target.value === 0) return
        try {
            const _businesses = await request_timereports_businesses()
            console.log(_businesses)
            const _business_catalog = onGetCatalogs(_businesses, 'business_id', 'business')
            setBusinesses(_business_catalog)
        } catch (error) {
            console.log(error)
        }
    }

    const onSearchService = async(e) => {
        //if(!e || e.target.value === "" || e.target.value === 0) return
        try {
            const _services = await request_timereports_services()
            const _service_catalog = onGetCatalogs(_services, 'id_time_report_service', 'name')
            setServices(_service_catalog)
        } catch (error) {
            console.log(error)
        }
    }

    const onFilterBusiness = (e, label) => {
        //console.log(e.target.value)
        //console.log(businesses)
        console.log(label)
        if(!label || label === "" || label === 0 || label === undefined) return
        const _filter = onFilterDataArray(businesses, label.toLowerCase())
        //console.log(_filter)
        setFilterBusinesses(_filter)
        console.log(_filter)
    }

    const onSearchSupervisor = async(e) => {
        if(!e || e.target.value === "" || e.target.value === 0 ) return
        try {
            const _supervisors = await request_timereports_supervisors(e.target.value)
            const _supervisor_catalog = onGetCatalogs(_supervisors, 'user_id', 'user')
            setSupervisors(_supervisor_catalog)
        } catch (error) {
            console.log(error)
        }
    }


    const onFilterDataArray = (data, text) => {
       
        const newArray =  data.filter(item => {
            //console.log(item.label.toLowerCase(), text.toLowerCase())
            return item.label.toLowerCase().includes(text)
        })
        return newArray;
    }

   

    /* const onSubmit = async () => {

        

        const errors = isFormValid(form)
        /// Check if the form is valid
        if(errors && errors.length){
            console.log(errors)
            const new_form = onSetErrorsToForm(form, errors)
            setForm(new_form)
            return
        }
        //Check supervisor
        if(supervisor){
            if(!form.supervisor_id.value){
                setError('Es necesario seleccionar el supervisor')
                return
            }
        }
        

        //Extract data
        let data2send = onGetFormData(form)
        const userID = onGetUserID()
        //Add extra data
        const timeRegex_start = /^(((([0-1][0-9])|(2[0-3])):?[0-5][0-9]))$/g
        const timeRegex_end = /^(((([0-1][0-9])|(2[0-3])):?[0-5][0-9]))$/g
        
        if(data2send.start_hour && timeRegex_start.test(data2send.start_hour)) data2send.start_hour = `${data2send.start_hour}:00`
        if(data2send.end_hour && timeRegex_end.test(data2send.end_hour)) data2send.end_hour = `${data2send.end_hour}:00`
        data2send.user_id = userID
        data2send.state_id = supervisor ? 2 : 1
        data2send.task_description = description
        data2send.meeting_notes = meeting_notes

        //Send data
        setLoading(true)
        try {
            let timereport_id = await request_create_timereport(data2send);
            if(file){
                const fileext = onGetFileExtension(file.name)
                try {
                    const file2send = {name:'evidence', ext:fileext}
                    await request_upload_document(timereport_id, file, file2send)

                } catch (error) {
                    console.log(error.response)
                    setError('Hubo un error al crear el archivo')
                }
            }
            onClose()
            history.push(`/timereports/${timereport_id}`)

        } catch (error) {
            console.log(error)
            setError('Hubo un error')
            setLoading(false)
        }

    } */

    const onSubmit = async () => {

        

        const errors = isFormValid(form)

        console.log(errors)
        /// Check if the form is valid
        if(errors && errors.length){
            const new_form = onSetErrorsToForm(form, errors)
            setForm(new_form)
            return
        }
        //Check supervisor
        if(supervisor){
            if(!form.supervisor_id.value){
                setError('Es necesario seleccionar el supervisor')
                return
            }
        }
        

        //Extract data
        let data2send = onGetFormData(form)
        const userID = onGetUserID()
        //Add extra data
        const timeRegex_start = /^(((([0-1][0-9])|(2[0-3])):?[0-5][0-9]))$/g
        const timeRegex_end = /^(((([0-1][0-9])|(2[0-3])):?[0-5][0-9]))$/g
        
        if(data2send.start_hour && timeRegex_start.test(data2send.start_hour)) data2send.start_hour = `${data2send.start_hour}:00`
        if(data2send.end_hour && timeRegex_end.test(data2send.end_hour)) data2send.end_hour = `${data2send.end_hour}:00`
        data2send.user_id = userID
        data2send.state_id = supervisor ? 2 : 1
        data2send.task_description = description
        data2send.meeting_notes = meeting_notes

        //Send data
        setLoading(true)
        try {
            let timereport_id = await request_create_timereport(data2send);
            if(file){
                const fileext = onGetFileExtension(file.name)
                try {
                    const file2send = {name:'evidence', ext:fileext}
                    await request_upload_document(timereport_id, file, file2send)

                } catch (error) {
                    console.log(error.response)
                    setError('Hubo un error al crear el archivo')
                }
            }
            onClose()
            history.push(`/timereports/${timereport_id}`)

        } catch (error) {
            console.log(error)
            setError('Hubo un error')
            setLoading(false)
        }

    }


    // Update catalogs in real time 

    let business_form = {...form.business_id}
    business_form.options = [...filterBusinesses]

    let service_form = {...form.service_id}
    service_form.options = [...services]

    let supervisor_form = {...form.supervisor_id}
    supervisor_form.options = [...supervisors]

    return ( 
        <SimpleModal open={open} onClose={onClose} maxWidth='md'>
            <div>
                <DisplayText variant='h6' color='primary' style={{ fontWeight: 500 }}>Agregar Timereport</DisplayText>
                <div className={classes.form}>
                    <Grid container spacing={3}>
                        <Grid item xs={12}>
                            <InputText data={form.date} onChange={onChange}/>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <InputText data={form.start_hour} onChange={onChange}/>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <InputText data={form.end_hour} onChange={onChange}/>
                        </Grid>
                        <Grid item xs={12}>
                            <InputSelect data={business_form} onChange={onChange} onInputChange={onFilterBusiness}/>
                        </Grid>
                        <Grid item xs={12}>
                            <InputSelect data={service_form} onChange={onChange} /* onInputChange={onSearchService} *//>
                        </Grid>
                        <Grid item xs={12}>
                            {/* <InputText data={form.task_description} onChange={onChange}/> */}
                            <RichTextForm label='Descripción' value={description} onChange={(value)=>setDescription(value)} />
                        </Grid>
                        <Grid item xs={12}>
                            {/* <InputText data={form.meeting_notes} onChange={onChange}/> */}
                            <RichTextForm label='Minuta' value={meeting_notes} onChange={(value)=>setMeetingNotes(value)} />
                        </Grid>
                        <Grid item xs={12}>
                            <InputFile id='file' file={file} label='Adjuntar evidencia' 
                            onChange={(file) => setFile(file)} onDelete={() => setFile(null)}/>
                        </Grid>
                        <Grid item xs={12}>
                            <Grid container alignItems='center'>
                                <Grid item><DisplayText style={{paddingRight:16}}>¿Requiere supervisión?</DisplayText></Grid>
                                <Grid item>
                                    <Grid container alignItems='center' >
                                        <Grid item><DisplayText variant='subtitle1'>No</DisplayText></Grid>
                                        <Grid item><Switch checked={supervisor} onChange={(e,v) => setSupervisor(v)} /></Grid>
                                        <Grid item><DisplayText variant='subtitle1'>Si</DisplayText></Grid>
                                    </Grid>
                                </Grid>
                            </Grid>   
                        </Grid>
                        {supervisor ? (
                            <Grid item xs={12}>
                                <InputSelect data={supervisor_form} onChange={onChange} onInputChange={onSearchSupervisor}/>
                            </Grid>
                        ) : null}
                        <Grid item xs={12} md={6}>
                            <InputRating data={form.complexity} onChange={onChange}/>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <InputRating data={form.client_satisfaction} onChange={onChange}/>
                        </Grid>
                        <Grid item xs={12}>
                            <InputSelect data={form.task_type_id} onChange={onChange} />
                        </Grid>
                        <Grid item xs={12}>
                            <ActionModalBar btnLabel='Guardar' loading={loading} error={error} onSubmit={onSubmit}/>
                        </Grid>
                    </Grid>
                </div>
            </div>
        </SimpleModal>
     );
}
 
export default AddTimeReportModal;

const formData = {
    date: {
        value: moment().format('YYYY-MM-DD'),
        error: false,
        isVisited: false,
        isRequired: true,
        isValid: true,
        config: {
            id: 'date',
            type: 'date',
            fullWidth: true,
            label: 'Fecha *',
            helper: 'La fecha seleccionada solo puede ser de máximo 2 días anteriores y posteriores',
            minDate: moment().subtract(2,'days').format('YYYY-MM-DD'),
            maxDate: moment().add(2,'days').format('YYYY-MM-DD'),
            onKeyDown:(e) => e.preventDefault()
        },
        rules: {type: 'dateRange', min:moment().subtract(2,'days').format('YYYY-MM-DD'), max:moment().add(2,'days').format('YYYY-MM-DD')}
    },
    business_id: {
        value: '',
        error: false,
        isVisited: false,
        isRequired: true,
        isValid: false,
        options:[],
        config: {
            id: 'business_id',
            type: 'select',
            fullWidth: true,
            label: 'Cliente *',
            placeholder:'Seleccione una opción',
            helper: 'Este campo es requerido'
        },
        rules: {type: 'select'}
    },
    service_id: {
        value: '',
        error: false,
        isVisited: false,
        isRequired: true,
        isValid: false,
        options:[],
        config: {
            id: 'service_id',
            type: 'select',
            fullWidth: true,
            label: 'Servicio*',
            placeholder:'Seleccione una opción',
            helper: 'Este campo es requerido'
        },
        rules: {type: 'select'}
    },
    task_description: {
        value: null,
        error: false,
        isVisited: false,
        isRequired: false,
        isValid: false,
        config: {
            id: 'task_description',
            type: 'text',
            fullWidth: true,
            label: 'Descripción de la tarea *',
            placeholder:'Ingrese la descripción',
            helper: 'Este campo es requerido',
            multiline:true,
            rows:3
        },
        rules: {type: 'distance', max:1024}
    },
    start_hour: {
        value: null,
        error: false,
        isVisited: false,
        isRequired: true,
        isValid: false,
        config: {
            id: 'start_hour',
            type: 'time',
            fullWidth: true,
            label: 'Hora de inicio *',
            helper: 'Este campo es requerido'
        },
        rules: {type: 'time'}
    },
    end_hour: {
        value: null,
        error: false,
        isVisited: false,
        isRequired: true,
        isValid: false,
        config: {
            id: 'end_hour',
            type: 'time',
            fullWidth: true,
            label: 'Hora de final *',
            helper: 'Este campo es requerido'
        },
        rules: {type: 'time'}
    },
    complexity: {
        value: null,
        error: false,
        isVisited: false,
        isRequired: true,
        isValid: false,
        config: {
            id: 'complexity',
            type: 'rating',
            fullWidth: true,
            label: 'Complejidad de la tarea *',
            helper: 'Debe seleccionar una opción'
        },
        rules: {type: 'numeric', min:1, max:10}
    },
    client_satisfaction: {
        value: null,
        error: false,
        isVisited: false,
        isRequired: true,
        isValid: false,
        config: {
            id: 'client_satisfaction',
            type: 'rating',
            fullWidth: true,
            label: 'Satisfacción del cliente *',
            helper: 'Debe seleccionar una opción'
        },
        rules: {type: 'numeric', min:1, max:10}
    },
    supervisor_id: {
        value: '',
        error: false,
        isVisited: false,
        isRequired: false,
        isValid: false,
        options:[],
        config: {
            id: 'supervisor_id',
            type: 'select',
            fullWidth: true,
            label: 'Supervisor',
            placeholder:'Seleccione una opción',
            helper: 'Este campo es requerido'
        },
        rules: {type: 'select'}
    },
    task_type_id: {
        value: '',
        error: false,
        isVisited: false,
        isRequired: true,
        isValid: false,
        options:[...catalogs.timereport_tasks],
        config: {
            id: 'task_type_id',
            type: 'select',
            fullWidth: true,
            label: 'Tipo de tarea *',
            placeholder:'Seleccione una opción',
            helper: 'Este campo es requerido'
        },
        rules: {type: 'select'}
    },
    meeting_notes: {
        value: null,
        error: false,
        isVisited: false,
        isRequired: false,
        isValid: false,
        config: {
            id: 'meeting_notes',
            type: 'text',
            fullWidth: true,
            label: 'Minuta de la reunión',
            placeholder:'Ingrese un texto...',
            helper: 'Este campo es requerido',
            multiline:true,
            rows:6
        },
        rules: {type: 'none'}
    },
}