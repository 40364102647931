export const contentData = {
    spanish: {
        title: 'Impuesto local sobre nómina',
        url: 'procstatetax',
        path: 'proc_state_tax',
        button: 'Editar',
        banners: {
            general: {
                title: '',
                button: 'Editar',
                form: {
                    fixed_responsible: 'Responsable impuesto local',
                    advisor: 'Asesor',
                    resposible: 'Responsable del cliente',
                    analyst: 'Analista',
                    supervisor: 'Supervisor'
                }
            },
            history: {
                title: 'Historial',
                filter: 'Año',
                url: 'statetax',
                path: 'proc_state_taxes',
                id: 'state_tax',
                maxStep: 3,
                location: true,
                header: [
                     { id: 1, label: 'Periodo', key: 'auto_date' },
                    /*{ id: 2, label: 'Turno de', key: 'fixed_responsible' },
                    { id: 3, label: 'Estado', key: 'fixed_state' },
                    { id: 4, label: 'Ciudad', key: 'fixed_city' },
                    { id: 5, label: 'Fecha límite', key: 'fixed_payday_limit' },
                    { id: 6, label: 'No. de paso', key: 'fixed_integral_solution_step_id' },
                    { id: 7, label: 'Prioridad', key: 'solution_priority' },
                    { id: 8, label: 'Porcentaje' },
                    { id: 9, label: 'Estatus', key: 'process_semaphore_id' }, */

                    //nuevo
                    { id: 3, label: 'Estado', key: 'fixed_state' },
                    { id: 4, label: 'Ciudad', key: 'fixed_city' },
                    { id: 11, label: 'Clasificación', key: 'fixed_client_classification' },
                    { id: 20, label: 'Importancia', key: 'fixed_importance' },
                    { id: 21, label: 'Socio', key: 'fixed_client_associate' },
                    { id: 23, label: 'Responsable', key: 'responsible' },
                    { id: 22, label: 'Asesor', key: 'fixed_business_advisor' },
                    { id: 24, label: 'Turno de', key: 'fixed_responsible' },
                    { id: 6, label: 'Fecha límite', key:'fixed_payday_limit' },
                    { id: 7, label: 'No. de paso', key:'fixed_state_tax_step_id' },
                    { id: 8, label: 'Prioridad', key:'solution_priority' },
                    { id: 9, label: 'Porcentaje', },
                    { id: 10, label: 'Estatus', key:'process_semaphore_id' }
                ],
            }
        },
    }
}
