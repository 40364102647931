import { Button, Collapse, Grid, Icon, IconButton, Slide, Typography, makeStyles } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import prueba from '../../../assets/platforms/teams.jpg'
import empty from '../../../assets/empty.png'
import cx from 'classnames'
import { imgServerS3 } from "../../../variables/config";
import GenericImage from "../../../components_v1/Structure/DisplayData/GenericImage";

const styles = makeStyles(theme => ({
    dot:{
        width:10,
        height:10,
        borderRadius:'50%',
        background:'#D0D0D0',
        cursor:'pointer'
    },
    dot_selected:{
        background:theme.palette.primary.main
    },
    dots_container:{
        marginTop:32
    },
    img:{
        borderRadius:20,
        width:'100%',
        height:'100%',
        objectFit:'cover'
    },
    img_container:{
        position:'absolute',
        width:'100%',
        height:'100%',
        top:0,
        left:0
    },
    container:{
        position:'relative',
        //paddingTop:'66%',
        width:'100%',
        
        //maxWidth:1000,
        //margin:'auto',
        //background:'red'
    },
    box:{
        width:'100%',
        maxWidth:1000,
        margin:'auto',
        minWidth:700
    },
    icon_button:{
        position:'absolute',
        right:20,
        zIndex:10,
        background:'white',
        color:theme.palette.secondary.main,
        '&:hover':{
            background:'white',
        }
    },
    go_button:{
        background:theme.palette.secondary.main,
        borderRadius:24,
        padding:'6px 16px',
        position:'absolute',
        zIndex:10,
        bottom:20,
        right:20,
        color:'white',
        textTransform:'none'
    },
    mask:{
        position:'absolute',
        top:0,
        left:0,
        width:'100%',
        height:'100%',
        background:'rgba(12, 37, 78, 0.58)',
        borderRadius:20,
        zIndex:5
    },
    title:{
        position:'absolute',
        bottom:60,
        left:40,
        zIndex:10,
        color:'white',
        fontSize:'2.4rem',
        textOverflow:'ellipsis',
        maxWidth:600
    },
    subtitle:{
        position:'absolute',
        bottom:20,
        left:40,
        zIndex:10,
        color:'white',
        fontSize:'1.8rem',
        textOverflow:'ellipsis',
        maxWidth:600
    }
}))

const Carrousel = ({banners, permissions, actions}) => {

    const classes = styles()

    

    let selected = 0

    const [sel, setSel] = useState(selected)
    const [collapse, setCollapse] = useState(true)
    

    console.log(banners)
    console.log(selected)

    const images = [
        {id:0, img:prueba},
        {id:1, img:empty},
        {id:2, img:prueba},
        {id:3, img:empty}
    ]

    let banner_permission_edit = false
    let banner_permission_add = false
    let banner_link = null
 

    if(permissions && permissions.length > 0){
        const banner_edit = permissions.find(el => el.permission_module_id === 75 && el.permission_type_id >= 3)
        const banner_add = permissions.find(el => el.permission_module_id === 75 && el.permission_type_id >= 4)
        if(banner_edit !== undefined) banner_permission_edit = true
        if(banner_add !== undefined) banner_permission_add = true
    }

    if(banners[sel]){
        banner_link = banners[sel].link
    }

    //imgServerS3
    /* let src = empty
    if(banners && banners[sel] ){
        src = banners[sel].web_image !== null ? imgServerS3 + banners[sel].web_image : empty
    } */

  

    useEffect(() => {
        
            //console.log('banners.length > 1')
            const interval = setInterval(onShowNext, 4000);
            return () => clearInterval(interval)
        
    }, [banners]);


    /* useEffect(() => {
        console.log('hola')
        setSel(selected)
    }, [selected]); */

    /* useEffect(() => {
    const interval = setInterval(test, 2000);
    return () => clearInterval(interval);
    }, []); */


    const onShowNext = () =>{
        //console.log(selected)
        //console.log(banners.length)
        setCollapse(true)
        const interval2 = setInterval(setCollapse(false), 4000);
        //const interval3 = setInterval(setCollapse(true), 4500);
        if(selected < banners.length - 1){
            selected = selected + 1
        }else{
            selected = 0
        }
        setSel(selected)
    }

    const onShowBanner = (_id) =>{
        selected = _id
        setSel(_id)
    }


  


    return(
        <div>
            {banners && banners.length > 0 ? <div>
                <div className={classes.box} >
                    <div className={classes.container} >
                        {banner_permission_add ? <IconButton onClick={()=>actions.onChangeModalStatus('add_banner', true)} className={classes.icon_button} style={{top:20}} ><Icon>add</Icon></IconButton> : null}
                        {banner_permission_edit ? <IconButton onClick={()=>actions.onEditBanner(banners[sel])} className={classes.icon_button} style={{top:90}} ><Icon>edit</Icon></IconButton> : null}
                        {banner_link !== null ? <Button href={banners[sel].link} target="_blank" className={classes.go_button} endIcon={<Icon>navigate_next</Icon>} >Ir</Button> : null}
                        {/* <div className={classes.img_container} >
                            <img src={src} className={classes.img} />
                        </div> */}  
                        <Typography noWrap className={classes.title} >{banners[sel]?.title}</Typography>
                        <Typography noWrap className={classes.subtitle} >{banners[sel]?.subtitle}</Typography>
                        <div className={classes.mask} ></div> 
                        <Collapse in={collapse} timeout={1000} >
                            <div>
                                <GenericImage ratio='4/3' borderRadius='20px' src={banners[sel]?.web_image} /> 
                            </div>
                        </Collapse>      
                    </div>
                </div>
                <Grid container spacing={2} className={classes.dots_container} justify="center" >
                    {banners.map((item, ind) => {
                        return(
                            <Grid item>
                                <div onClick={()=>onShowBanner(ind)} className={cx({[classes.dot]:true, [classes.dot_selected]: ind === sel})}></div>
                            </Grid>
                        )
                    })}
                </Grid>
            </div> : null}
        </div>
    )
}

export default Carrousel