import React from 'react'
import {
  Icon,
  Avatar,
  Typography,
  Grid,
  IconButton
} from '@material-ui/core'
import nouser from '../../assets/nouser.png'
import {imgServerS3} from '../../variables/config'
import specialityImage from '../../assets/speciality.png'
import {grey, green, cyan, blue, deepPurple,red} from '@material-ui/core/colors'


const stylesA = {
  icon:{
    color:blue[900]
  },
  text:{
    color:grey[700],
    fontWeight:'500'
  }
}

export const IconDataItem = (props) => {

  const data = props.data ? props.data : props.error;

  return(
    <div>
      <Grid container wrap="nowrap" spacing={1} alignItems='center'>
        <Grid item>
          <Icon style={stylesA.icon}>{props.icon}</Icon>
        </Grid>
        <Grid item xs zeroMinWidth>
          <Typography variant='body2' noWrap style={stylesA.text}>{data}</Typography>
        </Grid>
      </Grid>
    </div>
  )
}

export const ImageDataItem = (props) => {

  const data = props.data ? props.data : props.error;

  return(
    <div>
      <Grid container wrap="nowrap" spacing={1} alignItems='center'>
        <Grid item>
          <img src={props.image} alt='' width={32}/>
        </Grid>
        <Grid item xs zeroMinWidth>
          <Typography variant='body2' noWrap style={stylesA.text}>{data}</Typography>
        </Grid>
      </Grid>
    </div>
  )
}

const stylesD = {
  text:{
    fontWeight:'500',
    color:grey[700],
  },
  label:{
    fontWeight:'500',
    color:grey[500],
  }
}

export const DataItemWrapper = (props) => {

  let infoContent = null;

  if(!props.icon && !props.avatar && !props.image){
    let data = props.data ? props.data : props.error;
    infoContent = (
      <Typography variant='body2' noWrap style={stylesD.text}>
        {data}
      </Typography>
    )
  }else{
    if(props.icon){
      infoContent = (
        <IconDataItem data={props.data} icon={props.icon} error={props.error}/>
      )
    }else if(props.image){
      infoContent = (
        <ImageDataItem data={props.data} image={props.image} error={props.error}/>
      )
    }else{
      if(props.avatar){
        let fullname = ''
        fullname = fullname + (props.avatar.first_name ? props.avatar.first_name : props.error)
        fullname = fullname + ' '+ (props.avatar.last_name ? props.avatar.last_name : '')
        
        infoContent = (
          <TableItem 
            avatar={props.avatar.image}
            data={fullname}
          />
        )
        
      }
    }
  }
  let labelContent = null;
  if(props.label){
    labelContent = (
      <Grid item xs={12}>
        <Typography variant='body2' style={stylesD.label}>
          {props.label}
        </Typography>
      </Grid>
    )
  }

  return(
      <Grid container direction='column' spacing={1}>
        {labelContent}
        <Grid item xs={12}>
          {infoContent}
        </Grid>
      </Grid>
  )
}

const styles_error = {
  text:{
    color:red[900],
    fontWeight:'500'
  }
}

export const ErrorMessage = (props) => {
  return(
    <Typography variant={props.variant ? props.variant : 'body2'} style={styles_error.text}>
      {props.message}
    </Typography>
  )
}

const styles_cell_item = {
  text:{
    color:grey[700],
    fontWeight:'500'
  },
  icon:{
    color:blue[800]
  }
}

export const TableItem = (props) => {
  const {icon, avatar, src} = props
  let data = props.data ? props.data : props.error

  let iconContent = null
  if(icon){
    iconContent = (
      <Grid item>
        <Icon style={styles_cell_item.icon}>{icon}</Icon>
      </Grid>
    )
  }
  if(avatar){

    if(avatar && avatar !== 0 && avatar !== "0" && avatar !== ""){
      //console.log(imgServerS3+avatar)
      iconContent = (
        
        <Grid item>
          <Avatar 
            alt={nouser}
            src={imgServerS3+avatar}
            style={styles_cell_item.avatar}
          />
        </Grid>
        
      )
    }else{
      iconContent = (
        <Grid item>
          <Avatar 
            alt={'No data'}
            src={nouser}
            style={styles_cell_item.noavatar}
          />
        </Grid>
      )
    }
  }
  if(src){
    iconContent = (
      <Grid item>
        {src}
      </Grid>
    )
  }

  return(
    <Grid container wrap='nowrap' spacing={1} alignItems='center'>
        {iconContent}
        <Grid item xs zeroMinWidth>
          <Typography variant='body2' noWrap style={styles_cell_item.text}>
            {data}
          </Typography>
        </Grid>
    </Grid>
  )
}

const styles_subtitle = {
  text:{
    fontWeight:'500'
  }
}

export const Subtitle = (props) => {
  return(
    <Typography color='primary' style={styles_subtitle.text}>{props.data}</Typography>
  )
}

const styles_title = {
  text:{
    fontWeight:'500',
    fontSize:20
  }
}

export const Title = (props) => {
  return(
    <Typography color='primary' style={styles_title.text}>{props.data}</Typography>
  )
}

const styles_speciality = {
  text:{
    color:grey[700],
    fontWeight:'500'
  },
  image:{
    width:40
  }
}



export const DoctorSpeciality = (props) => {
  return(
    <div>
      <Grid container alignItems='center' spacing={3}>
        <Grid item>
          <img src={specialityImage} alt='' style={styles_speciality.image}/>
        </Grid>
        <Typography variant='body2' style={styles_speciality.text}>
          {props.data}
        </Typography>
      </Grid>
    </div>
  )
}

const styles_benefits = {
  text:{
    color:grey[700],
    fontWeight:'500'
  },
  good:{
    color:green[700]
  },
  bad:{
    color:red[700]
  }

}

export const BenefitItem = (props) => {
  const {data, status} = props

  let iconContent = null
  if(status === 1){
    iconContent = (
      <Icon fontSize='small' style={styles_benefits.good}>done</Icon>
    )
  }else{
    iconContent = (
      <Icon fontSize='small' style={styles_benefits.bad}>close</Icon>
    )
  }

  return(
    <div>
      <Grid container spacing={1} alignItems='center'>
        <Grid item xs>
          <Typography variant='body2' style={styles_benefits.text}>
            {data}
          </Typography>
        </Grid>
        <Grid item>
          {iconContent}
        </Grid>
      </Grid>
    </div>
  )
}

const styles_permissions = {
  text:{
    color:grey[700],
    fontWeight:'500'
  },
  icon:{
    fontSize:12
  }

}

export const PermissionItem = (props) => {
  const {module_name, permission_id, permission} = props

  let color = null
  switch(permission_id){
    case 5:
      color=green[700]
      break
    case 4:
      color=cyan[700]
      break;
    case 3:
      color=blue[700]
      break;
    case 2:
      color=deepPurple[700]
      break;
    case 1:
      color=red[700]
      break;
    default:
      color=grey[500]
  }

  return(
    <div>
      <Grid container spacing={1} alignItems='center'>
        <Grid item xs>
          <Typography variant='body2' style={styles_permissions.text}>
            {module_name}
          </Typography>
        </Grid>
        <Grid item>
          <div>
            <Grid container spacing={1} alignItems='center'>
              <Grid item>
                <Typography variant='caption' style={{color:grey[700]}}>
                  {permission}
                </Typography>
              </Grid>
              <Grid item>
                <Icon style={{...styles_permissions.icon, color:color}}>brightness_1</Icon>
              </Grid>
            </Grid>
          </div>
        </Grid>
      </Grid>
    </div>
  )
}

export const FileItemWrapper = (props) => {

  const {onFileAction, data} = props
  return(
    <Grid container align='center' spacing={0} wrap='nowrap'>
      <Grid item>
        <IconButton styles={{padding:8}} color='primary' onClick={() => onFileAction('upload',data)}>
          <Icon fontSize='small'>cloud_upload</Icon>
        </IconButton>
      </Grid>
      <Grid item>
        <IconButton styles={{padding:8}} color='primary' disabled={!data.status} onClick={() => onFileAction('download',data)}>
          <Icon fontSize='small'>cloud_download</Icon>
        </IconButton>
      </Grid>
    </Grid>
  )
}

export const FileItemWrapperDownload = (props) => {
  const {onFileAction, data} = props
  return(
    <Grid container spacing={0} wrap='nowrap'>
      <Grid item>
        <IconButton color='primary' disabled={!data.status} onClick={() => onFileAction('download',data)}>
          <Icon fontSize='small'>cloud_download</Icon>
        </IconButton>
      </Grid>
    </Grid>
  )
}