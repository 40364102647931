export const contentData = {
  spanish:{
    layout:{
      title:'Información del usuario',
      navigation:[
        {name:'Clientes',path:'/clients'},
        {name:'Detalles',path:'/clients/details/:id'},
        {name:'Usuarios',path:'/clients/details/:id/users'},
        {name:'Detalles',path:'/clients/details/:id/users/:iduser'},
      ],
    },
    modals:{
      warning_delete_business:{
        title:'¿Estás seguro?',
        message:'¿Realmente quieres eliminar este registro? Este proceso no puede ser revertido',
        cancelButton:'Cancelar',
        deleteButton:'Eliminar'
      },
      add_business:{
        title:'Agregar acceso',
        button:'Agregar',
        cancelButton:'Cancelar'
      }
    }
  }
}