import React, {Component} from 'react'
import moment from 'moment'
import { Grid, Switch, MenuItem, Select, InputBase, IconButton, Icon } from '@material-ui/core';
import {MuiPickersUtilsProvider, DatePicker} from '@material-ui/pickers';
import enLocale from "date-fns/locale/en-US";
import esLocale from "date-fns/locale/es";
import { withStyles } from '@material-ui/core/styles';
import ContentText from '../../../Texts/ContentText';
import DateFnsUtils from '@date-io/date-fns';
import styles from '../styles'
import { RoundedButton } from '../../../Buttons/GeneralButtons';
import SearchBar from './SearchBar';

const icon_list = ['list','arrow_upward','arrow_downward']

const samples = [
    {id:1, lista:['Arrow']},
    {id:2, lista:['Cat']},
    {id:3, lista:['Bern']},
    {id:4, lista:['Zumn']},
    {id:5, lista:['Dat']},
    {id:6, lista:['Epo']},
]

const localeMap = {
    spanish: esLocale,
    english: enLocale
};

class ControlTableFilter extends Component{
    state = {
        filterBy: 0,
        selectedMonth: null,
        initDate: null,
        finalDate: null,
    }

    componentDidMount = () => {
        const {filterBy, month, initDate, finalDate} = this.props
        this.setState({
            filterBy: filterBy,
            selectedMonth: month, 
            initDate: initDate,
            finalDate: finalDate
        })

        //console.log(samples.sort(this.onSorting))
    }

    onSorting = (a,b, direction) => {
        if(direction){
            return  -b.lista[0].localeCompare(a.lista[0])
        }
        return  b.lista[0].localeCompare(a.lista[0])
    }

    handleSwitch = event =>{
        const {updateData} = this.props
        updateData(event.target.checked);
    }

    handleChangeFilter = event =>{
        this.setState({filterBy : event.target.value})
    }

    handleMonthChange = date =>{
        this.setState({selectedMonth: date})
    }

    handleInitDate = date =>{
        this.setState({initDate: date})
    }

    handleFinalDate = date =>{
        this.setState({finalDate: date})
    }

    filterByMonth = () => {
        const {filterByMonth} = this.props
        const {filterBy, selectedMonth} = this.state
        const monthFormat = moment(selectedMonth).format('YYYY-MM-DD')
        filterByMonth(filterBy, monthFormat)
    }

    filterByRange = () => {
        const {filterByRange} = this.props
        const {filterBy, initDate, finalDate} = this.state
        const initDateFormat = moment(initDate).utc().startOf('month').format()
        const finalDateFormat = moment(finalDate).utc().endOf('month').format()
        filterByRange(filterBy, initDateFormat, finalDateFormat)
    }

    onChangeState = () => {
        const {position} = this.state
        let new_value = position
        new_value++
        if(new_value > 2){
            new_value = 0
        }
        this.setState({position:new_value})
    }

    render(){
        const {classes, completed, language, onSearch, position} = this.props;
        const {filterBy, selectedMonth, initDate, finalDate} = this.state

        const month = (
            <Grid
                container
                direction="row"
                alignItems="center"
            >
                <Grid item>
                    <ContentText text={'Mes & Año:'} align='none' medium/>
                </Grid>
                <Grid item>
                    <DatePicker
                        autoOk disableFuture
                        variant="inline"
                        openTo="year"
                        views={["year", "month"]}
                        value={selectedMonth}
                        className={classes.selectionButton}
                        onChange={this.handleMonthChange}
                    />
                </Grid>
                <Grid item>
                    <RoundedButton 
                        label={'Filtrar'} 
                        size='small' 
                        color='primary'
                        onClick={this.filterByMonth}
                    />
                </Grid>
            </Grid>        
        )

        const range = (
            <Grid
                container
                direction="row"
                justify="space-between"
                alignItems="center"
            >
                <Grid item>
                    <ContentText text={'Fecha Inicial:'} align='none' medium/>
                </Grid>
                <Grid item>
                    <DatePicker
                        autoOk disableFuture
                        variant="inline"
                        openTo="year"
                        views={["year", "month"]}
                        value={initDate}
                        maxDateMessage={'La fecha mínima debe ser menor a la máxima'}
                        className={classes.selectionButton}
                        onChange={this.handleInitDate}
                    />
                </Grid>
                <Grid item>
                    <ContentText text={'Fecha Final:'} align='none' medium/>
                </Grid>
                <Grid item>
                    <DatePicker
                        autoOk disableFuture
                        variant="inline"
                        openTo="year"
                        minDate={new Date(initDate)}
                        maxDateMessage={''}
                        views={["year", "month"]}
                        value={finalDate}
                        className={classes.selectionButton}
                        onChange={this.handleFinalDate}
                    />
                </Grid>
                <Grid item>
                    <RoundedButton 
                        label={'Filtrar'} 
                        size='small' 
                        color='primary'
                        onClick={this.filterByRange}
                    />
                </Grid>
            </Grid>
            
        )

        const filterMenu = (
            <Grid
                container
                direction="row"
                justify="space-between"
                alignItems="center"
            >
                <Grid item>
                    <ContentText text={'Filtrar por:'} align='none' medium/>
                </Grid>
                <Grid item>
                    <Select
                        labelId="filter"
                        id="filter"
                        value={filterBy}
                        onChange={this.handleChangeFilter}
                        className={classes.selectionButton}
                    >
                        <MenuItem value={0}>Mes</MenuItem>
                        <MenuItem value={1}>Rango</MenuItem>
                    </Select>
                </Grid>
            </Grid>
        )

        const switchButton = (
            <Grid 
                container
                direction="row"
                justify="space-between"
                alignItems="center"
            >
                <Grid item>
                    <ContentText text={'No completados'} align='none' medium/>
                </Grid>
                <Grid item>
                    <Switch
                        checked={completed}
                        onChange={this.handleSwitch}
                        color={classes.completedSwitch}
                        inputProps={{ 'aria-label': 'primary checkbox' }}
                    />
                </Grid>
                <Grid item>
                    <ContentText text={'Completados'} align='none' medium/>
                </Grid>
            </Grid>
        )

        return (
            <MuiPickersUtilsProvider utils={DateFnsUtils} locale={localeMap[language]}>
                <Grid 
                    container
                    direction="row"
                    alignItems="center"
                    spacing={2}
                >
                    <Grid item xs>
                        <Grid container wrap='nowrap'>
                            <Grid item xs>
                                <div style={{minWidth:300}}><SearchBar onSearch={onSearch}/></div>
                                
                            </Grid>
                            <Grid item>
                                <IconButton onClick={this.props.onChangeState}>
                                    <Icon>{icon_list[position]}</Icon>
                                </IconButton>
                            </Grid>
                        </Grid>
                        
                    </Grid>
                    <Grid item>
                        {filterMenu}
                    </Grid>
                    <Grid item>
                        {filterBy == 0 ? month : range}
                    </Grid>
                    <Grid item>
                        {switchButton}
                    </Grid>
                </Grid>
            </MuiPickersUtilsProvider>
        )
    }

    //onSearch = (value) => {
    //    console.log(value)
    //}
}

export default withStyles(styles)(ControlTableFilter)