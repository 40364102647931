import moment from "moment"
import { monthsName } from "../../../../../shared/utility"

export const cleanData2BChart = (data, content) => {
  let count = data ? data.reduce((acc, currentValue) => acc + currentValue.count, 0) : 0
  let _data = { count: count, data: [] }

  Object.keys(content).map(evaluation_type => {
    const chart = content[evaluation_type]
    let monthTotal = 0
    if (data && data.length > 0)
      monthTotal = data.reduce((acc, currentValue) => acc + currentValue[chart.id], 0)

    _data.data.push({
      name: chart.label,
      score: parseFloat(monthTotal).toFixed(2),
      color: chart.color
    })
  })

  return _data
}

export const cleanData2MixChart = (data, content, start, end) => {

  let count = data ? data.reduce((acc, currentValue) => acc + currentValue.count, 0) : 0
  let _data = { count: count, data: [] }

  const startMonth = moment(start).month()
  const endMonth = moment(end).month()
  const startYear = moment(start).year()
  const endYear = moment(end).year()

  if ((endYear - startYear) > 0) {
    for (let y = startYear; y <= endYear; y++) {
      let _startMonth = y === startYear ? startMonth : 0
      let _endMonth = y === endYear ? endMonth : 11

      for (let m = _startMonth; m <= _endMonth; m++) {
        let _monthTotal = {}

        Object.keys(content).map(evaluation_type => {
          const chart = content[evaluation_type]
          let evaluations = null

          if (data && data.length > 0)
            evaluations = data.filter(e => e.selected_month == m + 1)
          let total_topic = evaluations ? evaluations.reduce((acc, currentValue) => acc + currentValue[chart.id], 0) : 0
          _monthTotal = { ..._monthTotal, [evaluation_type]: total_topic }
        })

        _data.data.push({ name: `${monthsName[m]} ${y}`, ..._monthTotal })
      }
    }
  }
  else {

    for (let m = startMonth; m <= endMonth; m++) {
      let _monthTotal = {}

      Object.keys(content).map(evaluation_type => {
        const chart = content[evaluation_type]
        let evaluations = null
        if (data && data.length > 0)
          evaluations = data.filter(e => e.selected_month == m + 1)
        let total_topic = evaluations ? evaluations.reduce((acc, currentValue) => acc + currentValue[chart.id], 0) : 0
        _monthTotal = { ..._monthTotal, [evaluation_type]: total_topic }
      })

      _data.data.push({ name: monthsName[m], ..._monthTotal })
    }

  }
  return _data
}