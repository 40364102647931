import { Grid, makeStyles, Switch } from '@material-ui/core';
import React, { useState, useEffect } from 'react';
import ActionModalBar from '../../../../../../components_v1/Actions/ActionModalBar';
import InputSelect from '../../../../../../components_v1/Forms/InputSelect';
import SimpleModal from '../../../../../../components_v1/Structure/Modals/SimpleModal';
import DisplayText from '../../../../../../components_v1/Texts/DisplayText';
import { isFormValid, onCheckDateForm, onGetDateFormat, onGetErrorMessage, onGetFormData, onSetErrorsToForm } from '../../../../../../shared/utility';
import { catalogs } from '../../../../../../texts/esp/catalogs';
import { request_update_client, request_employees } from '../requests';
import { onInitForm } from '../../../../../../shared/utility';
import InputText from '../../../../../../components_v1/Forms/InputText';

const useStyles = makeStyles(theme => ({
    form:{
        paddingTop:32,
        paddingBottom:32
    }
}))


const EditContractModal = (props) => {

    const classes = useStyles()
    const { id, open, origin_data, onClose, onUpdateCompleted } = props
    const [form, setForm] = useState(JSON.parse(JSON.stringify(formData)))
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState('')

    useEffect(() => {
        if (!open) {
            setLoading(true)
            let _form = JSON.parse(JSON.stringify(formData))
            _form = onInitForm(_form,origin_data)
            setForm({..._form})
            setError(''); 
            setLoading(false);
        }
    }, [open,origin_data])

    const onChange = (data) => {
        let temp = { ...form }
        const id = data.config.id
        temp[id] = { ...data }
        setForm(temp)
    }

    const onSubmit = async () => {
        const errors = isFormValid(form)
        /// Check if the form is valid
        if(errors && errors.length){
            const new_form = onSetErrorsToForm(form, errors)
            setForm(new_form)
            return
        }
        
        //Extract data
        let data2send = onGetFormData(form)
        if(data2send.contract_value !== undefined || data2send.contract_value !== null){
            data2send.iva = parseFloat(data2send.contract_value)*0.16
            data2send.total_value = parseFloat(data2send.contract_value) + data2send.iva
        }
        //Send data
        setLoading(true)
        try {
            const _updatedClient = await request_update_client(id, data2send)
            onUpdateCompleted(_updatedClient)
            onClose()
        } catch (error) {
            console.log(error)
            setError(onGetErrorMessage(error))
        }
        setLoading(false)

    }

    return ( 
        <SimpleModal open={open} onClose={onClose} maxWidth='md'>
            <div>
                <DisplayText variant='h6' color='primary' style={{ fontWeight: 500 }}>{'Información de contrato'}</DisplayText>
                <div className={classes.form}>
                    <Grid container spacing={3}>
                        <Grid item xs={12} md={6}>
                            <InputSelect data={form.who_charges_id} onChange={onChange}/>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <InputSelect data={form.contract_duration_id} onChange={onChange}/>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <InputSelect data={form.contract_periodicity_id} onChange={onChange}/>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <InputText data={form.anual_increment} onChange={onChange}/>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <InputText data={form.outsourcing_comission} onChange={onChange}/>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <InputText data={form.contract_value} onChange={onChange}/>
                        </Grid>
                        <Grid item xs={12}>
                            <InputText data={form.contract_special_conditions} onChange={onChange}/>
                        </Grid>
                        <Grid item xs={12}>
                            <ActionModalBar btnLabel='Guardar' loading={loading} error={error} onSubmit={onSubmit}/>
                        </Grid>
                    </Grid>
                </div>
            </div>
        </SimpleModal>
     );
}
 
export default EditContractModal;

const formData = {
    who_charges_id:{
        value: null,
        error: false,
        isVisited: false,
        isRequired: false,
        isValid: false,
        options:[...catalogs.client_who_charges_options],
        config:{
            id:'who_charges_id',
            type:'simple_select',
            label:'¿Quién cobra?',
            placeholder:'¿Quién cobra?',
            fullWidth: true,
        },
        rules:{
          type:'none',
        }
      },
      contract_duration_id:{
        value: null,
        error: false,
        isVisited: false,
        isRequired: false,
        isValid: false,
        options:[...catalogs.client_contract_durations],
        config:{
            id:'contract_duration_id',
            type:'simple_select',
            label:'Duración',
            placeholder:'Duración',
            fullWidth: true,
        },
        rules:{
          type:'none',
        }
      },
      contract_periodicity_id:{
        value: null,
        error: false,
        isVisited: false,
        isRequired: false,
        isValid: false,
        options:[...catalogs.client_contract_periodicities],
        config:{
            id:'contract_periodicity_id',
            type:'simple_select',
            label:'Periodicidad',
            placeholder:'Periodicidad',
            fullWidth: true,
        },
        rules:{
          type:'none',
        }
      },
      anual_increment:{
        value: null,
        error: false,
        isVisited: false,
        isRequired: false,
        isValid: false,
        config:{
            id:'anual_increment',
            type:'number',
            fullWidth: true,
            icon:{name:'trending_up',position:'start'},
            label:'Incremento anual',
            placeholder:'Incremento anual',
            position:'start'
        },
        rules:{
          type:'none'
        }
      },
      outsourcing_comission:{
        value: null,
        error: false,
        isVisited: false,
        isRequired: false,
        isValid: false,
        config:{
            id:'outsourcing_comission',
            type:'number',
            fullWidth: true,
            label:'Comisión por subcontratación',
            placeholder:'Comisión por subcontratación',
            icon:{name:'attach_money',position:'start'},
            position:'start'
        },
        rules:{
          type:'none'
        }
      },
      contract_value:{
        value: null,
        error: false,
        isVisited: false,
        isRequired: false,
        isValid: false,
        config:{
            id:'contract_value',
            type:'number',
            fullWidth: true,
            label:'Valor del contrato',
            placeholder:'Valor del contrato',
            icon:{name:'attach_money',position:'start'},
            position:'start'
        },
        rules:{
          type:'none'
        }
      },
      
      contract_special_conditions:{
        value: null,
        error: false,
        isVisited: false,
        isRequired: false,
        isValid: false,
        config:{
            id:'contract_special_conditions',
            type:'text',
            label:'Condiciones Especiales',
            placeholder:'Condiciones Especiales',
            fullWidth: true,
        },
        rules:{
          type:'none'
        }
    }    
}