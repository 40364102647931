export const contentData = {
  spanish:{
    title:'Agregar registro estatal',
    editTitle:'Editar registro estatal',
    nodata:'Sin Registro',
    error:'Sin registro',
    button:'Guardar',
    cancelButton:'Cancelar',
    image_button:'Cambiar',
    form:{
      user:{
        label:'Usuario',
        placeholder:'Usuario',
      },
      password:{
        label:'Contraseña',
        placeholder:'Contraseña',
      },
      limit_date:{
        label:'Fecha Límite',
        placeholder:'Fecha Límite',
      },
      state_id:{
        label:'Estado',
        placeholder:'Estado'
      }
    },
  },
}
