import {mainServer} from '../../../../../variables/config.jsx'
import FileSaver from 'file-saver'
import axios from 'axios'

export const request_service_item = async (id, accountID) => {
  const logData = JSON.parse(localStorage.getItem('data'));
  mainServer.instance.defaults.headers.common['Authorization'] = logData.token;
  let response;
  response = await mainServer.instance.get("/consulting/"+accountID.toString())
  let service = response.data.data.consulting;
  response = await mainServer.instance.get("/businessdocument/"+id.toString());
  service = {...service, 
            rfc:response.data.data.tributary_mailbox_statuses.rfc}  
  return service;
}

export const request_add_service_item = async (id, data) => {
  const logData = JSON.parse(localStorage.getItem('data'));
  mainServer.instance.defaults.headers.common['Authorization'] = logData.token;
  await mainServer.instance.post("/consulting",data);
  let response;
  response = await mainServer.instance.get("/consulting/"+id.toString()+"/list")
  return response.data.data.consulting
}

export const request_edit_service_item = async (id, accountID, data) => {
  const logData = JSON.parse(localStorage.getItem('data'));
  mainServer.instance.defaults.headers.common['Authorization'] = logData.token;
  await mainServer.instance.patch("/consulting/" + accountID, data);
  await mainServer.instance.patch("/businessdocument/"+id.toString(), {rfc:data.rfc});
  let response;
  response = await mainServer.instance.get("/consulting/"+accountID.toString())
  let service = response.data.data.consulting;
  response = await mainServer.instance.get("/businessdocument/"+id.toString());
  service = {...service, 
            rfc:response.data.data.tributary_mailbox_statuses.rfc}  
  return service;
}

export const request_delete_service_item = async (id, stampID) => {
  const logData = JSON.parse(localStorage.getItem('data'));
  mainServer.instance.defaults.headers.common['Authorization'] = logData.token;
  await mainServer.instance.delete("/consulting/" + stampID);
  let response;
  response = await mainServer.instance.get("/consulting/"+id.toString())
  return response.data.data.consulting
}

/**************************************** */
export const request_users = async () => {
  const logData = JSON.parse(localStorage.getItem('data'));
  mainServer.instance.defaults.headers.common['Authorization'] = logData.token;
  let response;
  response = await mainServer.instance.get("/user/employee/list/");
  return response.data.data.employees
}

export const request_uploadFile = async(id, businessID, path,file, ext, type) => {
  const logData = JSON.parse(localStorage.getItem('data'));
  mainServer.instance.defaults.headers.common['Authorization'] = logData.token
  let url = ''
  if(type){
    url = 'businessdocument/' + businessID + '/' + path
  }else{
    url = 'consulting/' + id + '/' + path
  }
  let response = await mainServer.instance.post(url, ext)
  const options = {headers: {'Content-Type': file.type}}
  delete axios.defaults.headers.common["Authorization"]
  response = await axios.put(response.data.data.path, file, options);
  mainServer.instance.defaults.headers.common['Authorization'] = logData.token;
  response = await mainServer.instance.get("/consulting/"+id.toString())
  return response.data.data.consulting
}

export const request_downloadFile = async(id,businessID,path,type) => {
  const logData = JSON.parse(localStorage.getItem('data'));
  mainServer.instance.defaults.headers.common['Authorization'] = logData.token;
  let response;
  let url = ''
  if(type){
    url = 'businessdocument/' + businessID + '/' + path
  }else{
    url = 'consulting/' + id + '/' + path
  }
  response = await mainServer.instance.get(url)
  delete axios.defaults.headers.common["Authorization"]
  response = await axios.get(response.data.data.path, {responseType: 'blob'})
  FileSaver.saveAs(response.data, path)
}

export const request_deleteFile = async(id,businessID,path,type) => {
  const logData = JSON.parse(localStorage.getItem('data'));
  mainServer.instance.defaults.headers.common['Authorization'] = logData.token;
  let response
  let url = ''
  if(type){
    url = 'businessdocument/' + businessID + '/' + path
  }else{
    url = 'consulting/' + id + '/' + path
  }
  await mainServer.instance.delete(url)
  response = await mainServer.instance.get("/consulting/"+id.toString())
  return response.data.data.consulting
}