import {mainServer} from '../../../../variables/config'

export const request_employees = async () => {
  const logData = JSON.parse(localStorage.getItem('data'))
  mainServer.instance.defaults.headers.common['Authorization'] = logData.token
  let response;
  response = await mainServer.instance.get("/user/employee/list");
  return response.data.data.employees
}

export const request_clients = async () => {
  const logData = JSON.parse(localStorage.getItem('data'))
  mainServer.instance.defaults.headers.common['Authorization'] = logData.token
  let response;
  response = await mainServer.instance.get("/client/list");
  return response.data.data.clients
}

export const request_delete_client = async (id) => {
  const logData = JSON.parse(localStorage.getItem('data'))
  mainServer.instance.defaults.headers.common['Authorization'] = logData.token
  await mainServer.instance.delete("/client/"+id)
  let response;
  response = await mainServer.instance.get("/client/list");
  return response.data.data.clients
}

export const request_clientusers = async (id) => {
  const logData = JSON.parse(localStorage.getItem('data'));
  mainServer.instance.defaults.headers.common['Authorization'] = logData.token;
  let response;
  response = await mainServer.instance.get("/clientuser/listbyclient/"+id);
  return response.data.data.client_users;
}
