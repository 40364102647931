import {mainServer} from '../../../../variables/config'

export const sleep = (time) => {
  return new Promise((resolve) => setTimeout(resolve, time));
}

export const request_add_business = async (data) => {
  const logData = JSON.parse(localStorage.getItem('data'))
  const data2send = {rfc:data.rfc}
  mainServer.instance.defaults.headers.common['Authorization'] = logData.token
  let response = await mainServer.instance.post("/business/", data)
  const id = response.data.data.business_id
  await mainServer.instance.patch("/businessdocument/"+id, data2send)
  return id;

}

export const request_employees = async () => {
  const logData = JSON.parse(localStorage.getItem('data'))
  mainServer.instance.defaults.headers.common['Authorization'] = logData.token
  let response;
    response = await mainServer.instance.get("/user/employee/list");
    return response.data.data.employees
}
