import { Avatar, Grid } from '@material-ui/core';
import React from 'react';
import nopicture from '../../../assets/nouser.png'
import { isValidDocument } from '../../../shared/utility';
import { imgServerS3 } from '../../../variables/config';
import DisplayText from '../../Texts/DisplayText';

const AvatarName = ({name, imgSrc, styles}) => {

    let selectedImage = nopicture

    if(imgSrc){
        const isValid = isValidDocument(imgSrc)
        selectedImage = isValid ? `${imgServerS3}${imgSrc}` : nopicture
    }

    return ( 
        <Grid container wrap='nowrap' spacing={1} alignItems='center' >
            <Grid item><Avatar style={styles} src={selectedImage} alt='' /></Grid>
            <Grid item xs>
                <DisplayText variant='body2' >{name}</DisplayText>
            </Grid>
        </Grid>
    );
}
 
export default AvatarName;