export const contentData = {
  spanish:{
    modals:{
      warning_delete_item:{
        title:'¿Estás seguro?',
        message:'¿Realmente quieres eliminar este registro? Este proceso no puede ser revertido',
        cancelButton:'Cancelar',
        deleteButton:'Eliminar'
      },
      edit_service:{
        title:'Editar servicio',
        button:'Guardar',
        cancelButton:'Cancelar'
      }
    },
    basicform:{
      title:'Información Detallada',
      button:'Editar',
      nodata:'Sin Registro',
      data:{
        name:{
          label:'',
          data:'',
        },
        company_type:{
          label:'',
          data:'',
        },
        process:{
          label:'',
          data:'',
        },
        due_date:{
          label:'',
          data:'',
        },
        responsible:{
          label:'',
          data:'',
        },
        estimated_delivery_date:{
          label:'',
          data:'',
        },
        comments:{
          label:'',
          data:'',
        },
        project_costs:{
          label:'',
          data:'',
        },
        provider:{
          label:'',
          data:'',
        },
        cost:{
          label:'',
          data:'',
        },
        sell_price:{
          label:'',
          data:'',
        }
      },
      content:{
        name:{
          label:'Nombre del trabajador'
        },
        company_type:{
          label:'Tipo de Sociedad'
        },
        process:{
          label:'Seleccionar Proceso'
        },
        due_date:{
          label:'Fecha requerida'
        },
        responsible:{
          label:'Responsable'
        },
        estimated_delivery_date:{
          label:'Fecha Estimada de Entrega'
        },
        comments:{
          label:'Comentarios'
        },
        project_costs:{
          label:'Costos del Proyecto'
        },
        provider:{
          label:'Proveedor'
        },
        cost:{
          label:'Costo'
        },
        sell_price:{
          label:'Precio de venta'
        }
      },
      documents:{
        title:'Documentos',
        header:{title:'Archivos', actions:'Acciones'},
        files:[
          {key:'possible_names',name:'Posibles nombres de la sociedad',path:'possiblenames', status:false},
          {key:'oficial_id',name:'Identificación oficial',path:'oficialid', status:false, businessDocument:true},
          {key:'address_proof',name:'Comprobante de domicilio',path:'addressproof', status:false, businessDocument:true},
          {key:'birth_certificate',name:'Acta de nacimiento',path:'birthcertificate', status:false, businessDocument:true},
          {key:'fiscal_situation_proof',name:'Constancia de Situación Fiscal',path:'fiscalsituationproof', status:false, businessDocument:true},
          {key:'curp',name:'CURP',path:'curp', status:false, businessDocument:true},
          {key:'constitution_format',name:'Constitución de Sociedad Socios Mexicanos',path:'constitutionformat', status:false},
          {key:'documents',name:'Documentos',path:'documents', status:false},
        
        ] 
      }
    }
  }
}