import React, { useState, useEffect } from 'react';
import { Grid, makeStyles } from '@material-ui/core';
import ActionModalBar from '../../../../components_v1/Actions/ActionModalBar';
import InputSelect from '../../../../components_v1/Forms/InputSelect';
import SimpleModal from '../../../../components_v1/Structure/Modals/SimpleModal';
import DisplayText from '../../../../components_v1/Texts/DisplayText';
import { isFormValid, onGetFormData, onGetSelectedFormData, onSetErrorsToForm } from '../../../../shared/utility';
import { catalogs } from '../../../../texts/esp/catalogs';
import { request_add_process } from '../requests';


const useStyles = makeStyles(theme => ({
    form: {
        paddingTop: 32,
        //paddingBottom:32
    }//
}))

const AddProcessModal = (props) => {

    const classes = useStyles()
    const { id, open, onClose, process, onUpdateCompleted } = props
    const [form, setForm] = useState(JSON.parse(JSON.stringify(formData)))
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState('')

    useEffect(() => {
        let processes = []
        catalogs.process.forEach(item => {
            const foundService = process.find(el => el.process_id === item.value)
            if (foundService === null || foundService === undefined || !foundService)
                processes.push(item)
        })
        let temp = JSON.parse(JSON.stringify(formData))
        temp.process_id.options = processes
        setForm(temp)
        if (!open) { setError(''); setLoading(false); }
    }, [open])

    const onChange = (data) => {
        let temp = { ...form }
        const id = data.config.id
        temp[id] = { ...data }
        setForm(temp)
    }

    const onSubmit = async () => {

        const errors = isFormValid(form)
        /// Check if the form is valid
        if (errors && errors.length) {
            const new_form = onSetErrorsToForm(form, errors)
            setForm(new_form)
            setError('Seleccione algún proceso')
            return
        }

        //Extract data
        let data2send = onGetFormData(form)
        console.log(data2send)

        //Send data
        setLoading(true)
        try {
            await request_add_process(id, data2send)
            onUpdateCompleted()
            //history.push(`/timereports/${timereport_id}`)

        } catch (error) {
            console.log(error)
            setError('Hubo un error')
            setLoading(false)
        }

    }

    return (
        <SimpleModal open={open} onClose={onClose} maxWidth='sm'>
            <div>
                <DisplayText variant='h6' color='primary' style={{ fontWeight: 500 }}>Agregar proceso</DisplayText>
                <div className={classes.form}>
                    <Grid container spacing={3}>
                        <Grid item xs={12}>
                            <InputSelect data={form.process_id} onChange={onChange} />
                        </Grid>
                        <Grid item xs={12}>
                            <ActionModalBar btnLabel='Agregar' loading={loading} error={error} onSubmit={onSubmit} />
                        </Grid>
                    </Grid>
                </div>
            </div>
        </SimpleModal>
    );
}

export default AddProcessModal;

const formData = {
    process_id: {
        value: null,
        error: false,
        isVisited: false,
        isRequired: true,
        isValid: false,
        options: [],
        config: {
            id: 'process_id', type: 'simple_select', fullWidth: true,
            label: 'Seleccione un proceso',
            placeholder: 'Seleccione una opción...'
        },
        rules: {
            type: 'none',
        }
    },
}