export const contentData = {
    spanish:{
      nodata:'Sin Registro',
      error:'Sin registro',
      button:'Guardar',
      cancelButton:'Cancelar',
      image_button:'Cambiar',
      form:{
        tax_payment_date:{
          placeholder:'Fecha de Pago de Impuesto'
        },
        presentation_limit_date:{
          placeholder:'Fecha límite de presentación'
        },
        process:{
          placeholder:'Proceso'
        },
        status:{
          placeholder:'Estatus'
        },
        client_attention_info_1_id:{
          placeholder:'Infomación Atencion a Cliente 1'
        },
        client_attention_info_2_id:{
          placeholder:'Infomación Atencion a Cliente 2'
        },
        payment_quantity:{
          placeholder:'Cantidad a Pagar'
        },
        surcharges_and_updates:{
          placeholder:'Recargos y Actualizaciones'
        },
        payment_total:{
          placeholder:'Total a Pagar'
        },
        payment_limit_date:{
          placeholder:'Fecha Límite de Pago'
        },
        responsible_id:{
          placeholder:'Responsable'
        },
        client_comment:{
          placeholder:'Comentario del Cliente'
        },
        notes:{
          placeholder:'Notas'
        },
        extra_hours:{
          placeholder:'Horas Extra'
        },
        extra_charges:{
          placeholder:'Cargos Extra'
        },
        collections_charge:{
          placeholder:'Cargo a Cobranza'
        },
        completition_date:{
          placeholder:'Fecha de Completado'
        }
      }
    }
  }
