import React, {useState, useEffect} from 'react'
import LoadingContainer from '../../../../../components_v1/Structure/Layouts/LoadingContainer'
import SimpleTable from './components/SimpleTable'
import { contentData } from './content'
import { request_efirmaactions_list, request_service } from './requests'
import queryString from 'query-string' 
import { onGetCatalogs, onGetErrorMessage } from '../../../../../shared/utility'
import { fi } from 'date-fns/locale'
import { useStaticState } from '@material-ui/pickers'

const HistoricalView = props => {

  const {language, businessID, permission} = props

  const [loading, setLoading] = useState(false)
  const [sending, setSending] = useState(false)
  const [error, setError] = useState(false)
  const [success, setSuccess] = useState(false)

  const [efirmaact_list, setEfirmaActList] = useState([])
  
  //Table states
  const [total, setTotal] = useState(0)
  const [tableFilter, setTableFilter] = useState({limit:10,offset:0, order_by:'id_certificate_action', order:1})
  const [search, setSearch] = useState('')
  const [filter, setFilter] = useState({value:-1, label:'Todos'})
  const [certificates_filter, setCertificatesFilter] = useState([])

  const onChangeFilter = (_id) =>{
    console.log(_id)
    setFilter(_id)
  }

  //DATA
  const content = contentData['spanish']
  //console.log(content)

  useEffect(() => {
    const initModule = async() => {
      console.log(businessID)
      try {
        setLoading(true)
        onRequestEfirmaActParams()
        /* const _efa = await request_efirmaactions_list()
        console.log(_efa)
        setEfirmaActList(_efa.efirma_actions)
        setTotal(_efa.count) */
      } catch (error) {
        console.log(error)
        //setError(onGetErrorMessage(error))
      }
      setLoading(false)
    }
    initModule()
    //},[])
    },[tableFilter, search, filter])

  const onRequestEfirmaActParams = async() => {
    try {
      const {limit, offset, order_by, order} = tableFilter
      const param2send = {limit:limit, offset:offset*limit, order_by:order_by, order:order}
      let params = `&${queryString.stringify(param2send)}`
      let filterArgs = [{field:'business_id', operator:'=', value:businessID}]
      if(filter.value !== -1) filterArgs.push({"field":"certificate_id","operator":"=","value":`${filter?filter.value:''}`})
      if (search) filterArgs.push(
        //{"field":"certificate_action_type","operator":"LIKE","value":`%${search?search:''}%`},
        {"field":"user_name","operator":"LIKE","value":`%${search?search:''}%`}
        )
      
      //let final_filter = {"AND":filterArgs}
      filterArgs = encodeURIComponent(JSON.stringify(filterArgs))
      params = `&${queryString.stringify(param2send)}&filter=${filterArgs}` //&filter=${filterArgs}
      //console.log(filterArgs)
      //console.log(params)
      const _efa = await request_efirmaactions_list(params)
      //console.log(_efa)

      setEfirmaActList(_efa.certificate_actions)
      setTotal(_efa.count)

      const _service = await request_service(businessID)
      setCertificatesFilter(onGetCatalogs(_service.certificates, 'id_certificate', 'name'))
      console.log(onGetCatalogs(_service.certificates, 'id_certificate', 'name'))
    } catch (error) {
      console.log(error)
      setError(onGetErrorMessage(error))
    }
  }

  /* const onPreDeleteItem = (id) => {
    console.log(id)
    setSelectedItem(id)
    setWarningModal(true)
    console.log(id)
  } */

  const onChangeSearch = (val) => {
    setSearch(val)
    setTableFilter({...tableFilter, offset:0})
  }

  /* const onSelectedItem = (item) => {
    setSelectedItem(item)
    setEfirmaDetailsModal(true)
  } */

  /* const onUpdateCompleted = async() => {
    try {
      await onRequestModelData()
      setEfirmaDetailsModal(false)
      setAddItemModal(false)
      setWarningModal(false)
    } catch (error) {
      console.log(error)
      setError(onGetErrorMessage(error))
    }
  } */

    return(
        <LoadingContainer sending={sending} success={success} error={error} onCloseError={()=>setError(null)}>    
            <div>
                {/* <DeleteEfirmaModal origin={selected_item} message={content.delete_modal.message} open={warningmodal} onClose={() => setWarningModal(false)} onUpdateCompleted={onUpdateCompleted}/>
                <AddItemModal businessID={businessID} open={additemmodal} onClose={() => setAddItemModal(false)} 
                content={content.add_modal} language={language} onUpdateCompleted={onUpdateCompleted}/>
                <EfirmaDetailsModal open={efirma_details_modal} onClose={() => setEfirmaDetailsModal(false)} 
                businessID={businessID} init_data={selected_item} permission={permission} onUpdateCompleted={onUpdateCompleted}/> */}
                <SimpleTable data={efirmaact_list} total={total} loading={loading} permission={permission}
                    search={search} onChangeSearch={onChangeSearch} filterOptions={[{value:-1, label:'Todos'},...certificates_filter]} filter={filter}
                    onChangeFilter={onChangeFilter}
                    onSelectedItem={(data) => console.log(data) }
                    tableFilter={tableFilter}  onUpdateTableFilter={(data) => setTableFilter(data)}
                    />
            </div>
        </LoadingContainer>
    )
}

export default HistoricalView