export const contentData = {
  spanish:{
    nodata:'Sin Registro',
    notable:'No existen registros para ese año',
    tableTitle:'Logs',
    header:[
      {text:'ID',align:'inherit'},
      {text:'Fecha en que se realizó el servicio',align:'inherit'},
      {text:'Notas',align:'inherit'},
      {text:'Completado',align:'inherit'},
      {text:'Comprobante Carga',align:'center'},
    ],
    modals:{
      warning_delete_item:{
        title:'¿Estás seguro?',
        message:'¿Realmente quieres eliminar este registro? Este proceso no puede ser revertido',
        cancelButton:'Cancelar',
        deleteButton:'Eliminar'
      }
    },
  },
  english:{

  }
}