import React, {Component} from 'react'
import { withStyles } from '@material-ui/core/styles'
import { Grid} from '@material-ui/core'
import styles from './styles'
import {contentData} from './content'

import BasicView from '../../../../components/Layouts/BasicView/BasicView'
import EditProfileInfo from './EditProfileInfo/EditProfileInfo'
import EditWorkInfo from './EditWorkInfo/EditWorkInfo'
import EditContactInfo from './EditContactInfo/EditContactInfo'
import EditBenefits from './EditBenefits/EditBenefits';
import EditPermissions from './EditPermissions/EditPermissions'


import ProfilePictureModal from '../../../../components/Modals/ProfilePictureModal/ProfilePictureModal'

import {
  request_user_info,
  request_uploadFile,
  request_deleteFile,
  request_update_user,
  request_update_permissions,
  sleep
} from './requests'


class EditProfile extends Component{

  state = {
    isloading:false,
    user:null,
    permissions:[],
    modals:{
      imagemodal:{open:false,issending:false,error:null},
      warning_delete_file:{open:false, issending:false, error:null, id:null}
    },
    forms:{
      basicinfo:{issending:false, error:null},
      workinfo:{issending:false, error:null},
      contactinfo:{issending:false, error:null},
      benefits:{issending:false, error:null},
      permission:{issending:false, error:null},
    },
    success:{open:false,timeout:2000,type:'success',message:'Successful action!'},
  }

  componentDidMount(){
    this.initModule()
  }

  onCloseSnackbar = (id) => {
    let temp = this.state[id];
    temp.open = false;
    this.setState({[id]:{...temp}})
  }

  onActivateSuccessSnack = () => {
    const {success} = this.state;
    let success_temp = {...success};
    success_temp.open = true;
    this.setState({success:{...success_temp}})
  }

  initModule = async() => {
    this.setState({isloading:true})
    const {permissions} = this.props
    this.setState({permissions:[...permissions]})
    let response = await request_user_info()
    this.setState({user:{...response}})
    this.setState({isloading:false})
  }

  onReturn = () => {
    const {history} = this.props
    history.push('/profile/')
  }

  onCloseModal = (type) => {
    this.onEditModal(type, {open:false, issending:false, error:null})
  }

  onEditModal = (section, data) => {
    const {modals} = this.state
    let tempModals = {...modals}
    tempModals[section]={...tempModals[section],...data}
    this.setState({modals:{...tempModals}})
  }

  onEditForm = (section, data) => {
    const {forms} = this.state
    let temp = {...forms}
    temp[section]={...temp[section],...data}
    this.setState({forms:{...temp}})
  }

  onOpenFileModal = (section, filedata) => {
    const {modals} = this.state
    let tempModals = {...modals}
    tempModals[section].open=true
    if(filedata){
      tempModals[section].data = {...filedata}
    }
    this.setState({modals:{...tempModals}})
  }

  onSubmitPicture = async(type, file) => {
    try {
      this.onEditModal('imagemodal',{issending:true})
      const aux = file[0].name.split('.')
      const ext = {type:aux[aux.length-1]}
      let response = await request_uploadFile(type, file[0], ext);
      this.setState({user:{...response}})
      this.onCloseModal('imagemodal')
      this.onActivateSuccessSnack()
    } catch (error) {
      if(error.response){
        this.onEditForm('imagemodal',{issending:false, error:error.response.data.error.message})
      }
    }
  }

  onDeletePicture = async() => {
    try {
      this.onEditModal('imagemodal',{issending:true})
      let response = await request_deleteFile('image');
      this.setState({user:{...response}})
      this.onCloseModal('imagemodal')
      this.onActivateSuccessSnack()
    } catch (error) {
      if(error.response){
        this.onEditForm('imagemodal',{issending:false, error:error.response.data.error.message})
      }
    }
  }

  onEditProfileInfo = async(data, type) => {
    try {
      this.onEditForm(type,{issending:true})
      let response = await request_update_user(data)
      this.setState({user:{...response}})
      this.onEditForm(type,{issending:false, error:null})
      this.onActivateSuccessSnack()
      await sleep(600)
      this.onReturn();
    } catch (error) {
      this.onEditForm(type,{issending:false})
      if(error.response){
        this.onEditForm(type,{error:error.response.data.error.message})
      }
    }
  }

  cleanPermissions = (permissions) => {
    let cleanData = []
    permissions.map(item => {
      let array = {
        id_permission_module: item.permission_module_id,
        name: item.permission_module,
        id_permission_type: item.permission_type_id
      }
      cleanData.push(array)
    })

    return cleanData
  }  

  /*onEditPermissions = async(data, type) => {
    try {
      const {updatePermissions} = this.props
      this.onEditForm(type,{issending:true})
      let response = await request_update_permissions(data)
      let permissions = this.cleanPermissions(response)
      let c = updatePermissions(permissions)
      this.setState({permissions: [...permissions]})
      this.onEditForm(type,{issending:false, error:null})
      this.onActivateSuccessSnack()
      //await sleep(600)
      //this.onReturn();
    } catch (error) {
      this.onEditForm(type,{issending:false})
      if(error.response){
        this.onEditForm(type,{error:error.response.data.error.message})
      }
    }
  }*/

  render(){

    const {history, language, catalogs} = this.props;
    const {isloading, forms, user, success, modals, permissions} = this.state
    const content = contentData[language]
    let modalcontent = null

    if(modals.imagemodal.open){
      modalcontent = (
        <ProfilePictureModal
          data={modals.imagemodal}
          language={language}
          onClose={() => this.onCloseModal('imagemodal')}
          onSubmit={this.onSubmitPicture}
          onDelete={this.onDeletePicture}
        />
      )
    }


    return(
      <BasicView
        history={history}
        selected={0}
        onChange={this.onChangeView}
        content={content.layout}
        returnBtn={true}
        onReturn={this.onReturn}
        success={success}
        onCloseSnackbar={this.onCloseSnackbar}
        isloading={isloading}
      >
        {modalcontent}
        <Grid container spacing={4}>
          <Grid item xs={12}>
            <EditProfileInfo
              language={language}
              catalogs={catalogs}
              user={user}
              issending={forms.basicinfo.issending}
              error={forms.basicinfo.error}
              onSubmit={(data) => this.onEditProfileInfo(data, 'basicinfo')}
              onChangeImage={()=> this.onOpenFileModal('imagemodal')}
            />
          </Grid>
          <Grid item xs={12} lg={6}>
            <Grid container spacing={4}>
              <Grid item xs={12}>
                <EditWorkInfo
                  language={language}
                  catalogs={catalogs}
                  user={user}
                  issending={forms.workinfo.issending}
                  error={forms.workinfo.error}
                  onSubmit={(data) => this.onEditProfileInfo(data, 'workinfo')}
                />
              </Grid>
              <Grid item xs={12}>
                <EditContactInfo
                  language={language}
                  catalogs={catalogs}
                  user={user}
                  issending={forms.contactinfo.issending}
                  error={forms.contactinfo.error}
                  onSubmit={(data) => this.onEditProfileInfo(data, 'contactinfo')}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} lg={6}>
            <Grid container spacing={4}>
              <Grid item xs={12}>
                <EditBenefits
                  language={language}
                  catalogs={catalogs}
                  user={user}
                  issending={forms.benefits.issending}
                  error={forms.benefits.error}
                  onSubmit={(data) => this.onEditProfileInfo(data, 'benefits')}
                />
              </Grid>
              {/*<Grid item xs={12}>
                <EditPermissions
                  language={language}
                  catalogs={catalogs}
                  permissions={permissions}
                  issending={forms.permission.issending}
                  error={forms.permission.error}
                  onSubmit={(data) => this.onEditPermissions(data, 'permission')}
                />
              </Grid>*/}
            </Grid>
          </Grid>
        </Grid>
      </BasicView>

    )
  }
}

export default withStyles(styles)(EditProfile)
