import moment from 'moment'

export const cleanData2Table = (prevData, currentData, noData) => {
    let temp = {
        id:{
            id: currentData.id_ctrl_monthly_statements,
            businessID: currentData.business_id
        },
        completed: currentData.completed ? true : false,
        files:[],
        data:[]
    }

    let fixedDataA = [
        currentData.businesses_name ? currentData.businesses_name : noData,
        currentData.rfc ? currentData.rfc : noData,
        currentData.client_status ? currentData.client_status : noData,
        currentData.client_classification ? currentData.client_classification : noData,
        currentData.fiscal_regime ? currentData.fiscal_regime : noData,
        currentData.sub_activity ? currentData.sub_activity : noData,
        currentData.economic_activity_sector ? currentData.economic_activity_sector : noData,
        currentData.fiscal_situation ? currentData.fiscal_situation : noData,
        currentData.monthly_statement_tax_limit_date ? currentData.monthly_statement_tax_limit_date : noData,
        currentData.monthly_statement_ciec_key ? currentData.monthly_statement_ciec_key : noData
    ];

    let varData = [
        currentData.presentation_limit_date ? moment(currentData.presentation_limit_date).format('DD-MM-YYYY') : noData,
        {key:'work_sheet'},
        currentData.process_status ? currentData.process_status : noData,
        currentData.isr ? currentData.isr : noData,
        currentData.isr_retentions ? currentData.isr_retentions : noData,
        currentData.iva ? currentData.iva : noData,
        currentData.iva_retentions ? currentData.iva_retentions : noData,
        currentData.other ? currentData.other : noData,
        currentData.surcharges_and_updates ? currentData.surcharges_and_updates : noData,
        currentData.total_to_pay ? currentData.total_to_pay : noData,
        currentData.payment_limit_date ? moment(currentData.payment_limit_date).format('DD-MM-YYYY') : noData,
        {key:'receipt'},
        {key:'capture_line'},
        {key:'client_response'},
        currentData.client_comments ? currentData.client_comments : noData,
        currentData.diot_folio ? currentData.diot_folio : noData,
        {key:'diot_file'},
        currentData.diot_presentation_limit_date ? moment(currentData.diot_presentation_limit_date).format('DD-MM-YYYY') : noData,
        currentData.diot_responsible ? currentData.diot_responsible : noData,
        {key:'diot_receipt'},
        {key:'tax_mailbox'},
        {key:'compliance_opinion'},
        currentData.compliance_opinion_status ? currentData.compliance_opinion_status : noData,
        {key:'other_documents'},
        currentData.payment_date_mailbox ? moment(currentData.payment_date_mailbox).format('DD-MM-YYYY') : noData,
        currentData.mailbox_status ? currentData.mailbox_status : noData,
        currentData.mailbox_comment ? currentData.mailbox_comment : noData,
        {key:'mailbox_receipt'},
        currentData.responsible ? currentData.responsible : noData,
        currentData.notes ? currentData.notes : noData,
        currentData.extra_hours ? currentData.extra_hours : noData,
        {key:'hours_proof'},
        currentData.extra_charges ? currentData.extra_charges : noData,
        {key:'charge_proof'},
        currentData.collections_charge ? currentData.collections_charge : noData,
        currentData.completition_date ? moment(currentData.completition_date).format('DD-MM-YYYY') : noData,
        currentData.monthly_statement_ciec_key ? currentData.monthly_statement_ciec_key : noData
    ]

    let origin_data = {
        presentation_limit_date : currentData.presentation_limit_date,
        process_status_id : currentData.process_status_id,
        isr : currentData.isr,
        isr_retentions : currentData.isr_retentions,
        iva : currentData.iva,
        iva_retentions : currentData.iva_retentions,
        other : currentData.other,
        surcharges_and_updates : currentData.surcharges_and_updates,
        total_to_pay : currentData.total_to_pay,
        payment_limit_date : currentData.payment_limit_date,
        client_comments : currentData.client_comments,
        diot_folio : currentData.diot_folio,
        diot_presentation_limit_date : currentData.diot_presentation_limit_date,
        diot_responsible_id : currentData.diot_responsible_id,
        compliance_opinion_status_id : currentData.compliance_opinion_status_id,
        payment_date_mailbox : currentData.payment_date_mailbox,
        mailbox_status_id : currentData.mailbox_status_id,
        mailbox_comment : currentData.mailbox_comment,
        responsible_id : currentData.responsible_id,
        notes : currentData.notes,
        extra_hours : currentData.extra_hours,
        extra_charges : currentData.extra_charges,
        collections_charge : currentData.collections_charge,
        completition_date : currentData.completition_date,
        ciec_key : currentData.monthly_statement_ciec_key
    }

    let tempA = {...temp}
    tempA.data = [...fixedDataA]
    prevData.fixedDataA.push(tempA);

    let tempVar = {...temp}
    tempVar.data = [...varData]
    tempVar.files = [
        {id:currentData.id_ctrl_monthly_statements,key:'work_sheet',name:'Hoja de Trabajo',path:'worksheet',
            status: checkStatus(currentData, 'work_sheet')},
        {id:currentData.id_ctrl_monthly_statements,key:'receipt',name:'Acuse',path:'receipt',
            status: checkStatus(currentData, 'receipt')},
        {id:currentData.id_ctrl_monthly_statements,key:'capture_line',name:'Linea de Captura',path:'captureline',
            status: checkStatus(currentData, 'capture_line')},    
        {id:currentData.id_ctrl_monthly_statements,key:'client_response',name:'Respuesta Cliente',path:'clientresponse',
            status: checkStatus(currentData, 'client_response')},
        {id:currentData.id_ctrl_monthly_statements,key:'diot_file',name:'DIOT Archivos',path:'diotfile',
            status: checkStatus(currentData, 'diot_file')},
        {id:currentData.id_ctrl_monthly_statements,key:'diot_receipt',name:'DIOT Acuse',path:'diotreceipt',
            status: checkStatus(currentData, 'diot_receipt')},
        {id:currentData.id_ctrl_monthly_statements,key:'tax_mailbox',name:'Buzón Tributario',path:'taxmailbox',
            status: checkStatus(currentData, 'tax_mailbox')},
        {id:currentData.id_ctrl_monthly_statements,key:'compliance_opinion',name:'Opinión de Cumplimiento',path:'complianceopinion',
            status: checkStatus(currentData, 'compliance_opinion')},
        {id:currentData.id_ctrl_monthly_statements,key:'other_documents',name:'Otros Documentos',path:'otherdocuments',
            status: checkStatus(currentData, 'other_documents')},
        {id:currentData.id_ctrl_monthly_statements,key:'mailbox_receipt',name:'Buzón Acuse',path:'mailboxreceipt',
            status: checkStatus(currentData, 'mailbox_receipt')},
        {id:currentData.id_ctrl_monthly_statements,key:'hours_proof',name:'Comprobante de horas',path:'hoursproof',
            status: checkStatus(currentData, 'hours_proof')},
        {id:currentData.id_ctrl_monthly_statements,key:'charge_proof',name:'Tabla SAT',path:'chargeproof',
            status: checkStatus(currentData, 'charge_proof')}
    ]
    tempVar.origin_data = {...origin_data}
    
    prevData.varData.push(tempVar);
    return prevData;
}

const checkStatus = (item, key) => {
    return item[key] !== "0" && item[key] !== 0 && item[key] !== ""
}
