import React, {useState} from 'react'
import { Grid, Icon, InputBase, makeStyles, Popover, withStyles } from '@material-ui/core'
import { blueGrey, grey } from '@material-ui/core/colors'
import cx from 'classnames'
import DisplayText from '../../../../components_v1/Texts/DisplayText'
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers'
import MomentUtils from '@date-io/moment';
import moment from 'moment'

const useStyles = makeStyles(theme => ({
    root:{
        background:'white',
        boxShadow:'0 10px 24px 0 rgba(82, 91, 115, .12)',
        padding:'4px 16px',
        borderRadius:20,
        '&:hover':{
            cursor:'pointer'
        }
    },
    icon:{
        color:blueGrey[500],

    },
    subtitle:{
        paddingLeft:0,
        paddingBottom:0,
        color:theme.palette.primary.main
    },
    popper:{
        marginTop:8,
        padding:'16px 8px',
        paddingLeft:0,
        borderRadius:32,
        width:200,
        boxSizing:'border-box',
        overflow:'hidden'
    },
    container:{
        maxHeight:250,
        //background:'red',
        overflowX:'hidden',
        overflowY:'auto',
        
    },
    item:{
        fontFamily: theme.typography.fontFamily,
        padding:"12px 16px",
        borderTop:`1px solid ${grey[200]}`,
        '&:hover':{
            cursor:'pointer',
            background:grey[100]
        }
    },
    selected:{
        fontWeight:500
    }
}))

const FilterDate = props => {
    const classes = useStyles()
    const {id, label, value, onChange} = props

    return(
        <MuiPickersUtilsProvider utils={MomentUtils}>

            <div>
                <div className={classes.root} >
                    <Grid container direction='column'>
                        <Grid item >
                            <DisplayText variant='caption' className={classes.subtitle}>{label}</DisplayText>
                        </Grid>
                        <Grid item >
                            <InputBase value={value} type='date' fullWidth onChange={(e) => onChange(e.currentTarget.value, id)}/>
                        </Grid>
                    </Grid>
                </div>
            </div>
        </MuiPickersUtilsProvider>
        
    )
}

export default FilterDate

const CSSKeyboardDatePicker = withStyles(theme => ({
    root: {
      marginTop:'6px !important',
      '& input':{
        color:grey[900],
      },
      '& .MuiFormControl-root': {
          marginTop:0
      },
      '& .MuiInput-underline': {
        borderBottomColor: grey[300],
        borderBottomWidth:2,
        '&:hover:not($disabled):after': {
          //its when its hover and input is focused 
        },
        '&:hover:not($disabled):before': {
          borderBottomWidth:2,
          borderBottomColor: theme.palette.secondary.light,//its when you hover and input is not foucused 
        },
        '&:after': {
          borderBottomColor: theme.palette.secondary.main,//when input is focused, Its just for example. Its better to set this one using primary color
        },
        '&:before': {
          borderWidth:1,
          borderBottomColor: grey[300],// when input is not touched
        },
      }
    }
  }))(DatePicker);
