import React from 'react';
import { Grid, Icon } from '@material-ui/core';
import { grey } from '@material-ui/core/colors';
import DisplayText from '../../Texts/DisplayText';

const StatusData = ({data, color, bold, variant, align, icon}) => {


    return ( 
        <Grid container justify={align ? align : 'flex-start'} alignItems='center' spacing={1} wrap='nowrap'>
            <Grid item><Icon style={{marginTop:-8, fontSize:14, color: color ? color : grey[300]}}>{icon ? icon : 'brightness_1'}</Icon></Grid>
            <Grid item><DisplayText variant={bold ? 'subtitle1' : (variant ? variant : 'body1')}>{data}</DisplayText></Grid>
        </Grid>
    );
}
 
export default StatusData;