import { Grid, makeStyles, Switch } from '@material-ui/core';
import React, { useState, useEffect } from 'react';
import ActionModalBar from '../../../../components_v1/Actions/ActionModalBar';
import InputText from '../../../../components_v1/Forms/InputText';
import SimpleModal from '../../../../components_v1/Structure/Modals/SimpleModal';
import DisplayText from '../../../../components_v1/Texts/DisplayText';
import RichTextForm from '../../../../components_v1/Forms/RichTextForm';

const useStyles = makeStyles(theme => ({
    form:{
        paddingTop:32,
        paddingBottom:32
    }
}))

const EditCommentModal = ({ open, onClose, content, form, onChange, onSubmit, loading, error, comment, onSetComment}) => {
    const classes = useStyles()

    return ( 
        <SimpleModal open={open} onClose={onClose} maxWidth='sm'>
            <div>
                <DisplayText variant='h6' color='primary' style={{ fontWeight: 500 }}>{content.title}</DisplayText>
                <div className={classes.form}>
                    <Grid container spacing={3}>
                        <Grid item xs={12}>
                            <InputText data={form.title} onChange={onChange}/>
                        </Grid>
                        <Grid item xs={12}>
                            {/* <InputText data={form.comment} onChange={onChange}/> */}
                            <RichTextForm label='Comentario' value={comment} onChange={(value)=>onSetComment(value)} />
                        </Grid>
                        <Grid item xs={12}>
                            <ActionModalBar btnLabel={content.button} loading={loading} error={error} onSubmit={onSubmit}/>
                        </Grid>
                    </Grid>
                </div>
            </div>
        </SimpleModal>
     );
}
 
export default EditCommentModal;
