import { Grid, Icon, Typography, makeStyles } from "@material-ui/core"
import React from "react"


const useStyles = makeStyles(theme => ({
    container:{
        margin:'12px 0px'
    },
    span:{
        color:theme.palette.primary.main
    }
}))

const DataDisplayer = ({title, data, margin, data2}) => {

    const classes = useStyles()
    console.log(data2)

    return(
        <Grid container spacing={1} style={{margin: margin ? margin : '12px 0px'}}  >
            <Grid item><Typography style={{color:'#6C6C6C'}} >{title}</Typography></Grid>
            <Grid item><Typography color='secondary' >{data}</Typography></Grid>
            {data2 !== undefined ? <Grid item><Typography color='primary' >/ {data2}</Typography></Grid> : null}
        </Grid>
    )
}

export default DataDisplayer