export const contentData = {
  spanish:{
    layout:{
      title:'Timbrado y cálculo de nómina',
      navigation:[
        {name:'Clientes',path:'/clients'},
        {name:'Detalles',path:'/clients/details/:id'},
        {name:'Negocios',path:'/clients/details/:id/businesses'},
        {name:'Detalles',path:'/clients/details/:id/businesses/:idbusiness'},
        {name:'Servicio',path:'/clients/details/:id/businesses/:idbusiness/service/:idservice'},
      ],
      menu:[
        {id:0, label:'General'},
        {id:1, label:'Sellos de facturación'},
        // {id:2, label:'Logs'}
      ]
    },
    modals:{
      warning_delete_file:{
        title:'¿Estás seguro?',
        message:'¿Realmente quieres eliminar este registro? Este proceso no puede ser revertido',
        cancelButton:'Cancelar',
        deleteButton:'Eliminar'
      },
      edit_service:{
        title:'Editar servicio',
        button:'Guardar',
        cancelButton:'Cancelar'
      }
    },
    basicform:{
      title:'Información Básica',
      button:'Editar',
      nodata:'Sin Registro',
      data:{
        rfc:{
          label:'',
          data:'',
        },
        workers_limit:{
          label:'',
          data:'',
        },
        payroll_responsible:{
          label:'',
          data:'',
        },
        supervisor:{
          label:'',
          data:'',
        },
        analyst:{
          label:'',
          data:'',
        },
        specs:{
          label:'',
          data:'',
        },
      },
      content:{
        rfc:{
          label:'RFC'
        },     
        specs:{
          label:'Especificaciones'
        },
        responsible:{
          label:'Responsable'
        },
        payroll_responsible:{
          label:'Responsable Nómina'
        },
        service_periodicity:{
          label:'Periodicidad'
        },
        workers_limit:{
          label:'Límite de empleados'
        },
        roster_type:{
          label:'Tipo de nómina'
        },
        analyst:{
          label: 'Analista'
        },
        supervisor:{
          label: 'Supervisor'
        }
      },
      documents:{
        title:'Documentos',
        header:{title:'Archivos', actions:'Acciones'},
        files:[
          {key:'workers_db',name:'Base de Datos Trabajadores',path:'workersdb', status:false},
          {key:'fiscal_situation_proof',name:'Comprobante de Situación Fiscal',path:'fiscalsituationproof', status:false},
          {key:'as_roster_registration',name:'Registro AS Nómina',path:'asrosterregistration', status:false},
          {key:'workers_db_analyzed',name:'Base de datos analizada',path:'workersdbanalyzed', status:false},
        ]
      },
      
    },
    payrolls: {
      title: 'Nóminas',
      header: [
        { id: 1, label: 'ID' },
        { id: 2, label: 'Periodicidad' },
        { id: 3, label: 'Tipo de nómina' },
        { id: 4, label: 'Sistema' },
        { id: 5, label: 'No. de empleados' },
        { id: 6, label: 'Nota' },
        { id: 7, label: '' },
      ],
    },
  }
}