import React, { useState, useEffect } from 'react';
import { Grid, makeStyles } from '@material-ui/core';
import ActionModalBar from '../../../../../../../components_v1/Actions/ActionModalBar';
import InputSelect from '../../../../../../../components_v1/Forms/InputSelect';
import InputText from '../../../../../../../components_v1/Forms/InputText';
import SimpleModal from '../../../../../../../components_v1/Structure/Modals/SimpleModal';
import DisplayText from '../../../../../../../components_v1/Texts/DisplayText';
import { isFormValid, onSetErrorsToForm, onInitForm, onGetSelectedFormData, onGetErrorMessage, onGetExtensionFromURL, onGetDateFormat,  } from '../../../../../../../shared/utility';
import { catalogs } from '../../../../../../../texts/esp/catalogs';
import { request_update_business, request_update_business_documents, 
  request_service_item, request_business_documents, request_download_document, request_edit_service } from '../../requests';
import InputCheckbox from '../../../../../../../components_v1/Forms/InputCheckbox';
import DocumentsCard from '../../components/DocumentsCards';
import DocumentModal from '../DocumentModal'
import DeleteDocumentModal from '../DeleteDocumentModal';

const useStyles = makeStyles(theme => ({
    form:{
        paddingTop:32,
        paddingBottom:32
    }
}))

const EfirmaDetailsModal = (props) => {

    const classes = useStyles()
    const { businessID, open, onClose, init_data, onUpdateCompleted, permission } = props
    const [efirma, setEfirma] = useState(null)
    const [business_documents, setBusinessDocuments] = useState(null)
    const [form, setForm] = useState(JSON.parse(JSON.stringify(formData)))
    const [loading, setLoading] = useState(true)
    const [error, setError] = useState('')

    const [selected_document, setSelectedDocument] = useState(null)
    const [document_modal, setDocumentModal] = useState(false)
    const [delete_document_modal, setDeleteDocumentModal] = useState(false)

    const serviceID = init_data ? init_data.id_efirma : null

    useEffect(() => {

      const initModule = async() => {
        try {
          await onRequestModelInformation()
        } catch (error) {
          console.log(error)
          setError(onGetErrorMessage(error))
        }
      }
      
      if(!open){
        setTimeout(() => {
          setError(''); 
          setLoading(false); 
          setForm(JSON.parse(JSON.stringify(formData)));
        }, 500);
        return
      }

      initModule()
        
    }, [open])

    const onRequestModelInformation = async() => {
      if(init_data){
        const _efirma = await request_service_item(init_data.id_efirma)
        const _business_documents = await request_business_documents(businessID)
        setEfirma(_efirma)
        setBusinessDocuments(_business_documents)
        let _form = JSON.parse(JSON.stringify(formData))
        _form = onInitForm(_form, _efirma)
        _form.expiration_date.value = onGetDateFormat(_form.expiration_date.value)
        if(_business_documents && _business_documents.efirma_password){
          _form['efirma_password'] = {..._form['efirma_password'], value:_business_documents.efirma_password, isVisited:true, isValid:true}
        }
        setForm(_form)
      }
    }

    const onChange = (data) => {
        let temp = { ...form }
        const id = data.config.id
        temp[id] = { ...data }
        setForm(temp)
    }

    const onUploadDocument = (data) => {
      setSelectedDocument(data)
      setDocumentModal(true)
    }

    const onDeleteDocument = (data) => {
        setSelectedDocument(data)
        setDeleteDocumentModal(true)
    }

    const onSubmit = async () => {

        const errors = isFormValid(form)
        /// Check if the form is valid
        if(errors && errors.length){
            const new_form = onSetErrorsToForm(form, errors)
            setForm(new_form)
            setError('Llene los campos faltantes')
            return
        }

        //Extract data
        let data2send = onGetSelectedFormData(form, efirma)
        let bd_data2send = {efirma_password:data2send.efirma_password}
        ////Delete nulls
        Object.keys(data2send).forEach(key => {if(data2send[key]===null||data2send[key]===undefined) delete data2send[key]})
        Object.keys(bd_data2send).forEach(key => {if(bd_data2send[key]===null||bd_data2send[key]===undefined) delete bd_data2send[key]})
        delete data2send.efirma_password

        //Send data
        setLoading(true)
        try {
            await request_edit_service(serviceID, data2send)
            await request_update_business_documents(businessID, bd_data2send)
            onUpdateCompleted()
        } catch (error) {
            console.log(error)
            setError('Hubo un error')
            setLoading(false)
        }
        setLoading(false)

    }

    const onInnerUpdateCompleted = async() => {
      try {
        await onRequestModelInformation()
        setDocumentModal(false)
        setDeleteDocumentModal(false)
      } catch (error) {
        setError(onGetErrorMessage(error))
      }
    }

    const onDownloadDocument = async(data) => {try {
      if(data.businessDocument){
          console.log('Upload to business documents')
          const local_path = business_documents[data.key]
          const filename = `${data.key}.${onGetExtensionFromURL(local_path)}`
          await request_download_document(businessID, data.path, filename, 'BUSINESS')
      }else{
          console.log('Upload to service documents')
          const local_path = efirma[data.key]
          const filename = `${data.key}.${onGetExtensionFromURL(local_path)}`
          await request_download_document(serviceID, data.path, filename, 'SERVICE')
      }
    } catch (error) {
        console.log(error)
        setError(onGetErrorMessage(error))
    }}


    return ( 
        <SimpleModal open={open} onClose={onClose} maxWidth='lg'>
            <div>
                <DeleteDocumentModal businessID={businessID} serviceID={serviceID} open={delete_document_modal} document={selected_document} onClose={() => setDeleteDocumentModal(false)} 
                onUpdateCompleted={onInnerUpdateCompleted}/>
                <DocumentModal businessID={businessID} serviceID={serviceID}  open={document_modal} document={selected_document} onClose={() => setDocumentModal(false)}
                onUpdateCompleted={onInnerUpdateCompleted}/>
                <DisplayText variant='h6' color='primary' style={{ fontWeight: 500 }}>Información de E.Firma</DisplayText>
                <div className={classes.form}>
                  <Grid container spacing={3}>
                    <Grid item xs={12}>
                      <Grid container spacing={3}>    
                          <Grid item xs={12}>
                              <InputText data={form.name} onChange={onChange}/>
                          </Grid>
                          <Grid item xs={12}>
                              <InputText data={form.efirma_password} onChange={onChange}/>
                          </Grid>
                          <Grid item xs={12} sm={6}>
                              <InputText data={form.email} onChange={onChange}/>
                          </Grid>
                          <Grid item xs={12} sm={6}>
                              <InputText data={form.service_description} onChange={onChange}/>
                          </Grid>
                          <Grid item xs={12}>
                              <InputText data={form.expiration_date} onChange={onChange}/>
                          </Grid>
                          <Grid item xs={12}>
                              <ActionModalBar btnLabel='Guardar' loading={loading} error={error} onSubmit={onSubmit}/>
                          </Grid>
                      </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        <DocumentsCard permission={permission} data={efirma} files={business_documents} onUpload={onUploadDocument} onDownload={onDownloadDocument} onDelete={onDeleteDocument}/>
                    </Grid>
                    
                  </Grid>
                    
                </div>
            </div>
        </SimpleModal>
     );
}
 
export default EfirmaDetailsModal;

const formData = {
  efirma_password:{
    value:'',
    error: false,
    isVisited: false,
    isRequired: false,
    isValid: false,
    config:{
        label:'Contraseña',
        placeholder:'Contraseña',
        id:'efirma_password',
        type:'text',
        fullWidth: true
    },
    rules:{
        type:'distance',
        min:1,
        max:250
    }
  },  
  name:{
    value:'',
    error: false,
    isVisited: false,
    isRequired: false,
    isValid: false,
    config:{
        id:'name',
        type:'text',
        fullWidth: true,
        label:'Nombre',
        placeholder:'Nombre'
    },
    rules:{
        type:'distance',
        min:1,
        max:100
    }
},
email:{
    value:'',
    error: false,
    isVisited: false,
    isRequired: false,
    isValid: false,
    config:{
        id:'email',
        type:'text',
        fullWidth: true,
        label:'Email',
        placeholder:'Email'
    },
    rules:{
        type:'distance',
        min:1,
        max:100
    }
},
service_description:{
    value:'',
    error: false,
    isVisited: false,
    isRequired: false,
    isValid: false,
    config:{
        id:'service_description',
        type:'text',
        fullWidth: true,
        label:'Descripción',
        placeholder:'Descripción'
    },
    rules:{
        type:'distance',
        min:1,
        max:100
    }
},
expiration_date:{
    value:'',
    error: false,
    isVisited: false,
    isRequired: false,
    isValid: false,
    config:{
        id:'expiration_date',
        type:'date',
        fullWidth: true,
        label:'Fecha de Expiración',
        placeholder:'Fecha de Expiración'
    },
    rules:{
        type:'date',
    }
  }
}