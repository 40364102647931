import React from 'react';
import {
	Checkbox,
	Grid,
	Hidden,
	Icon,
	IconButton,
	LinearProgress,
	makeStyles,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	Typography,
} from '@material-ui/core';

const TaskUsers = ({ content, users, onAddUser, onSetMainUser, onDeleteUser, loading }) => {
	const classes = useStyles();

	return (
		<Grid container className={classes.mt_sm} alignItems='center' style={{ paddingLeft: 30 }}>
			<Grid item container xs={12} alignItems='center'>
				<Grid item xs={11}>
					<Typography className={classes.task_title}>{content.title}</Typography>
				</Grid>
				<Grid item xs={1}>
					<IconButton fontSize='small' className={classes.edit_button} onClick={onAddUser}>
						<Icon>add</Icon>
					</IconButton>
				</Grid>
			</Grid>
			<Grid item xs={12}>
				<TableContainer className={classes.table}>
					<Table size='small' aria-label='Tabla de usuarios' stickyHeader>
						<TableHead>
							<TableRow>
								{content.header.map((item) => (
									<TableCell align='center' key={item.id} className={classes.section_title}>
										{item.label}
									</TableCell>
								))}
							</TableRow>
						</TableHead>
						<TableBody>
							{users.map((user, i) => (
								<TableRow key={i}>
									<TableCell className={classes.cell}>
										<div className={classes.user_container}>{user.user}</div>
									</TableCell>
									<TableCell align='center'>
										<Checkbox color='primary' onClick={() => onSetMainUser(user.id_task_user, user.main)} checked={Boolean(Number(user.main))}></Checkbox>
									</TableCell>
									<TableCell>
										<IconButton onClick={() => onDeleteUser(user.id_task_user)}>
											<Icon style={{ color: '#9AA6B4' }}>close</Icon>
										</IconButton>
									</TableCell>
								</TableRow>
							))}
						</TableBody>
					</Table>
				</TableContainer>
			</Grid>
			<Grid item xs={12}>
				<Typography align='center' className={classes.user_count}>{`${users.length} ${content.users}`}</Typography>
			</Grid>
		</Grid>
	);
};

const useStyles = makeStyles((theme) => ({
	table: {
		maxHeight: 300,
	},
	section_title: {
		fontWeight: 600,
		fontSize: '0.75rem',
		color: '#9AA6B4',
	},
	task_title: {
		fontWeight: 500,
		fontSize: '1.375rem',
		color: theme.palette.primary.main,
	},
	edit_button: {
		width: 35,
		height: 35,
		background: theme.palette.secondary.light,
		'&:hover': {
			background: theme.palette.secondary.main,
		},
		'& .MuiIcon-root': {
			color: 'white',
		},
	},
	cell: {},
	user_container: {
		fontWeight: 400,
		fontSize: '1rem',
		display: 'flex',
		alignItems: 'center',
	},
	user_count: {
		fontWeight: 400,
		fontSize: '1rem',
		color: '#AAB4C0',
		marginTop: 40,
	},
	mt_sm: {
		[theme.breakpoints.down('sm')]: {
			marginTop: 20,
		},
	},
}));

export default TaskUsers;
