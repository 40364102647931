import { grey } from '@material-ui/core/colors'

const styles = theme => ({
  root: {
    display: 'flex',
  },
  appBar: {
    zIndex: theme.zIndex.drawer +1,
    boxShadow:'none',
    borderBottom:`1px solid ${grey[300]}`,
    background: 'white',
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  menu:{
    color:theme.palette.secondary.main
  },
  title:{
    marginTop: '9px',
    color:theme.palette.secondary.main
  },
  bor:{
    border: '1px solid red'
  },
  platformBtn:{
    borderRadius:0,
    '&:hover':{
      background:grey[100],
      cursor:'pointer'
    }
  }

});

export default styles;
