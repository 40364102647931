import React, { useState, useEffect } from 'react'
import BasicView from '../../../../components_v1/Structure/Layouts/BasicView'
import GeneralInfo from '../views/GeneralInfo'
import StepperContainer from '../views/StepperContainer/StepperContainer'
import { contentData } from './content'
import { Grid } from '@material-ui/core'
import { nodata } from '../../../../texts/esp/general'
import { request_process } from '../requests'
import { onGetErrorMessage } from '../../../../shared/utility'
import LoadingContainer from '../../../../components_v1/Structure/Layouts/LoadingContainer'

const PhysicalPersonsView = props => {

    const { match, history, language } = props

    const content = contentData[language]
    const processID = parseInt(match.params.id)


    const [loading, setLoading] = useState(false)
    const [success, setSuccess] = useState(null)
    const [error, setError] = useState(null)

    const [process, setProcess] = useState({})

    useEffect(() => {
        const initModule = async () => {
            try {
                setLoading(true)
                const url = `proc_${content.url}`
                const _process = await request_process(processID, url, `?step_id=1`)
                setProcess(_process)
            } catch (error) {
                console.log(error)
                setError(onGetErrorMessage(error))
            }
            setLoading(false)
        }
        initModule()
    }, [])

    return (
        <LoadingContainer loading={loading} success={success} error={error} onCloseSuccess={() => setSuccess(false)} onCloseError={() => setError(false)}>
            <BasicView title={process.business ? process.business : ''} setReturn>
                <Grid container spacing={4}>
                    <Grid item xs={12}>
                        <GeneralInfo process={process} url={`proc_${content.url}`} content={content.sections.general_info} nodata={nodata} />
                    </Grid>
                    <Grid item xs={12}>
                        <StepperContainer
                            id={processID}
                            fixedID={content.fixed_id}
                            url={content.url}
                            language={language}
                            content={content.sections.stepper_container}
                            steps={content.monthly_steps}
                            paths={content.paths}
                        />
                    </Grid>
                </Grid>
            </BasicView>
        </LoadingContainer>
    )
}

export default PhysicalPersonsView