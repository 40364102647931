import React from 'react'
import { grey } from '@material-ui/core/colors'
import { CircularProgress, Grid, Icon, IconButton, makeStyles, Paper, Table, TableBody, TableCell, TableHead, TableRow, withStyles } from '@material-ui/core'
import GenericStatus from '../../../../components_v1/Structure/DisplayData/GenericStatus'
import AvatarName from '../../../../components_v1/Structure/DisplayData/AvatarName'
import DisplayText from '../../../../components_v1/Texts/DisplayText'
import { catalogs } from '../../../../texts/esp/catalogs'
import empty from '../../../../assets/empty.png'
import FilterButton from './FilterButton'
import ActionBar from './ActionBar'
import StepperStatus from '../../../../components_v1/Texts/StepperStatus'
import THeader from './THeader'
import SingleFilterButton from './SingleFilterButton'
import AddFilterButton from './AddFilterButton'
import { onGetYearCatalog } from '../../../../shared/utility'

const useStyles = makeStyles(theme => ({
    root: {},
    paper: {
        borderRadius: 8,
        boxShadow: '0 10px 24px 0 rgba(82, 91, 115, .12)',
    },
    cell: {
        borderColor: grey[200],
        maxWidth: 250,
        minWidth: 120
    },
    row: {
        '&:hover': {
            cursor: 'pointer',
            background: grey[100]
        }
    },
    cellHeader: {
        borderColor: grey[300],
        paddingTop: 16,
        paddingBottom: 16
    },
    button: {
        background: theme.palette.primary.main,
        padding: '6px 12px',
        borderRadius: 32,
        color: 'white',
        '&:hover': {
            background: theme.palette.primary.dark,
        }
    },
    toolbar: {
        marginBottom: 16
    },
    actions: {
        marginTop: 16
    },
    table: {
        minWidth: 1400
    },
    tableWrapper: {
        overflowX: 'auto',
    },
    nodata: {
        marginTop: 40
    },
    syncButton:{
        color:theme.palette.secondary.main,
        background:'white'
    }
}))

const SimpleTable = props => {

    const classes = useStyles()
    const { origin_data, business, responsible, data, header, onChangeFilter, total, tableFilter, onUpdateTableFilter, onAddFilter,
        onRemoveFilter, onSelectedItem, loading, year, month, monthCatalog, onRefresh, location, type_period, classification, advisor } = props

    const onChangeOrder = (identifier) => {
        let new_order
        if (tableFilter.order === 1) {
            new_order = 0
        } else {
            new_order = tableFilter.order + 1
        }
        if (tableFilter.order_by !== identifier) new_order = 0
        onUpdateTableFilter({ ...tableFilter, order: new_order, order_by: identifier })
    }

    const noContent = (
        <div className={classes.nodata}>
            <Grid container justify='center' alignItems='center' direction='column'>
                <Grid item>
                    <img src={empty} alt='' width={180} />
                </Grid>
                <Grid item>
                    <DisplayText>No se encontraron registros</DisplayText>
                </Grid>
            </Grid>
        </div>
    )

    return (
        <div>
            <div className={classes.toolbar}>
                <Grid container wrap='nowrap' spacing={2} alignItems='flex-end'>
                    <Grid item>
                        <FilterButton label='Mes' options={monthCatalog} selected={month} onChange={(val) => onChangeFilter(val, 'month')} />
                    </Grid>
                    <Grid item>
                        <FilterButton label='Año' options={onGetYearCatalog()} selected={year} onChange={(val) => onChangeFilter(val, 'year')} />
                    </Grid>
                    {business ? <Grid item><SingleFilterButton label={business.label} id='business' onRemoveFilter={onRemoveFilter} /></Grid> : null}
                    {responsible ? <Grid item><SingleFilterButton label={responsible.label} id='responsible' onRemoveFilter={onRemoveFilter} /></Grid> : null}
                    {classification ? <Grid item><SingleFilterButton label={classification.label} id='classification' onRemoveFilter={onRemoveFilter} /></Grid> : null}
                    {advisor ? <Grid item><SingleFilterButton label={advisor.label} id='advisor' onRemoveFilter={onRemoveFilter} /></Grid> : null}
                    <Grid item xs></Grid>
                    <Grid item><IconButton onClick={onRefresh} className={classes.syncButton}><Icon>sync</Icon></IconButton></Grid>
                    <Grid item><AddFilterButton onChange={onAddFilter} /></Grid>
                </Grid>
            </div>
            {loading ? (
                <Grid container justify='center'>
                    <Grid item><CircularProgress size={32} /></Grid>
                </Grid>
            ) : null}
            <Paper className={classes.paper}>
                {data && data.length ? (
                    <div className={classes.tableWrapper}>
                        <Table className={classes.table}>
                            <THeader data={header} order={tableFilter ? tableFilter.order : null} orderby={tableFilter ? tableFilter.order_by : null} onChangeOrder={onChangeOrder} />
                            {/*header ? (
                                <TableHead>
                                    <TableRow>
                                        {header.map(item => {
                                            return (
                                                <TableCell padding={item.id === 1 ? 'default' : 'default'} className={classes.cellHeader} key={item.id.toString()}>
                                                    <DisplayText variant='subtitle2' >{item.label}</DisplayText>
                                                </TableCell>
                                            )
                                        })}
                                    </TableRow>
                                </TableHead>
                                    ) : null*/}
                            <TableBody>
                                {data.map((item, key) => {
                                    const { id, business, importance, responsible, fixed_payday_limit, step, priority, percent, status_id, fixed_city, fixed_state, fixed_ctrl_period_name, fixed_roster_type, fixed_payroll_periodicity } = item

                                    return (
                                        <TableRow key={key.toString()} className={classes.row} >
                                            <TableCell className={classes.cell} onClick={() => onSelectedItem(id)}><DisplayText variant='body2'>{business}</DisplayText></TableCell>
                                            {type_period && (
                                                <>
                                                    <TableCell className={classes.cell} onClick={() => onSelectedItem(id)}><DisplayText variant='body2'>{fixed_roster_type}</DisplayText></TableCell>
                                                    <TableCell className={classes.cell} onClick={() => onSelectedItem(id)}><DisplayText variant='body2'>{fixed_ctrl_period_name}</DisplayText></TableCell>
                                                    <TableCell className={classes.cell} onClick={() => onSelectedItem(id)}><DisplayText variant='body2'>{fixed_payroll_periodicity}</DisplayText></TableCell>
                                                </>
                                            )}
                                            {location && (
                                                <>
                                                    <TableCell className={classes.cell} onClick={() => onSelectedItem(id)}><DisplayText variant='body2'>{fixed_state}</DisplayText></TableCell>
                                                    <TableCell className={classes.cell} onClick={() => onSelectedItem(id)}><DisplayText variant='body2'>{fixed_city}</DisplayText></TableCell>
                                                </>
                                            )}
                                            <TableCell className={classes.cell} onClick={() => onSelectedItem(id)}><DisplayText variant='body2'>{item?.fixed_client_classification ?? '-'}</DisplayText></TableCell>
                                            <TableCell className={classes.cell} onClick={() => onSelectedItem(id)}><DisplayText variant='body2'>{importance}%</DisplayText></TableCell>
                                            <TableCell className={classes.cell} onClick={() => onSelectedItem(id)}><DisplayText variant='body2'>{item?.fixed_client_associate ?? '-'}</DisplayText></TableCell>
                                            <TableCell className={classes.cell} onClick={() => onSelectedItem(id)}><DisplayText variant='body2'>{item?.fixed_client_responsible ?? '-'}</DisplayText></TableCell>
                                            <TableCell className={classes.cell} onClick={() => onSelectedItem(id)}><DisplayText variant='body2'>{item?.fixed_business_advisor ?? '-'}</DisplayText></TableCell>
                                            <TableCell className={classes.cell} onClick={() => onSelectedItem(id)}><DisplayText variant='body2'>{responsible}</DisplayText></TableCell>
                                            {/*<TableCell className={classes.cell} onClick={() => onSelectedItem(id)}><AvatarName name={responsible} imgSrc={image} /></TableCell>*/}
                                            <TableCell className={classes.cell} onClick={() => onSelectedItem(id)}><DisplayText variant='body2'>{fixed_payday_limit}</DisplayText></TableCell>
                                            <TableCell className={classes.cell} onClick={() => onSelectedItem(id)}><StepperStatus {...step} /></TableCell>
                                            <TableCell className={classes.cell} onClick={() => onSelectedItem(id)}>{priority && <PriorityLabel {...priority} />}</TableCell>
                                            <TableCell className={classes.cell} onClick={() => onSelectedItem(id)}><DisplayText variant='body2'>{percent} %</DisplayText></TableCell>
                                            <TableCell className={classes.cell} onClick={() => onSelectedItem(id)}><GenericStatus id={status_id} variant='body2' options={catalogs.process_status} /></TableCell>
                                            
                                        </TableRow>
                                    )
                                })}

                            </TableBody>
                        </Table>
                    </div>

                ) : null}
            </Paper>
            {data && data.length ? <div className={classes.actions}>
                <ActionBar total={total} tableFilter={tableFilter} onUpdateTableFilter={onUpdateTableFilter} />
            </div> : null}
            {data.length === 0 && !loading ? noContent : null}
        </div>
    )
}

const PriorityLabel = ({ value, label, color, icon, align }) => {
    return (
        <Grid container justify={align ? align : 'flex-start'} alignItems='center' wrap='nowrap'>
            <Grid item>
                <Icon style={{ color: color }} fontSize='small'>{icon}</Icon>
            </Grid>
            <Grid item style={{marginLeft: 10}}>
                <DisplayText variant='body2'>
                    {label}
                </DisplayText>
            </Grid>
        </Grid>
    )
}

export default SimpleTable