import { useCallback, useEffect, useState } from "react";
import moment from "moment";
import { onGetMonthsCatalog, onGetTimeDuration } from "../../../shared/utility";
import useBusinessCost from "../../../hooks/api/useBusinessCost";
import XLSX from 'xlsx'
import FileSaver from "file-saver";
const useBusinessCostView = () => {

    const businessCostManager = useBusinessCost()

    const [loading, setLoading] = useState(false)
    const [done, setDone] = useState(false)
    const [data, setData] = useState([])
    const [total, setTotal] = useState(0)
    const [search, setSearch] = useState(null)
    const [search_by, setSearchBy] = useState("business_name")
    // filters
    const [tableFilter, setTableFilter] = useState({limit:10,offset:0})
    const [month, setMonth] = useState(moment().subtract(1,'month').month()+1)
    const [year, setYear] = useState(moment().year())
    const [month_options, setMonthOptions] = useState(onGetMonthsCatalog(moment().year()))

    const onUpdateTableFilter = async(data) => {
        setTableFilter(data)
    }

    const onDownload = async() => {
        try {
            const _params = onGetParams(true)
            const _request = await businessCostManager.onGetAll(_params)
            const data = _request.data
            let _exceldata = []

            const headerdata = header.map(item => item.label)
            _exceldata.push(headerdata)

            data.forEach(item => {
                const contract_value = parseFloat(item?.contract_value ?? 0)
                const fixed_cost = parseFloat(item?.fixed_cost ?? 0)
                const variable_cost = parseFloat(item?.variable_cost ?? 0)
                const balance = contract_value - fixed_cost - variable_cost
                _exceldata.push([
                    item?.business_name ?? '-',
                    item?.business_rfc ?? '-',
                    item?.client_classification ?? '-',
                    item?.client_importance ?? '-',
                    item?.client_associate ?? '-',
                    item?.client_responsible ?? '-',
                    item?.business_advisor ?? '-',
                    item?.fixed_cost ?? '-',
                    item?.variable_cost ?? '-',
                    balance,
                    balance >= 0 ? 'Positivo' : 'Negativo',
                    onGetTimeDuration(item?.monthly_quoted_time ?? 0),
                    onGetTimeDuration(item?.worked_hours ?? 0),
                    onGetTimeDuration(item?.average_duration ?? 0),
                    item?.average_complexity ?? '-',
                    item?.average_client_satisfaction ?? '-',
                    item?.ctrl_accounting_operations ?? '-',
                    item?.ctrl_billing_notes ?? '-',
                    item?.employee_number ?? '-',

                ])
            })

            console.log(_exceldata)

            let wb = XLSX.utils.book_new()
            wb.Props = {
                Title:"Costos del negocio",
                Subject:"Estadísticas",
                Author:"ASCG Group",
                CreatedDate: new Date()
            }
            let worksheet = XLSX.utils.aoa_to_sheet(_exceldata)
            XLSX.utils.book_append_sheet(wb, worksheet, "Estadisticas")
            const wbout = XLSX.write(wb, {bookType:'xlsx', type:'binary'});
            const buff = s2ab(wbout)
            const blob = new Blob([buff], { type: 'application/octet-stream' });
            FileSaver.saveAs(blob, "Costo del negocio.xlsx")

        } catch (error) {
            
        }
    }

    const s2ab = (wbout) => {
        const buf = new ArrayBuffer(wbout.length);
        const view = new Uint8Array(buf);
        for (let i = 0; i < wbout.length; i++) {
            view[i] = wbout.charCodeAt(i) & 0xFF;
        }
        return buf
    }

    const onGetParams = useCallback((ignorePagination) => {
        let _result = {}
        if(!ignorePagination){
            _result.limit = tableFilter.limit
            _result.offset = tableFilter.offset * tableFilter.limit
        }


        

        const _range = month_options.find(el => el.value == month)
        console.log(_range)

        let _filter = []
        /*if(_range){
            _filter.push({field:"date", operator:">=", value:_range?.start_date})
            _filter.push({field:"date", operator:"<=", value:_range?.end_date})
        }*/

        if(search !== "" && search !== null && search !== undefined){
            _filter.push({field:search_by, operator:"LIKE", value: `%${search}%`})
        }

        if(_filter.length) _result.filter = JSON.stringify({AND:_filter})

        return _result

    },[month_options, month, year, search, tableFilter, search_by])

    const onChangeFilter = (value, type) => {
        setTableFilter({ limit: 10, offset: 0 })
        if(type === 'month') setMonth(value)
        if(type === 'year'){
            setMonthOptions(onGetMonthsCatalog(value.toString()))
            setYear(value)
            //setMonth(1)
        }

    }

    const onChangeSearch = (data) => {
        console.log(data)
        setTableFilter({ limit: 10, offset: 0 })
        setSearch(data)
    }

    const onRequestData = useCallback(async() =>{

        const _params = onGetParams()
        console.log("Parametros", _params)
        try {
            const _request = await businessCostManager.onGetAll(_params)
            setData(_request.data)
            setTotal(_request.total)
        } catch (error) {
            console.log(error)
        }
        setDone(true)
    },[onGetParams])



    const onRefreshData = useCallback(async() => {
        console.log("Inicializar plataforma")
        setLoading(true)
        try {
            await onRequestData()
        } catch (error) {
            
        }
        setLoading(false)
        

    },[onRequestData])



    const initialize = useCallback(async() => {
        console.log("Inicializar plataforma")
        setLoading(true)
        try {
            await onRequestData()
        } catch (error) {
            
        }
        setLoading(false)

    },[onRequestData])

    useEffect(()=>{
        console.log("Volver a buscar")
        if(done) onRefreshData()
    },[tableFilter, month, year,search])

    useEffect(() => {
        initialize()
    },[])

    const filter_data = {
        search_by,
        month, year,
        month_options,
        onChangeFilter,
        onChangeSearch,
        setSearchBy
    }

    return {
        loading, 
        data,
        filter_data,
        tableFilter,
        total,
        onDownload,
        onUpdateTableFilter

    };
}
 
export default useBusinessCostView;

const header = [
    {id:'buisness_name', label:'Razón social'},
    {id:'business_rfc', label:'RFC'},
    {id:'client_classification', label:'Clasificación'},
    {id:'client_importance', label:'Importancia'},
    {id:'client_associate', label:'Socio'},
    {id:'client_responsible', label:'Responsable'},
    {id:'business_advisor', label:'Asesor'},
    {id:'contract_value', label:'Valor de contrato (s/IVA)'},
    {id:'fixed_cost', label:'Costo fijo'},
    {id:'variable_cost', label:'Costo variable'},
    {id:'formula', label:'Utilidad o Pérdida'},
    {id:'formula_status', label:'Estatus'},
    {id:'monthly_quoted_time', label:'Horas contratadas'},
    {id:'worked_hours', label:'Horas trabajadas'},
    {id:'average_duration', label:'Duración promedio'},
    {id:'average_complexity', label:'Complejidad'},
    {id:'average_client_satisfaction', label:'Satisfación'},
    {id:'ctrl_accounting_operations', label:'Número de operaciones'},
    {id:'ctrl_billing_notes', label:'Notas facturación'},
    {id:'employee_number', label:'Número de trabajadores'},
]
