import { Grid, makeStyles } from '@material-ui/core';
import moment from 'moment';
import React, { useState, useEffect } from 'react';
import ActionModalBar from '../../../../components_v1/Actions/ActionModalBar';
import RoundedButton from '../../../../components_v1/Actions/RoundedButton';
import InputSelect from '../../../../components_v1/Forms/InputSelect';
import SimpleModal from '../../../../components_v1/Structure/Modals/SimpleModal';
import DisplayText from '../../../../components_v1/Texts/DisplayText';
import { isFormValid, onGetEmployeesCatalogs, onSetErrorsToForm } from '../../../../shared/utility';
import { request_employees } from '../requests'

const useStyles = makeStyles(theme => ({
    form: {
        paddingTop: 32,
        paddingBottom: 32
    }
}))

const AdvisorFilterModal = (props) => {

    const classes = useStyles()
    const { open, onClose, onRequestUsers, history, onAddFilter } = props
    const [form, setForm] = useState(JSON.parse(JSON.stringify(formData)))
    const [file, setFile] = useState(null)
    const [responsibles, setResponsibles] = useState([])
    const [services, setServices] = useState([])
    const [supervisors, setSupervisors] = useState([])
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState('')

    useEffect(() => {
        if (!open) {
            setForm(JSON.parse(JSON.stringify(formData)))
            setError(''); setLoading(false); setResponsibles([])
        }
    }, [open])

    const onChange = (data) => {
        let temp = { ...form }
        const id = data.config.id
        temp[id] = { ...data }
        setForm(temp)
    }

    const onSearchUsers = async (e) => {
        if (!e || e.target.value === "" || e.target.value === 0) return
        try {
            const _responsibles = await request_employees()
            const _responsible_catalog = onGetEmployeesCatalogs(_responsibles)
            setResponsibles(_responsible_catalog)
        } catch (error) {
            console.log(error)
        }
    }

    const onSubmit = async () => {
        const errors = isFormValid(form)
        /// Check if the form is valid
        if (errors && errors.length) {
            const new_form = onSetErrorsToForm(form, errors)
            setForm(new_form)
            return
        }

        const data2send = responsibles.find(el => el.value === form.id_user.value)

        onAddFilter(data2send, 'advisor')
        onClose()

    }

    // Update catalogs in real time 
    let business_form = { ...form.id_user }
    business_form.options = [...responsibles]

    return (
        <SimpleModal open={open} onClose={onClose} maxWidth='sm' padding='small'>
            <div>
                <div className={classes.form}>
                    <Grid container spacing={3}>
                        <Grid item xs={12}>
                            <DisplayText variant='subtitle1'>Seleccione un asesor</DisplayText>
                            <InputSelect data={business_form} onChange={onChange} onInputChange={onSearchUsers} />
                        </Grid>
                        <Grid item xs={12}>
                            <Grid container justify='flex-end'>
                                <Grid item><RoundedButton onClick={onSubmit} >Seleccionar</RoundedButton></Grid>
                            </Grid>
                        </Grid>

                    </Grid>
                </div>
            </div>
        </SimpleModal>
    );
}

export default AdvisorFilterModal;

const formData = {
    id_user: {
        value: '',
        error: false,
        isVisited: false,
        isRequired: true,
        isValid: false,
        options: [],
        config: {
            id: 'id_user',
            type: 'select',
            fullWidth: true,
            label: '',
            placeholder: 'Seleccione una opción',
            helper: 'Este campo es requerido'
        },
        rules: { type: 'select' }
    },

}