import {mainServer} from '../../../../../variables/config.jsx'
import FileSaver from 'file-saver'
import axios from 'axios'

export const request_users = async () => {
  const logData = JSON.parse(localStorage.getItem('data'));
  mainServer.instance.defaults.headers.common['Authorization'] = logData.token;
  let response;
  response = await mainServer.instance.get("/user/employee/list/");
  return response.data.data.employees;
}

/**************************************** */

export const request_service_item = async (businessID, id) => {
  const logData = JSON.parse(localStorage.getItem('data'));
  mainServer.instance.defaults.headers.common['Authorization'] = logData.token;
  let response;
  response = await mainServer.instance.get("/externalinvestmentregistration/"+id.toString())
  let service = response.data.data.external_investment_registration;
  response = await mainServer.instance.get("/businessdocument/"+businessID.toString());
  service = {...service, 
    company_type:response.data.data.tributary_mailbox_statuses.company_type,
    company_type_id:response.data.data.tributary_mailbox_statuses.company_type_id
  }
  return service;
}

export const request_add_service_item = async (id, data) => {
  const logData = JSON.parse(localStorage.getItem('data'));
  mainServer.instance.defaults.headers.common['Authorization'] = logData.token;
  await mainServer.instance.post("/externalinvestmentregistration",data);
  let response;
  response = await mainServer.instance.get("/externalinvestmentregistration/"+id.toString())
  return response.data.data.external_investment_registration;
}

export const request_edit_service_item = async (businessID, id, data) => {
  const logData = JSON.parse(localStorage.getItem('data'));
  mainServer.instance.defaults.headers.common['Authorization'] = logData.token;
  await mainServer.instance.patch("/externalinvestmentregistration/" + id, data);
  await mainServer.instance.patch("/businessdocument/"+businessID.toString(), 
  {company_type:data.company_type, company_type_id:data.company_type_id});
  let response;
  response = await mainServer.instance.get("/externalinvestmentregistration/"+id.toString())
  let service = response.data.data.external_investment_registration;
  response = await mainServer.instance.get("/businessdocument/"+businessID.toString());
  service = {...service, 
    company_type:response.data.data.tributary_mailbox_statuses.company_type,
    company_type_id:response.data.data.tributary_mailbox_statuses.company_type_id
  }
  return service;
}

export const request_delete_service_item = async (id, data) => {
  const logData = JSON.parse(localStorage.getItem('data'));
  mainServer.instance.defaults.headers.common['Authorization'] = logData.token;
  await mainServer.instance.delete("/externalinvestmentregistration/" +id+"/"+data);
  let response;
  response = await mainServer.instance.get("/externalinvestmentregistration/"+id.toString())
  return response.data.data.external_investment_registration;
}

/**************************************** */

export const request_uploadFile = async(id,file,ext) => {
  const logData = JSON.parse(localStorage.getItem('data'));
  mainServer.instance.defaults.headers.common['Authorization'] = logData.token;
  const url = 'externalinvestmentregistration/' + id + '/documents'
  let response = await mainServer.instance.post(url, ext)
  const options = {headers: {'Content-Type': file.type}}
  delete axios.defaults.headers.common["Authorization"]
  response = await axios.put(response.data.data.path, file, options);
  mainServer.instance.defaults.headers.common['Authorization'] = logData.token;
  response = await mainServer.instance.get("/externalinvestmentregistration/"+id.toString())
  return response.data.data.external_investment_registration;
}

export const request_downloadFile = async(id) => {
  
  const logData = JSON.parse(localStorage.getItem('data'));
  mainServer.instance.defaults.headers.common['Authorization'] = logData.token;
  let response;
  const url = 'externalinvestmentregistration/' + id + '/documents/';
  response = await mainServer.instance.get(url)
  delete axios.defaults.headers.common["Authorization"]
  response = await axios.get(response.data.data.path, {responseType: 'blob'})
  FileSaver.saveAs(response.data)
}

export const request_deleteFile = async(id) => {
  const logData = JSON.parse(localStorage.getItem('data'));
  mainServer.instance.defaults.headers.common['Authorization'] = logData.token;
  let response
  const url = 'externalinvestmentregistration/' + id + '/documents/';
  await mainServer.instance.delete(url)
  response = await mainServer.instance.get("/externalinvestmentregistration/"+id.toString())
  return response.data.data.external_investment_registration;
}