export const contentData = {
  spanish:{
    title:'Crear consulta',
    nodata:'Sin Registro',
    error:'Sin registro',
    button:'Guardar',
    cancelButton:'Cancelar',
    image_button:'Cambiar',
    form:{
      name:{
        label:'Nombre',
        placeholder:'Nombre',
      }, 
      consulting_status_id:{
        label:'Estatus',
        placeholder:'Estatus',
      },
      consulting_progress_id:{
        label:'Progreso',
        placeholder:'Progreso',
      }, 
      start:{
        label:'Fecha de inicio',
        placeholder:'Fecha de inicio',
      }, 
      end:{
        label:'Fecha de fin',
        placeholder:'Fecha de fin',
      }, 
    },
  },
}