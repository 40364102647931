export const contentData = {
  spanish:{
    layout:{
      title:'Información del cliente',
      navigation:[
        {name:'Clientes',path:'/clients'},
        {name:'Detalles',path:'/clients/details/:id'},
      ],
    },
    modals:{
      warning_delete_file:{
        title:'¿Estás seguro?',
        message:'¿Realmente quieres eliminar este archivo? Este proceso no puede ser revertido',
        cancelButton:'Cancelar',
        deleteButton:'Eliminar'
      },
      warning_delete_contact:{
        title:'¿Estás seguro?',
        message:'¿Realmente quieres eliminar este contacto? Este proceso no puede ser revertido',
        cancelButton:'Cancelar',
        deleteButton:'Eliminar'
      }
    },
    clientusers_button:'Usuarios',
  }
}