
import moment from 'moment'

export const cleanData2Table = (data, nodata, catalogs) => {

  const table = data.map(item => {

    let temp = {
      id:item.id_idse_user,
      used_id: item.used,
      files:[
        {id:item.id_idse_user,
        key:'imss_pfx',name:'IMSS PFX',path:'imsspfx', status: checkStatus(item, 'imss_pfx')},
        {id:item.id_idse_user,
        key:'imss_key',name:'IMSS KEY',path:'imsskey', status: checkStatus(item, 'imss_key')},
        {id:item.id_idse_user,
        key:'imss_rec',name:'IMSS REC',path:'imssrec', status: checkStatus(item, 'imss_rec')},
        {id:item.id_idse_user,
        key:'sipare',name:'SIPARE',path:'sipare', status: checkStatus(item, 'sipare')},
        {id:item.id_idse_user,
        key:'employer_card',name:'Tarjeta pataronl',path:'employercard', status: checkStatus(item, 'employer_card')},
        {id:item.id_idse_user,
        key:'imss_digital_key',name:'Clave digital IMSS',path:'imssdigitalkey', status: checkStatus(item, 'imss_digital_key')},
        {id:item.id_idse_user,
        key:'imss_db',name:'Base de Datos IMSS',path:'imssdb', status: checkStatus(item, 'imss_db')},
        {id:item.id_idse_user,
        key:'compliance_opinion',name:'Opinión de Cumplimiento IMSS',path:'complianceopinion', status: checkStatus(item, 'compliance_opinion')},
        {id:item.id_idse_user,
          key:'employer_enrollment',name:'Registro Patronal',path:'employerenrollment', status: checkStatus(item, 'employer_enrollment')},
      ],
      data:[]
    }

    let state = catalogs.business_documents.mexico_states.filter(el => el.id === item.state_id)
    state = state.length > 0 ? state[0].label : nodata

    let array = [
      item.id_idse_user,
      state,
      item.city ? item.city: nodata,
      item.employer_enrollment ? item.employer_enrollment: nodata,
      item.user ? item.user: nodata,
      item.password ? item.password: nodata,
      'imss_pfx', 'imss_key', 'imss_rec',
      item.imss_user ? item.imss_user: nodata,
      item.imss_password ? item.imss_password: nodata,
      item.virtual_desktop_user ? item.virtual_desktop_user: nodata,
      item.virtual_desktop_password ? item.virtual_desktop_password: nodata,
      'sipare','employer_card',
      item.imss_limit_date ? moment(item.imss_limit_date).format('DD-MM-YYYY'): nodata,
      'imss_digital_key',
      item.expiration_date ? moment(item.expiration_date).format('DD-MM-YYYY'): nodata,
      item.risk_premium ? item.risk_premium: nodata,
      'imss_db',
      item.number_employees ? item.number_employees : nodata,
      'compliance_opinion',
      item.tax_payment_limit_date ? item.tax_payment_limit_date : nodata,
    ]

    temp.data = [...array]
    return temp
  })

  return table
}

const checkStatus = (item, key) => {
  return item[key] !== "0" && item[key] !== 0 && item[key] !== ""
}
