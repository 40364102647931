import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { onGetErrorMessage, onGetMonthsCatalog, onGetUserID } from '../../../../shared/utility'
import queryString from 'query-string'
import BasicView from '../../../../components_v1/Structure/Layouts/BasicView'
import SimpleTable from '../components/SimpleTable'
import { contentData } from './content'
import { request_process, request_employees } from './requests'
import { cleanData2Table } from './customFunctions'
import { nodata } from '../../../../texts/esp/general'
import ResponsibleFilterModal from '../modals/ResponsibleFilterModal'
import BusinessFilterModal from '../modals/BusinessFilterModal'
import ClassificationFilterModal from '../modals/ClassificationFilterModal'
import AdvisorFilterModal from '../modals/AdvisorFilterModal'

const IntegralSolution = props => {

    const { history, language } = props

    const [loading, setLoading] = useState(false)
    const [sending, setSending] = useState(false)
    const [error, setError] = useState(null)
    const [success, setSuccess] = useState(false)

    const [total, setTotal] = useState(0)
    const [tableFilter, setTableFilter] = useState({ language_id: 2, limit: 10, offset: 0, order: 0, order_by: 'id_proc_integral_solution' })

    const [process, setProcess] = useState([])
    const [users, setUsers] = useState([])
    const [month, setMonth] = useState(null)
    const [year, setYear] = useState(null)
    const [month_options, setMonthOptions] = useState([])

    const [responsible, setResponsible] = useState(null)
    const [business, setBusiness] = useState(null)
    const [classification, setClassification] = useState(null)
    const [advisor, setAdvisor] = useState(null)
    const [responsibleModal, setResponsibleModal] = useState(false)
    const [businessModal, setBusinessModal] = useState(false)
    const [classificactionModal, setClassificationModal] = useState(false)
    const [advisorModal, setAdvisorModal] = useState(false)

    const content = contentData[language]

    const [document_modal, setDocumentModal] = useState(false)

    useEffect(() => {
        const current_date = moment().subtract(1, 'month')
        const prev_month = current_date.month()
        const prev_year = current_date.year()
        setYear(prev_year)
        setMonthOptions(onGetMonthsCatalog(prev_year))
        setMonth(prev_month + 1)
    }, [])

    useEffect(() => {
        if (month && year)
            onRefresh()
    }, [tableFilter, month, year, month_options, responsible, business, classification, advisor])

    const onOpenFilter = (id) => {
        if (id === 'RESPONSIBLE_MODAL') setResponsibleModal(true)
        if (id === 'BUSINESS_MODAL') setBusinessModal(true)
        if (id === 'CLASSIFICATION_MODAL') setClassificationModal(true)
        if (id === 'ADVISOR_MODAL') setAdvisorModal(true)
    }

    const onAddFilter = (obj, key) => {
        console.log(obj, key)
        if (key === 'business') setBusiness(obj)
        if (key === 'responsible') setResponsible(obj)
        if (key === 'classification') setClassification(obj)
        if (key === 'advisor') setAdvisor(obj)
        setTableFilter({
            ...tableFilter,
            offset:0,
        })
    }

    const onRemoveFilter = (key) => {
        console.log(key)
        if (key === 'business') setBusiness(null)
        if (key === 'responsible') setResponsible(null)
        if (key === 'classification') setClassification(null)
        if (key === 'advisor') setAdvisor(null)
        setTableFilter({
            ...tableFilter,
            offset:0,
        })
    }

    const onChangeFilter = (value, type) => {
        if (type === 'month') setMonth(value)
        if (type === 'year') {
            setYear(value)
            setMonthOptions(onGetMonthsCatalog(value.toString()))
            //setMonth(1)
        }
        setTableFilter({
            ...tableFilter,
            offset:0,
        })
    }

    const onRefresh = async () => {
        console.log('Cargar datos')
        setLoading(true)
        try {
            const userID = onGetUserID()
            let param2send = { ...tableFilter }
            const rangeDate = month_options.find(el => el.value === month)
            let filterArgs = [
                { "field": "auto_date", "operator": ">=", "value": rangeDate.start_date },
                { "field": "auto_date", "operator": "<=", "value": rangeDate.end_date }
            ]

            if (business) filterArgs.push({ "field": "business_id", "operator": "=", "value": business.value })
            //if (responsible) filterArgs.push({ "field": "fixed_responsible_id", "operator": "=", "value": responsible.value })
            let ORfilter = []
            if(responsible){
                ORfilter.push({ "field": "fixed_responsible_id", "operator": "=", "value": responsible.value })
                ORfilter.push({ "field": "fixed_client_responsible_id", "operator": "=", "value": responsible.value })
                ORfilter.push({ "field": "fixed_business_advisor_id", "operator": "=", "value": responsible.value })
                ORfilter.push({ "field": "fixed_client_associate_id", "operator": "=", "value": responsible.value })
                filterArgs.push({"OR":ORfilter})
            }

            param2send.offset = tableFilter.offset * tableFilter.limit
            filterArgs = encodeURIComponent(JSON.stringify({AND:filterArgs}))
            const params = `?${queryString.stringify(param2send)}&filter=${filterArgs}`
            const _process = await request_process(params)
            setTotal(_process.count)
            setProcess(_process.proc_integral_solutions)
            let response = await request_employees()
            setUsers([...response])
        } catch (error) {
            console.log(error)
            setError(onGetErrorMessage(error))
        }
        setLoading(false)
    }

    let data2table = []
    if (process)
        data2table = cleanData2Table(process, nodata, users)

    return (
        <BasicView
            title={content.title}
            sending={sending}
            setReturn
            success={success}
            onCloseSuccess={() => setSuccess(null)}
            error={error} onCloseError={() => setError(null)}
        >
            <ResponsibleFilterModal open={responsibleModal} onClose={() => setResponsibleModal(false)} onAddFilter={onAddFilter} />
            <BusinessFilterModal open={businessModal} onClose={() => setBusinessModal(false)} onAddFilter={onAddFilter} />
            <ClassificationFilterModal open={classificactionModal} onClose={() => setClassificationModal(false)} onAddFilter={onAddFilter} />
            <AdvisorFilterModal open={advisorModal} onClose={() => setAdvisorModal(false)} onAddFilter={onAddFilter} />

            <SimpleTable
                header={content.header}
                month={month}
                year={year}
                monthCatalog={month_options}
                data={data2table}
                origin_data={process}
                tableFilter={tableFilter}
                total={total}
                loading={loading}
                onSelectedItem={(id) => window.open(`/process/integralsolution/${id}`, '_target')}
                onUpdateTableFilter={(data) => setTableFilter(data)}
                onChangeFilter={onChangeFilter}
                onAddFilter={onOpenFilter}
                onRemoveFilter={onRemoveFilter}
                onRefresh={onRefresh}
                business={business}
                responsible={responsible}
                advisor={advisor}
                classification={classification}
            />
        </BasicView>
    )
}

export default IntegralSolution