import React, {Component} from 'react'
import {Grid} from '@material-ui/core'
import {withStyles} from '@material-ui/core/styles'
import {
  request_fiscalobligations,
  request_add_fiscalobligation, 
  request_delete_fiscalobligation,
} from './requests.jsx'

import CustomSnackbar from '../../../../../components/Snackbars/CustomSnackbar'

import SimpleLoader from '../../../../../components/Loaders/SimpleLoader';
import CardWrapper from '../../../../../components/Cards/CardWrapper/CardWrapper';
import ItemSelection from '../../../../../components/Inputs/ItemSelection/ItemSelection'
import {ErrorMessage} from '../../../../../components/DisplayData/DisplayData'
const styles = (theme) => ({
  root:{padding:'32px'}
})

class FiscalObligations extends Component {

  state = {
    isloading:false,
    issending:false,
    error:null,
    fiscalobligations:[],
    success:{open:false,timeout:2000,type:'success',message:'Successful action!'},
  }

  componentDidMount(){
    this.initModule()
  }

  initModule = async() => {
    const {businessID} = this.props
    this.setState({isloading:true})
    let response = await request_fiscalobligations(businessID)
    this.setState({fiscalobligations:[...response]})
    this.setState({isloading:false})
  }

  onChange = (id, status) => {
    this.onEditFiscalObligations(id, status)
  }

  onEditFiscalObligations = async(id, status) => {
    try {
      const {businessID} = this.props
      this.setState({issending:true})
      let response
      let data2send = {
        business_id: businessID,
        fiscal_obligation_id:id
      }
      if(status){
        response = await request_add_fiscalobligation(businessID, data2send)
      }else{
        response = await request_delete_fiscalobligation(businessID, data2send)
      }
      this.setState({fiscalobligations:[...response]})
      this.setState({issending:false, iserror: null})
      this.onActivateSuccessSnack()
    } catch (error) {
      this.setState({issending:false})
      if(error.response){
        this.setState({error:error.response.data.error.message})
      }
    }
  }

  onMatchData = (data, request) => {
    let temp = [...data]
    data.forEach((item,key) => {
      let status = request.filter(el => el.fiscal_obligation_id === item.id)
      if(status.length > 0){
        temp[key].status = true
      }else{
        temp[key].status = false
      }
    })
  } 

  onCloseSnackbar = (id) => {
    let temp = this.state[id]
    temp.open = false
    this.setState({[id]:{...temp}})
  }

  onActivateSuccessSnack = () => {
    const {success} = this.state
    let success_temp = {...success}
    success_temp.open = true
    this.setState({success:{...success_temp}})
  }

  render(){
    const {isloading, fiscalobligations, issending, error, success} = this.state
    const {catalogs, classes, permission, permissionsType} = this.props
    let data = [...catalogs.business_documents.fiscal_obligations]
    this.onMatchData(data, fiscalobligations)

    let itemsdata = data.map((item,key)=>{
      return(
        <Grid item xs={12} md={6} lg={4} key={key.toString()}>
          <ItemSelection
            id={item.id}
            label={item.label}
            status={item.status}
            onChange={this.onChange}
            disabled={!(permission >= permissionsType.EDIT) || issending}
          />
        </Grid>
      )
    })

    let contentView = <Grid container justify='center'><Grid item><SimpleLoader /></Grid></Grid>



    if(!isloading){
      let infocontent = null
      if(issending){
        infocontent = (
          <Grid item>
            <SimpleLoader />
          </Grid>
        )
      }else{
        if(error){
          infocontent = (
            <Grid item>
              <ErrorMessage message={error}/>
            </Grid>
          )
        }
      }

      contentView = (
        <CardWrapper>
          <div className={classes.root}>
            <Grid container>
              <Grid item xs={12}>
                <Grid container spacing={3}>
                  {itemsdata}
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <Grid container justify='flex-end'>
                  {infocontent}
                </Grid>
              </Grid>
            </Grid>
            
          </div>
        </CardWrapper>
      )
    }

    return(
      <div>
        {contentView}
        <CustomSnackbar open={success.open} autoHideDuration={success.timeout} onClose={() => this.onCloseSnackbar(success.type)} message={success.message} variant={success.type}/>
      </div>
    )
  }
}

export default withStyles(styles)(FiscalObligations)

