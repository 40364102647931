
import React, { Component } from 'react';
import { OutlinedInput, InputAdornment, Icon, withStyles } from '@material-ui/core';
import { grey } from '@material-ui/core/colors';



class SearchBar extends Component{

  onChange = (event) => {
    const {onSearch} = this.props
    onSearch(event.currentTarget.value)
  }

  render(){
    const {classes} = this.props

    return(
      <OutlinedInput 
        margin='dense'
        placeholder='Buscardor...'
        fullWidth={true}
        classes={classes}
        onChange={this.onChange}
        startAdornment={<InputAdornment>
          <Icon style={{color: grey[500],paddingRight:'4px'}}>search</Icon>
        </InputAdornment>}
      />
    )
  }
}

const styles_input = (theme) => ({
  root: {
    position: 'relative',
    paddingTop:2,
    paddingBottom:2,
    borderRadius:32,
    color:grey[700],
    '& $notchedOutline': {
      borderRadius:32,
      borderWidth: 0, 
    },
    fontSize:14,
    '&:hover:not($disabled):not($focused):not($error) $notchedOutline': {
      // Reset on touch devices, it doesn't add specificity
      '@media (hover: none)': {
        borderColor:'blue',
      },
    },
    '&$focused $notchedOutline': {
      border:0,
      borderColor:grey[200]
    },
    '&$error $notchedOutline': {
      borderColor: theme.palette.error.main,
    },
    '&$disabled $notchedOutline': {
      borderColor: theme.palette.action.disabled,
    },
  },
  /* Styles applied to the root element if the component is focused. */
  focused: {},
  /* Styles applied to the root element if `disabled={true}`. */
  disabled: {},
  /* Styles applied to the root element if `error={true}`. */
  error: {},
  /* Styles applied to the `NotchedOutline` element. */
  notchedOutline: {},
  input:{
  },
  adornedStart:{
    background:'white',
    borderRadius:32,
    boxShadow:'0 4px 20px 0 rgba(82, 91, 115, .20)',

  }
})

export default withStyles(styles_input)(SearchBar);

