import React, {Component} from 'react'
import { withStyles } from '@material-ui/core/styles'
import { Grid} from '@material-ui/core'
import { grey } from '@material-ui/core/colors'
import ViewCardWrapper from '../../../../../components/Cards/ViewCardWrapper'
import {checkStatus} from './customFunctions'
import {contentData} from './content'
import ControlTable from '../../../../../components/Tables/ControlTable'

const styles = (theme) => ({
  root:{padding:'32px'},
  subtitle:{color:theme.palette.primary.main, fontWeight:'500'},
  nospeciality:{color:grey[700], fontWeight:'500'}
})

class ControlTablesList extends Component { 
  
  render(){

    const {language, onSelectedItem, permissions, permissionsType} = this.props
    const content = contentData[language]
    const controlTablePermissions= permissions.filter(e => e.id_permission_module > 37)
    const ctrlTableData=checkStatus(content.ctrltableData, controlTablePermissions, permissionsType.NO_ACCESS)
    
    return(
        <Grid container spacing={3}>
            <Grid item xs={12}>
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                    <Grid container spacing={4}>
                        <Grid item xs={12}>
                            <ViewCardWrapper
                                title={content.title}
                                level={4}
                            >
                                <ControlTable
                                header={content.header}
                                language={language}
                                ctrltableData={ctrlTableData}
                                onSelectedItem={onSelectedItem}
                                />
                            </ViewCardWrapper>
                        </Grid>
                    </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    )
  }
}

export default withStyles(styles)(ControlTablesList)