import React from 'react';
import { nodata } from '../../../texts/esp/general';
import StatusData from './StatusData';

const GenericStatus = ({id, variant, align, options}) => {

    const status = options.find(el => el.value === id)

    return ( 
        <div>
            <StatusData data={status ? status.label : nodata} color={status ? status.color : null} variant={variant ? variant : 'body1'} align={align}/>
        </div>
     );
}
 
export default GenericStatus;