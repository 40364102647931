import React, { useEffect, useState } from 'react'
import { Grid, Card, makeStyles, Hidden } from '@material-ui/core'
import DisplayText from '../../../../../../components_v1/Texts/DisplayText'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { Accordion2, AccordionDetails, AccordionSummary2 } from '../../../../../../components_v1/Structure/Accordion/Accordion';
import ShadedInputText from '../../../../../../components_v1/Forms/ShadedInputText';
import InputSelect from '../../../../../../components_v1/Forms/InputSelect';
import InputSelectStatus from '../../../../../../components_v1/Forms/InputSelectStatus';
import { onGetErrorMessage, onInitFormProcess, onUpdateDocumentListProcess } from '../../../../../../shared/utility';
import LoadingContainer from '../../../../../../components_v1/Structure/Layouts/LoadingContainer';
import RoundedButton from '../../../../../../components_v1/Actions/RoundedButton';
import { contentData } from './content'
import { catalogs } from '../../../../../../texts/esp/catalogs';
import { grey } from '@material-ui/core/colors';
import DocumentInput from '../../../../../../components_v1/Forms/DocumentInput';
import Responsibles from '../Responsibles';
import { request_process } from '../../requests';
import ButtonsSelect from '../../../../../../components_v1/Forms/ButtonsSelect';
import InputText from '../../../../../../components_v1/Forms/InputText';
import DocumentModal from '../../modals/DocumentModal';

const styles = makeStyles(theme => ({
    banner: {
        padding: '0px 64px 64px 64px',
        [theme.breakpoints.down('sm')]: {
            padding: '0px 32px 32px 32px',
        },
        borderBottom: `1px solid ${grey[300]}`
    },
    banner2: {
        padding: '0px 64px 64px 64px',
        [theme.breakpoints.down('sm')]: {
            padding: '0px 32px 32px 32px',
        },
    },
    subAccordion: {
        backgroundColor: 'rgba(75, 146, 248, 0.15)',
        color: '#0F2358'
    },
    title: {
        fontSize: 18,
        fontWeight: 500
    },
    smallTitle: {
        fontSize: 14,
        fontWeight: 500,
        color: '#607D8B'
    },
}))

const SectionF = ({ id, ctrlID, onUpdate, onUpdateStep, language, permission, onDownloadDocument }) => {

    const step = 9
    const _step = 6
    const classes = styles()

    const [form, setForm] = useState(JSON.parse(JSON.stringify(formData)))
    const [files, setFiles] = useState(JSON.parse(JSON.stringify(filesData)))
    const [loading, setLoading] = useState(false)
    const [sending, setSending] = useState(false)
    const [success, setSuccess] = useState(null)
    const [error, setError] = useState(null)

    const [process, setProcess] = useState(null)
    const [selected_document, setSelectedDocument] = useState(null)
    const [accordion, setAccordion] = useState(true)
    const [accordions, setAccordions] = useState(null)
    const content = contentData[language]

    const [document_modal, setDocumentModal] = useState(false)

    useEffect(() => {
        const initModule = async () => {
            try {
                setLoading(true)
                const _process = await request_process(id, `?step_id=${_step}`)
                setProcess(_process)
                let _form = JSON.parse(JSON.stringify(formData))
                _form = onInitFormProcess(_form, _process, step)
                setForm(_form)
                let _files = JSON.parse(JSON.stringify(filesData))
                _files = onUpdateDocumentListProcess(_files, _process, step)
                setFiles(_files)
                let _accordions = content.accordions.map(item => true)
                setAccordions(_accordions)
            } catch (error) {
                console.log(error)
                setError(onGetErrorMessage(error))
            }
            setLoading(false)
        }
        initModule()
    }, [])

    const onUploadDocument = (data) => {
        setSelectedDocument(data)
        setDocumentModal(true)
    }

    const onUpdateCompleted = async (newStep) => {
        try {
            setSending(true)
            if (newStep)
                await onUpdate(form, true, newStep, content.responsible_banner, step)
            else {
                let _process = await request_process(id, `?step_id=${_step}`)
                let _files = JSON.parse(JSON.stringify(filesData))
                _files = onUpdateDocumentListProcess(_files, _process, step)
                setFiles(_files)
            }
            setDocumentModal(false)
            setSending(false)
            setSuccess(true)
        } catch (error) {
            console.log(error)
            setError(onGetErrorMessage(error))
        }
        setSending(false)
    }

    const onChange = (data) => {
        let temp = { ...form }
        const id = data.config.id
        temp[id] = { ...data }
        setForm(temp)
    }

    const handleAccordions = (id) => {
        let _accordions = [...accordions]
        _accordions[id] = !accordions[id]
        setAccordions(_accordions)
    }

    return (
        <LoadingContainer loading={loading} sending={sending} success={success} error={error} onCloseSuccess={() => setSuccess(false)} onCloseError={() => setError(false)}>
            <div>
                <DocumentModal id={id} idExt={ctrlID} step={step} open={document_modal} document={selected_document} onClose={() => setDocumentModal(false)} onUpdateCompleted={onUpdateCompleted} />
                <Grid container justify='center' spacing={8}>
                    <Grid item xs={12}>
                        <div className={classes.banner}>
                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                    <DisplayText variant='body2' className={classes.title}>{content.title}</DisplayText>
                                </Grid>
                                {accordions && content.accordions.map((accordionData, key) => (
                                    <Grid item xs={12}>
                                        <Accordion2 expanded={accordions[key]} onChange={() => handleAccordions(key)}>
                                            <AccordionSummary2
                                                expandIcon={<ExpandMoreIcon />}
                                                aria-controls={`${accordionData.id}_controls`}
                                                id={accordionData}
                                                classes={{ root: classes.subAccordion }}
                                            >
                                                <DisplayText variant='body2' align='center'>{accordionData.title}</DisplayText>
                                            </AccordionSummary2>
                                            <AccordionDetails>
                                                <Grid container justify='center' spacing={4}>
                                                    <Grid item xs={12}>
                                                        <ButtonsSelect
                                                            data={form[accordionData.id]}
                                                            onChange={onChange}
                                                            disabled={!permission}
                                                        />
                                                    </Grid>
                                                </Grid>
                                            </AccordionDetails>
                                        </Accordion2>
                                    </Grid>
                                ))}
                            </Grid>
                        </div>
                    </Grid>
                    <Grid item xs={12}>
                        <div className={classes.banner}>
                            <Grid container spacing={8}>
                                <Grid item xs={12}>
                                    <ShadedInputText
                                        className={classes.selectStatus}
                                        color='primary'
                                        data={form.financial_statement_comments}
                                        onChange={onChange}
                                        disabled={!permission}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <Grid container justify='center' spacing={4}>
                                        <Grid item>
                                            <DocumentInput
                                                data={files[0]}
                                                onUploadDocument={() => onUploadDocument(files[0])}
                                                onDownloadDocument={() => onDownloadDocument(files[0])}
                                                disabled={!permission}
                                                downloadEnabled
                                            />
                                        </Grid>
                                        <Grid item>
                                            <DocumentInput
                                                data={files[1]}
                                                onUploadDocument={() => onUploadDocument(files[1])}
                                                onDownloadDocument={() => onDownloadDocument(files[1])}
                                                disabled={!permission}
                                                downloadEnabled
                                            />
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </div>
                    </Grid>
                    <Grid item xs={12}>
                        <Responsibles data={process} step={step} language={language} responsible_banner={content.responsible_banner} />
                    </Grid>
                    <Grid item>
                        <RoundedButton disabled={!permission} color='blueGrey' onClick={() => onUpdateStep(_step - 1, true)}>
                            {content.back}
                        </RoundedButton>
                    </Grid>
                    <Grid item>
                        <RoundedButton disabled={!permission} color='secondary' onClick={() => onUpdateCompleted(_step + 1)}>
                            {content.button}
                        </RoundedButton>
                    </Grid>
                </Grid>
            </div>
        </LoadingContainer >
    )
}

const filesData = [
    { path: 'financial_statement_document', name: 'Estados Financieros y sus Auxiliares', status: false, ctrlpath: true },
    { path: 'internal_reports', name: 'Reporte Especial Cliente', status: false, ctrlpath: true },
]

const formData = {
    financial_statement_comments: {
        value: null,
        error: false,
        isVisited: false,
        isRequired: false,
        isValid: false,
        ctrlpath: true,
        config: {
            id: 'financial_statement_comments',
            type: 'text',
            fullWidth: true,
            label: 'Comentarios',
            helper: 'Introduce tus comentarios',
            multiline: true,
            rows: 6
        },
        rules: {
            type: 'distance',
            min: 1
        }
    },
    closed_trial_balance: {
        value: null,
        error: false,
        isVisited: false,
        isRequired: false,
        isValid: false,
        ctrlpath: true,
        options: [...catalogs.process_accounting_documents_statuses],
        config: {
            id: 'closed_trial_balance',
            type: 'simple_select',
            fullWidth: true,
            label: 'Disponibilidad',
            helper: 'Disponibilidad'
        },
        rules: {
            type: 'none',
        }
    },
    income_statements: {
        value: null,
        error: false,
        isVisited: false,
        isRequired: false,
        isValid: false,
        ctrlpath: true,
        options: [...catalogs.process_accounting_documents_statuses],
        config: {
            id: 'income_statements',
            type: 'simple_select',
            fullWidth: true,
            label: 'Disponibilidad',
            helper: 'Disponibilidad'
        },
        rules: {
            type: 'none',
        }
    },
    general_balance: {
        value: null,
        error: false,
        isVisited: false,
        isRequired: false,
        isValid: false,
        ctrlpath: true,
        options: [...catalogs.process_accounting_documents_statuses],
        config: {
            id: 'general_balance',
            type: 'simple_select',
            fullWidth: true,
            label: 'Disponibilidad',
            helper: 'Disponibilidad'
        },
        rules: {
            type: 'none',
        }
    },
    tax_payment_record: {
        value: null,
        error: false,
        isVisited: false,
        isRequired: false,
        isValid: false,
        ctrlpath: true,
        options: [...catalogs.process_accounting_documents_statuses],
        config: {
            id: 'tax_payment_record',
            type: 'simple_select',
            fullWidth: true,
            label: 'Disponibilidad',
            helper: 'Disponibilidad'
        },
        rules: {
            type: 'none',
        }
    },
    financial_statements: {
        value: null,
        error: false,
        isVisited: false,
        isRequired: false,
        isValid: false,
        ctrlpath: true,
        options: [...catalogs.process_accounting_documents_statuses],
        config: {
            id: 'financial_statements',
            type: 'simple_select',
            fullWidth: true,
            label: 'Disponibilidad',
            helper: 'Disponibilidad'
        },
        rules: {
            type: 'none',
        }
    },
    accounting_adjustments: {
        value: null,
        error: false,
        isVisited: false,
        isRequired: false,
        isValid: false,
        ctrlpath: true,
        options: [...catalogs.process_accounting_documents_statuses],
        config: {
            id: 'accounting_adjustments',
            type: 'simple_select',
            fullWidth: true,
            label: 'Disponibilidad',
            helper: 'Disponibilidad'
        },
        rules: {
            type: 'none',
        }
    },
    auxiliars: {
        value: null,
        error: false,
        isVisited: false,
        isRequired: false,
        isValid: false,
        ctrlpath: true,
        options: [...catalogs.process_accounting_documents_statuses],
        config: {
            id: 'auxiliars',
            type: 'simple_select',
            fullWidth: true,
            label: 'Disponibilidad',
            helper: 'Disponibilidad'
        },
        rules: {
            type: 'none',
        }
    },
    special_reports: {
        value: null,
        error: false,
        isVisited: false,
        isRequired: false,
        isValid: false,
        ctrlpath: true,
        options: [...catalogs.process_accounting_documents_statuses],
        config: {
            id: 'special_reports',
            type: 'simple_select',
            fullWidth: true,
            label: 'Disponibilidad',
            helper: 'Disponibilidad'
        },
        rules: {
            type: 'none',
        }
    },
}

export default SectionF