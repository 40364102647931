export const contentData = {
    spanish:{
      nodata:'Sin Registro',
      error:'Sin registro',
      button:'Guardar',
      cancelButton:'Cancelar',
      image_button:'Cambiar',
      form:{
        responsible_id:{
          placeholder:'Responsable'
        },
        revision_date:{
          placeholder:'Fecha de Revisión'
        },
        revised_by:{
          placeholder:'Revisó'
        },
        control_client_status_id:{
          placeholder:'Status Cliente'
        },
        process_status_id:{
          placeholder:'Status'
        },
        sent_date:{
          placeholder:'Fecha de Envío'
        },
        notes:{
          placeholder:'Notas'
        },
        extra_hours:{
          placeholder:'Horas Extra'
        },
        extra_charges:{
          placeholder:'Cargos Extra'
        },
        collections_charge:{
          placeholder:'Cargo a Cobranza'
        },
        completition_date:{
          placeholder:'Fecha de Completado'
        }
      }
    }
  }
