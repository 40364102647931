export const contentData = {
    spanish:{
      title:'Información Basica',
      nodata:'Sin Registro',
      error:'Sin registro',
      button:'Guardar',
      cancelButton:'Cancelar',
      image_button:'Cambiar',
      form:{
        rfc:{
          label:'RFC',
          placeholder:'RFC'
        },
        responsible_id:{
          label:'Responsable',
          placeholder:'Responsable'
        }
      }
    }
  }