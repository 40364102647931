import moment from 'moment'

export const cleanData2Table = (prevData, currentData, noData) =>{
    let temp = {
        id: currentData.id_ctrl_billing,
        completed: currentData.completed ? true : false,
        files:[],
        data:[]
    }

    let fixedDataA = [
        currentData.businesses_name ? currentData.businesses_name : noData,
        currentData.rfc ? currentData.rfc : noData,
        currentData.client_status ? currentData.client_status : noData,
        currentData.client_classification ? currentData.client_classification : noData,
        currentData.fiscal_regime ? currentData.fiscal_regime : noData,
        currentData.sub_activity ? currentData.sub_activity : noData,
        currentData.billing_stamps_limits ? currentData.billing_stamps_limits : noData

    ];

    let fixedDataB = [
        currentData.billing_account_account ? currentData.billing_account_account : noData,
        currentData.billing_account_type ? currentData.billing_account_type : noData,
        currentData.billing_account_status ? currentData.billing_account_status : noData, 
        currentData.billing_account_password ? currentData.billing_account_password : noData,
        currentData.billing_account_stamps_expiration ? moment(currentData.billing_account_stamps_expiration).format('DD-MM-YYYY') : noData,
        currentData.billing_account_responsible_id ? currentData.billing_account_responsible_id : noData ,
        currentData.billing_account_responsible ? currentData.billing_account_responsible : noData
    ]

    let varData = [
        currentData.notes ? currentData.notes : noData,
        currentData.extra_hours ? currentData.extra_hours : noData,
        {key:'hours_proof'},
        currentData.extra_charges ? currentData.extra_charges : noData,
        {key:'charge_proof'},
        currentData.collections_charge ? currentData.collections_charge : noData,
        currentData.completition_date ? moment(currentData.completition_date).format('DD-MM-YYYY') : noData,
    ]

    let origin_data = {
        notes : currentData.notes,
        extra_hours : currentData.extra_hours,
        extra_charges : currentData.extra_charges,
        collections_charge : currentData.collections_charge,
        completition_date : currentData.completition_date
    }

    let tempA = {...temp}
    tempA.data = [...fixedDataA]
    prevData.fixedDataA.push(tempA);

    let tempB = {...temp}
    tempB.data = [...fixedDataB]
    prevData.fixedDataB.push(tempB);

    let tempVar = {...temp}
    tempVar.data = [...varData]
    tempVar.files = [
        {id:currentData.id_ctrl_billing,key:'work_sheet',name:'Hoja de Trabajo',path:'worksheet',
            status: checkStatus(currentData, 'work_sheet')},
        {id:currentData.id_ctrl_billing,key:'capture_line_imss_infonavit',name:'Línea de Captura IMSS/Infonavit',
            path:'capturelineimssinfonavit', status:checkStatus(currentData, 'capture_line_imss_infonavit')},
        {id:currentData.id_ctrl_billing,key:'client_answer',name:'Respuesta del Cliente',path:'clientanswer',
            status: checkStatus(currentData, 'client_answer')},
        {id:currentData.id_ctrl_billing,key:'hours_proof',name:'Comprobante de horas',path:'hoursproof',
            status: checkStatus(currentData, 'hours_proof')},
        {id:currentData.id_ctrl_billing,key:'charge_proof',name:'Tabla SAT',path:'chargeproof',
            status: checkStatus(currentData, 'charge_proof')}
    ]
    tempVar.origin_data = {...origin_data}
    
    prevData.varData.push(tempVar);
    return prevData;
}

const checkStatus = (item, key) => {
    return item[key] !== "0" && item[key] !== 0 && item[key] !== ""
}
