export const formData = {
  classification_id:{
    value: null,
    error: false,
    isVisited: false,
    isRequired: false,
    isValid: false,
    options:[],
    config:{
      id:'classification_id',
      type:'simple_select',
      fullWidth: true,
    },
    rules:{
      type:'none',
    }
  },
  pre_fiscal_regime_id:{
    value: null,
    error: false,
    isVisited: false,
    isRequired: false,
    isValid: false,
    options:[],
    config:{
      id:'pre_fiscal_regime_id',
      type:'simple_select',
      fullWidth: true,
    },
    rules:{
      type:'none',
    }
  },
  pre_contact_medium_id:{
    value: null,
    error: false,
    isVisited: false,
    isRequired: false,
    isValid: false,
    options:[],
    config:{
      id:'pre_contact_medium_id',
      type:'simple_select',
      fullWidth: true,
    },
    rules:{
      type:'none',
    }
  },
  pre_contact_date:{
    value: null,
    error: false,
    isVisited: false,
    isRequired: false,
    isValid: false,
    config:{
      id:'pre_contact_date',
      type:'date',
      fullWidth: true,
      icon:{
        name:'calendar_today',
        position:'start'
      },
    },
    rules:{
      type:'date',
    }
  },
  pre_required_date:{
    value: null,
    error: false,
    isVisited: false,
    isRequired: false,
    isValid: false,
    config:{
      id:'pre_required_date',
      type:'date',
      fullWidth: true,
      icon:{
        name:'calendar_today',
        position:'start'
      },
    },
    rules:{
      type:'date',
    }
  },
  pre_end_date:{
    value: null,
    error: false,
    isVisited: false,
    isRequired: false,
    isValid: false,
    config:{
      id:'pre_end_date',
      type:'date',
      fullWidth: true,
      icon:{
        name:'calendar_today',
        position:'start'
      },
    },
    rules:{
      type:'date',
    }
  },
  pre_observations:{
    value: null,
    error: false,
    isVisited: false,
    isRequired: false,
    isValid: false,
    config:{
      id:'pre_observations',
      type:'text',
      fullWidth: true,
    },
    rules:{
      type:'distance',
      min:1,
      max:100
    }
  },
  
  pre_contact_name:{
    value: null,
    error: false,
    isVisited: false,
    isRequired: true,
    isValid: false,
    config:{
      id:'pre_contact_name',
      type:'text',
      fullWidth: true,
    },
    rules:{
      type:'distance',
      min:1,
      max:100
    }
  },

}