export const contentData = {
  spanish:{
    layout:{
      title:'Agregar negocio',
      navigation:[
        {name:'Clientes',path:'/clients'},
        {name:'Detalles',path:'/clients/details/:id'},
        {name:'Negocios',path:'/clients/details/:id/businesses'},
        {name:'Agregar',path:'/clients/details/:id/add'},
      ],
      menu:null
    },
  }
}