import React from 'react'
import { Grid, IconButton, Icon} from '@material-ui/core'
import CardWrapper from './CardWrapper/CardWrapper'
import {RoundedButton} from '../Buttons/GeneralButtons'
import {Subtitle} from '../DisplayData/DisplayData'
import SimpleLoader from '../Loaders/SimpleLoader'


const ViewCardWrapper = (props) => {

  const {onClick, button, title, level, issending, disabled, onClose} = props
  let loaderContent = null
  if(issending){
    loaderContent = <SimpleLoader />
  }

  let titleContent = null
  let editButton = button ? (
    <Grid item xs={12}>
      <Grid container justify='flex-end' alignItems='center' spacing={2}>
        <Grid item>
          {loaderContent}
        </Grid>
        <Grid item>
          <RoundedButton 
            disabled={disabled}
            label={button} 
            size='small' 
            color='secondary'
            onClick={onClick}
          />
        </Grid>
      </Grid>
    </Grid>
  ) : null
  if(level){
    if(level<3){
      editButton = null
    }
  }


  if(title){
    titleContent = <Grid item xs>
      <Subtitle data={title}/>
    </Grid>
  }

  let closeButton = null
  if(onClose){
    closeButton = (
      <Grid item>
        <IconButton onClick={onClose} style={{padding:0}}><Icon>close</Icon></IconButton>
      </Grid>
    )
  }
    
  return(
    <CardWrapper>
      <div style={{padding:'32px'}}>
        <Grid container spacing={4}>
          <Grid item xs={12}>
            <Grid container spacing={1}>
              {titleContent}
              {closeButton}
            </Grid>
          </Grid>
          <Grid item xs={12}>
            {props.children}
          </Grid>
          {editButton}
        </Grid>
      </div>
    </CardWrapper>
  )
}

export default ViewCardWrapper