export const contentData = {
  spanish:{
    title:'Beneficios',
    nodata:'Sin Registro',
    error:'Sin registro',
    save_button:'Guardar',
    image_button:'Cambiar',
    sectionA:{
      phone_number:{
        label:'Teléfono',
        placeholder:'Teléfono',
      },
      work_email:{
        label:'Correo electrónico',
        placeholder:'Correo electrónico',
      },
      personal_email:{
        label:'Correo personal',
        placeholder:'Correo personal',
      },
      gmail:{
        label:'Gmail',
        placeholder:'Gmail',
      },
      dropbox:{
        label:'Dropbox',
        placeholder:'Dropbox',
      }
    },
  },
}