import React, { useState, useEffect } from 'react';
import { Grid, makeStyles } from '@material-ui/core';
import DocumentElement from '../../../../../../components_v1/Forms/DocumentElement';
import SimpleCard from '../../../../../../components_v1/Structure/Cards/SimpleCard';
import DisplayText from '../../../../../../components_v1/Texts/DisplayText';
import { onUpdateDocumentList } from '../../../../../../shared/utility';
import { PERMISSION_TYPES } from '../../../../../../variables/config';

const DocumentsCards = ({ files, title, onUpload, onDownload, onDelete, permission }) => {

    const classes = useStyles()
    const _files = onUpdateDocumentList(local_files, files)

    let can_edit = true
    let can_create = true
    let has_full_access = true
    if (permission) {
        can_edit = permission > PERMISSION_TYPES.READ
        can_create = permission > PERMISSION_TYPES.EDIT
        has_full_access = permission > PERMISSION_TYPES.CREATE
    }

    return (
        <SimpleCard>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <DisplayText color='primary' variant='subtitle1' >{title}</DisplayText>
                </Grid>
                <Grid item xs={12}>
                    <Grid container>
                        <Grid item xs={12}>
                            <div className={classes.header}>
                                <Grid container>
                                    <Grid item xs><DisplayText variant='body2' color='textSecondary'>Documento</DisplayText></Grid>
                                    <Grid item><DisplayText variant='body2' color='textSecondary'>Acciones</DisplayText></Grid>
                                </Grid>
                            </div>
                        </Grid>
                        <Grid item xs={12}>
                            <div className={classes.container}>
                                <Grid container>
                                    {_files.map(item => <Grid item xs={12} key={item.key}>
                                        <DocumentElement can_edit={can_edit} has_full_access={has_full_access} data={item} onUpload={onUpload} onDownload={onDownload} onDelete={onDelete} />
                                    </Grid>)}
                                </Grid>
                            </div>
                        </Grid>
                    </Grid>

                </Grid>
            </Grid>
        </SimpleCard>
    );
}

const useStyles = makeStyles(theme => ({
    container: {
        maxHeight: 369,
        overflowY: 'auto'
    },
    header: {
        padding: 8,
        paddingRight: 24
    }
}))

export default DocumentsCards;

const local_files = [
    { key: 'ine', name: 'Expediente digital', path: 'ine', status: false },
    { key: 'job_application', name: 'Contrato', path: 'jobapplication', status: false },

]