export const contentData = {
  spanish:{
    form:{
      username:{
        label:null,
        placeholder:'Usuario',
      },
      password:{
        label:null,
        placeholder:'Contraseña',
      }
    },
    error:'Usuario/Contraseña incorrectos',
    button:{label:'Ingresar'}
  }
}