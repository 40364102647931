export const contentData = {
    spanish:{
        layout:{
            title:'Prevención en Lavado de Dinero',
            navigation:[
                {name:'Clientes',path:'/clients'},
                {name:'Detalles',path:'/clients/details/:id'},
                {name:'Negocios',path:'/clients/details/:id/businesses'},
                {name:'Detalles',path:'/clients/details/:id/businesses/:idbusiness'},
                {name:'Servicio',path:'/clients/details/:id/businesses/:idbusiness/service/:idservice'},
            ],
            menu:[
                {id:0, label:'General'},
                {id:1, label:'Representante Legal'},
                {id:2, label:'Logs'}
              ]
        },
        modals:{
            warning_delete_file:{
                title:'¿Estás seguro?',
                message:'¿Realmente quieres eliminar este registro? Este proceso no puede ser revertido',
                cancelButton:'Cancelar',
                deleteButton:'Eliminar'
            },
            edit_service:{
                title:'Editar servicio',
                button:'Guardar',
                cancelButton:'Cancelar'
            }
        },
        basicform:{
            title:'Información Básica',
            button:'Editar',
            nodata:'Sin Registro',
            data:{
                rfc:{
                    label:'',
                    data:''
                },
                vulnerable_activity:{
                    label:'',
                    data:''
                },
                compliance_officer:{
                    label:'',
                    data:''
                },
                report_limit_date:{
                    label:'',
                    data:''
                },
                responsible:{
                    label:'',
                    data:''
                }
            },
            content:{
                rfc:{
                    label:'RFC'
                },
                vulnerable_activity:{
                    label:'Actividad Vulnerable'
                },
                compliance_officer:{
                    label:'Oficial de Cumplimiento'
                },
                report_limit_date:{
                    label:'Fecha Límite de Reportes de Envío'
                },
                responsible:{
                    label:'Responsable'
                }
            },
            documents:{
                title:'Documentos',
                header:{title:'Archivos', actions:'Acciones'},
                files:[
                    {key:'fiscal_situation_proof',name:'Constancia de Situación Fiscal de la Sociedad',path:'fiscalsituationproof', status:false, businessDocument:true},
                    {key:'constitutive_act',name:'Acta Constitutiva',path:'constitutiveact', status:false, businessDocument:true},
                    {key:'vulnerable_activities_format',name:'Formato "Alta Actividades Vulnerables"',path:'vulnerableactivitiesformat', status:false},
                ]
            }
        }
    }
}
