import { onGetResponsibleObj } from "../../../../../shared/utility"

export const cleanData2Table = (data, nodata, users) => {

  const table = data.map(item => {
    
    let temp = {
      id:item.id_bank_account_opening, 
      responsible: onGetResponsibleObj(item.responsible_id, users, nodata),
      files:[],
      data:[]
    }
    
    let responsible_fullname = ''
    responsible_fullname = responsible_fullname + (temp.responsible.first_name ? temp.responsible.first_name : nodata)
    responsible_fullname = responsible_fullname + ' '+ (temp.responsible.last_name ? temp.responsible.last_name : '')


    let array = [
      item.id_bank_account_opening, 
      item.name ? item.name : nodata,
      responsible_fullname,
      item.due_date ?  item.due_date : nodata,
    ]
    
    temp.data = [...array]
    return temp
  })

  return table
}

//const checkStatus = (item, key) => {
//  return item[key] !== "0" && item[key] !== 0 && item[key] !== ""
//}

const findResponsible  = (id, users, nodata) => {
  let temp = {
    first_name: nodata.split(' ')[0], 
    last_name:nodata.split(' ')[1],
    image:"0"
  }
  
  if(id !== null && id !== undefined && users){
    if(users.length > 0){
      let aux = users.find(el => el.id_user === id)
      if(aux) temp = {...aux}
    }
  }
  return temp
}