import React, {Component} from 'react'
import {Grid} from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles';
import ControlTableRow from './ControlTableRow/ControlTableRow'
import ControlTableTable from "../../Tables/ControlTableTable";
import IconButton from '@material-ui/core/IconButton';
import AddIcon from '@material-ui/icons/Add';
import RemoveIcon from '@material-ui/icons/Remove';
import styles from './styles'
import cx from 'classnames'
import ContentText from '../../Texts/ContentText';
import ControlTableFilter from './ControlTableFilter/ControlTableFilter';
import { grey, blueGrey } from '@material-ui/core/colors';

class ControlTableForm extends Component {

    state = {
        rowsPerPage:1000,
        page:0,
        tableAshow:false,
        tableBshow:false,
        searchText:null,
        direction:0,
        modals:{
            filemodal:{open:false,issending:false,error:null,extra:null},
            warning_delete_file:{open:false, issending:false, error:null, id:null}
        },
        success:{open:false,timeout:2000,type:'success',message:'Successful action!'},
    }


    handleChangeRowsPerPage = (event) => {
        const value = parseInt(event.target.value,10)
        this.setState({rowsPerPage:value})
    }

    handleChangePage = (event, newPage) => {
        this.setState({page:newPage})
    }

    hideTable = table => {
        if(table === 'A')
            this.setState((prevState, props) => ({
                tableAshow: !prevState.tableAshow
            }));

        if(table === 'B')
        this.setState((prevState, props) => ({
            tableBshow: !prevState.tableBshow
        }));
    }

    onSearch = (data) => {
        this.setState({searchText:data})
    }

    onChangeState = () => {
        const {direction} = this.state
        let new_value = direction
        new_value++
        if(new_value > 2){
            new_value = 0
        }
        this.setState({direction:new_value})
    }

    onFilteringData = (data, text, direction) => {
        //console.log(data)
        //console.log(text)
        //console.log(direction)
        let dataA=[], dataB=[], dataC = []

        const {fixedDataA, fixedDataB, varData} = data

        if(text && text !== ''){
            fixedDataA.forEach((item,key)=>{
                if(item.data[0].includes(text)){
                    dataA.push(item)
                    dataB.push(fixedDataB[key])
                    dataC.push(varData[key])
                }
            })
        }

        let tempA=[], tempB=[], tempC = []



        return data

    }


    render(){

        const {classes, staticview, data, formData, content, catalog_matrix, users,
            catalogs, onEditData, onFileAction, completed, updateData, filterBy, language,
            filterByMonth, filterByRange, month, initDate, finalDate, onChangeState, onSearch, 
            direction, permission, permissionsType } = this.props
        const {tableAshow, tableBshow, rowsPerPage,page, searchText} = this.state
        
        const spaceContent = (<Grid item xs={12} style={{height:'150px'}}/>)

        //let temp = this.onFilteringData(data, searchText, direction);
        const {fixedDataA, fixedDataB, varData} = data
        const {headers} = staticview;

        const sliceArrayA = fixedDataA.slice(page*rowsPerPage, page * rowsPerPage + rowsPerPage)

        const filterHeader = (
            <ControlTableFilter
                updateData={updateData}
                completed={completed}
                position={direction}
                month={month}
                initDate={initDate}
                finalDate={finalDate}
                filterBy={filterBy}
                filterByMonth={filterByMonth}
                filterByRange={filterByRange}
                onChangeState={onChangeState}
                onSearch={onSearch}
                language={language}
            />
        )

        let rowsTableA = sliceArrayA.map(row => {
            return(<ControlTableRow
                data={row}
                onFileAction={onFileAction}
                wrap={!tableAshow}
            />)
        })

        const fixedTableA = (
            <ControlTableTable
                title={''}
                header={headers.fixedHeaderA}
                rowsPerPage={rowsPerPage}
                page={page}
                rows={fixedDataA.length}
                onChangePage={this.handleChangePage}
                onChangeRowsPerPage={this.handleChangeRowsPerPage}
                addItem={false}
                noSearch={true}
                className={classes.borderTable}
                wrap={!tableAshow}
            >
                {rowsTableA}
                {spaceContent}
            </ControlTableTable>
        )
        
        let rowsTableB = null;
        let fixedTableB = null;
        if(fixedDataB){
            const sliceArrayB = fixedDataB.slice(page*rowsPerPage, page * rowsPerPage + rowsPerPage)

            rowsTableB = sliceArrayB.map(row => {
                return(<ControlTableRow
                    data={row}
                    onFileAction={onFileAction}
                    wrap={!tableBshow}
                />)
            })
    
            fixedTableB = (
                <ControlTableTable
                    title={''}
                    header={headers.fixedHeaderB}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    rows={fixedDataB.length}
                    onChangePage={this.handleChangePage}
                    onChangeRowsPerPage={this.handleChangeRowsPerPage}
                    addItem={false}
                    noSearch={true}
                    className={classes.borderTable}
                    wrap={!tableBshow}
                >
                    {rowsTableB}
                    {spaceContent}
                </ControlTableTable>
            )
        }
        
        const sliceArrayVar = varData.slice(page*rowsPerPage, page * rowsPerPage + rowsPerPage)

        let rowsTableVar = sliceArrayVar.map(row => {
            return(<ControlTableRow
                data={row}
                edit={permission >= permissionsType.EDIT}
                onEdit={onEditData}
                onFileAction={onFileAction}
                formData={formData}
                content={content}
                catalog_matrix={catalog_matrix}
                catalogs={catalogs}
                users={users}
            />)
        })

        const varTable = (
            <ControlTableTable
                title={''}
                header={permission >= permissionsType.EDIT ? [...headers.varHeader, headers.extra] : headers.varHeader}
                rowsPerPage={rowsPerPage}
                page={page}
                rows={varData.length}
                onChangePage={this.handleChangePage}
                onChangeRowsPerPage={this.handleChangeRowsPerPage}
                addItem={false}
                noSearch={true}
                //pagination={true}
                backgroundColor={classes.tableVar}
            >
                {rowsTableVar}
                {spaceContent}
            </ControlTableTable>
        )

        const contentView = (
            <div className={classes.generalTable}>
            <Grid container wrap='nowrap'>
                <Grid item xs 
                    className={cx({
                        [classes.noWidth]:true,
                        [classes.borderTable]: true
                    })}>
                    <IconButton
                        className={classes.icon}
                        size='small'
                        onClick={()=>{this.hideTable('A')}}>
                        {tableAshow ? <RemoveIcon className={classes.subIcon}/>: <AddIcon className={classes.subIcon}/>}
                    </IconButton>
                    {fixedTableA}
                </Grid>
                {fixedDataB ? 
                    <Grid item xs 
                        className={cx({
                            [classes.noWidth]:true,
                            [classes.borderTable]: true
                        })}>
                        <IconButton
                            className={classes.icon}
                            size='small'
                            onClick={()=>{this.hideTable('B')}}>
                            {tableBshow ? <RemoveIcon className={classes.subIcon}/> : <AddIcon className={classes.subIcon}/>}
                        </IconButton>
                        {fixedTableB}
                    </Grid>: null}
                <Grid item xs className={classes.noWidth}>
                    <div style={{marginTop:'22px'}}></div>
                    {varTable}
                </Grid>
            </Grid>
        </div>

        )

        const message = (
            <div className={classes.card}>
                <ContentText text={'Sin Registro'} medium align='center'/>
            </div>
            
        )

        return(
            <div>
                <div style={{
                    borderRadius:8,
                    marginBottom:16, 
                    background:blueGrey[100], 
                    paddingLeft:8, 
                    paddingRight:8}}>
                    {filterHeader}
                </div>
                <div className={classes.root}>
                   {data.fixedDataA.length !== 0 ? contentView : message}  
                </div>
            </div>
           
        )
    }

    
}

export default withStyles(styles)(ControlTableForm)

