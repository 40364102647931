import React, { useState, useRef, useCallback } from 'react';
import { Grid, Icon, InputBase, styled } from '@material-ui/core';
import { blueGrey } from '@material-ui/core/colors';

const BCSearchbar = ({
    onRequestSearch
}) => {

    const timerRef = useRef()
    const [value, setValue] = useState("")
    const [focus, setFocus] = useState(false)

    const onInnerRequestSearch = useCallback(() => {
        if(timerRef.current) clearTimeout(timerRef.current)
            onRequestSearch(value)
    }, [timerRef, value])

    const onStartCountdown = useCallback(() => {
        if(timerRef.current) clearTimeout(timerRef.current)
        timerRef.current = setTimeout(onInnerRequestSearch, 1000)
    },[timerRef, onInnerRequestSearch])

    const onStopCountdown = useCallback(() => {
        if(timerRef.current) clearTimeout(timerRef.current)
    },[timerRef])

    

    return ( 
        <RootContainer>
            <Grid container alignItems='center' spacing={1} wrap='nowrap'>
                <Grid item>
                    <Icon style={{color:blueGrey[500]}} fontSize='default'>search</Icon>
                </Grid>
                <Grid item>
                    <InputBase 
                        value={value} 
                        placeholder='Buscar...' 
                        onChange={(e) => setValue(e.currentTarget.value)} 
                        onKeyUp={onStartCountdown}
                        onKeyDown={onStopCountdown}
                    />
                </Grid>
            </Grid>
        </RootContainer>
     );
}
 
export default BCSearchbar;

const RootContainer = styled('div')(({ theme }) => ({
    background:'white',
    boxShadow:'0 10px 24px 0 rgba(82, 91, 115, .12)',
    padding:'8px 16px',
    borderRadius:32,
}));