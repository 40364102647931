import React, {useEffect, useState} from 'react'
import { withStyles } from '@material-ui/core/styles'
import styles from './styles'
import {request_businesses, request_delete_business} from './requests'
import BasicView from '../../../../components_v1/Structure/Layouts/BasicView'
import SimpleTable from './components/SimpleTable'
import queryString from 'query-string' 
import AddBusinessModal from './modals/AddBusiness/AddBusinessModal'
import WarningModal from '../../../../components_v1/Structure/Modals/WarningModal'
import { contentData } from './content'
import { PERMISSION_MODULES } from '../../../../variables/config'

const Businesses = props => {

  const {history, match, language, permissions, permissionsType} = props

  let module_permission = null
  if(permissions && permissions.length) module_permission = permissions.find(el => el.id_permission_module === PERMISSION_MODULES.BUSINESSES)
  //COMPONENT DATA
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(null)
  const [sending, setSending] = useState(false)
  const [success, setSuccess] = useState(null)  
  
  //DATA
  const [businesses, setBusiness] = useState([])
  const [selected, setSelected] = useState(null)
  
  //Modals
  const [addbusinessmodal, setAddBusinessModal] = useState(false)
  const [warningdeletemodal, setWarningDeleteModal] = useState(false)

  //Table states
  const [total, setTotal] = useState(0)
  const [tableFilter, setTableFilter] = useState({limit:10,offset:0, order_by:'id_business', order:0})
  const [search, setSearch] = useState(null)

  const clientID = match.params.id
  const content = contentData[language]  

  useEffect(() => {
    const initModule = async() => {try {
        console.log(search)
        setLoading(true)
        const args = queryString.parseUrl(history.location.search)
        const {limit, offset, order_by, order} = tableFilter
        let filterArgs = [
            {"field":"name","operator":"LIKE","value":`%${search?search:''}%`},
        ]
        filterArgs = encodeURIComponent(JSON.stringify(filterArgs))
        const param2send = {limit:limit, offset:offset*limit, order_by:order_by, order:order}
        let params = `?${queryString.stringify(param2send)}&filter=${filterArgs}` //&filter=${filterArgs}
        const _businesses = await request_businesses(clientID,params)
        setBusiness(_businesses.businesses)
        setTotal(_businesses.count)
    } catch (error) {
        console.log(error)
    }
    setLoading(false)}
    initModule()
},[tableFilter, search])

const onChangeSearch = (val) => {
    setSearch(val)
    setTableFilter({...tableFilter, offset:0})
}

const onPreDeleteBusiness = (id) => {
  setSelected(id)
  setWarningDeleteModal(true)
}

const onDeleteBusiness = async() => {
  try {
    setSending(true)
    const {limit, offset, order_by, order} = tableFilter
    let filterArgs = [
        {"field":"name","operator":"LIKE","value":`%${search?search:''}%`},
    ]
    filterArgs = encodeURIComponent(JSON.stringify(filterArgs))
    const param2send = {limit:limit, offset:offset*limit, order_by:order_by, order:order}
    let params = `?${queryString.stringify(param2send)}&filter=${filterArgs}` //&filter=${filterArgs}
    let _business = await request_delete_business(clientID,selected, params)
    setBusiness(_business.businesses)
    setTotal(_business.count)
    setWarningDeleteModal(false)
    setSending(false)
    setSuccess(true)
  } catch (error) {
    console.log(error)
    setError('Hubo un error al intentar eliminar el usuario')
  }
  setSending(false)
}

  /*onReturn = () => {
    this.props.history.push(`/clients/details/${this.props.match.params.id}`)
  }*/
    
return(
  <div>
    <BasicView
        title={content.layout.title} 
        success={success} 
        error={error} 
        sending={sending}
        onCloseSuccess={() => setSuccess(null)}
        onCloseError={() => setError(null)}
        setReturn
    >
        <WarningModal open={warningdeletemodal} message={content.modals.warning_modal} onClose={() => setWarningDeleteModal(false)} onDelete={onDeleteBusiness}/>
        <AddBusinessModal open={addbusinessmodal} clientID={clientID} onClose={() => setAddBusinessModal(false)} history={history} language={language}/>
        <SimpleTable 
            search={search} 
            onChangeSearch={onChangeSearch} 
            onAddRegister={() => setAddBusinessModal(true)}
            onSelectedItem={(data) => history.push(`/clients/details/${clientID}/businesses/${data.id_business}`)}
            data={businesses} 
            tableFilter={tableFilter}  
            onUpdateTableFilter={(data) => setTableFilter(data)}
            total={total} 
            loading={loading} 
            onRemoveItem={onPreDeleteBusiness}
            permission={module_permission}
        />

    </BasicView>
  </div> 
  )
}

export default withStyles(styles)(Businesses)