import {mainServer} from '../../../../../variables/config.jsx'
import FileSaver from 'file-saver'
import axios from 'axios'

export const request_business_documents = async (businessID) => {
  const logData = JSON.parse(localStorage.getItem('data'));
  mainServer.instance.defaults.headers.common['Authorization'] = logData.token;
  let response;
  response = await mainServer.instance.get("/businessdocument/"+businessID);
  return response.data.data.tributary_mailbox_statuses;
}

export const request_business_lr = async (businessID) => {
  const logData = JSON.parse(localStorage.getItem('data'));
  mainServer.instance.defaults.headers.common['Authorization'] = logData.token;
  let response;
  response = await mainServer.instance.get("/business/"+businessID);
  return response.data.data.business;
}


export const request_update_business_lr = async(id, data) => {
  const logData = JSON.parse(localStorage.getItem('data'));
  mainServer.instance.defaults.headers.common['Authorization'] = logData.token;
  await mainServer.instance.patch(`/business/${id}`, data);
}

export const request_update_businessdocs_lr = async(id, data) => {
  const logData = JSON.parse(localStorage.getItem('data'));
  mainServer.instance.defaults.headers.common['Authorization'] = logData.token;
  await mainServer.instance.patch(`/businessdocument/${id}`, data);
}


/*****************DOCUMENTS*********************** */

export const request_upload_document = async(id, type, file, data, mode) => {
  const logData = JSON.parse(localStorage.getItem('data'));
  mainServer.instance.defaults.headers.common['Authorization'] = logData.token;
  let response;
  if(mode === 'SERVICE'){
    response = await mainServer.instance.post(`/efirma/${id}/${type}`, data)
  }else{
    response = await mainServer.instance.post(`/businessdocument/${id}/${type}`, data)
  }
  const options = {headers: {'Content-Type': file.type}}
  delete axios.defaults.headers.common["Authorization"]
  await axios.put(response.data.data.path, file, options);
}


export const request_download_document = async(id, path, filename, mode) => {
  const logData = JSON.parse(localStorage.getItem('data'));
  mainServer.instance.defaults.headers.common['Authorization'] = logData.token;
  let response;
  response = await mainServer.instance.get(`/businessdocument/${id}/${path}`)
  console.log(response)
  delete axios.defaults.headers.common["Authorization"]
  response = await axios.get(response.data.data.path, {responseType: 'blob'});
  FileSaver.saveAs(response.data, `${filename}`);
} 


export const request_delete_document = async(id, type, mode) => {
  const logData = JSON.parse(localStorage.getItem('data'));
  mainServer.instance.defaults.headers.common['Authorization'] = logData.token;
  if(mode === 'SERVICE'){
    await mainServer.instance.delete(`/efirma/${id}/${type}`)
  }else{
    await mainServer.instance.delete(`/businessdocument/${id}/${type}`)
  }
}
