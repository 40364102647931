import { Button, CircularProgress, Dialog, Grid, Grow, Icon, makeStyles } from '@material-ui/core'
import { red } from '@material-ui/core/colors';
import React from 'react'
import RoundedButton from '../../Actions/RoundedButton';
import DisplayText from '../../Texts/DisplayText';

const Transition = React.forwardRef(function Transition(props, ref) {return <Grow ref={ref} {...props} timeout={500} />;});

const useStyles = makeStyles(theme => ({
    paper:{
        borderRadius:32, 
        padding:theme.spacing(5)
    },
    root:{
        paddingTop:32,
        paddingBottom:0
    },
    icon:{
        color:red[700],
        //background:red[700],
        borderRadius:'50%',
        fontSize:72,
        margin:'auto'
        //padding:8
    },
    message:{
        paddingTop:16,
        paddingBottom:16,
    }
}))

const WarningModal = (props) => {

    const classes = useStyles()
    const {open, message, onClose, onDelete} = props

    return(
        <Dialog open={open} maxWidth='xs' fullWidth classes={{ paper: classes.paper }} className={classes.root}
        TransitionComponent={Transition}>
            <div className={classes.root}>
                <Grid container justify='space-around' alignItems='center' spacing={3}>
                    <Grid item>
                        <div className={classes.iconContainer}>
                            <Icon className={classes.icon}>close</Icon>
                        </div>
                    </Grid>
                    <Grid item xs={12}>
                        <DisplayText variant='h6' align='center' style={{paddingTop:8}}> ¿Estás seguro? </DisplayText>
                    </Grid>
                    <Grid item xs={12}>
                        <div className={classes.message}>
                            <DisplayText color='textSecondary' align='center'>{message}</DisplayText>
                        </div>
                    </Grid>
                    <Grid item>
                        <RoundedButton color='primary' onClick={onDelete}>Continuar</RoundedButton>
                    </Grid>
                    <Grid item>
                        <Button onClick={onClose}>Cerrar</Button>
                    </Grid>
                </Grid>
            </div>
        </Dialog>
    )
}

export default WarningModal
