export const contentData = {
  spanish:{
    title:'Información Basica',
    nodata:'Sin Registro',
    error:'Sin registro',
    button:'Guardar',
    cancelButton:'Cancelar',
    image_button:'Cambiar',
    form:{
      usb_id:{
        label:'USB',
        placeholder:'USB'
      },
      process_id:{
        label:'Tipo de proceso',
        placeholder:'Tipo de proceso'
      },
      responsible_id:{
        label:'Responsable',
        placeholder:'Responsable'
      },
      comments:{
        label:'Comentarios',
        placeholder:'Comentarios'
      },
      estimated_delivery_date:{
        label:'Fecha estimada de entrega',
        placeholder:'Fecha estimada de entrega'
      },
      request_date:{
        label:'Fecha Requerida',
        placeholder: 'Fecha Requerida'
      },
      project_costs:{
        label:'Costos del proyecto',
        placeholder:'Costos del proyecto'
      },
      provider:{
        label:'Nombre del Proveedor',
        placeholder:'Nombre del Proveedor'
      },
    },
  },
}