
import moment from 'moment'

export const cleanData2Table = (data, nodata) => {

  const table = data.map(item => {

    let temp = {
      id:item.id_state_register,
      used_id: item.used,
      files:[
        {id:item.id_state_register,
        key:'enrollment',name:'Inscripción del Registro Estatal',path:'enrollment', status: checkStatus(item, 'enrollment')}
      ],
      data:[]
    }

    let array = [
      item.id_state_register,
      item.user ? item.user: nodata,
      item.password ? item.password: nodata,
      item.state ? item.state : nodata,
      item.limit_date ? moment(item.limit_date).format('DD-MM-YYYY') : nodata
    ]

    temp.data = [...array]
    return temp
  })

  return table
}

const checkStatus = (item, key) => {
  return item[key] !== "0" && item[key] !== 0 && item[key] !== ""
}
