import React from 'react';
import {Paper} from '@material-ui/core'

const style = {
  paper:{
    boxShadow:'0 10px 24px 0 rgba(82, 91, 115, .12)',
  },
}

const CardWrapper = (props) => {
  return(
    <Paper style={style.paper}>
      {props.children}
    </Paper>
  )
}

export default CardWrapper;