export const contentData = {
  spanish:{
    nodata:'Sin Registro',
    notable:'No existen registros',
    tableTitle:'',
    header:['ID','Nombre', 'Fecha de expiración',''],
    modals:{
      warning_delete_item:'¿Realmente quieres eliminar este registro? Este proceso no puede ser revertido'
    },
    delete_modal:{
      message:'¿Realmente quieres eliminar este registro? Este proceso no puede ser revertido'
    },
    add_modal:{
      title:'Información Básica',
      nodata:'Sin Registro',
      error:'Sin registro',
      button:'Guardar',
      cancelButton:'Cancelar',
      image_button:'Cambiar',
    }
  },
  english:{

  }
}