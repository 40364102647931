import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import styles from './styles.jsx';
import {
  Avatar,
  Grid,
  withWidth,
  Menu,
  MenuItem,
  Hidden,
  Typography
} from '@material-ui/core';

import { withRouter } from 'react-router-dom';
import NotificationBubble from '../../../components_v1/Texts/NotificationBubble.jsx';

class UserButton extends Component {

  state = {
    anchorEl: null,
  };

  handleClick = event => {
    this.setState({ anchorEl: event.currentTarget });
  };

  handleClose = () => {
    this.setState({ anchorEl: null });
  };

  handleLogout = () => {
    //this.props.onLogout();
    localStorage.clear()
    this.props.history.push("/signin");
  }

  render() {

    const { classes, user, notifications } = this.props;
    const { anchorEl } = this.state;

    let avatarText = ''
    if (user) {
      avatarText = avatarText + (user.first_name ? user.first_name[0] : '')
      avatarText = avatarText + (user.last_name ? user.last_name[0] : '')
    }


    const contetDesktop = (
      <Hidden smDown>
        <div className={classes.root}>
          <Grid container alignItems='center' justify='center' spacing={8}>
            <Grid item>
                <div className={classes.avatar_container}>
                  {notifications && notifications.pending_personal_evaluations > 0 &&
                      <NotificationBubble number={notifications.pending_personal_evaluations} top={-7} right={-3}/>
                  }
                  <Avatar className={classes.avatar}>
                    <Typography variant='body2' className={classes.text}>
                      {avatarText}
                    </Typography>
                  </Avatar>
                </div>
                
            </Grid>
          </Grid>
          <Menu
            id="user-menu"
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={this.handleClose}
          >
            <MenuItem onClick={this.handleLogout}>Cerrar sesión</MenuItem>
          </Menu>
        </div>

      </Hidden>
    );

    const contentMobile = (
      <Hidden mdUp>
        <div className={classes.root}>
          <Grid container alignItems='center' justify='center' spacing={8}>
          </Grid>
          <Menu
            id="user-menu"
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={this.handleClose}
          >
            <MenuItem onClick={this.handleLogout}>Cerrar sesión</MenuItem>
          </Menu>
        </div>
      </Hidden>
    );

    return (
      <div>
        {contetDesktop}
        {contentMobile}
      </div>
    );
  }
};

export default withRouter((withStyles(styles)(withWidth()(UserButton))));
