import React from 'react'
import { Icon, IconButton, Typography, styled } from '@material-ui/core';
import { red } from '@material-ui/core/colors';

const DisplayError = ({text, onClose}) => {


    if(!text) return null

    const _text = text?.message || text

    return ( 

        <StyledRoot >
            <Icon>error</Icon>
            <StyledTypography variant='subtitle2' color='textPrimary' >{_text}</StyledTypography>
            <IconButton size='small' onClick={onClose}><Icon>close</Icon></IconButton>
        </StyledRoot>
     );
}
 
export default DisplayError;

const StyledRoot = styled('div')(({ theme }) => ({
    background:red[100],
    border:`1px dashed ${red[300]}`,
    padding:8,
    borderRadius:8,
    marginBottom:16,
    color:red[900],
    display:'flex',
    wrap:'no-wrap',
    alignItems:'center'
}));

const StyledTypography = styled(Typography)(({ theme }) => ({
    fontWeight:400,
    marginLeft:4,
    flex:1
}));