import { Grid, makeStyles } from '@material-ui/core';
import moment from 'moment';
import React, { useState, useEffect } from 'react';
import ActionModalBar from '../../../../components_v1/Actions/ActionModalBar';
import RoundedButton from '../../../../components_v1/Actions/RoundedButton';
import InputSelect from '../../../../components_v1/Forms/InputSelect';
import SimpleModal from '../../../../components_v1/Structure/Modals/SimpleModal';
import DisplayText from '../../../../components_v1/Texts/DisplayText';
import { isFormValid, onGetCatalogs, onSetErrorsToForm } from '../../../../shared/utility';
import { catalogs } from '../../../../texts/esp/catalogs';
import { request_businesses } from '../requests'

const useStyles = makeStyles(theme => ({
    form:{
        paddingTop:32,
        paddingBottom:32
    }
}))

const ClassificationFilterModal = (props) => {

    const classes = useStyles()
    const { open, onClose,  onAddFilter } = props
    const [form, setForm] = useState(JSON.parse(JSON.stringify(formData)))
    const [error, setError] = useState()
    const [loading, setLoading] = useState(false)

    useEffect(() => {
        if (!open) {
            setForm(JSON.parse(JSON.stringify(formData)))
            setError(''); setLoading(false);
        }
    }, [open])

    const onChange = (data) => {
        let temp = { ...form }
        const id = data.config.id
        temp[id] = { ...data }
        setForm(temp)
    }


    const onSubmit = async () => {
        const errors = isFormValid(form)
        /// Check if the form is valid
        if(errors && errors.length){
            const new_form = onSetErrorsToForm(form, errors)
            setForm(new_form)
            return
        }

        const data2send = catalogs.client_classifications.find(el => el.value === form.id_client_classification.value)

        onAddFilter(data2send, 'classification')
        onClose()

    }

    // Update catalogs in real time 

    let _form = {...form.id_client_classification}
    _form.options = [...catalogs.client_classifications]

    return ( 
        <SimpleModal open={open} onClose={onClose} maxWidth='sm' padding='small'>
            <div>
                <div className={classes.form}>
                    <Grid container spacing={3}>
                        <Grid item xs={12}>
                            <DisplayText variant='subtitle1'>Seleccione una clasificación</DisplayText>
                            <InputSelect data={_form} onChange={onChange} />
                        </Grid>
                        <Grid item xs={12}>
                            <Grid container justify='flex-end'>
                                <Grid item><RoundedButton onClick={onSubmit} >Seleccionar</RoundedButton></Grid>
                            </Grid>
                        </Grid>
                    
                    </Grid>
                </div>
            </div>
        </SimpleModal>
     );
}
 
export default ClassificationFilterModal;

const formData = {
    id_client_classification: {
        value: '',
        error: false,
        isVisited: false,
        isRequired: true,
        isValid: false,
        options:[],
        config: {
            id: 'id_client_classification',
            type: 'select',
            fullWidth: true,
            label: '',
            placeholder:'Seleccione una opción',
            helper: 'Este campo es requerido'
        },
        rules: {type: 'select'}
    },
    
}