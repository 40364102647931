import { alpha } from "@material-ui/core";

const styles = theme => ({
  root:{
    height: "100vh",
    zIndex: 1,
    overflow: 'none',
    position: 'relative',
    display: 'flex',
    width: '100%',
    background:'#edf2f8',
    
  },
  content:{
    width:'100%',
    boxSizing:'border-box',
    marginTop:'67px',
    overflowY:'auto',
    overflowX:'hidden',
    paddingBottom:50,
  },
  ai_button:{
    position:'absolute',
    bottom:32,
    right:32,
    background:alpha(theme.palette.primary.main, 0.75),
    zIndex:5,
    '&:hover':{
      background:alpha(theme.palette.primary.main, 0.8),
    }
  }
});

export default styles;
