import React, { useEffect } from 'react'
import { connect } from 'react-redux';
import { Grid, Typography, makeStyles } from '@material-ui/core';
import CustomDropzone from './CustomDropzone'
import ReactCrop from 'react-image-crop'
import 'react-image-crop/dist/ReactCrop.css'
import usePictureModal from './usePictureModal';
import ActionModalBar from '../../../../components_v1/Actions/ActionModalBar';
import SimpleModal from '../../../../components_v1/Structure/Modals/SimpleModal';



const PictureModal = ({language, open, onClose, onCompleted, title, submit_url, delete_url, ratio, img_type, onSetTempImg, temp_img, enableHighResolution}) => {

    const classes = useStyles()
    //const content = system_translations[language].modals.picture

    const {src, crop, actions, error, loading} = usePictureModal({submit_url, delete_url, onClose, onCompleted, ratio, img_type, onSetTempImg, temp_img, enableHighResolution})


    useEffect(() => {
        if(open) {
            actions.onResetComponent()
        }
    }, [open])

    return(
        <SimpleModal open={open} onClose={onClose}>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Typography variant='h6'>{title ? title : 'Imagen'}</Typography>
                </Grid>
                <Grid item xs={12}>
                    <CustomDropzone onDropFile={actions.onDropFile} placeholder='Arrastre o de click para subir una imagen' />
                </Grid>
                {src ? (
                    <Grid item xs={12}>
                        <Grid container>
                            <Grid item xs={12}>
                                <Typography variant='subtitle1' >Imagen seleccionada</Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <div className={classes.react_drop}>
                                    <ReactCrop 
                                        src={src} crop={crop} 
                                        onImageLoaded={actions.onImageLoaded}
                                        onChange={actions.handleOnCropChange}
                                        onComplete = {actions.handleOnCropComplete}
                                    />
                                </div>       
                            </Grid>
                        </Grid>
                    </Grid>
                ) : null}
                <Grid item xs={12}>
                    <ActionModalBar error={error} loading={loading} btnLabel='Guardar' onDelete={actions.onDelete} onSubmit={actions.onSubmit}/>
                </Grid>           
            </Grid>
        </SimpleModal>
    )
}

const useStyles = makeStyles(theme => ({
    react_drop:{
        width:'100%',
        height:300,
        overflowY:'auto'
    },
    react_drop_container:{

    }
}))

const mapStateToProps = (state) => {
    return {
        language:state.language, 
    }
}

export default connect(mapStateToProps)(PictureModal)