import {mainServer} from '../../variables/config'

export const request_businesses = async(params) => {
  const logData = JSON.parse(localStorage.getItem('data'));
  mainServer.instance.defaults.headers.common['Authorization'] = logData.token;
  const response = await mainServer.instance.get(`/business/listall${params}` );
  return response.data.data
}

export const request_add_business = async(data) => {
  const logData = JSON.parse(localStorage.getItem('data'))
  mainServer.instance.defaults.headers.common['Authorization'] = logData.token
  let response = await mainServer.instance.post("/business/", data)
  return response.data.data.client_id
}

export const request_bonus = async (params) => {
  const logData = JSON.parse(localStorage.getItem('data'));
  mainServer.instance.defaults.headers.common['Authorization'] = logData.token;
  let response;
  response = await mainServer.instance.get(`/bonus/all${params}`);
  return response.data.data
}

export const request_update_bonus = async (id, data2send) => {
  const logData = JSON.parse(localStorage.getItem('data'));
  mainServer.instance.defaults.headers.common['Authorization'] = logData.token;
  let response;
  response = await mainServer.instance.patch(`/bonus/${id}`, data2send);
  return response.data.data
}

export const request_permissions = async () => {
  const logData = JSON.parse(localStorage.getItem('data'));
  mainServer.instance.defaults.headers.common['Authorization'] = logData.token;
  let response;
  response = await mainServer.instance.get(`/permission/me/list`);
  return response.data.data
}