import { catalogs } from "../../../texts/esp/catalogs";

export const content = {
    header:[
        {id:0, label:'',hidden:false, fixed:true, width:26, position:0, padding:'checkbox'},
        {id:1,key:'fixed_business_name', label:'Razón Social',hidden:false, width:300, fixed:true, add:{icon:'add'}, position:34},
        {id:2,key:'fixed_rfc', label:'RFC',hidden:false},
        {id:3,key:'fixed_client_classification', label:'Clasificación',hidden:false},
        {id:4,key:'fixed_client_status', label:'Estatus',hidden:false},
        {id:5,key:'fixed_client_responsible', label:'Asesor',hidden:false},
        {id:6,key:'fixed_sub_activity', label:'Regimen Fiscal',hidden:false, add:{icon:'add'}},
        {id:7,key:'fixed_activity_id', label:'Actividad económica',hidden:false},
        {id:8,key:'fixed_fiscal_situation', label:'Situación fiscal',hidden:false},
        {id:9,key:'fixed_accounting_method', label:'Método de pago de Impuestos',hidden:false},
        {id:10,key:'fixed_payday_limit', label:'Límite envío Impuestos',hidden:false},
        {id:11,key:'fixed_sending_date_limit', label:'Envío de Estados Financiero',hidden:false},
        {id:12,key:'fixed_tax_preview', label:'Previo de Impuestos',hidden:false},
        {id:13,key:'fixed_coi', label:'Número en COI',hidden:false},
        {id:14,key:'fixed_utility_coefficient', label:'Coeficiente de utilidad',hidden:false},
        {id:15,key:'fixed_analyst', label:'Analista',hidden:false},
        {id:16,key:'fixed_supervisor', label:'Supervisor',hidden:false},
        {id:17,key:'fixed_staff', label:'Staff',hidden:false},
        {id:18,key:'operations_number', label:'Número de Operaciones (Facturas emitidas y recibidas)',hidden:false},
        {id:19,key:'information_upload_status_id', label:'Información Cargada en:',hidden:false},
        {id:20,key:'bank_statements', label:'Estados de Cuenta Bancarios',hidden:false},
        {id:21,key:'payment_gateway', label:'Estado de Cuenta de Pasarela de Pagos',hidden:false},
        {id:22,key:'spending_control', label:'Control de Caja/Gastos',hidden:false},
        {id:23,key:'inventary_control', label:'Control de Inventarios',hidden:false},
        {id:24,key:'import_petition', label:'Pedimentos de Importación',hidden:false},
        {id:25,key:'payroll_social_security_taxes', label:'Nómina, Seguros Social, Impuestos Sobre nómina',hidden:false},
        {id:26,key:'professional_services_payment', label:'Pago de Honorarios y Servicios Profesionales',hidden:false},
        {id:27,key:'payment_service_invoice', label:'Pago y Factura del Servicios - AS Consulting Group',hidden:false},
        {id:28,key:'abroad_expenses', label:'Gastos al Extranjero',hidden:false},
        {id:29,key:'insurance_policy', label:'Polízas y Seguros',hidden:false},
        {id:30,key:'leases_rentals', label:'Arrendamientos o Rentas',hidden:false},
        {id:31,key:'intercompany_operation', label:'Operaciones Intercompañia',hidden:false},
        {id:32,key:'non_tax_provisions', label:'Registro de provisiones contables no fiscales',hidden:false},
        {id:33,key:'interest_loans_payments', label:'Pago de Intereses y/o Prestamos',hidden:false},
        {id:34,key:'capital_contributions', label:'Aportaciones de Capital',hidden:false},
        {id:35,key:'amortizations_deprecations', label:'Amortizaciones y Depreciaciones',hidden:false},
        {id:36,key:'comments', label:'Comentarios',hidden:false},
        {id:37,key:'coi_register', label:'Registro Contable en COI',hidden:false},
        {id:38,key:'bank_reconciliation', label:'Conciliación Bancaria',hidden:false},
        {id:39,key:'tax_calculation', label:'Cálculo de Impuestos',hidden:false},
        {id:40,key:'real_staff', label:'Staff',hidden:false},
        {id:41,key:'supervisor_status_id', label:'Estatus Supervisor',hidden:false},
        {id:42,key:'real_supervisor', label:'Supervisor',hidden:false},
        {id:43,key:'revision_date', label:'Fecha',hidden:false},
        {id:44,key:'notes', label:'Notas y comentarios',hidden:false},
        {id:45,key:'financial_statements', label:'Emisión de Estados Financieros',hidden:false},
        {id:46,key:'electronic_accounting', label:'Contabilidad Electrónica',hidden:false},
        {id:47,key:'balance_sheet', label:'Cierre Contable',hidden:false},
        {id:48,key:'accounting_status_id', label:'Estatus Contabilidad',hidden:false},
        {id:49,key:'real_analyst', label:'Analista',hidden:false},
        //{id:50,label:'Notas y comentarios',hidden:false},
        {id:51, key:'completition_date', label:'Fecha de Completado',hidden:false},
        {id:52, key:'completed', label:'Completado',hidden:false},

    ],
    dataConfig:[
        {id:'fixed_business_name',type:'text',fixed:true, editBtn:true, position:34},
        {id:'fixed_rfc',type:'text'},
        {id:'fixed_client_classification',type:'text'},
        {id:'fixed_client_status_id',type:'status', options: [...catalogs.client_statuses] },
        {id:'fixed_client_responsible',type:'text'},
        ///////// second fixed data
        {id:'fixed_sub_activity',type:'text'},
        {id:'fixed_activity',type:'text'},
        {id:'fixed_fiscal_situation',type:'text'},
        {id:'fixed_accounting_method',type:'text'},
        {id:'fixed_payday_limit',type:'text'},
        {id:'fixed_sending_date_limit',type:'text'},
        {id:'fixed_tax_preview',type:'boolean'},
        {id:'fixed_coi',type:'text'},
        {id:'fixed_utility_coefficient',type:'text'},
        {id:'fixed_analyst',type:'text'},
        {id:'fixed_supervisor',type:'text'},
        {id:'fixed_staff',type:'text'},
        // Real data
        {id:'operations_number',type:'text', editable:true},
        {id:'information_upload_status_id', type:'status', options: [...catalogs.accounting_information_upload_statuses], editable:true},
        {id:'bank_statements', type:'status', options: [...catalogs.accounting_documents_statuses], editable:true},
        {id:'payment_gateway', type:'status', options: [...catalogs.accounting_documents_statuses], editable:true},
        {id:'spending_control', type:'status', options: [...catalogs.accounting_documents_statuses], editable:true},
        {id:'inventary_control', type:'status', options: [...catalogs.accounting_documents_statuses], editable:true},
        {id:'import_petition', type:'status', options: [...catalogs.accounting_documents_statuses], editable:true},
        {id:'payroll_social_security_taxes', type:'status', options: [...catalogs.accounting_documents_statuses], editable:true},
        {id:'professional_services_payment', type:'status', options: [...catalogs.accounting_documents_statuses], editable:true},
        {id:'payment_service_invoice', type:'status', options: [...catalogs.accounting_documents_statuses], editable:true},
        {id:'abroad_expenses', type:'status', options: [...catalogs.accounting_documents_statuses], editable:true},
        {id:'insurance_policy', type:'status', options: [...catalogs.accounting_documents_statuses], editable:true},
        {id:'leases_rentals', type:'status', options: [...catalogs.accounting_documents_statuses], editable:true},
        {id:'intercompany_operation', type:'status', options: [...catalogs.accounting_documents_statuses], editable:true},
        {id:'non_tax_provisions', type:'status', options: [...catalogs.accounting_documents_statuses], editable:true},
        {id:'interest_loans_payments', type:'status', options: [...catalogs.accounting_documents_statuses], editable:true},
        {id:'capital_contributions', type:'status', options: [...catalogs.accounting_documents_statuses], editable:true},
        {id:'amortizations_deprecations', type:'status', options: [...catalogs.accounting_documents_statuses], editable:true},
        {id:'comments',type:'text',editable:true},
        {id:'coi_register',type:'status', options: [...catalogs.accounting_register_statuses],editable:true},
        {id:'bank_reconciliation',type:'status', options: [...catalogs.accounting_register_statuses],editable:true},
        {id:'tax_calculation',type:'status', options: [...catalogs.accounting_register_statuses],editable:true},
        {id:'real_staff', formId:'real_staff_id',type:'text',editable:true},
        {id:'supervisor_status_id',type:'status', options: [...catalogs.supervisor_statuses],editable:true},
        {id:'real_supervisor', formId:'real_supervisor_id',type:'text',editable:true},
        {id:'revision_date',type:'date', variant:'date',editable:true},
        {id:'notes',type:'text',editable:true},
        {id:'financial_statements',type:'status', options: [...catalogs.accounting_register_statuses],editable:true},
        {id:'electronic_accounting',type:'status', options: [...catalogs.accounting_register_statuses],editable:true},
        {id:'balance_sheet',type:'status', options: [...catalogs.accounting_register_statuses],editable:true},
        {id:'accounting_status_id',type:'status', options: [...catalogs.supervisor_statuses],editable:true},
        {id:'real_analyst',formId:'real_analyst_id',type:'text',editable:true},
        {id:'completition_date',type:'date', variant:'date',editable:true},
        {id:'completed',type:'checkbox',editable:true},
    ]
}