import React, { useEffect, useState } from 'react';
import { Grid, Icon, makeStyles, Typography } from '@material-ui/core';
import SimpleModal from '../../../../../../../components_v1/Structure/Modals/SimpleModal';
import DisplayText from '../../../../../../../components_v1/Texts/DisplayText';
import SectionTitle from './components/SectionTitle';
import MiniTable from './components/MiniTable';
import SubtitleTable from './components/SubtitleTable';
import MiniTableProcess from './components/MiniTableProcess';
import DataDisplayer from './components/DataDisplayer';
import { onGetFormatNumber } from '../../../../../../../shared/utility';



const useStyles = makeStyles(theme => ({
    form: {
        paddingTop: 32,
        paddingBottom: 0
    },
    container:{
        padding:'20px 4px'
    },
    section:{
        marginTop:16
    }
}))

const InfoModal = props => {

    const classes = useStyles()
    const { open, onClose, history, language, content, bonus } = props

    const [info, setInfo] = useState(null)

    useEffect(() => {
        if (open) {
            console.log(bonus?.info)
            try{
                if(bonus && bonus.info){
                    setInfo(JSON.parse(bonus.info))
                    console.log(JSON.parse(bonus.info))
                }
            }catch(e){
                console.log(e)
            }
            
        }
    }, [open])
    
    

    //tables
    const evaluation_header = [
        {id:1, label:'Periodo'},
        {id:2, label:'Calificación'},
        {id:3, label:''},
      
    ]

    const process_header = [
        {id:1, label:'Negocio'},
        {id:2, label:'Completado'},
        {id:3, label:'Fecha máxima'},
        {id:4, label:''},
    ]

    

    return (
        <SimpleModal open={open} onClose={onClose} maxWidth='md' padding='small'>
            <div className={classes.container}>
                <DisplayText variant='h6' color='primary' style={{ fontWeight: 500 }}>Detalles</DisplayText>
                {/* <div className={classes.form}>
                    <Typography>
                        {bonus?.info}
                    </Typography>
                </div> */}
                {info && info.salary ? <DataDisplayer title='Salario' data={onGetFormatNumber(info.salary)} /> : null}
                {info && info.evaluation_result ?
                <div className={classes.section}>
                    <SectionTitle title='Evaluaciones' result={Boolean(info.evaluation_result.result)}/>
                    <DataDisplayer title='Mensaje:' data={info.evaluation_result.message} />
                    <MiniTable header={evaluation_header} data={info.evaluation_result.evaluations} history={history}  />
                </div> : null}
                {info && info.time_report_result ?
                <div className={classes.section}>
                    <SectionTitle title='Time report' result={Boolean(info.time_report_result.result)}/>
                    <DataDisplayer title='Mensaje:' data={info.time_report_result.message} />
                    <Grid container spacing={2}>
                        <Grid item><DataDisplayer title='Resultado:' data={info.time_report_result.message} margin='0px' /></Grid>
                        <Grid item>
                            <Grid container spacing={1} alignItems='center' >
                                <Grid item><Icon color='primary' >schedule</Icon></Grid>
                                <Grid item><Typography color='secondary' >{(info.time_report_result.reported_hours).toFixed(2)} hrs</Typography></Grid>
                                <Grid item><Typography color='primary' >/ {(info.time_report_result.required_hours).toFixed(2)} hrs</Typography></Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </div> : null}
                {info && info.task_result ?
                <div className={classes.section}>
                    <SectionTitle title='Tareas' result={Boolean(info.task_result.result)}/>
                    <DataDisplayer title='Mensaje:' data={info.task_result.message} />
                    <DataDisplayer title='Resultado:' data={info.task_result.completed_tasks} data2={info.task_result.total_tasks} />
                </div> : null}
                {info && info.proc_integral_solution_result ?
                <div className={classes.section}>
                    <SectionTitle title='Proceso Solución Integral' result={Boolean(info.proc_integral_solution_result.result)}/>
                    <DataDisplayer title='Mensaje:' data={info.proc_integral_solution_result.message} />
                    <DataDisplayer title='Resultado:' data={info.proc_integral_solution_result.on_time_proc_integral_solution_count} 
                    data2={info.proc_integral_solution_result.total_proc_integral_solution_count}/>
                    <SubtitleTable>A tiempo</SubtitleTable>
                    <MiniTableProcess header={process_header} data={info.proc_integral_solution_result.on_time_proc_integral_solution} 
                    id_key={'id_proc_integral_solution'} url={'process/integralsolution'} history={history}  />
                    <SubtitleTable>Retardados</SubtitleTable>
                    <MiniTableProcess header={process_header} data={info.proc_integral_solution_result.delayed_proc_integral_solution} 
                    id_key={'id_proc_integral_solution'} url={'process/integralsolution'} history={history} />
                </div> : null}
                {info && info.proc_physical_person_result ?
                <div className={classes.section}>
                    <SectionTitle title='Proceso Personas Físicas' result={Boolean(info.proc_physical_person_result.result)}/>
                    <DataDisplayer title='Mensaje:' data={info.proc_physical_person_result.message} />
                    <DataDisplayer title='Resultado:' data={info.proc_physical_person_result.on_time_proc_physical_person_count} 
                    data2={info.proc_physical_person_result.total_proc_physical_person_count}/>
                    <SubtitleTable>A tiempo</SubtitleTable>
                    <MiniTableProcess header={process_header} data={info.proc_physical_person_result.on_time_proc_physical_person} 
                    id_key={'id_proc_physical_person'} url={'process/physicalpersons'} history={history}/>
                    <SubtitleTable>Retardados</SubtitleTable>
                    <MiniTableProcess header={process_header} data={info.proc_physical_person_result.delayed_physical_person} 
                    id_key={'id_proc_physical_person'} url={'process/physicalpersons'} history={history}/>
                </div> : null}
                {info && info.proc_social_security_result ?
                <div className={classes.section}>
                    <SectionTitle title='Proceso Seguro Social' result={Boolean(info.proc_social_security_result.result)}/>
                    <DataDisplayer title='Mensaje:' data={info.proc_social_security_result.message} />
                    <DataDisplayer title='Resultado:' data={info.proc_social_security_result.on_time_proc_social_security_count} 
                    data2={info.proc_social_security_result.total_proc_social_security_count}/>
                    <SubtitleTable>A tiempo</SubtitleTable>
                    <MiniTableProcess header={process_header} data={info.proc_social_security_result.on_time_proc_social_security} 
                    id_key={'id_proc_social_security'} url={'process/socialsecurity'} history={history}/>
                    <SubtitleTable>Retardados</SubtitleTable>
                    <MiniTableProcess header={process_header} data={info.proc_social_security_result.delayed_proc_social_security} 
                    id_key={'id_proc_social_security'} url={'process/socialsecurity'} history={history}/>
                </div> : null}
                {info && info.proc_state_tax_result ?
                <div className={classes.section}>
                    <SectionTitle title='Proceso Impuesto Local Sobre Nómina' result={Boolean(info.proc_state_tax_result.result)}/>
                    <DataDisplayer title='Mensaje:' data={info.proc_state_tax_result.message} />
                    <DataDisplayer title='Resultado:' data={info.proc_state_tax_result.on_time_proc_state_tax_count} 
                    data2={info.proc_state_tax_result.total_proc_state_tax_count}/>
                    <SubtitleTable>A tiempo</SubtitleTable>
                    <MiniTableProcess header={process_header} data={info.proc_state_tax_result.on_time_proc_state_tax} 
                    id_key={'id_proc_state_tax'} url={'process/statetax'} history={history}/>
                    <SubtitleTable>Retardados</SubtitleTable>
                    <MiniTableProcess header={process_header} data={info.proc_state_tax_result.delayed_proc_state_tax} 
                    id_key={'id_proc_state_tax'} url={'process/statetax'} history={history}/>
                </div> : null}
                {info && info.proc_stamping_result ?
                <div className={classes.section}>
                    <SectionTitle title='Timbrado y nómina' result={Boolean(info.proc_stamping_result.result)}/>
                    <DataDisplayer title='Mensaje:' data={info.proc_stamping_result.message} />
                    <DataDisplayer title='Resultado:' data={info.proc_stamping_result.on_time_proc_stamping_count} 
                    data2={info.proc_stamping_result.total_proc_stamping_count}/>
                    <SubtitleTable>A tiempo</SubtitleTable>
                    <MiniTableProcess header={process_header} data={info.proc_stamping_result.on_time_proc_stamping} 
                    id_key={'id_proc_stamping'} url={'process/stamping'} history={history}/>
                    <SubtitleTable>Retardados</SubtitleTable>
                    <MiniTableProcess header={process_header} data={info.proc_stamping_result.delayed_proc_stamping} 
                    id_key={'id_proc_stamping'} url={'process/stamping'} history={history}/>
                </div> : null}
            </div>
        </SimpleModal>
    );
}

export const formData = {
    password: {
        value: null,
        error: false,
        isVisited: false,
        isRequired: true,
        isValid: false,
        config: {
            id: 'password',
            type: 'password',
            fullWidth: true,
            label:'Nueva Contraseña',
            placeholder:'Nueva Contraseña'
        },
        rules: {
            type: 'distance',
            min: 1,
            max: 100
        }
    }
}

export default InfoModal;