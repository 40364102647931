import { catalogs } from "../../../texts/esp/catalogs";

export const content = {
    header:[
        {id:0,label:'',hidden:false, fixed:true, width:26, position:0, padding:'checkbox'},
        {id:1,key:'fixed_business_name',label:'Razón Social',hidden:false, width:300, fixed:true, add:{icon:'add'}, position:34},
        {id:2,key:'fixed_rfc',label:'RFC',hidden:false},
        {id:3,key:'fixed_client_classification',label:'Clasificación',hidden:false},
        {id:4,key:'fixed_client_status',label:'Estatus',hidden:false},
        {id:5,key:'fixed_client_responsible',label:'Asesor',hidden:false},
        //Fixed other data
        {id:6,key:'fiscal_regime',label:'Registro Patronal',hidden:false, add:{icon:'add'}},
        {id:7,key:'employer_registration_state',label:'Estado',hidden:false},
        {id:8,key:'employer_registration_city',label:'Ciudad',hidden:false},
        {id:9,key:'user',label:'Usuario Registro Estatal',hidden:false},
        {id:10,key:'employer_enrollment',label:'Número de Seguridad Social',hidden:false},
        /// New data
        {id:11,key:'tax_payment_date',label:'Fecha Pago Impuestos',hidden:false},
        {id:12,key:'presentation_limit_date',label:'Fecha Límite de Presentación',hidden:false},
        {id:20,label:'Hoja de Trabajo',hidden:false},
        {id:21,key:'process',label:'Proceso',hidden:false},
        {id:22,key:'status',label:'Estatus',hidden:false},
        {id:23,key:'payment_quantity',label:'Cantidad a Pagar',hidden:false},
        {id:24,key:'surcharges_and_updates',label:'Recargos y Actualizaciones',hidden:false},
        {id:26,key:'payment_total',label:'Total a Pagar',hidden:false},
        {id:27,label:'Línea de Captura IMSS/Infonavit',hidden:false},
        {id:28,key:'payment_limit_date',label:'Fecha Límite de Pago',hidden:false},
        {id:29,key:'responsible',label:'Responsable',hidden:false},
        {id:30,label:'Respuesta de Cliente',hidden:false},
        {id:31,key:'client_comment',label:'Comentario de Cliente',hidden:false},
        {id:27,key:'notes',label:'Notas',hidden:false},
        {id:28,key:'completition_date',label:'Fecha de Completado',hidden:false},
        {id:29,label:'Completado',hidden:false},

    ],
    dataConfig:[
        {id:'fixed_business_name',type:'text',fixed:true, editBtn:true, position:34},
        {id:'rfc',type:'text'},
        {id:'client_classification', type:'text'},
        {id:'client_status_id',type:'status', options: [...catalogs.client_statuses] },
        {id:'fixed_client_responsible',type:'text'},
        ///////// second fixed data
        {id:'fiscal_regime',type:'text'},
        {id:'employer_registration_state',type:'text'},
        {id:'employer_registration_city',type:'text'},
        {id:'user',type:'text'},
        {id:'employer_enrollment',type:'text'},
        // Real data
        {id:'tax_payment_date',type:'date', variant:'date',editable:true},
        {id:'presentation_limit_date',type:'date', variant:'date',editable:true},
        {id:'work_sheet',type:'document'},
        {id:'process',type:'text', editable:true},
        {id:'status',type:'text', editable:true},
        {id:'payment_quantity',type:'cash', editable:true},
        {id:'surcharges_and_updates',type:'cash', editable:true},
        {id:'payment_total',type:'cash', editable:true},
        {id:'capture_line_imss_infonavit',type:'document'},
        {id:'payment_limit_date',type:'date', variant:'date',editable:true},
        {id:'responsible', formId:'responsible_id', type:'text',editable:true},
        {id:'client_answer',type:'document'},
        {id:'client_comment',type:'text',editable:true},
        {id:'notes',type:'text',editable:true},
        {id:'completition_date',type:'date', variant:'date',editable:true},
        {id:'completed',type:'checkbox',editable:true},
    ]
}