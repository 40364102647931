import { mainServer } from "../../../variables/config";

export const request_businesses = async(value) => {
    const logData = JSON.parse(localStorage.getItem('data'));
    mainServer.instance.defaults.headers.common['Authorization'] = logData.token;
    const response = await mainServer.instance.get(`/timereport/businesses`, {params:{name:value, limit:20, offset:0}});
    return response.data.data.businesses
}

export const request_responsibles = async(value) => {
    const logData = JSON.parse(localStorage.getItem('data'));
    mainServer.instance.defaults.headers.common['Authorization'] = logData.token;
    let response;
    response = await mainServer.instance.get(`/timereport/employees`, {params:{name:value, limit:20, offset:0, language_id:2}});
    return response.data.data.employees
  }
  