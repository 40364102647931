export const onFilterDataArray = (data, fiscal_address, text) => { 
  const newArray =  data.filter(item => {
    let mustReturn = false;
    item.data.forEach(el =>{
      let response;
      if(typeof(el) === 'number'){
        response =  el.toString().toLowerCase().includes(text.toLowerCase());
      }else{
        response = el.toLowerCase().includes(text.toLowerCase());
      }
      if(response){
        mustReturn = true;
      }
    })
    item.data[2] = fiscal_address;
    return mustReturn;
  })
  return newArray;
}

export const onFillEmptyArray = (emptyRows, rowsPerPage) => {
  let emptylist = [];
  if(emptyRows > 0){
    let i;
    for(i = rowsPerPage - emptyRows; i < rowsPerPage; i++){
      emptylist.push(i)
    };
  }
  return emptylist
}

export const getStatusColor = (id) => {
  let color = 'gray';
  switch (id) {
    case 1:
      color = 'green';
      break;
    case 2:
      color = 'red';
      break;
    case 3:
      color = 'orange';
      break;
    case 4:
      color = 'green';
      break;
    default:
  }
  return color;
}


