export const contentData = {
  spanish: {
    nodata: 'Sin Registro',
    error: 'Sin registro',
    subtitle: 'Detalles',
    nospecialities: 'No existen especialidades registradas',
    title: {
      sectionA: '',
      sectionB: 'Información laboral',
      sectionC: 'Información de contacto',
      sectionD: 'Documentos',
      sectionE: 'Beneficios',
      sectionF: 'Permisos'
    },
    files: [
      { key: 'ine', name: 'Contrato', path: 'ine', status: false },
      { key: 'job_application', name: 'Expediente digital', path: 'jobapplication', status: false },
    ],
    header: { title: 'Archivos', actions: 'Acciones' },
    modals: {
      user_modal: {
        title: 'Editar información básica',
        nodata: 'Sin Registro',
        error: 'Sin registro',
        button: 'Actualizar',
        cancelButton: 'Cancelar',
        image_button: 'Cambiar',
      },
      work_modal: {
        title: 'Cambiar información laboral',
        nodata: 'Sin Registro',
        error: 'Sin registro',
        button: 'Actualizar',
        cancelButton: 'Cancelar',
        image_button: 'Cambiar',
      }
    },
    sectionA: {
      first_name: {
        label: 'Nombre(s)'
      },
      last_name: {
        label: 'Apellido(s)'
      },
      username: {
        label: 'Usuario'
      },
      gender: {
        label: 'Género'
      },
      scolarity: {
        label: 'Escoladridad'
      },
      specialization: {
        label: 'Especialización'
      },
      user_level: {
        label: 'Puesto',
      },
      area_name: {
        label: 'Área',
      },
      birth_date: {
        label: 'Fecha de nacimiento',
      },
      language: {
        label: 'Idioma',
      },
      timezone: {
        label: 'Zona Horaria',
      },
    },
    sectionB: {
      rfc: {
        label: 'R.F.C.'
      },
      curp: {
        label: 'C.U.R.P.'
      },
      contract_type: {
        label: 'Tipo de contrato'
      },
      contract_duration: {
        label: 'Duración de contrato'
      },
      employee_status: {
        label: 'Estatus'
      },
      salary_currency_id: {
        label: 'Divisa'
      },
      company_cost: {
        label: 'Costo'
      },
      gross_salary: {
        label: 'Salario bruto'
      },
      net_salary: {
        label: 'Salario neto'
      },
      entrance_hour: {
        label: 'Hora de entrada'
      },
      exit_hour: {
        label: 'Hora de salida'
      },
      entry_day: {
        label: 'Fecha de entrada'
      },
      exit_day: {
        label: 'Fecha de salida'
      },
    },
    sectionC: {
      phone_number: {
        label: 'Teléfono'
      },
      work_email: {
        label: 'Correo de trabajo'
      },
      personal_email: {
        label: 'Correo personal'
      },
      gmail: {
        label: 'Usuario Microsoft'
      },
      dropbox: {
        label: 'Usuario AS iViewer'
      },
    },
    sectionD: {
      speciality_id: {
        label: 'Especialidad'
      },
      licese: {
        label: 'Cédula Profesional'
      },
      ssa: {
        label: 'S.S.A.'
      },
    }
  },

}