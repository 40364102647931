import {mainServer} from '../../../../../variables/config.jsx'
import FileSaver from 'file-saver'
import axios from 'axios'

export const request_users = async () => {
  const logData = JSON.parse(localStorage.getItem('data'));
  mainServer.instance.defaults.headers.common['Authorization'] = logData.token;
  let response;
  response = await mainServer.instance.get("/user/employee/list/");
  return response.data.data.employees;
}

export const request_business_documents = async (id) => {
  const logData = JSON.parse(localStorage.getItem('data'));
  mainServer.instance.defaults.headers.common['Authorization'] = logData.token;
  let response;
  response = await mainServer.instance.get("/businessdocument/"+id.toString());
  return response.data.data.tributary_mailbox_statuses;
}

/**************************************** */

export const request_service_item = async (id, registerID) => {
  const logData = JSON.parse(localStorage.getItem('data'));
  mainServer.instance.defaults.headers.common['Authorization'] = logData.token;
  let response;
  response = await mainServer.instance.get("/stateregisterinscription/"+registerID.toString())
  let service = response.data.data.state_register_inscription;
  response = await mainServer.instance.get("/businessdocument/"+id.toString());
  service = {...service, 
            rfc:response.data.data.tributary_mailbox_statuses.rfc,
            company_type_id:response.data.data.tributary_mailbox_statuses.company_type_id,
            company_type:response.data.data.tributary_mailbox_statuses.company_type}  
  return service;
}

export const request_add_service_item = async (id, data) => {
  const logData = JSON.parse(localStorage.getItem('data'));
  mainServer.instance.defaults.headers.common['Authorization'] = logData.token;
  await mainServer.instance.post("/stateregisterinscription",data);
  let response;
  response = await mainServer.instance.get("/stateregisterinscription/"+id.toString())
  return response.data.data.state_register_inscription;
}

export const request_edit_service_item = async (id, registerID, data) => {
  const logData = JSON.parse(localStorage.getItem('data'));
  mainServer.instance.defaults.headers.common['Authorization'] = logData.token;
  await mainServer.instance.patch("/stateregisterinscription/" + registerID, data);
  await mainServer.instance.patch("/businessdocument/"+id.toString(), 
  {rfc:data.rfc, company_type_id:data.company_type_id});
  let response;
  response = await mainServer.instance.get("/stateregisterinscription/"+registerID.toString())
  let service = response.data.data.state_register_inscription;
  response = await mainServer.instance.get("/businessdocument/"+id.toString());
  service = {...service, 
    rfc:response.data.data.tributary_mailbox_statuses.rfc,
    company_type_id:response.data.data.tributary_mailbox_statuses.company_type_id,
    company_type:response.data.data.tributary_mailbox_statuses.company_type}  
  return service;
}

export const request_delete_service_item = async (id, data) => {
  const logData = JSON.parse(localStorage.getItem('data'));
  mainServer.instance.defaults.headers.common['Authorization'] = logData.token;
  await mainServer.instance.delete("/stateregisterinscription/" +id+"/"+data);
  let response;
  response = await mainServer.instance.get("/stateregisterinscription/"+id.toString())
  return response.data.data.state_register_inscription;
}

/**************************************** */

export const request_uploadFile = async(moduleID, id,path, file,ext, type) => {
  const logData = JSON.parse(localStorage.getItem('data'));
  mainServer.instance.defaults.headers.common['Authorization'] = logData.token;
  let url = 'stateregisterinscription/' + moduleID + '/'+path;
  if(type)
    url = 'businessdocument/'+id+'/'+path;
  let response = await mainServer.instance.post(url, ext)
  const options = {headers: {'Content-Type': file.type}}
  delete axios.defaults.headers.common["Authorization"]
  response = await axios.put(response.data.data.path, file, options);
  mainServer.instance.defaults.headers.common['Authorization'] = logData.token;
  response = await mainServer.instance.get("/stateregisterinscription/"+moduleID.toString())
  return response.data.data.state_register_inscription;
}

export const request_downloadFile = async(moduleID,id,path,type) => {
  const logData = JSON.parse(localStorage.getItem('data'));
  mainServer.instance.defaults.headers.common['Authorization'] = logData.token;
  let response;
  let url = 'stateregisterinscription/' + moduleID + '/'+path;
  if(type)
    url = 'businessdocument/'+id+'/'+path;
  response = await mainServer.instance.get(url)
  delete axios.defaults.headers.common["Authorization"]
  response = await axios.get(response.data.data.path, {responseType: 'blob'})
  FileSaver.saveAs(response.data)
}

export const request_deleteFile = async(moduleID, id,path, type) => {
  const logData = JSON.parse(localStorage.getItem('data'));
  mainServer.instance.defaults.headers.common['Authorization'] = logData.token;
  let response
  let url = 'stateregisterinscription/' + moduleID + '/'+path;
  if(type)
    url = 'businessdocument/'+id+'/'+path;
  await mainServer.instance.delete(url);
  response = await mainServer.instance.get("/stateregisterinscription/"+moduleID.toString())
  return response.data.data.state_register_inscription;
}
